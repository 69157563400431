import React, { PureComponent } from 'react';
import { throttle } from 'lodash';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import Animation from 'components/ui/animation';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import NoContent from 'components/ui/noContent';
import Teaser from 'components/ui/teaser';
import PaymentActions from 'components/paymentCard/paymentActions/PaymentActionsContainer';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import PaymentPageUI from 'types/PaymentPageUI';
import PaymentItemType from 'types/savedEntity/SavedPayment';
import { Config } from 'components/paymentCard/PaymentCardContainer';
import Operations from 'helpers/Operations';
import OperationItem from 'components/paymentCard/operationItem';
import CustomScrollbar from 'components/ui/customScrollbar';
import CopyToClipboard from 'components/ui/copyToClipboard';
import OperationInfo from 'components/paymentCard/operationInfo';
import CallBackButtonsMenu from 'components/paymentCard/callbackButtonsMenu';
import AddBlackList from 'components/paymentCard/panelBlackList/AddBlackListContainer';
import ButtonReference from 'components/paymentCard/buttonReference';
import CreateSupportTicketButton from 'components/createSupportTicketButton/CreateSupportTicketButton';
import PaymentInfo from 'components/paymentCard/paymentInfo';
import Messages from 'constants/rpcTypes';
import Button from 'components/ui/button';
import Loader from 'components/ui/loader';
import permissionReasons from 'constants/permissionReasons';
import breakpoints from 'constants/breakpoints';
import './paymentCard.scss';

interface Props extends IntlProps, WithPermissions {
  payment?: PaymentItemType;
  config?: Config;
  projectId?: string;
  isLoading: boolean;
  syncPaymentUI: (id: string, ui: PaymentPageUI) => void;
}

interface State {
  pageUI: PaymentPageUI;
  canRenderActions: boolean;
  isOpenedPaymentInfo: boolean;
}

class PaymentCard extends PureComponent<Props, State> {
  id: string;
  constructor(props) {
    super(props);
    this.state = {
      pageUI: { ...props.payment?.ui },
      canRenderActions: true,
      isOpenedPaymentInfo: this.setPaymentInfoState(),
    };
    this.id = props.payment.id;
    this.handleResize = throttle(this.handleResize, 500);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const { payment } = this.props;
    if (
      payment?.data?.payment?.transactionId !==
      prevProps.payment?.data?.payment?.transactionId
    ) {
      this.setState(
        {
          canRenderActions: false,
        },
        () => {
          this.setState({ canRenderActions: true });
        }
      );
    }
  }

  render() {
    const { canRenderActions, isOpenedPaymentInfo } = this.state;
    const { payment, config, isLoading, getTranslate } = this.props;
    let paymentIdWithCopyToClipboard: string | JSX.Element;
    if (payment?.data?.payment?.paymentId) {
      paymentIdWithCopyToClipboard = (
        <div className='payment-card__payment-id-with-copy-to-clipboard'>
          <span className='payment-card__payment-id'>
            {payment.data.payment.paymentId}
          </span>
          <CopyToClipboard
            text={payment.data.payment.paymentId}
            customClass='ui-copy-to-clipboard_color_white'
          />
        </div>
      );
    } else {
      paymentIdWithCopyToClipboard = ``;
    }

    return (
      <PageTemplate.Container customClass='payment-card'>
        <div className='payment-card__inner'>
          <Animation>
            {payment?.data?.error ? (
              <NoContent
                text={getTranslate(
                  'payments.single.paymentInfo.noPaymentDetails.text'
                )}
              />
            ) : (
              <div className='payment-card__wrapper'>
                <div className='payment-card__item payment-card__item_prev'>
                  <Teaser
                    title={getTranslate('payments.data.payment.header')}
                    text={paymentIdWithCopyToClipboard}
                    status={payment?.data?.payment.paymentStatus}
                    isLoading={isLoading}>
                    <Button
                      text={getTranslate(
                        'payments.single.viewPaymentInfo.button'
                      )}
                      onClick={this.openPaymentInfo}
                      status='light'
                      size='normal'
                      customClass='payment-card__mobile-button'
                    />
                    {config?.isManagePayment && canRenderActions && (
                      <PaymentActions
                        transactionId={payment?.data?.payment.transactionId}
                        projectId={payment?.data?.payment.projectId}
                        buttonTheme='light'
                        customClass='payment-actions_card'
                      />
                    )}
                  </Teaser>
                </div>
                <div className='payment-card__item payment-card__item_operation-info'>
                  {this.renderOperationInfo()}
                </div>
                {isOpenedPaymentInfo && (
                  <div className='payment-card__item payment-card__item_payment-info'>
                    {this.renderPaymentInfo()}
                  </div>
                )}

                <div className='payment-card__item payment-card__item_operations'>
                  {this.renderOperationsList()}
                </div>
              </div>
            )}
          </Animation>
        </div>
      </PageTemplate.Container>
    );
  }

  componentWillUnmount() {
    const { syncPaymentUI, payment } = this.props;
    const { pageUI } = this.state;
    window.removeEventListener('resize', this.handleResize);

    if (!payment) return;
    syncPaymentUI(this.id, pageUI);
  }

  renderOperationsList = () => {
    const { payment, isLoading, getTranslate } = this.props;
    const {
      pageUI: { selectedOperationId, operationsScrollTop },
    } = this.state;

    const operationsCount = !isLoading ? payment?.data?.operations?.length : '';
    const operationsDuration = !isLoading
      ? Operations.getDuration(payment?.data?.operations || [])
      : '';

    return (
      <div className='payment-card__operations card utils-flex-column'>
        <div className='card__header'>
          <div className='card__title'>
            {getTranslate('payments.data.paymentOperations.header')}
          </div>
          <div className='card__subtext'>
            {operationsDuration &&
              `${getTranslate('payments.single.operation', {
                operationsCount,
              })} |
          ${getTranslate('payments.single.duration', {
            operationsDuration,
          })}`}
          </div>
        </div>

        <div className='payment-card__content'>
          {isLoading ? (
            <Loader type='bung' />
          ) : (
            <CustomScrollbar
              initialScrollTop={operationsScrollTop}
              needResetScrollTop={true}
              onScrollStop={({ scrollTop }) =>
                this.changePageUI({ operationsScrollTop: scrollTop })
              }>
              <div className='card__content payment-card__operations-list'>
                {payment?.data?.operations?.map((operation, index) => {
                  let isCurrent: boolean;
                  if (!selectedOperationId && index === 0) {
                    isCurrent = true;
                  } else {
                    isCurrent = operation.operationId === selectedOperationId;
                  }

                  return (
                    <OperationItem
                      key={operation.operationId}
                      data={operation}
                      isCurrent={isCurrent}
                      onSelect={(operationId) =>
                        this.changePageUI({
                          selectedOperationId: operationId,
                        })
                      }
                    />
                  );
                })}
              </div>
            </CustomScrollbar>
          )}
        </div>
      </div>
    );
  };

  renderOperationInfo = () => {
    const { payment, isLoading, getTranslate } = this.props;
    const { pageUI } = this.state;
    const transactionId = payment?.data && payment.data.payment.transactionId;
    const selectedOperation = this.getSelectedOperation();

    return (
      <div className='payment-card__operation-info card utils-flex-column'>
        <div className='card__header'>
          <div className='card__subtext'>
            {getTranslate('payments.single.operationCard.header')}
          </div>
          <div className='card__title-l'>
            ID {selectedOperation?.operationId}{' '}
            <CopyToClipboard
              text={selectedOperation?.operationId}
              iconSize={14}
            />
          </div>
        </div>

        <div className='payment-card__content utils-flex-column'>
          {isLoading ? (
            <Loader type='bung' />
          ) : (
            <>
              <div className='payment-card__buttons card__container'>
                {this.renderOperationButtons()}
              </div>
              <div className='payment-card__content'>
                <CustomScrollbar
                  customHeight='100%'
                  initialScrollTop={pageUI.operationInfoScrollTop}
                  needResetScrollTop={true}
                  onScrollStop={({ scrollTop }) =>
                    this.changePageUI({ operationInfoScrollTop: scrollTop })
                  }>
                  <div className='card__container card__content'>
                    <OperationInfo
                      transactionId={transactionId}
                      operation={selectedOperation}
                      pageUI={pageUI}
                      onChangePageUI={this.changePageUI}
                      showVatFields={payment?.data?.showVatFields}
                      modern
                    />
                  </div>
                </CustomScrollbar>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  renderOperationButtons = () => {
    const { config, payment, isEnabled, isDisabledByReason, getTranslate } =
      this.props;
    const { canRenderActions } = this.state;
    const transactionId = payment?.data?.payment.transactionId;
    const selectedOperation = this.getSelectedOperation();

    return (
      <>
        {selectedOperation?.callback?.callbackBody && (
          <CallBackButtonsMenu
            callbackBody={selectedOperation.callback.callbackBody}
            transactionId={transactionId}
            operationId={selectedOperation.operationId}
            resendCallback={
              selectedOperation.availableActions.callbackResend.isEnabled
            }
            customClass='payment-single__block-button'
          />
        )}
        {selectedOperation?.availableActions.operationReferenceOrder
          .isEnabled && (
          <ButtonReference
            key={selectedOperation?.operationId}
            transactionId={transactionId}
            operationId={selectedOperation?.operationId}
            customClass='payment-single__block-button'
          />
        )}
        {(isEnabled(Messages.PaymentIssue_PerformAction) ||
          isDisabledByReason(
            Messages.PaymentIssue_PerformAction,
            permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
          )) && (
          <CreateSupportTicketButton
            operationId={selectedOperation?.operationId}
            transactionId={transactionId}
          />
        )}
        {config?.isAddToList && (
          <AddBlackList
            transactionId={transactionId}
            operationId={selectedOperation?.operationId}
            customClass='payment-single__block-button'
          />
        )}
        {canRenderActions &&
          selectedOperation?.operationType === 'recurring' && (
            <div className='payment-single__refund'>
              <PaymentActions
                isNeedPing={false}
                operationId={selectedOperation?.operationId}
                transactionId={transactionId}
                projectId={this.props.payment?.data?.payment.projectId}
                refundTooltip={getTranslate(
                  'payments.data.operationRefund.info.tooltip'
                )}
                customClass='payment-actions_card'
                buttonTheme='primary'
              />
            </div>
          )}
      </>
    );
  };

  renderPaymentInfo = () => {
    const { config, payment, isLoading } = this.props;
    const { pageUI } = this.state;

    return (
      <PaymentInfo
        theme='brand'
        isLoading={isLoading}
        closePaymentInfo={this.closePaymentInfo}
        data={payment?.data}
        pageUI={pageUI}
        onChangePageUI={this.changePageUI}
        isManagePayment={config?.isManagePayment}
        isCustomerInfo={config?.isCustomerInfo}
        isRemittanceRecipientAccount={config?.isRemittanceRecipientAccount}
      />
    );
  };

  changePageUI = (object) => {
    this.setState((prevState) => ({
      ...prevState,
      pageUI: {
        ...prevState.pageUI,
        ...object,
      },
    }));
  };

  getSelectedOperation() {
    const { payment } = this.props;
    const {
      pageUI: { selectedOperationId },
    } = this.state;

    if (!payment?.data?.operations) return null;

    const { operations } = payment.data;

    return (
      operations.find(
        ({ operationId }) => selectedOperationId === operationId
      ) || operations[0]
    );
  }

  openPaymentInfo = () => {
    this.setState({ isOpenedPaymentInfo: true });
  };

  closePaymentInfo = () => {
    this.setState({ isOpenedPaymentInfo: false });
  };

  handleResize = () => {
    if (document.body.clientWidth < 1024) {
      this.closePaymentInfo();
    } else {
      this.openPaymentInfo();
    }
  };

  setPaymentInfoState = () => {
    return document.body.clientWidth >= breakpoints.commonTabletL;
  };
}
export default addTranslation(addPermissions(PaymentCard));
