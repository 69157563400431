import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import api from 'api/chat';
import { editSupportMessages } from 'actions/user';
import { StoreProps } from 'store';
import { SupportMessages } from 'types/Api';

import { getSupportMessage } from './helpers';
import SupportChat from './SupportChat';
import './supportChat.scss';

interface OwnProps {
  isLoading: boolean;
}

interface ConnectedProps {
  messages: SupportMessages;
}

type Props = OwnProps & ConnectedProps & StoreProps & IntlProps;

interface State {
  isSending: boolean;
  isLoadingMore: boolean;
}
const AUTO_REPLY_TIMEOUT_MS = 5 * 1000;

class SupportChatContainer extends Component<Props, State> {
  markAsReadTimeout;
  idleTimeout;
  constructor(props: Props) {
    super(props);

    this.state = {
      isSending: false,
      isLoadingMore: false,
    };
  }

  componentDidMount() {
    this.markMessagesAsRead();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (this.props.messages.list.length !== prevProps.messages.list.length) {
      this.markMessagesAsRead();
    }
  }

  render() {
    const { isLoading, messages } = this.props;
    const { isSending, isLoadingMore } = this.state;

    return (
      <SupportChat
        raiseLimit={this.raiseLimit}
        messages={messages}
        isLoading={isLoading}
        isLoadingMore={isLoadingMore}
        isSending={isSending}
        onSendMessage={this.sendMessage}
        loadMore={this.loadMore}
      />
    );
  }

  markMessagesAsRead = () => {
    const { messages } = this.props;

    const unreadMessages = messages.list.filter((msg) => !msg.isRead);
    if (unreadMessages.length) {
      this.markAsRead(unreadMessages.map((i) => i.id));
    }
  };

  raiseLimit = () => {
    const { dispatch, messages } = this.props;
    dispatch(
      editSupportMessages({ limits: { ...messages.limits, message: 1 } })
    );
  };

  markAsRead = (ids) => {
    const { dispatch, messages } = this.props;

    const newList = messages.list.map((msg) => {
      if (ids.includes(msg.id)) {
        msg.isRead = true;
      }
      return msg;
    });
    const unreadCount = newList.filter((msg) => !msg.isRead).length;
    dispatch(
      editSupportMessages({
        list: newList,
        unreadCount,
      })
    );

    clearTimeout(this.markAsReadTimeout);
    this.markAsReadTimeout = setTimeout(() => {
      api.markChatMessagesAsRead(ids);
    }, 300);
  };

  sendMessage = async (message) => {
    clearTimeout(this.idleTimeout);

    this.setState({ isSending: true });
    if (message.length) {
      try {
        await api.sendMessage({ text: message });
        this.idleTimeout = setTimeout(() => {
          this.showAutoSupportMessage();
        }, AUTO_REPLY_TIMEOUT_MS);
      } catch (e) {
        console.error(e);
      } finally {
        this.setState({ isSending: false });
      }
    }
  };

  showAutoSupportMessage = () => {
    const { dispatch, messages, getTranslate } = this.props;

    if (messages.list.find((message) => message.__autoReply)) {
      return;
    }

    dispatch(
      editSupportMessages({
        ...messages,
        list: messages.list.concat(
          getSupportMessage({
            id: 'info-autoReply',
            text: getTranslate('chatBot.autoAnswer.text'),
            __autoReply: true,
          })
        ),
      })
    );
  };

  loadMore = async () => {
    const { messages, isLoading } = this.props;
    const { isLoadingMore } = this.state;

    if (!messages.hasMoreMessages || isLoading || isLoadingMore) {
      return;
    }

    this.setState({ isLoadingMore: true });

    const firstUserMessage = messages.list.find((m) => !m.id.includes('info'));

    try {
      await api.getMessages({ ltMessageId: firstUserMessage?.id });
    } finally {
      this.setState({ isLoadingMore: false });
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  messages: state.user.supportMessages || {},
});

export default connect(mapStateToProps)(addTranslation(SupportChatContainer));
