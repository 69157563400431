import React from 'react';

import Button from '../../../../ui/button';

import {
  addTranslation,
  IntlProps,
} from '../../../../../decorators/addTranslation';
import { AdvancedAnalyticsEntityType } from './AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';

const tabToConfig = {
  [AdvancedAnalyticsEntityType.operations]: {
    label: 'analytics.editForm.operations.tab',
    buttonLabel: 'analytics.editForm.template.conversions',
    templateExist: true,
  },
  [AdvancedAnalyticsEntityType.payments]: {
    label: 'analytics.editForm.payments.tab',
  },
  [AdvancedAnalyticsEntityType.payments_sa]: {
    label: 'analytics.editForm.payments.tab',
    postfix: 'analytics.editForm.for_sales_postfix.tab',
  },
  [AdvancedAnalyticsEntityType.inout]: {
    label: 'analytics.editForm.inOut.tab',
    buttonLabel: 'analytics.editForm.template.turnover',
    templateExist: true,
  },
  [AdvancedAnalyticsEntityType.inout_sales_agents]: {
    label: 'analytics.editForm.inOut.tab',
    postfix: 'analytics.editForm.for_sales_postfix.tab',
    buttonLabel: 'analytics.editForm.template.turnover',
    templateExist: true,
  },
  [AdvancedAnalyticsEntityType.topten]: {
    label: 'analytics.editForm.top.tab',
    buttonLabel: 'analytics.editForm.template.byIssuers',
    templateExist: true,
  },
  [AdvancedAnalyticsEntityType.declineCodes]: {
    label: 'analytics.editForm.declineCodes.tab',
  },
  [AdvancedAnalyticsEntityType.declineReasons]: {
    label: 'analytics.editForm.operationsPivot.tab',
  },
  [AdvancedAnalyticsEntityType.fraud_report]: {
    label: 'analytics.editForm.fraud.tab',
  },
  [AdvancedAnalyticsEntityType.chargeback]: {
    label: 'analytics.editForm.chargeback.tab',
  },
};

interface Props extends IntlProps {
  tab: string;
  onClick: (tab: string) => any;
  canUseTemplate: boolean;
}

const GraphDetailsWrapper = ({ children }) => (
  <div className='graph-details__label'>{children}</div>
);
const GraphDetailsLabel = ({
  getTranslate,
  tab,
  onClick,
  canUseTemplate,
}: Props) => {
  const config = tabToConfig[tab];
  const labelText = getTranslate(config['label']);
  const templateExist = config['templateExist'] ?? false;

  if (!canUseTemplate || !templateExist) {
    return <GraphDetailsWrapper>{labelText}</GraphDetailsWrapper>;
  }

  return (
    <GraphDetailsWrapper>
      <div className='graph-details__label_withTemplateButton'>
        <div>{labelText}</div>
        <div>
          <Button
            status='outline'
            icon={'dataset'}
            iconPosition={'left'}
            text={getTranslate(config['buttonLabel'])}
            tooltip={getTranslate(
              'analytics.editForm.template.button.useTemplateTooltip'
            )}
            onClick={() => {
              onClick(tab);
            }}
          />
        </div>
      </div>
    </GraphDetailsWrapper>
  );
};

export default addTranslation(GraphDetailsLabel);
