import { ModalChartTypes } from 'types/Analytics';
import { AdvancedAnalyticsEntityType } from '../components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';
import RpcTypes from 'constants/rpcTypes';

export const types: ModalChartTypes = [
  {
    id: AdvancedAnalyticsEntityType.operations,
    icon: {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      name: 'im-Finance',
      className: 'payments',
    },
    title: 'analytics.editForm.operations.tab',
    text: 'analytics.editForm.comment.operations.info',
    permission: RpcTypes.Analytics_AdvancedAnalyticsOnly,
  },
  {
    id: AdvancedAnalyticsEntityType.payments,
    icon: {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      name: 'payments',
      className: 'payments',
    },
    title: 'analytics.editForm.payments.tab',
    text: 'analytics.editForm.comment.operations.info',
    permission: RpcTypes.Analytics_AdvancedAnalyticsOnly,
  },
  {
    id: AdvancedAnalyticsEntityType.payments_sa,
    icon: {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      name: 'payments',
      className: 'payments',
    },
    title: 'analytics.editForm.payments.tab',
    text: 'analytics.editForm.comment.operations.info',
    permission: RpcTypes.Analytics_SalesAgents,
  },
  {
    id: AdvancedAnalyticsEntityType.declineReasons,
    icon: {
      name: 'im-Analytics-2',
      className: 'revenue',
    },
    title: 'analytics.editForm.operationsPivot.tab',
    text: 'analytics.editForm.comment.operationsPivot.info',
    permission: RpcTypes.Analytics_AdvancedAnalyticsOnly,
  },
  {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    id: AdvancedAnalyticsEntityType.inout,
    icon: {
      name: 'im-Finance',
      className: 'in-out-report',
    },
    title: 'analytics.editForm.inOut.tab',
    text: 'analytics.editForm.inOutComment.info',
    permission: RpcTypes.Analytics_AdvancedAnalyticsOnly,
  },
  {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    id: AdvancedAnalyticsEntityType.inout_sales_agents,
    icon: {
      name: 'im-Finance',
      className: 'in-out-report',
    },
    title: 'analytics.editForm.inOut.tab',
    text: 'analytics.editForm.inOutComment.info',
    permission: RpcTypes.Analytics_SalesAgents,
  },
  {
    id: AdvancedAnalyticsEntityType.topten,
    icon: {
      name: 'top10question',
      className: 'top-countries',
    },
    title: 'analytics.editForm.top.tab',
    text: 'analytics.editForm.topComment.info',
    permission: RpcTypes.Analytics_AdvancedAnalyticsOnly,
  },
  {
    id: AdvancedAnalyticsEntityType.declineCodes,
    icon: {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      name: 'fact_check',
      className: 'payments',
    },
    title: 'analytics.editForm.declineCodes.tab',
    text: 'analytics.editForm.comment.declineCodes.info',
    permission: RpcTypes.Analytics_AdvancedAnalyticsOnly,
  },
  {
    id: AdvancedAnalyticsEntityType.chargeback,
    icon: {
      name: 'im-Chargebacks',
      className: 'chargebacks',
    },
    title: 'analytics.editForm.chargeback.tab',
    text: 'analytics.editForm.chargebackComment.info',
    permission: RpcTypes.Chargeback_List,
  },
  {
    id: AdvancedAnalyticsEntityType.fraud_report,
    icon: {
      name: 'im-Risk',
      className: 'fraud-report',
    },
    title: 'analytics.editForm.fraud.tab',
    text: 'analytics.editForm.fraudComment.info',
    permission: RpcTypes.Rcs_ListFraud,
  },
];
