import React from 'react';
import { connect } from 'react-redux';
import Invoices from './Invoices';

interface OwnProps {
  isCreate?: boolean;
}

interface ConnectedProps {
  configuration: any;
}

type Props = OwnProps & ConnectedProps;

const InvoicesContainer: React.VFC<Props> = ({ configuration, isCreate }) =>
  !configuration ? null : <Invoices isCreate={Boolean(isCreate)} />;

const mapStateToProps = (state): ConnectedProps => ({
  configuration: state.configuration,
});

export default connect(mapStateToProps)(InvoicesContainer);
