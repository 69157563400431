import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { invert } from 'lodash';

import { addListeners } from 'decorators/addListeners';
import FormFields from 'components/formFields';

import Messages from 'constants/rpcTypes';
import fieldsConfig from './fieldsConfig';
import RemittancePaymentSingle from './components/remittancePaymentSingle/RemittancePaymentSingle';
import RemittancePaymentMass from './components/remittancePaymentMass/RemittancePaymentMass';
import { createRoutes, formIdRoutes } from '../routesMapper';
import path from 'helpers/path';
import urlsMap from 'constants/urlsMap';
import { WithRouterProps } from 'decorators/withRouter';
import tableNames from 'constants/tableNames';

interface OwnProps {
  id?: string;
}

interface State {
  currentForm: string;
}

type Props = OwnProps & WithRouterProps<{ formId: string }>;

@addListeners([
  Messages.RemittanceQueue_StatusUpdated,
  Messages.Remittance_CreatePayment,
])
class RemittancePaymentBuilder extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentForm: 'single',
    };
    this.checkRoute();
  }

  componentDidMount() {
    this.setFormTab();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.formId !== this.props.match.params.formId) {
      this.setFormTab();
    }
    if (prevState.currentForm !== this.state.currentForm) {
      this.setFormRoute();
    }
  }

  render() {
    const { currentForm } = this.state;
    return (
      <>
        {/* @ts-ignore TODO: t.melashchenko, выручай!*/}
        <FormFields
          id={currentForm}
          title='remittance.addPayout.header'
          fieldsConfig={fieldsConfig}
          tabs={[
            {
              id: 'single',
              label: 'remittance.addPayout.tabs.single',
              isCurrent:
                this.props.match.params.formId === formIdRoutes['single'],
            },
            {
              id: 'mass',
              label: 'remittance.addPayout.tabs.mass',
              isCurrent:
                this.props.match.params.formId === formIdRoutes['mass'],
            },
          ]}
          formFields={
            currentForm === 'single' ? (
              // @ts-ignore TODO: t.melashchenko, выручай!
              <RemittancePaymentSingle id={this.props.id} />
            ) : (
              <RemittancePaymentMass />
            )
          }
          onChangeTab={this.onChangeTab}
        />
      </>
    );
  }

  onChangeTab = (_, currentForm) => {
    this.setState({ currentForm });
  };

  checkRoute = () => {
    const isCorrectRoute = Object.values(formIdRoutes).includes(
      this.props.match.params.formId
    );

    if (!isCorrectRoute) {
      this.setFormRoute();
    }
  };

  setFormTab = () => {
    if (!this.state) return;
    const { match } = this.props;
    const formRoutes = invert(formIdRoutes);

    if (formRoutes[match.params.formId] !== this.state.currentForm) {
      this.onChangeTab(null, formRoutes[match.params.formId]);
    }
  };

  setFormRoute = () => {
    if (
      !this.state.currentForm ||
      this.props.match.params.formId === formIdRoutes[this.state.currentForm]
    )
      return;

    this.props.history.push(
      path(
        `/${urlsMap.remittance}/${
          createRoutes[tableNames.remittancePayments]
        }/${formIdRoutes[this.state.currentForm]}`
      )
    );
  };
}

export default withRouter(RemittancePaymentBuilder);
