import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Input from 'components/ui/inputAmount';

interface Props extends IntlProps {
  id: string;
  value: string;
  onChange: (filterId: string, value: any) => void;
  label?: string;
  placeholder?: string;
  isRawValue?: boolean;
}

class InputText extends PureComponent<Props> {
  render() {
    const {
      id,
      value,
      onChange,
      label,
      placeholder,
      getTranslate,
      isRawValue,
    } = this.props;

    return (
      <div className='filters-numbers'>
        <Input
          id={id}
          label={label || getTranslate(`filters.fields.${id}.label`)}
          placeholder={placeholder || `filters.fields.${id}.placeholder`}
          value={value}
          onChange={(event) => {
            const { target } = event;
            const newValue = isRawValue ? target['rawValue'] : target.value;
            onChange(id, newValue);
          }}
          cleaveOptions={{ numericOnly: true }}
          modern
        />
      </div>
    );
  }
}

export default addTranslation(InputText);
