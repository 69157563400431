export default [
  'im-Phone',
  'im-Single-Payout',
  'im-MO_TO-Payment',
  'im-House',
  'im-Mass-Remittances',
  'im-Mass-Cancels',
  'im-Mass-Captures',
  'im-Mass-Refunds',
  'im-Document',
  'im-Location',
  'im-Remmitance',
  'im-Menu',
  'im-Pin',
  'im-Chargebacks',
  'im-Many-Files',
  'im-List',
  'im-Code',
  'im-Browser',
  'im-Edit',
  'im-Touch',
  'im-Add-settings',
  'im-Analytics-2',
  'im-Analytics',
  'im-Arrow-down',
  'im-Arrow-left',
  'im-Arrow-right',
  'im-Avatar',
  'im-Basket',
  'im-Calendar',
  'im-Card-pay',
  'im-Catalog',
  'im-Changes-history',
  'im-Chargebacks1',
  'im-Chat',
  'im-Check-mark',
  'im-Close',
  'im-Collapse',
  'im-Configurator-2',
  'im-Configurator',
  'im-Copy',
  'im-Csv',
  'im-Danger',
  'im-Desktop',
  'im-Dots',
  'im-Double-arrows',
  'im-Double-basket',
  'im-Download1',
  'im-Draganddrop',
  'im-Edit1',
  'im-Ellipse',
  'im-Error',
  'im-Expand',
  'im-Eye-close',
  'im-Eye-open',
  'im-File',
  'im-Filter-2',
  'im-Filter',
  'im-Finance',
  'im-Forbidden',
  'im-Forms',
  'im-Get-Started',
  'im-Helpdesk',
  'im-Info-Square',
  'im-Info',
  'im-Invoice',
  'im-Loading',
  'im-Lock-close',
  'im-Lock-open',
  'im-Log-out',
  'im-Marker',
  'im-Menu1',
  'im-Mobile',
  'im-Multichoise',
  'im-My-team',
  'im-Payments',
  'im-Payout',
  'im-Plus',
  'im-Projects',
  'im-Question',
  'im-References',
  'im-Refill',
  'im-Refresh',
  'im-Reports',
  'im-Reset-filter',
  'im-Risk',
  'im-Select',
  'im-Settings',
  'im-Sort',
  'im-Subscription',
  'im-Tick',
  'im-Timezone',
  'im-Warning-2',
  'im-Warning',
  'im-Xls',
  'im-Arrow-right-Option-2',
  'im-External-Link',
  'im-Reconcilations',
  'im-Arrow-left-Option-2',
  'im-Info-Hint',
  'im-Search',
  'im-Camera',
  'im-Page-Controller',
  'im-Language',
  'im-Favourite',
  'im-Favourite-Filled',
  'im-Party',
];
