export const isRightPositionByWindow = (el) => {
  if (!el) return;
  const fullWidth = document.body.clientWidth;
  const selectPosition = el.getBoundingClientRect().right;
  const MIN_WIDTH = 220;
  const maxSelectMenuWidth =
    el.clientWidth > MIN_WIDTH ? el.clientWidth : MIN_WIDTH;
  return el.clientWidth + fullWidth - selectPosition < maxSelectMenuWidth;
};

const INPUT_HEIGHT = 52;
export const isTopPositionByBlock = (
  el,
  containerSelector,
  blockSelector,
  isUpperTop = false
) => {
  if (!el || !containerSelector) return false;

  const container = document.body.querySelector(containerSelector);

  if (!container) return false;

  const selectPosition = el.getBoundingClientRect();
  const containerPosition = container.getBoundingClientRect();

  const availableTopHeight =
    selectPosition.top -
    containerPosition.top +
    (isUpperTop ? 0 : INPUT_HEIGHT);
  const availableBottomHeight =
    containerPosition.bottom - selectPosition.bottom;

  if (!availableTopHeight || !availableBottomHeight) return false;

  const blockElement = blockSelector && el.querySelector(blockSelector);

  if (!blockElement) return false;

  return (
    blockElement.clientHeight > availableBottomHeight &&
    blockElement.clientHeight < availableTopHeight
  );
};
