import React from 'react';

import InputDateRange from '../../../../../../ui/inputDateRange/InputDateRange';
import { IOnChange } from '../../../../../../../types/Analytics';
import { IntlProps } from '../../../../../../../decorators/addTranslation';

interface IGraphSettingDate extends IntlProps {
  id: string;
  formItemClassName: string;
  isDisabled: boolean;
  label: string;
  data:
    | {
        from: string;
        to: string;
      }
    | undefined;
  onChange: IOnChange;
  toggleDatePicker: () => void;
  validationErrors: { [key: string]: string };
  disableButton?: boolean;
  panelPosition: {
    top: number;
  };
}

const DateRenderableField = ({
  id,
  formItemClassName,
  isDisabled,
  data,
  label,
  getTranslate,
  onChange,
  toggleDatePicker,
  validationErrors,
  panelPosition,
}: IGraphSettingDate) => {
  return (
    <div key={id} className={formItemClassName}>
      <InputDateRange
        isDisabled={isDisabled}
        id='graph-details-period-settings'
        label={getTranslate(label)}
        value={{ dateFrom: data?.from || '', dateTo: data?.to || '' }}
        onChange={(value) => {
          onChange(id, { from: value.dateFrom, to: value.dateTo });
        }}
        onDatePickerToggle={toggleDatePicker}
        error={validationErrors['period']}
        disableButton
        customClass='settings-form__date-picker'
        panelPosition={panelPosition}
      />
    </div>
  );
};

export default DateRenderableField;
