import React, { PureComponent, Fragment, ReactNode } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import Panel from 'components/ui/panel';
import FiltersContainer from './FiltersContainer';
import { AnyObject } from 'types/Common';
import { toggleOpenClass } from 'components/quickFiltersContainer/helper';

interface Props extends IntlProps {
  name: string;
  customButtonClass?: string;
  customFilterClass?: string;
  customPanelClass?: string;
  availableValues?: AnyObject;
  resetByTab?: boolean;
  panelTitle?: ReactNode | string;
  checkChanges?: boolean;
  onApply?: () => void;
}

interface State {
  isOpenedFiltersPanel: boolean;
}

class FiltersButton extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isOpenedFiltersPanel: false,
    };
  }

  render() {
    const {
      name,
      customButtonClass,
      customFilterClass,
      availableValues,
      checkChanges,
      onApply,
    } = this.props;
    const { isOpenedFiltersPanel } = this.state;

    return (
      <Fragment>
        <Button
          id='filters'
          status='light'
          onClick={this.togglePanel}
          icon='im-Filter'
          customClass={classNames(
            'button-open-filters ui-button_no-border ui-buttons-group__last-item',
            customButtonClass,
            {
              'ui-button_status-light-active': isOpenedFiltersPanel,
            }
          )}
        />
        <Panel
          id='filters-panel'
          isOpened={isOpenedFiltersPanel}
          customClass='ui-panel_filters'
          excludeFromCloseTrigger={['.button-open-filters']}
          onClose={this.closePanel}
          modern>
          <FiltersContainer
            name={name}
            availableValues={availableValues}
            onClosePanel={this.closePanel}
            checkChangesForApply={checkChanges}
            customClass={customFilterClass}
            onApply={onApply}
          />
        </Panel>
      </Fragment>
    );
  }

  togglePanel = (e): void => {
    e.persist();
    this.setState(
      (state) => {
        return {
          isOpenedFiltersPanel: !state.isOpenedFiltersPanel,
        };
      },
      () => toggleOpenClass('#filters', this.state.isOpenedFiltersPanel)
    );
  };

  closePanel = (e) => {
    this.setState(
      {
        isOpenedFiltersPanel: false,
      },
      () => toggleOpenClass('#filters', false)
    );
  };
}

export default addTranslation(FiltersButton);
