import React from 'react';
import { connect } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import { openModal } from 'actions/modal';
import { StoreProps } from 'store';
import I18nHelpers from 'i18n/I18nHelpers';

interface OwnProps {
  isEdited: boolean;
}

type Props = OwnProps & IntlProps & StoreProps & WithRouterProps;

interface State {
  isFormTouched: boolean;
}

export class LeavingPagePrompt extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { isFormTouched: false };
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.isEdited !== this.props.isEdited) {
      this.setState({ isFormTouched: this.props.isEdited });
    }
  }

  render() {
    return (
      <Prompt
        when={this.state.isFormTouched}
        message={this.handleLeavingPage}
      />
    );
  }

  handleLeavingPage = (location) => {
    const { dispatch, getTranslate } = this.props;

    if (location.pathname === '/login') return true;

    dispatch(
      openModal({
        modalId: 'MafConfirm',
        customClassName: 'maf-confirm-modal',
        needCloseButton: false,
        invertButtons: true,
        content: {
          title: I18nHelpers.getJsx(
            getTranslate('modals.businessDocs.cancel.title')
          ),
          text: I18nHelpers.getJsx(
            getTranslate('modals.businessDocs.cancel.text')
          ),
        },
        callback: (isYes) => {
          if (isYes) {
            this.setState({ isFormTouched: false }, () => {
              this.props.history.push(location.pathname);
            });
          }
        },
      })
    );
    return false;
  };
}

export default withRouter(connect()(addTranslation(LeavingPagePrompt)));
