import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual, isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import DynamicTable from 'components/ui/table/DynamicTable';

import { StoreProps } from 'store';
import { getData, saveDataState, changeDataForceUpdate } from 'actions/getData';
import getFiltersValues from 'selectors/getFiltersValues';
import path from 'helpers/path';
import {
  prepareDataForState,
  foundQuickFilters,
  getPathUrl,
} from 'helpers/paymentsPayoutsRequest';
import MetricService from 'helpers/metricService/MetricService';
import { ActionKeys } from 'helpers/metricService/metricTypes';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import {
  QuickFiltersType,
  QuickPayoutsFiltersType,
  QuickReportsFiltersType,
} from 'types/QuickFilters';
import { AnyObject } from 'types/Common';
import { WithRouterProps } from 'decorators/withRouter';

import Messages from 'constants/rpcTypes';
import CONFIG from 'config';
import { SortingType } from '../../types/sortings';

interface OwnProps {
  tableName: string;
  resizableColumns?: boolean;
  apiRequest: any;
  quickFilter?: string;
  rowRender?: any;
  headerRender?: any;
  customClass?: string;
  updateOnTimezoneChange?: boolean;
  minColumnsWidth?: AnyObject;
  forbidResizeFor?: string[];
  metric?: {
    sendRegistryMetric?: boolean;
    sendCardMetric?: boolean;
  };
}

interface ConnectedProps {
  user: any;
  itemsList: {
    isFetch: boolean;
    isFixed: false;
    items: any[];
    sort: {
      field: string;
      order: SortingType
    };
    totalItems: number;
    forceUpdate?: boolean;
    isFetched?: boolean;
  };
  quickFilters:
    | QuickFiltersType
    | QuickPayoutsFiltersType
    | QuickReportsFiltersType;
  searchFilters: any;
  filters: any;
}

type Props = OwnProps &
  ConnectedProps &
  WithPermissions &
  StoreProps &
  WithRouterProps;

interface State {
  offset: number;
}

class DataListContainer extends Component<Props, State> {
  private fetchTimerId;
  private wasInitialFetch;
  private prevRequest;
  private prevTimezone;

  static defaultProps: Readonly<Partial<OwnProps>> = {
    updateOnTimezoneChange: true,
    resizableColumns: true,
    minColumnsWidth: {},
    metric: {
      sendRegistryMetric: true,
      sendCardMetric: true,
    },
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      offset: 0,
    };

    this.fetchTimerId = null;
    this.wasInitialFetch = false;
  }

  async componentDidMount() {
    if (this.props.itemsList.items.length === 0) {
      await this.fetchItems();
    }
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ) {
    const {
      tableName,
      quickFilters,
      filters,
      user,
      searchFilters,
      itemsList,
      updateOnTimezoneChange,
      dispatch,
    } = this.props;

    if (
      prevProps.quickFilter === this.props.quickFilter &&
      !isEqual(prevProps.quickFilters, quickFilters)
    ) {
      return this.fetchItems();
    }
    if (
      prevProps.tableName !== this.props.tableName &&
      !this.props.itemsList.isFetched
    ) {
      this.wasInitialFetch = false;
      this.prevRequest = undefined;
      return this.fetchItems();
    }

    if (!isEqual(prevProps.searchFilters, searchFilters)) {
      return this.fetchItems();
    }

    if (!isEqual(prevProps.filters, filters)) {
      return this.fetchItems();
    }

    if (!isEqual(prevProps.itemsList.sort, itemsList.sort)) {
      return this.fetchItems();
    }

    if (
      user &&
      prevProps.user.timezone !== user.timezone &&
      updateOnTimezoneChange
    ) {
      return this.fetchItems();
    }

    if (!prevProps.itemsList.forceUpdate && itemsList.forceUpdate) {
      await this.fetchItems();
      return dispatch(changeDataForceUpdate(tableName, false));
    }
  }

  render() {
    const {
      itemsList: { isFetch, isFetched, items, totalItems },
      tableName,
      rowRender,
      headerRender,
      customClass,
      resizableColumns,
      minColumnsWidth,
      forbidResizeFor,
      metric,
    } = this.props;
    const { offset } = this.state;
    const thereAreItems = isFetched ? !!items.length : resizableColumns!;

    return (
      <DynamicTable
        clickToItem={this.goToItem}
        isFetch={isFetch}
        resizableColumns={resizableColumns! && thereAreItems}
        forbidResizeFor={forbidResizeFor}
        items={items}
        rowRender={rowRender}
        headerRender={headerRender}
        loadNextPage={this.fetchItems}
        offset={offset}
        totalItems={totalItems}
        // @ts-ignore TODO: t.melashchenko, выручай!
        tableName={tableName}
        minColumnsWidth={minColumnsWidth}
        canViewItem={this.canViewItem()}
        customClass={customClass}
        sendMetric={metric?.sendRegistryMetric}
      />
    );
  }

  componentWillUnmount(): void {
    const { dispatch, tableName } = this.props;
    dispatch(saveDataState(tableName, this.state));
  }

  canViewItem = (): boolean => {
    const { tableName, isEnabled } = this.props;
    if (['payments', 'payouts'].includes(tableName)) {
      return isEnabled(Messages.Payments_Payment);
    }
    return true;
  };

  goToItem = (data) => {
    const { tableName, metric, history } = this.props;
    if (this.canViewItem()) {
      const pathUrl = getPathUrl(tableName, data);
      if (pathUrl) {
        history.push(path(pathUrl));
      }
    }

    if (metric?.sendCardMetric) {
      this.sendOpeningCardMetric();
    }
  };

  sendOpeningCardMetric = () => {
    const { tableName } = this.props;
    MetricService.send({
      action: 'viewItem',
      actionKey: ActionKeys[`${tableName}.registry.card`],
    });
  };

  fetchItems = (options?: any) => {
    const delay = 1000;
    const { filters, searchFilters, itemsList } = this.props;
    clearTimeout(this.fetchTimerId);

    return new Promise<void>((resolve) => {
      if (
        !itemsList.forceUpdate &&
        this.wasInitialFetch &&
        isEmpty(options) &&
        foundQuickFilters({ filters, searchFilters })
      ) {
        this.fetchTimerId = setTimeout(() => {
          this.fetchItemsStart(resolve, options);
        }, delay);
      } else {
        this.fetchItemsStart(resolve, options);
      }
    });
  };

  private fetchItemsStart = (onResolve: () => void, options?: any) => {
    const {
      dispatch,
      quickFilters,
      filters,
      searchFilters,
      itemsList: { sort, forceUpdate },
      tableName,
      apiRequest,
      user: { timezone },
    } = this.props;
    const baseRequest = {
      pagination: {
        limit: CONFIG.REQUEST_ITEMS_LIMIT,
        offset: !isEmpty(options) && options.offset ? options.offset : 0,
      },
      sort,
      filter: {},
      saveFilter: false,
    };

    const request = prepareDataForState(
      { quickFilters, filters, searchFilters },
      baseRequest,
      { tableName }
    );

    if (
      !forceUpdate &&
      this.prevRequest &&
      isEqual(this.prevRequest, request) &&
      this.prevTimezone === timezone
    )
      return;

    this.prevRequest = JSON.parse(JSON.stringify(request));
    this.prevTimezone = timezone;

    let isUpload: boolean = false;
    let offset: number = 0;

    if (!isEmpty(options) && options.offset) {
      isUpload = true;
      offset = options.offset;
    }

    this.setState({
      offset,
    });

    dispatch(
      getData({
        apiRequest,
        request,
        onResolve,
        isUpload,
        name: tableName,
      })
    );
    this.wasInitialFetch = true;
  };
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => ({
  user: state.user,
  itemsList: state.data[ownProps.tableName],
  quickFilters: state.quickFilters[ownProps.quickFilter || ownProps.tableName],
  searchFilters: state.searchFilters,
  filters: getFiltersValues(state, ownProps.tableName),
});

const DataListContainer_ = connect(mapStateToProps)(
  addPermissions(DataListContainer)
);
export default withRouter(DataListContainer_);
