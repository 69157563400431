import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import api from 'api/user';
import { updateLanguage } from 'actions/user';
import MenuContext from 'components/ui/menuContext';
import { StoreProps } from 'store';
import Icon from 'components/ui/icon';
import flagList from 'images/flags/flagList';
import LocalStorage from 'helpers/LocalStorage';
import checkFilters from 'helpers/checkFilters';
import { Dictionary } from 'types/FilterValue';
import publicRoutes from 'config/publicRoutes';
import './changeLang.scss';
import * as objectorarray from 'objectorarray';

interface OwnProps {
  customClass?: string;
  openPosition?: string;
}

interface ConnectedProps {
  userLang: string;
  user: any;
  availableLanguages: Dictionary;
  hideLocalizations: objectorarray;
}

type Props = OwnProps &
  IntlProps &
  StoreProps &
  ConnectedProps &
  RouteComponentProps;

const unAuthorizedLanguages = [
  { id: 'en', text: 'ENG' },
  { id: 'ru', text: 'RUS' },
];

class ChangeLang extends PureComponent<Props> {
  componentDidMount() {
    const isAuthorizedLocation = !publicRoutes.some((item) =>
      this.props.match.path.includes(item.path || '')
    );
    if (this.props.user?.id && isAuthorizedLocation) {
      checkFilters(['interfaceLanguage']);
    }
  }

  render() {
    const { customClass, userLang, getTranslate, openPosition } = this.props;
    const translateById = getTranslate(`header.languages.${userLang}`);

    const value = {
      id: userLang,
      buttonContent: (
        <div className='change-lang__item change-lang__item_btn'>
          <span className='change-lang__text'>{translateById}</span>
          <Icon name='im-Arrow-down' size={9} />
        </div>
      ),
    };

    return (
      <div className='change-lang'>
        <MenuContext
          name='lang'
          list={this.getList()}
          value={value}
          customClass={customClass}
          openPosition={openPosition}
        />
      </div>
    );
  }

  async changeLang({ id }) {
    const { dispatch, user } = this.props;
    if (user?.id) {
      await api.updateUserLanguage(id);
    } else {
      dispatch(updateLanguage({ interfaceLang: id }));
    }
    LocalStorage.set('noAuthLang', id);
    document.documentElement.setAttribute('lang', id);
  }

  createItem = (id, icon, title) => {
    const { userLang } = this.props;
    return (
      <div
        className={classNames('change-lang__item', {
          'change-lang_active': id === userLang,
        })}>
        {icon ? (
          <img className='change-lang__icon' src={icon} alt={userLang} />
        ) : (
          <span className='change-lang__icon'>
            <Icon name='im-Info' size={11} />
          </span>
        )}

        <span className='change-lang__text'>{title}</span>
        <span className='change-lang__check-icon'>
          <Icon name='im-Check-mark' size={6} />
        </span>
      </div>
    );
  };

  getList = () => {
    const {
      user,
      userLang,
      availableLanguages,
      getTranslate,
      hideLocalizations,
    } = this.props;
    const list = user?.id
      ? availableLanguages?.list || []
      : unAuthorizedLanguages;

    return list
      .filter((item) => hideLocalizations.indexOf(item.id) < 0)
      .map((item) => {
        return {
          id: item.id,
          active: item.id === userLang,
          content: this.createItem(
            item.id,
            flagList[item.id],
            getTranslate(`header.languages.${item.id}`)
          ),
          onClick: (itemId) => this.changeLang(itemId),
        };
      });
  };
}

const setStateToProps = (state): ConnectedProps => {
  const userLang =
    state.user?.interfaceLang || LocalStorage.get('noAuthLang') || 'en';
  return {
    userLang,
    user: state.user,
    availableLanguages: state.filtersValues?.interfaceLanguage,
    hideLocalizations: state.settings?.hideConfig?.localization || [],
  };
};

export default withRouter(connect(setStateToProps)(addTranslation(ChangeLang)));
