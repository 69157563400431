import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import CustomScroll from 'components/ui/customScrollbar';
import Input from 'components/ui/input';
import InputAmount from 'components/ui/inputAmount';
import Button from 'components/ui/button';
import ButtonsGroup from 'components/ui/buttonsGroup';
import SelectionList from 'components/ui/selectionList';
import CustomSelect from 'components/ui/customSelect';
import InputList from 'components/ui/inputList';

import Utils from 'helpers/Utils';
import { FilterTypes } from 'constants/FilterTypes';
import { AnyObject } from 'types/Common';
import './builderFilters.scss';

interface Props extends IntlProps {
  fields: AnyObject;
  filters: AnyObject;
  isFilters: boolean;
  validationErrors: AnyObject;
  onChangeField: (key: string, value: any, filtersType: string) => void;
}

class BuilderFilters extends PureComponent<Props> {
  render() {
    const { isFilters, getTranslate } = this.props;

    return (
      <div className='templates-builder-filters card'>
        <div className='card__header'>
          <div className='card__title'>
            {getTranslate('reports.new.filters.label')}
          </div>
        </div>

        <div className='templates-builder-filters__block-section card__content'>
          {isFilters ? this.renderFilters() : this.renderInfoText()}
        </div>
      </div>
    );
  }

  renderInfoText() {
    const {
      fields: { reportType },
      getTranslate,
    } = this.props;

    return (
      <div className='templates-builder-filters__info-text'>
        {getTranslate(`reports.filters.${reportType}.text`)}
      </div>
    );
  }

  renderFilters() {
    const { fields, filters, getTranslate } = this.props;

    return (
      <CustomScroll>
        <div className='templates-builder-filters__block-section-inner'>
          <div className='utils-ui-block__content templates-builder__block-content_bordered'>
            <div className='templates-builder-filters__block-section'>
              <div className='utils-ui-block__row grid-row'>
                <CustomSelect
                  id='csvDelimiter'
                  options={fields.csvDelimiter.items}
                  value={{
                    value: fields.csvDelimiter.value,
                    label: fields.csvDelimiter.value,
                  }}
                  isRow={true}
                  label={getTranslate('reports.new.delimiter.label')}
                  onChange={({ value }) =>
                    this.changeSelect('csvDelimiter', value)
                  }
                  customClass='ui-select_large'
                />
              </div>
            </div>
          </div>

          <div className='utils-ui-block__content'>
            {Object.keys(filters).map((filter) => {
              filters[filter].id = filter;
              return (
                <div className='utils-ui-block__row grid-row' key={filter}>
                  {this.renderFilter(filters[filter])}
                </div>
              );
            })}
          </div>
        </div>
      </CustomScroll>
    );
  }

  renderFilter = (data) => {
    const { fields, onChangeField, getTranslate } = this.props;
    const { id, type, labelKey, placeholderKey, tooltip } = data;
    const isRow = true;
    const reportType = fields.reportType;
    const fieldsByType = fields[reportType];
    if (!Utils.hasProp(fieldsByType, id)) {
      console.error(`field "${id}" doesn't exist`);
      return null;
    }

    const allFiltersErrors = this.getErrors();
    const error = allFiltersErrors[id]
      ? getTranslate(allFiltersErrors[id])
      : '';

    switch (type) {
      case FilterTypes.text:
        return (
          <Input
            key={id}
            id={id}
            value={fieldsByType[id]}
            isRow={isRow}
            label={labelKey}
            placeholder={placeholderKey}
            onChange={(e) => onChangeField(id, e.target.value, reportType)}
            error={error}
          />
        );
      case FilterTypes.amount:
        return (
          <>
            <div className='templates-builder__label' id={id}>
              {getTranslate('payments.data.amount')}
            </div>
            <div className='templates-builder__row-inner grid-row'>
              <span className='templates-builder__label'>
                {getTranslate('payments.data.amountFrom.label')}
              </span>
              <InputAmount
                key={`${id}-from`}
                id={`${id}-from`}
                value={fieldsByType.amount.amountFrom}
                onChange={(e) => {
                  onChangeField(
                    id,
                    {
                      ...fieldsByType.amount,
                      amountFrom: e.target.value,
                    },
                    reportType
                  );
                }}
                placeholder='filters.fields.paymentAmountFrom.placeholder'
                error={error}
              />
              <span className='templates-builder__label'>
                {getTranslate('payments.data.amountTo.label')}
              </span>
              <InputAmount
                key={`${id}-to`}
                id={`${id}-to`}
                value={fieldsByType.amount.amountTo}
                onChange={(e) => {
                  onChangeField(
                    id,
                    {
                      ...fieldsByType.amount,
                      amountTo: e.target.value,
                    },
                    reportType
                  );
                }}
                placeholder='filters.fields.paymentAmountTo.placeholder'
                error={error}
              />
            </div>
          </>
        );

      case FilterTypes.numbers:
        return (
          <Input
            key={id}
            id={id}
            isRow={isRow}
            value={fieldsByType[id]}
            onChange={(e) => onChangeField(id, e.target.value, reportType)}
            label={labelKey}
            placeholder={placeholderKey}
            cleaveOptions={{ numericOnly: true }}
            error={error}
          />
        );
      case FilterTypes.multiSelect:
        return (
          <SelectionList
            key={id}
            id={id}
            isRow={isRow}
            items={fieldsByType[id]}
            onChange={(items) => onChangeField(id, items, reportType)}
            label={labelKey}
            placeholder={placeholderKey}
            error={error}
            tooltip={getTranslate(tooltip)}
          />
        );
      case FilterTypes.select:
        return (
          <CustomSelect
            key={id}
            id={id}
            isRow={true}
            isSearchable={true}
            options={fieldsByType[id].items}
            value={fieldsByType[id].value}
            onChange={(value) => this.changeSelect(id, value, reportType)}
            label={labelKey}
            placeholder={placeholderKey}
            error={error}
            customClass='ui-select_large'
            tooltip={getTranslate(tooltip)}
          />
        );
      case FilterTypes.buttonsList:
        return (
          <ButtonsGroup
            id={id}
            label={labelKey}
            activeButtons={fieldsByType[id]
              .filter(({ isSelected }) => isSelected)
              .map(({ id: buttonId }) => buttonId)}
            mode='multi'
            className='ui-buttons-group_separate ui-buttons-group_row templates-builder__field'
            onClick={(values) => this.changeTypes(id, values)}
            theme='dark'>
            {fieldsByType[id].map(({ id: buttonId, text }) => {
              return (
                <Button
                  key={buttonId}
                  id={buttonId}
                  text={text}
                  customClass='templates-builder-filters__type-item'
                  status='outline'
                />
              );
            })}
          </ButtonsGroup>
        );
      case FilterTypes.multiText:
        return (
          <InputList
            id={id}
            label={labelKey}
            placeholder={placeholderKey}
            items={fieldsByType[id]}
            onChange={(items) => {
              onChangeField(id, items, reportType);
            }}
            error={error}
          />
        );
      case FilterTypes.multiTextNumbers:
        return (
          <InputList
            id={id}
            label={labelKey}
            placeholder={placeholderKey}
            items={fieldsByType[id]}
            onChange={(items) => {
              onChangeField(id, items, reportType);
            }}
            numbersOnly={true}
            error={error}
          />
        );
      default:
        return null;
    }
  };

  getErrors = () => {
    const { validationErrors } = this.props;
    return validationErrors?.template?.filter || {};
  };

  changeTypes = (id, values) => {
    const { onChangeField, fields } = this.props;
    const result = fields[fields.reportType][id].map((item) => {
      for (const value of values) {
        if (item.id === value) {
          return {
            ...item,
            isSelected: true,
          };
        }
      }
      return {
        ...item,
        isSelected: false,
      };
    });

    onChangeField(id, result, fields.reportType);
  };

  changeSelect = (id, value, reportType?) => {
    const { onChangeField, fields } = this.props;
    if (reportType) {
      onChangeField(
        id,
        { items: [...fields[reportType][id].items], value },
        reportType
      );
    } else {
      onChangeField(id, { items: [...fields[id].items], value }, reportType);
    }
  };
}

export default addTranslation(BuilderFilters);
