import React, { PureComponent, ReactNode } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import ApsIcon from 'components/ui/apsIcon';
import LabelStatus from 'components/ui/labelStatus';
import DataBlockRow from './DataBlockRow';
import Utils from 'helpers/Utils';
import DateHelpers from 'helpers/Date';
import Mapper from 'helpers/Mapper';
import types from './types';
import './dataBlock.scss';
import Link from '../link';

interface DataItem {
  key: string;
  value: any;
  type?: string;
  isVisible?: boolean;
}

interface Props extends IntlProps {
  title?: string;
  data: DataItem[];
  followLink?: (e, value) => void;
  customClass?: string;
  theme?: string;
}

class DataBlock extends PureComponent<Props> {
  render() {
    const { title, data, customClass, theme, getTranslate } = this.props;

    if (!Array.isArray(data)) {
      console.error(`Data is not an array! Data: ${data}`);
      return null;
    }

    // Check if any elements have a data. If no, we hide ALL block with Title
    const isData = data.filter(
      (dataElement) => dataElement.value !== undefined
    );

    if (isData.length === 0) {
      return null;
    }

    return (
      <div
        className={classNames('ui-data-block', {
          [`${customClass}`]: !!customClass,
          [`ui-data-block_${theme}`]: !!theme,
        })}>
        {title && (
          <div className='ui-data-block__title'>{getTranslate(title)}</div>
        )}
        {isData.map(({ key, value, type, isVisible = true }, index) => {
          if (!isVisible) return null;
          return (
            <DataBlockRow
              key={index}
              lcKey={key}
              value={this.getFormattedValue(type, key, value)}
            />
          );
        })}
      </div>
    );
  }

  getFormattedValue = (
    type: string | undefined,
    key: string,
    value: any
  ): string | ReactNode => {
    if (value === null || value === undefined || value === '') {
      return '—';
    }

    const { getTranslate } = this.props;

    switch (type) {
      case types.date: {
        const date = DateHelpers.createUtcDate(value);
        return DateHelpers.getFormat(date, 'date');
      }
      case types.datetime: {
        const datetime = DateHelpers.createUtcDate(value);
        return DateHelpers.getFormat(datetime);
      }
      case types.currency: {
        const currencyChunks = value.split('.');
        return [
          Utils.getNumberWithSpace(currencyChunks[0]),
          currencyChunks[1],
        ].join('.');
      }
      case types.card:
        return Utils.getNumberWithSpace(value, 4);
      case types.image:
        return (
          <div className='ui-data-block__value-image'>
            <ApsIcon
              iconUrl={value.iconUrl}
              paymentType={value.paymentMethodType}
              customClass='aps-icon_inline'
              showTooltip={true}
            />
          </div>
        );
      case types.status:
        return (
          <LabelStatus
            text={getTranslate(value)}
            status={Mapper.getStatus(value)}
          />
        );
      case types.link: {
        const { followLink } = this.props;
        if (value.url) {
          return (
            <Link
              className='ui-link'
              url={value.url}
              onClick={(e) => followLink && followLink(e, value)}>
              {value.name}
            </Link>
          );
        }
        return value.name;
      }
      default:
        return value;
    }
  };
}

export default addTranslation(DataBlock);
