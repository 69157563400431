import { BoardingStage } from 'types/BoardingStage';
import { StatusTag } from '../BusinessDocumentsFormsList/components/StatusTag';
import { IntlProps, addTranslation } from 'decorators/addTranslation';
import './TopPageInformer.scss';
import I18nHelpers from 'i18n/I18nHelpers';

const statusToLabelMap: { [k in BoardingStage]?: string } = {
  daf_review: 'businessdocs.DAF.informer.inReview.label',
  accepted: 'businessdocs.DAF.informer.accepted.label',
  decline: 'businessdocs.DAF.informer.declined.label',
};

const statusToTextMap: { [k in BoardingStage]?: string } = {
  daf_review: 'businessdocs.DAF.informer.inReview.text',
  accepted: 'businessdocs.DAF.informer.accepted.text',
  decline: 'businessdocs.DAF.informer.declined.text',
};

export const TopPageInfromer = addTranslation(
  ({ status, getTranslate }: { status: BoardingStage } & IntlProps) => (
    <div className='maf-status-informer'>
      <div className='maf-status-informer__status-tag'>
        <StatusTag status={status} wasMafUpdated />
      </div>
      <div className='maf-status-informer__description'>
        <div className='maf-status-informer__description-label'>
          {getTranslate(statusToLabelMap[status])}
        </div>
        <div className='maf-status-informer__description-content'>
          {I18nHelpers.getJsx(getTranslate(statusToTextMap[status]))}
        </div>
      </div>
    </div>
  )
);
