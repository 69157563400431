import BaseMessageHandler from '../BaseMessageHandler';
import { addArticle } from 'actions/help';

export default class Article_Create extends BaseMessageHandler {
  default = () => {
    const {
      dispatch,
      message: { payload },
    } = this;

    if (payload.validationErrors) return;
    dispatch(addArticle({ payload }));
  };
}
