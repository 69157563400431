import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/ru';
import momentTimezone from 'moment-timezone';
import dateFormats, { periodTypes } from 'constants/dateFormats';

type dateFormatType = keyof typeof dateFormats;

class DateHelpers {
  /**
   *
   * @param locale
   */
  static setLocale(locale) {
    moment.locale(locale);
  }

  /**
   * Получить текущую текущую дату
   */
  static getDate() {
    return moment();
  }

  /**
   * Получить дату с таймзоной
   * @param timezone
   * @param date
   * @param format
   */
  static getDateWithTimezone(timezone, date?, format?: dateFormatType) {
    if (date) {
      const newDate = DateHelpers.createDate(date, format).format(
        dateFormats.noTZCommon
      );
      return momentTimezone.tz(newDate, timezone);
    }
    return momentTimezone().tz(timezone);
  }

  /**
   * Конвертировать таймзону даты
   * @param date
   * @param prevTimezone
   * @param newTimeZone
   */
  static convertTimeZone(date, prevTimezone, newTimeZone) {
    const prevTZDate = DateHelpers.getDateWithTimezone(
      prevTimezone,
      date,
      'datetime'
    );
    return DateHelpers.getFormat(
      prevTZDate.clone().tz(newTimeZone),
      'datetime'
    );
  }

  /**
   * Создать дату из переданного параметра date
   * Особое внимание на параметр format.
   * Это НЕ формат в котором будет создана дата, а формат параметра date для парсинга моментом,
   * передается только, если отличается от Date и timestamp.
   * Работает без формата - createDate('2019-11-05T12:01:09')
   * Требуется явное указание формата - createDate('14.10.2019', 'date')
   * @param date
   * @param format
   */
  static createDate(date, format?: dateFormatType | string) {
    return moment(date, (format && dateFormats[format]) || format);
  }

  static createDateFromTs(timestamp) {
    return moment.unix(timestamp);
  }

  /**
   * Следует использовать для работы с датами, в которых есть таймзоны,
   * но они УЖЕ учтены.
   * Приходит 2019-11-05T12:01:09+03:00, хотим получить 2019-11-05 12:01:09
   * @param date -
   * @param format
   */
  static createUtcDate(date, format?: dateFormatType) {
    return moment(date, format && dateFormats[format]).utcOffset(date);
  }

  /**
   * @param date - Date obj
   * */
  static getUtcDate(date) {
    return moment(date).utc();
  }

  /**
   * Преобразовывает дату в один из форматов dateFormats
   * В большинстве случаев используется для конечного вывода в интерфейсе
   * @param momentDate
   * @param format
   */
  static getFormat(momentDate, format?: dateFormatType): string {
    if (!momentDate) return '';
    return momentDate.format(
      format ? dateFormats[format] : dateFormats.datetime
    );
  }

  /**
   *
   * @param date
   * @param value
   * @param measure
   */
  static addTo(date, value, measure) {
    return date.add(value, measure);
  }

  /**
   *
   * @param value
   * @param measure
   */
  static add(value, measure) {
    return moment().add(value, measure);
  }

  /**
   * Получить текущую дату + minutes
   * @param minutes - сколько минут прибавить к текущей дате
   */
  static addMinutes(minutes) {
    return moment().add(minutes, 'minutes');
  }

  /**
   * Получить текущую дату + days
   * @param days - сколько дней прибавить к текущей дате
   */
  static addDays(days) {
    return moment().add(days, 'days');
  }

  /**
   * Получить разницу между датами в мс
   * @param startDate
   * @param endDate
   */
  static getDuration(startDate, endDate) {
    return moment.duration(startDate.diff(endDate));
  }

  /**
   * Получить текущую дату + years
   * @param years - сколько лет прибавить к текущей дате
   */
  static addYears(years) {
    return moment().add(years, 'years');
  }

  /**
   * Получить текущую дату - days
   * @param days - сколько дней убавить от текущей дате
   */
  static subtractDays(days) {
    return moment().subtract(days, 'days');
  }

  /**
   *
   * @param date
   * @param value
   * @param measure
   */
  static subtractFrom(date, value, measure) {
    return date.subtract(value, measure);
  }

  /**
   * Начало дня 00-00-00
   * @param momentDate
   * @returns {*}
   */
  static getStartDay(momentDate) {
    return momentDate.startOf('day');
  }

  /**
   * Конец дня 23-59-59
   * @param momentDate
   * @returns {*}
   */
  static getEndDay(momentDate) {
    return momentDate.endOf('day');
  }

  /**
   *
   * @param date
   */
  static getTimeFromDate(date) {
    return moment(date).format(dateFormats.time);
  }

  /**
   *
   * @param date
   */
  static getYear(date): number {
    return moment(date).year();
  }

  /**
   *
   * @param date
   */
  static getMonth(date): number {
    return moment(date).month();
  }
  /**
   *
   * @param date
   */
  static getDay(date): number {
    return moment(date).day();
  }

  /**
   *
   * @param unit - days, weeks, etc.
   * @param firstDate
   * @param secondDate
   * @param format
   */
  static getDiff(unit, firstDate, secondDate, format?): number {
    const momentDate1 = DateHelpers.createDate(firstDate, format).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const momentDate2 = DateHelpers.createDate(secondDate, format).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    return Number(momentDate2.diff(momentDate1, unit));
  }

  /**
   *
   * @param from
   * @param to
   */
  static getRangeWithDefaultTime({ from, to }) {
    return {
      from: from
        ? DateHelpers.getStartDay(
            DateHelpers.createDate(from, 'datetime')
          ).toDate()
        : null,
      to: to
        ? DateHelpers.getEndDay(DateHelpers.createDate(to, 'datetime')).toDate()
        : null,
    };
  }

  /**
   * Валидатор времени.
   * Принимает строку в виде HH:mm:ss, корректирует и возвращает в таком же формате
   * @param time
   * @param startDay
   */
  static validateTime(time: string, startDay: boolean): string {
    const splitTime: Array<string> = time.split(':');
    if (splitTime.length !== 3) {
      return startDay ? '00:00:00' : '23:59:59';
    }

    let hours: string = splitTime[0];
    const hoursInt: number = parseInt(hours);
    if (isNaN(hoursInt) || hoursInt < 0 || hoursInt > 23) {
      hours = startDay ? '00' : '23';
    } else if (hoursInt < 10) {
      hours = '0' + hoursInt;
    }

    let minutes: string = splitTime[1];
    const minutesInt: number = parseInt(minutes);
    if (isNaN(minutesInt) || minutesInt < 0 || minutesInt > 59) {
      minutes = startDay ? '00' : '59';
    } else if (minutesInt < 10) {
      minutes = '0' + minutesInt;
    }

    let seconds: string = splitTime[2];
    const secondsInt: number = parseInt(seconds);
    if (isNaN(secondsInt) || secondsInt < 0 || secondsInt > 59) {
      seconds = startDay ? '00' : '59';
    } else if (secondsInt < 10) {
      seconds = '0' + secondsInt;
    }

    return hours + ':' + minutes + ':' + seconds;
  }

  /**
   * Добавляет время в формате HH:mm:ss к переданной дате
   * @param date
   * @param time
   */
  static setTime(date: Date, time): Date {
    const splitTime = time.split(':');
    const momentDate = DateHelpers.createDate(date);

    momentDate.set({
      hour: splitTime[0],
      minute: splitTime[1],
      second: splitTime[2],
      milliseconds: 0,
    });

    return momentDate.toDate();
  }

  /**
   *
   */
  static getMonths() {
    return moment.months(moment.locale());
  }

  static getLastPeriod(measure, iso, format, timezone) {
    const lastDate = () =>
      DateHelpers.subtractFrom(
        DateHelpers.getDateWithTimezone(timezone),
        1,
        measure
      );
    const startDate = lastDate().startOf(iso);
    const endDate = lastDate().endOf(iso);
    return [
      DateHelpers.getFormat(startDate, format),
      DateHelpers.getFormat(endDate, format),
    ];
  }

  /**
   *
   * @param period
   * @param timezone
   * @param format
   */
  static getPeriod(
    period: periodTypes,
    timezone: string,
    format: keyof typeof dateFormats = 'datetime'
  ): string[] {
    const now = () => DateHelpers.getDateWithTimezone(timezone);

    switch (period) {
      case periodTypes.Today: {
        const currentDate = now();
        const startDay = DateHelpers.getStartDay(now());

        return [
          DateHelpers.getFormat(startDay, format),
          DateHelpers.getFormat(currentDate, format),
        ];
      }
      case periodTypes.TodayFullDay: {
        const startDay = DateHelpers.getStartDay(now());
        const endDay = DateHelpers.getEndDay(now());

        return [
          DateHelpers.getFormat(startDay, format),
          DateHelpers.getFormat(endDay, format),
        ];
      }
      case periodTypes.Yesterday:
        return DateHelpers.getLastPeriod('days', 'day', format, timezone);
      case periodTypes.LastWeek:
        return DateHelpers.getLastPeriod('weeks', 'isoWeek', format, timezone);
      case periodTypes.LastMonth:
        return DateHelpers.getLastPeriod('months', 'month', format, timezone);
      case periodTypes.LastQuarter:
        return DateHelpers.getLastPeriod(
          'quarters',
          'quarter',
          format,
          timezone
        );
      case periodTypes.LastYear:
        return DateHelpers.getLastPeriod('years', 'year', format, timezone);
      default:
        console.warn(`period ${period} not supported!`);
        return [];
    }
  }

  /**
   *
   * @param milliseconds
   */
  static convertMS(milliseconds) {
    let seconds = Math.floor((milliseconds || 0) / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let hour = Math.floor(minutes / 60);
    minutes = minutes % 60;
    const days = Math.floor(hour / 24);
    hour = hour % 24;

    return {
      days,
      hour,
      minutes,
      seconds,
    };
  }

  static checkDateInPeriod(period, date, format) {
    const momentDate = DateHelpers.createDate(date, format);
    const max = moment
      .max([DateHelpers.createDate(period[0], format), momentDate])
      .format(format);
    const min = moment
      .min([DateHelpers.createDate(period[1], format), momentDate])
      .format(format);

    return max === date && min === date;
  }

  static checkRangeIsPeriod({ range, timezone }) {
    let result;

    for (const key in periodTypes) {
      const period = DateHelpers.getPeriod(periodTypes[key], timezone);

      if (range[0] === period[0] && range[1] === period[1]) {
        result = periodTypes[key];
      }
    }

    return result;
  }

  static getMaxDate(date1, date2, format: dateFormatType = 'datetime') {
    const firstDate = DateHelpers.createDate(date1, format);
    const secondDate = DateHelpers.createDate(date2, format);
    return DateHelpers.getFormat(moment.max([firstDate, secondDate]), format);
  }

  static getDefaultMinDate(format?) {
    const MIN_YEAR = DateHelpers.getDate().year() - 2;

    const date = DateHelpers.getDate();

    const currentMonth = date.month();

    const minDate = date.year(MIN_YEAR).month(currentMonth).date(1);
    const startDay = DateHelpers.getStartDay(minDate);
    return DateHelpers.getFormat(startDay, format || 'datetime');
  }

  static formatMessageDate(msg, prevMessageDate, isFirst) {
    const messageDate = DateHelpers.createDateFromTs(msg.createdAtTs);
    const messageDateFormatted = messageDate.format(dateFormats.supportChatMsg);
    msg.firstOfDate = isFirst || !prevMessageDate.isSame(messageDate, 'day');

    msg.isAnotherYear = !prevMessageDate.isSame(messageDate, 'year');

    const isTodayStart =
      (msg.firstOfDate || isFirst) &&
      DateHelpers.getDate().isSame(messageDate, 'day');

    const isYesterdayStart =
      (msg.firstOfDate || isFirst) &&
      DateHelpers.getDate().subtract(1, 'day').isSame(messageDate, 'day');

    const [date, time] = messageDateFormatted.split(' ');
    const [day, month, year] = date.split('.');

    const msgDate = () => {
      if (isTodayStart) return 'dates.today';
      if (isYesterdayStart) return 'dates.yesterday';
      return `${month} ${day}`;
    };

    msg.displayDate = {
      date: msgDate(),
      time,
      year,
    };

    return msg;
  }

  static formatDateForTableCell(date: Date) {
    // table date format is "DD.MM.YYYY\nHH.mm.ss"
    return `${moment(date).format('DD.MM.YYYY')}\n${moment(date).format(
      'HH:mm:ss'
    )}`;
  }
}

export default DateHelpers;
