import BaseMessageHandler from '../BaseMessageHandler';
import { updateDataItem } from 'actions/getData';
import Repository from 'helpers/Repository';
import tableNames from 'constants/tableNames';

export default class PaymentOperation_StatusUpdate extends BaseMessageHandler {
  success = () => {
    const {
      dispatch,
      message: { payload },
    } = this;

    const store = Repository.get('store');
    const {
      data: { payouts },
    } = store.getState();

    if (payouts.isFetched) {
      const { paymentId, transactionId, paymentStatus } = payload;
      dispatch(
        updateDataItem(tableNames.payouts, 'paymentId', {
          paymentId,
          transactionId,
          paymentStatus,
        })
      );
    }
  };
}
