import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';

export const saveUI = (name: string, value: any) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.SAVE_UI,
        payload: {
          name,
          value,
        },
      })
    );
  };
};
