import React, { ReactNode } from 'react';
import { toast } from 'react-toastify';
import Notification from './index';

interface Props {
  status?: 'success' | 'error' | 'info' | 'warn';
  content: string | ReactNode;
}

export const toastIdsStore: Array<string | number> = [];

const showNotification = ({ status = 'success', content }: Props) => {
  if (toastIdsStore.length >= 5) {
    toast.dismiss(toastIdsStore[0]);
    toastIdsStore.shift();
  }

  toastIdsStore.push(
    toast[status](<Notification content={content} />, {
      autoClose: status === 'success' ? 6000 : false,
    })
  );
};

export default showNotification;
