import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import ErrorBoundary from './ErrorBoundary';

interface ConnectedProps {
  user: any;
}

type Props = PropsWithChildren<ConnectedProps>;

interface State {
  error: any;
  setConfig: boolean;
}

class ErrorBoundaryContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      setConfig: false,
    };
  }

  componentDidUpdate() {
    const { user } = this.props;
    const { setConfig } = this.state;

    if (user && !setConfig) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: user.id,
          email: user.email,
        });
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
    });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
    });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <ErrorBoundary error={error} />;
    }
    return children;
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
});

export default connect(mapStateToProps)(ErrorBoundaryContainer);
