import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

const initialState: any = {
  isFetched: false,
  articles: [],
};

const help = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_HELP_ARTICLES:
      return {
        ...state,
        articles: payload.articles.sort(
          (a, b) => a.sortMenuIndex - b.sortMenuIndex
        ),
        isFetched: true,
      };
    case ACTIONS.UPDATE_HELP_ARTICLES:
      return {
        ...state,
        ...payload,
      };
    case ACTIONS.ADD_HELP_ARTICLE:
      if (payload.interfaceLang !== payload.article.language) return state;
      return { ...state, articles: [...state.articles, payload.article] };
    case ACTIONS.UPDATE_HELP_ARTICLE: {
      if (payload.interfaceLang !== payload.article.language) {
        const newArticles = state.articles.filter(
          (article) => article.articleId !== payload.article.articleId
        );
        return { ...state, articles: newArticles };
      }

      const newArticles = state.articles.map((article) => {
        if (article.articleId === payload.article.articleId) {
          return { ...article, ...payload.article, isFetched: true };
        }
        return article;
      });
      return { ...state, articles: newArticles };
    }
    case ACTIONS.DELETE_HELP_ARTICLE:
      return {
        ...state,
        articles: state.articles.filter(
          (article) => article.articleId !== payload.articleId
        ),
      };
    case ACTIONS.USER_CHANGE_LANGUAGE:
    case ACTIONS.RESET_HELP:
      return initialState;
    default:
      return state;
  }
};

export default help;
