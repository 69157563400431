import BaseMessageHandler from '../BaseMessageHandler';
import { createAction } from 'helpers/redux';
import ACTIONS from 'constants/actionTypes';

export default class Auth_ConfirmSecondFactor extends BaseMessageHandler {
  validation = () => {
    const { dispatch, message } = this;
    const { payload } = message;

    dispatch(
      createAction({
        type: ACTIONS.SET_USER_ERROR,
        payload,
      })
    );
  };
}
