import React, { Component } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import Animation from 'components/ui/animation';
import Panel from 'components/ui/panel';
import DateHelpers from 'helpers/Date';
import TimezoneSelect from './select';
import TimezoneItem from 'types/TimezoneItem';
import './timezone.scss';

interface Props {
  currentTimezone: TimezoneItem;
  timezoneList: TimezoneItem[];
  isOpenedSelect: boolean;
  onUpdateTimezone: (timezone: TimezoneItem) => void;
  onToggleSelectPanel: (isOpened?: boolean) => void;
  customClass?: string;
}

interface State {
  currentTime: any;
}

class Timezone extends Component<Props, State> {
  private timerId;
  private _isMounted;

  constructor(props) {
    super(props);

    this.state = {
      currentTime: this.getTime(),
    };
  }

  componentDidMount(): void {
    this._isMounted = true;
    this.timerId = setInterval(this.updateTime, 1000);
  }

  componentWillUnmount(): void {
    this._isMounted = false;
    clearTimeout(this.timerId);
  }

  render() {
    const {
      currentTimezone,
      timezoneList,
      isOpenedSelect,
      onUpdateTimezone,
      onToggleSelectPanel,
      customClass,
    } = this.props;
    const { currentTime } = this.state;

    return (
      <div className={classNames('timezone', customClass)}>
        <Animation>
          <div
            className={classNames('timezone__inner', {
              timezone__inner_open: isOpenedSelect,
            })}>
            <div
              className='timezone__selected-wrapper'
              onClick={() => onToggleSelectPanel()}>
              <div className='timezone__selected-tz'>
                {currentTimezone.name.replace('_', ' ').replace('/', ' | ')}
              </div>
              <span className='timezone__selected-tz-separator'>&mdash;</span>
              <div className='timezone__selected-tz-offset'>
                ({currentTimezone.offset})
              </div>
              <div className='timezone__current-time'>{currentTime}</div>
              <Icon
                size={9}
                name='im-Arrow-down'
                className={classNames('timezone__selected-tz-arrow', {
                  'timezone__selected-tz-arrow_revert': isOpenedSelect,
                })}
              />
            </div>
          </div>
          <Panel
            id='timezone-panel'
            isOpened={isOpenedSelect}
            renderCloseControl={false}
            excludeFromCloseTrigger={['.timezone__selected-wrapper']}
            customClass='ui-panel_timezone-select'
            onClose={() => onToggleSelectPanel(false)}>
            <TimezoneSelect
              currentTimezone={currentTimezone}
              timezoneList={timezoneList}
              onUpdateTimezone={onUpdateTimezone}
            />
          </Panel>
        </Animation>
      </div>
    );
  }

  updateTime = () => {
    if (this._isMounted) {
      this.setState({
        currentTime: this.getTime(),
      });
    }
  };

  getTime = (): string => {
    const {
      currentTimezone: { name },
    } = this.props;
    return DateHelpers.getFormat(DateHelpers.getDateWithTimezone(name), 'time');
  };
}

export default Timezone;
