import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { WithRouterProps } from 'decorators/withRouter';
import Icon from 'components/ui/icon';
import Link from 'components/ui/link';
import LiveLogo from 'pages/app/components/liveLogo';
import TimezoneContainer from 'pages/app/components/timezone/TimezoneContainer';
import HelpDeskToggle from 'pages/app/components/helpdeskToggle';
import { UserNotificationsIndicator } from 'pages/app/components/userNotifications';
import ChangeLang from 'components/changeLang';
import Avatar from 'components/ui/avatar';
import Logout from 'components/logout';
import RoundButton from 'components/ui/roundButton';
import landingState from 'constants/landingState';
import Messages from 'constants/rpcTypes';
import RpcTypes from 'constants/rpcTypes';
import { StoreProps } from 'store';
import ThemeConfig from 'types/ThemeConfig';
import { AnyObject } from 'types/Common';
import './header.scss';

interface OwnProps {
  user: AnyObject;
  settings: {
    themeConfig: ThemeConfig;
    wl: string;
  };
  openedBlocks: {
    mainMenu: boolean;
    search: boolean;
  };
  helpdeskOpened: boolean;
  isDemoStage: boolean;
  toggleBlock: (blockName: string) => void;
  toggleHelpDesk: () => void;
  isHelpdeskAvailable: boolean;
  isConfirmLogout: boolean;
  isMobile: boolean;
}

type Props = OwnProps &
  StoreProps &
  IntlProps &
  WithPermissions &
  WithRouterProps;

class Header extends PureComponent<Props> {
  render() {
    const {
      openedBlocks,
      settings,
      user,
      toggleBlock,
      isDemoStage,
      isHelpdeskAvailable,
      helpdeskOpened,
      toggleHelpDesk,
      getTranslate,
      isEnabled,
    } = this.props;

    const showSearch = isEnabled(RpcTypes.Payments_List);

    return (
      <div className='header'>
        <div className='header__inner utils-flex'>
          <div className='header__col header__col_left utils-flex'>
            <RoundButton
              icon='im-Menu'
              size={30}
              iconSize={14}
              customClass='header__icon_menu'
              status={openedBlocks.mainMenu ? 'primary' : 'none'}
              onClick={() => toggleBlock('mainMenu')}
            />
            <TimezoneContainer customClass='header__item' />
            {isEnabled(Messages.Article_Menu) && (
              <Link url='/help' className='header__item header__item_help'>
                <span className='header__text'>
                  {getTranslate('mainMenu.help')}
                </span>
                <Icon
                  name='im-Info'
                  size={this.props.isMobile ? 15 : 12}
                  className='header__help-icon'
                />
              </Link>
            )}

            {isDemoStage && !this.props.isMobile && (
              <LiveLogo customClass='header__item' />
            )}
          </div>
          {settings.themeConfig?.logoUrl && (
            <Link url='/' className='header__logo'>
              <img src={settings.themeConfig.logoUrl} alt='logo' />
            </Link>
          )}
          <div className='header__col utils-flex'>
            <div className='header__item header__item_lang header__item_bordered'>
              <ChangeLang customClass='menu-context_no-hover' />
            </div>
            {user.landingState !== landingState.maf && (
              <div className='header__item header__item_bordered header__item_icons utils-flex'>
                {isHelpdeskAvailable && (
                  <HelpDeskToggle
                    onToggle={toggleHelpDesk}
                    user={user}
                    isOpened={helpdeskOpened}
                  />
                )}
                <UserNotificationsIndicator />

                {showSearch && (
                  <RoundButton
                    id='search-button'
                    icon='search'
                    iconSize={21}
                    size={30}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleBlock('search');
                    }}
                    status={openedBlocks.search ? 'primary' : 'none'}
                  />
                )}
              </div>
            )}
            <Link
              url='/profile'
              className={classNames(
                'header__item header__item_bordered header__item_profile utils-flex',
                {
                  header__item_rounded: !!user.avaUrl,
                }
              )}>
              <Avatar user={user} size='small' customClass='header__avatar' />
              <div className='header__name'>{user.name}</div>
            </Link>
            <Logout customClass='logout_header logout_no-text' />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(addTranslation(addPermissions(Header))));
