import React, { PureComponent } from 'react';
import Countdown from 'react-countdown';
import classNames from 'classnames';
import DateUtils from 'helpers/Date';
import Icon from 'components/ui/icon';
import './timer.scss';

interface Props {
  expiredTime: string | Date;
  now: () => Date;
  customClass?: string;
  onComplete?: () => void;
}

class Timer extends PureComponent<Props> {
  static defaultProps = {
    customClass: '',
    now: () => DateUtils.getDate().toDate(),
  };

  render() {
    const { now, expiredTime, customClass } = this.props;

    return (
      <div className={classNames(`ui-timer ${customClass}`)}>
        <div className='ui-timer__field'>
          <Icon className='ui-timer__icon' name='timer' size={17} />
          <span className='ui-timer__value'>
            <Countdown
              renderer={this.renderer}
              onComplete={this.onComplete}
              date={expiredTime}
              now={() => +now()}
              zeroPadTime={2}
            />
          </span>
        </div>
      </div>
    );
  }

  renderer = ({ formatted: { hours, minutes, seconds } }) => {
    return (
      <span>
        {hours}:{minutes}:{seconds}
      </span>
    );
  };

  onComplete = () => {
    const { onComplete } = this.props;
    onComplete && onComplete();
  };
}

export default Timer;
