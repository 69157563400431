import React, { Component } from 'react';
import { connect } from 'react-redux';

import { openModal } from 'actions/modal';
import { getApsIconsPaymentMethods } from 'api/icons';
import { initQuickFilters } from 'actions/quickFilters';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import QuickFiltersContainer from 'components/quickFiltersContainer';
import TopPanel from './TopPanel';
import QuickFilters from '../quickFilters';
import tableNames from 'constants/tableNames';
import filtersKeys from 'constants/filters';
import { AnyObject } from 'types/Common';
import SelectItem from 'types/SelectItem';
import { StoreProps } from 'store';
import { QuickIconsFiltersType } from 'types/QuickFilters';

interface OwnProps {
  onCreateItem: (data: AnyObject) => void;
}

interface ConnectedProps {
  filters: AnyObject;
}

type Props = OwnProps & ConnectedProps & StoreProps;

interface State {
  paymentMethods: SelectItem[];
}

class TopPanelContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      paymentMethods: [],
    };
  }

  componentDidMount() {
    return this.init();
  }

  render() {
    const { paymentMethods } = this.state;

    return (
      <TopPanel onResetFilters={this.resetFilters}>
        <QuickFiltersContainer tableName={tableNames.apsIcon}>
          {(props) => (
            <QuickFilters
              filters={props.filters as QuickIconsFiltersType}
              onChangeFilter={props.onChangeFilter}
              paymentMethods={paymentMethods}
              onOpenModal={this.openCreateModal}
            />
          )}
        </QuickFiltersContainer>
      </TopPanel>
    );
  }

  async init() {
    this.setState(
      {
        paymentMethods: getCustomSelectItems({
          list: await this.fetchMethods(),
          getLabel: ({ text, id }) => `${text} (${id})`,
          getValue: ({ id }) => id.toString(),
        }),
      },
      this.initFilters
    );
  }

  async fetchMethods() {
    try {
      return await getApsIconsPaymentMethods();
    } catch (error) {
      console.error(error);
    }
  }

  initFilters = () => {
    const { dispatch } = this.props;

    dispatch(
      initQuickFilters({
        name: tableNames.apsIcon,
        content: {
          isReady: true,
          [filtersKeys.iconPaymentMethodId]: '',
          [filtersKeys.iconPaymentMethodName]: '',
        },
      })
    );
  };

  resetFilters = () => {
    this.initFilters();
  };

  openCreateModal = () => {
    const { onCreateItem, dispatch } = this.props;

    dispatch(
      openModal({
        modalId: 'EditApsIcon',
        callback: (data) => {
          data.isNew && onCreateItem(data);
        },
      })
    );
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  filters: state.quickFilters[tableNames.apsIcon],
});

export default connect(mapStateToProps)(TopPanelContainer);
