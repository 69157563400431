import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import CustomScrollbar from 'components/ui/customScrollbar';
import StatementBlock from './components/statementBlock';
import StatementPendingAmountTable from './components/statementPendingAmountTable';
import defaultProps from './statementTotalsDefaultProps';
import './statementTotals.scss';

interface Props extends IntlProps {
  currentBalanceType: string;
  data?: any;
  pendingDates?: any;
  emptyData?: boolean;
  columns?: any;
}

class StatementTotals extends PureComponent<Props> {
  static defaultProps = {
    data: defaultProps,
    pendingDates: {},
    columns: [],
  };

  render() {
    const { data, pendingDates, emptyData, currentBalanceType, columns } =
      this.props;
    const { pending, available } = data;

    const hideBlocks =
      columns.length > 0 &&
      columns.every((col) => +pending[col] === 0 && +available[col] === 0);

    columns.forEach((col) => {
      if (+pending[col] === 0 && +available[col] === 0) {
        available[col] = data.closing[col];
      }
    });

    return (
      <div
        className={classNames('statement-totals', {
          'statement-totals_no-data': emptyData,
        })}>
        <CustomScrollbar hideVertical={true}>
          {hideBlocks === false && (
            <StatementBlock
              title='statement.generalInfo.pendingAmount'
              data={pending}
              columns={columns}
              isHeader={true}>
              {isEmpty(pendingDates) === false && (
                <StatementPendingAmountTable
                  title='statement.generalInfo.pending.period'
                  columns={columns}
                  data={pendingDates}
                />
              )}
            </StatementBlock>
          )}
          {this.renderTable(
            {
              rows: data.rowsOpeningClosing,
              opening: data.opening,
              openingTitle: 'openingBalance',
              closing: data.closing,
              closingTitle: 'closingBalance',
            },
            false
          )}
          {hideBlocks === false && (
            <StatementBlock
              title='statement.generalInfo.availableBalance'
              data={available}
              columns={columns}
              isHeader={false}
            />
          )}
          {currentBalanceType !== 'out' &&
            this.renderTable(
              {
                rows: data.rowsReversedHoldback,
                opening: data.accruedHoldbackVolume,
                openingTitle: 'accruedHoldbackVolume',
                closing: data.netHoldbackVolume,
                closingTitle: 'netHoldbackVolume',
              },
              false
            )}
        </CustomScrollbar>
      </div>
    );
  }

  renderTable = (
    { rows, opening, closing, openingTitle, closingTitle },
    isHeader = true
  ) => {
    const { columns, getTranslate } = this.props;

    return (
      <div className={classNames('statement-totals__table')}>
        <div className='statement-totals__balance-info'>
          <div className='statement-totals__row'>
            <div className='statement-totals__col statement-totals__col_title'>
              {getTranslate(`statement.generalInfo.${openingTitle}`)}
            </div>
            {columns.map((item) => (
              <div className='statement-totals__col' key={`opening-${item}`}>
                <div className='statement-totals__col-inner'>
                  {isHeader && (
                    <div className='statement-totals__col-title'>{item}</div>
                  )}
                  <div>{opening[item]}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='statement-totals__main'>
          {rows.map((group, i) => (
            <div className='statement-totals__group' key={i}>
              {group.map((row) => (
                <div className='statement-totals__row' key={row.id}>
                  <div className='statement-totals__col statement-totals__col_title'>
                    <span className='statement-totals__col-inner'>
                      {getTranslate(`statement.generalInfo.${row.id}`)}
                    </span>
                  </div>
                  {columns.map((col, j) => (
                    <div
                      key={`${i}-${j}-${row.id}-${row.columns[col]}`}
                      className='statement-totals__col'>
                      <span className='statement-totals__col-inner'>
                        {row.columns[col]}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className='statement-totals__balance-info statement-totals__balance-info_closing'>
          <div className='statement-totals__row'>
            <div className='statement-totals__col statement-totals__col_title'>
              {getTranslate(`statement.generalInfo.${closingTitle}`)}
            </div>
            {columns.map((item) => (
              <div className='statement-totals__col' key={`closing-${item}`}>
                <span className='statement-totals__col-inner'>
                  {closing[item]}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
}

export default addTranslation(StatementTotals);
