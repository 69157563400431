import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import {
  SortableContainer,
  SortableItem,
  SortableHandle,
} from 'components/ui/sortable';
import Link from 'components/ui/link';
import Icon from 'components/ui/icon';
import CustomScrollbar from 'components/ui/customScrollbar';
import Button from 'components/ui/button';
import Messages from 'constants/rpcTypes';
import HelpArticle from '../../types';
import './helpMenu.scss';

interface Props extends WithPermissions, IntlProps {
  currentSlug?: string;
  hash: string;
  isFetched: boolean;
  showSubmenu: boolean;
  articles: HelpArticle[];
  onOpenModal: () => void;
  onChangePosition: ({ oldIndex, newIndex }) => void;
}

interface State {
  isHideSubTitles: boolean;
}

class HelpMenu extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isHideSubTitles: false,
    };
  }
  render() {
    const {
      articles,
      currentSlug,
      isEnabled,
      showSubmenu,
      getTranslate,
      onOpenModal,
      onChangePosition,
    } = this.props;
    const { isHideSubTitles } = this.state;

    return (
      <div className='help-menu'>
        <div className='help-menu__header'>
          <div className='help-menu__title'>
            {getTranslate('help.menu.title')}
          </div>
          <div className='help-menu__subtitle'>
            {getTranslate('help.menu.subtitle')}
          </div>
          {this.canRenderCreateArticle() && (
            <div className='help-menu__add-btn'>
              <Button
                text='Create Article'
                status='primary'
                onClick={onOpenModal}
              />
            </div>
          )}
        </div>
        <div className='help-menu__content'>
          <CustomScrollbar>
            <div className='help-menu__list'>
              <SortableContainer
                updateBeforeSortStart={() =>
                  this.setState({ isHideSubTitles: true })
                }
                onSortEnd={(sortEvent) => {
                  this.setState({ isHideSubTitles: false });
                  onChangePosition(sortEvent);
                }}
                useDragHandle={true}
                lockAxis='y'
                lockOffset={['0%', '-10%']}
                lockToContainerEdges={true}
                helperClass='help-menu__item_drag'>
                {articles.map((item, index) => {
                  const enableDrag = isEnabled(
                    Messages.Article_SetSortMenuIndex
                  );

                  return (
                    <SortableItem index={index} key={item.slug}>
                      <div
                        className={classNames('help-menu__item', {
                          'help-menu__item_active': currentSlug === item.slug,
                          'help-menu__item_enable-drag': enableDrag,
                        })}>
                        <div className='help-menu__item-title'>
                          <Link
                            url={`/help/${item.slug}`}
                            className='help-menu__link'>
                            <Icon
                              name={item.menuIcon}
                              size={15}
                              className='help-menu__icon'
                            />
                            {showSubmenu && (
                              <Icon
                                name='im-Arrow-down'
                                size={11}
                                className='help-menu__arrow'
                              />
                            )}
                            {item.title}
                          </Link>
                          {enableDrag && (
                            <SortableHandle>
                              <span className='help-menu__drag-handler'>
                                <Icon name='im-Draganddrop' size={14} />
                              </span>
                            </SortableHandle>
                          )}
                        </div>
                        {showSubmenu &&
                          currentSlug === item.slug &&
                          !!item.subTitles?.length &&
                          !isHideSubTitles &&
                          this.renderSubTitles(item)}
                      </div>
                    </SortableItem>
                  );
                })}
              </SortableContainer>
            </div>
          </CustomScrollbar>
        </div>
      </div>
    );
  }

  canRenderCreateArticle(): boolean {
    const { isEnabled, isFetched } = this.props;
    return isEnabled(Messages.Article_Create) && isFetched;
  }

  renderSubTitles(article) {
    const { currentSlug, hash } = this.props;
    const { subTitles } = article;
    if (!subTitles || article.slug !== currentSlug) return null;

    return (
      <div className='help-menu__submenu'>
        {subTitles.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames('help-menu__submenu-item', {
                'help-menu__submenu-item_active':
                  item.id && item.id === hash.slice(1),
              })}>
              <Link className='help-menu__submenu-link' url={`#${item.id}`}>
                {item.text}
              </Link>
            </div>
          );
        })}
      </div>
    );
  }
}

export default addTranslation(addPermissions(HelpMenu));
