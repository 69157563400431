import React from 'react';
import themes from '../themes';
import Tabs from './Tabs';

interface Props {
  defaultActiveTabIndex?: number;
  theme?: keyof typeof themes;
  mode?: 'vertical';
  animation?: string;
  customClass?: any;
  onClick?: (tabIndex: number, tabId?: string) => void;
  onBeforeChange?: (tabIndex: number, tabId?: string) => void;
  isShowTabNames?: boolean;
  hideEmpty?: boolean;
  needButtons?: boolean;
  needUpdateDefaultTab?: boolean;
  infoText?: string;
  separate?: boolean;
}

interface State {
  activeTabIndex: number;
  canRenderContent: boolean;
}

class TabsContainer extends React.Component<Props, State> {
  static defaultProps = {
    defaultActiveTabIndex: 0,
    animation: 'fadeIn',
    customClass: '',
    isShowTabNames: true,
  };
  constructor(props) {
    super(props);

    this.state = {
      activeTabIndex: props.defaultActiveTabIndex,
      canRenderContent: true,
    };
  }

  componentDidUpdate(prevProps): void {
    const { children, needUpdateDefaultTab, defaultActiveTabIndex } =
      this.props;
    const { activeTabIndex } = this.state;
    if (children && !children[activeTabIndex] && activeTabIndex !== 0) {
      this.setState({ activeTabIndex: 0 });
    }

    if (
      needUpdateDefaultTab &&
      defaultActiveTabIndex !== undefined &&
      prevProps.defaultActiveTabIndex !== defaultActiveTabIndex
    ) {
      this.setState({ activeTabIndex: defaultActiveTabIndex });
    }
  }

  render() {
    const { activeTabIndex, canRenderContent } = this.state;
    const {
      onClick,
      theme,
      mode,
      children,
      hideEmpty,
      animation,
      customClass,
      isShowTabNames,
      needButtons,
      infoText,
      separate,
    } = this.props;
    return (
      <Tabs
        activeTabIndex={activeTabIndex}
        handleClickTab={this.handleClickTab}
        onClick={onClick}
        canRenderContent={canRenderContent}
        theme={theme}
        mode={mode}
        hideEmpty={hideEmpty}
        animation={animation}
        customClass={customClass}
        isShowTabNames={isShowTabNames}
        needButtons={needButtons}
        infoText={infoText}
        separate={separate}>
        {children}
      </Tabs>
    );
  }

  handleClickTab = (tabIndex: number, tabId): void => {
    const { onClick, onBeforeChange } = this.props;
    const { activeTabIndex } = this.state;

    if (tabIndex === activeTabIndex) return;

    if (onBeforeChange) {
      onBeforeChange(tabIndex, tabId);
      return;
    }

    this.setState(
      {
        activeTabIndex: tabIndex,
        canRenderContent: false,
      },
      () => {
        this.setState({
          canRenderContent: true,
        });
      }
    );

    if (onClick) {
      onClick(tabIndex, tabId);
    }
  };
}

export default TabsContainer;
