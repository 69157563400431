import showNotification from '../../../components/ui/notification/showNotification';
import getLocalizedText from '../../../helpers/getLocalizedText';

import { ServiceSettingsFormHelpers } from '../../../components/modal/modalList/graphDetails/services/ServiceSettingsFormHelpers';

import { applySelectedChartFiltersRequest } from '../../../api/analytics';
import {
  chartDataFormatterWrapper,
  fetchChartFilter,
} from '../../../actions/analytics';
import { createAction } from '../../../helpers/redux';
import ACTIONS from '../../../constants/actionTypes';

interface IAllChartsFiltersPayload {
  chartIds: string[];
  selectedProjects: Array<string>;
  period: {
    from: string;
    to: string;
  };
}

interface IApplyAllFiltersFormValues {
  chartIds: string[];
  projectId?: number[] | null;
  period?: {
    from: string;
    to: string;
  } | null;
}

const ERROR_CHANGES_APPLIED_TEXT =
  'analytics.mainView.changesApplied.error_text';
const SUCCESS_CHANGES_APPLIED_TEXT = 'analytics.mainView.changesApplied.text';

const chartDataSerializer = (
  payload: IAllChartsFiltersPayload
): IApplyAllFiltersFormValues => {
  let acc = {
    chartIds: payload.chartIds,
  };

  if ('selectedProjects' in payload) {
    acc['projectId'] = payload.selectedProjects.map((i) => Number(i));
  }
  if ('period' in payload) {
    let period = ServiceSettingsFormHelpers.formatDate(payload);

    Object.keys(period).forEach((key) => {
      period[key] = period[key] === 'Invalid date' ? '' : period[key];
    });
    acc['period'] = period;
  }

  return acc;
};

const updateChartCallback = async (dispatch, biChartId, data) => {
  const chartData = chartDataFormatterWrapper(data);
  dispatch(
    createAction({
      type: ACTIONS.CHART_EDIT,
      payload: {
        id: biChartId,
        chartData,
      },
    })
  );

  await dispatch(fetchChartFilter({ id: biChartId }));
};

const sendAllChartsFilters = (
  payload: IAllChartsFiltersPayload,
  onFinishCallback
) => {
  return async (dispatch) => {
    const requestData = chartDataSerializer(payload);

    try {
      const { data } = await applySelectedChartFiltersRequest(requestData);
      const biChartIds = Object.keys(data);

      await (async () => {
        for (let i = 0; i < biChartIds.length; i++) {
          const biChartId = biChartIds[i];
          await updateChartCallback(dispatch, biChartId, data[biChartId]);
        }
      })();

      showNotification({
        status: 'success',
        content: getLocalizedText(SUCCESS_CHANGES_APPLIED_TEXT),
      });
    } catch (err) {
      showNotification({
        status: 'info',
        content: getLocalizedText(ERROR_CHANGES_APPLIED_TEXT),
      });
    } finally {
      onFinishCallback();
    }
  };
};

export { sendAllChartsFilters };
