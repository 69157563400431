import { DictionaryOption } from '../../../../../components/maf/MafTypes';
import ItemConfiguration from '../../../../../types/ItemConfiguration';

export const QuickFilterCurrencies: DictionaryOption[] = [
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'RUB',
    label: 'RUB',
  },
  {
    value: 'KZT',
    label: 'KZT',
  },
];

export enum Columns {
  direction = 'direction',
  method = 'name',
  logo = 'logo',
  minAmount = 'min_amount',
  maxAmount = 'max_amount',
  currency = 'currency',
}

export enum Directions {
  payin = 'payIn',
  payout = 'payOut',
}

export const HeaderConfig: ItemConfiguration[] = [
  {
    active: true,
    id: Columns.method,
    localId: 'projects.paymentMethods.list.header.method',
    valueType: 'string',
    sort: true,
    fixed: false,
  },
  {
    active: true,
    id: Columns.direction,
    localId: 'projects.paymentMethods.list.header.direction',
    valueType: 'string',
    sort: true,
    fixed: false,
  },
  {
    active: true,
    id: Columns.minAmount,
    localId: 'projects.paymentMethods.list.header.min_amount',
    valueType: 'string',
    sort: true,
    fixed: false,
  },
  {
    active: true,
    id: Columns.maxAmount,
    localId: 'projects.paymentMethods.list.header.max_amount',
    valueType: 'string',
    sort: true,
    fixed: false,
  },
  {
    active: true,
    id: Columns.currency,
    localId: 'projects.paymentMethods.list.header.currency',
    valueType: 'string',
    sort: true,
    fixed: false,
  },
];

export const InfinitySymbol = '∞';
