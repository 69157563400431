import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Form from 'components/ui/form';
import InputAmount from 'components/ui/inputAmount';
import Button from 'components/ui/button';
import Utils from 'helpers/Utils';
import Tooltip from 'react-tooltip';

interface Props extends IntlProps {
  isSending: boolean;
  currency: string;
  maxValue: string;
  title: string;
  text: string;
  label: string;
  buttonText: string;
  onSubmit: (amount: number) => void;
}

interface State {
  amount: string;
}

class ActionForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
    };
  }

  componentDidMount() {
    //need for position in table
    const el: HTMLElement | null = document.body.querySelector(
      '.ReactVirtualized__Grid'
    );
    if (el) {
      el.style.willChange = 'unset';
    }
  }

  componentWillUnmount() {
    const el: HTMLElement | null = document.body.querySelector(
      '.ReactVirtualized__Grid'
    );
    if (el) {
      el.style.willChange = 'transform';
    }
  }

  render() {
    const {
      isSending,
      currency,
      maxValue,
      title,
      text,
      label,
      buttonText,
      getTranslate,
    } = this.props;
    const { amount } = this.state;

    return (
      <div className='payment-action__form' onMouseEnter={this.onHover}>
        <div className='payment-action__form-title'>{getTranslate(title)}</div>
        <div className='payment-action__form-available'>
          {getTranslate(text)}{' '}
          <span className='payment-action__accent'>
            {maxValue} {currency}
          </span>
        </div>
        <div className='payment-action__form-content'>
          <Form onSubmit={this.handleSubmitRefund}>
            <InputAmount
              label={getTranslate(label)}
              autoFocus
              id='input-refund'
              placeholder='0.00'
              value={amount}
              customClass='payment-action__form-input'
              disabled={isSending}
              error={
                this.isMoreThatAvailable()
                  ? getTranslate('payments.single.refundAmountBigger')
                  : ''
              }
              onChange={this.handleChangeAmount}
              modern
            />
            <Button
              type='submit'
              status='primary'
              text={getTranslate(buttonText)}
              customClass='payment-action__form-button ui-button_full-width'
              loading={isSending}
              disabled={!this.isValidForm()}
            />
          </Form>
        </div>
      </div>
    );
  }

  isValidForm = () => {
    const { isSending } = this.props;
    const { amount } = this.state;

    return (
      amount &&
      parseFloat(Utils.getNumberWithoutSpace(amount)) > 0 &&
      !this.isMoreThatAvailable() &&
      !isSending
    );
  };

  handleSubmitRefund = () => {
    const { onSubmit } = this.props;
    const { amount } = this.state;
    if (this.isValidForm()) {
      onSubmit(+Utils.getNumberWithoutSpace(amount));
    }
  };

  isMoreThatAvailable = () => {
    const { maxValue } = this.props;
    const { amount } = this.state;
    if (!maxValue) return;
    return (
      parseFloat(Utils.getNumberWithoutSpace(amount)) >
      parseFloat(Utils.getNumberWithoutSpace(maxValue))
    );
  };

  handleChangeAmount = ({ target }) => {
    this.setState({
      amount: target.value,
    });
  };

  onHover = (e) => {
    e.stopPropagation();
    setTimeout(() => {
      Tooltip.hide();
    }, 50);
  };
}

export default addTranslation(ActionForm);
