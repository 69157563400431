import React, { PureComponent, ReactNode } from 'react';
import classNames from 'classnames';
import InfoIcon from '../infoIcon';
import './infoText.scss';

interface Props {
  text: string | ReactNode;
  customClass?: string;
  isDisabledIcon?: boolean;
  type: 'error' | 'info' | 'shallow';
}

class InfoText extends PureComponent<Props> {
  static defaultProps = {
    type: 'info',
  };

  render() {
    const { text, customClass, type, isDisabledIcon } = this.props;

    if (!text) {
      return null;
    }

    return (
      <div
        className={classNames('ui-info-text', customClass, {
          'ui-info-text__error': type === 'error',
          'ui-info-text_shallow': type === 'shallow',
        })}>
        {!isDisabledIcon && type === 'info' && (
          <div className='ui-info-text__icon'>
            <InfoIcon tooltip={''} size={24} type={type} />
          </div>
        )}
        <span>{text}</span>
      </div>
    );
  }
}
export default InfoText;
