import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';
import Loader from 'components/ui/loader';
import Animation from 'components/ui/animation';
import CustomScrollbar from 'components/ui/customScrollbar';
import NoContent from 'components/ui/noContent';
import { BalanceType, BalanceRow, BalanceTotal } from './BalanceTypes';
import AccordionItem from 'components/ui/accordion';
import SelectionList from 'components/ui/selectionList';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import MetricService from 'helpers/metricService/MetricService';
import './balance.scss';

interface Props extends IntlProps {
  isFetch: boolean;
  balanceList: BalanceType[];
  contractList: any[];
  setSelectedContracts: (selectedContract: object) => void;
  applyFilter: () => void;
}

const MAX_COUNT_ROWS = 5;
const ROW_HEIGHT = 64;

@wrapAppWithCssClass('layout-app_desktop-width')
class BalancePage extends PureComponent<Props> {
  render() {
    const { isFetch, getTranslate } = this.props;
    return (
      <div className='page-balance'>
        {isFetch ? this.renderContent() : <Loader />}
      </div>
    );
  }

  renderContent() {
    const { balanceList } = this.props;

    return (
      <Animation>
        {balanceList.length === 0 ? (
          <NoContent />
        ) : (
          <div className='page-balance__main'>
            {balanceList.map((balance) => this.renderGroup(balance))}
          </div>
        )}
      </Animation>
    );
  }

  renderGroup(balance: BalanceType) {
    const title = balance.group.split('_').join('&');
    return (
      <AccordionItem
        key={balance.group}
        title={title}
        onOpen={this.sendMetric}
        customClass='ui-accordion-item_balance'>
        <div className='page-balance__group'>
          {this.renderTotals(balance.totals, balance.group)}
          <div
            className='page-balance__table'
            style={{
              height:
                balance.rows.length >= MAX_COUNT_ROWS
                  ? MAX_COUNT_ROWS * ROW_HEIGHT
                  : balance.rows.length * ROW_HEIGHT,
            }}>
            <CustomScrollbar>
              {balance.rows.map((row) => {
                return this.renderRow(row);
              })}
            </CustomScrollbar>
          </div>
        </div>
      </AccordionItem>
    );
  }

  renderRow(data: BalanceRow) {
    const { contractNum, balanceTitle, availableBalance, currency, balanceId } =
      data;

    const classes = classNames('page-balance__row', {
      'page-balance__row_not-empty': parseFloat(availableBalance) > 0,
    });

    return (
      <div key={balanceId} className={classes}>
        <div className='page-balance__column page-balance__column_name'>
          <div className='page-balance__row-title'>{balanceTitle}</div>
        </div>
        <div className='page-balance__column page-balance__column_contract'>
          {contractNum && (
            <div className='page-balance__row-contract'>{contractNum}</div>
          )}
        </div>
        <div className='page-balance__column page-balance__column_balance'>
          <div className='page-balance__row-value'>
            {`${availableBalance} ${currency}`}
          </div>
        </div>
      </div>
    );
  }

  renderTotals(totals: BalanceTotal[], group: string) {
    const { getTranslate } = this.props;
    return (
      <div className='page-balance__total'>
        <div className='page-balance__total-title'>
          {getTranslate('balance.total')}{' '}
          {group.toUpperCase().split('_').join('&')}{' '}
          {getTranslate('balance.byCurrency')}
        </div>
        <div className='page-balance__total-values'>
          {totals.map((total) => {
            return (
              <div key={total.currency} className='page-balance__total-value'>
                {`${total.balance} ${total.currency}`}
              </div>
            );
          })}
        </div>
        <div className='page-balance__table-header'>
          <div className='page-balance__column page-balance__column_name'>
            {getTranslate('balance.name')}
          </div>
          <div className='page-balance__column page-balance__column_contract'>
            {getTranslate('balance.contract', { amount: 1 })}
          </div>
          <div className='page-balance__column page-balance__column_balance'>
            {getTranslate('balance.balance')}
          </div>
        </div>
      </div>
    );
  }

  sendMetric = () => {
    MetricService.send({
      action: 'click',
      actionKey: 'finance.old.balanceDetails',
    });
  };
}

export default addTranslation(BalancePage);
