import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import { getOnlineBalance } from 'api/finance';
import { addListeners } from 'decorators/addListeners';
import { StoreProps } from 'store';

import OnlineFinance from './OnlineFinance';
import OnlineFilters from './components/filters';

import DateHelpers from 'helpers/Date';
import showNotification from 'components/ui/notification/showNotification';
import getMessageError from 'helpers/getMessageError';
import { AnyObject } from 'types/Common';
import { Dictionary } from 'types/FilterValue';
import { Balance } from './OnlineFinanceTypes';
import filtersKeys from 'constants/filters';
import Messages from 'constants/rpcTypes';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';
import { desc } from '../../../types/sortings';
interface ConnectedProps {
  filters: AnyObject;
  balanceTypes: Dictionary;
  wl: string;
  wlCount: number;
  isBalanceFilterByCompaniesEnabled: boolean;
}

type Props = ConnectedProps & StoreProps;

interface State {
  isLoading: boolean;
  list: Balance;
}

@wrapAppWithCssClass('layout-app_desktop-width')
@addListeners([Messages.Balance_RealTime])
class OnlineFinanceContainer extends PureComponent<Props, State> {
  __isMounted = false;

  constructor(props: Props) {
    super(props);

    const initialBalance = OnlineFinanceContainer.getInitialBalance();

    this.state = {
      isLoading: !props.balanceTypes?.isFetched,
      list: initialBalance,
    };
  }

  async componentDidMount() {
    this.__isMounted = true;
    await this.fetchBalance();
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ) {
    const { filters: prevFilters, balanceTypes: prevBalanceTypes } = prevProps;
    const { filters } = this.props;
    const { list: prevList } = prevState;
    const { list } = this.state;

    if (
      !isEqual(prevFilters, filters) ||
      !isEqual(prevBalanceTypes, this.props.balanceTypes)
    ) {
      return this.fetchBalance();
    }

    if (!isEqual(prevList?.sort, list?.sort)) {
      return this.fetchBalance(true);
    }
  }

  render() {
    const { isBalanceFilterByCompaniesEnabled, wlCount } = this.props;

    const { list, isLoading } = this.state;
    return (
      <Fragment>
        <OnlineFilters isLoading={isLoading} />
        <div className='page-finance__content'>
          <OnlineFinance
            data={list}
            isLoading={isLoading}
            onSort={this.changeSort}
            wlCount={wlCount}
            isBalanceFilterByCompaniesEnabled={
              isBalanceFilterByCompaniesEnabled
            }
          />
        </div>
      </Fragment>
    );
  }

  componentWillUnmount() {
    this.__isMounted = false;
  }

  async fetchBalance(forceFetch = false) {
    const { filters, balanceTypes: availableBalanceTypes } = this.props;
    const { list } = this.state;
    const balanceType = filters[filtersKeys.balanceRealTimeBalanceType];
    const companyId = filters[filtersKeys.balanceRealTimeCompanyId];

    if (!forceFetch && !this.canFetch()) {
      return;
    }

    if (
      !list ||
      !this.__isMounted ||
      !availableBalanceTypes?.isFetched ||
      availableBalanceTypes?.error
    ) {
      return;
    }
    this.setState({ isLoading: true });

    try {
      const { sort } = list;
      const params = {
        sort,
        filter: {
          balanceType,
          companyId,
        },
      };

      const { rows, totals } = await getOnlineBalance(params);

      this.setState({
        list: {
          sort,
          isFetched: true,
          totals,
          updateAt: DateHelpers.getDate().toDate(),
          items: rows.map((row) => {
            return {
              ...row,
              amount: `${row.amount} ${row.currency}`,
              availableAmount: `${row.availableAmount} ${row.currency}`,
            };
          }),
        },
      });
    } catch (error) {
      if (!error.message || !error.payload) return;

      showNotification({
        status: 'error',
        content: getMessageError(error),
      });

      console.error(error);
    }
  }

  canFetch = () => {
    const { list } = this.state;

    if (!list || !this.__isMounted) {
      return false;
    }

    return true;
  };

  changeSort = (sort: { field: string; order: string }) => {
    const { list } = this.state;
    this.setState({
      list: {
        ...list,
        sort,
      },
    });
  };

  static getInitialBalance(): Balance {
    return {
      isFetched: true, // false,
      items: [],
      totals: [],
      updateAt: null,
      sort: {
        field: 'amount',
        order: desc,
      },
    };
  }
  onEvent = () => {
    this.__isMounted && this.setState({ isLoading: false });
  };
}

const mapStateToProps = (state): ConnectedProps => {
  const { quickFilters, filtersValues } = state;

  return {
    filters: quickFilters.balanceRealTime,
    balanceTypes: filtersValues?.balanceType,
    wl: state.settings.wl,
    wlCount: state.user.wlCount,
    isBalanceFilterByCompaniesEnabled:
      state.user.isBalanceFilterByCompaniesEnabled,
  };
};

export default connect(mapStateToProps)(OnlineFinanceContainer);
