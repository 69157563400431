import React, { Component } from 'react';
import queryString from 'query-string';
import api from 'api/user';
import Support from './Support';
import showNotification from 'components/ui/notification/showNotification';
import { AnyObject } from 'types/Common';
import getMessageError from 'helpers/getMessageError';
import path from 'helpers/path';
import { WithRouterProps } from 'decorators/withRouter';

interface OwnProps {
  user: AnyObject;
}

type Props = OwnProps & WithRouterProps;

class SupportContainer extends Component<Props> {
  async componentDidMount() {
    const { user } = this.props;
    if (!user) {
      await this.login();
    }
  }

  render() {
    return <Support />;
  }

  async login() {
    const { history } = this.props;
    const params = queryString.parse(history.location.search);
    try {
      await api.loginAsSupport({
        ...params,
        // novalidate: true,
      });
    } catch (error) {
      showNotification({
        status: 'error',
        content: getMessageError(error),
      });
      history.push(path('/login'));
    }
  }
}

export default SupportContainer;
