import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import './tableItemsManage.scss';
import statuses from 'components/ui/button/statuses';

interface Props extends IntlProps {
  amount: number;
  onCancel?: () => void;
  action: () => void;
  actionButtonStatus?: keyof typeof statuses;
  actionButtonText?: string;
  actionButtonLoading?: boolean;
}

class TableItemsManage extends PureComponent<Props> {
  render() {
    const {
      amount,
      onCancel,
      action,
      actionButtonText,
      actionButtonStatus,
      actionButtonLoading,
      getTranslate,
    } = this.props;

    return (
      <div className='table-items-manage'>
        <div className='table-items-manage__text'>{`${getTranslate(
          'common.selecteditems.placeholder'
        )} ${amount}`}</div>
        <div className='table-items-manage__buttons'>
          {onCancel && (
            <Button
              text={getTranslate('common.cancel.button')}
              status='outline'
              size='normal'
              customClass='table-items-manage__button'
              onClick={onCancel}
            />
          )}
          <Button
            text={getTranslate(actionButtonText || 'common.accept.button')}
            status={actionButtonStatus || 'success'}
            size='normal'
            customClass='table-items-manage__button'
            disabled={amount < 1}
            onClick={action}
            loading={actionButtonLoading}
          />
        </div>
      </div>
    );
  }
}

export default addTranslation(TableItemsManage);
