import RpcService from '../helpers/RpcService';
import RpcTypes from '../constants/rpcTypes';

export const getReconciliation = (date) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Reconciliation_Get,
        payload: { date },
      },
    ],
  });
};
export const setReconciliation = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Reconciliation_SetData,
        payload,
      },
    ],
  });
};
