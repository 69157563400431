import React, { PureComponent } from 'react';
import classNames from 'classnames';
import devices from 'constants/devices';
import Icon from 'components/ui/icon';
import './deviceItem.scss';

interface Props {
  deviceCode: number;
  deviceTitle: string;
  customClass?: string;
}
class DeviceItem extends PureComponent<Props> {
  render() {
    const { deviceCode, deviceTitle, customClass } = this.props;

    const deviceIcon = devices[deviceCode];
    return (
      <span className={classNames('device-item', customClass)}>
        {deviceIcon ? (
          <Icon
            className='device-item__icon device-item__icon_device'
            name={`im-${deviceIcon}`}
            size={14}
          />
        ) : (
          <Icon className='device-item__icon' name='im-Question' size={8} />
        )}
        <span className='device-item__text'>{deviceTitle}</span>
      </span>
    );
  }
}
export default DeviceItem;
