import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Icon from 'components/ui/icon';

import './clarificationInfo.scss';

const ClarificationInfo: React.VFC<IntlProps> = ({ getTranslate }) => (
  <div className='clarification-info'>
    <div className='clarification-info__section clarification-info__section_preview'>
      <div className='clarification-info__container'>
        {getTranslate('payouts.batch.info.hint')}
      </div>
    </div>
    <div className='clarification-info__section'>
      <div className='clarification-info__container'>
        <div className='clarification-info__inner'>
          <div className='clarification-info__title'>
            <span className='clarification-info__icon clarification-info__icon_step1'>
              <Icon name='im-Tick' size={8} />
            </span>
            {getTranslate('payouts.batch.info.step1.title')}
          </div>

          <p className='clarification-info__text'>
            {getTranslate('payouts.batch.info.step1.point4', {
              download: (
                <span className='clarification-info__load-icon clarification-info__load-icon_download'>
                  <Icon name='download' size={12} /> csv
                </span>
              ),
              node: true,
            })}
          </p>
          <p className='clarification-info__text'>
            {getTranslate('payouts.batch.info.step1.point5')}
          </p>
          <p className='clarification-info__text'>
            {getTranslate('payouts.batch.info.step1.point6', {
              upload: (
                <span className='clarification-info__load-icon clarification-info__load-icon_upload'>
                  <Icon name='upload' size={12} /> csv
                </span>
              ),
              node: true,
            })}
          </p>
          <hr className='clarification-info__separator' />
          <div className='clarification-info__subtitle'>
            {getTranslate('payouts.batch.info.step1.or')}
          </div>
          <p className='clarification-info__text'>
            {getTranslate('payouts.batch.info.step1.point1')}
          </p>
          <p className='clarification-info__text'>
            {getTranslate('payouts.batch.info.step1.point2')}
          </p>
          <p className='clarification-info__text'>
            {getTranslate('payouts.batch.info.step1.point3')}
          </p>
        </div>
      </div>
    </div>
    <div className='clarification-info__section'>
      <div className='clarification-info__container'>
        <div className='clarification-info__inner'>
          <div className='clarification-info__title'>
            <span className='clarification-info__icon clarification-info__icon_step2'>
              <Icon name='im-Close' size={8} />
            </span>
            {getTranslate('payouts.batch.info.step2.title')}
          </div>
          <p className='clarification-info__text'>
            {getTranslate('payouts.batch.info.step2.point1')}
          </p>
          <p className='clarification-info__text'>
            {getTranslate('payouts.batch.info.step2.point2')}
          </p>
        </div>
      </div>
    </div>
    <div className='clarification-info__section'>
      <div className='clarification-info__container'>
        <div className='clarification-info__inner'>
          <div className='clarification-info__title'>
            <span className='clarification-info__icon clarification-info__icon_step3'>
              <Icon name='arrowRight' size={13} />
            </span>
            {getTranslate('payouts.batch.info.step3.title')}
          </div>
          <p className='clarification-info__text'>
            {getTranslate('payouts.batch.info.step3.point1')}
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default addTranslation(ClarificationInfo);
