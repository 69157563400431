export const TABLE_HEADER_HEIGHT = 40;
export const TABLE_ROW_HEIGHT = 40;

export default {
  TABLE_HEADER_HEIGHT,
  TABLE_ROW_HEIGHT,
};
export const MIN_COLUMN_WIDTH = 100;

export const DOUBLE_CLICK_DELAY = 300;

export const MENU_WIDTH = 45;
export const MENU_OPENED_WIDTH = 180;
export const APP_PADDING = 16;
export const HEADER_HEIGHT = 40;
