import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { RootState, StoreProps } from '../../../../store';

import { ByOrder, IFiltersById } from 'types/Analytics';
import {
  addTranslation,
  IntlProps,
} from '../../../../decorators/addTranslation';
import {
  fetchSharedWithMeChartItems,
  fetchSharedWithMeCharts,
} from '../../../../actions/analytics/shareChartsFeature';

import { LayoutTypes } from '../../../../components/modal/modalList/editLayout/EditLayout';
import Loader from '../../../../components/ui/loader';

import classNames from 'classnames';
import GraphCard, { GraphRelation } from '../GraphItem/GraphCardContainer';

interface ConnectedProps {
  byId: {
    [id: string]: any;
  };
  byOrder: ByOrder;
  filtersById: IFiltersById;
}

interface State {
  chartsFetched: boolean;
}

type Props = {
  layoutType: LayoutTypes;
} & ConnectedProps &
  IntlProps &
  StoreProps;

class SharedWithMeCharts extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      chartsFetched: false,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;

    await dispatch(fetchSharedWithMeCharts());

    this.setState({ chartsFetched: true });

    await dispatch(fetchSharedWithMeChartItems());
    // commented that time, will add this feature later
    // await dispatch(fetchSharedWithMeChartFilters());
  }

  render() {
    const { byId, byOrder, filtersById, layoutType, dispatch } = this.props;
    const { chartsFetched } = this.state;

    if (!chartsFetched) {
      return <Loader />;
    }

    if (Object.keys(byOrder).length === 0) {
      return <></>;
    }

    return (
      <div className={'sortable-container'}>
        {Object.entries(byOrder).map(([order, ByOrderObject]) => {
          return (
            <div
              key={`container-${ByOrderObject.biChartId}-${layoutType}`}
              className={classNames(
                layoutType === LayoutTypes.modules
                  ? `grid-column-6`
                  : 'grid-column-12',
                'sortable-element-container'
              )}>
              <GraphCard
                item={
                  ByOrderObject.fetched === false ||
                  ByOrderObject.error !== undefined
                    ? {}
                    : byId[ByOrderObject.biChartId]
                }
                id={ByOrderObject.biChartId}
                fetched={ByOrderObject.fetched}
                error={ByOrderObject.error}
                order={Number(order)}
                dispatch={dispatch}
                layoutType={layoutType}
                filters={filtersById[ByOrderObject.biChartId]}
                sortable={false}
                canEdit={false}
                canDelete={false}
                showRelation={true}
                relationType={GraphRelation.SHARED_WITH_ME}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ConnectedProps => ({
  byId: state.analytics.sharedChartData.byId,
  byOrder: state.analytics.sharedChartData.byOrder,
  filtersById: state.analytics.sharedChartData.filtersById,
});

export default connect(mapStateToProps)(addTranslation(SharedWithMeCharts));
