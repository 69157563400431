import BaseMessageHandler from '../BaseMessageHandler';
import { updateFiltersValues } from 'actions/filtersValues';

export default class Dictionary_Load extends BaseMessageHandler {
  success = () => {
    const {
      dispatch,
      message: { payload },
    } = this;

    dispatch(
      updateFiltersValues({
        [payload.name]: {
          isFetched: true,
          isLoading: false,
          list: payload.elements,
          hasMoreRows: payload.hasMoreRows,
        },
      })
    );
  };
}
