import { FilterNames } from './types';

export const idPropToType = {
  [FilterNames.project]: 'projectId',
  [FilterNames.merchant]: 'merchantId',
  [FilterNames.legalEntity]: 'legalEntities',
};

export const typeToIdProp = {
  projectId: 'project',
  merchantId: 'merchant',
  legalEntities: 'legalEntity',
};

export const minUsersTableColumnsWidth = {
  edit: 110,
};
