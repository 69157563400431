import React, { Component, Fragment, FormEvent } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Form from 'components/ui/form';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import InputPassword from 'components/ui/inputPassword';

interface Props extends IntlProps {
  email: string;
  signUpLink: string;
  password: string;
  isLoading: boolean;
  validation: any;
  settings: any;
  onChange: any;
  onLogin: (event: FormEvent) => void;
  goToResetPassword: any;
}

class LoginForm extends Component<Props> {
  render() {
    const {
      email,
      password,
      isLoading,
      validation,
      onChange,
      goToResetPassword,
      onLogin,
      getTranslate,
      signUpLink,
    } = this.props;

    return (
      <div className='login-form'>
        <Form onSubmit={onLogin}>
          <div className='login-form__content'>
            <div className='login-form__fields'>
              <div className='login-form__row'>
                <Input
                  disabled={isLoading}
                  id='email'
                  label={getTranslate('auth.email.label')}
                  placeholder={getTranslate('auth.email.placeholder')}
                  value={email}
                  onChange={(event) => onChange('email', event.target.value)}
                  modern
                />
              </div>

              <div className='login-form__row'>
                <InputPassword
                  disabled={isLoading}
                  id='password'
                  value={password}
                  label={getTranslate('auth.password.label')}
                  placeholder={getTranslate('auth.password.placeholder')}
                  customClass={classNames({ 'ui-input_empty': !password })}
                  onChange={(event) => onChange('password', event.target.value)}
                  modern
                />
              </div>
            </div>
            <div className='login-form__error'>
              {validation && (validation.email || validation.password) && (
                <Fragment>
                  <p className='login-form__error-text'>
                    {getTranslate(validation.email)}
                  </p>
                  <p className='login-form__error-text'>
                    {getTranslate(validation.password)}
                  </p>
                </Fragment>
              )}
            </div>
          </div>
          <Button
            loading={isLoading}
            text={getTranslate('auth.login.button')}
            status='primary'
            type='submit'
            size='large'
            customClass='page-auth__btn ui-button_full-width'
          />
          {signUpLink && (
            <div className='page-auth__link'>
              <a
                href={signUpLink}
                target='_blank'
                rel='noreferrer noopener'
                className='utils-link'>
                {getTranslate('auth.signUp.link')}
              </a>
            </div>
          )}
          <div className='page-auth__link'>
            <span onClick={goToResetPassword} className='utils-link'>
              {getTranslate('auth.forgotPassword.link')}
            </span>
          </div>

          {/*<div className='page-auth__link'>
            <a href={`mailto:${settings.supportEmail}`} className='utils-link'>
              {getTranslate('auth.contact.link')}
            </a>
          </div>*/}
        </Form>
      </div>
    );
  }
}

export default addTranslation(LoginForm);
