import NOTIFICATIONS from 'pages/app/components/userNotifications/availableNotifications';
import { AnyObject } from 'types/Common';
import urlsMap from 'constants/urlsMap';

const SHOW_DETAILS_TEXT = 'common.showDetails.label';

class LinkFactory {
  /**
   *
   * @param type
   * @param data
   */
  // eslint-disable-next-line complexity
  static create(
    type: string,
    data: AnyObject
  ): { url?: string; text?: string; isExternal?: boolean } {
    switch (type) {
      case NOTIFICATIONS.TYPE_PAF_READY_TO_FILL:
      case NOTIFICATIONS.SHOW_PAF:
        return {
          url: '/get-started',
          text: 'common.showPAF.button',
        };
      case NOTIFICATIONS.USER_FIRST_LOGIN:
      case NOTIFICATIONS.USER_BLOCKED_BY_PASSWORD_OR_TFA_BY_ATTEMPTS:
        return {
          url: '/team',
          text: 'common.showMyTeam.label',
        };
      case NOTIFICATIONS.USER_PASSWORD_EXPIRES_SOON:
        return {
          url: '/profile',
          text: 'common.changeMyPass.label',
        };
      case NOTIFICATIONS.PAYMENT_OPERATION_WAS_SUCCESSFUL:
      case NOTIFICATIONS.PAYMENT_OPERATION_TYPE_REFUND_WAS_UNSUCCESSFUL:
      case NOTIFICATIONS.PAYMENT_OPERATION_TYPE_PAYOUT_WAS_UNSUCCESSFUL:
      case NOTIFICATIONS.PAYMENT_OPERATION_TYPE_CAPTURE_WAS_UNSUCCESSFUL:
      case NOTIFICATIONS.PAYMENT_OPERATION_TYPE_CANCEL_WAS_UNSUCCESSFUL:
      case NOTIFICATIONS.INVOICE_SALE_OPERATION_WAS_SUCCESSFUL:
        return {
          url: `/payments/${data.transactionId}?name=${data.paymentId}`,
          text: SHOW_DETAILS_TEXT,
        };
      case NOTIFICATIONS.PAYMENT_OPERATION_BATCH_FINALIZED:
      case NOTIFICATIONS.PAYMENT_OPERATION_BATCH_AWAITING_CLARIFICATION:
        return {
          url: `/${urlsMap.payouts}/${data.batchId}?type=batch&name=Batch ${data.batchId}`,
          text: SHOW_DETAILS_TEXT,
        };
      case NOTIFICATIONS.REPORT_CREATED:
        return {
          url: data.reportUrl,
          text: 'common.download.label',
          isExternal: true,
        };
      case NOTIFICATIONS.REPORT_FAILED:
        return {
          url: '/reports',
          text: SHOW_DETAILS_TEXT,
        };
      case NOTIFICATIONS.CHARGEBACK_ACCEPT_WAS_UNSUCCESSFUL:
      case NOTIFICATIONS.CHARGEBACK_ACCEPT_WAS_SUCCESSFUL:
        return {
          url: `/chargebacks/${data.chargebackId}`,
          text: SHOW_DETAILS_TEXT,
        };
      case NOTIFICATIONS.USER_HAS_NEW_CHARGEBACKS:
      case NOTIFICATIONS.USER_HAS_EXPIRING_CHARGEBACKS:
      case NOTIFICATIONS.USER_HAS_NEW_CHARGEBACKS_PRE_ARBITRATION:
        return {
          url: `/chargebacks`,
          text: SHOW_DETAILS_TEXT,
        };
      case NOTIFICATIONS.REMITTANCE_PAYMENT_WAS_UNSUCCESSFUL:
      case NOTIFICATIONS.REMITTANCE_PAYMENT_WAS_SUCCESSFUL:
        return {
          url: `/remittance/${data.transactionId}?name=${data.paymentId}`,
          text: SHOW_DETAILS_TEXT,
        };
      default:
        return {};
    }
  }
}

export default LinkFactory;
