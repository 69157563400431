import React, { Component } from 'react';
import { connect } from 'react-redux';

import { RootState, StoreProps } from 'store';
import { getNotificationsCounters } from 'api/userNotifications';
import { updateUserNotifications } from 'actions/userNotifications';
import { addListeners, IListeners } from 'decorators/addListeners';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';

import UserNotificationsIndicator from './UserNotificationsIndicator';
import Messages from 'constants/rpcTypes';
import { UserNotificationsType } from 'types/UserNotifications';

interface ConnectedProps {
  userNotifications: UserNotificationsType;
}

type Props = ConnectedProps & StoreProps & WithPermissions;

@addListeners([Messages.UserNotification_Counters])
class UserNotificationIndicatorContainer
  extends Component<Props>
  implements IListeners
{
  componentDidMount() {
    const { isEnabled } = this.props;
    if (isEnabled(Messages.UserNotification_List)) {
      return this.fetchCounters();
    }
  }

  render() {
    const {
      userNotifications: { counters },
      isEnabled,
    } = this.props;

    if (!isEnabled(Messages.UserNotification_List)) return null;

    return <UserNotificationsIndicator unreadCount={counters.unread} />;
  }

  async fetchCounters() {
    try {
      await getNotificationsCounters();
    } catch (error) {
      console.error(error);
    }
  }

  onEvent({ data, name }) {
    const { dispatch } = this.props;
    const payload = data.payload;
    const { status } = data.rpc;

    if (status !== 'success') return;

    if (name === Messages.UserNotification_Counters) {
      dispatch(updateUserNotifications({ counters: payload.counters }));
    }
  }
}

const mapStateToProps = (state: RootState): ConnectedProps => ({
  userNotifications: state.userNotifications,
});

export default connect(mapStateToProps)(
  addPermissions(UserNotificationIndicatorContainer)
);
