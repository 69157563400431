import React, { PureComponent } from 'react';
import classNames from 'classnames';
import {
  FieldLabel,
  FieldValue,
  FieldContainer,
} from 'components/ui/input/components/components';
import Icon from 'components/ui/icon';
import './buttonLikeSelect.scss';

interface Props {
  label: string;
  placeholder: string;
  customClass?: string;
  onClick: () => void;
}

class ButtonLikeSelect extends PureComponent<Props> {
  render() {
    const { label, placeholder, customClass, onClick } = this.props;
    return (
      <FieldContainer
        customClass={classNames('ui-button-like-select', customClass)}
        onClick={onClick}>
        <FieldLabel text={label} />
        <FieldValue
          text={placeholder}
          customClass='ui-button-like-select__value'
        />
        <Icon
          name='im-Arrow-right-Option-2'
          className='ui-button-like-select__icon'
        />
      </FieldContainer>
    );
  }
}
export default ButtonLikeSelect;
