import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import Input from 'components/ui/input';
import InputAmount from 'components/ui/inputAmount';
import { AnyObject } from 'types/Common';

import './editable.scss';

interface Props {
  value: string | null;
  onChange: (value) => void;
  status?: string;
  theme?: 'column';
  onBlur?: (value) => void;
  onFocus?: (e) => void;
  canEdit?: boolean;
  isForbiddenEdit?: boolean;
  type?: string;
  inputOptions?: AnyObject;
}

interface State {
  isEditing: boolean;
}

class Editable extends PureComponent<Props, State> {
  private readonly refInputField;

  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
    this.refInputField = React.createRef();
  }

  static defaultProps = {
    theme: 'column',
    canEdit: true,
    isForbiddenEdit: false,
  };

  render() {
    const { status, theme, type, isForbiddenEdit } = this.props;
    const { isEditing } = this.state;

    return (
      <div
        className={classNames('ui-editable', {
          'ui-editable_editable': !isEditing && !isForbiddenEdit,
          'ui-editable_active': isEditing,
          'ui-editable_amount': type === 'amount',
          [`ui-editable_${theme}`]: !!theme,
          [`ui-editable_${status}`]: !!status,
        })}
        onClick={this.startEdit}>
        {this.getContent()}
        {!isEditing && !isForbiddenEdit && (
          <Icon name='im-Edit1' size={12} className='ui-editable__icon' />
        )}
      </div>
    );
  }

  getContent = () => {
    const { value, type, inputOptions, onChange, onFocus } = this.props;
    const { isEditing } = this.state;
    const InputComponent = type === 'amount' ? InputAmount : Input;
    if (isEditing) {
      return (
        <InputComponent
          value={value === null ? '' : value}
          id='editing'
          onChange={(e) => onChange(e.target.value)}
          placeholder=''
          onFocus={onFocus}
          onBlur={() => {
            this.finishEdit();
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              this.finishEdit();
            }
          }}
          cleaveOptions={inputOptions}
          customClass='ui-editable__field'
          inputContainerRef={this.refInputField}
        />
      );
    }
    return value;
  };

  startEdit = () => {
    const { canEdit } = this.props;
    if (!canEdit) return;
    this.setState({ isEditing: true }, this.focusInput);
  };

  finishEdit = () => {
    const { value, onBlur } = this.props;
    onBlur && onBlur(value);
    this.setState({ isEditing: false });
  };

  focusInput() {
    const { current } = this.refInputField;

    if (current) {
      current.querySelector('.ui-input__field').focus();
    }
  }

  blurInput = () => {
    const { current } = this.refInputField;

    if (current) {
      current.querySelector('.ui-input__field').blur();
    }
  };
}

export default Editable;
