import React, { PureComponent } from 'react';

// import Popover from 'components/ui/popover';
// import StatementOperationList from '../../../statementOperationList';

/*const TEST_COLS = ['paymentId', 'amount', 'currency', 'type', 'operationId'];

const TEST_DATA = [
  {
    paymentId: 123415125125,
    amount: '5.0',
    currency: 'EUR',
    type: 'capture',
    operationId: 123415125125,
  },
  {
    paymentId: 123415125125,
    amount: '5.0',
    currency: 'EUR',
    type: 'sale',
    operationId: 123415125125,
  },
  {
    paymentId: 123415125125,
    amount: '100.0',
    currency: 'RUB',
    type: 'capture',
    operationId: 123415125125,
  },
];*/

interface Props {
  data?: any;
  period?: string;
  columns?: any;
}

interface State {
  /* showPopover: boolean; */
}

class StatementPendingAmountTableRow extends PureComponent<Props, State> {
  /* static defaultProps = {
    showPopover: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showPopover: props.showPopover,
    };
  } */

  render() {
    const { columns, data, period } = this.props;

    return (
      <div className='statement-totals__row'>
        <div className='statement-totals__col statement-totals__col_title'>
          <span className='statement-totals__col-inner'>
            <span className='statement-totals__link-wrapper'>
              <span className='statement-totals__link'>{period}</span>
              {/** Popover will be programmed in feature task */}
              {/*showPopover && (
                <Popover
                  title='statement.modal.header'
                  titleCaption={period}
                  customClass='statement-totals__pending-amount-popover'
                  onClose={this.togglePopover}
                  onClickOutside={this.togglePopover}>
                  <StatementOperationList
                    columns={TEST_COLS}
                    data={TEST_DATA}
                  />
                </Popover>
              )*/}
            </span>
          </span>
        </div>
        {columns.map((col, j) => (
          <div key={`col-${j}`} className='statement-totals__col'>
            <span className='statement-totals__col-inner'>{data[col]}</span>
          </div>
        ))}
      </div>
    );
  }

  // Add onClick={this.togglePopover} for .statement-totals__link
  // for popup
  /* togglePopover = () => {
    const { showPopover } = this.state;

    this.setState({ showPopover: !showPopover });
  }; */
}

export default StatementPendingAmountTableRow;
