import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';

export const applySearchFilters = (filters) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.APPLY_SEARCH_FILTERS,
        payload: {
          filters,
        },
      })
    );
  };
};

export const resetSearchFilter = (filterId) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_ONE_SEARCH_FILTER,
        payload: {
          filterId,
        },
      })
    );
  };
};

export const resetSearchFilters = () => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_SEARCH_FILTERS,
      })
    );
  };
};
