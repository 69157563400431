import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { connect } from 'react-redux';

import { closeModal } from 'actions/modal';
import api from 'api/user';
import { StoreProps } from 'store';

import { withAuthPing } from 'decorators/withAuthPing';
import ModalTitle from '../components/ModalTitle';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import Form from 'components/ui/form';

interface OwnProps {
  callback: () => void;
  content: {
    authToken: string;
  };
  authToken: string;
}

interface ConnectedProps {
  user: any;
}

type Props = OwnProps & ConnectedProps & IntlProps & StoreProps;

interface State {
  validationCode: string;
}

const CODE_LENGTH = 6;

@withAuthPing
class TwoFactorAuth extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      validationCode: '',
    };
  }

  render() {
    const { getTranslate, user } = this.props;
    const { validationCode } = this.state;

    return (
      <>
        <ModalTitle customClass='modal__title_left-align'>
          {getTranslate('auth.twoFA.label')}
        </ModalTitle>
        <ModalContent>
          <Form onSubmit={() => this.sendForm()}>
            <Input
              autoFocus
              id='verification-code'
              label={getTranslate('auth.twoFA.text')}
              value={validationCode}
              placeholder={getTranslate('auth.twoFA.placeholder')}
              cleaveOptions={{
                numericOnly: true,
                blocks: [3, 3],
                delimiters: [' '],
              }}
              onChange={({ target: { value } }) =>
                this.setState({ validationCode: value })
              }
              error={
                user &&
                user.validationErrors &&
                user.validationErrors['validationCode']
              }
              modern
            />
          </Form>
        </ModalContent>
        <ModalFooter>
          <Button
            customClass='ui-button_full-width'
            text={getTranslate('modals.common.buttons.confirm')}
            status='primary'
            size='large'
            onClick={() => this.sendForm()}
            disabled={this.getCodeWithoutSpace().length !== CODE_LENGTH}
          />
        </ModalFooter>
      </>
    );
  }

  canSendForm = () => {
    return this.getCodeWithoutSpace().length === CODE_LENGTH;
  };

  getCodeWithoutSpace = () => {
    return this.state.validationCode.replace(/\s/g, '');
  };

  changeVerificationCode = (validationCode: string): void => {
    this.setState({ validationCode });
  };

  async sendForm() {
    const validationCode = this.getCodeWithoutSpace();
    if (this.canSendForm()) {
      const { dispatch, authToken } = this.props;
      try {
        await api.twoAuthConfirm({ validationCode, authToken });
        dispatch(closeModal());
      } catch (error) {
        console.error('TwoAuthConfirm error: ', error);
      }
    }
  }
}

const mapStateToProps = (state): ConnectedProps => ({ user: state.user });

export default connect(mapStateToProps)(addTranslation(TwoFactorAuth));
