import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import StatStatement from './StatStatement';
import { getDynamicDictionaries } from 'api/dictionaries';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import SelectItem from 'types/SelectItem';
import { downloadStatement } from 'api/financialData';
import DateHelpers from 'helpers/Date';
import { periodTypes } from 'constants/dateFormats';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { dictionaries } from './constants';
import { Data } from './statStatementData/types';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';

interface OwnProps {
  timezone: string;
}

interface State {
  state: 'loading' | 'empty' | 'data' | 'notFound';
  balanceIdList: SelectItem[];
  currencyList: SelectItem[];
  data: Data | null;
}

export interface Fields {
  date: {
    dateFrom: string;
    dateTo: string;
  };
  periodPreset: periodTypes | '';
  currency: string;
  balanceId: string;
  loadingDictionaries: string[];
}
type Props = OwnProps & IntlProps;

@wrapAppWithCssClass('layout-app_form')
class StatStatementContainer extends PureComponent<Props, State & Fields> {
  constructor(props) {
    super(props);
    this.state = {
      state: 'empty',
      date: {
        dateFrom: '',
        dateTo: '',
      },
      periodPreset: '',
      balanceId: '',
      currency: '',
      currencyList: [],
      balanceIdList: [],
      loadingDictionaries: [],
      data: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.date.dateFrom !== this.state.date.dateFrom ||
      prevState.date.dateTo !== this.state.date.dateTo
    ) {
      this.getDictionaries();
    }
    if (prevState.currency !== this.state.currency) {
      this.getDictionaries('financialDataStatementCurrency');
    }
  }

  render() {
    return (
      <StatStatement
        state={this.state.state}
        onChange={this.onChange}
        onChangePeriodPreset={this.onChangePeriodPreset}
        date={this.state.date}
        periodPreset={this.state.periodPreset}
        balanceId={this.state.balanceId}
        currency={this.state.currency}
        balanceIdList={this.state.balanceIdList}
        currencyList={this.state.currencyList}
        onFetchView={() => this.fetchView()}
        data={this.state.data}
        canFetch={Boolean(this.state.balanceId && this.state.currency)}
        loadingDictionaries={this.state.loadingDictionaries}
      />
    );
  }

  onChange = (name: keyof Fields, value, isPreset = false) => {
    this.setState(
      (state) =>
        ({
          [name]: value,
          state: state.state === 'data' ? 'empty' : state.state,
          data: null,
        } as Pick<State, keyof State>),
      () => {
        if (name === 'periodPreset') {
          this.onChangePeriodPreset(value);
        } else if (name === 'date') {
          this.setState((state) => ({
            currency: '',
            balanceId: '',
            periodPreset:
              !isPreset && state.periodPreset ? '' : state.periodPreset,
          }));
        } else if (name === 'currency') {
          this.getDictionaries('balanceId');
        }
      }
    );
  };

  getDictionaries = async (isBalance?) => {
    try {
      this.setState((state) => ({
        loadingDictionaries: [
          ...state.loadingDictionaries,
          isBalance ? dictionaries.balance : dictionaries.currency,
          dictionaries.balance,
        ],
      }));
      const dictionariesLoaded = await this.fetchDictionaries(
        isBalance ? dictionaries.balance : undefined
      );

      dictionariesLoaded.forEach((item) => {
        if (item.name === 'financialDataStatementCurrency') {
          this.setState({
            currencyList: getCustomSelectItems({
              list: item.elements,
            }),
          });
        } else {
          this.setState({
            balanceIdList: getCustomSelectItems({
              list: item.elements,
              getLabel: (element) =>
                `${this.props.getTranslate(element.text.toString())} (${
                  element.id
                })`,
            }),
          });
        }
      });
    } catch (e) {
      console.error('Dictionaries download error: ', e);
    } finally {
      this.setState({ loadingDictionaries: [] });
    }
  };

  fetchDictionaries = (dictionaryName?: dictionaries) => {
    if (dictionaryName) {
      return getDynamicDictionaries([
        {
          name: dictionaryName,
          params: {
            periodFrom: this.state.date.dateFrom,
            periodTo: this.state.date.dateTo,
            currency: this.state.currency,
          },
        },
      ]);
    }
    return getDynamicDictionaries([
      {
        name: dictionaries.currency,
        params: {
          periodFrom: this.state.date.dateFrom,
          periodTo: this.state.date.dateTo,
        },
      },
      {
        name: dictionaries.balance,
        params: {
          periodFrom: this.state.date.dateFrom,
          periodTo: this.state.date.dateTo,
          currency: this.state.currency,
        },
      },
    ]);
  };

  fetchView = async () => {
    try {
      this.setState(() => ({ state: 'loading' }));
      const data = await downloadStatement({
        reportPeriod: [this.state.date.dateFrom, this.state.date.dateTo],
        balanceId: this.state.balanceId,
        currency: this.state.currency,
      });
      this.setState(() => ({
        data,
        state: 'data',
      }));
    } catch (e) {
      console.error('Download statement error: ', e);
      this.setState(() => ({
        data: null,
        state: 'notFound',
      }));
    }
  };

  onChangePeriodPreset = (value: periodTypes): void => {
    const { timezone } = this.props;

    const period = DateHelpers.getPeriod(value, timezone, 'date');
    this.onChange('date', { dateFrom: period[0], dateTo: period[1] }, true);
  };
}

const mapStateToProps = (state) => ({
  timezone: state.user.timezone,
});

export default connect(mapStateToProps)(addTranslation(StatStatementContainer));
