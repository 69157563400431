import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getPaymentAvailableActions } from 'api/payment';
import PaymentActions from './PaymentActions';
import { addIsMounted, IsMounted } from 'decorators/addIsMounted';
import OPERATION_CODES from 'constants/operationCodes';
import config from 'config';
import { AnyObject } from 'types/Common';
import statuses from 'components/ui/button/statuses';
import { StoreProps } from 'store';
import { actionTypesT } from './components/action/ActionContainer';

interface OwnProps {
  transactionId: string;
  projectId: string;
  operationId?: string;
  actionsToShow?: string[];
  isNeedPing?: boolean;
  refundTooltip?: string;
  buttonTheme?: keyof typeof statuses;
  buttonType?: 'round';
  actionTypes?: actionTypesT[];
  customClass?: string;
  startPingOnClick?: boolean;
  disabled?: boolean;
  refundMetric?: string;
  selectorPortal?: string;
}

interface ConnectedProps {
  availableActions: AnyObject;
}

interface State {
  isPingEnabled: boolean;
  isLoading: boolean;
  isOpenedPanel: boolean;
}

type Props = OwnProps & ConnectedProps & StoreProps & IsMounted;

const defaultActionTypes: actionTypesT[] = [
  'capture',
  'cancel',
  'refund',
  'payout',
];

class PaymentActionsContainer extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isPingEnabled: props.isNeedPing !== undefined ? props.isNeedPing : true,
      isLoading: false,
      isOpenedPanel: false,
    };
  }
  private updateTimerId;

  async componentDidMount() {
    await this.init();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState) {
    if (
      prevProps.isNeedPing !== this.props.isNeedPing &&
      this.props.isNeedPing !== undefined
    ) {
      this.setState({ isPingEnabled: this.props.isNeedPing });
    }

    if (!prevState.isPingEnabled && this.state.isPingEnabled) {
      this.init();
    }
    if (
      this.state.isLoading &&
      !prevState.isLoading &&
      this.props.startPingOnClick
    ) {
      this.setState({ isOpenedPanel: true });
    }
  }

  componentWillUnmount(): void {
    this.stopUpdateStatus();
  }

  render() {
    const {
      transactionId,
      operationId,
      projectId,
      availableActions,
      refundTooltip,
      buttonTheme,
      buttonType,
      actionTypes,
      customClass,
      selectorPortal,
    } = this.props;
    return (
      <PaymentActions
        availableActions={availableActions}
        isLoading={this.state.isLoading}
        projectId={projectId}
        transactionId={transactionId}
        operationId={operationId}
        refundTooltip={refundTooltip}
        buttonTheme={buttonTheme}
        buttonType={buttonType}
        customClass={customClass}
        actionTypes={actionTypes || defaultActionTypes}
        startPingOnClick={this.props.startPingOnClick}
        startPing={() => this.togglePing(true)}
        isEnabled={this.state.isPingEnabled}
        disabled={this.props.disabled}
        refundMetric={this.props.refundMetric}
        isOpenedPanel={this.state.isOpenedPanel}
        selectorPortal={selectorPortal}
      />
    );
  }

  async init() {
    if (!this.state.isPingEnabled && !this.props.availableActions) return;
    await this.getAvailableActions();
    if (this.canUpdateStatus()) {
      this.updateStatus();
    }
  }

  async getAvailableActions(): Promise<any> {
    const { transactionId } = this.props;
    if (!transactionId) return;

    this.setState({ isLoading: !this.props.availableActions });

    return getPaymentAvailableActions({ transactionId });
  }

  canUpdateStatus(): boolean {
    const { actionTypes, availableActions } = this.props;
    const types = (actionTypes || defaultActionTypes).filter(
      (type) => type !== 'payout'
    );
    return (
      this.isProcessing() ||
      types.some((type) => {
        if (type === 'refund') {
          return availableActions?.[type].isEnabled;
        }
        return availableActions?.[type].reasonCode === true;
      })
    );
  }

  updateStatus = () => {
    const { isMount } = this.props;
    if (!isMount()) {
      return this.stopUpdateStatus();
    }

    const timer = this.isProcessing()
      ? config.CHECK_PAYMENT_STATUS
      : config.CHECK_PAYMENT_STATUS_BACKGROUND;
    this.updateTimerId = setTimeout(() => {
      this.getAvailableActions().then(() => {
        if (this.canUpdateStatus()) {
          this.updateStatus();
        }
      });
    }, timer);
  };

  stopUpdateStatus = () => {
    clearTimeout(this.updateTimerId);
  };

  isProcessing = (): boolean => {
    const { availableActions, actionTypes } = this.props;
    const types = (actionTypes || defaultActionTypes).filter(
      (type) => type !== 'payout'
    );
    return types.some((type) => {
      return (
        availableActions?.[type].reasonCode ===
        OPERATION_CODES.REASON_PAYMENT_PROCESSING
      );
    });
  };

  togglePing = (isPingEnabled) => {
    this.setState({ isPingEnabled });
  };
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => {
  if (ownProps.actionsToShow) {
    const availableActions = state.paymentActions[ownProps.transactionId];

    return {
      availableActions: ownProps.actionsToShow.reduce((result, action) => {
        if (availableActions[action]) {
          return {
            ...result,
            [action]: availableActions[action],
          };
        }
        return result;
      }, {}),
    };
  }

  return {
    availableActions: state.paymentActions[ownProps.transactionId],
  };
};

export default connect(mapStateToProps)(addIsMounted(PaymentActionsContainer));
