import getLocalizedText from 'helpers/getLocalizedText';

const getMessageError = (data) => {
  let key = 'Validation error';
  if (data.payload && data.payload.validationErrors) {
    const { validationErrors } = data.payload;
    const keys = Object.keys(validationErrors);
    if (keys.length > 0) {
      if (typeof validationErrors[keys[0]] === 'object') {
        key = JSON.stringify(validationErrors[keys[0]]);
      } else {
        key = validationErrors[keys[0]];
      }
    }
  } else if (
    data.error &&
    data.error.message &&
    typeof data.error.message === 'string'
  ) {
    key = data.error.message;
  }

  return getLocalizedText(key);
};

export default getMessageError;
