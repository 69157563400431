import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';

export const updateUserNotifications = (data = {}) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.USER_NOTIFICATIONS_UPDATE,
        payload: {
          data,
        },
      })
    );
  };
};

export const newUserNotificationsItem = (item) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.USER_NOTIFICATIONS_NEW_ITEM,
        payload: {
          item,
        },
      })
    );
  };
};

export const updateUserNotificationsItem = (item = {}) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.USER_NOTIFICATIONS_UPDATE_ITEM,
        payload: {
          item,
        },
      })
    );
  };
};

export const deleteUserNotificationsItem = (item = {}) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.USER_NOTIFICATIONS_DELETE_ITEM,
        payload: {
          item,
        },
      })
    );
  };
};

export const deleteAllUserNotifications = () => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.USER_NOTIFICATIONS_DELETE_ALL,
        payload: {},
      })
    );
  };
};

export const updateUserNotificationsCounts = (counters: {}) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.USER_NOTIFICATIONS_UPDATE_COUNTS,
        payload: {
          counters,
        },
      })
    );
  };
};
