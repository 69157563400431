import {
  ChartTypesUnion,
  ChartViewData,
  DeclineReasonsData,
  FraudReportDataInner,
  GraphViewData,
  InOutData,
  TopTenData,
  ChargebackViewData,
} from 'types/Analytics';
import { v4 as uuid } from 'uuid';

import getLocalizedText from 'helpers/getLocalizedText';
import { AdvancedAnalyticsEntityType } from 'components/modal/modalList/graphDetails/components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';

const label = 'analytics.editForm.example.values.label';

const getLineChartMock = (): ChartViewData => ({
  chartName: '',
  chartGroup: AdvancedAnalyticsEntityType.operations,
  biChartId: '242',
  chartType: 'line',
  colors: ['#F88B8A', '#7FB2D7', '#F2D794', '#EEB985', '#93CCCE'],
  exporting: { enabled: false },
  legend: { enabled: true },
  credits: { enabled: false },
  xAxis: { categories: ['1', '2', '3', '4', '5'] },
  yAxis: {
    title: {
      text: getLocalizedText(label),
    },
  },
  chart: {
    marginBottom: 90,
  },
  title: {
    text: getLocalizedText('analytics.editForm.example.lineChart.label'),
  },
  series: [
    {
      name: 'Red',
      data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
    },
    {
      name: 'Blue',
      data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434],
    },
  ],
  uuid: uuid(),
});

const getPieChartMock = (): ChartViewData => ({
  chartName: '',
  chartGroup: AdvancedAnalyticsEntityType.operations,
  colors: ['#F88B8A', '#7FB2D7', '#F2D794', '#EEB985', '#93CCCE'],
  chart: {
    marginBottom: 90,
    type: 'pie',
  },
  chartType: 'pie chart',
  biChartId: '142',
  yAxis: {
    title: {
      text: getLocalizedText('analytics.editForm.example.pieChart.label'),
    },
  },
  legend: { enabled: true },
  exporting: { enabled: false },
  credits: { enabled: false },
  title: {
    text: getLocalizedText('analytics.editForm.example.pieChart.label'),
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.percentage:.1f} %</b>',
      },
    },
  },
  series: [
    {
      data: [
        {
          name: 'Red',
          y: 10,
        },
        {
          name: 'Blue',
          y: 30,
        },
        {
          name: 'Yellow',
          y: 50,
        },
      ],
    },
  ],
  uuid: uuid(),
});

const getBarChartMock = (): ChartViewData => ({
  chartName: '',
  chartGroup: AdvancedAnalyticsEntityType.operations,
  colors: ['#F88B8A', '#7FB2D7', '#F2D794', '#EEB985', '#93CCCE'],
  chart: {
    marginBottom: 90,
  },
  legend: { enabled: true },
  exporting: { enabled: false },
  credits: { enabled: false },
  chartType: 'bar',
  title: {
    text: getLocalizedText('analytics.editForm.example.barChart.label'),
  },
  xAxis: {
    categories: ['1', '2', '3', '4', '5', '6'],
  },
  yAxis: {
    title: {
      text: getLocalizedText(label),
    },
  },
  series: [
    {
      type: 'column',
      name: 'Red',
      data: [38, 50, 40, 70, 30, 10],
    },
    {
      type: 'column',
      name: 'Blue',
      data: [50, 20, 50, 70, 85, 90],
    },
  ],
  biChartId: '42',
  uuid: uuid(),
});

const getHorizontalBarChartMock = (): TopTenData => ({
  chartName: '',
  colors: ['#F88B8A'],
  chart: {
    marginBottom: 90,
  },
  legend: { enabled: false },
  exporting: { enabled: false },
  credits: { enabled: false },
  chartType: 'horizontal_bar',
  title: {
    text: getLocalizedText('analytics.editForm.example.barChart.label'),
  },
  xAxis: {
    categories: ['1', '2', '3', '4', '5', '6'],
  },
  yAxis: {
    title: {
      text: getLocalizedText(label),
    },
  },
  series: [
    {
      type: 'bar',
      name: 'Red',
      data: [70, 50, 40, 38, 30, 10],
    },
  ],
  biChartId: '42',
  chartGroup: AdvancedAnalyticsEntityType.operations,
  uuid: uuid(),
});

const inOutMock = (): InOutData => ({
  biChartId: '542',
  chart: 'inout',
  chartName: '',
  chartGroup: AdvancedAnalyticsEntityType.inout,
  chartData: 'amount',
  chartType: 'table',
  columns: ['currency', 'type'],
  rows: ['projectId', 'paymentMethod'],
  columnsGroup: {
    RUB: ['refund'],
    USD: ['refund'],
  },
  rowsGroup: {
    '1': ['mastercard', 'visa'],
    '2': ['maestro', 'mastercard', 'visa'],
  },
  tableValues: {
    '1|mastercard|RUB|refund': 1,
    '1|mastercard|USD|refund': 2,
    '1|visa|RUB|refund': 3,
    '1|visa|USD|refund': 4,
    '2|maestro|RUB|refund': 5,
    '2|mastercard|RUB|refund': 6,
    '2|visa|RUB|refund': 7,
  },

  totalColumns: {
    'RUB|refund': 22,
    'USD|refund': 6,
  },
  totalRows: {
    '1|mastercard': 3,
    '1|visa': 7,
    '2|maestro': 5,
    '2|mastercard': 6,
    '2|visa': 7,
  },
  totalSum: 28,
  uuid: uuid(),
  measureField: 'channel_amount',
  measureFunction: 'sum',
});

const declineReasonsMock = (): DeclineReasonsData => ({
  biChartId: '642',
  chart: 'pivot',
  chartType: 'table',
  chartName: '',
  chartGroup: AdvancedAnalyticsEntityType.declineReasons,
  chartData: 'count',
  rows: ['projectId', 'paymentMethod', 'declineReasons'],
  columns: ['operationType', 'currency'],
  rowsGroup: {
    '1': {
      visa: [
        'Card expired',
        'Success',
        'Operation amount is greater than limit',
      ],
      mastercard: ['Success'],
    },
    '2': {
      visa: ['Card expired'],
      mastercard: ['Success', 'Operation amount is greater than limit'],
    },
  },
  columnsGroup: {
    refund: ['RUB', 'USD'],
  },
  tableValues: {
    '1|visa|Card expired|refund|RUB': 1,
    '1|visa|Success|refund|RUB': 2,
    '1|mastercard|Success|refund|RUB': 4,
    '1|visa|Operation amount is greater than limit|refund|RUB': 3,
    '1|visa|Card expired|refund|USD': 2,
    '1|visa|Success|refund|USD': 3,
    '1|mastercard|Success|refund|USD': 5,
    '1|visa|Operation amount is greater than limit|refund|USD': 4,
    '2|visa|Card expired|refund|RUB': 5,
    '2|visa|Card expired|refund|USD': 0,
    '2|mastercard|Success|refund|USD': 0,
    '2|mastercard|Success|refund|RUB': 6,
    '2|mastercard|Operation amount is greater than limit|refund|USD': 0,
  },
  totalRows: {
    '1|visa|Card expired': 3,
    '1|visa|Success': 5,
    '1|mastercard|Success': 9,
    '1|visa|Operation amount is greater than limit': 7,
    '2|mastercard|Success': 6,
    '2|mastercard|Operation amount is greater than limit': 0,
    '2|visa|Card expired': 5,
  },
  totalColumns: {
    'refund|RUB': 21,
    'refund|USD': 14,
  },
  totalSum: 35,
  uuid: uuid(),
  measureField: 'operation_id',
});

const chargebackTableMock = (): ChargebackViewData => ({
  biChartId: '842',
  chartGroup: AdvancedAnalyticsEntityType.chargeback,
  chartType: 'simple_table',
  chartName: '',
  chartData: 'count',
  data: [
    {
      card_type: 'Visa',
      sum_amount_in_usd: '100',
      sum_channel_amount_in_usd: '50000',
      ratio_by_amount: '0.2%',
    },
    {
      card_type: 'Mastercard',
      sum_amount_in_usd: '300',
      sum_channel_amount_in_usd: '100000',
      ratio_by_amount: '0.3%',
    },
  ],
  headLine: [
    'card_type',
    'sum_amount_in_usd',
    'sum_channel_amount_in_usd',
    'ratio_by_amount',
  ],
  uuid: uuid(),
});

const fraudReportTableMock = (): FraudReportDataInner => ({
  biChartId: '842',
  chartGroup: AdvancedAnalyticsEntityType.fraud_report,
  chart: AdvancedAnalyticsEntityType.fraud_report,
  chartType: 'simple_table',
  chartName: '',
  chartData: 'count',
  data: [
    {
      card: 'Visa',
      sum_fraud_amount_in_usd: '100',
      sum_channel_amount_in_usd: '50000',
      ratio_by_amount: '0.2%',
    },
    {
      card: 'Mastercard',
      sum_fraud_amount_in_usd: '300',
      sum_channel_amount_in_usd: '100000',
      ratio_by_amount: '0.3%',
    },
  ],
  headLine: [
    'card',
    'sum_fraud_amount_in_usd',
    'sum_channel_amount_in_usd',
    'ratio_by_amount',
  ],
  uuid: uuid(),
});

const ServiceGetMockedOptionsByType = ({
  type,
  group,
}: {
  type: ChartTypesUnion | 'inout' | 'declineReasons';
  group: AdvancedAnalyticsEntityType;
}): ChartViewData => {
  switch (type) {
    case 'line':
      return getLineChartMock();
    case 'pie chart':
      return getPieChartMock();
    case 'bar':
      if (
        group === AdvancedAnalyticsEntityType.topten ||
        group === AdvancedAnalyticsEntityType.declineCodes
      ) {
        return {
          ...getBarChartMock(),
          series: [
            {
              type: 'column',
              name: 'Red',
              data: [38, 50, 40, 70, 30, 10],
            },
          ],
        } as GraphViewData;
      }

      return getBarChartMock();
    case 'horizontal_bar':
      return getHorizontalBarChartMock();
    case 'table':
      if (
          group === AdvancedAnalyticsEntityType.inout ||
          group === AdvancedAnalyticsEntityType.inout_sales_agents
      ) {
        return inOutMock();
      }

      return declineReasonsMock();
    case 'simple_table':
      if (group === AdvancedAnalyticsEntityType.fraud_report) {
        return fraudReportTableMock();
      }

      if (group === AdvancedAnalyticsEntityType.chargeback) {
        return chargebackTableMock();
      }

      return fraudReportTableMock();
    default:
      return getLineChartMock();
  }
};

export { ServiceGetMockedOptionsByType };
