import React, { PureComponent } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import classNames from 'classnames';
import { isEqual, forEach, throttle } from 'lodash';
import Input from 'components/ui/input';
import Icon from 'components/ui/icon';
import Button from 'components/ui/button';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import DateHelpers from 'helpers/Date';
import dateFormats from 'constants/dateFormats';
import './datePickerRange.scss';

import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import SliderPicker from 'components/ui/sliderPicker/SliderPicker';
import ManualInputCalendar from 'components/ui/manualInputCalendar/ManualInputCalendar';
import breakpoints from 'constants/breakpoints';
import { getMaxDate } from 'components/ui/datePicker/helpers';

interface Range {
  from: Date;
  to: Date;
}

interface RangeFormat {
  from: string; // DD.MM.YYYY HH:mm:ss
  to: string; // DD.MM.YYYY HH:mm:ss
}

interface Time {
  from: string; // HH:mm:ss
  to: string; // HH:mm:ss
}

interface Props extends IntlProps {
  onApplyRange: (range: RangeFormat) => void;
  range?: Range | RangeFormat;
  showMonths?: number;
  customClass?: string;
  applyFormat?: keyof typeof dateFormats;
  inputLabel?: string;
  disabled?: boolean;
  withTime?: boolean;
  hideManualDate?: boolean;
  metricName?: string;
  minDate?: string;
  maxDate?: string;
  isOpenedMenu: boolean;
}

interface State {
  range: Range;
  month: any;
  time: Time;
  fromMonth: Date;
  toMonth: Date;
  monthsToShow: number | undefined;
}

const MIN_YEAR = DateHelpers.getDate().year() - 2;
const MAX_YEAR = DateHelpers.getDate().year();

class DatePickerRange extends PureComponent<Props, State> {
  private readonly initialRange;

  static defaultProps = {
    showMonths: 1,
    applyFormat: 'datetime',
    inputLabel: 'ui.datePicker.textInfo',
    withTime: true,
  } as const;

  constructor(props) {
    super(props);

    const range: Range = this.getInitialRange();
    if (props.range) {
      this.initialRange = JSON.parse(JSON.stringify(props.range));
    }

    this.state = {
      range,
      fromMonth: this.setMinDate(),
      toMonth: this.setMaxDate(),
      month: DateHelpers.createDate(range.from, 'date').toDate(),
      time: {
        from: DateHelpers.getTimeFromDate(range.from),
        to: DateHelpers.getTimeFromDate(range.to),
      },
      monthsToShow: this.setMonthsToShow(),
    };
    this.handleResize = throttle(this.handleResize, 200);
  }

  componentDidMount() {
    this.setLimits();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(_, prevState: Readonly<State>) {
    if (prevState.monthsToShow !== this.state.monthsToShow) {
      const month = this.state.month;
      this.setState({ month: '' }, () => {
        this.changeMonth(month);
      });
    }
  }

  render() {
    const {
      customClass,
      withTime,
      getTranslate,
      disabled,
      hideManualDate,
      minDate,
    } = this.props;
    const {
      fromMonth,
      toMonth,
      range: { from, to },
      time,
      month,
      monthsToShow,
    } = this.state;

    const modifiers = { start: from, end: to };

    return (
      <div
        className={classNames(
          'ui-date-picker-range date-picker-custom',
          customClass
        )}>
        <div className='ui-date-picker-range__main'>
          {this.renderRangeSlider()}
          <DayPicker
            locale={moment.locale()}
            localeUtils={MomentLocaleUtils}
            numberOfMonths={monthsToShow}
            selectedDays={[from, { from, to }]}
            modifiers={modifiers}
            fromMonth={fromMonth}
            toMonth={toMonth}
            month={month}
            fixedWeeks={true}
            showOutsideDays={true}
            firstDayOfWeek={1}
            onMonthChange={this.changeMonth}
            onDayClick={this.changeRange}
            captionElement={({ date }) => this.renderCaption(date)}
            renderDay={(date) => this.renderDay(date)}
            navbarElement={(options) => this.renderNavigation(options)}
            disabledDays={[
              {
                before: fromMonth,
                after: toMonth,
              },
            ]}
          />
        </div>
        <div className='ui-date-picker-range__footer'>
          {(!hideManualDate || withTime) && (
            <div className='ui-date-picker-range__footer-col'>
              {!hideManualDate && (
                <div className='ui-date-picker-range__footer-item'>
                  <ManualInputCalendar
                    id='ui-date-picker-range-from-date'
                    value={{
                      dateFrom: DateHelpers.getFormat(
                        DateHelpers.createDate(from)
                      ),
                    }}
                    withTime={false}
                    onChange={(date) => {
                      this.onChangeDateField(date.dateFrom, 'from');
                    }}
                    disableButton
                    needIcon
                    customClass='ui-date-picker-range__date-field'
                    minDate={minDate}
                  />
                  &mdash;
                  <ManualInputCalendar
                    id='ui-date-picker-range-to-date'
                    value={{
                      dateFrom: DateHelpers.getFormat(
                        DateHelpers.createDate(to)
                      ),
                    }}
                    withTime={false}
                    onChange={(date) => {
                      this.onChangeDateField(date.dateFrom, 'to');
                    }}
                    disableButton
                    needIcon
                    customClass='ui-date-picker-range__date-field'
                  />
                </div>
              )}

              {withTime && (
                <div className='ui-date-picker-range__footer-item'>
                  <Input
                    disabled={disabled}
                    id='from'
                    placeholder='00:00:00'
                    value={time.from}
                    cleaveOptions={{
                      time: true,
                      timePattern: ['h', 'm', 's'],
                    }}
                    onChange={({ target }) =>
                      this.changeTime('from', target.value)
                    }
                    prefix={<Icon size={16} name='im-Timezone' />}
                    customClass='ui-date-picker-range__time-field'
                  />
                  &mdash;
                  <Input
                    disabled={disabled}
                    id='to'
                    placeholder='23:59:59'
                    value={time.to}
                    cleaveOptions={{
                      time: true,
                      timePattern: ['h', 'm', 's'],
                    }}
                    onChange={({ target }) =>
                      this.changeTime('to', target.value)
                    }
                    prefix={<Icon size={16} name='im-Timezone' />}
                    customClass='ui-date-picker-range__time-field'
                  />
                </div>
              )}
            </div>
          )}

          {!disabled && (
            <div className='ui-date-picker-range__footer-item ui-date-picker-range__footer-item_button'>
              <Button
                onClick={this.applyRange}
                disabled={!this.canApplyRange()}
                status='primary'
                id='apply-quick-dates'
                text={getTranslate('ui.datePicker.apply')}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  renderDay = (day) => {
    return <span>{DateHelpers.createDate(day).date()}</span>;
  };

  renderCaption(date) {
    const months = DateHelpers.getMonths();
    const currentMonth = months[DateHelpers.getMonth(date)];
    const currentYear = DateHelpers.getYear(date);

    return (
      <div className='DayPicker-Caption'>
        {currentMonth} {currentYear}
      </div>
    );
  }

  renderRangeSlider = () => {
    const { fromMonth, month } = this.state;
    const minYearValue = DateHelpers.getYear(fromMonth);
    const currentYear = DateHelpers.getYear(month);

    return (
      <div className='DayPicker-Year'>
        <SliderPicker
          min={Number(minYearValue)}
          max={Number(MAX_YEAR)}
          value={currentYear}
          onChange={this.changeYear}
          isRange
        />
      </div>
    );
  };

  renderNavigation = (options) => {
    return (
      <div className='DayPicker-NavBar'>
        <Icon
          name='im-Arrow-left-Option-2'
          size={16}
          className={classNames(
            'DayPicker-NavButton DayPicker-NavButton--prev',
            {
              'DayPicker-NavButton--interactionDisabled':
                !options.showPreviousButton,
            }
          )}
          onClick={() => options.onPreviousClick()}
        />
        <Icon
          name='im-Arrow-right-Option-2'
          size={16}
          className={classNames(
            'DayPicker-NavButton DayPicker-NavButton--next',
            {
              'DayPicker-NavButton--interactionDisabled':
                !options.showNextButton,
            }
          )}
          onClick={() => options.onNextClick()}
        />
      </div>
    );
  };

  /**
   *
   * @param newYear
   */
  changeYear = (newYear) => {
    const { month, fromMonth } = this.state;
    const newDate = DateHelpers.getDate()
      .month(DateHelpers.createDate(month).month())
      .year(newYear)
      .toDate();

    this.setState({
      month: fromMonth > newDate ? fromMonth : newDate,
    });
  };

  /**
   *
   * @param month
   */
  changeMonth = (month) => {
    this.setState({ month });
  };

  /**
   * При инициализации компонента будет сформирован интервал с выбранными датами
   * или текущая, если ничего не передано
   */
  getInitialRange(): Range {
    const { range } = this.props;
    if (range && range.from && range.to) {
      return {
        from: DateHelpers.createDate(range.from, 'datetime').toDate(),
        to: DateHelpers.createDate(range.to, 'datetime').toDate(),
      };
    }

    const currentDate = DateHelpers.getDate();
    return DateHelpers.getRangeWithDefaultTime({
      from: currentDate,
      to: currentDate,
    });
  }

  /**
   * Обработчик изменения интервала, срабатывает при нажатии на день
   * @param day
   */
  changeRange = (day) => {
    const { range, time } = this.state;
    const newRange = DateUtils.addDayToRange(day, range);
    const rangeWithTime: any = {};

    // При установке новых дат, добавляем им время из стейта
    forEach(newRange, (date, type) => {
      if (!date) {
        rangeWithTime[type] = date;
      } else {
        rangeWithTime[type] = DateHelpers.setTime(date, time[type]);
      }
    });

    this.setState({
      range: rangeWithTime,
    });
  };

  onChangeDateField = (date, fieldType: 'from' | 'to') => {
    const [day, month, year] = date.split('.');

    this.setState((state) => ({
      range: {
        ...state.range,
        [fieldType]: new Date(`${month}.${day}.${year}`),
      },
    }));
  };
  /**
   * Преобразовывает данные перед отправкой к типу RangeFormat
   */
  getRangeForApply = (): RangeFormat => {
    const { applyFormat } = this.props;
    const { range, time } = this.state;
    const finalRange = { ...range };
    forEach(finalRange, (timeValue, type) => {
      const correctTime: string = DateHelpers.validateTime(
        time[type],
        type === 'from'
      );
      finalRange[type] = DateHelpers.setTime(range[type], correctTime);
    });

    return {
      from: DateHelpers.getFormat(
        DateHelpers.createDate(finalRange.from),
        applyFormat
      ),
      to: DateHelpers.getFormat(
        DateHelpers.createDate(finalRange.to),
        applyFormat
      ),
    };
  };

  /**
   * Вернуть данные в родительский компонент
   */
  applyRange = (): void => {
    const { onApplyRange } = this.props;
    if (this.canApplyRange()) {
      onApplyRange(this.getRangeForApply());
    }
  };

  /**
   * Условия для применения:
   * - Должны быть выбраны две даты в календаре
   * - Начальный интервал не совпадает с текущим
   */
  canApplyRange = (): boolean => {
    const { range } = this.state;
    return (
      Boolean(range.from && range.to) &&
      !isEqual(this.initialRange, this.getRangeForApply())
    );
  };

  /**
   * Обработчик на изменения времени
   * @param key
   * @param value
   */
  changeTime = (key: string, value: string): void => {
    const { range, time } = this.state;

    this.setState({
      range: {
        ...range,
        [key]: DateHelpers.setTime(range[key], value),
      },
      time: {
        ...time,
        [key]: value,
      },
    });
  };

  setMinDate = () => {
    const { minDate } = this.props;
    const startDate = minDate
      ? DateHelpers.createDate(minDate, 'datetime')
      : DateHelpers.getDate();

    const minYear = minDate ? DateHelpers.getYear(startDate) : MIN_YEAR;
    const currentMonth = startDate.month();

    return startDate.year(minYear).month(currentMonth).date(1).toDate();
  };

  setMaxDate = () => {
    const currentMonth = DateHelpers.getDate().month();
    return DateHelpers.getDate().year(MAX_YEAR).month(currentMonth).toDate();
  };

  setLimits = () => {
    this.setMinDate();
    const toMonth = getMaxDate(this.props.maxDate) || this.setMaxDate();
    const fromMonth = this.setMinDate();
    this.setState({ toMonth, fromMonth });
  };

  handleResize = () => {
    this.setState({ monthsToShow: this.setMonthsToShow() });
  };

  setMonthsToShow = () => {
    if (window.innerWidth <= breakpoints.datePicker) {
      return 1;
    } else if (window.innerWidth < breakpoints.commonTablet) {
      return 2;
    } else if (window.innerWidth < 900) {
      return this.props.isOpenedMenu &&
        this.props.showMonths &&
        this.props.showMonths > 2
        ? 2
        : this.props.showMonths;
    }
    return this.props.showMonths || 1;
  };
}

export default addTranslation(DatePickerRange);
