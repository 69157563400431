import { tableRoutes, createRoutes } from './routesMapper';
import tableNames from 'constants/tableNames';
import urlsMap from 'constants/urlsMap';
import { AnyObject } from 'types/Common';
import { tableNameType } from './RemittanceContainer';

type tableNameT = tableNames.remittancePaymentBatch | tableNameType;

export const getPath = (
  tableName: tableNameT | tableNames.remittancePaymentBatch,
  data: AnyObject = {},
  isCreate = false
) => {
  const createPath = isCreate ? `/${createRoutes[tableName]}` : '';

  switch (tableName) {
    case tableNames.remittancePayments: {
      if (isCreate) {
        return `/${urlsMap.remittance}${createPath}`;
      }
      return `/${urlsMap.remittance}/${encodeURIComponent(
        data.transactionId
      )}?name=${encodeURIComponent(data.paymentId)}`;
    }
    case tableNames.massRemittancePayments: {
      if (isCreate) {
        return `/${urlsMap.remittance}${createPath}`;
      }
      return `/${urlsMap.remittance}/${encodeURIComponent(
        data.id
      )}?type=batch&name=${encodeURIComponent(`Batch ${data.id}`)}`;
    }
    case tableNames.remittancePaymentBatch:
      if (data.transactionId && data.paymentId) {
        return `/${urlsMap.remittance}/${encodeURIComponent(
          data.transactionId
        )}?name=${encodeURIComponent(data.paymentId)}`;
      }
      return '';
    case tableNames.remittanceRecipientAccounts:
      if (isCreate) {
        return `/${urlsMap.remittance}${createPath}`;
      }
      return `/${urlsMap.remittance}/${encodeURIComponent(
        data.remittanceRecipientAccountId
      )}`;
  }
};

export const isCustomId = (id) => {
  if (!id) return;
  return (
    !Object.values(tableRoutes).includes(id) &&
    !Object.values(createRoutes).includes(id)
  );
};
