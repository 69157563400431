import React, { PureComponent, ReactNode } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import './tab.scss';

interface Props {
  title: ReactNode;
  isDisabled?: boolean;
  className?: string;
  id?: string;
  icon?: string;
}

class Tab extends PureComponent<Props> {
  static defaultProps = {
    isDisabled: false,
  };

  render() {
    const {
      title,
      tabIndex,
      id,
      icon,
      isActive,
      isDisabled,
      theme,
      mode,
      onClick,
      className,
    }: any = this.props;

    const classes = classNames('ui-tab', className, {
      'ui-tab_active': isActive,
      'ui-tab_disabled': isDisabled,
      [`ui-tab_${theme}`]: theme,
      [`ui-tab_${mode}`]: mode,
    });

    return (
      <div onClick={() => onClick(tabIndex, id)} className={classes}>
        <div className='ui-tab__inner'>
          {!icon && isActive && theme === 'vertical' && (
            <Icon name='im-Marker' size={16} className='ui-tab__marker' />
          )}
          {icon && <Icon name={icon} size={12} className='ui-tab__icon' />}
          {title}
        </div>
      </div>
    );
  }
}

export default Tab;
