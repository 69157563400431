import React, { FormEvent, PureComponent } from 'react';
import classNames from 'classnames';
import './form.scss';

interface Props {
  id?: string;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  customClass?: string;
}

class Form extends PureComponent<Props> {
  render() {
    const { children, customClass, id } = this.props;

    return (
      <form
        id={id}
        onSubmit={this.onSubmit}
        className={classNames('ui-form', customClass)}>
        {children}
      </form>
    );
  }

  onSubmit = (event: FormEvent<HTMLFormElement>) => {
    const { onSubmit } = this.props;
    event.preventDefault();
    onSubmit(event);
  };
}

export default Form;
