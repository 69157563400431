import React, { PureComponent } from 'react';
import Form from 'components/ui/form';
import Input from 'components/ui/input';
import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import './create.scss';

interface Props {
  form: {
    ticket: string;
    token: string;
    en: string;
    ru: string;
  };
  isLoading: boolean;
  onClickUpload: () => void;
  onChange: (key: string, value: any) => void;
  onCreate: () => void;
}

class Create extends PureComponent<Props> {
  render() {
    const { form, isLoading, onChange, onCreate, onClickUpload } = this.props;
    const { ticket, token, en, ru } = form;

    return (
      <div className='lc-manage-create'>
        <Form customClass='lc-manage-create__form' onSubmit={onCreate}>
          <div className='lc-manage-create__form-block'>
            <Input
              id='lc-ticket'
              label='Ticket'
              value={ticket}
              placeholder='Enter ticket'
              onChange={({ target: { value } }) => onChange('ticket', value)}
            />
          </div>
          <div className='lc-manage-create__form-block'>
            <Input
              id='lc-token'
              label='Token'
              value={token}
              placeholder='Enter token'
              customClass='lc-create-input-token'
              onChange={({ target: { value } }) => onChange('token', value)}
            />
          </div>
          <div className='lc-manage-create__form-block'>
            <Input
              id='lc-en'
              label='En'
              value={en}
              placeholder='Enter translation'
              onChange={({ target: { value } }) => onChange('en', value)}
            />
          </div>
          <div className='lc-manage-create__form-block'>
            <Input
              id='lc-ru'
              label='Ru'
              value={ru}
              placeholder='Enter translation'
              onChange={({ target: { value } }) => onChange('ru', value)}
            />
          </div>
          <div className='lc-manage-create__form-block'>
            <Button
              text='Create'
              status='success'
              type='submit'
              customClass='lc-manage-create__form-button'
            />
          </div>
        </Form>
        <Button
          text='Mass upload'
          status='primary'
          onClick={onClickUpload}
          customClass='lc-manage-create__upload-button'
        />
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default Create;
