import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

const initialState: any = null;

const app = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.INIT_APP:
      return { ...payload, isConfirm: false };
    case ACTIONS.CONFIRM_LEAVE_PAGE:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default app;
