import BaseMessageHandler from '../BaseMessageHandler';
import DateUtils from 'helpers/Date';

export default class Qa_EnableApiLogs extends BaseMessageHandler {
  success = () => {
    const {
      message: { payload },
    } = this;
    console.log(
      `%cApi logs enabled ${DateUtils.getFormat(
        DateUtils.createDate(payload.timeoutTs * 1000)
      )}`,
      'color: #3498db; font-weight: bold'
    );
  };
}
