import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import api from 'api/dictionaries';
import { initAllConfiguration } from 'actions/configuration';
import { changeQuickFilter } from 'actions/quickFilters';
import { StoreProps } from 'store';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { WithRouterProps } from 'decorators/withRouter';

import Messages from 'constants/rpcTypes';
import filtersKeys from 'constants/filters';
import urlsMap from 'constants/urlsMap';
import MetricService from 'helpers/metricService/MetricService';
import path from 'helpers/path';

import PayoutsPage from './PayoutsPage';
import { QuickFiltersTableName } from 'types/QuickFilters';
import { tableRoutes } from './routesMapper';
import tableNames from 'constants/tableNames';

type Props = StoreProps &
  WithPermissions &
  WithRouterProps<{ id: string; formId: string }>;

interface State {
  tableName: QuickFiltersTableName;
}

class PayoutsContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tableName:
        props.match.params.id === tableRoutes[tableNames.massPayouts]
          ? tableNames.massPayouts
          : tableNames.payouts,
    };
    this.init();
  }

  async componentDidMount() {
    // TODO Обновление колонок при переходе в раздел, перевести на сокеты или rpc
    const list = await api.getTableColumns();
    this.props.dispatch(initAllConfiguration(list));

    if (this.props.match.params.id === tableRoutes[tableNames.massPayouts]) {
      this.setState({
        tableName: tableNames.massPayouts,
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.match.url !== this.props.match.url) {
      if (this.props.match.params.id === tableRoutes[tableNames.massPayouts]) {
        this.setState({
          tableName: tableNames.massPayouts,
        });
      } else {
        this.setState({
          tableName: tableNames.payouts,
        });
      }
    }
  }

  render() {
    const {
      match: { params },
      location,
    } = this.props;

    const isCreating = location.pathname.includes(`${urlsMap.payouts}/request`);
    const { type } = queryString.parse(location.search);
    const isBatchDetail = Boolean(type && type === 'batch');

    return (
      <PayoutsPage
        id={params.id !== tableRoutes[tableNames.massPayouts] ? params.id : ''}
        isCreating={isCreating}
        isBatchDetail={isBatchDetail}
        tableName={this.state.tableName}
        changeTable={this.changeTable}
      />
    );
  }

  init() {
    const { isEnabled, dispatch } = this.props;
    let initialType = 'single';
    if (
      !isEnabled(Messages.PaymentOperation_Payout) &&
      (isEnabled(Messages.BulkPayouts_UploadPayout) ||
        isEnabled(Messages.BulkPayouts_UploadRefund))
    ) {
      initialType = 'mass';
    }
    dispatch(
      changeQuickFilter('payouts', filtersKeys.payoutsType, [initialType])
    );
  }

  changeTable = (tableName) => {
    this.setState({ tableName });

    const actionKey =
      tableName === tableNames.massPayouts
        ? 'manualPayments.fastFilter.type.mass'
        : 'manualPayments.fastFilter.type.allRequests';
    MetricService.send({ action: 'click', actionKey });

    if (tableName === tableNames.massPayouts) {
      this.props.match.params.id !== tableRoutes[tableNames.massPayouts] &&
        this.props.history.push(
          path(`/${urlsMap.payouts}/${tableRoutes[tableNames.massPayouts]}`)
        );
    } else {
      this.props.history.push(path(`/${urlsMap.payouts}`));
    }
  };
}

export default withRouter(connect()(addPermissions(PayoutsContainer)));
