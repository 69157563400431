import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import StatementPendingAmountTableRow from './components/statementPendingAmountTableRow';

interface Props extends IntlProps {
  title: string;
  data?: any;
  columns?: any;
}

class StatementPendingAmountTable extends PureComponent<Props> {
  static defaultProps = {
    data: {},
  };

  render() {
    const { title, columns, data, getTranslate } = this.props;

    return (
      <div className='statement-totals__group'>
        <div className='statement-totals__row'>
          <div className='statement-totals__col statement-totals__col_title'>
            <span className='statement-totals__col-inner'>
              {getTranslate(title)}
            </span>
          </div>
          {/** Extra columns to fix custom scroll styles behavior */}
          {columns.map((col) => (
            <div className='statement-totals__col' key={col} />
          ))}
        </div>
        {Object.keys(data).map((key, index) => (
          <StatementPendingAmountTableRow
            columns={columns}
            period={key}
            data={data[key]}
            key={`row-${index}`}
          />
        ))}
      </div>
    );
  }
}

export default addTranslation(StatementPendingAmountTable);
