import React, { PureComponent } from 'react';
import classNames from 'classnames';
import CustomScrollbar from 'components/ui/customScrollbar';
import Icon from 'components/ui/icon';
import Chip from 'components/ui/chip';
import './listView.scss';

interface Props {
  list: string[];
  maxHeight: number;
  isLoading?: boolean;
  customClass?: string;
}

class ListView extends PureComponent<Props> {
  static defaultProps = {
    maxHeight: 200,
  };

  render() {
    const { isLoading, customClass } = this.props;

    return (
      <div
        className={classNames('ui-list-view', customClass, {
          'ui-list-view_loading': isLoading,
        })}>
        <CustomScrollbar recalculateHeightOnResize={true}>
          <div className='ui-list-view__wrapper'>
            <ul className='ui-list-view__inner'>
              {isLoading ? (
                <Icon name='im-Ellipse' size={16} className='rotating' />
              ) : (
                this.renderList()
              )}
            </ul>
          </div>
        </CustomScrollbar>
      </div>
    );
  }

  renderList = () => {
    const { list } = this.props;

    if (list.length === 0) {
      return '-';
    }
    return list.map((item, index) => {
      return (
        <li className='ui-list-view__item' key={`${item}-${index}`}>
          <Chip text={item} id={item} theme='grey-round' />{' '}
        </li>
      );
    });
  };
}
export default ListView;
