import React, { Component } from 'react';
import { throttle } from 'lodash';
import classNames from 'classnames';
import Button from 'components/ui/button';
import Loader from 'components/ui/loader';
import CustomScrollbar from 'components/ui/customScrollbar';
import FlagItem from 'components/ui/flagItem';
import BrowserItem from 'components/ui/browserItem';
import DeviceItem from 'components/ui/deviceItem';
import ActivityType from './ActivityType';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import breakpoints from 'constants/breakpoints';
import './profileActivity.scss';

interface Props extends IntlProps {
  list: ActivityType[];
  moreRows: boolean;
  isLoading: boolean;
  onClick: () => void;
  terminateSessions: () => void;
  customClass: string;
}

interface State {
  tableHeight: number;
}

class ProfileActivity extends Component<Props, State> {
  private tableRef;
  private scrollbarRef;

  constructor(props) {
    super(props);

    this.state = {
      tableHeight: 0,
    };

    this.calcTableHeight = throttle(this.calcTableHeight, 100);
    this.attachEvents();
  }

  componentWillUnmount(): void {
    this.detachEvents();
  }

  componentDidUpdate(prevProps): void {
    if (prevProps.isLoading && !this.props.isLoading) {
      this.calcTableHeight();
    }
  }

  render() {
    const {
      list,
      isLoading,
      moreRows,
      terminateSessions,
      getTranslate,
      customClass,
    } = this.props;
    const { tableHeight } = this.state;

    return (
      <div className={classNames('profile-activity', customClass)}>
        <div className='card card_l'>
          <div className='card__header card__header_space-between'>
            <div className='card__title card__title-l'>
              {getTranslate('profile.myactivity.header')}
            </div>
            <Button
              text={getTranslate(
                'profile.userprofile.completeAllSessions.button'
              )}
              size='normal'
              status='primary'
              onClick={terminateSessions}
            />
          </div>

          <div className='profile-activity__content'>
            <div className='profile-activity__table'>
              <div className='profile-activity__table-row profile-activity__table-row_header'>
                <div className='profile-activity__table-cell profile-activity__table-cell_time'>
                  {getTranslate('profile.myactivity.timeAndDate.label')}
                </div>
                <div className='profile-activity__table-cell profile-activity__table-cell_activity'>
                  {getTranslate('profile.myactivity.activity.label')}
                </div>
                <div className='profile-activity__table-cell profile-activity__table-cell_browser'>
                  {getTranslate('profile.myactivity.browser.label')}
                </div>
                <div className='profile-activity__table-cell profile-activity__table-cell_device'>
                  {getTranslate('profile.myactivity.device.label')}
                </div>
                <div className='profile-activity__table-cell profile-activity__table-cell_location'>
                  {getTranslate('profile.myactivity.location.label')}
                </div>
              </div>

              <div
                style={{ height: tableHeight }}
                ref={(el) => {
                  this.tableRef = el;
                }}
                className='profile-activity__table-body'>
                {list.length > 0 && (
                  <CustomScrollbar
                    getRef={(el) => {
                      this.scrollbarRef = el;
                    }}>
                    {list.map((item) => this.renderActivityItem(item))}
                  </CustomScrollbar>
                )}
              </div>

              <div className='profile-activity__footer'>
                {moreRows && this.renderButton()}
              </div>
              {isLoading && <Loader />}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderActivityItem = (item) => {
    const { getTranslate } = this.props;
    return (
      <div className='profile-activity__table-row' key={item.id}>
        <div className='profile-activity__table-cell profile-activity__table-cell_time'>
          <span className='profile-activity__item-title'>
            {getTranslate('profile.myactivity.timeAndDate.label')}
          </span>

          <span className='profile-activity__item-inner'>
            <span className='profile-activity__text'>{item.dateDay}</span>
            <span className='profile-activity__text'>{item.dateTime}</span>
          </span>
        </div>
        <div className='profile-activity__table-cell profile-activity__table-cell_activity'>
          <span className='profile-activity__item-title'>
            {getTranslate('profile.myactivity.activity.label')}
          </span>

          <span className='profile-activity__text'>{item.action}</span>
        </div>
        <div className='profile-activity__table-cell profile-activity__table-cell_browser'>
          <span className='profile-activity__item-title'>
            {getTranslate('profile.myactivity.browser.label')}
          </span>

          <BrowserItem
            browserSrc={item.browserCode}
            browserTitle={item.browserName}
            customClass='profile-activity__cell-item browser-item_activity'
          />
        </div>
        <div className='profile-activity__table-cell profile-activity__table-cell_device'>
          <span className='profile-activity__item-title'>
            {getTranslate('profile.myactivity.device.label')}
          </span>

          <DeviceItem
            customClass='profile-activity__cell-item device-item_activity'
            deviceCode={item.deviceId}
            deviceTitle={item.deviceName}
          />
        </div>
        <div className='profile-activity__table-cell profile-activity__table-cell_location'>
          <span className='profile-activity__item-title'>
            {getTranslate('profile.myactivity.location.label')}
          </span>

          <FlagItem
            flagName={item.countryCode}
            ipAddress={item.ipAddress}
            customClass='profile-activity__cell-item flag-item_activity'
          />
        </div>
      </div>
    );
  };

  renderButton = () => {
    const { onClick, moreRows, getTranslate } = this.props;
    if (!moreRows) return null;

    return (
      <Button
        customClass='ui-button_fly'
        text={getTranslate('profile.myactivity.showMore.button')}
        icon='im-Arrow-down'
        size='inline'
        iconSize={9}
        onClick={onClick}
      />
    );
  };
  attachEvents = () => {
    window.addEventListener('resize', this.calcTableHeight);
  };

  detachEvents = () => {
    window.removeEventListener('resize', this.calcTableHeight);
  };

  calcTableHeight = () => {
    if (!this.tableRef) return;

    const mobileScreen = window.innerWidth < breakpoints.profileActivity;
    const tablePaddings = 10;
    const bottomTableSpace = mobileScreen ? 99 : 33;
    const policyHeight = 33;
    let rowsHeight = 0;
    const tableRows = this.tableRef.querySelectorAll(
      '.profile-activity__table-row'
    );
    const bottom =
      window.innerHeight -
      (document.body.querySelector('.page-profile')?.getBoundingClientRect()
        .bottom || policyHeight) +
      bottomTableSpace;
    const { top } = this.tableRef.getBoundingClientRect();
    const maxHeight = window.innerHeight - top - bottom;
    tableRows.forEach((item) => {
      rowsHeight += item.offsetHeight;
    });
    const total = rowsHeight + tablePaddings;
    const defaultHeight = mobileScreen ? 534 : 212;
    this.setState({
      tableHeight: Math.max(
        defaultHeight,
        total > maxHeight ? maxHeight : total
      ),
    });

    if (this.scrollbarRef && !mobileScreen) {
      this.scrollbarRef.scrollTop = total;
    }
  };
}
export default addTranslation(ProfileActivity);
