import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { isEqual } from 'lodash';

import { getCurrencyRates } from 'api/finance';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { StoreProps } from 'store';

import CurrencyRates from './components/currencyRates';
import CurrencyRatesFilters from './components/filters';
import FinancialTabEmptyState from '../financialTabEmptyState';
import Loader from 'components/ui/loader';

import showNotification from 'components/ui/notification/showNotification';
import CurrencyRatesEmptyImage from '../images/currency-rates-empty-image.svg';
import getMessageError from 'helpers/getMessageError';
import { Dictionary } from 'types/FilterValue';
import { QuickCurrencyRatesFiltersType } from 'types/QuickFilters';

import './currencyRatesContainer.scss';

interface ConnectedProps {
  filters: QuickCurrencyRatesFiltersType;
  currency: Dictionary;
  merchants: Dictionary;
}

type Props = ConnectedProps & StoreProps & IntlProps;

interface State {
  isLoading: boolean;
  filterLoading: boolean;
  list: {
    items: any[];
    isFetched: boolean;
  };
}

class CurrencyRatesContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      filterLoading: true,
      list: {
        items: [],
        isFetched: false,
      },
    };
  }

  formatDateForRest(payload) {
    const [date] = payload.split(' ');
    const [day, month, year] = date.split('.');

    return `${year}-${month}-${day}`;
  }

  formatDateForTable(payload) {
    const [date, time] = payload.split(' ');
    const [year, month, day] = date.split('-');

    return `${day}.${month}.${year} ${time}`;
  }

  async fetchCurrencyRates() {
    const { filters } = this.props;

    if (!filters.actualDate || !filters.currency || !filters.merchantId) {
      return;
    }

    this.setState({ isLoading: true });

    try {
      const params = {
        filter: {
          currency: filters.currency,
          merchantId: filters.merchantId,
          actualDate: this.formatDateForRest(filters.actualDate),
        },
      };

      const { currencyRates } = await getCurrencyRates(params);

      this.setState({
        list: {
          items: currencyRates,
          isFetched: true,
        },
      });
    } catch (error: any) {
      if (!error.message || !error.payload) return;

      showNotification({
        status: 'error',
        content: getMessageError(error),
      });

      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    const { filters: prevFilters } = prevProps;
    const { currency, merchants, filters } = this.props;

    if (currency?.isFetched && merchants?.isFetched) {
      this.setState({
        filterLoading: false,
      });
    }

    if (!isEqual(prevFilters, filters)) {
      return this.fetchCurrencyRates();
    }
  }

  render() {
    const { filters, currency, merchants, getTranslate } = this.props;
    const { list, isLoading, filterLoading } = this.state;
    const isEmptyList = list && list.isFetched && !list.items.length;
    const isNotFetched = !list || !list.isFetched;

    return (
      <div className='financial-tab card card_l page-currency-rates'>
        <div className='financial-tab__header card__header card__header_bordered'>
          <div className='financial-tab__title'>
            {getTranslate('currencyRates.title')}
          </div>
          <div className='financial-tab__text card__subtext'>
            {getTranslate('currencyRates.caption')}
          </div>
          <CurrencyRatesFilters
            filters={filters}
            currency={currency}
            merchants={merchants}
          />
          {filterLoading && <Loader />}
        </div>
        <div
          className={classNames('financial-tab__content', {
            'financial-tab__content_empty': isEmptyList || isNotFetched,
          })}>
          {!isNotFetched && !isEmptyList ? (
            <CurrencyRates data={list} filters={filters} />
          ) : (
            <FinancialTabEmptyState
              title={isNotFetched ? 'currencyRates.emptyTitle' : ''}
              text={
                isNotFetched
                  ? 'currencyRates.emptyCaption'
                  : 'currencyRates.notFound'
              }
              picturePath={isNotFetched ? CurrencyRatesEmptyImage : ''}
            />
          )}
        </div>

        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state): ConnectedProps => {
  const { filtersValues, quickFilters } = state;

  return {
    filters: quickFilters.currencyRates,
    currency: filtersValues?.currency,
    merchants: filtersValues?.userMerchant,
  };
};

export default connect(mapStateToProps)(addTranslation(CurrencyRatesContainer));
