import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as Redux from 'redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import PolicyContainer from '../PolicyContainer';
import { AnyObject } from 'types/Common';
import ThemeConfig from 'types/ThemeConfig';

interface OwnProps {
  history?: any;
  dispatch?: Redux.Dispatch<any>;
  children?: any;
}

interface ConnectedProps {
  settings: {
    themeConfig: ThemeConfig;
    lexemeMap: AnyObject;
  };
}

type Props = OwnProps & ConnectedProps & IntlProps;

class CookiePolicy extends PureComponent<Props> {
  render() {
    const { getTranslate } = this;
    const { common = {}, cookie = {} } = this.props.settings.lexemeMap;

    return (
      <PolicyContainer {...this.props}>
        <h1>{getTranslate('cookies.policy.label')}</h1>

        <div className='policy__block'>
          <h2>{getTranslate('cookie.policy.heading1')}</h2>
          <p>
            {getTranslate(cookie.text1, {
              link1: (
                <a
                  href={getTranslate(common.link1, {
                    inline: true,
                  })}
                  className='policy__link'
                  rel='noopener noreferrer'
                  target='_blank'>
                  {getTranslate(common.link1)}
                </a>
              ),
            })}
          </p>
          <p>{getTranslate('cookie.policy.text1a')}</p>
        </div>

        <div className='policy__block'>
          <h2>{getTranslate('cookie.policy.heading2')}</h2>

          <p>{getTranslate('cookie.policy.text2')}</p>
          <p>{getTranslate('cookie.policy.text2a')}</p>

          <p>{getTranslate('cookies.privacyPolicy.text06')}</p>
        </div>
        <div className='policy__block'>
          <h2>{getTranslate('cookie.policy.heading2a')}</h2>
          <p>{getTranslate('cookie.policy.text2b')}</p>
        </div>
        <div className='policy__block'>
          <h2>{getTranslate('cookie.policy.heading3')}</h2>
          <p>{getTranslate('cookie.policy.text3')}</p>

          <ol>
            <li className='policy__sub-block'>
              <h3>{getTranslate('cookie.policy.heading31')}</h3>

              <p>{getTranslate('cookie.policy.text31')}</p>
              <p>{getTranslate('cookie.policy.text31a')}</p>
            </li>
            <li className='policy__sub-block'>
              <h3>{getTranslate('cookie.policy.heading32')}</h3>

              <p>{getTranslate('cookie.policy.text32')}</p>
            </li>
            <li className='policy__sub-block'>
              <h3>{getTranslate('cookie.policy.heading33')}</h3>

              <p>{getTranslate('cookie.policy.text33')}</p>
            </li>
          </ol>
          <div className='policy__sub-block'>
            <p>{getTranslate('cookie.policy.text33a')}</p>
          </div>
        </div>

        <div className='policy__block'>
          <h2>{getTranslate('cookie.policy.heading4')}</h2>

          <p>{getTranslate('cookie.policy.text4')}</p>

          <p>
            {getTranslate('cookie.policy.text4a', {
              link4: (
                <a
                  href={getTranslate('cookie.policy.link4', { inline: true })}
                  className='policy__link'
                  rel='noopener noreferrer'
                  target='_blank'>
                  {getTranslate('cookie.policy.link4')}
                </a>
              ),
            })}
          </p>
          <p>{getTranslate('cookie.policy.text4b')}</p>
          <p>{getTranslate('cookie.policy.text4c')}</p>
        </div>

        <div className='policy__block'>
          <h2>{getTranslate('cookie.policy.heading5')}</h2>

          <p>
            {getTranslate('cookie.policy.text5', {
              link5: (
                <a
                  href={`mailto:${getTranslate(common.link5, {
                    inline: true,
                  })}`}
                  rel='noopener noreferrer'
                  target='_blank'>
                  {getTranslate(common.link5)}
                </a>
              ),
            })}
          </p>
        </div>
      </PolicyContainer>
    );
  }

  getTranslate = (id, values = {}) => {
    const { getTranslate } = this.props;
    const options: any = values;
    if (!id) {
      return '';
    }
    return getTranslate(id, { ...options, node: !options.inline });
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  settings: state.settings,
});

export default connect(mapStateToProps)(addTranslation(CookiePolicy));
