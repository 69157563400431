import React, { Component } from 'react';
import classNames from 'classnames';
import Animation from 'components/ui/animation';
import CustomScrollbar from 'components/ui/customScrollbar';
import TimezoneItem from 'types/TimezoneItem';
import './timezoneSelect.scss';

interface Props {
  currentTimezone: TimezoneItem;
  timezoneList: TimezoneItem[];
  onUpdateTimezone: (timezone: TimezoneItem) => void;
}

interface State {
  currentLetter: string;
}

class TimezoneSelect extends Component<Props, State> {
  private componentRef;
  private scrollbarLettersRef;
  private lettersCollectionRef = {};
  private scrollbarListRef;
  private listCollectionRef = {};

  constructor(props) {
    super(props);

    this.state = {
      currentLetter: this.getLetterByName(props.currentTimezone.name),
    };
  }

  componentDidMount(): void {
    this.syncScrolls();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.timezoneList.length && this.props.timezoneList.length) {
      this.syncScrolls();
    }
  }

  render() {
    return (
      <div
        ref={(el) => {
          this.componentRef = el;
        }}
        className='timezone-select'>
        {this.renderContent()}
      </div>
    );
  }

  renderContent() {
    const { timezoneList } = this.props;

    if (timezoneList.length === 0) {
      return null;
    }

    return (
      <Animation>
        <div className='timezone-select__inner'>
          {this.renderLetters()}
          {this.renderList()}
        </div>
      </Animation>
    );
  }

  renderLetters() {
    const { currentLetter } = this.state;
    // TODO Localization
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

    return (
      <div className='timezone-select__letters'>
        <CustomScrollbar
          getRef={(el) => {
            this.scrollbarLettersRef = el;
          }}>
          <div className='timezone-select__letters-inner'>
            <div className='timezone-select__letter-offset' />
            {alphabet.map((letter) => {
              return (
                <div
                  key={letter}
                  ref={(el) => {
                    this.lettersCollectionRef[letter] = el;
                  }}
                  onClick={() => this.changeCurrentLetter(letter)}
                  className={classNames('timezone-select__letter', {
                    'timezone-select__letter_current': letter === currentLetter,
                  })}>
                  {letter}
                </div>
              );
            })}
            <div className='timezone-select__letter-offset' />
          </div>
        </CustomScrollbar>
      </div>
    );
  }

  renderList() {
    const { currentTimezone, onUpdateTimezone } = this.props;

    return (
      <div className='timezone-select__list'>
        <CustomScrollbar
          customHeight={200}
          getRef={(el) => {
            this.scrollbarListRef = el;
          }}>
          <div className='timezone-select__list-inner'>
            {this.getFilteredList().map((timezone) => {
              const { name, offset } = timezone;
              return (
                <div
                  key={name}
                  ref={(el) => {
                    this.listCollectionRef[name] = el;
                  }}
                  onClick={() => onUpdateTimezone(timezone)}
                  className={classNames('timezone-select__item', {
                    'timezone-select__item_current':
                      name === currentTimezone.name,
                  })}>
                  <div className='timezone-select__item-name'>
                    {name.replace('_', ' ').replace('/', ' | ')}
                  </div>
                  <div className='timezone-select__item-offset'>{offset}</div>
                </div>
              );
            })}
          </div>
        </CustomScrollbar>
      </div>
    );
  }

  syncScrolls = () => {
    setTimeout(() => {
      this.syncLettersScroll();
      this.syncListScroll();
    });
  };

  syncLettersScroll = () => {
    const { currentLetter } = this.state;
    const { componentRef, lettersCollectionRef, scrollbarLettersRef } = this;
    const letter = lettersCollectionRef[currentLetter];

    if (letter) {
      const top =
        letter.offsetTop -
        (componentRef.offsetHeight / 2 - letter.offsetHeight / 2);
      scrollbarLettersRef.scrollerElement.scrollTo({
        top,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  syncListScroll = () => {
    const { currentTimezone } = this.props;
    const { componentRef, listCollectionRef, scrollbarListRef } = this;

    const listItem = listCollectionRef[currentTimezone.name];
    if (listItem) {
      scrollbarListRef.scrollTop =
        listItem.offsetTop -
        (componentRef.clientHeight / 2 - listItem.offsetHeight / 2);
    } else {
      scrollbarListRef.scrollTop = 0;
    }
  };

  changeCurrentLetter = (letter: string): void => {
    this.setState(
      {
        currentLetter: letter.toLowerCase(),
      },
      this.syncScrolls
    );
  };

  getFilteredList = (): TimezoneItem[] => {
    const { timezoneList } = this.props;
    const { currentLetter } = this.state;

    return timezoneList
      .filter((timezone) => {
        return this.getLetterByName(timezone.name) === currentLetter;
      })
      .sort((a, b) => {
        return parseInt(a.offset) - parseInt(b.offset);
      });
  };

  getLetterByName = (name: string): string => {
    const splitName = name.split('/');
    const splitChunk = splitName[1] || splitName[0];
    return splitChunk.charAt(0).toLowerCase();
  };
}

export default TimezoneSelect;
