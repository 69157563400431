import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';

import './flagItem.scss';

interface Props {
  flagName: string;
  ipAddress: string;
  customClass?: string;
}

class FlagItem extends PureComponent<Props> {
  render() {
    const { flagName, ipAddress, customClass } = this.props;
    let srcFlag = null;
    try {
      srcFlag = require(`images/flags/${flagName.toLocaleLowerCase()}.svg`);
    } catch (error) {}

    return (
      <span className={classNames('flag-item', customClass)}>
        <span
          className={classNames('flag-item__icon', {
            'flag-item__icon_unknown': [`${!srcFlag}`],
          })}>
          {srcFlag ? (
            <img src={srcFlag} alt='' />
          ) : (
            <Icon name='im-Question' size={6} />
          )}
        </span>
        <span className='flag-item__text'>{ipAddress}</span>
      </span>
    );
  }
}

export default FlagItem;
