import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import api from 'api/user';
import { setQuickActions } from 'actions/quickActions';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import QuickActionItem, { IQuickActionItem } from './QuickActionItem';
import Icon from 'components/ui/icon';
import getQuickActionsToSave from './getQuickActionsToSave';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import Messages from 'constants/rpcTypes';
import { StoreProps } from 'store';
import './quickActions.scss';

interface ConnectedProps {
  quickActions: { isFetched: boolean; items: IQuickActionItem[] };
}
type Props = ConnectedProps & StoreProps & IntlProps & WithPermissions;
class QuickActionsContainer extends PureComponent<Props> {
  render() {
    const { getTranslate } = this.props;
    const {
      quickActions: { items },
    } = this.props;
    return (
      <div className='quick-actions'>
        {items?.length ? (
          items.map((item) => (
            <QuickActionItem
              key={item.id}
              id={item.id}
              title={getTranslate(item.title)}
              icon={item.icon}
              isFavorite={item.isFavorite}
              customClass='quick-actions__item'
              toggleFavorite={this.toggleFavorite}
            />
          ))
        ) : (
          <div className='quick-actions__empty'>
            <Icon
              name='im-Favourite'
              size={14}
              className='quick-actions__empty-icon'
            />
            <div className='quick-actions__empty-text'>
              {getTranslate('getStarted.quickActions.noActions.label')}
            </div>
          </div>
        )}
      </div>
    );
  }

  toggleFavorite = async (id) => {
    if (
      isNotAvailableForSupport(Messages.UserConfig_Set) ||
      !this.props.isEnabled(Messages.UserConfig_Set)
    )
      return;

    const { dispatch } = this.props;

    const routesToSave = getQuickActionsToSave(
      (this.props.quickActions.items || []).map((item) => {
        if (item.id === id) {
          return { ...item, isFavorite: !item.isFavorite };
        }
        return item;
      })
    );

    try {
      const { quickActions } = await api.setQuickActions(routesToSave);
      dispatch(setQuickActions(quickActions));
    } catch (e) {
      console.error('Update quick actions error:', e);
    }
  };
}

const mapStateToProps = (state) => ({
  quickActions: state.quickActions,
});

export default connect(mapStateToProps)(
  addPermissions(addTranslation(QuickActionsContainer))
);
