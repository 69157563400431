import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import ModalTitle from '../components/ModalTitle';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import Button from 'components/ui/button';
import Checkbox from 'components/ui/checkbox';
import LocalStorage from 'helpers/LocalStorage';

interface Props extends IntlProps {
  callback: (data: any) => void;
  content: {
    title?: string;
    text?: string;
    buttonYes?: string;
    buttonNo?: string;
  };
}

interface State {
  isInvoiceSubscriptionInfoSeen: boolean;
}
class ConfirmInvoiceSubscription extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isInvoiceSubscriptionInfoSeen:
        LocalStorage.get('isInvoiceSubscriptionInfoSeen') || false,
    };
  }

  render() {
    const { getTranslate, callback } = this.props;
    return (
      <>
        <ModalTitle>
          {getTranslate('invoiceSubscriptions.modal.title')}
        </ModalTitle>
        <ModalContent>
          <div className='invoice-subscription-confirm'>
            {getTranslate('invoiceSubscriptions.modal.text')}
          </div>
          <Checkbox
            id='isInvoiceSubscriptionInfoSeen'
            checked={this.state.isInvoiceSubscriptionInfoSeen}
            onChange={this.onChange}
            text={getTranslate('invoiceSubscriptions.modal.checkbox')}
          />
        </ModalContent>
        <ModalFooter>
          <Button
            text={getTranslate('invoiceSubscriptions.back.button')}
            status='outline'
            size='normal'
            onClick={() => callback(false)}
          />
          <Button
            text={getTranslate('invoiceSubscriptions.continue.button')}
            status='primary'
            size='normal'
            onClick={() => callback(true)}
          />
        </ModalFooter>
      </>
    );
  }

  onChange = () => {
    this.setState(
      (state) => ({
        isInvoiceSubscriptionInfoSeen: !state.isInvoiceSubscriptionInfoSeen,
      }),
      () => {
        LocalStorage.set(
          'isInvoiceSubscriptionInfoSeen',
          this.state.isInvoiceSubscriptionInfoSeen
        );
      }
    );
  };
}

export default addTranslation(ConfirmInvoiceSubscription);
