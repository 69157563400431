import React, { Component, FormEvent } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Form from 'components/ui/form';
import Button from 'components/ui/button';
import InputPassword from 'components/ui/inputPassword';
import Icon from 'components/ui/icon';
import { AnyObject } from 'types/Common';

interface Props extends IntlProps {
  validation: AnyObject;
  password: string;
  passwordType: string;
  passwordConfirm: string;
  passwordConfirmType: string;
  settings: AnyObject;
  onChange: (key: string, value: any) => void;
  onSave: (event: FormEvent) => void;
}

class PasswordResetForm extends Component<Props> {
  private passwordInput;

  componentDidMount() {
    this.passwordInput.querySelector('.ui-input__field').focus();
  }

  render() {
    const {
      getTranslate,
      password,
      passwordType,
      passwordConfirm,
      passwordConfirmType,
      settings,
      onChange,
      validation,
    } = this.props;

    return (
      <div className='login-form'>
        <Form onSubmit={this.savePassword}>
          <div className='login-form__fields'>
            <div
              className='login-form__row'
              ref={(el) => {
                this.passwordInput = el;
              }}>
              <InputPassword
                customClass={classNames({ 'ui-input_empty': !password })}
                id='password'
                type={passwordType}
                label={getTranslate('auth.changePassword.newPassword.label')}
                placeholder={getTranslate('auth.newPasswordPlaceholder')}
                value={password}
                onChange={(event) => onChange('password', event.target.value)}
                error={validation && validation.newPassword}
                modern
                suffix={
                  <Icon
                    onClick={() =>
                      onChange(
                        'passwordType',
                        passwordType === 'password' ? 'text' : 'password'
                      )
                    }
                    name={
                      passwordType === 'password'
                        ? 'im-Eye-close'
                        : 'im-Eye-open'
                    }
                  />
                }
              />
            </div>

            <div className='login-form__row'>
              <InputPassword
                customClass={classNames({ 'ui-input_empty': !passwordConfirm })}
                id='passwordConfirm'
                type={passwordConfirmType}
                label={getTranslate(
                  'auth.changePassword.confirmPassword.label'
                )}
                placeholder={getTranslate('auth.passwordConfirmPlaceholder')}
                value={passwordConfirm}
                error={validation && validation.newPassword2}
                onChange={(event) =>
                  onChange('passwordConfirm', event.target.value)
                }
                modern
                suffix={
                  <Icon
                    onClick={() =>
                      onChange(
                        'passwordConfirmType',
                        passwordConfirmType === 'password' ? 'text' : 'password'
                      )
                    }
                    name={
                      passwordConfirmType === 'password'
                        ? 'im-Eye-close'
                        : 'im-Eye-open'
                    }
                  />
                }
              />
            </div>
          </div>

          <Button
            text={getTranslate('auth.changePassword.confirm.button')}
            status='primary'
            type='submit'
            disabled={!this.canChange()}
            size='large'
            customClass='page-auth__btn ui-button_full-width'
          />
          <div className='page-auth__link'>
            <a href={`mailto:${settings.supportEmail}`} className='utils-link'>
              {getTranslate('auth.contact.link')}
            </a>
          </div>
        </Form>
      </div>
    );
  }

  savePassword = (event) => {
    const { onSave } = this.props;
    if (this.canChange()) {
      onSave(event);
    }
  };

  canChange = (): boolean => {
    const { password, passwordConfirm } = this.props;
    return Boolean(password && passwordConfirm) && password === passwordConfirm;
  };
}

export default addTranslation(PasswordResetForm);
