import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';

export const updatePermissions = (permissions) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPDATE_PERMISSIONS,
        payload: {
          permissions,
        },
      })
    );
  };
};
