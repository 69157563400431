import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReportsPage from './ReportsPage';
import api from 'api/dictionaries';
import { initAllConfiguration } from 'actions/configuration';
import { addListeners } from 'decorators/addListeners';
import { addDataItem, updateData, updateDataItem } from 'actions/getData';
import { StoreProps } from 'store';

import Utils from 'helpers/Utils';
import Messages from 'constants/rpcTypes';
import filterKeys from 'constants/filters';
import DateHelpers from 'helpers/Date';
import { QuickReportsFiltersType } from 'types/QuickFilters';
import { AnyObject } from 'types/Common';
import { WithRouterProps } from 'decorators/withRouter';

interface OwnProps {
  isTemplatesBuilder?: boolean;
}

interface ConnectedProps {
  configuration: any;
  reportList: AnyObject[];
  filters: QuickReportsFiltersType;
  totalItems: null | number;
}

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  WithRouterProps<{ id?: string }>;

@addListeners([Messages.ReportTemplateTaskHistory_Update])
class ReportsContainer extends Component<Props> {
  async componentDidMount() {
    const { isTemplatesBuilder, dispatch } = this.props;
    if (!isTemplatesBuilder) {
      const list = await api.getTableColumns();
      dispatch(initAllConfiguration(list));
    }
  }

  render() {
    const { match, configuration, isTemplatesBuilder } = this.props;

    if (!configuration) return null;

    return (
      <ReportsPage
        id={match.params.id}
        isNewTab={match.path.includes('reports/create')}
        isTemplatesBuilder={Boolean(isTemplatesBuilder)}
      />
    );
  }

  onEvent = ({ data: { payload } }) => {
    const { reportList, dispatch, totalItems } = this.props;
    const isExist = reportList.find(
      ({ reportTemplateTaskHistoryId }) =>
        reportTemplateTaskHistoryId === payload.reportTemplateTaskHistoryId
    );
    const isCurrentTable = this.isCurrentTable(payload);
    if (isExist) {
      if (isCurrentTable) {
        dispatch(
          updateDataItem('reports', 'reportTemplateTaskHistoryId', {
            ...payload,
            needAnimate: true,
          })
        );
      } else {
        const updatedItems = reportList.filter(
          ({ reportTemplateTaskHistoryId }) =>
            reportTemplateTaskHistoryId !== payload.reportTemplateTaskHistoryId
        );
        dispatch(
          updateData('reports', {
            items: updatedItems.length ? updatedItems : [],
            totalRows: totalItems,
          })
        );
      }
    } else if (isCurrentTable) {
      //check sort
      dispatch(addDataItem('reports', { ...payload, needAnimate: true }));
    }
    this.disableAnimation(payload);
  };

  isCurrentTable = (item): boolean => {
    const { filters } = this.props;
    let isCurrent = true;
    for (const itemKey in item) {
      if (Utils.hasProp(item, itemKey) && Utils.hasProp(filters, itemKey)) {
        switch (itemKey) {
          case filterKeys.reportStatus:
          case filterKeys.reportType:
            if (
              filters[itemKey].length &&
              !filters[itemKey].includes(item[itemKey])
            ) {
              isCurrent = false;
              return isCurrent;
            }
            continue;
          case filterKeys.reportId:
            if (filters[itemKey] && filters[itemKey] !== item[itemKey]) {
              isCurrent = false;
              return isCurrent;
            }
            continue;
          case filterKeys.reportPeriod: {
            if (
              filters[filterKeys.reportCreatedAt].values.length &&
              !DateHelpers.checkDateInPeriod(
                filters[filterKeys.reportCreatedAt].values,
                item[filterKeys.reportCreatedAt],
                'datetime'
              )
            ) {
              isCurrent = false;
              return isCurrent;
            }
          }
        }
      }
    }
    return isCurrent;
  };

  disableAnimation = (data) => {
    const { dispatch } = this.props;
    setTimeout(() => {
      dispatch(
        updateDataItem('reports', 'reportTemplateTaskHistoryId', {
          ...data,
          needAnimate: false,
        })
      );
    }, 300);
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  configuration: state.configuration,
  reportList: state.data.reports.items,
  filters: state.quickFilters.reports,
  totalItems: state.data.reports.totalItems,
});

export default connect(mapStateToProps)(ReportsContainer);
