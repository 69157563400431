import React, { Fragment, PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip';

import { Header, Row } from '../../../../components/ui/table';
import Column from '../../../../components/ui/table/column/Column';
import ButtonsGroup from '../../../../components/ui/buttonsGroup/ButtonsGroup';
import modes from '../../../../components/ui/buttonsGroup/modes';
import Button from '../../../../components/ui/button';

import { filter, head, inRange, isEmpty, isEqual, map } from 'lodash';
import api from 'api/projects';
import { updateProjectField } from 'actions/projects';

import { addPermissions } from 'decorators/addPermissions';
import {
  addTranslation,
  GetTranslate,
} from '../../../../decorators/addTranslation';

import PaymentMethodsTable from './PaymentMethodsTable';
import statusMapper from '../../statusMapper';

import Messages from 'constants/rpcTypes';
import filtersKeys from '../../../../constants/filters';
import tableNames from '../../../../constants/tableNames';
import permissionReasons from 'constants/permissionReasons';
import {
  Columns,
  Directions,
  HeaderConfig,
  InfinitySymbol,
  QuickFilterCurrencies,
} from './helpers/constants';
import breakpoints from '../../../../constants/breakpoints';
import {
  filterList,
  isContainsValue,
  isEmptyFilters,
  prepareLimit,
  sortedList,
  updateSelectFilter,
} from './helpers/helpers';

import { PaymentMethodFilters } from '../../../../types/QuickFilters';
import { AnyObject } from '../../../../types/Common';
import {
  ConnectedProps,
  CProps,
  Filter,
  PaymentMethod,
  PaymentMethodList,
  State,
} from './helpers/types';
import { asc, SortingType } from '../../../../types/sortings';

import Panel from '../../../../components/ui/panel';
import MultiSelect from '../../../../components/filters/list/multiSelect/MultiSelect';
import InputNumbers from '../../../../components/filters/list/numbers/inputNumbers';
import SortingCell from '../../../../components/tableHeaders/components/sortingCell/SortingCell';

import classNames from 'classnames';
import './paymentMethods.scss';

class PaymentMethodsContainer extends PureComponent<CProps, State> {
  constructor(props: CProps) {
    super(props);

    const initialPaymentMethods =
      PaymentMethodsContainer.getInitialPaymentMethod();

    this.state = {
      isOpenedFiltersPanel: false,
      isEmptyFilters: true,
      list: {
        ...initialPaymentMethods,
        items: props.paymentMethods,
      },
      methodFilter: this.getFilter(props.paymentMethods, filtersKeys.method),
      directionFilter: this.getFilter(
        props.paymentMethods,
        filtersKeys.direction,
        props.getTranslate
      ),
      currencyFilter: this.getFilter(
        props.paymentMethods,
        filtersKeys.currency
      ),
      filters: PaymentMethodsContainer.getInitialFilters(),
    };
  }

  componentDidMount() {
    setTimeout(() => Tooltip.rebuild());
  }

  componentDidUpdate(prevProps: Readonly<CProps>) {
    if (
      !isEqual(
        this.props.oldData.paymentMethodsStatuses,
        prevProps.oldData.paymentMethodsStatuses
      )
    ) {
      this.checkChanges();
    }
    if (
      !isEqual(
        prevProps.paymentMethodsStatuses,
        this.props.paymentMethodsStatuses
      )
    ) {
      setTimeout(() => Tooltip.rebuild());
    }

    if (prevProps.projectId !== this.props.projectId) {
      const currencyFilter = this.getFilter(
        this.props.paymentMethods,
        filtersKeys.currency
      );
      const methodFilter = this.getFilter(
        this.props.paymentMethods,
        filtersKeys.method
      );
      const directionFilter = this.getFilter(
        this.props.paymentMethods,
        filtersKeys.direction,
        this.props.getTranslate
      );

      this.setState({
        currencyFilter: currencyFilter,
        methodFilter: methodFilter,
        directionFilter: directionFilter,
        list: {
          ...PaymentMethodsContainer.getInitialPaymentMethod(),
          items: sortedList(this.props.paymentMethods, filtersKeys.method, asc),
        },
        filters: PaymentMethodsContainer.getInitialFilters(),
        isOpenedFiltersPanel: false,
        isEmptyFilters: true,
      });
    }
  }

  static getInitialFilters(): PaymentMethodFilters {
    return {
      [filtersKeys.method]: [],
      [filtersKeys.currency]: [],
      [filtersKeys.direction]: [],
      [filtersKeys.min_amount]: '',
      [filtersKeys.max_amount]: '',
    };
  }

  static getInitialPaymentMethod(): PaymentMethodList {
    return {
      isFetched: true,
      items: [],
      totals: [],
      updateAt: null,
      sort: {
        field: filtersKeys.method,
        order: asc,
      },
      filters: this.getInitialFilters(),
    };
  }

  getFilter(data: PaymentMethod[], key: string, getTranslate?: GetTranslate) {
    return data.reduce((acc: Filter[], item) => {
      const text: string =
        getTranslate && item[key]
          ? getTranslate(`projects.paymentMethods.${Directions[item[key]]}`)
          : item[key];
      if (acc.find((el) => el.text === text)) {
        return acc;
      }
      return [
        ...acc,
        {
          id: item[key],
          isSelected: false,
          text: text,
        },
      ];
    }, []);
  }

  headerRender = () => {
    const {
      list: { sort },
    } = this.state;
    const { getTranslate } = this.props;

    return (
      <Header>
        {HeaderConfig.map((config) => {
          let content: string | ReactElement = getTranslate(config.localId);
          const isFixed = Boolean(config.fixed);

          content = (
            <SortingCell
              tableName={tableNames.paymentMethods}
              id={config.id}
              onSort={this.changeSort}
              sort={sort}>
              {content}
            </SortingCell>
          );

          return (
            <Column
              id={config.id}
              key={config.id}
              customClass={
                isFixed ? 'ui-table__column_fixed' : 'ui-table__column_left'
              }
              modifier={config.id}
              content={content}
            />
          );
        })}
      </Header>
    );
  };

  renderRow = (data: AnyObject) => {
    const {
      paymentMethodOptions: { list },
      getTranslate,
    } = this.props;
    const { id, columnWidths, forbidResizeFor } = data;

    const direction = list.find((el) => el.id === data[Columns.direction]);

    return (
      <Row key={id} id={id}>
        <Column
          key={Columns.method}
          id={Columns.method}
          content={data[Columns.method]}
          columnWidths={columnWidths}
          forbidResizeFor={forbidResizeFor}
          customClass={`ui-table__column_${id}`}
          data={data}
          params={{
            valueId: Columns.method,
          }}
        />
        <Column
          key={Columns.direction}
          id={Columns.direction}
          content={
            <div className='pay'>
              <div
                className={`${
                  'projects.paymentMethods.payIn' === direction?.text
                    ? 'pay_in'
                    : 'pay_out'
                }`}>
                <span>{getTranslate(direction?.text)}</span>
              </div>
            </div>
          }
          columnWidths={columnWidths}
          forbidResizeFor={forbidResizeFor}
          customClass={`ui-table__column_${id}`}
          data={data}
          params={{
            valueId: Columns.direction,
          }}
        />
        <Column
          key={Columns.minAmount}
          id={Columns.minAmount}
          content={
            data.limits[Columns.minAmount] === null
              ? InfinitySymbol
              : data.limits[Columns.minAmount]
          }
          columnWidths={columnWidths}
          forbidResizeFor={forbidResizeFor}
          customClass={`ui-table__column_${id} ${
            data.limits[Columns.minAmount] === null
              ? `infinity-symbol`
              : 'text-right'
          }`}
          data={data}
          params={{
            valueId: Columns.minAmount,
          }}
        />
        <Column
          key={Columns.maxAmount}
          id={Columns.maxAmount}
          content={
            data.limits[Columns.maxAmount] === null
              ? InfinitySymbol
              : data.limits[Columns.maxAmount]
          }
          columnWidths={columnWidths}
          forbidResizeFor={forbidResizeFor}
          customClass={`ui-table__column_${id} ${
            data.limits[Columns.maxAmount] === null
              ? `infinity-symbol`
              : 'text-right'
          }`}
          data={data}
          params={{
            valueId: Columns.maxAmount,
          }}
        />
        <Column
          key={Columns.currency}
          id={Columns.currency}
          content={data[Columns.currency]}
          columnWidths={columnWidths}
          forbidResizeFor={forbidResizeFor}
          customClass={`ui-table__column_${id}`}
          data={data}
          params={{
            valueId: Columns.currency,
          }}
        />
      </Row>
    );
  };

  changeSort = ({ field, order }: { field: string; order: SortingType }) => {
    const { list } = this.state;

    this.setState({
      ...this.state,
      list: {
        ...list,
        items: sortedList(list.items, field, order),
        sort: {
          field: field,
          order: order,
        },
      },
    });
  };

  updateFilter = (key: string, values: string[]) => {
    const { list, filters } = this.state;

    const updatedFilters = this.updateSelectFilters(key, values);

    this.setState(
      {
        ...this.state,
        list: {
          ...list,
        },
        filters: { ...list.filters, ...filters, [key]: values },
        currencyFilter: updatedFilters.newCurrencyFilter,
        methodFilter: updatedFilters.newMethodFilter,
        directionFilter: updatedFilters.newDirectionFilter,
      },
      () => {
        this.setState({
          ...this.state,
          isEmptyFilters: isEmptyFilters(this.state.filters),
        });
      }
    );
  };

  onChangeFilter = (key: string, values: string[]) => {
    const { list, filters } = this.state;

    const { paymentMethods } = this.props;

    let newItems: PaymentMethod[] = paymentMethods;
    const updatedFilters = this.updateSelectFilters(key, values);

    if (
      isContainsValue(filters[filtersKeys.method]) &&
      key !== filtersKeys.method
    ) {
      newItems = filterList(
        newItems,
        filters[filtersKeys.method],
        filtersKeys.method
      );
    }
    if (
      isContainsValue(filters[filtersKeys.currency]) &&
      key !== filtersKeys.currency
    ) {
      newItems = filterList(
        newItems,
        filters[filtersKeys.currency],
        filtersKeys.currency
      );
    }
    if (
      isContainsValue(filters[filtersKeys.direction]) &&
      key !== filtersKeys.direction
    ) {
      newItems = filterList(
        newItems,
        filters[filtersKeys.direction],
        filtersKeys.direction
      );
    }
    if (
      isContainsValue(filters[filtersKeys.min_amount]) &&
      key !== filtersKeys.min_amount
    ) {
      newItems = filter(newItems, (el) => {
        const minAmount = head(filters[filtersKeys.min_amount]);
        return !!(
          minAmount &&
          inRange(
            prepareLimit(el.limits[filtersKeys.min_amount]),
            prepareLimit(minAmount),
            Infinity
          )
        );
      });
    }
    if (
      isContainsValue(filters[filtersKeys.max_amount]) &&
      key !== filtersKeys.max_amount
    ) {
      newItems = filter(newItems, (el) => {
        const maxAmount = head(filters[filtersKeys.max_amount]);
        return !!(
          maxAmount &&
          inRange(
            prepareLimit(el.limits[filtersKeys.max_amount]),
            0,
            prepareLimit(maxAmount) + 1
          )
        );
      });
    }

    if (isContainsValue(values)) {
      if (key === filtersKeys.min_amount) {
        newItems = filter(newItems, (el) => {
          const minAmount = head(values);
          return (
            minAmount !== undefined &&
            inRange(
              prepareLimit(el.limits[filtersKeys.min_amount]),
              prepareLimit(minAmount),
              Infinity
            )
          );
        });
      } else if (key === filtersKeys.max_amount) {
        newItems = filter(newItems, (el) => {
          const maxAmount = head(values);
          return (
            maxAmount !== undefined &&
            inRange(
              prepareLimit(el.limits[filtersKeys.max_amount]),
              0,
              prepareLimit(maxAmount) + 1
            )
          );
        });
      } else {
        newItems = filterList(newItems, values, key);
      }
    }

    this.setState(
      {
        ...this.state,
        list: {
          ...list,
          items: sortedList(newItems, list.sort.field, list.sort.order),
          filters: { ...filters, [key]: values },
        },
        currencyFilter: updatedFilters.newCurrencyFilter,
        methodFilter: updatedFilters.newMethodFilter,
        directionFilter: updatedFilters.newDirectionFilter,
        filters: { ...filters, [key]: values },
      },
      () => {
        this.setState({
          ...this.state,
          isEmptyFilters: isEmptyFilters(this.state.filters),
        });
      }
    );
  };

  updateSelectFilters = (key: string, values: string[]) => {
    const { currencyFilter, methodFilter, directionFilter } = this.state;

    let newCurrencyFilter: Filter[] = currencyFilter;
    let newMethodFilter: Filter[] = methodFilter;
    let newDirectionFilter: Filter[] = directionFilter;

    if (key === filtersKeys.currency) {
      newCurrencyFilter = updateSelectFilter(currencyFilter, values);
    }
    if (key === filtersKeys.method) {
      newMethodFilter = updateSelectFilter(methodFilter, values);
    }
    if (key === filtersKeys.direction) {
      newDirectionFilter = updateSelectFilter(directionFilter, values);
    }

    return {
      newCurrencyFilter,
      newMethodFilter,
      newDirectionFilter,
    };
  };

  resetFilters = () => {
    const { paymentMethods } = this.props;
    const { list, currencyFilter, directionFilter, methodFilter } = this.state;

    this.setState({
      ...this.state,
      list: {
        ...list,
        filters: PaymentMethodsContainer.getInitialFilters(),
        items: paymentMethods,
      },
      currencyFilter: this.resetFilterForSelect(currencyFilter),
      directionFilter: this.resetFilterForSelect(directionFilter),
      methodFilter: this.resetFilterForSelect(methodFilter),
      filters: PaymentMethodsContainer.getInitialFilters(),
      isEmptyFilters: true,
    });
  };

  resetFilterForSelect = (filters: Filter[]): Filter[] => {
    return map(filters, (filter) => {
      return { ...filter, isSelected: false };
    });
  };

  renderDirectionFilter = () => {
    const {
      list: { filters },
    } = this.state;
    const { getTranslate } = this.props;

    return (
      <ButtonsGroup
        activeButtons={filters[filtersKeys.direction]}
        mode={modes.multi}
        onClick={(values) => {
          this.onChangeFilter(filtersKeys.direction, values);
        }}>
        <Button
          status='light'
          id='payin'
          text={getTranslate('projects.paymentMethods.payIn')}
        />
        <Button
          status='light'
          id='payout'
          text={getTranslate('projects.paymentMethods.payOut')}
        />
      </ButtonsGroup>
    );
  };

  renderCurrencyFilter = () => {
    const {
      list: { filters },
    } = this.state;

    return (
      <ButtonsGroup
        activeButtons={filters[filtersKeys.currency]}
        mode={modes.multi}
        onClick={(values) => {
          this.onChangeFilter(filtersKeys.currency, values);
        }}>
        {map(QuickFilterCurrencies, ({ value, label }) => {
          return (
            <Button status='light' id={value} text={label} tooltip={label} />
          );
        })}
      </ButtonsGroup>
    );
  };

  canEdit = () => {
    const {
      isEnabled,
      isDisabledByReason,
      isEditable,
      paymentMethodsStatuses,
    } = this.props;
    return (
      isEditable &&
      (isEnabled(Messages.Project_ChangePaymentMethodStatus) ||
        isDisabledByReason(
          Messages.Project_ChangePaymentMethodStatus,
          permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
        )) &&
      Array.isArray(paymentMethodsStatuses) &&
      paymentMethodsStatuses.length > 0
    );
  };

  checkChanges = () => {
    const { paymentMethodsStatuses, oldData, dispatch } = this.props;
    if (isEqual(paymentMethodsStatuses, oldData.paymentMethodsStatuses)) {
      dispatch(updateProjectField('canSavePaymentMethods', false));
    } else {
      dispatch(updateProjectField('canSavePaymentMethods', true));
    }
  };

  async onSavePaymentMethods() {
    const { projectId, dispatch } = this.props;
    dispatch(updateProjectField('isLoading', true));

    try {
      await api.changePaymentMethodStatus({
        projectId,
        methods: this.getMethodsToSave(),
      });
    } catch (error) {
      dispatch(updateProjectField('isLoading', false));
    }
  }

  getMethodsToSave = () => {
    const { paymentMethodsStatuses, oldData } = this.props;
    return paymentMethodsStatuses.reduce((dataToSave, method) => {
      const initialMethod = oldData.paymentMethodsStatuses.find(
        ({ id }) => id === method.id
      );
      if (isEqual(method, initialMethod)) return dataToSave;
      return [
        ...dataToSave,
        {
          method: method.id,
          channels: method.items.reduce((result, channel) => {
            const initialChannel = initialMethod.items.find(
              ({ id }) => id === channel.id
            );
            if (isEqual(channel, initialChannel)) return result;
            return [
              ...result,
              {
                channel: channel.id,
                action: channel.isSelected ? statusMapper.on : statusMapper.off,
              },
            ];
          }, []),
        },
      ];
    }, []);
  };

  renderResetButton = (compact: boolean) => {
    const { isEmptyFilters } = this.state;
    const { getTranslate } = this.props;
    const isMobile: boolean = window.innerWidth <= breakpoints.header;

    if (compact) {
      return (
        <Button
          status='danger-light'
          id='reload'
          icon='im-Reset-filter'
          customClass='ui-button_no-border'
          onClick={this.resetFilters}
          disabled={isEmptyFilters}
        />
      );
    } else {
      return (
        <Button
          text={getTranslate('common.clearAll.label')}
          status='outline'
          size={isMobile ? 'normal' : 'small'}
          id='search-button-reset'
          onClick={this.resetFilters}
          customClass={!isMobile ? 'search-filters__button-desktop' : ''}
          disabled={isEmptyFilters}
        />
      );
    }
  };

  prepareFilterValues = (filter: Filter[]): string[] => {
    return filter
      .map((filter: Filter) => (filter.isSelected ? filter.id : ''))
      .filter((el) => !isEmpty(el));
  };

  render() {
    const { getTranslate } = this.props;
    const {
      list: { items },
      currencyFilter,
      methodFilter,
      directionFilter,
      isOpenedFiltersPanel,
      filters,
      isEmptyFilters,
    } = this.state;

    return (
      <>
        <div className='page-projects__block page-projects__block_payment-methods'>
          <div className='card card_l'>
            <div className='card__header page-projects__block-title'>
              {getTranslate('projects.paymentMethods.title')}
            </div>
            <div className='card__content card__container'>
              <div className='page-projects__block-text page-projects__item'>
                {getTranslate('projects.paymentMethods.text')}
              </div>
            </div>
            <div className='page-container'>
              <div className='card__content card__container'>
                <div className='quick-filters__inner'>
                  <div className='quick-filters__filter-settings ui-buttons-group_single-choice'>
                    <div className='ui-buttons-group__inner'>
                      {this.renderResetButton(true)}
                      <Fragment>
                        <Button
                          id='filters'
                          status='light'
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              isOpenedFiltersPanel: !isOpenedFiltersPanel,
                            });
                          }}
                          icon='im-Filter'
                          customClass={classNames(
                            'button-open-filters ui-button_no-border ui-buttons-group__last-item',
                            {
                              'ui-button_status-light-active':
                                isOpenedFiltersPanel,
                            }
                          )}
                        />
                        <Panel
                          id='filters-panel'
                          isOpened={isOpenedFiltersPanel}
                          customClass='ui-panel_filters'
                          excludeFromCloseTrigger={['.button-open-filters']}
                          onClose={() => {
                            this.setState({
                              ...this.state,
                              isOpenedFiltersPanel: false,
                            });
                          }}
                          modern>
                          <div className='search-filters__inner'>
                            <div className='search-filters__header'>
                              <div className='search-filters__title'>
                                {getTranslate('filters.header')}
                              </div>
                              {this.renderResetButton(false)}
                            </div>
                          </div>
                          <div className='filters__fields'>
                            <div
                              className={`filters__item filters__item_${filtersKeys.method}`}
                              key={`item-${filtersKeys.method}`}>
                              <MultiSelect
                                key={filtersKeys.method}
                                id={filtersKeys.method}
                                label={getTranslate(
                                  'projects.paymentMethods.list.header.method'
                                )}
                                placeholder={getTranslate(
                                  'projects.paymentMethods.list.header.method'
                                )}
                                items={methodFilter}
                                onChange={(key, values) =>
                                  this.updateFilter(
                                    key,
                                    this.prepareFilterValues(values)
                                  )
                                }
                                withLabel={true}
                              />
                            </div>
                            <div
                              className={`filters__item filters__item_${filtersKeys.direction}`}
                              key={`item-${filtersKeys.direction}`}>
                              <MultiSelect
                                key={filtersKeys.direction}
                                id={filtersKeys.direction}
                                label={getTranslate(
                                  'projects.paymentMethods.list.header.direction'
                                )}
                                placeholder={getTranslate(
                                  'projects.paymentMethods.list.header.direction'
                                )}
                                items={directionFilter}
                                onChange={(key, values) => {
                                  this.updateFilter(
                                    key,
                                    this.prepareFilterValues(values)
                                  );
                                }}
                                withLabel={true}
                              />
                            </div>
                            <div
                              className={`filters__item filters__item_${filtersKeys.currency}`}
                              key={`item-${filtersKeys.currency}`}>
                              <MultiSelect
                                key={filtersKeys.currency}
                                id={filtersKeys.currency}
                                label={getTranslate(
                                  'projects.paymentMethods.list.header.currency'
                                )}
                                placeholder={getTranslate(
                                  'projects.paymentMethods.list.header.currency'
                                )}
                                items={currencyFilter}
                                onChange={(key, values) => {
                                  this.updateFilter(
                                    key,
                                    this.prepareFilterValues(values)
                                  );
                                }}
                                withLabel={true}
                              />
                            </div>
                            <div className='filters__item filters__item_group'>
                              <div
                                id='filters-group-payment-amount'
                                className='filters__group filters__group_payment-amount'>
                                <div className='filters__group-title'>
                                  {getTranslate('limits.label')}
                                </div>
                                <div className='utils-flex filters__group-content'>
                                  <div className='filters-amount'>
                                    <div className='ui-input ui-input_modern'>
                                      <div className='ui-input__wrapper ui-input__wrapper_modern'>
                                        <div
                                          className={`ui-input filters__item filters__item_${filtersKeys.min_amount}`}
                                          key={`item-${filtersKeys.min_amount}`}>
                                          <InputNumbers
                                            key={filtersKeys.min_amount}
                                            id={filtersKeys.min_amount}
                                            label={getTranslate(
                                              'projects.paymentMethods.list.header.min_amount'
                                            )}
                                            placeholder={getTranslate(
                                              'projects.paymentMethods.list.header.min_amount'
                                            )}
                                            value={
                                              filters[filtersKeys.min_amount]
                                            }
                                            onChange={(key, value) => {
                                              this.updateFilter(key, [value]);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='filters-amount'>
                                    <div className='ui-input ui-input_modern'>
                                      <div className='ui-input__wrapper ui-input__wrapper_modern'>
                                        <div
                                          className={`ui-input filters__item filters__item_${filtersKeys.max_amount}`}
                                          key={`item-${filtersKeys.max_amount}`}>
                                          <InputNumbers
                                            key={filtersKeys.max_amount}
                                            id={filtersKeys.max_amount}
                                            label={getTranslate(
                                              'projects.paymentMethods.list.header.max_amount'
                                            )}
                                            placeholder={getTranslate(
                                              'projects.paymentMethods.list.header.max_amount'
                                            )}
                                            value={
                                              filters[filtersKeys.max_amount]
                                            }
                                            onChange={(key, value) => {
                                              this.updateFilter(key, [value]);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='filters__footer filters__footer-wide'>
                            <Button
                              text={getTranslate('filters.footer.apply')}
                              status='primary'
                              customClass='ui-button_full-width text-semi'
                              disabled={isEmptyFilters}
                              onClick={() => this.onChangeFilter('', [])}
                            />
                          </div>
                        </Panel>
                      </Fragment>
                    </div>
                  </div>
                  <div className='quick-filters__item'>
                    {this.renderCurrencyFilter()}
                  </div>
                  <div className='quick-filters__item quick-filters__direction'>
                    {this.renderDirectionFilter()}
                  </div>
                </div>
                <PaymentMethodsTable
                  tableName={tableNames.paymentMethods}
                  paymentMethods={items}
                  rowRender={this.renderRow}
                  headerRender={this.headerRender()}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: AnyObject): ConnectedProps => {
  const {
    projects: {
      canSavePaymentMethods,
      eShopLink,
      isApmHighRiskVisible,
      isApmLowRiskVisible,
      isEditable,
      oldData,
      paymentMethods,
      paymentMethodsStatuses,
      project: { projectId, status },
      validationErrors,
    },
    filtersValues,
  } = state;
  return {
    isFetchedFilters: filtersValues.paymentMethodChannels?.isFetched,
    projectId: projectId,
    status: status,
    paymentMethods: paymentMethods,
    paymentMethodsStatuses: paymentMethodsStatuses,
    oldData: oldData,
    isEditable: isEditable,
    canSavePaymentMethods: canSavePaymentMethods,
    validationErrors: validationErrors,
    isHighRisk: isApmHighRiskVisible,
    isLowRisk: isApmLowRiskVisible,
    eShopLink: eShopLink,
    wlId: state.settings.wlId,
    paymentMethodOptions: filtersValues?.paymentMethodOptions,
  };
};

export default connect(mapStateToProps)(
  addTranslation(addPermissions(PaymentMethodsContainer))
);
