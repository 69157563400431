import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import classNames from 'classnames';
import Button from 'components/ui/button';
import Switch from 'components/ui/switch';
import Panel from 'components/ui/panel';
import ChangePassword from './components/changePassword';
import TwoFactorAuth from './components/twoFactorAuth';
import DateHelpers from 'helpers/Date';
import { AnyObject } from 'types/Common';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import Messages from 'constants/rpcTypes';
import './profileSecure.scss';

interface OwnProps {
  user: any;
  validationErrors: AnyObject;
  handle2FAConfirmation: () => void;
  loading: boolean;
  customClass: string;
}

type Props = OwnProps & IntlProps;

interface State {
  isOpenedPasswordPanel: boolean;
  isOpenedTwoFactorPanel: boolean;
}

class ProfileSecure extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpenedPasswordPanel: false,
      isOpenedTwoFactorPanel: false,
    };
  }

  render() {
    const { user, getTranslate, validationErrors, loading, customClass } =
      this.props;
    const { isOpenedPasswordPanel, isOpenedTwoFactorPanel } = this.state;
    const { days: passwordDaysToLive } = DateHelpers.convertMS(
      user.passwordTTL * 1000
    );

    return (
      <div className={classNames('profile-secure card card_l', customClass)}>
        <div className='card__header card__header_space-between'>
          <div className='card__title card__title-l'>
            {getTranslate('profile.security.header')}
          </div>
          <div className='profile-secure__buttons'>
            <Button
              text={getTranslate('profile.security.password.button')}
              size='normal'
              status='primary'
              type='submit'
              customClass='profile-secure__button'
              onClick={() => this.togglePasswordPanel(true)}
            />
          </div>
          <Panel
            id='change-password-panel'
            title={getTranslate('profile.changepass.label')}
            customClass='ui-panel_400 ui-panel_profile-secure profile-secure__panel'
            isOpened={isOpenedPasswordPanel}
            excludeFromCloseTrigger={['.profile-secure__button']}
            onClose={() => this.togglePasswordPanel(false)}
            modern>
            <ChangePassword
              onChangeSuccess={() => {
                this.togglePasswordPanel(false);
              }}
            />
          </Panel>
          <Panel
            title={getTranslate('profile.twoFA.header')}
            id='two-factor-auth-panel'
            customClass={`ui-panel_by-content ui-panel_two-fa profile-secure__panel profile-secure__panel-auth-${
              user.twoFactorAuth ? 'off' : 'on'
            }`}
            isOpened={isOpenedTwoFactorPanel}
            excludeFromCloseTrigger={['#toggle-two-factor-auth']}
            onClose={() => this.toggleAuthPanel(false)}
            modern>
            <TwoFactorAuth
              onChangeTwoFactor={() => this.toggleAuthPanel(false)}
            />
          </Panel>
        </div>
        <div className='card__container profile-secure__row'>
          <div className='card card_grey profile-secure__column'>
            <div className='card__header card__header_space-between profile-secure__header'>
              <span className='card__title card__title-l'>
                {getTranslate('profile.security.twoFA.label')}
                {user.isTwoFactorRequired && (
                  <span className='profile-secure__active'> (Active)</span>
                )}
              </span>
              {!user.isTwoFactorRequired && (
                <Switch
                  disabled={loading}
                  id='toggle-two-factor-auth'
                  customClass={classNames('profile-secure__switcher', {
                    'profile-secure__switcher__error':
                      validationErrors.contactPhone,
                  })}
                  checked={user.twoFactorAuth}
                  onChange={this.on2FASwitchChange}
                  textPosition='right'
                />
              )}
            </div>

            <div className='card__container card__content'>
              {validationErrors.contactPhone && (
                <div className='profile-secure__error'>
                  {getTranslate('userProfile.2FA.emptyContactPhone.error')}
                </div>
              )}
              {getTranslate('profile.security.twoFA.text')}
            </div>
          </div>
          <div className='card card_grey profile-secure__column'>
            <div className='card__header'>
              <span className='card__title card__title-l'>
                {getTranslate('profile.security.password.label')}
              </span>{' '}
              <span className='profile-secure__pass-valid'>
                (
                {getTranslate('profile.security.passwordValid.text', {
                  daysValid: passwordDaysToLive,
                })}
                )
              </span>
            </div>
            <div className='card__container card__content'>
              {getTranslate('profile.security.passwordInfo.text')}
            </div>
          </div>
        </div>
      </div>
    );
  }

  togglePasswordPanel(isOpened: boolean) {
    if (isNotAvailableForSupport(Messages.Auth_PasswordChange)) return false;
    this.setState({ isOpenedPasswordPanel: isOpened });
  }

  toggleAuthPanel(isOpened: boolean) {
    if (isNotAvailableForSupport(Messages.GoogleAuth_EnableParams))
      return false;
    this.setState({ isOpenedTwoFactorPanel: isOpened });
  }

  on2FASwitchChange = () => {
    const { user, handle2FAConfirmation } = this.props;
    if (user.twoFactorAuth) {
      this.toggleAuthPanel(true);
      return;
    }
    handle2FAConfirmation();
  };
}

export default addTranslation(ProfileSecure);
