import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';
import { MESSAGES_PER_REQUEST } from 'components/supportChat/constants';

const sendMessage = (msg) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.SupportChat_Message,
        payload: {
          ...msg,
        },
      },
    ],
  });
};

interface IGetMessages {
  ltMessageId?;
  gtMessageId?;
}

const getMessages = (payload?: IGetMessages) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.SupportChat_Messages,
        payload: {
          limit: MESSAGES_PER_REQUEST,
          ltMessageId: payload?.ltMessageId,
          gtMessageId: payload?.gtMessageId,
        },
      },
    ],
  });
};

const getTips = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.HelpTips_List,
      },
    ],
  });
};

const markTipAsRead = (helpTip: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.HelpTips_MarkAsRead,
        payload: {
          helpTip,
        },
      },
    ],
  });
};

const markChatMessagesAsRead = (messageIds: string[]) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.SupportChat_MessageMarkAsRead,
        payload: {
          messageIds,
        },
      },
    ],
  });
};

export default {
  markChatMessagesAsRead,
  sendMessage,
  getMessages,
  getTips,
  markTipAsRead,
};
