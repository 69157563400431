import Utils from 'helpers/Utils';
import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';
import { UserNotificationsType } from 'types/UserNotifications';

const initialState = {
  counters: {
    unread: 0,
  },
  filters: {
    onlyNew: false,
  },
  settings: [],
  items: [],
  isFetched: false,
} as UserNotificationsType;

const userNotifications = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.USER_NOTIFICATIONS_UPDATE:
      return {
        ...state,
        ...payload.data,
      };
    case ACTIONS.USER_NOTIFICATIONS_NEW_ITEM:
      return {
        ...state,
        items: [payload.item, ...state.items],
      };
    case ACTIONS.USER_NOTIFICATIONS_UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === payload.item.id) {
            return { ...payload.item };
          }
          return { ...item };
        }),
      };
    case ACTIONS.USER_NOTIFICATIONS_DELETE_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => {
          return item.id !== payload.item.id;
        }),
      };
    case ACTIONS.USER_NOTIFICATIONS_DELETE_ALL:
      return {
        ...state,
        counters: {
          ...state.counters,
          unread: 0,
        },
        items: [],
      };
    case ACTIONS.USER_NOTIFICATIONS_UPDATE_COUNTS: {
      const newCounters = { ...state.counters };
      for (const key in payload.counters) {
        if (Utils.hasProp(payload.counters, key)) {
          if (!newCounters[key]) {
            newCounters[key] = 0;
          }
          newCounters[key] = +newCounters[key] + +payload.counters[key];
        }
      }
      return {
        ...state,
        counters: newCounters,
      };
    }
    default:
      return state;
  }
};

export default userNotifications;
