import React from 'react';
import { connect } from 'react-redux';

import { StoreProps } from 'store';
import { AdvancedAnalyticsEntityType } from './components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';
import {
  ChartViewData,
  IDictionary,
  IFormValidateHandler,
  IFormValues,
  IOnChange,
  IValidateHandler,
  IValidator,
} from 'types/Analytics';

import { createChart } from 'actions/analytics';
import { closeModal } from 'actions/modal';

import { GraphDetails } from './GraphDetails';
import { ServiceGetMockedOptionsByType } from './services/ServiceGetMockedOptionsByType';
import { ServiceSettingsFormHelpers } from './services/ServiceSettingsFormHelpers';
import { types } from './services/ServiceGetModalChartTypes';
import DateHelpers from 'helpers/Date';

import { AnyObject } from 'types/Common';

import DateFormats from 'constants/dateFormats';
import { FormValidate } from './components/settingsForm/InOutFormConfig';
import { InOutSalesAgentsFormValidate } from './components/settingsForm/InOutSalesAgentsFormConfig';
import { SALES_AGENTS_ROLE } from '../../../userBuilder/constants';

const WEEK = 7;
const MAX_INOUT_TABLE_AMOUNT = 3;
const FORM_VALIDATORS = {
  [AdvancedAnalyticsEntityType.inout]: FormValidate,
  [AdvancedAnalyticsEntityType.inout_sales_agents]:
    InOutSalesAgentsFormValidate,
};

interface OwnProps {
  operationType: IDictionary;
  callback: (savingData: AnyObject) => void;
}

interface ConnectedProps {
  roles: string[];
  projects: { list: IDictionary; isLoading: boolean };
  country: { list: IDictionary; isLoading: boolean };
  paymentMethod: { list: IDictionary; isLoading: boolean };
  currency: { list: IDictionary; isLoading: boolean };
  analyticsBrand: { list: IDictionary; isLoading: boolean };
  paymentMethodFromDictionary: { list: IDictionary; isLoading: boolean };
  issuerBank: { list: IDictionary; isLoading: boolean };
  inOutTableAmount: number;
  inOutTableAmountSalesAgents: number;
  declineReasonsTableAmount: number;
}

type Props = OwnProps & ConnectedProps & StoreProps;

interface State {
  graphType: AdvancedAnalyticsEntityType;
  values: { [key: string]: IFormValues };
  options: ChartViewData | null;
  validationErrors:
    | {
        [key: string]: string;
      }
    | {};
  isLoading: boolean;
  formValidationErrors:
    | {
        [key: string]: string;
      }
    | {};
  pristineFields: { [key: string]: boolean };
  initialValues: IFormValues;
}

class GraphDetailsContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.setGraphType = this.setGraphType.bind(this);
    this.submitButtonDisabledConditions =
      this.submitButtonDisabledConditions.bind(this);

    const { roles } = props;
    const graphType = roles.includes(SALES_AGENTS_ROLE)
      ? AdvancedAnalyticsEntityType.payments_sa
      : AdvancedAnalyticsEntityType.operations;
    this.state = {
      ...this.initializeState({
        graphType,
      }),
    } as State;
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { graphType } = this.state;
    const prevValues = prevState.values[AdvancedAnalyticsEntityType[graphType]];
    const currentValues =
      this.state.values[AdvancedAnalyticsEntityType[graphType]];

    const prevChartType =
      prevValues !== undefined && 'chartType' in prevValues
        ? prevValues.chartType
        : null;
    const chartType =
      prevValues !== undefined && 'chartType' in currentValues
        ? currentValues.chartType
        : null;

    const supportedGraphTypes = [
      AdvancedAnalyticsEntityType.operations,
      AdvancedAnalyticsEntityType.payments,
      AdvancedAnalyticsEntityType.payments_sa,
      AdvancedAnalyticsEntityType.declineCodes,
      AdvancedAnalyticsEntityType.topten,
      AdvancedAnalyticsEntityType.fraud_report,
      AdvancedAnalyticsEntityType.chargeback,
    ];

    if (
      supportedGraphTypes.includes(graphType) &&
      chartType &&
      prevChartType &&
      chartType !== prevChartType
    ) {
      const options = ServiceGetMockedOptionsByType({
        type: chartType,
        group: graphType,
      });

      this.setState({
        options,
      });
    }
  }

  submitButtonDisabledConditions() {
    const {
      inOutTableAmount,
      inOutTableAmountSalesAgents,
      declineReasonsTableAmount,
    } = this.props;
    const { graphType } = this.state;
    const acc: Array<{ tooltipText: string; disabled: boolean }> = [];

    if (
      inOutTableAmount >= MAX_INOUT_TABLE_AMOUNT &&
      graphType === AdvancedAnalyticsEntityType.inout
    ) {
      acc.push({
        tooltipText: 'analytics.editForm.addInOutChart.toolTip',
        disabled: true,
      });
    } else if (
      inOutTableAmountSalesAgents >= MAX_INOUT_TABLE_AMOUNT &&
      graphType === AdvancedAnalyticsEntityType.inout_sales_agents
    ) {
      acc.push({
        tooltipText: 'analytics.editForm.addInOutChart.toolTip',
        disabled: true,
      });
    } else if (
      declineReasonsTableAmount >= MAX_INOUT_TABLE_AMOUNT &&
      graphType === AdvancedAnalyticsEntityType.declineReasons
    ) {
      acc.push({
        tooltipText: 'analytics.editForm.addPaymentsPivot.toolTip',
        disabled: true,
      });
    }

    return acc;
  }

  get dictionariesLoading() {
    const {
      projects,
      currency,
      paymentMethod,
      country,
      analyticsBrand,
      paymentMethodFromDictionary,
      issuerBank,
    } = this.props;

    return (
      projects.isLoading &&
      currency.isLoading &&
      paymentMethod.isLoading &&
      country.isLoading &&
      analyticsBrand.isLoading &&
      paymentMethodFromDictionary.isLoading &&
      issuerBank.isLoading
    );
  }

  render() {
    const {
      projects,
      currency,
      paymentMethod,
      country,
      analyticsBrand,
      paymentMethodFromDictionary,
      issuerBank,
      dispatch,
    } = this.props;
    const {
      graphType,
      validationErrors,
      options,
      values,
      isLoading,
      formValidationErrors,
      pristineFields,
    } = this.state;

    const formValidators =
      FORM_VALIDATORS[graphType] === undefined
        ? []
        : FORM_VALIDATORS[graphType];
    const formValidate = formValidators.reduce((acc, { fields }) => {
      acc = [...acc, ...fields];

      return acc;
    }, []);

    return (
      <GraphDetails
        titleToken='analytics.editForm.addChart.label'
        chartTypes={types}
        isLoading={isLoading || this.dictionariesLoading}
        validationErrors={validationErrors}
        options={options}
        values={values[AdvancedAnalyticsEntityType[graphType]]}
        multiSelectItems={{
          projects: projects.list,
          currency: currency.list,
          paymentMethod: paymentMethod.list,
          country: country.list,
          analyticsBrand: analyticsBrand.list,
          paymentMethodFromDictionary: paymentMethodFromDictionary.list,
          issuerBank: issuerBank.list,
        }}
        onChange={this.onChange}
        selectedType={graphType}
        setType={this.setGraphType}
        closeModal={this.closeModal}
        saveChanges={this.saveChanges}
        validateHandler={this.validateHandler}
        formValidationErrors={formValidationErrors}
        submitButtonDisabledConditions={this.submitButtonDisabledConditions()}
        pristineFields={pristineFields}
        formValidatedFields={formValidate}
        dispatch={dispatch}
        canApplyTemplate={true}
        onApplyTemplate={this.applyValuesToState}
      />
    );
  }

  generatePristineFieldMap(values) {
    return Object.fromEntries(
      Object.entries(values).map(([key]) => [key, true])
    );
  }

  getInitialValidationResults(
    validationMap: { [key: string]: IValidator[] },
    initialValues: IFormValues
  ) {
    return Object.entries(validationMap).reduce(
      (acc, [field, validatorList]) => {
        acc[field] = validatorList.map((validator) =>
          validator(initialValues[field])
        );

        return acc;
      },
      {}
    );
  }

  initializeState({ graphType }: { graphType: AdvancedAnalyticsEntityType }) {
    const today = DateHelpers.getEndDay(DateHelpers.getDate()).format(
      DateFormats.datetime
    );
    const weekEarlier = DateHelpers.getStartDay(
      DateHelpers.subtractDays(WEEK)
    ).format(DateFormats.datetime);

    if (graphType === AdvancedAnalyticsEntityType.operations) {
      const initialValues = {
        chart: AdvancedAnalyticsEntityType.operations,
        chartName: '',
        groupByDate: '1d',
        groupBy: 'operationType',
        chartType: 'line',
        chartData: 'count',
        operationStatus: [],
        period: { from: weekEarlier, to: today },
        projects: [],
        lastStepCascade: [],
      };

      return {
        isLoading: false,
        graphType: AdvancedAnalyticsEntityType.operations,
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: initialValues,
        },
        validationErrors: {},
        formValidationErrors: {},
        options: ServiceGetMockedOptionsByType({
          type: 'line',
          group: graphType,
        }),
        pristineFields: this.generatePristineFieldMap(initialValues),
        initialValues,
      };
    } else if (graphType === AdvancedAnalyticsEntityType.payments) {
      const initialValues = {
        chart: AdvancedAnalyticsEntityType.payments,
        chartName: '',
        groupByDate: '1d',
        groupBy: 'paymentType',
        chartType: 'line',
        chartData: 'count_transactions',
        paymentStatus: [],
        period: { from: weekEarlier, to: today },
        projects: [],
      };

      return {
        isLoading: false,
        graphType: AdvancedAnalyticsEntityType.payments,
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: initialValues,
        },
        validationErrors: {},
        formValidationErrors: {},
        options: ServiceGetMockedOptionsByType({
          type: 'line',
          group: graphType,
        }),
        pristineFields: this.generatePristineFieldMap(initialValues),
        initialValues,
      };
    } else if (
      graphType === AdvancedAnalyticsEntityType.payments_sa
    ) {
      const initialValues = {
        chart: AdvancedAnalyticsEntityType.payments_sa,
        chartName: '',
        groupByDate: '1d',
        groupBy: 'paymentType',
        chartType: 'line',
        chartData: 'count_transactions',
        paymentStatus: [],
        period: { from: weekEarlier, to: today },
        projects: [],
      };

      return {
        isLoading: false,
        graphType: AdvancedAnalyticsEntityType.payments_sa,
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: initialValues,
        },
        validationErrors: {},
        formValidationErrors: {},
        options: ServiceGetMockedOptionsByType({
          type: 'line',
          group: graphType,
        }),
        pristineFields: this.generatePristineFieldMap(initialValues),
        initialValues,
      };
    } else if (graphType === AdvancedAnalyticsEntityType.inout) {
      const initialValues = {
        chart: AdvancedAnalyticsEntityType.inout,
        chartName: '',
        chartData: 'amount',
        groupByDate: '1d',
        groupByRows: [],
        groupByColumns: [],
        projects: [],
        paymentMethod: [],
        channelCurrency: [],
        type: [],
        period: {
          from: weekEarlier,
          to: today,
        },
        country: [],
      };

      return {
        isLoading: false,
        graphType: AdvancedAnalyticsEntityType.inout,
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: initialValues,
        },
        validationErrors: {},
        formValidationErrors: {},
        options: ServiceGetMockedOptionsByType({
          type: 'table',
          group: graphType,
        }),
        pristineFields: this.generatePristineFieldMap(initialValues),
        initialValues,
      };
    } else if (graphType === AdvancedAnalyticsEntityType.inout_sales_agents) {
      const initialValues = {
        chart: AdvancedAnalyticsEntityType.inout_sales_agents,
        chartName: '',
        chartData: 'amount',
        groupByDate: '1d',
        groupByRows: [],
        groupByColumns: [],
        projects: [],
        paymentMethod: [],
        channelCurrency: [],
        type: [],
        period: {
          from: weekEarlier,
          to: today,
        },
        country: [],
      };

      return {
        isLoading: false,
        graphType: AdvancedAnalyticsEntityType.inout_sales_agents,
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: initialValues,
        },
        validationErrors: {},
        formValidationErrors: {},
        options: ServiceGetMockedOptionsByType({
          type: 'table',
          group: graphType,
        }),
        pristineFields: this.generatePristineFieldMap(initialValues),
        initialValues,
      };
    } else if (graphType === AdvancedAnalyticsEntityType.declineReasons) {
      const initialValues = {
        chartName: '',
        chartData: 'count',
        groupByRows: [],
        groupByColumns: [],
        groupByDate: '1d',
        period: {
          from: weekEarlier,
          to: today,
        },
        projectId: [],
        operationType: [],
        operationStatus: [],
        countryByBin: [],
        countryByIp: [],
        paymentMethod: [],
        currency: [],
      };

      return {
        isLoading: false,
        graphType: AdvancedAnalyticsEntityType.declineReasons,
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: initialValues,
        },
        validationErrors: {},
        formValidationErrors: {},
        options: ServiceGetMockedOptionsByType({
          type: 'table',
          group: graphType,
        }),
        pristineFields: this.generatePristineFieldMap(initialValues),
        initialValues,
      };
    } else if (graphType === AdvancedAnalyticsEntityType.topten) {
      return {
        isLoading: false,
        graphType: AdvancedAnalyticsEntityType.topten,
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: {
            chart: AdvancedAnalyticsEntityType.topten,
            chartName: '',
            chartData: 'count',
            dimension: 'paymentMethod',
            chartType: 'bar',
            period: { from: weekEarlier, to: today },
            projects: [],
          },
        },
        options: ServiceGetMockedOptionsByType({
          type: 'bar',
          group: graphType,
        }),
      };
    } else if (graphType === AdvancedAnalyticsEntityType.declineCodes) {
      return {
        isLoading: false,
        graphType: graphType,
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: {
            chart: graphType,
            chartName: '',
            chartData: 'count',
            dimension: 'mappingResultMessage',
            chartType: 'bar',
            period: { from: weekEarlier, to: today },
            projects: [],
          },
        },
        options: ServiceGetMockedOptionsByType({
          type: 'bar',
          group: graphType,
        }),
      };
    } else if (graphType === AdvancedAnalyticsEntityType.fraud_report) {
      return {
        isLoading: false,
        graphType: AdvancedAnalyticsEntityType.fraud_report,
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: {
            chart: AdvancedAnalyticsEntityType.fraud_report,
            chartName: '',
            chartData: 'numberOfFraudOperations',
            chartType: 'simple_table',
            period: { from: weekEarlier, to: today },
            projectId: [],
            groupByMultiSelect: [],
            groupBy: 'cardType',
            brandId: [],
            groupByDate: '',
            issuerCountry: [],
            cardType: [],
          },
        },
        options: ServiceGetMockedOptionsByType({
          type: 'simple_table',
          group: graphType,
        }),
      };
    } else if (graphType === AdvancedAnalyticsEntityType.chargeback) {
      return {
        isLoading: false,
        graphType: AdvancedAnalyticsEntityType.chargeback,
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: {
            chart: AdvancedAnalyticsEntityType.chargeback,
            chartName: '',
            chartData: 'count_chargeback_id',
            chartType: 'bar',
            period: { from: weekEarlier, to: today },
            projects: [],
            groupByMultiSelect: [],
            groupBy: 'cardType',
          },
        },
        options: ServiceGetMockedOptionsByType({
          type: 'bar',
          group: graphType,
        }),
      };
    }
  }

  validateHandler: IValidateHandler = (name, value, formValues, validator) => {
    const { validationErrors } = this.state;
    const validationResult = validator
      .map((func) => func(name))
      .filter((err) => err !== undefined);

    if (validationResult.length !== 0) {
      const newErrors = { [name]: validationResult };

      this.setState({
        validationErrors: { ...validationErrors, ...newErrors },
      });
    } else {
      const withoutCurrentFieldErrors = Object.entries(validationErrors).reduce(
        (acc, [field, err]) => {
          if (field === name) {
            return acc;
          }

          acc[field] = err;

          return acc;
        },
        {}
      );

      this.setState({
        validationErrors: withoutCurrentFieldErrors,
      });
    }
  };

  formValidateHandler: IFormValidateHandler = (name, value, formValues) => {
    const { formValidationErrors, graphType } = this.state;
    const formValidate = FORM_VALIDATORS[graphType];

    if (formValidate !== undefined) {
      const newErrors = formValidate.reduce((acc, { _, errId, validator }) => {
        const validationResult = validator(name, value, formValues);

        acc[errId] = validationResult;

        return acc;
      }, {});

      this.setState({
        formValidationErrors: { ...formValidationErrors, ...newErrors },
      });
    }
  };

  onChange: IOnChange = (name, value, onChangeCallback) => {
    const { graphType } = this.state;
    const values = this.state.values[AdvancedAnalyticsEntityType[graphType]];
    const newValues = { ...values, [name]: value };
    const cbHandledValues =
      onChangeCallback !== undefined
        ? { ...onChangeCallback(newValues, values) }
        : newValues;

    ServiceSettingsFormHelpers.adjustOptionalFieldsValues({
      values: cbHandledValues,
      chartType: graphType,
    });

    this.setState({
      values: {
        [AdvancedAnalyticsEntityType[graphType]]: { ...cbHandledValues },
      },
    });
  };

  setGraphType(graphType: AdvancedAnalyticsEntityType) {
    const newState = { ...this.initializeState({ graphType }) } as State;

    this.setState({ ...newState });
  }

  saveChanges = async () => {
    const { dispatch } = this.props;
    const { graphType } = this.state;
    const values = this.state.values[AdvancedAnalyticsEntityType[graphType]];

    this.setState({ isLoading: true });

    await dispatch(createChart({ graphType, values }));

    this.setState({ isLoading: false });
  };

  applyValuesToState = (selectType: string, values: any) => {
    const newValues = {
      [selectType]: {
        ...this.state.values[selectType],
        ...values,
      },
    };

    this.setState({
      values: newValues,
    });
  };

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(closeModal());
  };
}

const mapStateToProps = (state): ConnectedProps => {
  const {
    filtersValues,
    analytics: {
      chartData: { byId },
    },
    user: { userRoles },
  }: {
    filtersValues?: any;
    analytics: { chartData: { byId: { [id: string]: any } } };
    user: { userRoles: string[] };
  } = state;

  const inOutTableAmount = Object.entries(byId).filter(
    ([_, item]) => item.chart === AdvancedAnalyticsEntityType.inout
  ).length;

  const inOutTableAmountSalesAgents = Object.entries(byId).filter(
    ([_, item]) => item.chart === AdvancedAnalyticsEntityType.inout_sales_agents
  ).length;

  const declineReasonsTableAmount = Object.entries(byId).filter(
    ([_, item]) =>
      item.chartGroup === AdvancedAnalyticsEntityType.declineReasons
  ).length;

  return {
    roles: userRoles ?? [],
    projects: filtersValues?.enabledProject ?? {},
    country: filtersValues?.country ?? {},
    paymentMethod: filtersValues?.paymentMethodType ?? {},
    currency: filtersValues?.currency ?? {},
    analyticsBrand: filtersValues?.analyticsBrand ?? {},
    paymentMethodFromDictionary:
      filtersValues?.paymentMethodFromDictionary ?? {},
    issuerBank: filtersValues?.issuerBank ?? {},
    inOutTableAmount,
    inOutTableAmountSalesAgents,
    declineReasonsTableAmount,
  };
};

const GraphDetailsContainer_ = connect(mapStateToProps)(GraphDetailsContainer);

export { GraphDetailsContainer_ as GraphDetailsContainer };
