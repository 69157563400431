import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import queryString from 'query-string';

import { addListeners } from 'decorators/addListeners';
import { WithRouterProps } from 'decorators/withRouter';
import { addEntityToSaved, updateEntity } from 'actions/savedEntities';
import { openModal } from 'actions/modal';
import api from 'api/payouts';
import getPaymentById from 'selectors/getPaymentById';
import getConfigurationByName from 'selectors/getConfigurationByName';
import UiStateFactory from 'factories/UiStateFactory';
import { StoreProps } from 'store';

import PageTemplate from 'components/pageTemplate';
import TopPanelContainer from './components/topPanel';
import BatchDetail from './BatchDetail';
import BatchLoad from './components/loadFile';

import path from 'helpers/path';
import Utils from 'helpers/Utils';
import { getPathUrl } from 'helpers/paymentsPayoutsRequest';
import saveFile from 'helpers/saveFile';
import Messages from 'constants/rpcTypes';
import RequestStates from '../requestStates';
import savedEntities from 'constants/savedEntities';
import SavedPaymentsItem from 'types/savedEntity/SavedPayment';
import { AnyObject } from 'types/Common';
import SavedEntity from 'types/savedEntity';
import fieldsMapper from '../fieldsMapper';
import './batchDetail.scss';

const CLARIFICATION_STATUSES = {
  AWAITING_CLARIFICATION: 'awaiting clarification',
  CLARIFICATION: 'clarification',
};

interface OwnProps {
  id: string;
  tableName: string;
}

interface ConnectedProps {
  user: AnyObject;
  payout: SavedPaymentsItem;
  configuration: AnyObject[];
  storedSavedEntities: { isFetch: boolean; items: SavedEntity[] };
}

type Props = OwnProps & ConnectedProps & StoreProps & WithRouterProps;

interface State {
  isLoading: boolean;
  isEditing: boolean;
  isOpenUploading: boolean;
  isFileUploading: boolean;
  isSavingField: boolean;
  file: null | File;
  validationErrors: AnyObject;
  hasTableChanges: boolean;
  isTyping: boolean;
  wasShownChangesModal: boolean;
  rejectIds: string[];
  focusedField: {
    name: string;
    value: string;
    id: string;
  };
}

@addListeners([
  Messages.BulkPayouts_View,
  Messages.PaymentOperationBatch_Updated,
  Messages.PaymentOperationBatch_ClarificationUploaded,
  Messages.BulkPayouts_ClarificationFieldUpdate,
  Messages.BulkPayouts_ClarificationProceed,
  Messages.BulkPayouts_ClarificationReject,
  Messages.PaymentOperation_StatusUpdate,
  Messages.Confirm_Reject,
])
class BatchDetailContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      validationErrors: {},
      isLoading: true,
      isEditing: false,
      isFileUploading: false,
      isOpenUploading: false,
      hasTableChanges: false,
      isTyping: false,
      wasShownChangesModal: false,
      isSavingField: false,
      file: null,
      rejectIds: [],
      focusedField: {
        value: '',
        name: '',
        id: '',
      },
    };
  }

  async componentDidMount() {
    await this.init();
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    const { user, storedSavedEntities } = this.props;

    if (user && prevProps.user.timezone !== user.timezone) {
      return this.getBatch();
    }

    if (!prevProps.storedSavedEntities && storedSavedEntities) {
      await this.init();
    }
  }

  render() {
    const {
      file,
      validationErrors,
      isLoading,
      isEditing,
      isOpenUploading,
      isFileUploading,
      hasTableChanges,
      isSavingField,
      isTyping,
      focusedField,
      rejectIds,
    } = this.state;
    const { id, payout } = this.props;

    return (
      <Fragment>
        <TopPanelContainer
          id={id}
          payoutData={payout?.data}
          isEditing={isEditing}
          isLoading={isLoading}
          isOpenUploading={isOpenUploading}
          file={file}
          hasTableChanges={hasTableChanges}
          isSavingField={isSavingField}
          isTyping={isTyping}
          itemsToReject={rejectIds}
          expiredTime={this.getExpiredTime()}
          isClarification={this.isClarification()}
          onToggleEditMode={() => this.toggleEditMode()}
          onToggleUploading={this.toggleUploading}
          onProceed={() => this.proceed()}
          onReject={() => this.reject()}
          onDownloadFile={() => this.downloadBatch()}
          isProceedEnabled={this.checkIsProceedEnabled()}
          onComplete={() => {
            this.cancelEditMode();
          }}
        />
        <PageTemplate.Container>
          <BatchLoad
            file={file}
            isFileUploading={isFileUploading}
            validationErrors={validationErrors}
            isOpen={isOpenUploading}
            onDeleteFile={this.deleteFile}
            closeLoading={this.closeUploading}
            onDrop={(files) => this.onLoadFile(files)}
          />
          <BatchDetail
            isLoading={isLoading}
            isEditing={isEditing}
            isFetched={Boolean(payout?.data)}
            isOpenUploading={isOpenUploading}
            itemsToReject={rejectIds}
            focusedField={focusedField}
            itemsList={this.getCurrentItems()}
            columns={this.getBathConfig()}
            goToPayment={this.goToPayoutCard}
            selectItemsToReject={this.selectItemsToReject}
            onTableInputChange={this.changeDataColumn}
            onTableInputFocus={(paymentId, name, value) => {
              this.toggleFocus({ id: paymentId, name, value });
            }}
            onTableInputBlur={(data) => {
              this.toggleFocus();
              this.saveDataColumn(data);
            }}
          />
        </PageTemplate.Container>
      </Fragment>
    );
  }

  async init() {
    const { id, dispatch, location, payout, tableName, storedSavedEntities } =
      this.props;
    const urlParams = queryString.parse(location.search);

    if (!storedSavedEntities) return;

    if (!payout) {
      const entityKey = savedEntities[tableName];
      dispatch(addEntityToSaved({ entityKey, id, urlParams }));
    }

    if (!payout || (payout && !payout.data)) {
      const ui = UiStateFactory.getInitialPageUI();
      this.syncPayoutUIWithStore(id, ui);
    }

    await this.getBatch();
  }

  async getBatch() {
    const { id } = this.props;
    try {
      this.setState({ isLoading: true });
      await api.getBatch(id);
    } catch (error) {
      console.error(error);
    }
  }

  setBatch = (payload) => {
    const { id, tableName, dispatch } = this.props;
    const entityKey = savedEntities[tableName];
    dispatch(updateEntity({ entityKey, id, fields: { data: payload } }));
  };

  getBathConfig = () => {
    const { configuration } = this.props;
    const columns = {};

    let config = [...configuration];

    this.getDataRows().forEach((row) => {
      const { clarificationRules } = row;
      if (!clarificationRules) return;

      for (const key in clarificationRules) {
        if (Utils.hasProp(clarificationRules, key)) {
          if (fieldsMapper[key]) {
            config = config.filter((col) => {
              return col.id !== fieldsMapper[key];
            });
          }
          columns[key] = {
            id: key,
            ...clarificationRules[key],
          };
        }
      }
    });

    return [...config, ...Object.values(columns)];
  };

  getDataRows = (): AnyObject[] => {
    const { payout } = this.props;
    return payout?.data?.rows || [];
  };

  getCurrentItems = () => {
    const { isEditing } = this.state;

    const newData = this.getDataRows().map((item) => {
      const { clarificationValues } = item;
      for (const key in clarificationValues) {
        if (!Utils.hasProp(clarificationValues, key)) continue;
        item[key] = clarificationValues[key];
      }
      return item;
    });

    if (isEditing) {
      return newData.filter(({ paymentStatus }) => {
        return (
          paymentStatus === CLARIFICATION_STATUSES.AWAITING_CLARIFICATION ||
          paymentStatus === CLARIFICATION_STATUSES.CLARIFICATION
        );
      });
    }

    return newData;
  };

  changeDataColumn = (paymentId, fieldName, value) => {
    const { id, tableName, payout, dispatch } = this.props;

    const newDataRows = this.getDataRows().map((item) => {
      if (item.paymentId === paymentId) {
        return {
          ...item,
          clarificationValues: {
            ...item.clarificationValues,
            [fieldName]: value,
          },
        };
      }
      return item;
    });

    this.setState({
      hasTableChanges: true,
      focusedField: {
        value,
        name: fieldName,
        id: paymentId,
      },
    });

    const entityKey = savedEntities[tableName];
    const newData = {
      ...payout.data,
      rows: newDataRows,
    };

    dispatch(updateEntity({ entityKey, id, fields: { data: newData } }));
  };

  async saveDataColumn(data) {
    const { id } = this.props;
    const payload = {
      ...data,
      paymentOperationBatchId: id,
    };
    try {
      this.setState({ isSavingField: true });
      await api.updateField(payload);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isSavingField: false,
      });
    }
  }

  updateDataRow = (updatedItem) => {
    const { payout } = this.props;
    const { focusedField } = this.state;

    const newDataRows = this.getDataRows().map((item) => {
      if (item.paymentId === updatedItem.paymentId) {
        const { clarificationValues } = updatedItem;
        for (const field in clarificationValues) {
          if (!Utils.hasProp(clarificationValues, field)) continue;

          if (
            updatedItem.paymentId === focusedField.id &&
            field === focusedField.name
          ) {
            clarificationValues[field] = focusedField.value;
          }

          updatedItem[field] = clarificationValues[field];
        }
        return { ...updatedItem };
      }
      return item;
    });

    this.setBatch({
      ...payout.data,
      rows: newDataRows,
    });
  };

  isClarification = (): boolean => {
    return Boolean(this.getExpiredTime());
  };

  getExpiredTime = (): string => {
    const { payout } = this.props;
    return payout?.data?.clarificationBestBefore;
  };

  syncPayoutUIWithStore = (id, ui) => {
    const { dispatch, tableName } = this.props;
    const entityKey = savedEntities[tableName];
    dispatch(updateEntity({ entityKey, id, fields: { ui } }));
  };

  goToPayoutCard = (data) => {
    const { history, tableName } = this.props;
    const { isEditing } = this.state;
    if (isEditing) return;

    const { state } = data;
    if (
      !(
        state === RequestStates.STATE_DECLINE_DWH ||
        state === RequestStates.STATE_DECLINE_BY_USER_DWH ||
        state === RequestStates.STATE_SUCCESS_DWH ||
        state === RequestStates.STATE_PARTIALLY_PAID_OUT_DWH
      )
    )
      return;

    if (data.paymentId && data.transactionId) {
      history.push(path(getPathUrl(savedEntities[tableName], data)));
    }
  };

  onLoadFile(file) {
    this.setState({ file });
  }

  toggleEditMode() {
    const { isEditing, isOpenUploading } = this.state;
    if (isEditing && isOpenUploading) {
      this.closeUploading();
    }
    this.setState({ isEditing: !isEditing });
  }

  toggleUploading = () => {
    const { dispatch } = this.props;
    const { hasTableChanges, wasShownChangesModal, isOpenUploading } =
      this.state;

    if (isOpenUploading) {
      return this.closeUploading();
    }

    if (hasTableChanges && !wasShownChangesModal) {
      dispatch(
        openModal({
          content: {
            title: 'modals.common.title',
            text: 'payouts.request.mass.modal.lostRegistryChanges.text',
          },
          modalId: 'Confirm',
          callback: (answer) => {
            if (answer) {
              this.setState({
                isOpenUploading: true,
                wasShownChangesModal: true,
              });
            }
          },
        })
      );
    } else {
      this.setState({ isOpenUploading: true });
    }
  };

  closeUploading = () => {
    this.setState(
      {
        isOpenUploading: false,
        isFileUploading: false,
      },
      this.deleteFile
    );
  };

  deleteFile = () => {
    this.setState({
      file: null,
      validationErrors: {},
    });
  };

  async downloadBatch() {
    const { id } = this.props;

    try {
      const response = await api.downloadBatchClarification(id);
      await saveFile(`batch-${id}.csv`, response);
    } catch (error) {
      console.error(error);
    }
  }

  async proceed() {
    const { id } = this.props;
    const { file } = this.state;
    //проверить что закончилось сохранение поля
    if (file) {
      try {
        this.setState({ isFileUploading: true });
        await api.uploadBatchClarification({
          paymentOperationBatchId: id,
          file,
        });
      } catch (error) {
        if (error.payload.validationErrors) {
          this.setState({
            validationErrors: error.payload.validationErrors,
            isFileUploading: false,
          });
        }
      }
    } else {
      this.setState({ isLoading: true });
      await api.sendEditedClarification(id);
    }
  }

  async reject() {
    const { id } = this.props;
    const { rejectIds } = this.state;

    this.setState({ isLoading: true });

    try {
      await api.rejectPayouts({
        paymentOperationBatchId: +id,
        payoutQueueIds: rejectIds,
      });
    } catch (error) {
      console.error(error);
    }
  }

  selectItemsToReject = (value: string | boolean) => {
    const { rejectIds } = this.state;
    if (typeof value === 'boolean') {
      this.checkAll(value);
      return;
    }
    if (rejectIds.includes(value)) {
      this.setState({
        rejectIds: rejectIds.filter((itemId) => itemId !== value),
      });
    } else {
      this.setState({ rejectIds: [...rejectIds, value] });
    }
  };

  checkAll = (isCheck) => {
    if (isCheck) {
      const rejectIds = this.getCurrentItems().map(
        (item) => item.payoutQueueId
      );
      this.setState({ rejectIds });
    } else {
      this.setState({ rejectIds: [] });
    }
  };

  cancelEditMode = () => {
    this.setState({
      isEditing: false,
      file: null,
      hasTableChanges: false,
      isOpenUploading: false,
      rejectIds: [],
    });
  };

  checkIsProceedEnabled = (): boolean => {
    const { hasTableChanges, file, validationErrors } = this.state;
    return hasTableChanges || (Boolean(file) && isEmpty(validationErrors));
  };

  toggleFocus = (data?) => {
    if (!data) {
      this.setState({
        isTyping: false,
        focusedField: { name: '', value: '', id: '' },
      });
      return;
    }
    this.setState({
      isTyping: true,
      focusedField: { name: data.name, value: data.value, id: data.id },
    });
  };

  async onEvent({ name, data }) {
    const { id, payout } = this.props;
    const { rpc, payload } = data;

    if (rpc.status !== 'success') return;

    switch (name) {
      case Messages.Confirm_Reject:
        return this.setState({
          isLoading: false,
        });
    }

    if (+id !== +payload.paymentOperationBatchId) return;

    switch (name) {
      case Messages.BulkPayouts_View:
        this.setState({
          isLoading: false,
        });
        return this.setBatch(payload);
      case Messages.PaymentOperationBatch_Updated:
        return this.setBatch({
          ...payout.data,
          ...payload,
        });
      case Messages.PaymentOperation_StatusUpdate:
        return this.updateDataRow(payload);
      case Messages.BulkPayouts_ClarificationFieldUpdate:
        return this.updateDataRow(payload);
      case Messages.BulkPayouts_ClarificationProceed:
      case Messages.BulkPayouts_ClarificationReject:
        this.cancelEditMode();
        this.setState({
          isLoading: false,
        });
        return this.setBatch(payload);
      case Messages.PaymentOperationBatch_ClarificationUploaded:
        this.setState({
          isFileUploading: false,
        });
        if (payload.errors?.length) {
          return this.setState({
            validationErrors: { file: payload.errors.join(', ') },
          });
        }
        this.cancelEditMode();
        return this.getBatch();
    }
  }
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => ({
  user: state.user,
  payout: getPaymentById(state, savedEntities[ownProps.tableName], ownProps.id),
  configuration: getConfigurationByName(state, 'batch'),
  storedSavedEntities: state.savedEntities[savedEntities.payouts],
});

export default withRouter(connect(mapStateToProps)(BatchDetailContainer));
