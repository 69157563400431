import React, { PureComponent } from 'react';
import Icon from 'components/ui/icon';
import Env from 'helpers/Env';
import './avatar.scss';
import classNames from 'classnames';
import Loader from 'components/ui/loader';

interface Props {
  user: any;
  size: 'small' | 'large';
  id?: string;
  customClass?: string;
  isEdit?: boolean;
  isLoading?: boolean;
}

class Avatar extends PureComponent<Props> {
  render() {
    const {
      user: { avaUrl },
      size,
      id,
      isEdit,
      isLoading,
      customClass,
    } = this.props;

    let path;

    if (avaUrl) {
      path = avaUrl.charAt(0) === '/' ? avaUrl.slice(1) : avaUrl;
    }

    return (
      <div
        id={id}
        className={classNames(`ui-avatar ui-avatar_${size} utils-align`, {
          [`${customClass}`]: !!customClass,
          'ui-avatar_edit': !!isEdit,
        })}>
        {isLoading && <Loader customClass='ui-loader_small' />}
        {path ? (
          <img
            className='ui-avatar__image'
            src={Env.getApiUrl() + path}
            alt='avatar'
          />
        ) : (
          <span className='ui-avatar__icon-wrapper'>
            <Icon
              size={this.getIconSize()}
              className='ui-avatar__icon'
              name={isEdit ? 'im-Camera' : 'im-Avatar'}
            />
          </span>
        )}
      </div>
    );
  }

  getIconSize() {
    const { size, isEdit } = this.props;
    if (size === 'small') {
      return 14;
    } else if (isEdit) {
      return 18;
    }
    return 24;
  }
}

export default Avatar;
