import { generaTemplateTitle, prepareProjects } from './helpers';
import { IDictionary } from '../../../../../../../types/Analytics';
import { GetTranslate } from '../../../../../../../decorators/addTranslation';

const templateTopTenIssuer = (
  getTranslate: GetTranslate,
  projects: IDictionary
) => {
  return {
    chartParams: {
      chartName: generaTemplateTitle(
        'analytics.editForm.fromTemplate.title.byIssuers',
        getTranslate
      ),
      chartData: 'count',
      dimension: 'issuerBankName',
      chartType: 'bar',
    },
    filters: {
      // TODO: revise - other chart use projects filter
      projectId: prepareProjects(projects),
    },
  };
};

export { templateTopTenIssuer };
