import { FilterTypes } from 'constants/FilterTypes';
import FormFieldsConfig from 'components/formFields/types';

const fieldsConfig: FormFieldsConfig = {
  general: [
    {
      id: 'paymentId',
      fieldType: FilterTypes.text,
      valueType: 'string',
      required: true,
      generateValue: true,
      clearButton: true,
      label: 'remittance.addPayout.field.paymentID.label',
      placeholder: 'remittance.addPayout.field.paymentID.placeholder',
      subText: 'remittance.addPayout.field.paymentID.hint',
      tooltip: 'paymentIdGenerator.common.info.hint',
    },
    {
      id: 'remittanceRecipientAccountId',
      fieldType: FilterTypes.select,
      valueType: 'string',
      required: true,
      dictionaryId: {
        name: 'remittanceRecipientAccount',
        params: {
          status: ['active', '-deactivated-'],
          havePaymentMethods: true,
        },
      },
      label: 'remittance.addPayout.field.recipAccID.label',
      placeholder: 'remittance.addPayout.field.recipAccID.placeholder',
      isSearchable: true,
    },
    [
      {
        id: 'amount',
        fieldType: FilterTypes.amount,
        valueType: 'string',
        required: true,
        label: 'remittance.addPayout.field.amount.label',
        placeholder: 'remittance.addPayout.field.amount.placeholder',
      },
      {
        id: 'currency',
        fieldType: FilterTypes.select,
        valueType: 'string',
        required: true,
        dictionaryId: 'currency',
        label: 'remittance.addPayout.field.currency.label',
        tooltip: 'select.currency.favorite.tooltip',
      },
    ],
  ],
  additional: [
    {
      id: 'description',
      fieldType: 'textarea',
      valueType: 'string',
      required: false,
      label: 'remittance.addPayout.field.description.label',
      placeholder: 'remittance.addPayout.field.description.placeholder',
      maxLength: 119,
    },
  ],
};
export default fieldsConfig;
