import React from 'react';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { BoardingStage } from 'types/BoardingStage';

import StatusBox from './components/StatusBox';

import './nonSddWidgets.scss';
import CommercialOfferWasAccepted from './components/DafStatusInformer/CommercialOfferWasAccepted';
import DafIsReady from './components/DafStatusInformer/DafIsReady';
import DocumentsWereSubmitted from './components/DafStatusInformer/DocumentsWereSubmitted';
import swytchImage from 'images/getStarted/swytch.png';
import Button from 'components/ui/button/Button';

type Props = IntlProps & { boardingStage: BoardingStage };

interface State {
  boardingStage: BoardingStage;
  isUserCommittedPaf: boolean;
}

class NonSddWidgets extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      boardingStage: this.props.boardingStage,
      isUserCommittedPaf: false,
    };
  }

  componentDidUpdate = (
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ) => {
    if (prevProps.boardingStage !== this.props.boardingStage) {
      this.setState({ boardingStage: this.props.boardingStage });
    }
  };

  onPafCommitted = () => {
    this.setState({ isUserCommittedPaf: true });
  };

  render() {
    return (
      <div className='non-sdd card grid-column-8 utils-flex'>
        {this.renderInner()}
      </div>
    );
  }

  renderInner = () => {
    const { getTranslate } = this.props;
    const { boardingStage } = this.state;

    if (this.state.isUserCommittedPaf) {
      return (
        <StatusBox
          icon='ok'
          title={getTranslate('getStarted.PAFsent.label')}
          actions={
            <Button
              id='gotIt'
              status='outline'
              text={getTranslate('getStarted.PAFsent.gotit.button')}
              onClick={() =>
                this.setState({
                  boardingStage: 'paf_review',
                  isUserCommittedPaf: false,
                })
              }
            />
          }>
          {getTranslate('getStarted.PAFsent.text')}
        </StatusBox>
      );
    }

    if (boardingStage === 'onboarding_draft') {
      return <CommercialOfferWasAccepted />;
    }
    if (boardingStage === 'onboarding_fill') {
      return <DafIsReady />;
    }
    if (boardingStage === 'onboarding_review') {
      return <DocumentsWereSubmitted />;
    }
    return null;
  };

  renderCustomerReviews = () => {
    return (
      <aside className='customer-review utils-align'>
        <div className='customer-review__inner'>
          <img
            src={swytchImage}
            alt='Swytch Technology logo'
            className='customer-review__logo'
          />
          <header className='customer-review__title'>Swytch Technology</header>
          <p className='customer-review__text'>
            ECOMMPAY have helped us revolutionize the buying experience for our
            customers
          </p>
        </div>
      </aside>
    );
  };
}

export default addTranslation(NonSddWidgets);
