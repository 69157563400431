import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip';
import queryString from 'query-string';

import {
  acceptChargebacks,
  cancelDispute,
  commitDispute,
  getChargebackInfo,
  getDisputeStatus,
  startDisputeChargeback,
} from 'api/chargebacks';
import {
  addEntityToSaved,
  reloadEntity,
  updateEntity,
  updateEntityParams,
} from 'actions/savedEntities';
import getEntityById from 'selectors/getPaymentById';
import ChargebackCard, { Dispute } from './ChargebackCard';
import checkFilters from 'helpers/checkFilters';
import MetricService from 'helpers/metricService/MetricService';
import path from 'helpers/path';
import savedEntities from 'constants/savedEntities';
import Messages from 'constants/rpcTypes';
import SavedEntity from 'types/savedEntity';
import { addListeners } from 'decorators/addListeners';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import showNotification from 'components/ui/notification/showNotification';
import { StoreProps } from 'store';
import { getCurrentDispute } from 'pages/chargebacks/card/helpers';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';

export const DISPUTE_STATUSES = {
  awaiting_form: 'awaiting_form',
  awaiting_delete: 'awaiting_delete',
  draft: 'draft',
  sent: 'sent',
  accepted: 'accepted',
  error: 'error',
  deleted: 'deleted',
};

interface OwnProps {
  id: string;
}

interface ConnectedProps {
  user: any;
  chargeback: any;
  storedSavedEntities: { isFetch: boolean; items: SavedEntity[] };
}

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  IntlProps &
  WithRouterProps;

interface State {
  isFormOpened: boolean;
  isLoading: boolean;
  isAcceptProcessing: boolean;
  firstChargeId: string;
  loadingText: string;
}

@addListeners([
  Messages.Chargeback_DisputeStart,
  Messages.Chargeback_DisputeUpdated,
  Messages.Chargeback_DisputeStatus,
  Messages.Chargeback_DisputeCommit,
  Messages.Chargeback_DisputeDelete,
  Messages.Chargeback_Accept,
  Messages.Maf_Error,
  Messages.Confirm_Reject,
  Messages.Confirm_Accept,
])
class ChargebackCardContainer extends Component<Props, State> {
  private disputeStatusIntervalId;
  private currentChargebackId;
  constructor(props: Props) {
    super(props);
    this.state = {
      isFormOpened: false,
      isLoading: false,
      isAcceptProcessing: false,
      firstChargeId: '',
      loadingText: '',
    };
    /* const { chargebackId, type } = queryString.parse(
      this.props.location.search
    );
    this.currentChargebackId = chargebackId;*/
  }

  componentDidMount() {
    checkFilters('chargebackCard');
    this.setChargebackId();

    if (!this.currentChargebackId) return;
    this.setState({ firstChargeId: this.currentChargebackId });
    this.init();
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    const { id, user, chargeback, storedSavedEntities } = this.props;
    if (!this.currentChargebackId) return;

    if (user && prevProps.user.timezone !== user.timezone) {
      await this.fetchInfo(this.currentChargebackId);
    }

    if (
      (id && prevProps.id && id !== prevProps.id) ||
      (this.props.location.search &&
        queryString.parse(this.props.location.search).type !== 'payment' &&
        prevProps.location.search !== this.props.location.search)
    ) {
      this.closeForm();
      this.setChargebackId();

      await this.fetchInfo(this.currentChargebackId);
    }

    if (prevProps.id !== this.props.id) {
      this.setState({ firstChargeId: this.currentChargebackId });
    }
    if (
      prevProps.id === this.props.id &&
      prevProps.location.search !== this.props.location.search
    ) {
      const { type } = queryString.parse(this.props.location.search);

      this.props.dispatch(
        updateEntityParams({
          entityKey: savedEntities.chargebacks,
          id,
          params: {
            urlParams: {
              chargebackId: this.currentChargebackId,
              type,
            },
          },
        })
      );
    }
    if (!prevProps.storedSavedEntities && storedSavedEntities) {
      this.setChargebackId();
      await this.init();
    }

    if (
      chargeback?.data?.status &&
      prevProps.chargeback?.data?.status !== chargeback?.data?.status
    ) {
      Tooltip.rebuild();
    }
  }

  render() {
    const { chargeback } = this.props;
    const { isFormOpened, isLoading } = this.state;

    const chargebackId = this.currentChargebackId;
    const currentDispute = this.getCurrentDispute();

    return (
      <ChargebackCard
        chargeback={chargeback}
        firstChargeId={this.state.firstChargeId}
        id={chargebackId}
        caseId={this.props.id}
        isFormOpened={isFormOpened}
        isFormEnabled={!!currentDispute}
        onOpenForm={this.openForm}
        isLoading={isLoading}
        isAcceptProcessing={this.state.isAcceptProcessing}
        loadingText={this.state.loadingText}
        mafData={
          currentDispute?.mafData &&
          JSON.parse(JSON.stringify(currentDispute?.mafData))
        }
        mafId={currentDispute?.mafId || ''}
        currentDispute={currentDispute}
        onAccept={(selectedIds) => this.acceptChargeback(selectedIds)}
        onStartDispute={(idsToSubmit) => this.onStartDispute(idsToSubmit)}
        onCancelDispute={this.cancelDispute}
        onDisputeCommit={this.onDisputeCommit}
        location={this.props.location}
        history={this.props.history}
        dispatch={this.props.dispatch}
      />
    );
  }

  async init() {
    const { id, chargeback, storedSavedEntities, dispatch } = this.props;

    if (!storedSavedEntities) return;

    if (!chargeback) {
      const noCaseId = this.props.id === 'null';
      if (noCaseId) {
        const { history } = this.props;
        const pathname = history.location.pathname;
        const nullIndex = pathname.indexOf('null');
        history.replace(
          path(
            `${pathname.slice(0, nullIndex)}${this.currentChargebackId}${
              history.location.search
            }`
          )
        );
      }
      const entityKey = savedEntities.chargebacks;
      dispatch(
        addEntityToSaved({
          entityKey,
          id: noCaseId ? this.currentChargebackId : id,
          hiddenParams: {
            id: noCaseId ? this.currentChargebackId : id,
          },
          urlParams: {
            chargebackId: this.currentChargebackId,
          },
        })
      );
    }

    if (!chargeback || (chargeback && !chargeback.data)) {
      await this.fetchInfo(this.currentChargebackId);
    }
  }

  async fetchInfo(id, isForceUpdate = false) {
    const { chargeback, dispatch } = this.props;

    if (chargeback?.[id] && !isForceUpdate) {
      const currentDispute = this.getCurrentDispute(chargeback?.[id].disputes);
      if (currentDispute) {
        this.openForm();
        this.checkDisputeStatus(currentDispute);
      }
      return;
    }

    try {
      this.setState({ isLoading: true });
      const data = await getChargebackInfo(id);
      const currentDispute = this.getCurrentDispute(data.disputes);

      if (currentDispute) {
        this.openForm();
      }

      const action = isForceUpdate ? reloadEntity : updateEntity;

      dispatch(
        action({
          entityKey: savedEntities.chargebacks,
          id: this.props.id,
          fields: {
            data: data.chargeback,
            disputes: data.disputes,
          },
        })
      );

      if (this.currentChargebackId && this.currentChargebackId !== id) {
        await this.fetchInfo(this.currentChargebackId);
      }
      this.checkDisputeStatus(currentDispute);
    } catch (error) {
      dispatch(
        updateEntity({
          entityKey: savedEntities.chargebacks,
          id: this.props.id,
          fields: {
            data: {
              error,
            },
          },
        })
      );
      this.setState({ isLoading: false, loadingText: '' });
    }
  }

  checkDisputeStatus = (currentDispute) => {
    const isLoading =
      currentDispute?.status === DISPUTE_STATUSES.awaiting_form ||
      currentDispute?.status === DISPUTE_STATUSES.awaiting_delete;
    this.setState({ isLoading });

    if (currentDispute?.status === DISPUTE_STATUSES.awaiting_delete) {
      this.setState({ loadingText: 'chargebackCard.cancelDisputing.text' });
    } else {
      this.setState({ loadingText: '' });
    }
  };

  setChargebackId = () => {
    const { chargebackId, type } = queryString.parse(
      this.props.location.search
    );
    if (type === 'payment') {
      this.currentChargebackId = undefined;
      return;
    }

    this.currentChargebackId = chargebackId;

    if (!chargebackId) {
      showNotification({
        status: 'error',
        content: this.props.getTranslate('notAvaliableActionChb.sysmsg'),
      });
    }
  };

  getCurrentDispute = (insetDisputes?): Dispute | undefined => {
    const { chargeback } = this.props;
    const disputes =
      insetDisputes || chargeback?.[this.currentChargebackId]?.disputes;
    if (!disputes) return undefined;
    const disputeArray: Dispute[] = Object.values(disputes);
    return getCurrentDispute(disputeArray);
  };

  onStartDispute = async (idsForSubmit) => {
    const { chargeback, getTranslate } = this.props;
    if (chargeback[this.currentChargebackId]?.data.dispute?.disputeId) {
      this.openForm();
      return;
    }

    try {
      this.setState({ isFormOpened: true, isLoading: true });

      await startDisputeChargeback(idsForSubmit);
    } catch (e) {
      if (e.payload.validationErrors?.chargebackId?.errorMessage) {
        showNotification({
          status: 'error',
          content: getTranslate(
            e.payload.validationErrors.chargebackId.errorMessage
          ),
        });
      } else {
        console.error('Error occurred while dispute start', e);
      }
      this.setState({ isLoading: false });
      this.closeForm();
    }
  };

  openForm = () => {
    this.setState({ isFormOpened: true });
  };

  closeForm = () => {
    this.setState({ isFormOpened: false });
  };

  onDisputeCommit = async (data) => {
    const { getTranslate } = this.props;

    try {
      this.setState({ isLoading: true });

      const dispute = this.getCurrentDispute();

      if (!dispute) {
        console.error('Error occurred while getting dispute');
      } else {
        const commit = await commitDispute({
          disputeId: Number(dispute.disputeId),
          mafData: data,
        });

        if (commit.hasChargebacksInWrongStatus) {
          showNotification({
            status: 'error',
            content: getTranslate('common.partiallySubmittedDispute.sysmsg'),
          });
        }
      }
    } catch (e) {
      console.error('Dispute commit error: ', e);
      if (e.payload?.validationErrors?.errorMessage) {
        showNotification({
          status: 'error',
          content: getTranslate(e.payload?.validationErrors.errorMessage),
        });
      }
      this.setState({ isLoading: false });
    }
  };

  cancelDispute = async () => {
    try {
      this.setState({
        isLoading: true,
        loadingText: 'chargebackCard.cancelDisputing.text',
      });
      const dispute = this.getCurrentDispute();

      if (!dispute) {
        console.error('Cannot find processing disputes for cancel');
        return;
      }

      const data = await cancelDispute(Number(dispute.disputeId));
      if (data.status === DISPUTE_STATUSES.deleted) {
        await this.fetchInfo(this.currentChargebackId, true);

        this.closeForm();
      }
    } catch (e) {
      console.error('Error occurred while cancel dispute', e);
    }
  };

  pingDisputeStatus = async () => {
    const dispute = this.getCurrentDispute();
    if (!dispute) {
      clearInterval(this.disputeStatusIntervalId);
      console.error(
        'Error occurred while getting processing dispute. Please, reload the page'
      );
      return;
    }
    clearInterval(this.disputeStatusIntervalId);
    this.disputeStatusIntervalId = setInterval(this.pingDisputeStatus, 5000);
    await getDisputeStatus(Number(dispute.disputeId));
  };

  async acceptChargeback(selectedIds) {
    if (isNotAvailableForSupport(Messages.Chargeback_Accept)) return;

    this.setState({ isAcceptProcessing: true });

    try {
      await acceptChargebacks(selectedIds);
    } catch (error) {
      this.setState({ isAcceptProcessing: false });
    }
  }

  /*openModal = () => {
    const { id, dispatch, getTranslate } = this.props;
    dispatch(
      openModal({
        modalId: 'Notification',
        callback: (yes) => {
          yes && this.fetchInfo(this.currentChargebackId, true);
        },
        content: {
          text: getTranslate('common.partiallySubmittedDispute.sysmsg'),
        },
      })
    );
  };*/

  sendMetric = () => {
    MetricService.send({
      action: 'click',
      actionKey: 'chargebacks.chargebackCard.accept.yes',
    });
  };

  onEvent = async ({ name, data }) => {
    const { dispatch, getTranslate } = this.props;
    switch (name) {
      case Messages.Chargeback_DisputeStart: {
        const { id } = this.props;
        if (data.payload.hasChargebacksInWrongStatus) {
          showNotification({
            status: 'error',
            content: getTranslate('common.partiallySubmittedDispute.sysmsg'),
          });
        } else if (data.payload.validationErrors?.chargebackId) {
          showNotification({
            status: 'error',
            content: getTranslate(data.payload.validationErrors.chargebackId),
          });
        }
        if (data.status !== 'success') return;

        if (data.payload.validationErrors) {
          this.setState({ isLoading: false });
        } else {
          dispatch(
            updateEntity({
              entityKey: savedEntities.chargebacks,
              id,
              fields: {
                data: {
                  chargebackId: this.state.firstChargeId,
                  disputeId: data.payload.dispute.disputeId,
                },
                disputes: {
                  [data.payload.dispute.disputeId]: { ...data.payload.dispute },
                },
              },
            })
          );
        }

        if (
          data.payload.status &&
          data.payload.status !== DISPUTE_STATUSES.awaiting_form
        ) {
          this.setState({ isLoading: false });
        }
        break;
      }
      case Messages.Chargeback_DisputeUpdated:
      case Messages.Chargeback_DisputeStatus: {
        if (
          data.payload.status === DISPUTE_STATUSES.awaiting_delete ||
          data.payload.status === DISPUTE_STATUSES.awaiting_form
        ) {
          this.setState({ isLoading: true });
          return;
        } else if (data.payload.status === DISPUTE_STATUSES.error) {
          await this.fetchInfo(this.currentChargebackId, true);
          this.closeForm();
          return;
        }

        if (!data.payload.mafData) {
          showNotification({
            status: 'error',
            content: 'Long answer. Please wait or come back later',
          });
          this.disputeStatusIntervalId = setInterval(
            this.pingDisputeStatus,
            5000
          );
        } else {
          clearInterval(this.disputeStatusIntervalId);
        }

        dispatch(
          updateEntity({
            entityKey: savedEntities.chargebacks,
            id: this.props.id,
            fields: {
              disputes: { [data.payload.disputeId]: data.payload },
              data: {
                chargebackId: this.currentChargebackId,
              },
            },
          })
        );

        if (
          data.payload.status === DISPUTE_STATUSES.accepted ||
          data.payload.status === DISPUTE_STATUSES.deleted
        ) {
          await this.fetchInfo(this.currentChargebackId, true);
          this.closeForm();
        }

        this.setState({ isLoading: false });
        break;
      }
      case Messages.Chargeback_DisputeCommit: {
        if (data.payload.status === DISPUTE_STATUSES.error) {
          showNotification({ status: 'error', content: data.payload.status });
        }
        break;
      }
      case Messages.Maf_Error: {
        this.setState({ isLoading: false });
        break;
      }
      case Messages.Chargeback_Accept: {
        if (!data.rpc.status) return;

        this.setState({
          isAcceptProcessing: false,
        });
        this.sendMetric();
        this.fetchInfo(this.currentChargebackId, true);
        break;
      }
      case Messages.Confirm_Reject: {
        if (data.payload.unblockedTypes.includes(Messages.Chargeback_Accept)) {
          if (!data.rpc.status) return;
          this.setState({
            isAcceptProcessing: false,
          });
        }
      }
    }
  };
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => ({
  user: state.user,
  chargeback: getEntityById(state, savedEntities.chargebacks, ownProps.id),
  storedSavedEntities: state.savedEntities[savedEntities.chargebacks],
});

export default withRouter(
  connect(mapStateToProps)(addTranslation(ChargebackCardContainer))
);
