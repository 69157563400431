import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import Tooltip from 'react-tooltip';
import { isEmpty } from 'lodash';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { addListeners, IListeners } from 'decorators/addListeners';
import { WithRouterProps } from 'decorators/withRouter';
import { StoreProps } from 'store';

import api from 'api/projects';
import { openModal } from 'actions/modal';
import { confirmLeavePage } from 'actions/app';
import {
  resetProjects,
  setProject,
  updateProject,
  updateProjectField,
} from 'actions/projects';
import Projects from './Projects';
import Messages from 'constants/rpcTypes';
import { sortProjectsById } from 'selectors/sortProjectsById';
import MetricService from 'helpers/metricService/MetricService';
import checkFilters from 'helpers/checkFilters';
import { Dictionary } from 'types/FilterValue';
import { ProjectGeneral } from './types';
import { AnyObject } from 'types/Common';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import { Value } from 'components/ui/customSelect/CustomSelect';
import path from 'helpers/path';
import getNameWithId from 'helpers/getNameWithId';

interface OwnProps {
  editPaymentMethods: boolean;
  paymentMethods: AnyObject;
  isStageUser: boolean;
}

interface ConnectedProps {
  projectList: Value[];
  settingHistoryAction: Dictionary;
  availableStatuses: Dictionary;
  availableChannels: Dictionary;
  availableOptions: Dictionary;
  isConfirmLeavePage: boolean;
  modal: AnyObject;

  project: ProjectGeneral;
  isPaymentPageEditorEnabled: boolean;
  paymentMethodsStatuses: any[];
  oldData: AnyObject;
  isLoading: boolean;
  canSaveRedirectSettings: boolean;
  canSavePaymentMethods: boolean;
  canSaveSalt: string;
  isLoginAsSupport: boolean;

  validationErrors: AnyObject;
}

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  IntlProps &
  WithPermissions &
  WithRouterProps<{ id?: string }>;

interface State {
  currentTabIndex: number;
}

export const TABS = [
  {
    id: 'general',
    path: '',
  },
  {
    id: 'paymentMethods',
    path: 'payment-methods',
  },
  {
    id: 'callbacks',
    path: 'callbacks',
  },
  {
    id: 'redirectUrls',
    path: 'redirect-urls',
  },
  {
    id: 'ppDesigner',
    path: 'pp-designer',
  },
];

@addListeners([
  Messages.Project_Get,
  Messages.Confirm_Reject,
  Messages.Confirm_Accept,
  Messages.Project_ChangePaymentMethodStatus,
])
class ProjectsContainer extends Component<Props, State> implements IListeners {
  constructor(props) {
    super(props);
    this.state = {
      currentTabIndex: 0,
    };
  }

  async componentDidMount() {
    checkFilters('projects');
    const id = this.props.projectList[0]?.value;
    await this.fetch(id);
    this.setActiveTab(this.props.match.params.id);
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ) {
    if (
      this.props.canSavePaymentMethods !== prevProps.canSavePaymentMethods ||
      this.props.canSaveRedirectSettings !==
        prevProps.canSaveRedirectSettings ||
      this.props.canSaveSalt !== prevProps.canSaveSalt
    ) {
      this.preventLeavePage();
    }

    if (
      !prevProps.availableChannels?.isFetched &&
      this.props.availableChannels?.isFetched
    ) {
      const paymentMethodsStatuses = this.getPaymentMethodsStatuses(
        this.props.paymentMethodsStatuses
      );
      this.props.dispatch(
        updateProject({
          paymentMethodsStatuses,
          oldData: { ...this.props.oldData, paymentMethodsStatuses },
        })
      );
    }

    if (this.props.project.projectId !== prevProps.project.projectId) {
      this.setActiveTab(this.props.match.params.id);
    }

    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setActiveTab(this.props.match.params.id);
    }

    if (prevState.currentTabIndex !== this.state.currentTabIndex) {
      this.changePath(this.state.currentTabIndex);
    }
  }

  render() {
    const {
      projectList,
      editPaymentMethods,
      isConfirmLeavePage,
      project,
      isPaymentPageEditorEnabled,
      paymentMethods,
      isLoading,
      isLoginAsSupport,
      validationErrors,
    } = this.props;

    return (
      <>
        <Projects
          currentTabIndex={this.state.currentTabIndex}
          projectList={projectList}
          projectGeneral={project}
          isPaymentPageEditorEnabled={isPaymentPageEditorEnabled}
          paymentMethods={paymentMethods}
          isLoading={isLoading}
          editPaymentMethods={editPaymentMethods}
          isLoginAsSupport={isLoginAsSupport}
          validationErrors={validationErrors}
          setActiveProject={(id) => this.getHandler(id)}
          onChangeTab={this.onChangeTab}
        />
        <Prompt when={isConfirmLeavePage} message={this.handleNavigation} />
      </>
    );
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetProjects());
  }

  getHandler = (id) => {
    const { isConfirmLeavePage } = this.props;
    return isConfirmLeavePage
      ? this.showConfirmModal(this.handleProjectNavigate, id)
      : this.fetch(id);
  };

  async fetch(id) {
    const { dispatch } = this.props;

    dispatch(updateProjectField('isLoading', true));
    try {
      await api.getProjects(id);
    } finally {
      dispatch(updateProjectField('isLoading', false));
    }
  }

  preventLeavePage = (answer?) => {
    const {
      canSaveRedirectSettings,
      canSavePaymentMethods,
      canSaveSalt,
      dispatch,
    } = this.props;
    dispatch(
      confirmLeavePage(
        answer
          ? answer
          : {
              isConfirm:
                canSaveRedirectSettings ||
                canSavePaymentMethods ||
                !!canSaveSalt,
            }
      )
    );
  };

  handleNavigation = (location) => {
    if (location.pathname === '/login') return true;
    if (this.props.isConfirmLeavePage) {
      this.showConfirmModal(this.handlePageNavigation, location);
      return false;
    }
    return true;
  };

  showConfirmModal(callback, option?) {
    if (typeof option === 'string' && option === this.props.project.projectId)
      return;
    const { dispatch } = this.props;

    dispatch(
      openModal({
        modalId: 'Confirm',
        content: {
          title: 'projects.urls.modal.urlChangeDiscard.header',
          text: 'projects.urls.modal.urlChangeDiscard.label',
        },
        callback: (answer) => {
          this.preventLeavePage({ isConfirm: !answer });
          callback(answer, option);
        },
      })
    );
  }

  handleProjectNavigate = (answer, id) => (answer ? this.fetch(id) : false);

  handlePageNavigation = (answer, locate?) => {
    const { history } = this.props;
    if (answer) {
      locate && history.push(locate.pathname);
      this.revertChanges();
    }
  };

  getPaymentMethodsStatuses = (data) => {
    if (!data.length) return;
    const { availableOptions, availableChannels } = this.props;

    const getPaymentMethod = (channelId) => {
      return data.find((item) => item.name === channelId);
    };

    return availableChannels?.list?.map((channel) => {
      const paymentMethod = getPaymentMethod(channel.id);
      return {
        id: channel.id,
        text: channel.text,
        items: channel.channels.map((paymentChannel) => {
          const paymentMethodChannel = paymentMethod[paymentChannel];
          return {
            id: paymentChannel,
            text: availableOptions?.list?.find((type) => {
              return type.id === paymentChannel;
            })?.text,
            isSelected: paymentMethodChannel.isEnabled,
            isDisabled: !paymentMethodChannel.isChangeable,
            tooltip: this.getTooltip(paymentMethodChannel, paymentChannel),
          };
        }),
      };
    });
  };

  getInfo = (data) => {
    if (!data) return '';
    return Object.keys(data).reduce<string>((result, item): string => {
      if (data[item] && typeof data[item] === 'object') {
        return `${result}\n${item}: { ${this.getInfo(data[item])} }`;
      }
      return `${result}\n${item}: ${data[item]}`;
    }, '');
  };

  getTooltip = (paymentMethodChannel, paymentChannel) => {
    if (
      paymentMethodChannel.status === 'active' ||
      paymentMethodChannel.status === 'inactive'
    )
      return '';
    const { availableStatuses, getTranslate } = this.props;
    const tooltip = availableStatuses?.list?.find((channel) => {
      return channel.id === paymentMethodChannel.status;
    })?.text[paymentChannel];
    return getTranslate(tooltip) + this.getInfo(paymentMethodChannel.info);
  };

  updatePaymentMethodStatuses = (data) => {
    if (!data) return;
    const { paymentMethodsStatuses, oldData, validationErrors, dispatch } =
      this.props;

    const updatedData = paymentMethodsStatuses.map((oldItem) => {
      let newItems = null;
      data.methods?.find((item) => {
        if (item.method === oldItem.id) {
          newItems = oldItem.items.map((oldChannel) => {
            const newChannel = item.channels[oldChannel.id];
            if (!newChannel) return oldChannel;
            return {
              id: oldChannel.id,
              isSelected: newChannel.isEnabled,
              isDisabled: !newChannel.isChangeable,
              text: oldChannel.text,
              tooltip: this.getTooltip(newChannel, oldChannel.id),
            };
          });
        }
        return null;
      });
      return {
        ...oldItem,
        items: newItems || oldItem.items,
      };
    });

    dispatch(
      updateProject({
        paymentMethodsStatuses: updatedData,
        oldData: { ...oldData, paymentMethodsStatuses: updatedData },
        validationErrors: { ...validationErrors, paymentMethodsStatuses: {} },
      })
    );

    Tooltip.rebuild();
  };

  getCurrentTabIndex = (pathId?) => {
    if (!pathId) return 0;
    return TABS.findIndex(
      (tab) => tab.path === (pathId || this.props.match.params.id)
    );
  };

  setActiveTab = (pathId) => {
    const { isEnabled, isPaymentPageEditorEnabled } = this.props;
    const tabByPath = TABS.find((tab) => tab.path === pathId);

    if (pathId === 'callbacks') {
      if (isEnabled(Messages.ProjectCallbackSettings_List)) {
        this.setState({
          currentTabIndex: this.getCurrentTabIndex(tabByPath?.path) + 1,
        });
      } else {
        this.setState({ currentTabIndex: 1 });
      }
    } else if (pathId === 'pp-designer') {
      if (isPaymentPageEditorEnabled) {
        this.setState({
          currentTabIndex: this.getCurrentTabIndex(tabByPath?.path) + 1,
        });
      } else {
        this.setState({ currentTabIndex: 1 });
      }
    } else {
      const index = this.getCurrentTabIndex(tabByPath?.path);
      this.setState({
        currentTabIndex: index >= 0 ? index + 1 : 1,
      });
    }
  };

  changePath = (index) => {
    if (
      this.props.isConfirmLeavePage ||
      TABS[index - 1].path === this.props.match.params.id
    )
      return;
    if (index === 1) {
      if (!this.props.match.params.id) return;
      this.props.history.push(path('/projects'));
    } else {
      this.props.history.push(path(`/projects/${TABS[index - 1].path}`));
    }
  };

  onChangeTab = (tabIndex, tabId?) => {
    if (tabId === 'constructor') {
      MetricService.send({
        action: 'click',
        actionKey: 'projects.ppDesigner.subsection',
      });
    }
    this.handleCloseForm((answer) => {
      if (!answer) return;
      this.setActiveTab(TABS[tabIndex - 1].path);
      this.handlePageNavigation(answer);
    });
  };

  handleCloseForm = (handler) => {
    const { isConfirmLeavePage } = this.props;

    if (isConfirmLeavePage) {
      return this.showConfirmModal((answer) => {
        setTimeout(() => handler(answer));
      });
    }
    handler(true);
  };

  revertChanges = () => {
    this.props.dispatch(
      updateProject({
        canSaveRedirectSettings: false,
        canSavePaymentMethods: false,
        canSaveSalt: false,
        salt: '',
        ...this.props.oldData.redirectSettings,
        ...this.props.oldData.paymentStatuses,
        validationErrors: {},
      })
    );
  };

  onEvent = ({ name, data: response }) => {
    const data = response.payload;
    const { dispatch } = this.props;
    if (name === Messages.Project_Get) {
      if (!data || isEmpty(data)) {
        return;
      }

      const paymentMethodsStatuses = this.getPaymentMethodsStatuses(
        data.paymentMethodsStatuses
      );

      dispatch(
        setProject({
          project: { ...data.project },
          isApmHighRiskVisible: data.isApmHighRiskVisible,
          isApmLowRiskVisible: data.isApmLowRiskVisible,
          eShopLink: data.eShopLink,
          isPaymentPageEditorEnabled: data.isPaymentPageEditorEnabled,
          paymentPageEditorUrl: data.paymentPageEditorUrl,
          paymentMethods: data.paymentMethods,
          paymentMethodsStatuses:
            paymentMethodsStatuses || data.paymentMethodsStatuses,
          ...data.redirectSettings,
          oldData: {
            redirectSettings: data.redirectSettings,
            paymentMethodsStatuses:
              paymentMethodsStatuses || data.paymentMethodsStatuses,
          },
          isEditable: data.isEditable,
          canSavePaymentMethods: false,
          canSaveRedirectSettings: false,
          canSaveSalt: '',
        })
      );
    } else if (name === Messages.Project_ChangePaymentMethodStatus) {
      dispatch(updateProjectField('isLoading', false));
      this.updatePaymentMethodStatuses(response.payload);
      MetricService.send({
        action: 'click',
        actionKey: 'projects.paymentMethods.save',
      });
    }
  };
}

const mapStateToProps = (state): ConnectedProps => {
  const { projects, filtersValues } = state;

  return {
    availableChannels: filtersValues?.paymentMethodChannels,
    availableStatuses: filtersValues?.paymentMethodStatuses,
    availableOptions: filtersValues?.paymentMethodOptions,
    settingHistoryAction: filtersValues?.settingHistoryAction,
    projectList: getCustomSelectItems({
      list: sortProjectsById(state),
      valueKey: 'projectId',
      getLabel: ({ name, projectId }) => getNameWithId(name, projectId),
    }),
    modal: state.modal,
    isConfirmLeavePage: state.app.isConfirm,
    project: projects.project,
    isPaymentPageEditorEnabled: projects.isPaymentPageEditorEnabled,
    paymentMethodsStatuses: projects.paymentMethodsStatuses,
    oldData: projects.oldData,
    canSaveRedirectSettings: projects.canSaveRedirectSettings,
    canSavePaymentMethods: projects.canSavePaymentMethods,
    canSaveSalt: projects.canSaveSalt,
    isLoading: projects.isLoading,
    validationErrors: projects.validationErrors,
    isLoginAsSupport: state.user.isLoginAsSupport,
  };
};

export default withRouter(
  connect(mapStateToProps)(addTranslation(addPermissions(ProjectsContainer)))
);
