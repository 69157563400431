import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';

import GuideIcon from 'components/ui/guideIcon';
import Button from 'components/ui/button';
import landingState from 'constants/landingState';
import path from 'helpers/path';
import urlsMap from 'constants/urlsMap';

interface OwnProps {
  guideInfo: string;
  customClass?: string;
}

interface ConnectedProps {
  isDemoStage: boolean;
}

type Props = OwnProps & ConnectedProps & IntlProps & WithRouterProps;

const RequestButton: React.VFC<Props> = ({
  history,
  guideInfo,
  getTranslate,
  isDemoStage,
  customClass,
}) => (
  <>
    <Button
      status='primary'
      onClick={() => history.push(path(`/${urlsMap.payouts}/request/`))}
      text={getTranslate('payouts.requestButton')}
      customClass={customClass}
    />
    {guideInfo && isDemoStage && <GuideIcon tooltip={guideInfo} />}
  </>
);

const mapStateToProps = (state): ConnectedProps => ({
  isDemoStage: state.user.landingState === landingState.demoStage,
});

export default withRouter(
  connect(mapStateToProps)(addTranslation(RequestButton))
);
