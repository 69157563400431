import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Form from 'components/ui/form';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import InputPassword from 'components/ui/inputPassword/InputPassword';
import Loader from 'components/ui/loader';
import { AnyObject } from 'types/Common';
import './changePassword.scss';

interface Props extends IntlProps {
  user: any;
  isChanging: boolean;
  validationErrors: AnyObject;
  onChangeField: () => void;
  onChangePassword: (data) => void;
}

interface State {
  oldPassword: string;
  newPassword: string;
  newPassword2: string;
  twoFactorCode: string;
}

class ChangePassword extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      twoFactorCode: '',
    };
  }

  render() {
    const { user, isChanging, validationErrors, getTranslate } = this.props;
    const { oldPassword, newPassword, newPassword2, twoFactorCode } =
      this.state;

    return (
      <div className='profile-change-password'>
        <Form
          onSubmit={this.sendNewPassword}
          customClass='profile-change-password__form'>
          <div className='profile-change-password__group'>
            <div className='profile-change-password__text'>
              {getTranslate('profile.security.passwordInfo.text')}
            </div>
            <InputPassword
              autoFocus
              id='old-password'
              value={oldPassword}
              placeholder='profile.changepass.oldPass.placeholder'
              error={validationErrors.oldPassword}
              label={getTranslate('profile.changepass.oldPass.label')}
              customClass='profile-change-password__input'
              onChange={({ target }) =>
                this.changeField('oldPassword', target.value)
              }
              modern
            />
            <InputPassword
              id='new-password'
              label={getTranslate('profile.changepass.newPass.label')}
              value={newPassword}
              placeholder='profile.changepass.newPass.placeholder'
              error={validationErrors.newPassword}
              customClass={`profile-change-password__input ${
                validationErrors.newPassword
                  ? 'profile-change-password__input_error'
                  : ''
              }`}
              onChange={({ target }) =>
                this.changeField('newPassword', target.value)
              }
              modern
            />
            <InputPassword
              id='new-password-2'
              label={getTranslate('profile.changepass.confirmPass.label')}
              value={newPassword2}
              placeholder='profile.changepass.confirmPass.placeholder'
              error={validationErrors.newPassword2}
              customClass='profile-change-password__input'
              onChange={({ target }) =>
                this.changeField('newPassword2', target.value)
              }
              modern
            />
          </div>
          {Boolean(user.twoFactorAuth) && (
            <div className='profile-change-password__group'>
              <Input
                id='two-factor-code'
                label={getTranslate('profile.twoFA.verificationCode')}
                value={twoFactorCode}
                placeholder='profile.twoFA.verificationCode.placeholder'
                cleaveOptions={{
                  numericOnly: true,
                  blocks: [3, 3],
                  delimiters: [' '],
                }}
                error={validationErrors.otp}
                customClass='profile-change-password__input'
                onChange={({ target }) =>
                  this.changeField('twoFactorCode', target.value)
                }
                modern
              />
            </div>
          )}
          <div className='profile-change-password__footer'>
            <Button
              text={getTranslate('common.confirm.button')}
              type='button'
              status='primary'
              size='normal'
              customClass='ui-button_full-width'
              onClick={this.sendNewPassword}
              disabled={!this.isEqualsPasswords() || !this.isAllFilled()}
            />
          </div>
        </Form>
        {isChanging && <Loader />}
      </div>
    );
  }

  changeField = (key: keyof State, value: string): void => {
    const { onChangeField } = this.props;
    this.setState({
      [key]: value,
    } as Pick<State, keyof State>);
    onChangeField();
  };

  sendNewPassword = (): void => {
    const { onChangePassword } = this.props;
    const { twoFactorCode } = this.state;

    if (this.canSend()) {
      onChangePassword({
        ...this.state,
        twoFactorCode: twoFactorCode.replace(/\s/g, ''),
      });
    }
  };

  canSend = (): boolean => {
    const { isChanging } = this.props;
    return !isChanging && this.isAllFilled() && this.isEqualsPasswords();
  };

  isAllFilled = (): boolean => {
    const { user } = this.props;
    const { oldPassword, newPassword, newPassword2, twoFactorCode } =
      this.state;
    if (user.twoFactorAuth && !twoFactorCode) {
      return false;
    }
    return Boolean(oldPassword && newPassword && newPassword2);
  };

  isEqualsPasswords = (): boolean => {
    const { newPassword, newPassword2 } = this.state;
    return newPassword === newPassword2;
  };
}

export default addTranslation(ChangePassword);
