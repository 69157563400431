import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 *
 * @param params
 */
export const getNotifications = (params: {}) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserNotification_List,
        payload: {
          ...params,
        },
      },
    ],
  });
};

/**
 *
 */
export const getNotificationsCounters = () => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.UserNotification_Counters,
          payload: {},
        },
      ],
    })
    .then((data) => data.counters);
};

/**
 *
 */
export const deleteNotification = (id: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserNotification_Delete,
        payload: { id },
      },
    ],
  });
};

/**
 *
 */
export const deleteAllNotifications = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserNotification_DeleteAll,
        payload: {},
      },
    ],
  });
};

/**
 *
 * @param id
 */
export const markAsReadNotification = (id: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserNotification_MarkAsRead,
        payload: { id },
      },
    ],
  });
};

/**
 *
 */
export const getNotificationsSettings = () => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.UserNotificationSetting_List,
          payload: {},
        },
      ],
    })
    .then((data) => data.list);
};

/**
 *
 * @param list
 */
export const saveNotificationsSettings = (list) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserNotificationSetting_Save,
        payload: { list },
      },
    ],
  });
};
