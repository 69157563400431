import React, { PureComponent, ReactNode } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import ModalTitle from '../components/ModalTitle';
import ModalContent from '../components/ModalContent';

interface Props extends IntlProps {
  content: {
    title?: string;
    text: ReactNode;
  };
}

class ChargebackTimeline extends PureComponent<Props> {
  render() {
    const {
      getTranslate,
      content: { title, text },
    } = this.props;

    return (
      <>
        <ModalTitle customClass=''>{getTranslate(title)}</ModalTitle>
        <ModalContent customClass=''>{text}</ModalContent>
      </>
    );
  }
}

export default addTranslation(ChargebackTimeline);
