import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import ModalTitle from 'components/modal/components/ModalTitle';
import ModalContent from 'components/modal/components/ModalContent';
import ModalFooter from 'components/modal/components/ModalFooter';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import Form from 'components/ui/form';
import { AnyObject } from 'types/Common';
import './confirmTelegram.scss';

interface Props extends IntlProps {
  isLoading: boolean;
  validationErrors: AnyObject;
  onSubmit: (code: string) => void;
}

interface State {
  publicKey: string;
}

class ConfirmTelegram extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      publicKey: '',
    };
  }

  render() {
    const { validationErrors, getTranslate } = this.props;
    const { publicKey } = this.state;

    return (
      <div className='confirm-telegram'>
        <ModalTitle>{getTranslate('profile.modal.bot.header')}</ModalTitle>
        <ModalContent>
          <Form onSubmit={this.sendForm}>
            <Input
              autoFocus
              id='tgm-verify-input'
              label={getTranslate('profile.modal.bot.text')}
              value={publicKey}
              error={validationErrors.publicKey}
              maxLength={32}
              placeholder={getTranslate('profile.modal.bot.placeholder')}
              cleaveOptions={{ numericOnly: true }}
              onChange={({ target: { value } }) =>
                this.setState({ publicKey: value })
              }
              modern
            />
          </Form>
        </ModalContent>
        <ModalFooter>
          <Button
            text={getTranslate('modals.common.buttons.confirm')}
            status='primary'
            size='large'
            customClass='ui-button_full-width'
            onClick={this.sendForm}
            disabled={!this.canSendForm()}
          />
        </ModalFooter>
      </div>
    );
  }

  canSendForm = () => {
    return this.state.publicKey.length > 0;
  };

  sendForm = () => {
    const { onSubmit } = this.props;
    const { publicKey } = this.state;
    if (this.canSendForm()) {
      onSubmit(publicKey);
    }
  };
}

export default addTranslation(ConfirmTelegram);
