import React from 'react';
import DateHelpers from 'helpers/Date';
import InputDate from 'components/ui/inputDate';
import dateFormats from 'constants/dateFormats';
import { MafField, MafTab } from '../../MafTypes';

interface Props {
  field: MafField;
  form: MafTab;
  error: string;
  isRequired: boolean;
  isDisabled: boolean;
  modern?: boolean;
  onChangeField: (formId: string, key: string, value: any) => void;
}

interface State extends DateRange {}

interface DateRange {
  minDate: string;
  maxDate: string;
}

const MIN_DATE_YEARS = 120;
const MAX_DATE_YEARS = 100;

type dateFormatType = keyof typeof dateFormats;

class MafDateInput extends React.PureComponent<Props, State> {
  datePickerPanelRef;
  constructor(props) {
    super(props);

    this.state = this.getDateRange();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.field.name !== this.props.field.name) {
      this.setState(this.getDateRange());
    }
  }

  render() {
    const { field, isRequired, isDisabled, error, form } = this.props;

    return (
      <div className='maf-field__input-date'>
        <InputDate
          getRef={(ref) => {
            this.datePickerPanelRef = ref;
          }}
          tooltip={field.notes}
          selectedDate={this.getDate(field.value, 'crmDate')}
          error={error}
          initialDate={this.initialDate}
          isRequired={isRequired}
          title={field.caption}
          modern={this.props.modern}
          withCloseButton={false}
          minDate={this.state.minDate}
          maxDate={this.state.maxDate}
          withTime={false}
          isDisabled={isDisabled}
          onChange={(date) => {
            if (!isDisabled) {
              this.changeDate(form, field, date);
            }
          }}
          isUnselectDate={false}
          scrollIntoView={false}
          closeAfterSelect
        />
      </div>
    );
  }

  get initialDate() {
    const { field } = this.props;

    if (!field.config) {
      return DateHelpers.getFormat(DateHelpers.getDate(), 'datetime');
    }

    const now = () => DateHelpers.getDate();
    const minDate = DateHelpers.createDate(
      field.config.min_date,
      'crmDateShort'
    );

    const maxDate = DateHelpers.createDate(
      field.config.max_date,
      'crmDateShort'
    );

    const diffDaysMinDate = now().diff(minDate, 'days');
    const diffDaysMaxDate = now().diff(maxDate, 'days');

    if (diffDaysMinDate > 0 && diffDaysMaxDate < 0) {
      return DateHelpers.getFormat(now(), 'datetime');
    }

    const closestDate =
      Math.abs(diffDaysMaxDate) > Math.abs(diffDaysMinDate) ? minDate : maxDate;

    return DateHelpers.getFormat(closestDate, 'datetime');
  }

  getDateRange = (): DateRange => {
    const { field } = this.props;

    const result = {} as DateRange;
    if (field.config?.min_date) {
      result.minDate = this.getDate(field.config.min_date, 'crmDateShort');
    } else {
      result.minDate = DateHelpers.getFormat(
        DateHelpers.subtractFrom(
          DateHelpers.getDate(),
          MIN_DATE_YEARS,
          'years'
        ).startOf('year'),
        'datetime'
      );
    }

    if (field.config?.max_date) {
      result.maxDate = this.getDate(field.config.max_date, 'crmDateShort');
    } else {
      result.maxDate = DateHelpers.getFormat(
        DateHelpers.addTo(
          DateHelpers.getDate(),
          MAX_DATE_YEARS,
          'years'
        ).startOf('year'),
        'datetime'
      );
    }
    return result;
  };

  getDate = (crmDate: string | undefined, format: dateFormatType): string => {
    if (!crmDate) return '';
    return DateHelpers.getFormat(
      DateHelpers.createDate(crmDate, format),
      'datetime'
    );
  };

  changeDate = (form: MafTab, field: MafField, newDate: string) => {
    const { onChangeField } = this.props;
    let value = '';
    if (newDate) {
      value = DateHelpers.getFormat(
        DateHelpers.createDate(newDate, 'datetime'),
        'crmDate'
      );
    }
    onChangeField(form.name, field.name, {
      value,
    });
  };
}

export default MafDateInput;
