import React, { useCallback } from 'react';
import { connect, InferableComponentEnhancer } from 'react-redux';

import { RootState } from 'store';
import RpcTypes from 'constants/rpcTypes';

export interface WithPermissions {
  isEnabled: (name: RpcTypes) => boolean;
  isSomeEnabled: (name: Array<RpcTypes>) => boolean;
  isDisabledByReason: (name: RpcTypes, reason: string) => boolean;
}

export const addPermissions: InferableComponentEnhancer<WithPermissions> = (
  WrapperComponent
) => {
  const AddPermissionsDecorator: React.FC<{ permissionsList: any }> = ({
    permissionsList,
    ...props
  }) => {
    const isEnabled = useCallback<WithPermissions['isEnabled']>(
      (name) => permissionsList[name]?.status === 'enabled',
      [permissionsList]
    );

    const isSomeEnabled = useCallback<WithPermissions['isSomeEnabled']>(
      (list) => list.some((name) => isEnabled(name)),
      [isEnabled]
    );

    const isDisabledByReason = useCallback<
      WithPermissions['isDisabledByReason']
    >(
      (name, reason) => {
        const permission = permissionsList[name];
        if (!permission) return false;
        return permission.status === 'disabled' && permission.reason === reason;
      },
      [permissionsList]
    );

    return (
      <WrapperComponent
        {...(props as any)}
        isEnabled={isEnabled}
        isSomeEnabled={isSomeEnabled}
        isDisabledByReason={isDisabledByReason}
      />
    );
  };

  return connect((state: RootState) => {
    return {
      permissionsList: state.permissions,
    };
  })(AddPermissionsDecorator) as any;
};
