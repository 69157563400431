import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import Tooltip from 'react-tooltip';

import { createRemittancePayment } from 'api/remittance';
import { addEntityToSaved } from 'actions/savedEntities';
import { confirmLeavePage } from 'actions/app';
import { openModal } from 'actions/modal';
import { WithRouterProps } from 'decorators/withRouter';
import { addListeners } from 'decorators/addListeners';
import FormFields from 'components/formFields/FormFields';

import Utils from 'helpers/Utils';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import formStateFactory from 'components/formFields/formStateFactory';
import { getRequiredFields } from 'components/formFields/helpers';
import SubmittedForm from 'components/formFields/components/submittedForm';
import path from 'helpers/path';
import savedEntities from 'constants/savedEntities';
import Messages from 'constants/rpcTypes';
import urlsMap from 'constants/urlsMap';
import { AnyObject } from 'types/Common';
import fieldsConfig from '../../fieldsConfig';
import { StoreProps } from 'store';
import { createRoutes } from '../../../routesMapper';
import tableNames from 'constants/tableNames';

interface OwnProps {
  id?: string;
}

interface ConnectedProps {
  isConfirmLeavePage: boolean;
  storedSavedEntities: any;
  modal: any;
  availableAccountIds: any;
  filtersValues: any;
  filtersValuesCustom: any;
  isPaymentIdGeneratorEnabled: boolean;
}

type Props = OwnProps & ConnectedProps & StoreProps & WithRouterProps;

interface State {
  canSave: boolean;
  isLoading: boolean;
  isConfirm: boolean;
  statusText: string;
  fields: any;
  validationErrors: AnyObject;
  fieldsState: { currency: boolean };
}

@addListeners([
  Messages.RemittanceQueue_StatusUpdated,
  Messages.Remittance_CreatePayment,
])
class RemittancePaymentSingle extends PureComponent<
  Props & ConnectedProps & StoreProps,
  State
> {
  private pathToGo;
  private requiredFields = getRequiredFields(fieldsConfig);

  constructor(props) {
    super(props);
    this.state = {
      fields: this.getInitialFields(),
      fieldsState: {
        currency: false,
      },
      statusText: '',
      canSave: false,
      validationErrors: {},
      isLoading: false,
      isConfirm: false,
    };
  }

  async componentDidMount() {
    this.setSavedEntities();
  }

  componentDidUpdate(
    prevProps: Props & ConnectedProps & StoreProps,
    prevState: Readonly<State>
  ) {
    if (
      prevProps.isConfirmLeavePage &&
      !this.props.isConfirmLeavePage &&
      this.props.modal.modalId
    ) {
      this.handlePageNavigation(this.pathToGo);
    }
    if (!prevProps.storedSavedEntities && this.props.storedSavedEntities) {
      this.setSavedEntities();
    }
    if (prevProps.modal.isOpened && !this.props.modal.isOpened) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isConfirmLeavePage } = this.props;
    const { validationErrors, statusText } = this.state;

    if (statusText) {
      return (
        <SubmittedForm
          id='remittance'
          text={statusText}
          onPrimaryButtonClick={this.cleanForm}
          primaryButtonText='remittance.addRecipientAcc.newRemittance.button'
          secondaryButtonText='remittance.addPayout.back.button'
          onSecondaryButtonLink={path(`/${urlsMap.remittance}`)}
          status='success'
        />
      );
    }

    return (
      <>
        <FormFields
          id='remittance'
          text='remittance.addPayout.infoText'
          generalTitle='remittance.addPayout.generalInfo.label'
          additionalTitle='remittance.addPayout.additionalInfo.label'
          onChange={this.onChange}
          onSubmit={() => this.save()}
          onReset={this.cleanForm}
          isLoading={this.state.isLoading}
          canCreate={this.state.canSave}
          fieldsValues={this.state.fields}
          dictionaries={{
            ...this.props.filtersValues,
            ...this.props.filtersValuesCustom,
          }}
          fieldsConfig={fieldsConfig}
          fieldsState={this.state.fieldsState}
          validationErrors={validationErrors}
          isSubmitted={!!statusText}
          submittedTitle='remittance.addPayout.sendRequestSuccess.header'
          submittedText={statusText}
          backUrl={path(`/${urlsMap.remittance}`)}
          backText='remittance.addPayout.back.button'
          createButtonText='remittance.addPayout.sendRequest.button'
          repeatCreateButtonText='remittance.addRecipientAcc.newRemittance.button'
        />

        <Prompt when={isConfirmLeavePage} message={this.handleNavigation} />
      </>
    );
  }

  setSavedEntities = () => {
    const { id, dispatch, storedSavedEntities } = this.props;

    if (
      !storedSavedEntities?.isFetch ||
      storedSavedEntities.items.some((entity) => {
        return String(entity.id) === id;
      })
    )
      return;

    let savedEntityParams;
    const createUrl = createRoutes[tableNames.remittancePayments];

    if (!id?.includes(createUrl)) {
      savedEntityParams = {
        entityKey: savedEntities.remittance,
        id,
      };
    } else {
      if (storedSavedEntities.items.some((entity) => entity.id === createUrl)) {
        return;
      }
      savedEntityParams = {
        entityKey: savedEntities.remittance,
        id: createUrl,
        hiddenParams: {
          localeKey: 'remittance.addPayout.header',
          isCreate: true,
        },
      };
    }
    dispatch(addEntityToSaved(savedEntityParams));
  };

  getInitialFields = () => {
    return {
      ...formStateFactory(
        fieldsConfig.general,
        this.props.isPaymentIdGeneratorEnabled
      ),
      ...formStateFactory(fieldsConfig.additional),
    };
  };

  handleNavigation = (location) => {
    const { isConfirm } = this.state;
    this.pathToGo = location.pathname;
    if (location.pathname === '/login') return true;

    if (isConfirm) {
      this.showConfirmModal();
      return false;
    }
    return true;
  };

  handlePageNavigation = (locate) => {
    const { history } = this.props;

    history.push(locate);
  };

  showConfirmModal() {
    const { dispatch } = this.props;

    dispatch(
      openModal({
        modalId: 'Confirm',
        content: {
          title: 'remittance.addPayout.modal.backToRegistry.text',
        },
        callback: (answer) => {
          this.preventLeavePage({ isConfirm: !answer });
        },
      })
    );
  }

  preventLeavePage = (answer) => {
    const { dispatch } = this.props;

    dispatch(
      confirmLeavePage({
        isConfirm: answer ? answer.isConfirm : this.state.isConfirm,
      })
    );
  };

  onChange = (name, value) => {
    this.setState(
      (state) => ({
        fields: {
          ...state.fields,
          [name]: value,
          currency:
            name === 'remittanceRecipientAccountId'
              ? this.getRecipientAccountIdCurrency(value)
              : state.fields.currency,
        },
        validationErrors: { ...state.validationErrors, [name]: '' },
      }),
      async () => {
        if (name === 'remittanceRecipientAccountId' && value) {
          this.setState((state) => ({
            fieldsState: { ...state.fieldsState },
          }));
        }
        this.checkRequired();
      }
    );
  };

  getRecipientAccountIdCurrency = (value) => {
    const { availableAccountIds } = this.props;
    return availableAccountIds?.list.find((item) => item.id === value)
      ?.transferCurrency;
  };

  checkRequired = () => {
    const { fields } = this.state;

    this.setState(
      {
        isConfirm: !!Object.values(fields).length,
        canSave: this.requiredFields.every((item) => {
          return !!fields[item.id];
        }),
      },
      () => this.preventLeavePage(false)
    );
  };

  cleanForm = () => {
    this.setState({ statusText: '', fields: this.getInitialFields() }, () =>
      Tooltip.rebuild()
    );
  };

  save = async () => {
    const { fields } = this.state;

    if (isNotAvailableForSupport(Messages.Remittance_CreatePayment)) return;

    this.setState({ isLoading: true });
    await createRemittancePayment({
      ...fields,
      amount: Utils.getNumberWithoutSpace(fields.amount),
    });
  };

  onEvent = ({ name, data }) => {
    switch (name) {
      case Messages.Remittance_CreatePayment: {
        if (data.payload.validationErrors) {
          this.setState({
            isLoading: false,
            validationErrors: data.payload.validationErrors,
          });
        } else {
          this.setState({
            isLoading: false,
            statusText: 'remittance.addPayout.sendRequestSuccess.text',
          });
          this.props.dispatch(confirmLeavePage({ isConfirm: false }));
        }
        break;
      }
    }
  };
}

const mapStateToProps = (state) => {
  return {
    isPaymentIdGeneratorEnabled: state.user.isPaymentIdGeneratorEnabled,
    isConfirmLeavePage: state.app.isConfirm,
    storedSavedEntities: state.savedEntities[savedEntities.remittance],
    modal: state.modal,
    availableAccountIds: state.filtersValues?.remittanceRecipientAccount,
  };
};

export default withRouter(connect(mapStateToProps)(RemittancePaymentSingle));
