import React, { PureComponent } from 'react';
import './roleItem.scss';

interface Props {
  text: string;
}
class RoleItem extends PureComponent<Props> {
  render() {
    const { text } = this.props;
    return <span className='role-item'>{text}</span>;
  }
}
export default RoleItem;
