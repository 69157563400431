import Utils from 'helpers/Utils';
import { AnyObject } from 'types/Common';

class EventManager {
  private readonly events: AnyObject;

  constructor() {
    this.events = {};
  }

  public subscribe = (
    name: string,
    listener: (name: string, data: AnyObject) => void
  ): string => {
    if (!this.events[name]) {
      this.events[name] = {};
    }

    const id = Utils.getHash();
    this.events[name][id] = listener;

    return id;
  };

  public unsubscribe = (listenerId: string) => {
    for (const key in this.events) {
      if (Utils.hasProp(this.events, key)) {
        const event = this.events[key];
        if (event[listenerId]) {
          return delete event[listenerId];
        }
      }
    }
  };

  public emit = (name: string, data: any) => {
    const event = this.events[name];
    if (event) {
      for (const key in event) {
        if (Utils.hasProp(event, key)) {
          const listener = event[key];
          if (typeof listener === 'function') {
            listener(name, data);
          }
        }
      }
    }
  };
}

export default new EventManager();
