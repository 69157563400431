import React from 'react';
import classNames from 'classnames';
import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
} from 'react-sortable-hoc';

import { IFiltersById, ISharedWith } from 'types/Analytics';
import { StoreProps } from 'store';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { changeOrder } from 'actions/analytics';

import AllChartsFilters from './components/AllChartsFilters';
import AddGraphButton from 'pages/analytics/components/AddGraphButton';
import Button from 'components/ui/button';
import GraphCard from 'pages/analytics/components/GraphItem';
import { LayoutTypes } from 'components/modal/modalList/editLayout/EditLayout';
import Loader from 'components/ui/loader';
import Icon from 'components/ui/icon';
import ShareButton from './components/ShareChart/ShareButton';
import SharedWithMeCharts from './components/ShareChart/SharedWithMeCharts';
import {
  SHOW_DEFAULT_OPTION,
  SHOW_ONLY_MY,
  SHOW_ONLY_SHARED_WITH_ME,
  WhatToShowSelector,
} from './components/TopPanel/WhatToShowSelector';

import 'pages/analytics/advanced-section.scss';
import { GraphRelation } from './components/GraphItem/GraphCardContainer';

const MAX_CHART_AMOUNT = 10;

interface Props extends IntlProps, StoreProps {
  openChartEditModal: () => void;
  openLayoutModal: () => void;
  layoutType: LayoutTypes;
  chartsFetched: boolean;
  byOrder: {
    [order: number]: {
      biChartId: string;
      fetched: boolean;
    };
  };
  byId: {
    [id: string]: any;
  };
  filtersById: IFiltersById;
  sharedWith: ISharedWith;
}

export const SortableContainer = sortableContainer(({ children }) => (
  <div className='sortable-container'>{children}</div>
));

export const SortableItem = sortableElement(({ children }) => children);

interface State {
  selectedCharts: string[];
  loading: boolean;
  whatToShow: { value: string; label: string };
}

class Analytics extends React.Component<Props, State> {
  private handlers: {
    onSortEnd: ({
      oldIndex,
      newIndex,
    }: {
      oldIndex: number;
      newIndex: number;
    }) => void;
  };

  private onSortEnd({ oldIndex, newIndex }): void {
    const { dispatch } = this.props;

    dispatch(changeOrder({ oldOrder: oldIndex, newOrder: newIndex }));
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedCharts: [],
      loading: false,
      whatToShow: SHOW_DEFAULT_OPTION,
    };
    this.handlers = {
      onSortEnd: this.onSortEnd.bind(this),
    };
  }

  get isAddChartDisabled() {
    const { byId } = this.props;

    return Object.keys(byId).length >= MAX_CHART_AMOUNT;
  }

  get isApplyFiltersToAllDisabled() {
    const { selectedCharts } = this.state;
    return selectedCharts.length === 0;
  }

  toggleChartSelected = (chartId, selected) => {
    let { selectedCharts } = this.state;
    if (selected === true && selectedCharts.indexOf(chartId) < 0) {
      selectedCharts.push(chartId);
    } else {
      selectedCharts = selectedCharts.filter((item) => item !== chartId);
    }

    this.setState({ selectedCharts });
  };

  resetSelectedCharts = () => {
    this.setState({
      selectedCharts: [],
      loading: false,
    });
  };

  render() {
    return (
      <div className='advanced-section'>
        {this.renderTopPanel()}
        {this.renderContent()}
      </div>
    );
  }

  renderContent = () => {
    const { chartsFetched } = this.props;
    const { loading } = this.state;

    if (!chartsFetched || loading) {
      return <Loader />;
    }

    return (
      <>
        {this.renderSharedWithMeCharts()}
        {this.renderMyCharts()}
      </>
    );
  };

  renderTopPanel = () => {
    const { openChartEditModal, openLayoutModal, getTranslate } = this.props;
    const { selectedCharts } = this.state;

    return (
      <div className='advanced-section__top-panel'>
        <div className='advanced-section__top-panel-row'>
          <div className='advanced-section__common-filters'>
            <AllChartsFilters
              chartIds={selectedCharts}
              isApplyFiltersToAllDisabled={this.isApplyFiltersToAllDisabled}
              beforeApplyFunction={() => {
                this.setState({ loading: true });
              }}
              afterApplyFunction={this.resetSelectedCharts}
            />
          </div>
          <div className='advanced-section__top-panel-right-control'>
            <Button
              status='primary'
              onClick={openChartEditModal}
              text={getTranslate('analytics.mainView.addChart.button')}
              disabled={this.isAddChartDisabled}
            />
            {this.isAddChartDisabled && (
              <Icon
                size={18}
                name='help_outline'
                className={classNames(
                  'payment-action__icon-info',
                  'settings-form__grid-element',
                  'advanced-section__tooltip'
                )}
                data-place='right'
                tooltip={getTranslate('analytics.mainView.addChart.toolTip')}
                data-delay-hide='200'
              />
            )}
            <Button
              customClass='advanced-section__edit-layout'
              status='outline'
              onClick={openLayoutModal}
              text={getTranslate('analytics.mainView.editLayout.button')}
            />
            <ShareButton
              name={'Share charts'}
              selectedChartsIds={selectedCharts}
              afterApply={this.resetSelectedCharts}
            />
          </div>
        </div>
        <div className='advanced-section__top-panel-row advanced-section__top-panel-row__what-to-selector'>
          <div className={'advanced-section__top-panel-title'}>
            {getTranslate('analytics.mainView.topPanel.title')}
          </div>
          <WhatToShowSelector
            selected={this.state.whatToShow}
            onChange={(value) => {
              this.setState({
                whatToShow: value,
              });
            }}
          />
        </div>
      </div>
    );
  };

  renderSharedWithMeCharts = () => {
    const { layoutType } = this.props;
    const {
      whatToShow: { value },
    } = this.state;

    if (value === SHOW_ONLY_MY) {
      return <></>;
    }

    return <SharedWithMeCharts layoutType={layoutType} />;
  };

  renderMyCharts = () => {
    const {
      chartsFetched,
      openChartEditModal,
      byId,
      byOrder,
      layoutType,
      dispatch,
      filtersById,
      sharedWith,
    } = this.props;
    const {
      loading,
      selectedCharts,
      whatToShow: { value },
    } = this.state;

    if (value === SHOW_ONLY_SHARED_WITH_ME) {
      return <></>;
    }

    if (!chartsFetched || loading) {
      return <Loader />;
    }

    if (Object.keys(byOrder).length === 0) {
      return <AddGraphButton addNewChart={openChartEditModal} />;
    }

    return (
      <SortableContainer
        onSortEnd={this.handlers.onSortEnd}
        useDragHandle={true}
        hideSortableGhost={true}
        axis={layoutType === LayoutTypes.modules ? 'xy' : 'y'}>
        {Object.entries(byOrder).map(([orderNumber, orderObj]) => (
          <div
            key={`container-${orderObj.biChartId}-${layoutType}`}
            className={classNames(
              layoutType === LayoutTypes.modules
                ? `grid-column-6`
                : 'grid-column-12',
              'sortable-element-container'
            )}>
            <SortableItem index={Number(orderNumber)}>
              <GraphCard
                item={
                  byOrder[orderNumber].fetched === false
                    ? {}
                    : byId[byOrder[orderNumber]['biChartId']]
                }
                id={byOrder[orderNumber]['biChartId']}
                fetched={byOrder[orderNumber].fetched}
                error={byOrder[orderNumber].error}
                order={Number(orderNumber)}
                dispatch={dispatch}
                layoutType={layoutType}
                filters={filtersById[byOrder[orderNumber]['biChartId']]}
                onChartSelectChange={this.toggleChartSelected}
                selectedCharts={selectedCharts}
                showRelation={true}
                relationType={GraphRelation.MY}
                sharedWith={sharedWith[byOrder[orderNumber]['biChartId']]}
              />
            </SortableItem>
          </div>
        ))}
      </SortableContainer>
    );
  };
}

export default addTranslation(Analytics);
