import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';

export const update = (key: string, value: any) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPDATE_TEMPLATE_BUILDER,
        payload: {
          key,
          value,
        },
      })
    );
  };
};

export const updateField = (key: string, value: any) => {
  return (dispatch) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: ACTIONS.UPDATE_TEMPLATE_BUILDER_FIELD,
        payload: {
          key,
          value,
        },
      });
      resolve();
    });
  };
};

export const updateFilterField = (key: string, value: any, reportType) => {
  return (dispatch) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: ACTIONS.UPDATE_TEMPLATE_BUILDER_FILTER_FIELD,
        payload: {
          key,
          value,
          reportType,
        },
      });
      resolve();
    });
  };
};

export const reset = () => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_TEMPLATE_BUILDER,
      })
    );
  };
};
