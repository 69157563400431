export const DEFAULT_BALANCE_TYPES = [
  {
    id: 'in',
    text: 'In',
  },
  {
    id: 'out',
    text: 'Out',
  },
  {
    id: 'in_out',
    text: 'In/Out',
  },
];
