import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

const initialState: any = null;

const user = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.LOGIN_USER:
      return { ...payload.user };
    case ACTIONS.USER_CHANGE_LANGUAGE:
      return { ...state, interfaceLang: payload.interfaceLang };
    case ACTIONS.SET_USER_ERROR:
      return { ...state, ...payload };
    case ACTIONS.RESET_USER_ERROR:
      if (state && state.validationErrors) {
        return initialState;
      }
      return state ? { ...state } : null;
    case ACTIONS.UPDATE_USER:
      return {
        ...state,
        ...payload.fields,
      };
    case ACTIONS.SET_SUPPORT_MESSAGES:
      return {
        ...state,
        ...payload,
      };
    case ACTIONS.SET_USER_TIPS:
      return {
        ...state,
        tips: {
          ...(state?.tips || {}),
          list: payload,
        },
      };
    case ACTIONS.EDIT_SUPPORT_MESSAGES:
      return {
        ...state,
        supportMessages: { ...state.supportMessages, ...payload },
      };
    case ACTIONS.UPDATE_USER_TIPS:
      return {
        ...state,
        tips: {
          ...(state?.tips || {}),
          ...payload,
        },
      };
    case ACTIONS.LOGOUT_USER:
    case ACTIONS.RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

export default user;
