import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import SelectionList from 'components/ui/selectionList';

interface Props extends IntlProps {
  id: string;
  items: { id: string; text: string; isSelected: boolean }[];
  onChange: (filterId: string, value: any) => void;
  withLabel: boolean;
  autoApply?: boolean;
  isLoading?: boolean;
  withLoadMore?: boolean;
  loadMore?: () => void;
  label?: string;
  placeholder?: string;
  tooltip?: string;
  searchPlaceholder?: string;
  forceDropdownShown?: boolean;
}

class MultiSelect extends PureComponent<Props> {
  render() {
    const {
      id,
      items,
      onChange,
      label,
      tooltip,
      placeholder,
      getTranslate,
      withLoadMore,
      isLoading,
      loadMore,
      forceDropdownShown,
      searchPlaceholder,
    } = this.props;

    return (
      <div className='filters-multi-select'>
        <SelectionList
          withLoadMore={withLoadMore}
          isLoading={isLoading}
          isSearchable={items.length > 10}
          loadMore={loadMore}
          label={getTranslate(label || `filters.fields.${id}.label`)}
          placeholder={getTranslate(placeholder || '')}
          items={items}
          onChange={(newItems) => onChange(id, newItems)}
          containerSelector='.filters__content'
          tooltip={getTranslate(tooltip)}
          forceDropdownShown={forceDropdownShown}
          searchPlaceholder={searchPlaceholder}
          modern
        />
      </div>
    );
  }
}

export default addTranslation(MultiSelect);
