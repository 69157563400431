import React from 'react';
import classNames from 'classnames';
import CopyToClipboard from 'components/ui/copyToClipboard';
import './copyString.scss';

interface Props {
  text: string;
  customClass?: string;
}

export const CopyString: React.VFC<Props> = (props) => {
  if (!props.text) return null;

  return (
    <div className={classNames('ui-copy-string', props.customClass)}>
      <div className='ui-copy-string__wrapper'>
        <span className='ui-copy-string__text'>{props.text}</span>
      </div>
      <CopyToClipboard text={props.text} customClass='ui-copy-string__copy' />
    </div>
  );
};
