import { IFormDescriptorItem } from 'types/Analytics';

const paymentTypeOptions = [
  {
    id: 'purchase',
    text: 'Purchase',
  },
  {
    id: 'purchase_dms',
    text: 'Purchase dms',
  },
  {
    id: 'payout',
    text: 'Payout',
  },
  {
    id: 'recurring',
    text: 'Recurring',
  },
  {
    id: 'invoice',
    text: 'Invoice',
  },
  {
    id: 'money_transfer',
    text: 'Money transfer',
  },
  {
    id: 'account verification',
    text: 'Account verification',
  },
];

const PaymentChartParams: Array<IFormDescriptorItem> = [
  {
    id: 'chartName',
    label: 'analytics.editForm.params.chartTitle.label',
    placeholder: 'analytics.editForm.params.chartTitle.placeholder',
    type: 'input',
  },
  {
    id: 'chartData',
    type: 'singleSelect',
    label: 'analytics.editForm.params.chartData.label',
    options: [
      {
        value: 'count_transactions',
        label: 'analytics.editForm.params.chartData.number_transactions.item',
      },
      {
        value: 'unique_customers',
        label: 'analytics.editForm.params.chartData.uniqueCustomers.item',
      },
    ],
  },
  {
    id: 'groupBy',
    type: 'singleSelect',
    label: 'analytics.editForm.params.groupBy.label',
    options: [
      {
        value: 'projectId',
        label: 'analytics.editForm.params.groupBy.project.item',
      },
      {
        value: 'paymentType',
        label: 'analytics.editForm.params.groupBy.paymentType.item',
      },
      {
        value: 'paymentStatus',
        label: 'analytics.editForm.params.groupBy.paymentStatus.item',
      },
      {
        value: 'paymentMethod',
        label: 'analytics.editForm.params.groupBy.paymentMethodType.item',
      },
      {
        value: 'issuerCountry',
        label: 'analytics.editForm.filters.countryByBIN.label',
      },
      {
        value: 'country',
        label: 'analytics.editForm.params.groupBy.countryByIP.item',
      },
    ],
    conditionalOptions: [
      {
        data: [
          {
            value: 'currency',
            label: 'analytics.editForm.params.groupBy.channelCurrency.item',
          },
        ],
        condition: (values) =>
          ![
            'sum_channel_amount_in_eur',
            'sum_channel_amount_in_usd',
            'avg_channel_amount_in_eur',
            'avg_channel_amount_in_usd',
          ].includes(values.chartData),
      },
    ],
    renderOptions: {
      disabled: {
        condition: (values) =>
          values.chartData === 'amount' ||
          values.chartData === 'avg_channel_amount',
        fallBackValue: {
          value: 'currency',
          label: 'analytics.editForm.params.groupBy.channelCurrency.item',
        },
      },
      tooltipText: 'analytics.editForm.params.groupByAmount.toolTip',
    },
  },
  {
    id: 'period',
    type: 'date',
    label: 'analytics.editForm.params.period.label',
  },
  {
    id: 'groupByDate',
    type: 'singleSelect',
    label: 'analytics.editForm.params.groupPeriodBy.label',
    options: [
      {
        value: '1d',
        label: 'analytics.editForm.params.groupPeriodBy.day.item',
        order: 2,
      },
      {
        value: '1w',
        label: 'analytics.editForm.params.groupPeriodBy.week.item',
        order: 3,
      },
      {
        value: '1m',
        label: 'analytics.editForm.params.groupPeriodBy.month.item',
        order: 4,
      },
      {
        value: '3m',
        label: 'analytics.editForm.params.groupPeriodBy.quarter.item',
        order: 5,
      },
    ],
    conditionalOptions: [
      {
        data: [
          {
            value: 'all',
            label: 'analytics.editForm.params.groupPeriodBy.noGroup.item',
            order: 1,
          },
        ],
        condition: (values) =>
          'chartType' in values && values.chartType !== 'line',
      },
    ],
    renderOptions: {
      disabled: {
        fallBackValue: {
          value: 'all',
          label: 'analytics.editForm.params.groupPeriodBy.noGroup.item',
        },
        condition: (values) =>
          'chartType' in values && values.chartType === 'pie chart',
      },
      tooltipText: 'analytics.editForm.params.groupByPie.toolTip',
    },
  },
  {
    id: 'chartType',
    type: 'singleSelect',
    label: 'analytics.editForm.params.chartType.label',
    options: [
      { value: 'bar', label: 'analytics.editForm.params.chartType.bar.item' },
      {
        value: 'pie chart',
        label: 'analytics.editForm.params.chartType.pie.item',
      },
      { value: 'line', label: 'analytics.editForm.params.chartType.line.item' },
    ],
  },
];

const paymentFilters: Array<IFormDescriptorItem> = [
  {
    id: 'projects',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.project.label',
    dictionaryKey: 'projects',
  },
  {
    id: 'paymentType',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.paymentType.label',
    options: paymentTypeOptions,
  },
  {
    type: 'selectGroup',
    id: 'paymentStatus',
    label: 'analytics.editForm.filters.paymentStatus.label',
    items: [
      {
        value: 'success',
        label: 'Success',
      },
      {
        value: 'decline',
        label: 'Decline',
      },
      {
        value: 'canceled',
        label: 'Canceled',
      },
      {
        value: 'refunded',
        label: 'Refunded',
      },
    ],
    renderOptions: {
      customClass: 'selectGroup_severalRows',
    },
  },
  {
    id: 'issuerCountry',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.countryByBIN.label',
    dictionaryKey: 'country',
  },
  {
    id: 'country',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.countryByIP.label',
    dictionaryKey: 'country',
  },
  {
    id: 'paymentMethodId',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.paymentMethod.label',
    dictionaryKey: 'paymentMethodFromDictionary',
  },
  {
    id: 'paymentMethod',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.paymentMethodType.label',
    dictionaryKey: 'paymentMethod',
  },
  {
    id: 'currency',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.currency.label',
    dictionaryKey: 'currency',
    tooltip: 'select.currency.favorite.tooltip',
  },
];

export { paymentFilters, PaymentChartParams };
