import types from 'components/ui/dataBlock/types';

const getValue = (amount, currency) => {
  if (!amount || (!amount && !currency)) return '—';
  if (!currency) return amount;
  return `${amount}\u00A0${currency}`;
};

/**
 * Класс преобразования данных из DWH в наш формат для компонента DataBlock
 */
class ChargebackDataMapper {
  static get(data) {
    const { iconUrl, paymentMethodType } = data;
    const paymentLink = data.transactionId
      ? `/chargebacks/${data.transactionId}?name=${data.paymentId}&type=payment`
      : `/chargebacks/${data.paymentId}?projectId=${data.projectId}&name=${data.paymentId}&type=payment`;

    return {
      caseInfo: [
        { key: 'chargebackCard.reasonCode', value: data.reasonCode },
        {
          key: 'chargebackCard.reasonCodeDescription',
          value: data.reasonCodeDescription,
        },
        { key: 'chargebackCard.accountNumber', value: data.accountNumber },
        {
          key: 'chargebackCard.paymentMethodType',
          value:
            iconUrl || paymentMethodType
              ? { iconUrl, paymentMethodType }
              : undefined,
          type: types.image,
        },
        { key: 'charges.registry.customerId', value: data.customerId },
        { key: 'chargebackCard.projectId', value: data.projectId },
        { key: 'chargebackCard.merchantName', value: data.merchantName },
      ],
      chargebackInfo: [
        { key: 'chargebackCard.reportDate', value: data.reportDate },
        {
          key: 'chargebackCard.chargebackAmount',
          value: `${data.chargebackAmount}\u00A0${data.chargebackCurrency}`,
        },
        {
          key: 'chargebackCard.chargebackSettlementAmount',
          value: `${data.chargebackSettlementAmount}\u00A0${data.chargebackSettlementCurrency}`,
        },
      ],
      arbitrationInfo: [
        { key: 'chargebackCard.reportDate', value: data.arbitrationReportDate },
        {
          key: 'chargebackCard.amount',
          value: getValue(data.arbitrationAmount, data.arbitrationCcy),
        },
      ],
      preArbitrationInfo: [
        {
          key: 'chargebackCard.reportDate',
          value: data.preArbitrationReportDate,
        },
        {
          key: 'chargebackCard.amount',
          value: getValue(data.preArbitrationAmount, data.preArbitrationCcy),
        },
      ],
      paymentInfo: [
        {
          key: 'chargebackCard.paymentId',
          value: {
            type: 'payment',
            url: paymentLink,
            id: data.transactionId || data.projectId,
            name: data.paymentId,
          },
          type: types.link,
        },
        { key: 'chargebackCard.operationId', value: data.operationId },
        { key: 'chargebackCard.operationType', value: data.operationType },
        {
          key: 'chargebackCard.channelAmount',
          value: `${data.channelAmount}\u00A0${data.channelCurrency}`,
        },
        {
          key: 'chargebackCard.paymentDescription',
          value: data.paymentDescription,
        },
      ],
      chargebackFinancials: [
        {
          key: 'chargebackCard.chargedAmount',
          value: getValue(data.chargedAmount, data.chargedCcy),
        },
        {
          key: 'chargebackCard.creditedAmount',
          value: getValue(data.representmentAmount, data.representmentCcy),
        },
      ],
    };
  }
}

export default ChargebackDataMapper;
