import React from 'react';
import { addTranslation } from 'decorators/addTranslation';
import DynamicTable from '../../../../components/ui/table/DynamicTable';
import { TProps } from './helpers/types';
import { Columns } from './helpers/constants';

const PaymentMethods: React.VFC<TProps> = ({
  isFetched = false,
  paymentMethods,
  headerRender,
  rowRender,
  tableName,
}) => (
  <DynamicTable
    tableName={tableName}
    isFetch={isFetched}
    items={paymentMethods}
    totalItems={paymentMethods.length}
    headerRender={headerRender}
    rowRender={rowRender}
    forbidResizeFor={[Columns.method, Columns.direction, Columns.currency]}
    resizableColumns={true}
    isListView={true}
    key={tableName}
    sendMetric={false}
  />
);

export default addTranslation(PaymentMethods);
