import Repository from 'helpers/Repository';
import request from 'helpers/request';
import Env from 'helpers/Env';

import { ActionCategories, OptionsTypes } from './metricTypes';
import Utils from '../Utils';

let timerId;

const observableRequests = {
  Auth_Login: true,
  Auth_PasswordChange: true,
  Profile_Get: true,
  Profile_Update: true,
  Payments_List: true,
  Payouts_List: true,
  BulkPayouts_List: true,
  Dictionary_PayoutPaymentMethods: true,
  Dictionary_PayoutPaymentMethodRules: true,
  Balance_Get: true,
  Balance_RealTime: true,
  Balance_Statement: true,
  Team_ListUsers: true,
  Team_ListActivities: true,
  ReportTemplateTaskHistory_List: true,
  Analytics_Ts: true,
  Project_Get: true,
  Payments_Payment: true,
};

function serialize(obj) {
  if (!obj) return '';
  const str: string[] = [];
  for (const p in obj)
    if (Utils.hasProp(obj, p)) {
      if (Array.isArray(obj[p])) {
        str.push(
          encodeURIComponent(p) + '=' + encodeURIComponent(obj[p].join(','))
        );
      } else if (typeof obj[p] === 'object') {
        str.push(
          encodeURIComponent(p) + '=' + encodeURIComponent(serialize(obj[p]))
        );
      } else {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
  return str.join('&');
}

class MetricService {
  static send({ action, actionKey, params }: OptionsTypes) {
    if (Env.isDevelopment()) return;
    if (!actionKey) console.warn(`no action key for ${action} action`);
    const url = Env.getMetricUrl() + 'metrics' || '';
    if (!url) return;
    return request({
      method: 'get',
      url,
      data: {
        eventActive: action,
        actionKey,
        actionCategory: this.getCategory(actionKey),
        userId: Repository.get('store')?.getState().user.id,
        viewpoint: window.innerWidth + 'x' + window.innerHeight,
        page: Repository.get('history')?.location.pathname,
        params,
      },
    }).catch(() => {
      console.error('MetricService request error');
    });
  }

  static sendRequestTiming(message) {
    const store = Repository.get('store');
    const { user } = store.getState();

    if (user && message && Utils.hasProp(observableRequests, message.type)) {
      if (Env.isDevelopment()) return;
      const url = Env.getMetricUrl() + 'metrics' || '';

      if (!url) {
        return;
      }
      const requestUrl = `/rpc/${message.type}`;
      const { filter } = message.payload || {};

      return request({
        method: 'get',
        url,
        data: {
          userId: user.id,
          params: serialize(filter),
          loadTime: message.loadTime.toFixed(2),
          viewpoint: window.innerWidth + 'x' + window.innerHeight,
          page: message.pagination
            ? `${requestUrl}?${serialize(message.pagination)}`
            : requestUrl,
          eventActive: 'preloadpage',
        },
      });
    }
  }

  static getCategory(actionKey): keyof ActionCategories {
    if (!actionKey) {
      return 'unknownCategory';
    }
    return actionKey.split('.')[0];
  }

  static sendInputField = (actionKey) => {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      MetricService.send({
        action: 'click',
        actionKey,
      });
    }, 1000);
  };

  static sendButtonsGroup = (actionKey, prevValues, values, fieldsMapper?) => {
    let item;
    if (prevValues.length < values.length) {
      item = values.filter((filter) => {
        return !prevValues.includes(filter);
      });
    } else if (prevValues.length > values.length) {
      item = prevValues.filter((filter) => !values.includes(filter));
    }

    const key = fieldsMapper
      ? `${actionKey}.${fieldsMapper[item]}`
      : `${actionKey}.${item}`;

    MetricService.send({
      action: 'click',
      actionKey: key.toLowerCase().replace(/ /g, ''),
    });
  };
  static sendButtonsSingleChoice = (actionKey, value) => {
    const key = `${actionKey}.${value[0]?.toLowerCase() || ''}`;

    MetricService.send({
      action: 'click',
      actionKey: key.replace(/ /g, ''),
    });
  };
}

export default MetricService;
