import { connect } from 'react-redux';
import { uploadUserFile } from 'api/maf';
import { MultipleFileUploadView } from './MultipleFileUploadView';
import { MafField, MafState, MafTab } from 'components/maf/MafTypes';
import { useCallback, useEffect, useState } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import showNotification from 'components/ui/notification/showNotification';
import { MAX_FILE_SIZE_MB, MAX_NUMBER_OF_FILE_TO_UPLOAD } from './constants';
import { IntlProps, addTranslation } from 'decorators/addTranslation';
import { setBeforeUpdateActions } from 'actions/maf';
import { StoreProps } from 'store';
import DateHelpers from 'helpers/Date';
import { openModal } from 'actions/modal';

interface ConnectedProps {
  actionsBeforeUpdate: MafState['actionsBeforeUpdate'];
}

type Props = {
  form: MafTab;
  mafId?: string;
  legalEntityId?: string;
  disabled?: boolean;
  fileTypes?: string;
  onChangeFileName: ({
    objectName,
    date,
    fileName,
    field,
    formName,
  }: {
    objectName: string;
    date: string;
    fileName: string;
    field: MafField;
    formName: string;
  }) => void;
  onChangeFileSize: ({
    fileSize,
    field,
    formName,
  }: {
    fileSize: string;
    field: MafField;
    formName: string;
  }) => void;
  onAddFileField: (fileMafTab: MafTab) => MafTab | null;
  onRemoveFileField: (fileMafTab: MafTab) => void;
  setFormAsFailed: () => void;
} & ConnectedProps &
  StoreProps &
  IntlProps;

// TODO: move to helpers
const parseDateFromResponse = ({ objectName }) => {
  // objectName: ...675-20210712160837.jpeg
  const lastPiece = objectName.split('-').pop(); // 20210712160837.jpeg
  const dateString = lastPiece.split('.').shift(); // 20210712160837
  return DateHelpers.getFormat(
    DateHelpers.createDate(dateString, 'YYYYMMDDHHmmss'),
    'crmDate'
  );
};

const getFileFieldFromTab = (tab: MafTab) => {
  if (!tab.fields) {
    return tab.tabs[0].fields.find((field) => field.type === 'file');
  }

  return tab.fields.find((field) => field.type === 'file');
};

const getSizeFieldFromTab = (tab: MafTab) => {
  if (!tab.fields) {
    return tab.tabs[0].fields.find((field) => field.type === 'filesize');
  }

  return tab.fields.find((field) => field.type === 'filesize');
};

const mapTabsToFiles = (tabs: MafTab[]) => {
  return new Map(
    tabs?.map((tab) => {
      const fileField = getFileFieldFromTab(tab);
      const sizeField = getSizeFieldFromTab(tab);

      return [
        tab.crm_id,
        {
          name: fileField?.lookup_display_value || 'unknown file',
          size: sizeField?.value,
        } as unknown as File,
      ];
    })
  );
};

export const Component: React.FC<Props> = ({
  mafId = '',
  legalEntityId,
  form,
  onAddFileField,
  onRemoveFileField,
  actionsBeforeUpdate,
  dispatch,
  onChangeFileName,
  onChangeFileSize,
  getTranslate,
  disabled,
  setFormAsFailed,
  fileTypes,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<Map<string, File>>(
    mapTabsToFiles(form.tabs)
  );

  const getTabById = useCallback(
    (id: string) => {
      return form.tabs.find((tab) => tab.crm_id === id);
    },
    [form]
  );

  const onDrop: DropzoneOptions['onDrop'] = (filesToSelect: File[]) => {
    if (!filesToSelect.length) {
      showNotification({
        status: 'error',
        content:
          'Only the following file formats are allowed: docx, doc, rar, zip, pdf, png, jpeg, jpg.',
      });

      return;
    }

    if (filesToSelect.length >= MAX_NUMBER_OF_FILE_TO_UPLOAD) {
      showNotification({
        status: 'error',
        content: `Maximum number of files is ${MAX_NUMBER_OF_FILE_TO_UPLOAD}.`, // TODO: add translation
      });

      return;
    }

    const filteredFilesToSelect = filesToSelect.filter((file) => {
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        showNotification({
          status: 'error',
          content: `File size must be less than ${MAX_FILE_SIZE_MB} MB`,
        });

        return false;
      }

      return true;
    });

    filteredFilesToSelect.forEach((file) => {
      const createdMafTab = onAddFileField(form);
      if (!createdMafTab) {
        return;
      }

      selectedFiles.set(createdMafTab.crm_id, file);
    });

    setSelectedFiles(new Map(selectedFiles));
  };

  const uploadSelectedFiles = useCallback(() => {
    return Promise.all(
      [...selectedFiles.entries()].map(([id, file]) => {
        if (!file.lastModified) {
          // If file has last modified date, it means that it wasn't uploaded yet
          return Promise.resolve(); // TODO: change to simple return?
        }

        const updatingTab = getTabById(id);
        if (!updatingTab) {
          return Promise.resolve();
        }

        const fileField = getFileFieldFromTab(updatingTab);
        const sizeField = getSizeFieldFromTab(updatingTab);

        return uploadUserFile({
          file,
          mafId,
          legalEntityId,
          attachmentType: fileField?.attach_type_id || '',
          documentType: fileField?.doc_type_id || '',
        })
          .then((result) => {
            const date = parseDateFromResponse(result);
            sizeField &&
              onChangeFileSize({
                fileSize: file.size.toString(),
                formName: updatingTab.tabs[0].name, // Fragile code, but it's the only way to get form name
                field: sizeField,
              });
            fileField &&
              onChangeFileName({
                ...result,
                date,
                formName: updatingTab.tabs[0].name, // Fragile code, but it's the only way to get form name
                field: fileField,
              });
          })
          .catch((e) => {
            if (e.payload?.validationErrors?.file) {
              showNotification({
                status: 'error',
                content: (
                  <>
                    <div>{file.name}</div>
                    <div>{e.payload.validationErrors.file}</div>
                  </>
                ),
              });

              setFormAsFailed();

              selectedFiles.delete(id);
              setSelectedFiles(new Map(selectedFiles));
              onRemoveFileField(updatingTab);

              return Promise.resolve();
            }
            console.error(e);
          });
      })
    );
  }, [
    selectedFiles,
    mafId,
    legalEntityId,
    onChangeFileName,
    onChangeFileSize,
    onRemoveFileField,
    setFormAsFailed,
    getTabById,
  ]);

  useEffect(() => {
    actionsBeforeUpdate[form.name] = uploadSelectedFiles;
    dispatch(setBeforeUpdateActions(actionsBeforeUpdate));

    return () => {
      delete actionsBeforeUpdate[form.name];
      dispatch(setBeforeUpdateActions(actionsBeforeUpdate));
    };
  }, [
    dispatch,
    actionsBeforeUpdate,
    form.name,
    uploadSelectedFiles,
    selectedFiles,
    onRemoveFileField,
    form.tabs,
  ]);

  const unselectFile = (id: string) => {
    const unselectedTab = getTabById(id);

    if (unselectedTab?.__temporal) {
      dispatch(
        openModal({
          modalId: 'Confirm',
          content: {
            text: getTranslate('businessDocs.deleteItem.modal.text'),
          },
          callback: async (isOK) => {
            if (isOK) {
              selectedFiles.delete(id);
              setSelectedFiles(new Map(selectedFiles));
            }
          },
        })
      );
    }

    unselectedTab && onRemoveFileField(unselectedTab);
  };

  return (
    <MultipleFileUploadView
      onDrop={onDrop}
      selectedFiles={selectedFiles}
      unselectFile={unselectFile}
      maxFileSize={MAX_FILE_SIZE_MB}
      disabled={disabled}
      fileTypes={fileTypes}
    />
  );
};

const mapStateToProps = (state): ConnectedProps => {
  return {
    actionsBeforeUpdate: state.maf.actionsBeforeUpdate,
  };
};

export const MultipleFileUploadContainer = connect(mapStateToProps)(
  addTranslation(Component)
);
