import React, { PureComponent, Fragment } from 'react';
import classNames from 'classnames';
import { isEqual, cloneDeep, orderBy } from 'lodash';
import { addTranslation } from 'decorators/addTranslation';
import { SelectListProps } from './SelectListProps';
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';

import Icon from 'components/ui/icon';
import Checkbox from 'components/ui/checkbox';
import Radio from 'components/ui/radio';
import Input from 'components/ui/input';
import Button from 'components/ui/button';
import CustomScrollbar from 'components/ui/customScrollbar';
import Panel from 'components/ui/panel';
import Chip from 'components/ui/chip';
import InfoIcon from '../infoIcon';
import Loader from '../loader';
import Utils from 'helpers/Utils';
import {
  isRightPositionByWindow,
  isTopPositionByBlock,
} from 'helpers/setCorrectPosition';
import './selectionList.scss';
import './selectionListModern.scss';
import './selectionListLight.scss';
import { desc } from '../../../types/sortings';

export interface Item {
  id: string;
  isSelected: boolean;
  text: string;
  tooltip?: string;
  group?: string;
  isDisabled?: boolean;
  isFavorite?: boolean;
}

interface State {
  filterText: string;
  filteredItems: Item[];
  innerCopy: Item[];
  prevSelection: Item[];
  isOpenedDropdown: boolean;
  autoFocus: boolean;
  containerHeight: number;
}

const ANIMATION_DURATION = 10;
const ITEMS_LIST_PADDING = 12;
const MAX_VISIBLE_ROWS = 5;
const GROUP_TITLE_HEIGHT = 41;
const GROUP_TITLE_MARGIN = 13;

// TODO: check out this component to make it simpler
class SelectionList extends PureComponent<SelectListProps, State> {
  private readonly componentId: string;
  private readonly searchInput;
  private readonly cache;
  private selectRef;
  private listRef;
  private selectedInner;

  static defaultProps = {
    isDisabled: false,
    isFullMode: false,
    placeholder: 'common.selectitems.placeholder',
    placeholderSelected: 'common.select.selected.placeholder',
    applyText: 'common.select.apply.btn',
    searchPlaceholder: 'common.search.placeholder',
    customClass: '',
    withGroups: false,
    isSearchable: true,
    isSelectAll: true,
    isRequired: false,
    forceDropdownShown: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      filterText: '',
      filteredItems: [],
      innerCopy: [],
      prevSelection: [],
      isOpenedDropdown: false,
      autoFocus: false,
      containerHeight: 0,
    };

    this.componentId = Utils.getHash();
    this.searchInput = React.createRef();

    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: props.modern ? 36 : 28,
      defaultHeight: props.modern ? 36 : 28,
    });
    setTimeout(() => this.updateFilteredItems(false), 0);
  }

  componentDidMount() {
    const { forceDropdownShown } = this.props;

    if (forceDropdownShown) {
      this.clearCacheAndUpdateItems();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { lazyApplyAndSort = true, forceDropdownShown } = this.props;
    const { filteredItems } = this.state;
    if (
      (this.props.onApply &&
        prevState.filteredItems.length === 0 &&
        filteredItems.length > 0) ||
      (!prevState.isOpenedDropdown && this.state.isOpenedDropdown)
    ) {
      this.setState({ prevSelection: filteredItems });
    }

    if (
      !isEqual(prevProps.items, this.props.items) ||
      (!prevProps.forceUpdate && this.props.forceUpdate)
    ) {
      this.updateFilteredItems();
      forceDropdownShown && this.clearCacheAndUpdateItems();
    }

    if (this.state.isOpenedDropdown && !prevState.isOpenedDropdown) {
      this.updateContainerHeight(this.state.filteredItems);
      if (lazyApplyAndSort) {
        this.setState((state) => ({
          filteredItems: this.sortItems(state.filteredItems),
        }));
      }
    } else if (
      !this.state.isOpenedDropdown &&
      prevState.isOpenedDropdown &&
      lazyApplyAndSort
    ) {
      this.applyChange(prevProps.items);
    }
  }

  updateFilteredItems = (isUpdateHeight = true) => {
    const { items, forceUpdate, lazyApplyAndSort = true } = this.props;
    const innerCopy = lazyApplyAndSort
      ? this.sortItems(items)
      : cloneDeep(items);
    const newItems = this.getFilteredItems(innerCopy);
    this.setState(
      {
        filteredItems: newItems,
        innerCopy,
      },
      () => {
        isUpdateHeight && this.updateContainerHeight(newItems, forceUpdate);
      }
    );
  };

  /* eslint-disable complexity */
  render() {
    const {
      isDisabled,
      customClass,
      isRow,
      icon,
      error,
      id,
      theme,
      modern,
      forceDropdownShown,
    } = this.props;
    const isOpenedDropdown = this.getIsOpenedDropDownList();

    const selectedItems = this.getSelectedItems();
    return (
      <div
        id={id}
        ref={(el) => {
          this.selectRef = el;
        }}
        className={classNames('ui-selection-list', customClass, {
          'ui-selection-list_is-opened': isOpenedDropdown,
          'ui-selection-list_is-disabled': isDisabled,
          'ui-selection-list_row': isRow,
          'ui-selection-list_icon': !!icon,
          'ui-selection-list_error': !!error,
          'ui-selection-list_selected': selectedItems.length,
          'ui-selection-list_modern': !!modern,
          'ui-selection-list_top': isTopPositionByBlock(
            this.selectRef,
            this.props.containerSelector,
            '.ui-selection-list__panel'
          ),
          [`ui-selection-list_${theme}`]: !!theme,
          'ui-selection-list__onlyDropDown': forceDropdownShown,
        })}>
        {forceDropdownShown === true
          ? this.renderDropDownList()
          : this.renderInside()}
        {error && modern && (
          <div className='ui-selection-list__error'>{error}</div>
        )}
      </div>
    );
  }

  renderDropDownList = () => {
    const {
      onApply,
      applyText,
      isSingleMode,
      isSelectAll,
      withGroups,
      getTranslate,
      isLoading,
      onClose,
      theme,
    } = this.props;
    const { filteredItems, containerHeight } = this.state;
    const isOpenedDropdown = this.getIsOpenedDropDownList();

    return (
      <Panel
        isOpened={isOpenedDropdown}
        animationDuration={ANIMATION_DURATION}
        excludeFromCloseTrigger={[
          '.ui-selection-list_is-opened',
          '.js-panel-trigger-ignore',
        ]}
        renderCloseControl={false}
        customClass={classNames('ui-selection-list__panel', {
          'ui-selection-list__panel_right': isRightPositionByWindow(
            this.selectRef
          ),
        })}
        onClose={() => {
          this.close();
          onClose && onClose();
        }}>
        <div className='ui-selection-list__dropdown'>
          {this.renderSearchBlock()}
          {filteredItems.length > 0 ? (
            <Fragment>
              {isSelectAll && !isSingleMode && (
                <div className='ui-selection-list__select-all'>
                  <Checkbox
                    id='select-all'
                    text={getTranslate('common.select.selectAll.btn')}
                    checked={this.isSelectedAll()}
                    onChange={(e) => this.changeGroup(e.target.checked)}
                  />
                </div>
              )}
              <div
                className='ui-selection-list__items'
                style={{
                  height: containerHeight,
                }}>
                <AutoSizer>
                  {({ width, height }) => {
                    return (
                      <CustomScrollbar
                        onScroll={this.scrollList}
                        customHeight={height}
                        customWidth={width}>
                        {withGroups ? (
                          this.renderGroupedList()
                        ) : (
                          <List
                            ref={(el) => {
                              this.listRef = el;
                            }}
                            height={height}
                            width={width}
                            onScroll={this.handleListScroll}
                            rowRenderer={this.renderRow}
                            rowHeight={this.cache.rowHeight}
                            rowCount={filteredItems.length}
                          />
                        )}
                      </CustomScrollbar>
                    );
                  }}
                </AutoSizer>
              </div>
              {onApply && (
                <div className='ui-selection-list__apply'>
                  <Button
                    text={getTranslate(applyText)}
                    status={theme ? 'primary' : 'success'}
                    customClass='ui-selection-list__apply-button'
                    onClick={this.apply}
                  />
                </div>
              )}
            </Fragment>
          ) : (
            <div className='ui-selection-list__empty'>
              {getTranslate('common.select.empty')}
            </div>
          )}
        </div>
        {isLoading && <Loader />}
      </Panel>
    );
  };

  renderInside = () => {
    const { label, icon, error, getTranslate, isLoading, modern, tooltip } =
      this.props;
    const isOpenedDropdown = this.getIsOpenedDropDownList();

    return (
      <>
        {this.renderOldLabel()}
        <div className='ui-selection-list__wrapper'>
          {modern && (
            <label className='ui-selection-list__label'>
              {getTranslate(label ?? '')}
            </label>
          )}
          <div
            className='ui-selection-list__control'
            onClick={() => this.toggleDropdown(!isOpenedDropdown)}>
            {isLoading && (
              <Icon
                size={18}
                name='im-Ellipse'
                className='ui-selection-list__loader rotating'
              />
            )}
            {icon && (
              <Icon name={icon} size={20} className='ui-selection-list__icon' />
            )}
            {this.renderSelectedBlock()}
            {modern && tooltip && (
              <Icon
                name='im-Info'
                className='ui-selection-list__info'
                tooltip={tooltip}
                tooltipPlace='top'
              />
            )}
            <div className='ui-selection-list__indicator'>
              {modern ? (
                <Icon size={6} name='dropdownTriangle' />
              ) : (
                <Icon
                  className='ui-selection-list__indicator-icon'
                  size={8}
                  name='im-Arrow-down'
                />
              )}
            </div>
          </div>

          {error && !modern && (
            <div className='ui-selection-list__error'>{error}</div>
          )}

          {this.renderDropDownList()}
        </div>
      </>
    );
  };

  renderOldLabel = () => {
    const { label, modern, tooltip, isRequired, getTranslate } = this.props;
    if (!(label && !modern)) return null;

    return (
      <label className='ui-selection-list__label'>
        {getTranslate(label)}
        {isRequired && (
          <span
            data-tip={getTranslate('common.requiredField.tooltip')}
            className='ui-selection-list__required'>
            *
          </span>
        )}
        {tooltip && (
          <InfoIcon
            tooltip={tooltip}
            customClass='ui-selection-list__info'
            dataPlace='top'
          />
        )}
      </label>
    );
  };

  renderSearchBlock = () => {
    const { isSearchable, searchPlaceholder, getTranslate } = this.props;
    const { filteredItems, filterText, autoFocus } = this.state;
    if (!isSearchable) return null;

    return (
      (filteredItems?.length > 0 ||
        (filteredItems?.length === 0 && filterText.length > 0)) && (
        <Input
          inputContainerRef={this.searchInput}
          id={`ui-selection-list-field_${this.componentId}`}
          placeholder={getTranslate(searchPlaceholder)}
          value={filterText}
          autoFocus={autoFocus}
          customClass='ui-selection-list__filter'
          onChange={this.changeFilterText}
        />
      )
    );
  };

  renderGroupedList = () => {
    const { id, getTranslate } = this.props;
    const { filteredItems } = this.state;
    return filteredItems.map((item, i) => {
      const { id: itemId, text, isSelected, group } = item;
      return (
        <Fragment key={`${id}-${itemId}`}>
          {group && (i === 0 || group !== filteredItems[i - 1].group) && (
            <div className='ui-selection-list__title'>
              {getTranslate(group)}
            </div>
          )}
          <div key={itemId} className='ui-selection-list__item'>
            <Checkbox
              id={itemId}
              text={text}
              checked={isSelected}
              disabled={!!item.isDisabled}
              onChange={(e) =>
                this.changeItem({
                  id: itemId,
                  isSelected: e.target.checked,
                })
              }
            />
          </div>
        </Fragment>
      );
    });
  };

  renderRow = ({ index, key, parent, style }) => {
    const { isSingleMode, getTranslate, selectedCellClassName } = this.props;
    const { filteredItems } = this.state;
    const item = filteredItems[index];
    const { id, text, isSelected, isDisabled } = item;

    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}>
        {({ registerChild }) => (
          <div
            ref={registerChild}
            className={classNames('ui-selection-list__item', {
              [`${selectedCellClassName}`]: isSelected,
              'ui-selection-list__item_last-favorite':
                item.isFavorite && !filteredItems[index + 1]?.isFavorite,
            })}
            style={style}>
            {isSingleMode ? (
              <Radio
                id={id}
                text={text}
                isChecked={isSelected}
                isDisabled={!!isDisabled}
                onChange={(e) => {
                  this.changeItem({
                    id,
                    isSelected: e.target.checked,
                  });
                }}
              />
            ) : (
              <Checkbox
                id={id}
                text={getTranslate(text)}
                checked={isSelected || false}
                disabled={!!isDisabled}
                onChange={({ target }) => {
                  this.changeItem(
                    {
                      id,
                      isSelected: target.checked,
                    },
                    this.props.forceDropdownShown
                  );
                }}
              />
            )}
          </div>
        )}
      </CellMeasurer>
    );
  };

  renderSelectedBlock() {
    const {
      isFullMode,
      isSingleMode,
      placeholderSelected,
      mode,
      placeholder,
      getTranslate,
    } = this.props;

    if (isFullMode) {
      return this.renderSelectedItems();
    }

    const selectedItems = this.getSelectedItems();

    if (mode) {
      if (isSingleMode) {
        return selectedItems.length
          ? `${getTranslate(placeholder)}: ${selectedItems[0].text}`
          : getTranslate(placeholder);
      }
      return selectedItems.length
        ? `${getTranslate(placeholder)}: ${selectedItems.length}`
        : getTranslate(placeholder);
    }

    if (isSingleMode) {
      return (
        selectedItems[0]?.text ||
        getTranslate('common.selectSingle.placeholder')
      );
    }

    return `${getTranslate(placeholderSelected)} ${selectedItems.length}`;
  }

  renderSelectedItems() {
    const { getTranslate, disableScrollForSelected, placeholder } = this.props;
    const selectedItems = this.getSelectedItems();

    if (!selectedItems.length)
      return (
        <span className='ui-selection-list__placeholder'>
          {getTranslate(placeholder)}
        </span>
      );

    const selectionItems = (
      <div
        className='ui-selection-list__selected-items'
        ref={(el) => {
          this.selectedInner = el;
        }}>
        <div className='ui-selection-list__selected-items-inner'>
          {selectedItems.map((item) => {
            return (
              <Chip
                id={item.id}
                key={item.id}
                text={getTranslate(item.text)}
                onClick={(e) => this.removeSelectedItem(e, item)}
                isDisabled={!!item.isDisabled || this.props.isDisabled}
                tooltip={item.tooltip}
                theme='green'
                customClass='ui-selection-list__selected-item'
              />
            );
          })}
        </div>
      </div>
    );

    return disableScrollForSelected ? (
      selectionItems
    ) : (
      <CustomScrollbar
        customHeight={
          this.selectedInner && getComputedStyle(this.selectedInner).height
        }>
        {selectionItems}
      </CustomScrollbar>
    );
  }

  getSelectedItems = (): Item[] => {
    const { innerCopy } = this.state;
    if (!innerCopy) return [];
    return innerCopy.filter((item) => item.isSelected);
  };

  isSelectedAll = (): boolean => {
    const { filteredItems } = this.state;
    return (
      filteredItems.length > 0 &&
      filteredItems.every(({ isSelected }) => isSelected)
    );
  };

  changeFilterText = ({ target }): void => {
    const { handleSearch, items, lazyApplyAndSort = true } = this.props;

    if (lazyApplyAndSort) {
      this.applyChange(items);
    }

    this.setState(
      {
        filterText: target.value,
      },
      () => {
        if (handleSearch && typeof handleSearch === 'function') {
          handleSearch(target.value);
          return;
        }
        this.clearCacheAndUpdateItems();
      }
    );
  };

  getFilteredItems = (filteredItems) => {
    const { withGroups } = this.props;
    const { filterText } = this.state || {};

    if (filterText) {
      const cleanText = filterText.toLowerCase().trim();
      filteredItems = filteredItems.filter((item) =>
        item.text.toLowerCase().includes(cleanText)
      );
    }

    if (withGroups) {
      filteredItems = orderBy(filteredItems, ['group'], [desc]);
    }

    return filteredItems;
  };

  toggleDropdown = (isOpenedDropdown: boolean): void => {
    const { isDisabled, onOpen } = this.props;

    if (isDisabled) {
      return;
    }

    if (this.state.isOpenedDropdown !== isOpenedDropdown) {
      this.setState({
        isOpenedDropdown,
      });
    }

    if (!isOpenedDropdown) {
      setTimeout(() => {
        this.setState(
          {
            filterText: '',
          },
          this.clearCacheAndUpdateItems
        );
      }, ANIMATION_DURATION);
    }
    this.setAutoFocus(isOpenedDropdown);

    if (isOpenedDropdown && onOpen) {
      onOpen();
    }
  };

  clearCacheAndUpdateItems = () => {
    this.cache.clearAll();
    this.updateFilteredItems();
  };

  removeSelectedItem = (event, { id, isDisabled }: any) => {
    event.stopPropagation();
    if (isDisabled) return false;
    this.changeItem({ id, isSelected: false }, true);
  };

  changeItem = (
    { id, isSelected }: { id: string; isSelected: boolean },
    needApply = false
  ) => {
    const {
      isSingleMode,
      isAutoApply,
      lazyApplyAndSort = true,
      onChange,
      isValidBeforeApply,
      onClose,
    } = this.props;

    const getItemsResult = (list) =>
      list.map((item) => {
        const copyItem = { ...item };
        if (isSingleMode) {
          copyItem.isSelected = id === copyItem.id;
        } else if (copyItem.id === id) {
          copyItem.isSelected = isSelected;
        }
        return copyItem;
      });

    const resultItems = getItemsResult(this.state.innerCopy);
    if (lazyApplyAndSort) {
      if (isValidBeforeApply?.(resultItems)) return;

      this.setState(
        (state) => {
          const itemsResult = getItemsResult(state.innerCopy);

          return {
            innerCopy: itemsResult,
            filteredItems: this.getFilteredItems(itemsResult),
          };
        },
        () => {
          if (needApply) {
            this.applyChange();
          }
        }
      );
    } else {
      onChange(resultItems, {
        type: 'changeItem',
        state: { id, isSelected },
      });
    }

    if (isAutoApply) {
      this.close();
      onClose && onClose();
    }
    this.updateList();
  };

  changeGroup = (isSelected) => {
    const {
      lazyApplyAndSort = true,
      onChange,
      isValidBeforeApply,
      forceDropdownShown,
    } = this.props;
    const { filteredItems } = this.state;

    const getItemsResult = (list) => {
      return list.map((item) => {
        const copyItem = { ...item };
        if (filteredItems.find((fItem) => fItem.id === copyItem.id)) {
          copyItem.isSelected = isSelected;
        }
        return copyItem;
      });
    };

    if (lazyApplyAndSort) {
      if (isValidBeforeApply?.(getItemsResult(this.state.innerCopy))) return;

      const cb = forceDropdownShown
        ? () => {
            this.applyChange();
            this.clearCacheAndUpdateItems();
          }
        : () => {};

      this.setState((state) => {
        const innerCopy = getItemsResult(state.innerCopy);
        return {
          innerCopy,
          filteredItems: this.getFilteredItems(innerCopy),
        };
      }, cb);
    } else {
      onChange(getItemsResult(this.state.innerCopy), {
        type: 'changeGroup',
        state: { isSelected },
      });
    }

    this.updateList();
  };

  updateList = () => {
    setTimeout(() => this.listRef && this.listRef.forceUpdateGrid());
  };

  sortItems = (items) => {
    const sorted = cloneDeep(items).sort((a, b) => {
      if (a.isFavorite && !b.isFavorite) {
        return -1;
      } else if (!a.isFavorite && b.isFavorite) {
        return 1;
      }

      if (a.isSelected && !b.isSelected) {
        return -1;
      } else if (!a.isSelected && b.isSelected) {
        return 1;
      }
      return 0;
    });

    if (this.props.withGroups) {
      return orderBy(sorted, ['group'], [desc]);
    }
    return sorted;
  };

  scrollList = (values) => {
    const { scrollTop, scrollLeft } = values;
    if (this.listRef) {
      const { Grid: grid } = this.listRef;
      grid.handleScrollEvent({ scrollTop, scrollLeft });
    }
  };

  apply = () => {
    const { onApply } = this.props;
    const { innerCopy } = this.state;
    this.toggleDropdown(false);
    this.setState({ prevSelection: innerCopy }, () => {
      onApply && onApply(this.getSelectedItems());
    });
  };

  close = () => {
    const { onApply, onChange, forceDropdownShown } = this.props;
    if (forceDropdownShown) return;

    const { prevSelection } = this.state;
    this.toggleDropdown(false);
    if (onApply) {
      onChange && onChange(prevSelection, { type: 'changeGroup' });
    }
  };

  applyChange = (prevItems?) => {
    const { onChange, items } = this.props;
    const { innerCopy } = this.state;
    const itemsResult: Item[] = (prevItems || items).map((item) => {
      return {
        ...item,
        isSelected:
          innerCopy.find(({ id }) => id === item.id)?.isSelected || false,
      };
    });

    onChange && onChange(itemsResult, { type: 'changeGroup' });
  };

  setAutoFocus = (isOpenedDropdown) => {
    if (isOpenedDropdown) {
      setTimeout(() => {
        this.setState({ autoFocus: true });
        const { current: node } = this.searchInput;
        const input = node && node.querySelector('.ui-input__field');
        if (input) {
          input.focus();
        }
      }, ANIMATION_DURATION);
    } else {
      this.setState({ autoFocus: false });
    }
  };

  handleListScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
    const { withLoadMore, loadMore, lazyApplyAndSort } = this.props;
    if (!withLoadMore) {
      return;
    }
    const scrolledToBottom = clientHeight + scrollTop >= scrollHeight - 30;
    if (scrolledToBottom && loadMore) {
      if (lazyApplyAndSort) {
        this.applyChange();
      }
      loadMore();
    }
  };

  calculateContainerHeight = (filteredItems): number => {
    const { itemsPerRow = 1 } = this.props;
    let countedHeight = (this.props.modern ? 36 : 28) * 5;

    const hasGroup = !!filteredItems[0]?.group;
    const rowsCount = Math.ceil(filteredItems.length / itemsPerRow);
    const visibleList = filteredItems.slice(0, itemsPerRow * MAX_VISIBLE_ROWS);

    if (rowsCount && this.cache) {
      countedHeight = this.computeCacheHeight(filteredItems);
    }

    if (!hasGroup) return countedHeight + ITEMS_LIST_PADDING;

    if (filteredItems[0].group === visibleList[visibleList.length - 1].group) {
      return countedHeight + ITEMS_LIST_PADDING + GROUP_TITLE_HEIGHT;
    }

    const groups = visibleList.reduce((groupNames, item) => {
      if (!groupNames.includes(item.group)) {
        groupNames.push(item.group);
      }
      return groupNames;
    }, []);

    const titlesHeight =
      groups.length * (GROUP_TITLE_HEIGHT + GROUP_TITLE_MARGIN) -
      GROUP_TITLE_MARGIN; // у первого заголовка нет отступа
    return countedHeight + ITEMS_LIST_PADDING + titlesHeight;
  };

  computeCacheHeight = (items) => {
    const { itemsPerRow = 1 } = this.props;
    const visibleLength =
      items.length / itemsPerRow < MAX_VISIBLE_ROWS
        ? items.length / itemsPerRow
        : MAX_VISIBLE_ROWS;

    return Array.from({
      length: Math.ceil(visibleLength),
    })
      .map((_, index) => this.cache.rowHeight({ index }))
      .reduce((a, c) => a + c, 0);
  };

  adjustContainerHeight = (items, count?) => {
    if (this.props.withGroups) {
      this.setState({
        containerHeight: this.calculateContainerHeight(items),
      });
      return;
    }
    setTimeout(() => {
      const { itemsPerRow = 1 } = this.props;
      const { filteredItems } = this.state;
      if (items.length === filteredItems.length) {
        const containerHeight = this.computeCacheHeight(filteredItems);
        const rowsCount = Math.ceil(filteredItems.length / itemsPerRow);

        const sameHeight =
          containerHeight === this.cache.defaultHeight * rowsCount;

        if (sameHeight && count < MAX_VISIBLE_ROWS) {
          this.adjustContainerHeight(filteredItems, (count || 0) + 1);
        }
        if (!sameHeight) {
          this.setState({
            containerHeight: containerHeight + ITEMS_LIST_PADDING,
          });
        }
      }
    });
  };

  updateContainerHeight = (newItems, forceUpdate = false) => {
    const { forceDropdownShown } = this.props;
    const containerHeight = this.calculateContainerHeight(newItems);
    this.setState({
      containerHeight,
    });

    this.adjustContainerHeight(newItems);
    (forceUpdate || forceDropdownShown) && this.updateList();
  };

  getIsOpenedDropDownList = (): boolean => {
    const { forceDropdownShown } = this.props;
    return forceDropdownShown ? true : this.state.isOpenedDropdown;
  };
}

export default addTranslation(SelectionList);
