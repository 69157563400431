import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import { openModal } from 'actions/modal';
import { confirmLeavePage } from 'actions/app';
import { connect } from 'react-redux';
import { logoutUser } from 'actions/user';
import path from 'helpers/path';
import { StoreProps } from 'store';
import { WithRouterProps } from 'decorators/withRouter';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import './logout.scss';

interface ConnectedProps {
  isConfirmLogout: boolean;
}

interface OwnProps {
  customClass?: string;
}

class Logout extends PureComponent<
  StoreProps & WithRouterProps & OwnProps & ConnectedProps & IntlProps
> {
  render() {
    return (
      <div
        className={classNames('logout', this.props.customClass)}
        onClick={this.onLogout}>
        <Icon size={13} name='im-Log-out' className='logout__icon' />
        <span className='logout__text'>
          {this.props.getTranslate('common.logOut.button')}
        </span>
      </div>
    );
  }

  onLogout = () => {
    const { isConfirmLogout, dispatch } = this.props;

    if (isConfirmLogout) {
      dispatch(
        openModal({
          modalId: 'Confirm',
          content: {
            title: 'projects.urls.modal.urlChangeDiscard.header',
            text: 'projects.urls.modal.urlChangeDiscard.label',
          },
          callback: (isContinue) => {
            dispatch(confirmLeavePage({ isConfirm: !isContinue }));
            if (isContinue) {
              this.logout();
            }
          },
        })
      );
    } else {
      this.logout();
    }
  };

  logout = () => {
    this.props.dispatch(
      logoutUser(() => {
        this.props.history.push(path('/login'), {});
      })
    );
  };
}

const mapStateToProps = (state) => ({
  isConfirmLogout: state.app.isConfirm,
});

export default connect(mapStateToProps)(withRouter(addTranslation(Logout)));
