import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import './emptyState.scss';

interface EmptyStateProps {
  title: string;
  text: string;
  label?: string;
  buttonText: string;
  onButtonClick: (e) => void;
  picturePath: string;
}

type Props = EmptyStateProps & IntlProps;

class EmptyState extends PureComponent<Props> {
  render() {
    const { getTranslate } = this.props;
    return (
      <div className='ui-empty-state'>
        <div className='ui-empty-state__inner'>
          <img
            src={this.props.picturePath}
            alt=''
            className='ui-empty-state__image'
          />
          {this.props.label && <div>label</div>}
          <div className='ui-empty-state__title'>
            {getTranslate(this.props.title)}
          </div>
          <div className='ui-empty-state__text'>
            {getTranslate(this.props.text)}
          </div>
          <Button
            status='primary'
            size='normal'
            onClick={this.props.onButtonClick}
            text={getTranslate(this.props.buttonText)}
          />
        </div>
      </div>
    );
  }
}

export default addTranslation(EmptyState);
