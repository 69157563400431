import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { getMafDigest, setMafWasOpen } from 'api/maf';
import { MafDigest } from 'components/maf/MafTypes';
import { WithRouterProps } from 'decorators/withRouter';
import BusinessDocumentsFormsList from './BusinessDocumentsFormsList';
import { LegalEntity, MafListOption } from './BusinessDocumentsFormsList.types';
import { addListeners, IListeners } from 'decorators/addListeners';
import Messages from 'constants/rpcTypes';
import { removeAllEntities } from 'actions/savedEntities';
import savedEntities from 'constants/savedEntities';
import { StoreProps } from 'store';
import './businessDocumentsFormsList.scss';

interface ConnectedProps {
  legalEntities: LegalEntity[];
}

@addListeners([Messages.Maf_GetDigest])
class BusinessDocumentsFormsListContainer
  extends React.PureComponent<
    WithRouterProps & ConnectedProps & StoreProps,
    {
      digest?: MafDigest;
      selectedLegalEntity: MafListOption;
    }
  >
  implements IListeners
{
  constructor(props) {
    super(props);
    this.state = {
      digest: undefined,
      selectedLegalEntity: {
        value: this.props.legalEntities?.[0]?.id,
        label: this.props.legalEntities?.[0]?.title,
      },
    };
  }

  async updateDigest(id?: number) {
    if (!id) return;

    try {
      const digest = await getMafDigest(id);
      this.setState({ digest });
    } catch (e) {
      console.error('Cannot get maf digest', e);
    }
  }

  componentDidMount() {
    const selectedLegalEntity = {
      value: this.props.legalEntities?.[0]?.id,
      label: this.props.legalEntities?.[0]?.title,
    };

    this.setSelectedEntity(selectedLegalEntity)
      .then(() => {
        this.updateDigest(this.state.selectedLegalEntity?.value);
      })
      .catch(console.error);
  }

  onEvent(event) {
    if (event.data.payload.result === 'ok') {
      this.updateDigest(this.state.selectedLegalEntity?.value);
    }
  }

  setSelectedEntity = (selectedEntity: MafListOption) => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          selectedLegalEntity: selectedEntity,
          digest: undefined,
        },
        () => {
          resolve(selectedEntity);
        }
      );
    });
  };

  changeSelectedEntity = (selectedEntity: MafListOption) => {
    if (
      !selectedEntity ||
      selectedEntity.value === this.state.selectedLegalEntity?.value
    ) {
      return;
    }

    this.setSelectedEntity(selectedEntity);
    this.props.dispatch(
      removeAllEntities({
        entityKey: savedEntities.businessDocs,
      })
    );
    this.updateDigest(selectedEntity.value);
  };

  goToSelectedFormHandler = (formId: string, caption: string, wasMafOpen) => {
    !wasMafOpen && setMafWasOpen(formId, this.state.selectedLegalEntity?.value);

    this.props.history.push(
      `${this.props.match.url}/${formId}?${queryString.stringify({
        legalEntityId: this.state.selectedLegalEntity?.value,
      })}`,
      { legalEntityCaption: caption }
    );
  };

  render() {
    return (
      <BusinessDocumentsFormsList
        digest={this.state.digest}
        goToForm={this.goToSelectedFormHandler}
        legalEntities={this.props.legalEntities}
        changeSelectedEntity={this.changeSelectedEntity}
        selectedLegalEntity={this.state.selectedLegalEntity}
      />
    );
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  legalEntities: state.user.activeLegalEntities,
});

export default connect(mapStateToProps)(BusinessDocumentsFormsListContainer);
