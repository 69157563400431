export default {
  commonDesktop: 1200,
  commonTabletL: 1024,
  commonTablet: 768,
  quickFilters: 560,
  header: 768,
  panels: 680,
  profileActivity: 600,
  searchFilters: 840,
  userBuilder: 680,
  projects: 1000,
  datePicker: 480,
};
