export default {
  pending: {},
  available: {},
  opening: {},
  closing: {},
  rowsOpeningClosing: [
    // GROUP 1
    [
      {
        id: 'payInVolume',
        columns: {},
      },
      {
        id: 'payInFee',
        columns: {},
      },
    ],

    // GROUP 2
    [
      {
        id: 'refundVolume',
        columns: {},
      },
      {
        id: 'refundFee',
        columns: {},
      },
    ],
    //GROUP Interchange
    [
      {
        id: 'interchange',
        columns: {},
      },
      {
        id: 'interchangeSchemeFee',
        columns: {},
      },
      {
        id: 'interchangeProcessorFee',
        columns: {},
      },
    ],
    // GROUP 3
    [
      {
        id: 'chargebackVolume',
        columns: {},
      },
      {
        id: 'chargebackFee',
        columns: {},
      },
      {
        id: 'chargebackAdditionalFee',
        columns: {},
      },
      {
        id: 'retrievalRequestFee',
        columns: {},
      },
      {
        id: 'representment',
        columns: {},
      },
    ],
    // GROUP 4
    [
      {
        id: 'fraudCoverFee',
        columns: {},
      },
      {
        id: 'otherServiceFees',
        columns: {},
      },
      {
        id: 'fundsTransfer',
        columns: {},
      },
      {
        id: 'transferFee',
        columns: {},
      },
      {
        id: 'currencyExchange',
        columns: {},
      },
      {
        id: 'settlement',
        columns: {},
      },
      {
        id: 'settlementFee',
        columns: {},
      },
      {
        id: 'balanceCorrection',
        columns: {},
      },
      {
        id: 'surchargeFee',
        columns: {},
      },
    ],
    // GROUP 5
    [
      {
        id: 'holdbackVolume',
        columns: {},
      },

      {
        id: 'unblockedHoldbackVolume',
        columns: {},
      },
    ],
  ],
  accruedHoldbackVolume: {},
  netHoldbackVolume: {},
  rowsReversedHoldback: [
    [
      {
        id: 'holdbackVolume',
        columns: {},
      },
      {
        id: 'unblockedHoldbackVolume',
        columns: {},
      },
    ],
  ],
};
