enum tableNames {
  payments = 'payments',
  payouts = 'payouts',
  massPayouts = 'massPayouts',
  batch = 'batch',
  rcs = 'rcsBlackWhite',
  fraud = 'rcsFraud',
  references = 'paymentOperationReference',
  teamUsers = 'teamUsers',
  users = 'users',
  teamActivities = 'teamActivities',
  subscriptionPayments = 'subscriptionPayments',
  subscriptionPaymentsSettingsHistory = 'subscriptionPaymentsSettingsHistory',
  chargebacks = 'chargebacks',
  reports = 'reports',
  apsIcon = 'apsIcon',
  balanceRealTime = 'balanceRealTime',
  balanceStatement = 'balanceStatement',
  financialReport = 'financialReport',
  financialFile = 'financialFile',
  currencyRates = 'currencyRates',
  projectCallbacks = 'projectCallbacks',
  remittanceRecipientAccounts = 'remittanceRecipientAccounts',
  remittancePayments = 'remittancePayments',
  massRemittancePayments = 'massRemittancePayments',
  remittancePaymentBatch = 'remittancePaymentBatch',
  invoices = 'invoices',
  merchantLegalEntities = 'merchantLegalEntities',
  chbHistory = 'chbHistory',
  paymentMethods = 'paymentMethods',
}

export default tableNames;
