import { createAction } from 'helpers/redux';
import ACTIONS from 'constants/actionTypes';

let pendingRequests: any[] = [];

const clearRequests = (requests, name) => {
  const index = requests.findIndex((type) => type === name);
  return requests.reduce((result, type, i) => {
    if (i === index && type === name) {
      return result;
    }
    return [...result, type];
  }, []);
};

export const getData =
  ({ apiRequest, request, onResolve, isUpload, name }) =>
  (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.FETCH_DATA_PROGRESS,
        payload: {
          name,
        },
      })
    );
    pendingRequests.push(name);

    apiRequest(request)
      .then((payload) => {
        onResolve();
        dispatch(
          createAction({
            type: isUpload
              ? ACTIONS.FETCH_DATA_UPLOAD_SUCCESS
              : ACTIONS.FETCH_DATA_SUCCESS,
            payload: {
              ...payload,
              name,
            },
          })
        );
        pendingRequests = clearRequests(pendingRequests, name);
      })
      .catch((error) => {
        pendingRequests = clearRequests(pendingRequests, name);

        if (pendingRequests.includes(name)) {
          return;
        }

        dispatch(
          createAction({
            type: ACTIONS.FETCH_DATA_FAIL,
            payload: {
              name,
            },
          })
        );

        console.error(error);
      });
  };

export const updateData = (name, payload) => (dispatch) => {
  dispatch(
    createAction({
      type: ACTIONS.FETCH_DATA_SUCCESS,
      payload: { name, ...payload },
    })
  );
};

export const saveDataState = (name, state) => (dispatch) => {
  dispatch(
    createAction({
      type: ACTIONS.SAVE_DATA_STATE,
      payload: { name, state },
    })
  );
};

export const resetData = (name) => (dispatch) => {
  dispatch(
    createAction({
      type: ACTIONS.RESET_PART_OF_DATA,
      payload: { name },
    })
  );
};

export const changeDataSorting = (name, sort) => (dispatch) => {
  dispatch(
    createAction({
      type: ACTIONS.CHANGE_DATA_SORTING,
      payload: { name, sort },
    })
  );
};

export const changeDataForceUpdate = (name, value) => (dispatch) => {
  dispatch(
    createAction({
      type: ACTIONS.CHANGE_DATA_FORCE_UPDATE,
      payload: { name, value },
    })
  );
};

export const updateDataItem = (name, key, data) => (dispatch) => {
  dispatch(
    createAction({
      type: ACTIONS.UPDATE_DATA_ITEM,
      payload: { name, key, data },
    })
  );
};

export const updateDataItemProperties =
  (name, key, propsToUpdate: string[], data) => (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPDATE_DATA_ITEM_PROPERTIES,
        payload: { name, key, propsToUpdate, data },
      })
    );
  };

export const deleteDataItem = (name, key, keyValue) => (dispatch) => {
  dispatch(
    createAction({
      type: ACTIONS.DELETE_DATA_ITEM,
      payload: { name, key, keyValue },
    })
  );
};

export const addDataItem = (name, data, sort?) => (dispatch) => {
  dispatch(
    createAction({
      type: ACTIONS.ADD_DATA_ITEM,
      payload: { name, data, sort },
    })
  );
};

export const addDataItems = (name, list) => (dispatch) => {
  dispatch(
    createAction({
      type: ACTIONS.ADD_DATA_ITEMS,
      payload: { name, list },
    })
  );
};
