import React from 'react';
import classNames from 'classnames';
import './grid.scss';

interface Props {
  className?: string;
  noGap?: boolean;
  direction: 'column' | 'row';
  spacing:
    | 'xxs' //5px
    | 'xs' //10px
    | 's' // 15px;
    | 'm' // 20px;
    | 'l'; // 25px;
  justify:
    | 'initial'
    | 'baseline'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'flex-start'
    | 'flex-end';
  align:
    | 'initial'
    | 'baseline'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'flex-start'
    | 'flex-end';
  flex: number | string;
  wrap?: 'initial' | 'wrap';
  onClick?: (args: any) => any;
}

class Grid extends React.Component<Props> {
  static defaultProps = {
    direction: 'row',
    spacing: 's',
    justify: 'initial',
    align: 'initial',
    flex: 'initial',
    wrap: 'initial',
  };

  render() {
    const {
      className,
      noGap,
      direction,
      spacing,
      justify,
      align,
      flex,
      wrap,
      onClick,
      ...rest
    } = this.props;
    return (
      <div
        {...rest}
        onClick={onClick}
        style={{
          justifyContent: justify,
          alignItems: align,
          flex,
          flexWrap: wrap,
        }}
        className={classNames(
          'grid',
          `grid_${direction}`,
          {
            [`grid_${spacing}`]: !noGap,
          },
          className
        )}>
        {this.props.children}
      </div>
    );
  }
}

export default Grid;
