import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { WithRouterProps } from 'decorators/withRouter';
import Link from 'components/ui/link';
import { getArticleBySlug } from 'pages/help/article/utils';
import HelpArticle from 'pages/help/types';

interface ConnectedProps {
  articles: HelpArticle[];
}

type Props = ConnectedProps & WithRouterProps<{ slug?: string }>;

class AnchorList extends PureComponent<Props> {
  render() {
    const { articles, location, match } = this.props;
    const article = getArticleBySlug(articles, match.params.slug);
    if (!article?.subTitles?.length) return null;

    return (
      <div className='help-menu__submenu'>
        {article.subTitles.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames('help-menu__submenu-item', {
                'help-menu__submenu-item_active':
                  item.id && item.id === location.hash.slice(1),
              })}>
              <Link className='help-menu__submenu-link' url={`#${item.id}`}>
                {item.text}
              </Link>
            </div>
          );
        })}
      </div>
    );
  }
}
const mapStateToProps = (state): ConnectedProps => ({
  articles: state.help.articles,
});

export default withRouter(connect(mapStateToProps)(AnchorList));
