import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { getRemittanceBatchDetail } from 'api/remittance';
import { addEntityToSaved, updateEntity } from 'actions/savedEntities';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import DynamicTable from 'components/ui/table/DynamicTable';
import savedEntities from 'constants/savedEntities';
import tableNames from 'constants/tableNames';
import getPaymentById from 'selectors/getPaymentById';
import SavedEntity from 'types/savedEntity';
import { AnyObject } from 'types/Common';
import Messages from 'constants/rpcTypes';
import path from 'helpers/path';
import { getPath } from '../helpers';
import { addListeners } from 'decorators/addListeners';
import { StoreProps } from 'store';

interface Props {
  id: string;
  location: any;
  history: any;
}

interface ConnectedProps {
  user: AnyObject;
  storedSavedEntities: { isFetch: boolean; items: SavedEntity[] };
  batch: any;
}

@addListeners([Messages.Remittance_PaymentBatchView])
class BatchDetail extends Component<Props & ConnectedProps & StoreProps> {
  componentDidMount() {
    this.init();
  }

  async componentDidUpdate(prevProps) {
    const { user, storedSavedEntities } = this.props;

    if (user && prevProps.user.timezone !== user.timezone) {
      return this.getBatch();
    }

    if (!prevProps.storedSavedEntities && storedSavedEntities) {
      this.init();
    }

    if (prevProps.id !== this.props.id) {
      this.getBatch();
    }
  }

  render() {
    const { batch } = this.props;
    return (
      <PageTemplate.Container customClass='remittance-page'>
        {/* @ts-ignore TODO: t.melashchenko, выручай! */}
        <DynamicTable
          resizableColumns={!!batch?.data?.rows?.length}
          tableName={tableNames.remittancePaymentBatch}
          items={batch?.data?.rows || []}
          isFetch={!batch?.isFetch}
          clickToItem={this.goToPayment}
        />
      </PageTemplate.Container>
    );
  }

  init = () => {
    const { id, dispatch, location, batch, storedSavedEntities } = this.props;
    const urlParams = queryString.parse(location.search);

    if (!storedSavedEntities) return;

    if (!batch) {
      dispatch(
        addEntityToSaved({ entityKey: savedEntities.remittance, id, urlParams })
      );
    }
    this.getBatch();
  };

  getBatch = () => {
    const { id, batch } = this.props;
    if (batch?.data?.rows?.length) return;
    try {
      getRemittanceBatchDetail(id);
    } catch (error) {
      console.error(error);
    }
  };

  setBatch = (payload) => {
    const { id, dispatch } = this.props;
    dispatch(
      updateEntity({
        entityKey: savedEntities.remittance,
        id,
        fields: { data: payload },
      })
    );
  };

  goToPayment = (data) => {
    if (!data.transactionId) return;
    this.props.history.push(
      path(getPath(tableNames.remittancePaymentBatch, data))
    );
  };

  onEvent = ({ data }) => {
    this.setBatch(data.payload);
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    batch: getPaymentById(state, savedEntities.remittance, ownProps.id),
    storedSavedEntities: state.savedEntities[savedEntities.remittance],
  };
};
export default withRouter(connect(mapStateToProps)(BatchDetail));
