import { createAction } from 'helpers/redux';
import ACTIONS from 'constants/actionTypes';
import LocalStorage from '../helpers/LocalStorage';
import DateHelpers from '../helpers/Date';

const setDocLinks = (payload) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_DOC_LINKS,
        payload,
      })
    );
  };
};

const setMafStatusChangeAvailability = (canChangeMafStatus: boolean) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.MAF_STATUS_CHANGE_AVAILABILITY,
        payload: { canChangeMafStatus },
      })
    );
  };
};

const checkMafStatusChangeAvailability = () => {
  return (dispatch, getState) => {
    const dateUnblock = LocalStorage.get('ForbidMafStatusChangeTill');

    if (!dateUnblock) {
      return;
    }
    const timeTillUnblock = DateHelpers.createDate(
      dateUnblock,
      'noTZCommon'
    ).diff(
      DateHelpers.createDate(
        DateHelpers.getFormat(
          DateHelpers.getDateWithTimezone(getState().user.timezone),
          'datetime'
        ),
        'datetime'
      ),
      'milliseconds'
    );

    if (timeTillUnblock > 0) {
      dispatch(setMafStatusChangeAvailability(false));
      setTimeout(() => {
        dispatch(setMafStatusChangeAvailability(true));
        LocalStorage.remove('ForbidMafStatusChangeTill');
      }, timeTillUnblock);
    } else {
      LocalStorage.remove('ForbidMafStatusChangeTill');
    }
  };
};

export {
  setDocLinks,
  setMafStatusChangeAvailability,
  checkMafStatusChangeAvailability,
};
