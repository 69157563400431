import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

export const getTabList = (tabsName) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ClientTab_Info,
        payload: { ...tabsName },
      },
    ],
  });
};

export const updateTabList = (tabsName) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ClientTab_Update,
        payload: { ...tabsName },
      },
    ],
  });
};
