import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { IntlProps, addTranslation } from 'decorators/addTranslation';
import Chip from 'components/ui/chip';
import CustomScrollbar from '../customScrollbar';
import './InputList.scss';

interface Props extends IntlProps {
  id?: string;
  items: string[];
  onChange: (items: string[], item: string, type: string) => void;
  separators?: string[];
  label?: string;
  placeholder?: string;
  error?: string;
  numbersOnly?: boolean;
  inline?: boolean;
  width?: string;
  customClass?: string;
  theme?: string;
  disabled?: boolean;
}

interface State {
  value: string;
  isShowInput: boolean;
}

class InputList extends PureComponent<Props, State> {
  private inputRef;
  static defaultProps = {
    separators: [';'],
    inline: false,
    width: '200px',
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      isShowInput: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { isShowInput } = this.state;
    if (!prevState.isShowInput && isShowInput) {
      this.inputRef.querySelector('input').focus();
    }
  }

  render() {
    const {
      id,
      items,
      label,
      placeholder,
      error,
      inline,
      width,
      customClass,
      theme,
      getTranslate,
    } = this.props;
    const { value, isShowInput } = this.state;

    return (
      <div
        className={classNames('ui-input-list', customClass, {
          'ui-input-list_error': !!error,
          [`ui-input-list_${theme}`]: !!theme,
        })}
        id={id}>
        {label && (
          <label className='ui-input-list__label'>{getTranslate(label)}</label>
        )}

        <div
          className='ui-input-list__area'
          onClick={this.showInput}
          style={inline ? { maxWidth: width, width } : {}}>
          {!items?.length && !isShowInput && (
            <span className='ui-input-list__placeholder'>
              {getTranslate(placeholder)}
            </span>
          )}

          <CustomScrollbar>
            <ul
              className={classNames('ui-input-list__list', {
                'ui-input-list__list_inline': inline,
              })}
              style={inline ? { width: 'auto' } : {}}>
              {items?.map((item) => (
                <li className='ui-input-list__item' key={item}>
                  <Chip
                    id={item}
                    text={item}
                    onClick={this.removeItem}
                    theme='blue'
                  />
                </li>
              ))}
              {isShowInput && (
                <li
                  className='ui-input-list__item ui-input-list__item_input'
                  key='ui-input-list-filed'
                  ref={(el) => {
                    this.inputRef = el;
                  }}>
                  <input
                    type='text'
                    value={value}
                    className='ui-input-list__input'
                    onInput={(e) => this.onInput(e)}
                    onChange={() => null}
                    onKeyPress={(e) => this.onKeyPress(e)}
                    onBlur={() => this.hideInput()}
                  />
                </li>
              )}
            </ul>
          </CustomScrollbar>
          {error && <span className='ui-input-list__error'>{error}</span>}
        </div>
      </div>
    );
  }

  removeItem = (_, ItemToRemove) => {
    const { items, onChange } = this.props;
    const newItems = items.filter((item) => item !== ItemToRemove);
    onChange(newItems, ItemToRemove, 'remove');

    if (!newItems.length) {
      this.hideInput(true);
    }
  };

  addItem = () => {
    const { items, onChange } = this.props;
    const { value } = this.state;
    if (!value.trim() || items.includes(value)) return;
    const newItems = [...items, value];
    onChange(newItems, value, 'add');
    this.setState({ value: '' });
  };

  onInput = (e) => {
    const { numbersOnly, separators } = this.props;
    const value = e.target.value.trim();

    if (
      (numbersOnly && isNaN(+value)) ||
      separators?.includes(value[value.length - 1])
    )
      return;

    this.setState({ value });
  };

  onKeyPress = (e) => {
    const { separators } = this.props;
    const isSeparator = separators?.includes(e.key);

    if (e.which === 13 || e.which === 32 || isSeparator) {
      this.addItem();
    }
  };

  showInput = () => {
    this.setState({ isShowInput: true });
  };

  hideInput = (isForceHide?: boolean) => {
    const { inline, items } = this.props;
    const { value } = this.state;
    this.addItem();
    if ((!isForceHide || (inline && items.length)) && value) return;
    this.setState({ isShowInput: false, value: '' });
  };
}
export default addTranslation(InputList);
