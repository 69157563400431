import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Loader from 'components/ui/loader';
import { ChargebackFlowType } from 'pages/chargebacks/ChargebacksTypes';
import MetricService from 'helpers/metricService/MetricService';
import './chargebackFlow.scss';
import classNames from 'classnames';
import Icon from '../../../../../components/ui/icon';

interface Stage {
  isCompleted: boolean;
  isCurrent: boolean;
  isOptional: boolean;
  title: string;
  text: string;
}

interface Props extends IntlProps {
  chargebackFlow: ChargebackFlowType;
  timeline: Stage[];
  currentLanguage: string;
  isLoading: boolean;
}

interface State {
  timelineInner: {
    isCurrent: boolean;
    isCompleted: boolean;
    isOptional: boolean;
    title: string;
    text: string;
    isOpened: boolean;
  }[];
}

class ChargebackFlow extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      timelineInner: this.setTimeline(),
    };
  }

  render() {
    const { chargebackFlow, getTranslate, isLoading } = this.props;

    if (isLoading) {
      return <Loader />;
    }

    if (!chargebackFlow) return null;

    return (
      <div className='chargeback-flow'>
        {this.state.timelineInner.map((stage, index) => {
          return (
            <div
              key={index}
              className={classNames('chargeback-flow__step', {
                'chargeback-flow__step_completed': stage.isCompleted,
                'chargeback-flow__step_current': stage.isCurrent,
                'chargeback-flow__step_closed': !stage.isOpened,
              })}
              onClick={() => this.openStep(stage.title)}>
              {stage.isCompleted && (
                <Icon
                  name='im-Tick'
                  size={8}
                  className='chargeback-flow__icon'
                />
              )}
              <div className='chargeback-flow__step-inner'>
                <div className='chargeback-flow__step-content'>
                  <div className='chargeback-flow__step-title'>
                    <div className='chargeback-flow__step-title-inner'>
                      {getTranslate(stage.title)}
                      {stage.isOptional && (
                        <span className='chargeback-flow__step-title-light'>
                          {' '}
                          ({getTranslate('chargebackCard.timeline.optional')})
                        </span>
                      )}
                    </div>
                    <Icon
                      name='dropdownTriangle'
                      size={6}
                      className='chargeback-flow__step-arrow'
                    />
                  </div>
                  {stage.isOpened && (
                    <div className='chargeback-flow__step-text'>
                      {getTranslate(stage.text)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderFlowLogo() {
    const { chargebackFlow } = this.props;

    if (!chargebackFlow.logoImage && !chargebackFlow.logoLabel) return null;

    let src = '';
    try {
      src = require(`images/flows/${chargebackFlow.logoImage}.svg`);
    } catch {}

    return (
      <div className='chargeback-flow__logo'>
        {
          <img
            className={`chargeback-flow__logo-img chargeback-flow__logo-img_${chargebackFlow.logoImage}`}
            src={src}
            alt={chargebackFlow.logoImage}
          />
        }
        {chargebackFlow.logoLabel && (
          <div className='chargeback-flow__logo-label'>
            {chargebackFlow.logoLabel}
          </div>
        )}
      </div>
    );
  }

  setTimeline = () => {
    return this.props.timeline.map((item) => ({ ...item, isOpened: false }));
  };

  openStep = (title) => {
    this.setState((state) => ({
      timelineInner: state.timelineInner.map((item) => {
        if (item.title === title) {
          return {
            ...item,
            isOpened: !item.isOpened,
          };
        }
        return item;
      }),
    }));
  };

  sendMetric = () => {
    MetricService.send({
      action: 'click',
      actionKey: 'chargebacks.chargebackCard.recommendedDocs',
    });
  };
}

export default addTranslation(ChargebackFlow);
