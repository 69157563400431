import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ArrayMove from 'array-move';
import {
  deleteAdditionalFeature,
  getAdditionalFeaturesList,
  saveSortFeaturesList,
} from 'api/overview';
import { openModal, closeModal } from 'actions/modal';
import AdditionalFeature from './AdditionalFeature';
import { RootState, StoreProps } from 'store';
import { AdditionalFeatures, Feature } from 'reducers/additionalFeatures';
import {
  setAdditionalFeatures,
  uploadAdditionalFeatureList,
  resetFeatures,
  confirmDeleteAdditionalFeature,
  updateFeatures,
} from 'actions/additionalFeature';
import Messages from 'constants/rpcTypes';
import { addListeners } from 'decorators/addListeners';
import { WithRouterProps } from 'decorators/withRouter';

interface OwnProps {
  language: string;
  additionalFeatures: AdditionalFeatures;
}

interface State {
  features: Feature[];
  isEditing: boolean;
  isLoading: boolean;
}

type Props = OwnProps & StoreProps & WithRouterProps;

@addListeners([Messages.AdditionalFeature_Delete])
class AdditionalFeatureContainer extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      features: [],
      isEditing: false,
      isLoading: false,
    };
  }

  async componentDidMount() {
    if (!this.props.additionalFeatures.isFetched) {
      await this.fetchList();
    } else {
      this.setState({ features: this.props.additionalFeatures.list });
    }
    setTimeout(() => {
      if (this.props.location.hash) {
        //querySelector can't search numeric id
        // eslint-disable-next-line unicorn/prefer-query-selector
        document
          .getElementById(this.props.location.hash.slice(1))
          ?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.additionalFeatures.list !== this.props.additionalFeatures.list
    ) {
      this.setState({ features: this.props.additionalFeatures.list || [] });
    }

    if (this.props.language !== prevProps.language) {
      this.props.dispatch(resetFeatures());
      this.fetchList();
    }
  }

  render() {
    return (
      <AdditionalFeature
        isEditing={this.state.isEditing}
        isLoading={this.state.isLoading}
        language={this.props.language}
        features={this.state.features}
        handleSort={this.handleSort}
        onAddItem={this.openAddModal}
        onToggleEditMode={this.toggleEditMode}
        onClose={this.closeModal}
        onDelete={this.onDeleteFeature}
        handleScroll={this.handleScroll}
        onSave={this.confirmModal}
        cancelSave={this.cancelSave}
        onEditFeature={(feature) => this.openAddModal(feature)}
      />
    );
  }

  fetchList = async () => {
    const { features } = this.state;
    const params: { language: string; offset?: number } = {
      language: this.props.language,
    };
    const isUpload =
      features.length && this.props.additionalFeatures.hasMoreRows;
    if (isUpload) {
      params.offset = features.length;
    }

    if (
      !this.state.isLoading &&
      (!this.props.additionalFeatures.isFetched ||
        this.props.additionalFeatures.hasMoreRows)
    ) {
      this.setState({ isLoading: true });
      try {
        const data = await getAdditionalFeaturesList(params);
        if (!this.props.additionalFeatures.isFetched) {
          this.props.dispatch(setAdditionalFeatures(data));
        } else {
          this.props.dispatch(uploadAdditionalFeatureList(data));
        }
      } catch (e) {
        console.error('Features fetch error: ', e);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  openAddModal = (data?) => {
    this.props.dispatch(
      openModal({
        modalId: 'AddAdditionalFeature',
        content: data ? { ...data } : undefined,
      })
    );
  };

  closeModal = () => {
    this.props.dispatch(closeModal());
  };

  onDeleteFeature = (id: number) => {
    this.setState((state) => ({
      features: state.features.filter(
        (item) => item.additionalFeatureId !== id
      ),
    }));
  };

  cancelSave = () => {
    this.setState(
      { features: this.props.additionalFeatures.list },
      this.toggleEditMode
    );
  };

  confirmModal = () => {
    this.props.dispatch(
      openModal({
        modalId: 'Confirm',
        callback: (isAgree) => {
          if (isAgree) {
            this.saveChanges();
          }
        },
      })
    );
  };

  saveChanges = async () => {
    this.setState({ isLoading: true });
    try {
      await this.saveSort();
      await this.deleteFeatures();
    } finally {
      this.setState({ isLoading: false }, this.toggleEditMode);
    }
  };

  handleSort = ({ oldIndex, newIndex }) => {
    const { features } = this.state;
    const featuresList = JSON.parse(JSON.stringify(features));

    this.setState({
      features: ArrayMove(featuresList, oldIndex, newIndex),
    });
  };

  getChangedIds = () => {
    const {
      additionalFeatures: { list: prevList },
    } = this.props;
    const { features } = this.state;

    return features.reduce<
      { sortIndex: number; additionalFeatureId: number }[]
    >((result, item, i) => {
      const prevListItem = prevList[i];
      if (item.additionalFeatureId !== prevListItem.additionalFeatureId) {
        return [
          {
            sortIndex: i + 1,
            additionalFeatureId: item.additionalFeatureId,
          },
          ...result,
        ];
      }
      return result;
    }, []);
  };

  saveSort = async () => {
    const changedFeatures = this.getChangedIds();
    try {
      await saveSortFeaturesList(changedFeatures);
      this.props.dispatch(updateFeatures(this.state.features));
    } catch (e) {
      console.error('Save sorting error: ', e);
    }
  };

  deleteFeatures = async () => {
    const deletedList = this.props.additionalFeatures.list.filter((item) => {
      return !this.state.features.find(
        (feature) => feature.additionalFeatureId === item.additionalFeatureId
      );
    });
    const listToDelete = deletedList.map((item) => ({
      additionalFeatureId: item.additionalFeatureId,
    }));
    try {
      await deleteAdditionalFeature(listToDelete);
    } catch (e) {
      console.error('Delete feature error: ', e);
    }
  };

  toggleEditMode = () => {
    this.setState((state) => ({ isEditing: !state.isEditing }));
  };

  handleScroll = (data) => {
    if (data.scrollHeight - data.scrollTop - data.clientHeight === 0) {
      this.fetchList();
    }
  };

  onEvent = ({ data }) => {
    this.props.dispatch(
      confirmDeleteAdditionalFeature(data.payload.additionalFeatureId)
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  language: state.user.interfaceLang,
  additionalFeatures: state.additionalFeatures,
});

export default withRouter(connect(mapStateToProps)(AdditionalFeatureContainer));
