import React from 'react';
import EventManager from 'helpers/EventManager';
import { AnyObject } from 'types/Common';

export interface IListeners {
  onEvent: ({ name: string, data: AnyObject }) => void;
}

export const addListeners = (eventsNames: string[]) => (WrapperComponent) => {
  class ListenersDecorator extends React.Component<any> {
    private readonly ids: string[] = [];
    private readonly eventsNames: string[] = [];
    private readonly wrapped;

    constructor(props) {
      super(props);
      this.eventsNames = eventsNames;
      this.ids = [];

      this.wrapped = React.createRef();

      this.eventsNames.forEach((name) => {
        const id = EventManager.subscribe(name, this.onEvent);
        this.ids.push(id);
      });
    }

    componentWillUnmount(): void {
      this.ids.forEach((id) => {
        EventManager.unsubscribe(id);
      });
    }

    render() {
      return <WrapperComponent {...this.props} ref={this.wrapped} />;
    }

    onEvent = (name: string, data: AnyObject) => {
      const { wrapped } = this;
      if (wrapped.current && wrapped.current.onEvent) {
        wrapped.current.onEvent({ name, data });
      }
    };
  }

  return ListenersDecorator as typeof WrapperComponent;
};
