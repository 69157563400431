import React, { PureComponent } from 'react';
import Tooltip from 'react-tooltip';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Form from 'components/ui/form';
import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import RadioGroup from 'components/ui/radioGroup';
import Radio from 'components/ui/radio';
import SelectionList from 'components/ui/selectionList';
import CustomSelect from 'components/ui/customSelect';

import { checkSelectedRcsProjects } from 'pages/risks/helpers';
import WbListFields from './WbListFields';
import ListTypes from './ListTypes';
import { AnyObject } from 'types/Common';
import SelectItemType from 'types/SelectItem';
import './wbSingle.scss';

interface Props extends IntlProps {
  fields: WbListFields;
  dictionaries: {
    merchants: SelectItemType[];
  };
  isCreating: boolean;
  isRcsBinBlackEnabled: boolean;
  isRcsBinWhiteEnabled: boolean;
  isLoading: boolean;
  validationErrors: AnyObject;
  canCreate: boolean;
  onChangeField: (key: keyof WbListFields, value: any) => void;
  onCreate: () => void;
  onCancel: () => void;
}

const TOOLTIP_LOCALE = 'risks.addToList.forProject.tooltip';

class WbSingle extends PureComponent<Props> {
  componentDidMount(): void {
    Tooltip.rebuild();
  }
  componentDidUpdate(prevProps: Readonly<Props>) {
    if (!this.props.isRcsBinBlackEnabled && !this.props.isRcsBinWhiteEnabled)
      return;

    if (prevProps.fields.listType !== this.props.fields.listType) {
      Tooltip.rebuild();
    }
  }

  render() {
    const {
      fields,
      dictionaries,
      validationErrors,
      isCreating,
      isLoading,
      onChangeField,
      onCreate,
      getTranslate,
    } = this.props;

    return (
      <div className='wb-single-form'>
        <Form onSubmit={onCreate}>
          <div className='grid-row'>
            <div className='grid-column-1 wb-single-form__column wb-single-form__column_type'>
              <div className='wb-single-form__field-title'>
                {getTranslate('risks.addToList.list')}
              </div>
              <RadioGroup
                onChange={(value) => onChangeField('listType', value)}
                checkedId={fields.listType}>
                <Radio
                  id={ListTypes.blackList}
                  text={getTranslate('risks.addToList.list.black')}
                />
                <Radio
                  id={ListTypes.whiteList}
                  text={getTranslate('risks.addToList.list.white')}
                />
              </RadioGroup>
            </div>
            <div className='grid-column-11'>
              <div className='grid-row'>
                <div className='grid-column-4 wb-single-form__column'>
                  <CustomSelect
                    options={dictionaries.merchants}
                    value={fields.selectedMerchant}
                    label={getTranslate('risks.addToList.merchant')}
                    error={validationErrors.merchantId}
                    onChange={(value) =>
                      onChangeField('selectedMerchant', value)
                    }
                    isLoading={isLoading}
                    customClass='ui-select_large wb-single-form__field'
                  />
                  <Input
                    id='wb-form-email'
                    label={getTranslate('risks.addToList.email')}
                    placeholder={getTranslate(
                      'risks.addToList.email.placeholder'
                    )}
                    tooltip={getTranslate(TOOLTIP_LOCALE)}
                    value={fields.email}
                    error={validationErrors.email}
                    onChange={(event) =>
                      onChangeField('email', event.target.value)
                    }
                    maxLength={255}
                    customClass='wb-single-form__field'
                  />
                  {this.isShowBin() && (
                    <Input
                      id='wb-form-bin'
                      type='input'
                      label={getTranslate('risks.addToList.bin')}
                      placeholder={getTranslate(
                        'risks.addToList.bin.placeholder'
                      )}
                      value={fields.bin}
                      error={validationErrors.bin}
                      onChange={(event) =>
                        onChangeField('bin', event.target.value)
                      }
                      cleaveOptions={{
                        numericOnly: true,
                      }}
                      tooltip={getTranslate(
                        'risks.addToList.forMerchant.tooltip'
                      )}
                      maxLength={6}
                      customClass='wb-single-form__field'
                    />
                  )}
                </div>
                <div className='grid-column-4 wb-single-form__column'>
                  <SelectionList
                    label={getTranslate('risks.addToList.project')}
                    items={fields.projectList}
                    error={validationErrors.projectId}
                    onChange={(projects) =>
                      onChangeField('projectList', projects)
                    }
                    isValidBeforeApply={checkSelectedRcsProjects}
                    isLoading={isLoading}
                    customClass='wb-single-form__field'
                  />
                  <Input
                    id='wb-form-card'
                    label={getTranslate('risks.addToList.card')}
                    placeholder={getTranslate(
                      'risks.addToList.card.placeholder'
                    )}
                    tooltip={getTranslate(TOOLTIP_LOCALE)}
                    value={fields.pan}
                    error={validationErrors.pan}
                    maxLength={19}
                    cleaveOptions={{
                      numericOnly: true,
                    }}
                    onChange={(event) =>
                      onChangeField('pan', event.target.value)
                    }
                  />
                </div>
                <div className='grid-column-4 wb-single-form__column'>
                  <Input
                    id='wb-form-customer'
                    label={getTranslate('risks.addToList.customerID')}
                    placeholder={getTranslate(
                      'risks.addToList.customerID.placeholder'
                    )}
                    tooltip={getTranslate(TOOLTIP_LOCALE)}
                    value={fields.customer}
                    error={validationErrors.customer}
                    onChange={(event) =>
                      onChangeField('customer', event.target.value)
                    }
                    maxLength={255}
                    customClass='wb-single-form__field'
                  />
                  <Input
                    id='wb-form-ip'
                    label={getTranslate('risks.addToList.IP')}
                    placeholder={getTranslate('risks.addToList.IP.placeholder')}
                    tooltip={getTranslate(
                      'risks.addToList.forMerchant.tooltip'
                    )}
                    value={fields.ip}
                    error={validationErrors.ip}
                    maxLength={255}
                    onChange={(event) =>
                      onChangeField('ip', event.target.value)
                    }
                  />
                </div>
                <div className='grid-column-4 wb-single-form__column'>
                  <Input
                    id='wb-form-description'
                    type='textarea'
                    resize={false}
                    label={getTranslate('risks.addToList.reason')}
                    placeholder={getTranslate(
                      'risks.addToList.reason.placeholder'
                    )}
                    value={fields.comment}
                    error={validationErrors.description}
                    onChange={(event) =>
                      onChangeField('comment', event.target.value)
                    }
                    customClass='wb-single-form__reason wb-single-form__field'
                  />
                  {this.renderButtons()}
                </div>
              </div>
            </div>
          </div>
        </Form>
        {isCreating && <Loader />}
      </div>
    );
  }

  renderButtons() {
    const { canCreate, onCancel, getTranslate } = this.props;

    return (
      <div className='wb-single-form__buttons'>
        <Button
          text={getTranslate('common.cancel.button')}
          onClick={onCancel}
          status='outline'
        />
        <Button
          text={getTranslate('common.apply.button')}
          disabled={!canCreate}
          status='success'
          type='submit'
          customClass='wb-single-form__apply'
        />
      </div>
    );
  }

  isShowBin = () => {
    const { fields, isRcsBinWhiteEnabled, isRcsBinBlackEnabled } = this.props;
    return (
      (fields.listType === ListTypes.blackList && isRcsBinBlackEnabled) ||
      (fields.listType === ListTypes.whiteList && isRcsBinWhiteEnabled)
    );
  };
}

export default addTranslation(WbSingle);
