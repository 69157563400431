import React, { Component } from 'react';
import Tooltip from 'react-tooltip';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Loader from 'components/ui/loader';
import Animation from 'components/ui/animation';
import MafSteps from '../MafSteps';
import NoContent from 'components/ui/noContent';
import MafForm from 'components/maf/components/form';
import Grid from 'components/grid';
import MafHeader from '../MafHeader';
import FormControlButtons from '../formControlButtons';
import LeavingPagePrompt from 'components/maf/components/modals/leavingPagePrompt';
import MafSlider from 'components/maf/components/MafSlider';

import Overview from '../overview';

import MafHelpers from 'components/maf/MafHelpers';
import { MafTab } from 'components/maf/MafTypes';
import 'components/maf/maf.scss';
import { MafContainerProps } from 'components/maf/MafProps';
import { MafReviewInformer } from '../MafReviewInformer';

interface Props extends IntlProps, MafContainerProps {
  useModernInputs?: boolean;
  goToMafList: () => void;
  wasCommited: boolean;
}

class BusinessDocumentsForm extends Component<Props> {
  componentDidUpdate(): void {
    Tooltip.rebuild();
  }

  render() {
    const { data, fetchError, isMafFetching, getTranslate } = this.props;

    if (isMafFetching || !data) {
      return <Loader />;
    }

    if (this.props.wasCommited) {
      return <MafReviewInformer onButtonClick={this.props.goToMafList} />;
    }

    if (fetchError) {
      return (
        <div className='page-maf'>
          <NoContent text={getTranslate('add.entities.info.text')} />
        </div>
      );
    }

    return (
      <div className='page-maf'>
        <Animation>
          <div className='grid-row'>
            <div className='grid-row-item grid-column-12'>
              {this.renderContent()}
            </div>
          </div>
        </Animation>
      </div>
    );
  }

  renderContent() {
    const {
      selectedEntity,
      isUpdating,
      isCommitting,
      validationErrors,
      onChangeStep,
      currentSteps,
      canCommit,
      mafId,
      data,
      fieldState,
      onCreateFormByTemplate,
      getTabByName,
      onDeleteForm,
      onChangeField,
      onDeleteAllTabs,
      currentStep,
      onOpenModalTab,
      onCloseModalTab,
      onDeleteTab,
      onCommit,
      onBlur,
      onBackAndCancelStep,
      openedModalTabs,
      legalEntityId,
      isFormLocked,
      forseMarkStepAsFailed,
    } = this.props;

    const currentForm = this.getCurrentForm();

    const isAllowMultipleInputsPerRows = data?.boarding_status === 'daf_fill';

    return (
      <div className='maf-content'>
        <LeavingPagePrompt isEdited={this.props.isEdited} />
        <div className='maf-content__main'>
          <div className='maf-content__inner'>
            <div className='grid-row grid-column-12'>
              <Grid direction='column' className='maf-content__left-column'>
                <MafHeader selectedEntity={selectedEntity} />
                <MafSteps
                  currentStep={currentStep}
                  validationErrors={validationErrors}
                  tabs={currentSteps}
                  form={currentForm}
                  onChangeStep={onChangeStep}
                />
              </Grid>

              <div className='maf-form-wrapper'>
                <MafSlider
                  modalTabs={openedModalTabs}
                  currentStep={currentForm}
                  renderer={(formData) => {
                    return (
                      <div className='maf-form-wrapper__content'>
                        {currentStep !== currentSteps.length || formData ? (
                          <MafForm
                            fileTypes='image/jpg,image/jpeg,image/png,.pdf,.zip,.rar,.doc,.docx'
                            key={`maf-form-tab-${formData?.name}`}
                            onCreateFormByTemplate={onCreateFormByTemplate}
                            currentFormName={currentForm?.caption}
                            onDeleteTab={onDeleteTab}
                            allowMultipleInputsPerRows={
                              isAllowMultipleInputsPerRows
                            }
                            currentSteps={currentSteps}
                            currentStep={currentStep}
                            validationErrors={validationErrors}
                            onChangeField={onChangeField}
                            onDeleteAllTabs={onDeleteAllTabs}
                            getTabByName={getTabByName}
                            onDeleteForm={onDeleteForm}
                            fieldState={fieldState}
                            onOpenModalTab={onOpenModalTab}
                            withBreadCrumbs={formData?.__collapsed}
                            ignoreDuplicateHeaders={false}
                            onCloseModalTab={onCloseModalTab}
                            formData={formData}
                            isInnerTabOpen={Boolean(openedModalTabs.length)}
                            data={data}
                            mafId={mafId}
                            legalEntityId={legalEntityId}
                            onBlur={onBlur}
                            forseMarkStepAsFailed={forseMarkStepAsFailed}
                          />
                        ) : (
                          <Overview
                            validationErrors={validationErrors}
                            currentSteps={currentSteps}
                            onChangeStep={onChangeStep}
                            isVisible={fieldState.isVisible}
                            isFormLocked={isFormLocked}
                          />
                        )}
                        <FormControlButtons
                          isOverviewStep={currentSteps.length === currentStep}
                          isFirstStep={currentStep === 0}
                          isInnerTabOpen={Boolean(openedModalTabs.length)}
                          canUpdate={!isFormLocked}
                          canCommit={canCommit}
                          onCloseModalTab={onCloseModalTab}
                          onBackAndCancelStep={onBackAndCancelStep}
                          onCommit={onCommit}
                          onUpdate={this.handleUpdate}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          {(isUpdating || isCommitting) && <Loader />}
        </div>
      </div>
    );
  }

  handleUpdate = async () => {
    const { modalTab, confirm, onChangeField, fieldState, getTranslate } =
      this.props;

    if (!modalTab || !MafHelpers.isSignatorySection(this.getCurrentForm())) {
      return this.props.onUpdate();
    }

    const verifyCheckbox = modalTab.tabs[0]?.fields.find(
      (field) => field.name === 'UsrVerifyAuthorisedSignatoryDocuments'
    );

    if (!verifyCheckbox) {
      return this.props.onUpdate();
    }

    const isVerifyCheckboxDisabled = fieldState.isDisabled(
      modalTab.tabs[0],
      verifyCheckbox
    );

    if (isVerifyCheckboxDisabled) {
      return this.props.onUpdate();
    }

    const didUserCheckVerify = verifyCheckbox?.value === 'True';

    if (!didUserCheckVerify) {
      confirm({
        title: 'You Have Not Confirmed the Verification',
        text: 'Do you want to pass it now?',
        buttonYes: 'Verify Now',
        buttonNo: 'Verify Later',
      }).then((isOk) => {
        if (!isOk) {
          this.props.onUpdate();
        } else {
          onChangeField(
            modalTab.tabs[0]?.name,
            'UsrVerifyAuthorisedSignatoryDocuments',
            {
              value: 'True',
            }
          );
          const UsrUseAsSignatoryChkBx = modalTab.tabs[0]?.fields.find(
            (field) => field.name === 'UsrUseAsSignatory'
          );

          if (
            UsrUseAsSignatoryChkBx &&
            fieldState.isVisible(modalTab.tabs[0], UsrUseAsSignatoryChkBx)
          ) {
            onChangeField(modalTab.tabs[0]?.name, 'UsrUseAsSignatory', {
              value: 'True',
            });
          }
        }
      });
    } else {
      confirm({
        title: getTranslate('modals.businessDocs.signatorySending.title'),
        text: getTranslate('modals.businessDocs.signatorySending.text'),
        buttonYes: getTranslate('common.send.button'),
        buttonNo: getTranslate('common.cancel.button'),
      }).then((isOk) => {
        if (isOk) {
          this.props.onUpdate();
        }
      });
    }
  };

  getCurrentForm(): MafTab {
    const { currentStep, currentSteps } = this.props;
    return currentSteps[currentStep];
  }
}

export default addTranslation(BusinessDocumentsForm);
