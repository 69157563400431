import DateHelpers from 'helpers/Date';
import Repository from 'helpers/Repository';

export const getLatestDate = (date?) => {
  const timezone = Repository.get('store').getState().user.timezone;
  const currentDateStr = DateHelpers.getFormat(
    DateHelpers.getDateWithTimezone(timezone),
    'datetime'
  );

  if (!date) {
    return currentDateStr;
  }

  const selectedDate = DateHelpers.createDate(date, 'datetime');
  const currentDate = DateHelpers.createDate(currentDateStr, 'datetime');

  if (currentDate.valueOf() > selectedDate.valueOf()) {
    return DateHelpers.getFormat(currentDate, 'datetime');
  }
  return DateHelpers.getFormat(selectedDate, 'datetime');
};
