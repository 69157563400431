import React from 'react';
import ModalContent from 'components/modal/components/ModalContent';
import ModalFooter from 'components/modal/components/ModalFooter';
import Button from 'components/ui/button';
import DropZone from 'components/ui/dropzone';
import ModalTitle from 'components/modal/components/ModalTitle';
import { CSVToArray, CSVArrayToObject } from 'helpers/csvParsers';
import './csvUpload.scss';

type FileData = string[][] | null;
interface Props {
  callback: (fileData: FileData) => void;
}

interface State {
  file: File | null;
  fileData: FileData;
}

export default class CSVUpload extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileData: null,
    };
  }

  render() {
    const { file } = this.state;
    return (
      <div className='csv-upload'>
        <ModalTitle>Select File</ModalTitle>
        <ModalContent>
          <DropZone
            noClick={true}
            file={file}
            acceptFiles='.csv'
            onDrop={(files: any) => {
              this.onChangeFile(files);
            }}
            onDeleteFile={this.onDeleteFile}
          />
        </ModalContent>
        <ModalFooter>
          <Button text='Apply' status='success' onClick={this.apply} />
        </ModalFooter>
      </div>
    );
  }
  onChangeFile = (files) => {
    const file = files[0];
    if (file) {
      this.setState({ file });
    }

    const reader = new FileReader();
    reader.onload = () => {
      // Parse CSV file
      this.setState({ fileData: CSVArrayToObject(CSVToArray(reader.result)) });
    };
    files.forEach((f) => reader.readAsBinaryString(f));
  };

  onDeleteFile = () => {
    this.setState({ file: null });
  };

  apply = () => {
    const { callback } = this.props;
    callback(this.state.fileData);
  };
}
