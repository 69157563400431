const getSelectionListItems = ({
  originalList,
  selectedList,
  keyId = 'id',
  getText,
  isReadyOnly,
}: {
  originalList: any[];
  selectedList: any[];
  getText?: (item: any) => string;
  keyId?: string;
  isReadyOnly?: boolean;
}) => {
  return originalList.map((item: any) => {
    const result: any = {
      id: item[keyId],
      text: getText ? getText(item) : item.text,
      isSelected: !!selectedList.find((sItem) => {
        if (['number', 'string'].includes(typeof sItem)) {
          return sItem === item[keyId];
        }
        return sItem[keyId] === item[keyId];
      }),
      isDisabled: Boolean(isReadyOnly),
      isFavorite: item.isFavorite,
    };

    if (item.group) {
      result.group = `reports.new.columns.${item.group}.label`;
    }

    return result;
  });
};

export default getSelectionListItems;
