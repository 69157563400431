import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Input from 'components/ui/input';
import Button from 'components/ui/button';
import RoundButton from 'components/ui/roundButton';
import Form from 'components/ui/form';
import Loader from 'components/ui/loader';

interface Props extends IntlProps {
  isEnabled: boolean;
  isSaltSpecified: boolean;
  editSalt: boolean;
  salt: string;
  error: string;
  canSaveSalt: string;
  isEditing: boolean;
  isLoading: boolean;
  onChangeField: (name, value) => void;
  onSubmit: () => void;
  onEdit: () => void;
  preDeleteSalt: () => void;
  inputRef: any;
}

const SaltSettings: React.VFC<Props> = ({
  isEnabled,
  isSaltSpecified,
  salt,
  error,
  canSaveSalt,
  isEditing,
  isLoading,
  inputRef,
  onChangeField,
  onSubmit,
  onEdit,
  preDeleteSalt,
  editSalt,
  getTranslate,
}) => (
  <Form customClass='salt-settings' onSubmit={onSubmit}>
    {isLoading && <Loader />}
    <div className='page-projects__row page-projects__row_salt' ref={inputRef}>
      {!isEditing ? (
        <div className='page-projects__hidden utils-hidden'>
          {getTranslate('projects.salt.hidden')}
        </div>
      ) : (
        <Input
          onChange={(e) => onChangeField('salt', e.target.value)}
          value={salt}
          id='salt'
          placeholder={
            isSaltSpecified && !isEditing
              ? getTranslate('projects.categories.salt.text')
              : getTranslate('projects.categories.salt.placeholder')
          }
          customClass='page-projects__block-value-item'
          disabled={!isEnabled || !isEditing}
          error={error}
        />
      )}
      {isSaltSpecified && editSalt && (
        <>
          <RoundButton
            icon='im-Edit1'
            iconSize={11}
            onClick={onEdit}
            status='primary'
            customClass='ui-button_no-border salt-settings_btn'
            disabled={!isEnabled}
          />
          <RoundButton
            icon='im-Basket'
            iconSize={11}
            onClick={preDeleteSalt}
            status='danger'
            customClass='ui-button_no-border salt-settings_btn'
            disabled={!isEnabled}
          />
        </>
      )}
    </div>
    {editSalt && isEditing && (
      <div className='page-projects__block-footer'>
        <Button
          type='submit'
          text={getTranslate('common.save.button')}
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          status='primary'
          disabled={!isEnabled || !canSaveSalt}
        />
      </div>
    )}
  </Form>
);

export default addTranslation(SaltSettings);
