import React, { Component } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Form from 'components/ui/form';
import Link from 'components/ui/link';
import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import FormField from 'components/formField';
import { scrollToError } from 'components/formFields/helpers';
import FormFieldsProps from './FormFieldsProps';
import { FormField as FormFieldType } from './types';
import './formFields.scss';

interface Props extends FormFieldsProps, IntlProps {
  loadMore?: (name: string) => void;
  isAdditionalLoading?: boolean;
}

class FormFields extends Component<Props> {
  render() {
    const { getTranslate } = this.props;
    const extraSections =
      this.props.fieldsConfig &&
      Object.keys(this.props.fieldsConfig).filter((key) => key !== 'general');
    return (
      <>
        {this.props.isLoading && <Loader />}
        <Form customClass='ui-form-fields__form' onSubmit={this.onSubmit}>
          <div className='ui-form-fields__container ui-form-fields__container_content ui-form-fields__content'>
            {this.props.text && (
              <div className='ui-form-fields__text'>
                {getTranslate(this.props.text)}
              </div>
            )}
            <div className='ui-form-fields__inner'>
              <div className='ui-form-fields__col ui-form-fields__col_general'>
                <div className='ui-form-fields__col-title'>
                  {getTranslate(this.props.generalTitle)}
                </div>
                {this.renderFields(this.props.fieldsConfig?.general || [])}
              </div>
              <div className='ui-form-fields__col ui-form-fields__col_additional'>
                <div className='ui-form-fields__col-title'>
                  {getTranslate(this.props.additionalTitle)}
                </div>
                {extraSections?.length
                  ? extraSections.map((section) => {
                      return this.renderFields(
                        this.props.fieldsConfig?.[section]
                      );
                    })
                  : null}
              </div>
            </div>
          </div>

          <div className='ui-form-fields__container ui-form-fields__container_footer utils-flex'>
            <Link
              url={this.props.backUrl || ''}
              className='ui-form-fields__back-btn ui-button ui-submitted-form__button ui-button_status-outline ui-button_size-normal'>
              {getTranslate(this.props.backText)}
            </Link>
            <Button
              disabled={!this.props.canCreate}
              text={getTranslate(
                this.props.status
                  ? this.props.repeatCreateButtonText
                  : this.props.createButtonText
              )}
              loading={this.props.isLoading}
              status='primary'
              type='submit'
              customClass='ui-form-fields__button'
            />
          </div>
        </Form>
      </>
    );
  }

  renderFields = (fieldsList: Array<FormFieldType | FormFieldType[]>) => {
    const options = {
      fieldsValues: this.props.fieldsValues,
      dictionaries: this.props.dictionaries,
      fieldsState: this.props.fieldsState,
      hiddenFields: this.props.hiddenFields,
      validationErrors: this.props.validationErrors,
      onChange: this.props.onChange,
      loadMore: this.props.loadMore,
      isLoading: this.props.isLoading,
      formClassName: 'ui-form-fields',
    };
    return fieldsList.map((field, i) => {
      if (Array.isArray(field)) {
        return (
          <div className='ui-form-fields__item utils-flex' key={`inner-${i}`}>
            {field.map((innerField) => {
              return (
                <FormField
                  field={innerField}
                  options={options}
                  key={innerField.id}
                />
              );
            })}
          </div>
        );
      }
      return <FormField field={field} options={options} key={field.id} />;
    });
  };

  onSubmit = async () => {
    try {
      await this.props.onSubmit?.();
    } finally {
      scrollToError(this.props.validationErrors);
    }
  };
}

export default addTranslation(FormFields);
