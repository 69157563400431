import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Panel from 'components/ui/panel';

import './menuContext.scss';

interface Props {
  onClick?: any;
  value?: {
    buttonContent?: any;
    rawContent?: any;
  };
  list: any;
  name?: string;
  customClass?: string;
  openPosition?: string;
  error?: string;
}

interface State {
  isOpen: boolean;
}

class MenuContext extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }
  static defaultProps = {
    selectable: false,
  };
  render() {
    const { value, name, error, customClass, openPosition } = this.props;
    const { isOpen } = this.state;
    if (!value) {
      return null;
    }

    const classes = classNames('menu-context', customClass, {
      'menu-context_opened': isOpen,
      [`menu-context_${name}`]: !!name,
      [`menu-context_error`]: !!error,
      [`menu-context_open-${openPosition}`]: !!openPosition,
    });
    return (
      <div className={classes} onClick={() => this.toggleList(!isOpen)}>
        {value.buttonContent && (
          <button type='button' className='menu-context__btn'>
            {value.buttonContent}
          </button>
        )}
        {value.rawContent}
        {error && <span className='menu-context__error'>{error}</span>}
        {this.renderList()}
      </div>
    );
  }

  renderList = () => {
    const { list, name, openPosition } = this.props;
    const { isOpen } = this.state;

    return (
      <Panel
        isOpened={isOpen}
        renderCloseControl={false}
        animationDuration={300}
        customClass={classNames('ui-panel_menu-context', {
          [`ui-panel_${name}`]: !!name,
        })}
        excludeFromCloseTrigger={['.menu-context_opened']}
        onClose={() => this.toggleList(false)}
        animationNameShow={openPosition && 'none'}
        animationNameHide={openPosition && 'none'}>
        <div className='menu-context__list'>
          {list.map((item) => {
            return (
              <div
                className={classNames('menu-context__item', item.customClass, {
                  'menu-context__item_active': item.active,
                  'menu-context__item_disabled': item.disabled,
                })}
                key={item.id}
                onClick={() => item.onClick(item)}>
                {item.content}
              </div>
            );
          })}
        </div>
      </Panel>
    );
  };

  toggleList = (isOpen: boolean): void => {
    this.setState({
      isOpen,
    });
  };
}
export default MenuContext;
