import { chartParams as OperationsChartParams } from 'components/modal/modalList/graphDetails/components/settingsForm/OperationsFormConfig';
import { InOutTableGroupByParams } from '../components/settingsForm/InOutFormConfig';
import { InOutSalesAgentsTableGroupByParams } from '../components/settingsForm/InOutSalesAgentsFormConfig';
import DateHelpers from 'helpers/Date';
import { AdvancedAnalyticsEntityType } from '../components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';
import { topTenChartParams } from 'components/modal/modalList/graphDetails/components/settingsForm/Top10FormConfig';
import { DeclineReasonsParams } from 'components/modal/modalList/graphDetails/components/settingsForm/DeclineReasonsFormConfig';
import { FraudReportParams } from 'components/modal/modalList/graphDetails/components/settingsForm/FraudReportFormConfig';
import { IFormValues } from 'types/Analytics';
import { ChargebackParams } from 'components/modal/modalList/graphDetails/components/settingsForm/ChargebackFormConfig';
import { PaymentChartParams } from 'components/modal/modalList/graphDetails/components/settingsForm/PaymentsFormConfig';
import { PaymentSalesAgentsChartParams } from 'components/modal/modalList/graphDetails/components/settingsForm/PaymentsSalesAgentsFormConfig';
import { DeclineCodesChartParams } from 'components/modal/modalList/graphDetails/components/settingsForm/DeclineCodesFormConfig';

const TYPE_TO_PARAMS_MAP = {
  [AdvancedAnalyticsEntityType.operations]: OperationsChartParams,
  [AdvancedAnalyticsEntityType.payments]: PaymentChartParams,
  [AdvancedAnalyticsEntityType.payments_sa]: PaymentSalesAgentsChartParams,
  [AdvancedAnalyticsEntityType.inout]: InOutTableGroupByParams,
  [AdvancedAnalyticsEntityType.inout_sales_agents]: InOutSalesAgentsTableGroupByParams,
  [AdvancedAnalyticsEntityType.topten]: topTenChartParams,
  [AdvancedAnalyticsEntityType.declineCodes]: DeclineCodesChartParams,
  [AdvancedAnalyticsEntityType.declineReasons]: DeclineReasonsParams,
  [AdvancedAnalyticsEntityType.fraud_report]: FraudReportParams,
  [AdvancedAnalyticsEntityType.chargeback]: ChargebackParams,
};

const filterOptionsWithPermissions = (options) =>
  options.filter((option) =>
    option.permissionCallback !== undefined ? option.permissionCallback() : true
  );

export class ServiceSettingsFormHelpers {
  static getOptions(field, values) {
    const { options, conditionalOptions } = field;
    const _options = filterOptionsWithPermissions(options);

    if (conditionalOptions) {
      const _conditionalOptions =
        filterOptionsWithPermissions(conditionalOptions);

      return _conditionalOptions
        .reduce((result, conditionalOptionsItem) => {
          if (conditionalOptionsItem.condition(values)) {
            result = result.concat(conditionalOptionsItem.data);
          }
          return result;
        }, _options)
        .sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          return 1;
        });
    }

    return _options;
  }

  static adjustOptionalFieldsValues({
    values,
    chartType,
  }: {
    values: IFormValues;
    chartType: AdvancedAnalyticsEntityType;
  }) {
    const params = TYPE_TO_PARAMS_MAP[chartType];

    params.forEach((field) => {
      const { id, renderOptions } = field;

      if (field.conditionalOptions) {
        const fieldOptions = ServiceSettingsFormHelpers.getOptions(
          field,
          values
        );
        if (
          !fieldOptions.find((f) => f.value === values[id]) &&
          field.type === 'singleSelect'
        ) {
          values[id] = fieldOptions[0]?.value || '';
        }
      }
      if (renderOptions?.disabled?.condition(values)) {
        values[id] = renderOptions.disabled.fallBackValue.value;
      }
    });

    return values;
  }

  static formatDate(values) {
    const { period } = values;

    return ['from', 'to'].reduce((acc, value) => {
      acc[value] = period
        ? DateHelpers.getFormat(
            DateHelpers.createDate(period[value], 'datetime'),
            'noTZCommon'
          )
        : '';
      return acc;
    }, {});
  }
}
