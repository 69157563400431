import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import api from 'api/user';
import showNotification from 'components/ui/notification/showNotification';
import ChangePassword from './ChangePassword';
import { AnyObject } from 'types/Common';
import MetricService from 'helpers/metricService/MetricService';
import { StoreProps } from 'store';

interface OwnProps {
  onChangeSuccess: () => void;
}

interface ConnectedProps {
  user: any;
}

type Props = OwnProps & ConnectedProps & StoreProps & IntlProps;

interface State {
  isChanging: boolean;
  validationErrors: AnyObject;
}

class ChangePasswordContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isChanging: false,
      validationErrors: {},
    };
  }

  render() {
    const { user } = this.props;
    const { isChanging, validationErrors } = this.state;

    return (
      <ChangePassword
        user={user}
        validationErrors={validationErrors}
        isChanging={isChanging}
        onChangeField={this.resetErrors}
        onChangePassword={(data) => this.changePassword(data)}
      />
    );
  }

  resetErrors = () => {
    const { validationErrors } = this.state;
    if (Object.keys(validationErrors).length) {
      this.setState({ validationErrors: {} });
    }
  };

  async changePassword(data) {
    const { onChangeSuccess, getTranslate } = this.props;

    this.setState({ isChanging: true, validationErrors: {} });

    try {
      MetricService.send({
        actionKey: 'userProfile.changePassword.confirm',
        action: 'click',
      });
      await api.changePassword(data);
      showNotification({
        status: 'success',
        content: getTranslate('profile.changepass.success.popup'),
      });
      onChangeSuccess();
    } catch ({ payload }) {
      if (payload?.validationErrors) {
        this.setState({
          validationErrors: payload.validationErrors,
        });
      }
    } finally {
      this.setState({ isChanging: false });
    }
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
});

export default connect(mapStateToProps)(
  addTranslation(ChangePasswordContainer)
);
