import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 *
 * @param params
 */
export const getInvoices = (params = {}): Promise<any> => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Invoice_List,
          payload: { ...params },
        },
      ],
      isCancelable: true,
    })
    .then((data: any) => ({
      items: data.rows,
      totalRows: Infinity,
    }));
};

/**
 *
 * @param params
 */
export const createInvoice = (params): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Invoice_Create,
        payload: { ...params },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 *
 * @param params
 */
export const cancelInvoice = (params): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Invoice_Cancel,
        payload: { ...params },
      },
    ],
  });
};

export const getFormConfig = (payload: {
  projectId: string;
  isSubscription: boolean;
}): Promise<any> => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Invoice_FormConfig,
          payload,
        },
      ],
    })
    .catch((e) => ({
      paymentMethods: [],
    }));
};

export const createSubscriptionInvoice = (params): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Invoice_CreateSubscription,
        payload: { ...params },
      },
    ],
    showValidateNotification: false,
  });
};
