import React, { CSSProperties, PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { throttle } from 'lodash';

import { addTranslation, IntlProps } from 'decorators/addTranslation';

import { StoreProps } from 'store';
import { saveUI } from 'actions/ui';
import Animation from 'components/ui/animation';
import { Column, Row } from 'components/ui/table';
import Loader from 'components/ui/loader';
import NoContent from 'components/ui/noContent';
import SmartList from 'components/ui/smartList';
import TableHeaders from 'components/tableHeaders';

import { DOUBLE_CLICK_DELAY, TABLE_ROW_HEIGHT } from 'constants/ui';
import tableNames from 'constants/tableNames';
import scrollDirections from 'constants/scrollDirections';
import { OptionsTypes } from 'helpers/metricService/metricTypes';
import getConfigurationByName from 'selectors/getConfigurationByName';
import getUIByName from 'selectors/getUIByName';
import ItemConfiguration from 'types/ItemConfiguration';
import { AnyObject } from 'types/Common';
import MetricService from 'helpers/metricService/MetricService';
import { isEmpty, parseColumnsWidthsFromHeader } from './helpers';
import { addResize, ResizeProps } from 'decorators/addResize';

import './table.scss';
import { SortingType } from '../../../types/sortings';

interface OwnProps {
  loadNextPage?: any;
  items: AnyObject[];
  clickToItem?: (item) => void;
  totalItems?: number;
  isFetch?: boolean;
  isListView?: boolean;
  resizableColumns?: boolean;
  offset?: number;
  tableName: tableNames;
  canViewItem?: boolean;
  customClass?: string;
  rowRender?: any;
  headerRender?: any;
  noContentText?: string;
  outerSort?: any;
  minColumnsWidth?: AnyObject;
  onSort?: ({ field, order }: { field: string; order: SortingType }) => void;
  sendMetric?: boolean;
  forbidResizeFor?: string[];
  customConfiguration?: { id: string }[];
}

interface ConnectedProps {
  configuration: ItemConfiguration[];
  ui: any;
}

type Props = OwnProps & ConnectedProps & StoreProps & IntlProps & ResizeProps;

interface State {
  tableHeight: NonNullable<CSSProperties['height']>;
  showTable: boolean;
  rowClicked?: {
    id: number;
    count: number;
  };
}

const SEARCH_BLOCK_HEIGHT = 83;
@addResize
class DynamicTable extends PureComponent<Props, State> {
  private scrollbarRef;
  private headerRef;
  private tableRef;
  private doubleClickTimer;
  private readonly isTableReopened;

  static defaultProps: Readonly<Partial<OwnProps>> = {
    canViewItem: true,
    sendMetric: false,
    noContentText: 'common.noMatches.text',
  };

  constructor(props) {
    super(props);

    this.state = {
      tableHeight: 0,
      showTable: false,
    };

    this.calcTableHeight = throttle(this.calcTableHeight, 300);
    this.sendMetric = throttle(this.sendMetric, 1000);
    this.isTableReopened = props.items.length;
  }

  render() {
    const { tableHeight, showTable } = this.state;
    const {
      items,
      loadNextPage,
      totalItems,
      ui,
      configuration,
      isFetch,
      tableName,
      resizableColumns,
      customClass,
      headerRender,
      noContentText,
      getTranslate,
      outerSort,
      onSort,
      minColumnsWidth,
      updateWidth,
      width,
      setRefForResize,
      forbidResizeFor,
      setWidth,
    } = this.props;
    const isFirstColumnFixed = configuration[0] && configuration[0].fixed;
    const fixedColumnWidth = isFirstColumnFixed && width?.[configuration[0].id];

    return (
      <div
        id='ui-table'
        className={classNames('ui-table', customClass, {
          'ui-table_fixed': isFirstColumnFixed,
          'ui-table_resizable': resizableColumns,
          'ui-table_empty': items.length === 0,
        })}
        ref={(el) => {
          this.tableRef = el;
        }}>
        <div
          className='ui-table__inner'
          style={{ height: tableHeight, opacity: showTable ? 1 : 0 }}>
          <Animation>
            <SmartList
              getCustomContentScrollWidth={this.getCustomContentScrollWidth}
              tooltip={true}
              setWidth={setWidth}
              initialScroll={ui}
              list={items}
              totalCount={totalItems || 9999}
              loadNextPage={
                loadNextPage ? (offset) => loadNextPage({ offset }) : undefined
              }
              fixedColumnWidth={fixedColumnWidth || 0}
              rowRenderer={this.renderRowWithProps}
              headerRender={
                this.setTopPositionToCustomHeader(headerRender) || (
                  <TableHeaders
                    forbidResizeFor={forbidResizeFor}
                    setHeaderRef={this.setHeaderRef}
                    minColumnsWidth={minColumnsWidth}
                    width={width}
                    resizableColumns={resizableColumns}
                    updateWidth={updateWidth}
                    tableHeight={tableHeight}
                    tableName={tableName}
                    outerSort={outerSort}
                    onSort={onSort}
                  />
                )
              }
              onScroll={this.handleScroll}
              onScrollStop={this.sendMetric}
              hideHorizontal={false}
              getRef={(el) => {
                this.scrollbarRef = el;
                if (setRefForResize) {
                  setRefForResize({ scrollBarRef: el });
                }
              }}
              contentId='ui-table-content'
              noContent={
                isFetch ? (
                  <div />
                ) : (
                  <NoContent
                    className='ui-table__no-content'
                    text={getTranslate(noContentText)}
                  />
                )
              }
              onReady={this.showTable}
            />
          </Animation>
        </div>
        {isFetch && <Loader />}
      </div>
    );
  }

  componentDidMount(): void {
    this.calcTableHeight(this.isTableReopened);
    this.forceUpdate();
    this.attachEvents();
  }

  componentDidUpdate(prevProps): void {
    const { configuration: currConfig, tableName } = this.props;
    const { configuration: prevConfig } = prevProps;
    if (
      prevConfig[0] &&
      currConfig[0] &&
      prevConfig[0].fixed !== currConfig[0].fixed
    ) {
      this.reloadHorizontalScroll();
    }
    const itemsBefore = prevProps.items.length;
    const itemsNow = this.props.items.length;

    if (itemsBefore !== itemsNow) {
      this.calcTableHeight();
    }
    if (
      this.props.resizableColumns &&
      prevConfig.length &&
      currConfig.length !== prevConfig.length &&
      prevProps.tableName === tableName
    ) {
      this.handleConfigurationChange();
    }
  }

  componentWillUnmount(): void {
    const { dispatch, tableName } = this.props;

    // This condition is necessary when the table is empty
    if (this.scrollbarRef) {
      dispatch(
        saveUI(tableName, {
          scrollTop: this.scrollbarRef.scrollTop,
          scrollLeft: this.scrollbarRef.scrollLeft,
        })
      );
    }
    this.detachEvents();
  }

  attachEvents = () => {
    window.addEventListener('resize', this.calcTableHeight);
  };

  detachEvents = () => {
    window.removeEventListener('resize', this.calcTableHeight);
  };

  renderRowWithProps = (data, index) => {
    const { rowRender, width, forbidResizeFor } = this.props;
    const renderer = rowRender || this.rowRender;

    return renderer(
      {
        ...data,
        columnWidths: width,
        forbidResizeFor,
        handleDoubleClick: this.handleDoubleRowClick(data, index),
      },
      index
    );
  };

  handleDoubleRowClick = (data, rowIndex) => (onItemClick: Function) => {
    const { rowClicked } = this.state;
    const { count = 0, id } = rowClicked || {};
    this.setState({
      rowClicked: { id: rowIndex, count: id !== rowIndex ? 1 : count + 1 },
    });
    if (!count || id !== rowIndex) {
      clearTimeout(this.doubleClickTimer);
    }
    this.doubleClickTimer = setTimeout(() => {
      const selectedArea = document.getSelection();
      const selectedText = selectedArea && Boolean(selectedArea.toString());
      if (selectedText) {
        return;
      }
      onItemClick && onItemClick(data);
    }, DOUBLE_CLICK_DELAY);
  };

  rowRender = ({ handleDoubleClick, ...data }) => {
    const { configuration, canViewItem, clickToItem } = this.props;
    const { columnWidths, ...columnsData } = data;

    let rowId = '';
    if (data.projectId && data.transactionId) {
      rowId = `data-${data.projectId}-${data.transactionId}`;
    }

    return (
      <Row
        id={rowId}
        onClick={() => handleDoubleClick(clickToItem)}
        customClass={classNames({
          'ui-table__row_readonly': !canViewItem,
          'ui-table__row_clickable': clickToItem,
        })}
        needAnimate={data.needAnimate}
        status={data.status || data.paymentStatus}>
        {configuration.map((config) => {
          const { id } = config;
          return (
            <Column
              columnWidths={columnWidths}
              id={id}
              modifier={id}
              key={`${data[id]}_${id}`}
              customClass={classNames({
                'ui-table__column_fixed': config.fixed,
              })}
              params={{
                ...config,
                valueId: id,
                valueType: config.valueType,
              }}
              content={data[id]}
              data={columnsData}
            />
          );
        })}
      </Row>
    );
  };

  getCustomContentScrollWidth = () => {
    const { width, configuration } = this.props;
    let contentWidth = 0;
    if (width) {
      configuration.forEach((item) => {
        if (item.active) {
          contentWidth += Number(width[item.id]) || 0;
        }
      });
    }
    return contentWidth;
  };

  setTopPositionToCustomHeader = (header) => {
    const { tableHeight } = this.state;
    const {
      resizableColumns,
      updateWidth,
      width,
      forbidResizeFor,
      minColumnsWidth,
    } = this.props;
    if (header) {
      const props: any = {
        style: { ...header.props?.style },
      };

      if (resizableColumns) {
        props.childrenProps = {
          columnWidths: width,
          updateWidth,
          forbidResizeFor,
          minColumnsWidth,
          tableHeight,
        };
        props.setHeaderRef = this.setHeaderRef;
      }
      return React.cloneElement(header, props);
    }
    return null;
  };

  calcTableHeight = (isTableReopened?) => {
    const { items, isListView }: any = this.props;

    if (!this.tableRef) return;

    if (items.length === 0) {
      this.setState({
        tableHeight: 'auto',
      });
      return;
    }

    let total;
    let maxHeight;
    let horizontalScrollHeight: number = 0;

    const paddingBottom = 40;
    const rowsHeight = TABLE_ROW_HEIGHT;
    const tableHeader = 42;
    const scrollRef = this.tableRef.closest('.ui-scrollbar');
    if (scrollRef?.querySelector('.trackXVisible')) {
      horizontalScrollHeight = 8;
    }

    const height =
      rowsHeight * items.length + tableHeader + horizontalScrollHeight;

    // Такая высота необходима когда нет данных и выводится NoContent
    if (isListView) {
      total = height;
    } else {
      const offset = this.tableRef.getBoundingClientRect();
      maxHeight = window.innerHeight - offset.top - paddingBottom;

      // Дополнительные расчеты спрятанного блока необходимы при переключении между страницами
      // и открытым блоком поиска
      if (isTableReopened) {
        maxHeight += SEARCH_BLOCK_HEIGHT;
      }
      total = height > maxHeight && maxHeight > 0 ? maxHeight : height;
    }

    this.setState({
      tableHeight: total,
    });
  };

  /***
   * При переключении режима просмотра таблицы (фиксированная первая колонка),
   * мы изменяем размеры и позицию области, в которой работает скролл,
   * поэтому нужно пересчитать размеры для элементов track и thumb.
   * Один из способов это сделать - изменить положение скролла, с помощью
   * scrollLeft.
   * Особенность в том, что пересчет произойдет, только если новое положение
   * scrollLeft отличное от текушего, поэтому нужно сетить 0 или 1.
   * Например, если переключиться из обычного режима со scrollLeft = 0
   * в фиксированный, то повторный сеттинг scrollLeft = 0, ничего не даст и
   * размеры будут кривые.
   */
  reloadHorizontalScroll = (): void => {
    const { scrollbarRef } = this;
    const { width, setWidth } = this.props;
    if (isEmpty(width) && setWidth) {
      setWidth(parseColumnsWidthsFromHeader(this.headerRef), () => {
        this.forceUpdate();
      });
    }
    if (scrollbarRef) {
      scrollbarRef.scrollLeft = this.scrollbarRef.scrollLeft > 0 ? 0 : 1;
    }
  };

  handleScroll = () => {
    this.forceUpdate();
  };

  sendMetric = ({ direction }) => {
    const { sendMetric } = this.props;
    if (!sendMetric) return;

    const { tableName } = this.props;

    const options: OptionsTypes =
      direction === scrollDirections.vertical
        ? {
            action: 'scrollVertical',
            actionKey: `${tableName}.registry.verticalScroll`,
          }
        : {
            action: 'scrollHorizontal',
            actionKey: `${tableName}.registry.horizontalScroll`,
          };

    MetricService.send(options);
  };

  showTable = () => {
    this.setState({ showTable: true });
  };

  setHeaderRef = (ref) => {
    const { setRefForResize } = this.props;
    this.headerRef = ref;
    if (setRefForResize) {
      setRefForResize({ headerRef: ref });
    }
  };

  handleConfigurationChange = () => {
    const { setWidth } = this.props;
    setWidth &&
      setWidth(undefined, () => {
        this.forceUpdate();
      });
  };
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => ({
  configuration: getConfigurationByName(state, ownProps.tableName),
  ui: getUIByName(state, ownProps.tableName),
});

export default connect(mapStateToProps)(addTranslation(DynamicTable));
