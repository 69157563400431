import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import DynamicTable from 'components/ui/table/DynamicTable';
import { Column, Header, Row } from 'components/ui/table';

import { QuickCurrencyRatesFiltersType } from 'types/QuickFilters';
import tableNames from 'constants/tableNames';

import './currencyRates.scss';

const SHOW_EUR_CURRENCIES = ['BGN'];

interface Props extends IntlProps {
  filters: QuickCurrencyRatesFiltersType;
  data: any;
}

class CurrencyRates extends PureComponent<Props> {
  getConfiguration() {
    const { filters } = this.props;

    const configuration = [
      {
        id: 'actualDate',
      },
      {
        id: 'fromUSD',
      },
      {
        id: 'toUSD',
      },
    ];

    if (SHOW_EUR_CURRENCIES.includes(filters.currency) === true) {
      return [
        ...configuration,
        {
          id: 'fromEUR',
        },
        {
          id: 'toEUR',
        },
      ];
    }

    return configuration;
  }

  getTableColumns() {
    const { filters, getTranslate } = this.props;
    const columns = [
      {
        key: 'actualDate',
        header: getTranslate('currencyRates.table.actualDate'),
      },
      {
        key: 'fromUSD',
        header: `${getTranslate('currencyRates.table.buyPrefix')} USD/${
          filters.currency
        }`,
      },
      {
        key: 'toUSD',
        header: `${getTranslate('currencyRates.table.sellPrefix')} USD/${
          filters.currency
        }`,
      },
    ];

    if (SHOW_EUR_CURRENCIES.includes(filters.currency) === true) {
      return [
        ...columns,
        {
          key: 'fromEUR',
          header: `${getTranslate('currencyRates.table.buyPrefix')} EUR/${
            filters.currency
          }`,
        },
        {
          key: 'toEUR',
          header: `${getTranslate('currencyRates.table.sellPrefix')} EUR/${
            filters.currency
          }`,
        },
      ];
    }

    return columns;
  }

  renderTableHeader() {
    const columns = this.getTableColumns();

    return (
      <Header>
        {columns.map(({ key, header }) => (
          <Column id={key} key={key} content={header} modifier={key} />
        ))}
      </Header>
    );
  }

  renderRow(row) {
    const columns = this.getTableColumns();

    return (
      <Row>
        {columns.map(({ key }) => (
          <Column
            id={key}
            key={key}
            content={row[key] || '—'}
            modifier={key}
            columnWidths={row.columnWidths}
          />
        ))}
      </Row>
    );
  }

  render() {
    const { data } = this.props;
    if (!data?.isFetched) {
      return null;
    }

    return (
      <div className='currency-rates'>
        <DynamicTable
          tableName={tableNames.currencyRates}
          items={data.items}
          rowRender={(row) => this.renderRow(row)}
          headerRender={this.renderTableHeader()}
          customConfiguration={this.getConfiguration()}
        />
      </div>
    );
  }
}

export default addTranslation(CurrencyRates);
