import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { IntlProps, addTranslation } from 'decorators/addTranslation';
import classNames from 'classnames';

import Button from 'components/ui/button';
import Panel from 'components/ui/panel';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import Messages from 'constants/rpcTypes';
import permissionReasons from 'constants/permissionReasons';
import MultiSelect from '../../../../components/filters/list/multiSelect';

import { loadDictionary } from '../../../../api/dictionaries';

import './share-chart.scss';
import { shareCharts } from '../../../../actions/analytics/shareChartsFeature';
import Loader from '../../../../components/ui/loader';
import { forEach, isEmpty } from 'lodash';
import { StoreProps } from '../../../../store';

interface OwnProps {
  name: string;
  selectedChartsIds: any;
  afterApply: () => void;
}

type Props = OwnProps & IntlProps & WithPermissions & StoreProps;

interface State {
  showConfigPanel: any;
  selectedUsers: any;
  users: any;
  isLoading: boolean;
}

export interface Item {
  id: string;
  text: string;
  isSelected?: boolean;
}

class ShareButton extends PureComponent<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      showConfigPanel: false,
      selectedUsers: [],
      users: [],
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    const { elements } = await loadDictionary(
      {
        name: 'UsersListToShareChartWith',
      },
      false
    );

    this.setState({ selectedUsers: elements, isLoading: false });
  };

  render() {
    const { showConfigPanel, isLoading, selectedUsers } = this.state;
    const { getTranslate } = this.props;

    if (!this.canRenderButton()) return null;

    return (
      <>
        <Button
          id='share-charts-button'
          status='light'
          onClick={this.togglePanel}
          icon='share'
          customClass={classNames(
            'button-open-share-chart ui-button_no-border',
            {
              'button-open_active': showConfigPanel,
            }
          )}
        />
        <Panel
          id='share-chart-panel'
          isOpened={showConfigPanel}
          title={''}
          renderCloseControl={false}
          customClass='ui-panel_fixed ui-panel_share_chart'
          excludeFromCloseTrigger={['.button-open-share-chart']}
          onClose={this.closePanel}
          contentStyle={{ top: '144px' }}
          modern>
          {isLoading && <Loader />}

          <div className={'share-chart-select-container'}>
            <MultiSelect
              id={'share-chart-users'}
              items={selectedUsers}
              isLoading={isLoading}
              onChange={(filterId, value) => {
                this.setState({ selectedUsers: value });
              }}
              withLabel={false}
              forceDropdownShown={true}
              searchPlaceholder={getTranslate(
                'analytics.shareCharts.shareWith.title'
              )}
            />
            <Button
              customClass='ui-button_full-width'
              status='primary'
              text={getTranslate('analytics.shareCharts.shareWith.buttonLabel')}
              disabled={this.shareButtonIsDisabled()}
              onClick={this.share}
            />
          </div>
        </Panel>
      </>
    );
  }

  canRenderButton(): boolean {
    const { isEnabled, isDisabledByReason } = this.props;
    return (
      isEnabled(Messages.Analytics_ShareCharts) &&
      !isDisabledByReason(
        Messages.Analytics_ShareCharts,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      )
    );
  }

  togglePanel = (): void => {
    this.setState((state) => {
      return {
        showConfigPanel: !state.showConfigPanel,
      };
    });
  };

  closePanel = () => {
    this.setState({
      showConfigPanel: false,
    });
  };

  share = async () => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { selectedChartsIds, dispatch } = this.props;
        const userIds = this.getSelectedUsersIds();

        await dispatch(shareCharts(selectedChartsIds, userIds));

        const unselectedUsers = this.state.selectedUsers.map((item) => {
          item.isSelected = false;
          return item;
        });
        this.setState({ selectedUsers: unselectedUsers, isLoading: false });
        this.closePanel();
        this.props.afterApply();
      }
    );
  };

  getSelectedUsersIds() {
    let { selectedUsers } = this.state;
    let userIds: String[] = [];
    forEach(selectedUsers, (user: Item) => {
      const { id, isSelected } = user;
      if (isSelected) {
        userIds.push(id);
      }
    });

    return userIds;
  }

  shareButtonIsDisabled() {
    const { selectedChartsIds } = this.props;
    return isEmpty(selectedChartsIds) || isEmpty(this.getSelectedUsersIds());
  }
}

const _ShareButton = connect(null)(ShareButton);

export default addTranslation(addPermissions(_ShareButton));
