import Repository from './Repository';

const getLocalizedText = (key: string) => {
  if (!key) return '';

  const store = Repository.get('store');
  const messages = Repository.get('messages');
  const { user } = store.getState();
  const locale = user?.interfaceLang || 'en';
  key = key.trim();

  try {
    return (messages && messages[locale][key]) || key;
  } catch (e) {
    return key;
  }
};

export default getLocalizedText;
