import BaseMessageHandler from '../BaseMessageHandler';
import { updateDataItem } from 'actions/getData';
import getStringWithSpecialSymbols from 'helpers/getStringWithSpecialSymbols';

export default class Team_UpdateUser extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;
    const { payload } = message;

    dispatch(
      updateDataItem('teamUsers', 'id', {
        ...this.getTableFormat(payload),
      })
    );
  };

  getTableFormat = (user) => {
    const {
      id,
      photo,
      username,
      email,
      status,
      roles,
      isChargebackMailingEnabled,
      legalEntities,
    } = user;
    return {
      id,
      userPhoto: photo,
      username: getStringWithSpecialSymbols(username),
      userEmail: email,
      userStatus: status,
      userRole: roles.toString(),
      isChargebackMailingEnabled,
      legalEntities: legalEntities.map((item) => item.text),
    };
  };
}
