import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { IntlProps, addTranslation } from 'decorators/addTranslation';
import { resendPaymentCallback } from 'api/payment';
import CopyToClipboard from 'components/ui/copyToClipboard';
import Button from 'components/ui/button';
import Panel from 'components/ui/panel';
import MetricService from 'helpers/metricService/MetricService';
import './callbackButtonsMenu.scss';

interface Props extends IntlProps {
  callbackBody: string;
  resendCallback: boolean;
  transactionId: string;
  operationId: string;
  customClass: string;
}

interface State {
  isOpenedCallbackPanel: boolean;
  isLoading: boolean;
}

class CallBackButtonsMenu extends PureComponent<Props, State> {
  private callbackAreaRef: any;

  constructor(props) {
    super(props);
    this.state = {
      isOpenedCallbackPanel: false,
      isLoading: false,
    };
  }

  render() {
    const { customClass, getTranslate } = this.props;
    const { isLoading } = this.state;

    return (
      <>
        <Button
          id='button-open-last-callback-button'
          customClass={classNames('payment-callback__button', customClass)}
          size='normal'
          status='outline'
          loading={isLoading}
          text={getTranslate('payments.data.lastcallback.button')}
          onClick={() => {
            this.openPanel();
            MetricService.send({
              action: 'click',
              actionKey: 'payments.paymentCard.lastCallback',
            });
          }}
        />

        {this.renderPanelCallback()}
      </>
    );
  }

  renderPanelCallback() {
    const { callbackBody, resendCallback, getTranslate } = this.props;
    const { isOpenedCallbackPanel } = this.state;

    let pretty = '';
    try {
      pretty = JSON.stringify(JSON.parse(callbackBody), undefined, 4);
    } catch (err) {
      console.error(err);
    }

    return (
      <Panel
        id='callback-buttons-menu'
        isOpened={isOpenedCallbackPanel}
        title={getTranslate('payments.single.lastcallback.header')}
        onClose={this.closePanel}
        excludeFromCloseTrigger={['.payment-callback__button']}
        modern
        customClass='ui-panel_payment-callback'>
        {resendCallback && (
          <div className='payment-callback__container payment-callback__button-wrapper'>
            <Button
              id='button-open-resend-callback-panel'
              size='normal'
              status='primary'
              customClass='payment-callback__button ui-button_full-width'
              text={getTranslate(
                'payments.single.callback.resendCallback.button'
              )}
              onClick={() => {
                this.resendCallback();
                MetricService.send({
                  action: 'click',
                  actionKey: 'payments.paymentCard.resendCallback',
                });
              }}
            />
          </div>
        )}
        <div className='payment-callback__inner'>
          <div className='payment-callback__content'>
            {/*<CustomScrollbar>*/}
            <CopyToClipboard
              text={pretty}
              customClass='payment-callback__copy'
            />
            <textarea
              readOnly={true}
              ref={(el) => {
                this.callbackAreaRef = el;
              }}
              defaultValue={pretty}
              className='payment-callback__textarea'
            />
            {/*</CustomScrollbar>*/}
          </div>
        </div>
      </Panel>
    );
  }

  resendCallback = () => {
    const { transactionId, operationId } = this.props;
    this.setState({ isLoading: true });
    resendPaymentCallback(transactionId, operationId).finally(() =>
      this.setState({ isLoading: false })
    );
  };

  openPanel = () => {
    this.setState({
      isOpenedCallbackPanel: true,
    });
    setTimeout(() => {
      if (this.callbackAreaRef) {
        const { callbackAreaRef } = this;
        callbackAreaRef.style.cssText =
          'height:' + callbackAreaRef.scrollHeight + 'px';
      }
    });
  };

  closePanel = () => {
    this.setState({
      isOpenedCallbackPanel: false,
    });
  };
}

export default addTranslation(CallBackButtonsMenu);
