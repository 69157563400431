const appStore = {};

class Repository {
  static set(key: string, data: any): void {
    appStore[key] = data;
  }

  static get(key: string) {
    return appStore[key];
  }
}

export default Repository;
