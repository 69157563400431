import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import Icon from 'components/ui/icon';
import Button from 'components/ui/button';
import RoundButton from 'components/ui/roundButton';
import {
  SortableContainer,
  SortableHandle,
  SortableItem,
} from 'components/ui/sortable';
import Loader from 'components/ui/loader';
import ConditionalWrapper from 'components/ui/conditionalWrapper';
import CustomScrollbar from 'components/ui/customScrollbar';
import permissionReasons from 'constants/permissionReasons';
import Messages from 'constants/rpcTypes';
import { Feature } from 'reducers/additionalFeatures';
import './additionalFeature.scss';

type Props = OwnProps & IntlProps & WithPermissions;

interface OwnProps {
  features: Feature[];
  handleScroll: (data) => void;
  handleSort: (data: { oldIndex: any; newIndex: any }) => void;
  isEditing: boolean;
  isLoading: boolean;
  language: string;
  onClose: () => void;
  onAddItem: () => void;
  onDelete: (id: number) => void;
  onEditFeature: (feature: Feature) => void;
  onToggleEditMode: () => void;
  onSave: () => void;
  cancelSave: () => void;
}

class AdditionalFeature extends PureComponent<Props> {
  render() {
    const { isEditing, isLoading, getTranslate, isEnabled } = this.props;

    return (
      <div
        className={classNames('additional-features-page', {
          'additional-features-page_edit-mode': isEditing,
        })}>
        <CustomScrollbar onEnd={this.props.handleScroll}>
          <div className='additional-features-page__header'>
            <div className='additional-features-page__status'>
              {getTranslate('getStarted.additionalFeatures.header')}
            </div>
            <div className='additional-features-page__title'>
              {getTranslate('additionalFeatures.header')}
            </div>
            <div className='additional-features-page__text'>
              {getTranslate('additionalFeatures.text')}
            </div>
          </div>
          <div className='additional-features-page__content'>
            {isLoading && <Loader />}
            {(isEnabled(Messages.AdditionalFeature_Create) ||
              this.props.isDisabledByReason(
                Messages.AdditionalFeature_Create,
                permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
              )) && (
              <div className='additional-features-page__buttons'>
                {isEditing && (
                  <Button
                    status='outline'
                    text={getTranslate(
                      'additionalFeatures.featuresList.cancelEditing.button'
                    )}
                    customClass='additional-features-page__edit-button'
                    onClick={this.props.cancelSave}
                  />
                )}
                <Button
                  status='primary'
                  text={getTranslate(
                    isEditing
                      ? 'additionalFeatures.faturesList.save.button'
                      : 'additionalFeatures.editFeature.button'
                  )}
                  customClass='additional-features-page__edit-button'
                  onClick={
                    isEditing ? this.props.onSave : this.props.onToggleEditMode
                  }
                />
                {isEditing && (
                  <Button
                    status='primary'
                    text={getTranslate(
                      'additionalFeatures.addNewFeature.button'
                    )}
                    customClass='additional-features-page__edit-button'
                    onClick={() => this.props.onAddItem()}
                  />
                )}
              </div>
            )}
            <div className='additional-features-page__list'>
              <ConditionalWrapper
                condition={isEditing}
                wrap={(children) => (
                  <SortableContainer
                    onSortEnd={this.props.handleSort}
                    useDragHandle={true}
                    axis='xy'
                    lockOffset={['0%', '-10%']}
                    lockToContainerEdges={true}
                    helperClass='additional-features-page__item_dragging'>
                    {children}
                  </SortableContainer>
                )}>
                {!this.props.features.length && (
                  <div className='additional-features-page__empty'>
                    {getTranslate('common.noData')}
                  </div>
                )}
                {this.props.features.map((feature, index) => {
                  return (
                    <ConditionalWrapper
                      key={feature.additionalFeatureId}
                      condition={isEditing}
                      wrap={(children) => (
                        <SortableItem
                          key={feature.additionalFeatureId}
                          index={index}
                          customClass='additional-features-page__item'>
                          <SortableHandle>
                            <span
                              className={classNames(
                                'additional-features-page__drag-handle'
                              )}>
                              <Icon
                                className='common_disable'
                                name='im-Draganddrop'
                                size={12}
                              />
                            </span>
                          </SortableHandle>
                          {children}
                        </SortableItem>
                      )}>
                      <div
                        className={
                          isEditing
                            ? 'additional-features-page__item-inner'
                            : 'additional-features-page__item'
                        }
                        id={`${feature.additionalFeatureId}`}
                        key={feature.additionalFeatureId}>
                        {feature.iconPath && (
                          <img
                            src={feature.iconPath}
                            alt='icon'
                            className='additional-features-page__feature-icon'
                          />
                        )}
                        {feature.picturePath && (
                          <img
                            src={feature.picturePath}
                            alt='decor'
                            className='additional-features-page__feature-image'
                          />
                        )}
                        {isEnabled(Messages.AdditionalFeature_Delete) &&
                          isEditing && (
                            <RoundButton
                              status='danger'
                              icon='im-Basket'
                              iconSize={11}
                              customClass='additional-features-page__delete-button'
                              onClick={() =>
                                this.props.onDelete(feature.additionalFeatureId)
                              }
                            />
                          )}
                        <div className='additional-features-page__feature-category'>
                          {feature.category}
                        </div>
                        <div className='additional-features-page__feature-title'>
                          {feature.title}
                        </div>
                        <div className='additional-features-page__feature-description'>
                          {feature.description}
                        </div>
                        <div className='additional-features-page__feature-subtext'>
                          {getTranslate(
                            'additionalFeatures.featureCard.checkWithManager.text'
                          )}
                        </div>
                      </div>
                    </ConditionalWrapper>
                  );
                })}
              </ConditionalWrapper>
            </div>
          </div>
        </CustomScrollbar>
      </div>
    );
  }
}

export default addPermissions(addTranslation(AdditionalFeature));
