import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';

import PageTemplate from 'components/pageTemplate/PageTemplate';
import Animation from 'components/ui/animation';
import NoContent from 'components/ui/noContent';
import DataBlock from 'components/ui/dataBlock';
import Loader from 'components/ui/loader';
import CaseIdList from './components/caseIdList';
import ChargebackDataMapper from './ChargebackDataMapper';
import ChargeSubmitForm from './components/chargeSubmitForm';
import Button from 'components/ui/button';
import { Maf } from 'components/maf/MafTypes';
import Teaser from 'components/ui/teaser';
import { isEqual, throttle } from 'lodash';
import { AnyObject } from '../../../types/Common';
import { statusMapper } from '../statuses';
import queryString from 'query-string';
import { getCurrentDispute } from './helpers';
import path from 'helpers/path';
import { WithRouterProps } from 'decorators/withRouter';
import ConditionalWrap from 'components/ui/conditionalWrapper';
import Messages from 'constants/rpcTypes';
import permissionReasons from 'constants/permissionReasons';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import LabelStatus from 'components/ui/labelStatus';
import Mapper from 'helpers/Mapper';
import Icon from 'components/ui/icon';
import CopyToClipboard from 'components/ui/copyToClipboard';
import { openModal } from 'actions/modal';
import ChargebackFlow from './components/chargebackFlow/ChargebackFlowContainer';
import ChbHistory from './components/chbHistory';
import { StoreProps } from 'store';
import InfoIcon from 'components/ui/infoIcon';
import DateHelpers from 'helpers/Date';
import classNames from 'classnames';
import breakpoints from 'constants/breakpoints';
import RoundButton from 'components/ui/roundButton';
import { caseStatusMapper } from './constants';
import MetricService from 'helpers/metricService/MetricService';
import { Tab, TabsContainer } from '../../../components/ui/tabs';
import './chargebackCard.scss';

export interface Dispute {
  caseId: number;
  status: string;
  disputeId: string;
  crmMafId: string;
  mafData: string;
  mafId: string;
  chargebackIds: string[];
  firstChargeId: string;
}

interface OwmProps {
  chargeback: any;
  // onAccept: () => void;
  onStartDispute: (list) => void;
  onCancelDispute: () => void;
  onDisputeCommit: (data: Maf) => void;
  onOpenForm: () => void;
  onAccept: (selectedIds: string[]) => void;
  id: string;
  caseId: string;
  firstChargeId: string;
  currentDispute: Dispute | undefined;
  isFormEnabled: boolean;
  isFormOpened: boolean;
  isLoading: boolean;
  isAcceptProcessing: boolean;
  mafData: any;
  mafId: string;
  loadingText?: string;
  location: WithRouterProps['location'];
  history: WithRouterProps['history'];
  dispatch: StoreProps['dispatch'];
}

type Props = OwmProps & IntlProps; //& WithRouterProps;

interface State {
  isCheckedAll: boolean;
  list: any[];
  isOpenedPaymentInfo: boolean;
}

@wrapAppWithCssClass('layout-app_tablet-width')
class ChargebackCard extends PureComponent<Props & WithPermissions, State> {
  constructor(props) {
    super(props);
    this.state = {
      isCheckedAll: true,
      list: [],
      isOpenedPaymentInfo: this.setCaseInfoState(),
    };
    this.handleResize = throttle(this.handleResize, 500);
  }

  componentDidMount() {
    if (this.props.chargeback) {
      this.setList();
    }
    window.addEventListener('resize', this.handleResize);

    // this.setSelected();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { firstChargeId } = this.props;

    if (
      (!prevProps.chargeback?.[firstChargeId]?.data &&
        this.props.chargeback?.[firstChargeId]?.data) ||
      // !isEqual(prevProps.chargeback?.[firstChargeId]?.data, this.props.chargeback?.[firstChargeId]?.data) ||
      prevProps.firstChargeId !== this.props.firstChargeId ||
      prevProps.chargeback?.id !== this.props.chargeback?.id ||
      (prevProps.isLoading && !this.props.isLoading)
    ) {
      this.setList();
    }
    if (!isEqual(this.state.list, prevState.list)) {
      // this.setSelected();
    }
  }

  render() {
    const { chargeback, id, firstChargeId, getTranslate } = this.props;

    return (
      <div className='chargeback-card'>
        <Animation duration={chargeback?.data ? 0 : 1000}>
          <PageTemplate.Container>
            {(chargeback?.[id]?.data && chargeback?.[id]?.data.error) ||
            !firstChargeId ? (
              <NoContent text={getTranslate('common.noMatches.text')} />
            ) : (
              this.renderContent()
            )}
          </PageTemplate.Container>
        </Animation>
      </div>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  renderContent() {
    const { chargeback, id, isFormOpened, firstChargeId, getTranslate } =
      this.props;

    if (!chargeback?.[firstChargeId]) {
      return (
        <div className='grid-row utils-height-100'>
          <Loader />
        </div>
      );
    }

    const selectedCount = this.getSelectedIds();

    return (
      <div className='payment-card payment-card_chargeback'>
        <div className='payment-card__inner'>
          <div className='payment-card__wrapper'>
            <div className='payment-card__item payment-card__item_prev'>
              <Teaser
                title={`${getTranslate('chargebackCard.caseId')} ${
                  chargeback[firstChargeId].data.caseId || '—'
                }`}
                text={
                  chargeback[firstChargeId].data.caseId
                    ? `${getTranslate(
                        'chargebackCard.caseId.chargebacksCount.text',
                        {
                          count:
                            chargeback[firstChargeId].data.sameCaseChargebacks
                              .length + 1,
                        }
                      )}
                ${getTranslate(
                  'chargebackCard.caseId.selectedChargebacksCount.text'
                )}
                ${selectedCount.length || 0}`
                    : ''
                }>
                <Button
                  text={getTranslate('chargebackCard.viewCaseIdInfo.button')}
                  onClick={this.openCaseInfo}
                  status='light'
                  size='normal'
                  disabled={this.props.isLoading}
                  customClass='payment-card__mobile-button'
                />
                {this.renderButtons()}
              </Teaser>
            </div>
            <div className='payment-card__item payment-card__item_operations'>
              {this.renderCaseIdList()}
            </div>
            <div className='payment-card__item payment-card__item_operation-info utils-flex-column'>
              {isFormOpened ? (
                this.renderForm()
              ) : (
                <>
                  <div className='card chargeback-card__info-prev'>
                    <div className='card__content chargeback-card__info-prev-inner'>
                      {!isFormOpened && chargeback?.[id] ? (
                        this.renderChbPrev()
                      ) : (
                        <Loader
                          type='bung'
                          customClass='chargeback-card__loader'
                        />
                      )}
                    </div>
                  </div>
                  <div className='card chargeback-card__chb-info'>
                    <TabsContainer
                      theme='brand'
                      customClass='chargeback-card__tabs'>
                      <Tab
                        title={getTranslate('chargebackCard.tabs.main.title')}>
                        <div className='card__content card__container'>
                          {chargeback?.[id] ? (
                            <>{this.renderChbInfo()}</>
                          ) : (
                            <Loader
                              type='bung'
                              customClass='chargeback-card__loader'
                            />
                          )}
                        </div>
                      </Tab>
                      <Tab
                        title={getTranslate(
                          'chargebackCard.tabs.history.title'
                        )}>
                        <div className='card__container'>
                          <ChbHistory
                            data={chargeback?.[id]?.data?.history || []}
                            isFetch={this.props.isLoading}
                          />
                        </div>
                      </Tab>
                    </TabsContainer>
                  </div>
                </>
              )}
            </div>
            {this.state.isOpenedPaymentInfo && (
              <div className='payment-card__item payment-card__item_payment-info'>
                <div className='payment-card__payment-info payment-info payment-info_modern card chargeback-card__case-info-block'>
                  <RoundButton
                    customClass='payment-info__close'
                    icon='im-Arrow-right'
                    iconSize={10}
                    size={26}
                    onClick={this.closeCaseInfo}
                  />
                  <div className='card__header card__header_space-between'>
                    <div className='card__title card__title-l'>
                      {getTranslate('chargebackCard.caseInfo')}
                    </div>
                    <InfoIcon
                      customClass='ui-info-icon_l'
                      size={28}
                      tooltipType='tooltip-info_blue'
                      tooltip={`${getTranslate(
                        'chargebackCard.descriptionCaseId.text'
                      )} ${getTranslate(
                        'chargebackCard.descriptionCaseIdOptions.text'
                      )}`}
                      modern
                    />
                  </div>
                  <div className='card__content chargeback-card__case-info-content'>
                    {chargeback?.[id] ? (
                      this.renderCaseIdInfo()
                    ) : (
                      <Loader
                        type='bung'
                        customClass='chargeback-card__loader'
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderChbPrev = () => {
    const { chargeback, id, getTranslate } = this.props;
    const data = chargeback?.[id]?.data;

    return (
      <>
        <div className='chargeback-card__info-row chargeback-card__info-row_header'>
          <span className='chargeback-card__amount'>
            {data.chargebackCurrentStageAmount}
            <span className='chargeback-card__currency'>
              {data.chargebackCurrentStageCurrency}
            </span>
          </span>

          <span className='chargeback-card__label' onClick={this.openTimeline}>
            <Icon
              name='im-Changes-history'
              size={11}
              className='chargeback-card__label-icon'
            />{' '}
            {(data.chargebackStage === '-' ? '' : data.chargebackStage) ||
              getTranslate('chargebackCard.timeLine.label')}
          </span>
          {this.renderRespond(data.respondBy)}
        </div>
        <div className='chargeback-card__info-row'>
          <div className='chargeback-card__info-item'>
            <div className='chargeback-card__info-title'>
              <Icon
                name='im-Timezone'
                size={11}
                className='chargeback-card__info-icon'
              />
              {getTranslate('chargebackCard.respondBy')}
            </div>
            <div className='chargeback-card__info-text'>
              {data.respondBy || '—'}
            </div>
          </div>
          <div className='chargeback-card__info-item'>
            <div className='chargeback-card__info-title'>
              {getTranslate('chargebackCard.ARN')}
            </div>
            <div className='chargeback-card__info-text chargeback-card__info-text_arn'>
              <span>{data.arn}</span>{' '}
              <CopyToClipboard
                text={data.arn}
                customClass='chargeback-card__info-copy'
              />
            </div>
          </div>
          <div className='chargeback-card__info-item'>
            <div className='chargeback-card__info-title'>
              {getTranslate('chargebackCard.chargebackId')}
            </div>
            <div className='chargeback-card__info-text'>
              {data.chargebackId}
            </div>
          </div>
          <div className='chargeback-card__info-item'>
            <div className='chargeback-card__info-title'>
              {getTranslate('chargebackCard.status')}
            </div>
            <div className='chargeback-card__info-text'>
              <LabelStatus
                status={Mapper.getStatus(data.status)}
                text={data.status}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  renderRespond = (respondBy) => {
    if (!respondBy) return null;

    const date = DateHelpers.createDate(respondBy, 'date');
    const currentDate = DateHelpers.getDate();
    const duration = DateHelpers.getDiff('days', currentDate, date);
    const classNameStatus = this.getStatus(duration);

    if (!duration) return null;

    return (
      <span
        className={classNames(
          'chargeback-card__label chargeback-card__label_respond',
          classNameStatus
        )}>
        {this.props.getTranslate('chargebackCard.respond.status', { duration })}
      </span>
    );
  };

  getStatus = (duration) => {
    switch (duration) {
      case 1:
        return 'chargeback-card__label_danger';
      case 2:
        return 'chargeback-card__label_warn';
      default:
        return 'chargeback-card__label_normal';
    }
  };

  renderChbInfo = () => {
    const { chargeback, id, getTranslate } = this.props;

    const {
      chargebackInfo,
      paymentInfo,
      preArbitrationInfo,
      arbitrationInfo,
      chargebackFinancials,
    } = ChargebackDataMapper.get(chargeback?.[id]?.data);

    return (
      <div className='grid-row grid-gap-20'>
        <div className='grid-column-6'>
          <DataBlock
            title={getTranslate('chargebackCard.chargebackInfo')}
            data={chargebackInfo}
            theme='modern'
          />
          <DataBlock
            title={getTranslate('chargebackCard.chargebackPreArbitrationInfo')}
            data={preArbitrationInfo}
            theme='modern'
          />
          <DataBlock
            title={getTranslate('chargebackCard.chargebackArbitrationInfo')}
            data={arbitrationInfo}
            theme='modern'
          />
        </div>
        <div className='grid-column-6'>
          <DataBlock
            title={getTranslate('chargebackCard.chargebackFinancials')}
            data={chargebackFinancials}
            theme='modern'
          />
          <DataBlock
            title={getTranslate('chargebackCard.paymentInfo')}
            data={paymentInfo}
            theme='modern'
          />
        </div>
      </div>
    );
  };

  renderCaseIdInfo = () => {
    const { chargeback, id } = this.props;

    const { caseInfo } = ChargebackDataMapper.get(chargeback?.[id]?.data);

    return <DataBlock data={caseInfo} theme='light' />;
  };

  renderCaseIdList = () => {
    const { isFormOpened, firstChargeId, currentDispute, isLoading } =
      this.props;
    return (
      <CaseIdList
        firstChargeId={firstChargeId}
        isButtonsDisabled={Boolean(currentDispute || isLoading)}
        isFormOpened={isFormOpened}
        list={this.state.list}
        isCheckedAll={this.state.isCheckedAll}
        onToggleChb={this.onToggleChb}
        toggleCheckAll={this.toggleCheckAll}
        goToCharge={this.goToCharge}
        isNeedResponseStatus={this.isNeedResponseStatus}
      />
    );
  };

  renderForm = () => {
    const {
      isLoading,
      loadingText,
      mafData,
      mafId,
      onDisputeCommit,
      onCancelDispute,
    } = this.props;
    const selectedCharge = this.state.list.find((item) => item.isSelected);

    return (
      <ChargeSubmitForm
        isLoading={isLoading || !mafData}
        loadingText={loadingText}
        mafData={mafData}
        onDisputeCommit={(data) => {
          if (selectedCharge.caseStatus === caseStatusMapper.preArbitration) {
            MetricService.send({
              action: 'click',
              actionKey:
                'chargebacks.chargebackCard.submitPrearbResponse.button',
            });
          } else {
            MetricService.send({
              action: 'click',
              actionKey: 'chargebacks.chargebackCard.submitSave.button',
            });
          }
          return onDisputeCommit(data);
        }}
        onCancelDispute={() => {
          if (selectedCharge.caseStatus === caseStatusMapper.preArbitration) {
            MetricService.send({
              action: 'click',
              actionKey:
                'chargebacks.chargebackCard.cancelPrearbSubmitting.button',
            });
          } else {
            MetricService.send({
              action: 'click',
              actionKey: 'chargebacks.chargebackCard.cancelSubmitting.button',
            });
          }
          return onCancelDispute();
        }}
        mafId={mafId}
      />
    );
  };

  renderButtons = () => {
    const { getTranslate, chargeback, firstChargeId, isAcceptProcessing } =
      this.props;
    const isAcceptEnabled =
      this.props.isEnabled(Messages.Chargeback_Accept) ||
      this.props.isDisabledByReason(
        Messages.Chargeback_Accept,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );
    const isSubmitEnabled =
      this.props.isEnabled(Messages.Chargeback_DisputeStart) ||
      this.props.isDisabledByReason(
        Messages.Chargeback_DisputeStart,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );
    const data = chargeback?.[firstChargeId]?.data;
    const selectedCount = this.state.list.filter(
      (item: AnyObject) => item.isSelected
    ).length;
    const isButtonsDisabled = Boolean(
      this.props.currentDispute || this.props.isLoading
    );

    return (
      (isAcceptEnabled || isSubmitEnabled) && (
        <>
          {isSubmitEnabled && data?.caseId && (
            <Button
              size='normal'
              status='outline'
              text={`${getTranslate(
                'chargebackCard.submitEvidence.button'
              )} (${selectedCount})`}
              onClick={this.onStartDispute}
              disabled={!selectedCount || isButtonsDisabled}
            />
          )}
          {isAcceptEnabled && (
            <ConditionalWrap
              wrap={(children) => (
                <span
                  data-tip={getTranslate('chargebackCard.invalidStatus.popup')}>
                  {children}
                </span>
              )}
              condition={!this.isNeedResponseStatus(data) && selectedCount}>
              <Button
                size='normal'
                status='success'
                text={`${getTranslate(
                  'common.accept.button'
                )} (${selectedCount})`}
                onClick={() => this.props.onAccept(this.getSelectedIds())}
                loading={isAcceptProcessing}
                disabled={!selectedCount || isButtonsDisabled}
              />
            </ConditionalWrap>
          )}
        </>
      )
    );
  };

  openTimeline = () => {
    const { chargeback, id, dispatch } = this.props;
    const { list } = this.state;
    const selectedCharge: AnyObject =
      list.find((item: AnyObject) => item.isCurrent) || {};

    const chargebackTypeId: string = selectedCharge.chargebackTypeId;
    dispatch(
      openModal({
        modalId: 'ChargebackTimeline',
        content: {
          title: 'chargebackCard.timeLine.label',
          text: (
            <ChargebackFlow
              chargebackTypeId={chargebackTypeId}
              timeline={chargeback?.[id]?.data.timeline}
            />
          ),
        },
      })
    );
  };

  onStartDispute = () => {
    if (isNotAvailableForSupport(Messages.Chargeback_DisputeStart)) return;

    const selectedCharge = this.state.list.find((item) => item.isSelected);

    const actionKey = this.getMetricKey(selectedCharge.caseStatus);

    MetricService.send({
      action: 'click',
      actionKey,
    });

    this.sortList();
    this.props.onStartDispute(this.getSelectedIds());
  };

  getMetricKey = (caseStatus) => {
    switch (caseStatus) {
      case caseStatusMapper.dispute:
        return 'chargebacks.chargebackCard.submitEvidence.button';
      case caseStatusMapper.preArbitration:
        return 'chargebacks.chargebackCard.submitPrearbEvidence.button';
      default:
        return '';
    }
  };

  setList = () => {
    const { chargeback, firstChargeId, location } = this.props;

    if (!chargeback?.[firstChargeId]?.data) return;
    const list = [
      chargeback[firstChargeId].data,
      ...chargeback[firstChargeId].data?.sameCaseChargebacks,
    ]
      .map((item) => {
        const currentId = queryString.parse(location.search);
        return {
          ...item,
          isSelected: this.isSelected(item),
          isCurrent: currentId.chargebackId === item.chargebackId,
        };
      })
      .sort((item) => (item.status === statusMapper.needsResponse ? -1 : 1))
      .sort((item) => (item.isSelected ? -1 : 1));

    this.setState(
      {
        list,
      },
      this.setIsAllChecked
    );
  };

  sortList = () => {
    this.setState((state) => ({
      list: state.list.sort((item) => (item.isSelected ? -1 : 1)),
    }));
  };

  getSelectedIds = () => {
    const { list } = this.state;
    if (!list?.length) return [];
    return list.reduce((selectedIds, item) => {
      return item.isSelected
        ? [...selectedIds, item.chargebackId]
        : selectedIds;
    }, []);
  };

  setIsAllChecked = () => {
    const selectableCharges = this.state.list.filter(
      (item: AnyObject) => item.status === statusMapper.needsResponse
    );
    const checkedCharges = selectableCharges.filter(
      (item: AnyObject) => item.isSelected
    );
    this.setState(() => ({
      isCheckedAll: checkedCharges.length === selectableCharges.length,
    }));
  };

  isSelected = (item) => {
    const { chargeback, firstChargeId } = this.props;

    const disputes: Dispute[] =
      chargeback[firstChargeId].disputes &&
      Object.values(chargeback[firstChargeId].disputes);

    const currentDispute = getCurrentDispute(disputes);
    return currentDispute?.chargebackIds.length
      ? currentDispute.chargebackIds.includes(item.chargebackId)
      : this.isNeedResponseStatus(item);
  };

  toggleCheckAll = () => {
    const { isCheckedAll } = this.state;

    const list = this.state.list.map((item: AnyObject) => ({
      ...item,
      isSelected: !isCheckedAll && item.status === statusMapper.needsResponse,
    }));
    this.setState(() => ({ list }), this.setIsAllChecked);
  };

  onToggleChb = (id, isSelected) => {
    this.setState(
      (state) => ({
        list: state.list.map((item: AnyObject) => {
          if (item.chargebackId === id) {
            return {
              ...item,
              isSelected,
            };
          }
          return item;
        }),
      }),
      this.setIsAllChecked
    );
  };

  isNeedResponseStatus = (chargeback) => {
    if (!chargeback) return false;
    return chargeback.status === statusMapper.needsResponse;
  };

  goToCharge = (id) => {
    const { isFormOpened, currentDispute, isLoading, history } = this.props;

    if (Boolean(currentDispute || isLoading || isFormOpened)) return;
    this.setState((state) => ({
      list: state.list.map((item: AnyObject) => {
        return {
          ...item,
          isCurrent: id === item.chargebackId,
        };
      }),
    }));

    history.push(path(`${history.location.pathname}?chargebackId=${id}`));
  };

  openCaseInfo = () => {
    this.setState({ isOpenedPaymentInfo: true });
  };

  closeCaseInfo = () => {
    this.setState({ isOpenedPaymentInfo: false });
  };

  handleResize = () => {
    if (document.body.clientWidth <= 1024) {
      this.closeCaseInfo();
    } else {
      this.openCaseInfo();
    }
  };

  setCaseInfoState = () => {
    return document.body.clientWidth > breakpoints.commonTabletL;
  };
}

export default addPermissions(addTranslation(ChargebackCard));
