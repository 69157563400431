import { IDictionaryItem } from '../../../../../../../types/Analytics';
import { take } from 'lodash';
import { GetTranslate } from '../../../../../../../decorators/addTranslation';

const prepareProjects = (projects: Array<IDictionaryItem>): Array<string> => {
  let source = projects;
  if (projects.length > 10) {
    source = projects.slice(0);
    source.sort((itemA, itemB) => {
      return Number(itemB.id) - Number(itemA.id);
    });
    source = take(source, 10);
  }

  return source.reduce(
    (acc: Array<string>, item: IDictionaryItem): Array<string> => {
      acc.push(item.id);
      return acc;
    },
    []
  );
};

const generaTemplateTitle = (
  token: string,
  getTranslate: GetTranslate
): string => {
  const date = new Date();
  const unixtime = Math.floor(date.getTime() / 1000);
  const title = getTranslate(token);
  return title.replace('<unixtime>', String(unixtime));
};

export { prepareProjects, generaTemplateTitle };
