import React, { Component } from 'react';
import { connect } from 'react-redux';

import api from 'api/secure';
import { updateUser } from 'actions/user';
import Loader from 'components/ui/loader';
import showNotification from 'components/ui/notification/showNotification';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import TwoFactorAuth from './TwoFactorAuth';
import { StoreProps } from 'store';

interface OwnProps {
  onChangeTwoFactor: () => void;
}

interface ConnectedProps {
  user: any;
}

type Props = OwnProps & ConnectedProps & StoreProps & IntlProps;

interface State {
  isInit: boolean;
  isLoading: boolean;
  qrCode: string;
  qrCodeError: string;
  secret: string;
}

const MAX_ATTEMPTS_GENERATE = 3;
const TIMEOUT_GENERATE = 40000;

class TwoFactorAuthContainer extends Component<Props, State> {
  private generateTimerId;
  private generateAttempts: number = 0;

  constructor(props: Props) {
    super(props);

    this.state = {
      isInit: false,
      isLoading: false,
      qrCode: '',
      qrCodeError: '',
      secret: '',
    };
  }

  async componentDidMount() {
    await this.init();
  }

  componentWillUnmount(): void {
    clearTimeout(this.generateTimerId);
  }

  render() {
    const { user } = this.props;
    const { isInit, isLoading, qrCode, qrCodeError } = this.state;
    if (!isInit) {
      return <Loader />;
    }

    return (
      <TwoFactorAuth
        user={user}
        qrCode={qrCode}
        qrCodeError={qrCodeError}
        isLoading={isLoading}
        onEnable={(code) => this.enableTwoFactor(code)}
        onDisable={(code) => this.disableTwoFactor(code)}
      />
    );
  }

  async init() {
    const { user } = this.props;

    if (!user.twoFactorAuth) {
      return this.generateQRCode();
    }

    this.setState({ isInit: true });
  }

  async generateQRCode() {
    this.generateAttempts++;
    try {
      const data = await api.enable2FAParams();
      this.setState({
        qrCodeError: '',
        qrCode: data.image,
        secret: data.secret,
      });
    } catch ({ error }) {
      this.setState({
        qrCodeError:
          this.generateAttempts === MAX_ATTEMPTS_GENERATE
            ? error.message
            : 'common.unavailableServisUpdating.text',
      });
      if (MAX_ATTEMPTS_GENERATE > this.generateAttempts) {
        setTimeout(() => {
          return this.generateQRCode();
        }, TIMEOUT_GENERATE);
      }
    } finally {
      this.setState({
        isInit: true,
      });
    }
  }

  async enableTwoFactor(verificationCode) {
    const { onChangeTwoFactor, dispatch, getTranslate } = this.props;
    const { secret } = this.state;

    this.setState({ isLoading: true });
    try {
      await api.enableTwoFactor({ verificationCode, secret });
      showNotification({
        status: 'success',
        content: getTranslate('profile.twoFA.enableSuccess.popup'),
      });
      dispatch(updateUser({ twoFactorAuth: true }));
      onChangeTwoFactor();
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async disableTwoFactor(verificationCode) {
    const { getTranslate } = this.props;
    const { onChangeTwoFactor, dispatch } = this.props;
    this.setState({ isLoading: true });

    try {
      await api.disableTwoFactor({ verificationCode });
      showNotification({
        status: 'success',
        content: getTranslate('profile.twoFA.disableSuccess.popup'),
      });
      dispatch(updateUser({ twoFactorAuth: false }));
      onChangeTwoFactor();
    } finally {
      this.setState({ isLoading: false });
    }
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
});

export default connect(mapStateToProps)(addTranslation(TwoFactorAuthContainer));
