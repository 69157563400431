import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';

/*const createAction = ({ type, payload }: { type: string; payload: any }) => {
  return new Promise(resolve => {});
}*/
export const setProject = (payload) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_PROJECT,
        payload,
      })
    );
  };
};

export const updateProject = (payload) => {
  return (dispatch) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: ACTIONS.UPDATE_PROJECT,
        payload,
      });
      resolve();
    });
  };
};

export const updateProjectField = (key: string, value: any) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPDATE_PROJECT_FIELD,
        payload: {
          key,
          value,
        },
      })
    );
  };
};

export const resetProjects = () => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_PROJECT,
      })
    );
  };
};
