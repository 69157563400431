import React, { PureComponent } from 'react';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import SavedEntitiesContainer from 'components/savedEntities';
import RecurringList from './list';
import RecurringCard from './card';
import savedEntities from 'constants/savedEntities';

interface Props {
  id?: string;
}

interface State {
  renderContent: boolean;
}

class Recurring extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      renderContent: true,
    };
  }

  componentDidUpdate({ id: prevId }) {
    const { id } = this.props;
    if (prevId && id && prevId !== id) {
      this.setState(
        {
          renderContent: false,
        },
        () => {
          this.setState({ renderContent: true });
        }
      );
    }
  }

  render() {
    return (
      <PageTemplate.Main>
        <PageTemplate.Entities>
          <SavedEntitiesContainer entityKey={savedEntities.recurring} />
        </PageTemplate.Entities>
        {this.renderContent()}
      </PageTemplate.Main>
    );
  }

  renderContent() {
    const { id } = this.props;
    const { renderContent } = this.state;

    if (!renderContent) return null;

    if (id) {
      return <RecurringCard id={id} />;
    }

    return (
      <PageTemplate.Container>
        <RecurringList />
      </PageTemplate.Container>
    );
  }
}

export default Recurring;
