import React, { PureComponent, ReactNode } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Icon from 'components/ui/icon';
import Button from 'components/ui/button';
import classNames from 'classnames';
import Loader from 'components/ui/loader';
import CopyToClipboard from 'components/ui/copyToClipboard';
import Link from 'components/ui/link';
import I18nHelpers from 'i18n/I18nHelpers';
import './submittedForm.scss';

interface Props extends IntlProps {
  id: string;
  title?: string;
  text?: string | ReactNode;
  status:
    | 'success'
    | 'error'
    | 'reject'
    | 'decline'
    | 'partiallyPaid'
    | 'terminalError'
    | string;
  secondaryButtonText?: string;
  link?: string;
  primaryButtonText?: string;
  onSecondaryButtonLink?: string;
  onPrimaryButtonClick?: () => void;
  errors?: string[];
}

class SubmittedForm extends PureComponent<Props> {
  render() {
    const { status, id, errors, getTranslate } = this.props;
    return (
      <div
        className={`ui-form-fields__submitted ui-submitted-form
          ui-submitted-form_${id}`}>
        <div
          className={classNames('ui-submitted-form__icon', {
            [`ui-submitted-form__icon_${status}`]: !!status,
          })}>
          {this.renderIcon()}
        </div>

        <div className='ui-submitted-form__item ui-submitted-form__item_title'>
          {I18nHelpers.getJsx(getTranslate(this.props.title))}
        </div>
        <div className='ui-submitted-form__item ui-submitted-form__item_text'>
          {typeof this.props.text === 'string'
            ? I18nHelpers.getJsx(getTranslate(this.props.text))
            : this.props.text}
        </div>
        <div className='ui-submitted-form__item ui-submitted-form__item_link'>
          <span className='ui-submitted-form__link'>
            {this.props.link ? (
              <>
                {this.props.link}
                <CopyToClipboard
                  text={this.props.link}
                  customClass='ui-submitted-form__copy'
                />
              </>
            ) : (
              <Loader />
            )}
          </span>
        </div>
        {errors && (
          <div className='ui-submitted-form__item ui-submitted-form__item_error'>
            {errors.map((error, i) => (
              <div className='ui-submitted-form__error' key={i}>
                {error}
              </div>
            ))}
          </div>
        )}
        {this.props.primaryButtonText && this.props.onPrimaryButtonClick && (
          <div className='ui-submitted-form__item ui-submitted-form__item_button'>
            <Button
              onClick={this.props.onPrimaryButtonClick}
              text={getTranslate(this.props.primaryButtonText)}
              status='primary'
              type='button'
              customClass='ui-submitted-form__button'
            />
          </div>
        )}
        {this.props.onSecondaryButtonLink && this.props.secondaryButtonText && (
          <div className='ui-submitted-form__item ui-submitted-form__item_button'>
            <Link
              url={this.props.onSecondaryButtonLink}
              className='ui-button ui-submitted-form__button ui-button_status-outline ui-button_size-normal'>
              {I18nHelpers.getJsx(getTranslate(this.props.secondaryButtonText))}
            </Link>
          </div>
        )}
      </div>
    );
  }

  renderIcon() {
    const { status } = this.props;

    switch (status) {
      case 'waiting':
        return <Loader />;
      case 'success':
        return <Icon name='im-Tick' size={16} />;
      case 'error':
      case 'terminalError':
        return <Icon name='im-Error' size={16} />;
      case 'reject':
      case 'decline':
        return <Icon name='im-Close' size={14} />;
      default:
        return <Icon name='im-Info-Square' size={18} />;
    }
  }
}

export default addTranslation(SubmittedForm);
