import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import './previewText.scss';

interface Props extends IntlProps {
  text: string;
  maxLength?: number;
}

interface State {
  needPreview: boolean;
  isOpened: boolean;
}

const MAX_LINES = 3;

class PreviewText extends PureComponent<Props, State> {
  private textRef;

  constructor(props) {
    super(props);

    this.state = {
      needPreview: false,
      isOpened: false,
    };
  }

  componentDidMount(): void {
    this.setState(
      {
        needPreview: this.checkNeedPreview(),
      },
      this.cropText
    );
  }

  componentDidUpdate(prevProps, prevState): void {
    if (
      prevState.isOpened !== this.state.isOpened ||
      prevState.needPreview !== this.state.needPreview ||
      prevProps.text !== this.props.text
    ) {
      this.cropText();
    }
  }

  render() {
    const { text, getTranslate } = this.props;
    const { isOpened, needPreview } = this.state;

    return (
      <div className='ui-preview-text'>
        <span
          ref={(el) => {
            this.textRef = el;
          }}
          className='ui-preview-text__inner'>
          {getTranslate(text)}
        </span>
        {needPreview && (
          <span className='ui-preview-text__button' onClick={this.toggle}>
            {isOpened
              ? getTranslate('common.less.button')
              : getTranslate('common.more.button')}
          </span>
        )}
      </div>
    );
  }

  cropText = () => {
    const { text, maxLength, getTranslate } = this.props;
    const { needPreview, isOpened } = this.state;
    const { textRef } = this;
    const localeText = getTranslate(text);

    if (isOpened || !needPreview) {
      textRef.textContent = localeText;
      return;
    }

    if (maxLength) {
      if (localeText.length > maxLength) {
        textRef.textContent =
          textRef.textContent.slice(0, maxLength).trim() + '...';
      }
    } else {
      textRef.textContent = '';

      for (const s of localeText) {
        if (MAX_LINES >= this.getLines()) {
          textRef.textContent += s;
        } else {
          textRef.textContent =
            textRef.textContent.slice(0, -10).trim() + '...';
          break;
        }
      }
    }
  };

  checkNeedPreview = () => {
    const { text, maxLength, getTranslate } = this.props;
    if (maxLength) {
      return getTranslate(text).length > maxLength;
    }
    return this.getLines() > MAX_LINES;
  };

  getLines = () => {
    const { textRef } = this;
    const style = getComputedStyle(textRef);
    const { height } = textRef.getBoundingClientRect();
    return Math.round(height / parseFloat(style['line-height']));
  };

  toggle = () => {
    this.setState((state) => {
      return {
        isOpened: !state.isOpened,
      };
    });
  };
}

export default addTranslation(PreviewText);
