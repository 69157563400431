import React, { PureComponent } from 'react';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import SavedEntitiesContainer from 'components/savedEntities/SavedEntitiesContainer';
import PaymentCard from 'components/paymentCard';
import savedEntities from 'constants/savedEntities';
import ChargebackList from './list';
import ChargebackCard from './card';

import './chargebacks.scss';

interface Props {
  id?: string;
  isPayment: boolean;
  projectId: any;
}

class Chargebacks extends PureComponent<Props> {
  render() {
    return (
      <PageTemplate.Main customClass='page-chargebacks'>
        <PageTemplate.Entities>
          <SavedEntitiesContainer entityKey={savedEntities.chargebacks} />
        </PageTemplate.Entities>
        {this.renderContent()}
      </PageTemplate.Main>
    );
  }

  renderContent() {
    const { id, projectId, isPayment } = this.props;

    if (isPayment && (id || projectId)) {
      return <PaymentCard id={id} savedEntity={savedEntities.chargebacks} />;
    } else if (id && !isPayment) {
      return <ChargebackCard id={id} />;
    }
    return (
      <PageTemplate.Container>
        <ChargebackList />
      </PageTemplate.Container>
    );
  }
}

export default Chargebacks;
