import LocalStorage from './LocalStorage';
import Utils from './Utils';

class Token {
  static setTokens(tokens) {
    for (const key in tokens) {
      if (Utils.hasProp(tokens, key)) {
        LocalStorage.set(`${key}`, tokens[key]);
      }
    }
  }

  static getTokens() {
    return {
      authToken: LocalStorage.get('authToken'),
      prevAuthToken: LocalStorage.get('prevAuthToken'),
    };
  }

  static clearTokens = () => {
    LocalStorage.remove('authToken');
    LocalStorage.remove('prevAuthToken');
  };
}
export default Token;
