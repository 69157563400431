import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addListeners } from 'decorators/addListeners';
import { WithRouterProps } from 'decorators/withRouter';

import { acceptChargebacks, getChargebacks } from 'api/chargebacks';
import path from 'helpers/path';
import getConfigurationByName from 'selectors/getConfigurationByName';
import MetricService from 'helpers/metricService/MetricService';
import {
  changeQuickFilter,
  initQuickFilters,
  resetConcreteQuickFilters,
} from 'actions/quickFilters';
import TopPanelContainer from 'components/topPanel/TopPanelContainer';
import TableItemsManage from 'components/ui/tableItemsManage';
import DataListContainer from 'components/dataListContainer';
import { Column, Header, Row } from 'components/ui/table';
import Checkbox from 'components/ui/checkbox';
import Loader from 'components/ui/loader';
import Lock from 'components/tableHeaders/components/lock';
import SortingCell from 'components/tableHeaders/components/sortingCell/SortingCell';
import Button from 'components/ui/button';

import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import { AnyObject } from 'types/Common';
import tableNames from 'constants/tableNames';
import Messages from 'constants/rpcTypes';
import filtersKeys from 'constants/filters';
import { minChargebackTableColumnsWidth } from './constants';
import { statuses } from 'pages/chargebacks/statuses';
import quickFiltersConfig from './quickFiltersConfig';
import getQuickFiltersValues from 'selectors/getQuickFiltersValues';
import { StoreProps } from 'store';
import { resetFilters } from 'actions/filters';
import permissionReasons from 'constants/permissionReasons';
import './chargebackList.scss';
import { SortingType } from '../../../types/sortings';

interface ConnectedProps {
  configuration: AnyObject;
  data: any[];
  canRender: boolean;
  sort: {
    field: string;
    order: SortingType;
  };
  selectedQuickFilters: AnyObject;
}

type Props = ConnectedProps &
  StoreProps &
  IntlProps &
  WithRouterProps &
  WithPermissions;

interface State {
  selectedIds: {
    [key: string]: boolean;
  };
  isManage: boolean;
  isAccepting: boolean;
}

@addListeners([Messages.Chargeback_Accept, Messages.Confirm_Reject])
class ChargebackListContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedIds: {},
      isManage: false,
      isAccepting: false,
    };
  }

  componentDidUpdate(_, prevState: Readonly<State>) {
    if (prevState.isManage && !this.state.isManage) {
      this.props.dispatch(
        changeQuickFilter(
          tableNames.chargebacks,
          filtersKeys.chargebacksStatuses,
          []
        )
      );
    }
  }
  render() {
    const { canRender, getTranslate } = this.props;
    const { isManage, isAccepting } = this.state;
    const isEnabled =
      this.props.isEnabled(Messages.Chargeback_Accept) ||
      this.props.isDisabledByReason(
        Messages.Chargeback_Accept,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );

    return (
      <Fragment>
        <TopPanelContainer
          name={tableNames.chargebacks}
          filtersConfig={quickFiltersConfig}
          isFilterButton={true}
          disableFilters={{ [filtersKeys.chargebacksStatuses]: isManage }}
          onReset={this.resetQuickFilters}
          hasSelectedFiltersCustom={this.hasSelectedFilters()}
          button={
            isEnabled && (
              <Button
                text={getTranslate('charges.manage.button')}
                status='primary'
                onClick={this.onManage}
              />
            )
          }
        />
        {canRender && (
          <Fragment>
            {isManage && (
              <TableItemsManage
                amount={this.getSelectedCount()}
                onCancel={this.onCancel}
                action={() => this.onAccept()}
              />
            )}
            <DataListContainer
              minColumnsWidth={minChargebackTableColumnsWidth}
              customClass='table-chargebacks'
              tableName={tableNames.chargebacks}
              apiRequest={getChargebacks}
              headerRender={this.renderTableHeader()}
              rowRender={this.renderRow}
            />
            {isAccepting && <Loader />}
          </Fragment>
        )}
      </Fragment>
    );
  }

  renderRow = ({ handleDoubleClick, ...data }) => {
    const { configuration } = this.props;
    const { selectedIds, isManage } = this.state;
    const { columnWidths, ...columnsData } = data;

    return (
      <Row
        customClass='ui-table__row_clickable'
        onClick={() => handleDoubleClick(this.handleRowClick)}
        key={data.chargebackId}>
        {configuration.map((config) => {
          const { id } = config;
          let content = data[id];
          const columnParams = {
            ...config,
            valueId: config.id,
            valueType: config.valueType,
          };

          if (id === 'chargebackId') {
            content = isManage ? (
              <>
                <Checkbox
                  id={content}
                  checked={selectedIds[content] || false}
                  customClass='table-chargebacks__checkbox'
                  onChange={() => this.onCheckCharge(data[id])}
                />
                {content}
              </>
            ) : (
              content
            );
          }

          return (
            <Column
              columnWidths={columnWidths}
              key={id}
              id={id}
              content={content || '—'}
              customClass={`ui-table__column_${id}`}
              data={columnsData}
              params={columnParams}
            />
          );
        })}
      </Row>
    );
  };

  renderTableHeader = () => {
    const { configuration, data, sort, getTranslate } = this.props;
    const { isManage } = this.state;
    const selectedCount = this.getSelectedCount();

    return (
      <Header>
        {configuration.map(({ id, localId, fixed }) => {
          const translation = getTranslate(localId);
          if (id === 'chargebackId') {
            const isFixed = Boolean(fixed);

            return (
              <Column
                key={id}
                id={id}
                modifier={id}
                customClass={isFixed ? 'ui-table__column_fixed' : ''}
                content={
                  <>
                    {isManage && (
                      <Checkbox
                        id='chargebackId-checkAll'
                        checked={selectedCount === data.length}
                        onChange={() => {
                          this.checkAll(selectedCount < data.length);
                        }}
                        customClass='table-chargebacks__checkbox'
                      />
                    )}
                    <Lock
                      content={getTranslate(translation)}
                      tableName={tableNames.chargebacks}
                      isFixed={isFixed}
                    />
                  </>
                }
              />
            );
          }
          if (id === 'respondBy') {
            return (
              <Column
                key={id}
                id={id}
                modifier={id}
                content={
                  <SortingCell
                    tableName={tableNames.chargebacks}
                    id={id}
                    sort={sort}>
                    {translation}
                  </SortingCell>
                }
              />
            );
          }
          return (
            <Column key={id} id={id} modifier={id} content={translation} />
          );
        })}
      </Header>
    );
  };

  handleRowClick = ({ chargebackId, caseId }) => {
    const { isManage } = this.state;
    if (isManage) {
      return;
    }
    this.goToChargeback(caseId, chargebackId);
  };

  getSelectedCount = () => {
    const { selectedIds } = this.state;
    return Object.keys(selectedIds).length;
  };

  resetQuickFilters = () => {
    const { isManage } = this.state;
    const { dispatch } = this.props;

    if (isManage) {
      dispatch(
        initQuickFilters({
          name: tableNames.chargebacks,
          content: {
            isReady: true,
            [filtersKeys.chargebacksProjectIds]: [],
            [filtersKeys.chargebacksStatuses]: ['NEEDS RESPONSE'],
            [filtersKeys.chargebacksArn]: '',
            [filtersKeys.chargebacksId]: '',
            [filtersKeys.caseId]: '',
            [filtersKeys.chargebacksDate]: {
              type: '',
              values: [],
            },
          },
        })
      );
    } else {
      dispatch(resetConcreteQuickFilters({ name: tableNames.chargebacks }));
    }
    dispatch(resetFilters(tableNames.chargebacks));
  };

  hasSelectedFilters = () => {
    const { selectedQuickFilters } = this.props;
    if (this.state.isManage) {
      const selectedKeys = Object.keys(selectedQuickFilters);
      return !(
        selectedKeys.length === 1 &&
        selectedKeys[0] === filtersKeys.chargebacksStatuses
      );
    }
    return !isEmpty(selectedQuickFilters);
  };

  goToChargeback = (caseId, id) => {
    const { history } = this.props;
    history.push(path(`/chargebacks/${caseId}?chargebackId=${id}`));
    MetricService.send({
      action: 'viewItem',
      actionKey: 'chargebacks.registry.chargebackCard',
    });
  };

  onCheckCharge = (id) => {
    const { selectedIds } = this.state;
    const newSelectedIds = { ...selectedIds };
    if (newSelectedIds[id]) {
      delete newSelectedIds[id];
    } else {
      newSelectedIds[id] = true;
    }

    this.setState({ selectedIds: newSelectedIds });
  };

  checkAll = (isSelected) => {
    const { data } = this.props;
    if (!isSelected) {
      this.setState({ selectedIds: {} });
    } else {
      const selectedIds = {};
      data.forEach(({ chargebackId }) => {
        selectedIds[chargebackId] = true;
      });
      this.setState({ selectedIds });
    }
  };

  onManage = () => {
    this.props.dispatch(
      changeQuickFilter(
        tableNames.chargebacks,
        filtersKeys.chargebacksStatuses,
        [statuses[0].id]
      )
    );

    this.setState({ isManage: true });
  };

  async onAccept() {
    const { selectedIds } = this.state;
    if (isNotAvailableForSupport(Messages.Chargeback_Accept)) return;

    this.setState({ isAccepting: true });
    try {
      await acceptChargebacks(Object.keys(selectedIds));
    } catch (error) {
      this.setState({ isAccepting: false });
    }
  }

  onCancel = () => {
    this.checkAll(false);
    this.setState({ isManage: false });
  };

  sendMetric = () => {
    MetricService.send({
      action: 'click',
      actionKey: 'chargebacks.registry.accept.yes',
    });
  };

  onEvent = ({ name, data }) => {
    const { status } = data.rpc;
    const { unblockedTypes } = data.payload;

    if (status)
      if (name === Messages.Chargeback_Accept) {
        this.setState({
          isAccepting: false,
          isManage: false,
        });
        this.checkAll(false);
        this.sendMetric();
      } else if (
        name === Messages.Confirm_Reject &&
        unblockedTypes.includes(Messages.Chargeback_Accept)
      ) {
        this.setState({
          isAccepting: false,
        });
      }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  configuration: getConfigurationByName(state, tableNames.chargebacks),
  data: state.data.chargebacks.items,
  canRender: state.quickFilters[tableNames.chargebacks].isReady,
  sort: state.data[tableNames.chargebacks].sort,
  selectedQuickFilters: getQuickFiltersValues(state, tableNames.chargebacks),
});

export default withRouter(
  addTranslation(
    connect(mapStateToProps)(addPermissions(ChargebackListContainer))
  )
);
