import React from 'react';
import { connect } from 'react-redux';

import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';
import MafContainer from 'components/maf/MafContainer';
import { MafContainerProps } from 'components/maf/MafProps';
import BusinessDocs from './BusinessDocumentsForm';
import { WithRouterProps } from 'decorators/withRouter';
import { StoreProps } from 'store';
import { addEntityToSaved, updateEntity } from 'actions/savedEntities';
import savedEntities from 'constants/savedEntities';
import { Maf } from 'components/maf/MafTypes';
import { State as SavedEntitiesState } from 'reducers/savedEntities';
import SavedEntity from 'types/savedEntity';

type ConnectedProps = {
  storedSavedEntities: { isFetch: boolean; items: SavedEntity[] };
};
type Props = WithRouterProps<{ id?: string; legalEntityId?: string }> &
  StoreProps &
  ConnectedProps;

type State = {
  wasCommited: boolean;
};

@wrapAppWithCssClass([
  'layout-app_tablet-width',
  'layout-app_scrollable-content',
])
class BusinessDocumentsFormContainer extends React.Component<Props, State> {
  /* Maf Id */
  id: string | null = null;
  legalEntityId: string | null = null;

  constructor(props) {
    super(props);

    this.id = props.match.params.id;
    const search = new URLSearchParams(this.props.location.search);
    this.legalEntityId = search.get('legalEntityId');
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState({ wasCommited: false });
    }
  }

  init() {
    if (!this.id || !this.legalEntityId) return;

    // if state still not mounted we dont know, is entity already saved or not
    if (!this.props.storedSavedEntities) return;

    this.props.dispatch(
      addEntityToSaved({
        entityKey: savedEntities.businessDocs,
        id: this.id,
        caption: this.props.location.state?.legalEntityCaption,
        urlParams: { legalEntityId: this.legalEntityId },
      })
    );
  }

  updateSavedMaf = (maf: Maf) => {
    this.props.dispatch(
      updateEntity({
        entityKey: savedEntities.businessDocs,
        id: maf.crm_id,
        fields: { data: maf },
      })
    );
  };

  render() {
    const { id } = this.props.match.params;
    const search = new URLSearchParams(this.props.location.search);
    const legalEntityId = search.get('legalEntityId');

    return (
      <MafContainer
        crmId={id}
        legalEntityId={legalEntityId || undefined}
        onMafLoaded={this.updateSavedMaf}
        externalMaf={
          this.props.storedSavedEntities?.items.find(
            (entity) => entity.id === id
          )?.data
        }
        withOverviewTab>
        {(props: MafContainerProps) => (
          <BusinessDocs
            {...props}
            goToMafList={() => {
              this.setState({ wasCommited: false });
              this.props.history.push('/maf');
            }}
            onCommit={async () => {
              const commited = await props.onCommit();
              commited && this.setState({ wasCommited: true });
              return commited;
            }}
            wasCommited={this.state?.wasCommited}
          />
        )}
      </MafContainer>
    );
  }
}

export default connect(
  (state: SavedEntitiesState): ConnectedProps => ({
    storedSavedEntities: state.savedEntities[savedEntities.businessDocs],
  })
)(BusinessDocumentsFormContainer);
