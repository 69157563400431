import Date from 'helpers/Date';

class Operations {
  /**
   *
   * @param operations
   */
  static getDuration(operations: any[]): number {
    if (operations.length <= 1) {
      return 1;
    }
    return (
      Date.getDiff(
        'days',
        Date.createDate(operations[0].creationDate, 'datetime'),
        Date.createDate(
          operations[operations.length - 1].updatedDate,
          'datetime'
        )
      ) || 1
    );
  }
}

export default Operations;
