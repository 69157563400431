import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { invert, isEqual, pickBy } from 'lodash';

import { addDataItem } from 'actions/getData';
import { openModal } from 'actions/modal';
import {
  createInvoice,
  createSubscriptionInvoice,
  getFormConfig,
} from 'api/invoices';
import { addListeners, IListeners } from 'decorators/addListeners';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { WithRouterProps } from 'decorators/withRouter';
import { StoreProps } from 'store';

import formStateFactory from 'components/formFields/formStateFactory';
import FormFields from 'components/formFields/FormFieldsContainer';
import { fieldsConfig, fieldsConfigSubscription } from './fieldsConfig';
import Utils from 'helpers/Utils';
import MetricService from 'helpers/metricService/MetricService';
import CacheService from 'helpers/CacheService';
import path from 'helpers/path';
import checkFilters from 'helpers/checkFilters';
import LocalStorage from 'helpers/LocalStorage';
import { getMethods } from 'creators/paymentMethods';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import Messages from 'constants/rpcTypes';
import { AnyObject } from 'types/Common';
import { Dictionary } from 'types/FilterValue';
import { formIdRoutes } from './formIdRoutes';
import permissionReasons from 'constants/permissionReasons';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';

export enum InvoiceStatus {
  DISABLED,
  ENABLED,
}

interface OwnProps {
  invoiceStatusProjects?: AnyObject[];
}

interface ConnectedProps {
  isPaymentIdGeneratorEnabled: boolean;
  isInvoiceCardOperationTypeManageAvailable: boolean;
  invoiceProject: AnyObject;
  motoType: AnyObject;
  subscriptionPaymentPeriod: Dictionary;
  invoices: AnyObject;
}

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  IntlProps &
  WithRouterProps<{ formId: string }> &
  WithPermissions;

interface State {
  isCreating: boolean;
  isSuccessful: boolean;
  isMotoFieldEnabled: boolean;
  fields: any; //InvoiceFields;
  validationErrors: AnyObject;
  paymentMethods: Dictionary;
  fieldsState: {
    paymentMethodCode: boolean;
    isEmailSend: boolean;
  };
  hiddenFields: {
    motoType: boolean;
    cardToken: boolean;
    cardOperationType: boolean;
    paymentMethodCode: boolean;
  };
  link?: string;
  currentTab: 'single' | 'subscription';
}

const errorFieldsMap = {
  projectId: 'projectId',
  paymentMethodCode: 'paymentMethodCode',
  currency: 'currency',
};

const CARD_TOKEN_PAYMENT_METHOD = 'card-token';
const cardPaymentMethods = ['card', CARD_TOKEN_PAYMENT_METHOD];

@addListeners([
  Messages.Invoice_Create,
  Messages.Invoice_CreateSubscription,
  Messages.Invoice_FormConfig,
  Messages.InvoicesUpdated,
])
class InvoiceCreateContainer
  extends PureComponent<Props, State>
  implements IListeners
{
  private cacheService;

  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      isSuccessful: false,
      isMotoFieldEnabled: false,
      fields: this.getInitialFields(),
      validationErrors: {},
      paymentMethods: {
        isFetched: false,
        isLoading: false,
        hasMoreRows: false,
        list: [],
      },
      hiddenFields: {
        motoType: true,
        cardToken: true,
        cardOperationType: true,
        paymentMethodCode: false,
      },
      fieldsState: {
        isEmailSend: false,
        paymentMethodCode: false,
      },
      link: undefined,
      currentTab: 'single',
    };
    this.cacheService = new CacheService(
      Messages.Invoice_FormConfig,
      this.loadFormConfig
    );
  }

  componentDidMount() {
    this.setFormTab();
    if (
      this.props.match.params.formId === formIdRoutes.subscription &&
      !this.checkSubscriptionAvailability()
    ) {
      this.setFormRoute();
    }
    if (this.props.motoType) {
      this.setMotoType();
    }

    if (this.props.invoiceProject?.list?.length === 1) {
      this.changeFields(
        'projectId',
        this.props.invoiceProject.list[0].projectId
      );
    }

    if (this.state.currentTab === 'subscription') {
      checkFilters([
        {
          name: 'subscriptionPaymentPeriod',
        },
      ]);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedProject } = this.state.fields;
    const value = selectedProject?.value;

    if (value && value !== prevState.fields.selectedProject?.value) {
      this.validateProjectIdField(String(value));
    }

    if (
      !prevProps.invoiceStatusProjects &&
      this.props.invoiceStatusProjects &&
      value
    ) {
      this.validateProjectIdField(String(value));
    }

    if (!prevProps.motoType && this.props.motoType) {
      this.setMotoType();
    }

    if (
      prevProps.invoiceProject?.list?.length !==
        this.props.invoiceProject?.list?.length &&
      this.props.invoiceProject?.list?.length === 1
    ) {
      this.changeFields(
        'projectId',
        this.props.invoiceProject.list[0].projectId
      );
    }

    if (prevProps.match.params.formId !== this.props.match.params.formId) {
      this.setFormTab();
    }

    if (prevState.currentTab !== this.state.currentTab) {
      this.setFormRoute();
      this.reset();
      if (this.state.currentTab === 'subscription') {
        checkFilters([
          {
            name: 'subscriptionPaymentPeriod',
          },
        ]);
      }
    }
  }

  render() {
    const {
      isCreating,
      isSuccessful,
      link,
      fields,
      paymentMethods,
      validationErrors,
      currentTab,
    } = this.state;
    const isSingle = currentTab === 'single';

    return (
      <FormFields
        id='invoices'
        isLoading={isCreating}
        fieldsConfig={isSingle ? fieldsConfig : fieldsConfigSubscription}
        hiddenFields={this.state.hiddenFields}
        fieldsState={this.state.fieldsState}
        canCreate={!isSuccessful && this.canCreate()}
        onChange={this.changeFields}
        onReset={this.reset}
        onSubmit={this.showModal}
        fieldsValues={fields}
        filtersValuesCustom={{
          paymentMethods,
          subscriptionPaymentPeriod: {
            ...this.props.subscriptionPaymentPeriod,
            list: getCustomSelectItems({
              list: this.props.subscriptionPaymentPeriod?.list,
              getLabel: (item) =>
                this.props.getTranslate(item.text, {
                  amount: +this.state.fields.recurringInterval || 0,
                }),
            }),
          },
        }}
        validationErrors={validationErrors}
        title='invoicing.newinvoice.tab'
        text={this.getText()}
        generalTitle='invoicing.newinvoice.paymentInfo.label'
        additionalTitle='invoicing.newinvoice.additionalInfo.label'
        backUrl='/invoices'
        backText='invoicing.newinvoice.backToRegistry.button'
        createButtonText='invoicing.newinvoice.createLink.button'
        repeatCreateButtonText='invoicing.newInvoice.button'
        isSubmitted={isSuccessful}
        submittedTitle='invoicing.newinvoice.successLink.header'
        submittedText={
          isSingle
            ? 'invoicing.newinvoice.successLink.text'
            : 'invoicing.newinvoicesubscription.successLink.text'
        }
        link={link}
        tabs={this.getTabs()}
        onChangeTab={this.onChangeTab}
      />
    );
  }

  getText = () => {
    if (this.state.currentTab === 'subscription') {
      return 'invoicing.newinvoice.subscription.infoText';
    }
    if (this.props.isInvoiceCardOperationTypeManageAvailable) {
      return 'invoicing.newinvoice.dmsFeature.infoText';
    }
    return 'invoicing.newinvoice.infoText';
  };

  validateProjectIdField = (value: string) => {
    const { invoiceStatusProjects, getTranslate } = this.props;
    const { validationErrors } = this.state;
    if (invoiceStatusProjects) {
      const selectedProjectWithInvoiceStatus = invoiceStatusProjects.find(
        (project) => project.id === value
      );

      const invoiceStatusEnabled =
        selectedProjectWithInvoiceStatus &&
        selectedProjectWithInvoiceStatus.invoice_enabled ===
          InvoiceStatus.ENABLED;

      if (!invoiceStatusEnabled) {
        this.setState({
          validationErrors: {
            ...validationErrors,
            projectId: getTranslate(
              'invoicing.newinvoice.project.validationError.text'
            ),
          },
        });
      }
    }
  };

  formIsInvalid = () => {
    const { validationErrors } = this.state;
    if (!validationErrors) {
      return false;
    }
    return Object.values(validationErrors).some((error) => error);
  };

  setMoto = (isMotoFieldEnabled) =>
    this.setState((state) => ({
      fieldsState: { ...state.fieldsState, motoType: isMotoFieldEnabled },
    }));

  canCreate = () => {
    const {
      isMotoFieldEnabled,
      fields: {
        amount,
        cardToken,
        paymentId,
        currency,
        motoType,
        paymentMethodCode,
        projectId,
        bestBefore,
        customerId,
        recurringScheduledPaymentId,
        recurringInterval,
        recurringPeriod,
        recurringStartDate,
      },
    } = this.state;

    if (this.formIsInvalid()) {
      return false;
    }

    if (
      this.state.currentTab === 'subscription' &&
      !(
        recurringScheduledPaymentId &&
        recurringInterval &&
        recurringPeriod &&
        recurringStartDate &&
        paymentMethodCode
      )
    )
      return false;

    if (
      paymentId &&
      projectId &&
      amount &&
      currency &&
      bestBefore &&
      customerId
    ) {
      if (isMotoFieldEnabled && !motoType) return false;
      if (paymentMethodCode) {
        return !(paymentMethodCode === CARD_TOKEN_PAYMENT_METHOD && !cardToken);
      }
      return true;
    }
    return false;
  };

  showModal = () => {
    if (
      this.state.currentTab === 'subscription' &&
      !LocalStorage.get('isInvoiceSubscriptionInfoSeen')
    ) {
      this.props.dispatch(
        openModal({
          modalId: 'ConfirmInvoiceSubscription',
          callback: (isAgree) => isAgree && this.createInvoice(),
        })
      );
    } else {
      this.createInvoice();
    }
  };

  async createInvoice() {
    const { fields } = this.state;
    const isSubscription = this.state.currentTab === 'subscription';

    if (
      isNotAvailableForSupport(
        isSubscription
          ? Messages.Invoice_CreateSubscription
          : Messages.Invoice_Create
      )
    )
      return;

    const request = isSubscription ? createSubscriptionInvoice : createInvoice;

    this.setState({ isCreating: true });
    const options: AnyObject = {
      customerId: fields.customerId,
      paymentId: fields.paymentId,
      isEmailSend: fields.isEmailSend,
      customerEmail: fields.customerEmail,
      bestBefore: fields.bestBefore,
      description: fields.description,
      cardToken: fields.cardToken,
      amount: Utils.getNumberWithoutSpace(fields.amount),
      currency: fields.currency,
      motoType: fields.motoType,
      projectId: fields.projectId,
      paymentMethodCode: fields.paymentMethodCode,
      cardOperationType: cardPaymentMethods.includes(fields.paymentMethodCode)
        ? fields.cardOperationType
        : '',
    };

    if (isSubscription) {
      options.recurringScheduledPaymentId =
        this.state.fields.recurringScheduledPaymentId;
      options.recurringInterval = this.state.fields.recurringInterval || '1';
      options.recurringPeriod = this.state.fields.recurringPeriod;
      options.recurringStartDate = this.state.fields.recurringStartDate;
      options.recurringEndDate = this.state.fields.recurringEndDate;
    }

    try {
      await request(pickBy(options, (val) => val !== null && val !== ''));
    } catch (error) {
      const { payload } = error;
      this.setState({
        validationErrors: payload.validationErrors || payload,
      });
    } finally {
      this.setState({ isCreating: false });
    }
  }

  reset = () => {
    const initialFields = this.getInitialFields();
    this.setState(
      (state) => ({
        isCreating: false,
        isSuccessful: false,
        isMotoFieldEnabled: false,
        fields:
          this.props.invoiceProject?.list?.length === 1
            ? {
                ...initialFields,
                projectId: this.props.invoiceProject.list[0].projectId,
              }
            : initialFields,
        hiddenFields: {
          ...state.hiddenFields,
          paymentMethodCode: false,
          motoType: true,
          cardToken: true,
          cardOperationType: true,
          text: true,
        },
        validationErrors: {},
        fieldsState: {
          isEmailSend: false,
          paymentMethodCode: this.props.invoiceProject?.list?.length === 1,
        },
        link: undefined,
      }),
      () => this.handleProjectChange(this.state.fields.projectId)
    );
  };

  nullifyErrors = (field) => {
    const { validationErrors } = this.state;
    const errorField = errorFieldsMap[field] || field;
    this.setState({
      validationErrors: {
        ...validationErrors,
        [errorField]: '',
      },
    });
  };

  setMotoType = () => {
    if (!this.state || this.state.hiddenFields.motoType) return;
    const { motoType } = this.props;
    const { fields } = this.state;
    let selectedMotoType: any = null;
    if (motoType?.list?.length) {
      const withoutMoto = motoType?.list?.find(({ id }) => id === 0);
      if (withoutMoto) {
        selectedMotoType = {
          value: withoutMoto.id,
          label: withoutMoto.text,
        };
      }
    }
    this.setState({
      fields: { ...fields, motoType: selectedMotoType },
    });
  };

  getInitialFields = () => {
    const isSingle = this.props.match.params.formId === 'single';
    const config = isSingle ? fieldsConfig : fieldsConfigSubscription;
    const additional = config.additional.map((item) => {
      if (Array.isArray(item)) {
        return item.map((field) => this.checkItem(field));
      }
      return this.checkItem(item);
    });

    const fields = {
      ...formStateFactory(
        fieldsConfig.general,
        this.props.isPaymentIdGeneratorEnabled
      ),
      ...formStateFactory(additional),
    };

    if (isSingle) {
      return fields;
    }
    return {
      ...fields,
      ...formStateFactory(config.subscription),
    };
  };

  checkItem = (item) => {
    if (item.id === 'cardOperationType') {
      return {
        ...item,
        isFeatureEnabled: this.props.isInvoiceCardOperationTypeManageAvailable,
      };
    }
    return item;
  };

  changeFields = (field: string, value) => {
    const { fields, hiddenFields, fieldsState } = this.state;
    const newFields = { ...fields };

    this.nullifyErrors(field);

    newFields[field] = value;
    if (field === 'customerEmail') {
      if (!value) {
        newFields.isEmailSend = false;
      }
      this.setState({ fieldsState: { ...fieldsState, isEmailSend: !!value } });
    } else if (field === 'projectId') {
      this.handleProjectChange(value);
      this.setState({
        fieldsState: { ...fieldsState, paymentMethodCode: !!value },
      });
      if (isEqual(fields[field], value)) return false;
      newFields.paymentMethodCode = null;
    } else if (field === 'paymentMethodCode') {
      this.setState({
        hiddenFields: {
          ...hiddenFields,
          cardToken: value !== CARD_TOKEN_PAYMENT_METHOD,
          cardOperationType: this.props
            .isInvoiceCardOperationTypeManageAvailable
            ? !cardPaymentMethods.includes(value)
            : true,
        },
      });
    }

    this.setState({
      fields: newFields,
    });
  };

  handleProjectChange = (item) => {
    if (!item) return;

    const cachedName = this.getCachedName(item);
    const data = this.cacheService.getData(cachedName, true);
    if (data) {
      this.setConfigSettings(data.isMotoFieldEnabled, data.paymentMethods);
    }
  };

  getCachedName = (projectId) => {
    return `${this.state.currentTab}-${projectId}`;
  };

  loadFormConfig = async (itemId) => {
    const { paymentMethods } = this.state;
    this.setState({ paymentMethods: { ...paymentMethods, isLoading: true } });
    const projectId = itemId.split('-')[1];
    await getFormConfig({
      projectId,
      isSubscription: this.state.currentTab === 'subscription',
    });
  };

  setConfigSettings = (isMotoFieldEnabled, paymentMethodsList) => {
    this.setMoto(isMotoFieldEnabled);

    this.setState((state) => ({
      paymentMethods: {
        list: [...paymentMethodsList],
        isLoading: false,
        isFetched: true,
        hasMoreRows: false,
      },
      hiddenFields: {
        ...state.hiddenFields,
        paymentMethodCode: !paymentMethodsList.length,
        text: !!paymentMethodsList.length,
      },
    }));
  };

  sendMetric = () => {
    MetricService.send({
      action: 'click',
      actionKey: 'invoices.newInvoice.create',
    });
  };

  getTabs = () => {
    const tabs = [
      {
        id: 'single',
        label: 'invoicing.newinvoice.tab.single',
        isCurrent: this.props.match.params.formId === formIdRoutes.single,
      },
    ];
    if (this.checkSubscriptionAvailability()) {
      tabs.push({
        id: 'subscription',
        label: 'invoicing.newinvoice.tab.subscription',
        isCurrent: this.props.match.params.formId === formIdRoutes.subscription,
      });
    }
    return tabs;
  };

  checkSubscriptionAvailability = () => {
    return (
      this.props.isEnabled(Messages.Invoice_CreateSubscription) ||
      this.props.isDisabledByReason(
        Messages.Invoice_CreateSubscription,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      )
    );
  };

  onChangeTab = (_, currentTab) => {
    this.setState({ currentTab }, () => {
      this.handleProjectChange(this.state.fields.projectId);
    });
  };

  checkRoute = () => {
    const isCorrectRoute = Object.values(formIdRoutes).includes(
      this.props.match.params.formId
    );

    if (!isCorrectRoute) {
      this.setFormRoute();
    }
  };

  setFormTab = () => {
    if (!this.state) return;
    const { match } = this.props;
    const formRoutes = invert(formIdRoutes);
    const tabs = this.getTabs();
    const availableTab = tabs.find(
      (tab) => tab.id === formRoutes[match.params.formId]
    );
    if (formRoutes[match.params.formId] !== this.state.currentTab) {
      this.onChangeTab(null, availableTab?.id || tabs[0].id);
    }
  };

  setFormRoute = () => {
    if (
      !this.state.currentTab ||
      this.props.match.params.formId === formIdRoutes[this.state.currentTab]
    )
      return;

    this.props.history.push(
      path(`/invoices/${formIdRoutes[this.state.currentTab]}`)
    );
  };

  onEvent = ({ data, name }) => {
    const { status } = data.rpc;

    if (name === Messages.InvoicesUpdated) {
      const {
        fields: { paymentId },
      } = this.state;
      let link: string;
      if (Array.isArray(data.payload)) {
        link = data.payload?.find((item) => item.paymentId === paymentId)?.link;
      } else {
        link = data.payload.paymentId === paymentId ? data.payload.link : '';
      }

      this.setState({ link });
    }

    if (status !== 'success') return;

    if (
      name === Messages.Invoice_Create ||
      name === Messages.Invoice_CreateSubscription
    ) {
      const { invoices, dispatch } = this.props;
      if (invoices?.items.length) {
        dispatch(addDataItem('invoices', data.payload));
        this.sendMetric();
      }
      this.setState({
        isSuccessful: true,
      });
    } else if (name === Messages.Invoice_FormConfig) {
      if (!data?.payload) return;

      const {
        fields: { projectId },
      } = this.state;

      const { isMotoFieldEnabled } = data.payload;
      const paymentMethodsList = getMethods(data.payload.paymentMethods);

      this.setConfigSettings(isMotoFieldEnabled, paymentMethodsList);

      this.cacheService.setData(this.getCachedName(projectId), {
        paymentMethods: paymentMethodsList,
        isMotoFieldEnabled,
      });
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  isPaymentIdGeneratorEnabled: state.user.isPaymentIdGeneratorEnabled,
  isInvoiceCardOperationTypeManageAvailable:
    state.user.isInvoiceCardOperationTypeManageAvailable,
  invoiceProject: state.filtersValues.invoiceProject,
  motoType: state.filtersValues.motoType,
  subscriptionPaymentPeriod: state.filtersValues.subscriptionPaymentPeriod,
  invoices: state.data.invoices,
});

export default withRouter(
  connect(mapStateToProps)(
    addPermissions(addTranslation(InvoiceCreateContainer))
  )
);
