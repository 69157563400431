import BaseMessageHandler from '../BaseMessageHandler';
import { updateDataItem } from 'actions/getData';

export default class User_Update extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;
    const { payload } = message;

    dispatch(updateDataItem('users', 'id', this.getTableFormat(payload)));
  };

  getTableFormat = (user) => {
    const {
      id,
      merchants,
      projectsAvailable,
      email,
      roles,
      status,
      isChargebackMailingEnabled,
      wlId,
      legalEntities,
    } = user;
    return {
      id,
      email,
      merchants: merchants?.map(({ merchantId, text }) => ({
        id: merchantId,
        name: text,
      })),
      projects: projectsAvailable?.map(({ projectId, text }) => ({
        id: projectId,
        name: text,
      })),
      legalEntities: legalEntities.map((item) => item.text),
      status,
      roles,
      isChargebackMailingEnabled,
      wlId,
    };
  };
}
