import React, { Component } from 'react';
import { connect } from 'react-redux';

import { StoreProps } from 'store';
import { closeModal } from 'actions/modal';
import Modal from './Modal';

import modalsList from './modalList';
import Confirm from 'components/modal/modalList/Confirm';

interface ConnectedProps {
  modal: {
    content: object;
    theme: string;
    isOpened: boolean;
    onClose?: () => void;
    modalId: string;
    callback?: (data: any) => void;
    onClickAwayClose: boolean;
    customClassName?: string;
    needCloseButton?: boolean;
    tooltipId?: string;
    invertButtons?: boolean;
    confirm?: {
      title: string;
      text: string;
    };
  };
}

type Props = ConnectedProps & StoreProps;

class ModalContainer extends Component<Props> {
  render() {
    const {
      modal: {
        isOpened,
        modalId,
        content,
        theme,
        onClickAwayClose = true,
        customClassName,
        needCloseButton = true,
        tooltipId,
        invertButtons,
        confirm,
        callback,
      },
    } = this.props;
    const ModalContent = modalsList[modalId];

    if (!ModalContent) return null;
    return (
      <Modal
        isOpened={isOpened}
        modalId={modalId}
        theme={theme}
        customClassName={customClassName}
        onClickAwayClose={onClickAwayClose}
        needCloseButton={needCloseButton}
        tooltipId={tooltipId}
        onClose={() => this.onClose(false)}>
        {
          <ModalContent
            callback={this.callback}
            content={content}
            invertButtons={invertButtons}>
            {confirm ? (
              <Confirm content={confirm} callback={callback || (() => null)} />
            ) : null}
          </ModalContent>
        }
      </Modal>
    );
  }

  onClose = (isCallback) => {
    const { modal, dispatch } = this.props;
    dispatch(closeModal());
    modal.onClose && modal.onClose();

    if (!isCallback) {
      this.callback(false);
    }
  };

  callback = (data) => {
    const {
      modal: { callback },
    } = this.props;

    this.onClose(true);

    if (callback) {
      return callback(data);
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  modal: state.modal,
});

export default connect(mapStateToProps)(ModalContainer);
