const parseValue = (value) => {
  value = value.replace(/""/g, '"');
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }

  return value;
};

const CSVToArray = (data) => {
  // eslint-disable-next-line unicorn/no-unsafe-regex
  const re = /(;|\r?\n|\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^;\r\n]*))/gi;
  const result = [[]];
  let matches: any;
  while ((matches = re.exec(data))) {
    if (matches[1].length && matches[1] !== ';') result.push([]);
    result[result.length - 1].push(
      // @ts-ignore
      parseValue(matches[2] !== undefined ? matches[2] : matches[3])
    );
  }
  return result.filter((row) => !(row.length === 1 && row[0] === ''));
};

function CSVArrayToObject(csvArray) {
  const [header] = csvArray.splice(0, 1);
  return csvArray.map((row) => {
    let index = 0;
    return row.reduce((acc, val) => {
      acc[header[index]] = val;
      index += 1;
      return acc;
    }, {});
  });
}

export { CSVToArray, CSVArrayToObject };
