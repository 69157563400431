import React, { PureComponent } from 'react';
import Tooltip from 'react-tooltip';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import PageTemplate from 'components/pageTemplate/PageTemplate';
import Animation from 'components/ui/animation';
import CustomScrollbar from 'components/ui/customScrollbar';
import NoContent from 'components/ui/noContent';
import Button from 'components/ui/button';
import RoundButton from 'components/ui/roundButton';
import OperationInfo from 'components/paymentCard/operationInfo';
import OperationItem from 'components/paymentCard/operationItem/OperationItem';
import SavedRecurring from 'types/savedEntity/SavedRecurring';
import Teaser from 'components/ui/teaser';
import CopyToClipboard from 'components/ui/copyToClipboard';
import PaymentInfo from 'components/paymentCard/paymentInfo';
import Loader from 'components/ui/loader';
import SettingsHistory from './settings/settingsHistory';

import DateHelpers from 'helpers/Date';
import RecurringCardPageUI from 'types/RecurringCardPageUI';
import { RecurringSettingsHistory } from './settings/RecurringSettingsTypes';
import statusList from 'constants/statusList';
import './recurringCard.scss';

enum paymentTypes {
  init,
  subs,
}

enum sortTypes {
  asc,
  desc,
}

interface Props extends IntlProps {
  recurring: SavedRecurring;
  syncPaymentUI: (id: string, ui: RecurringCardPageUI) => void;
  historyList: RecurringSettingsHistory;
  isSettingsLoading: boolean;
  isLoading: boolean;
  openSettingsForm: () => void;
  isShowHistory: boolean;
  toggleHistory: () => void;
}

interface State {
  initOperationsIsOpened: boolean;
  subOperationsSort: sortTypes;
  currentPaymentType: paymentTypes;
  selectedOperationId: string | undefined;
  pageUI: RecurringCardPageUI | undefined;
  isOpenedPaymentInfo: boolean;
}

class RecurringCard extends PureComponent<Props, State> {
  id: string;

  constructor(props) {
    super(props);

    const { recurring } = props;

    this.state = {
      initOperationsIsOpened: false,
      subOperationsSort: recurring?.ui?.subOperationsSort,
      pageUI: { ...recurring?.ui },
      currentPaymentType: 0,
      selectedOperationId: undefined,
      isOpenedPaymentInfo: this.setPaymentInfoState(),
    };

    this.id = props.recurring?.id;
  }

  componentDidMount(): void {
    Tooltip.rebuild();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState): void {
    if (
      this.state.initOperationsIsOpened !== prevState.initOperationsIsOpened
    ) {
      Tooltip.rebuild();
    }
    if (!prevProps.recurring?.data && this.props.recurring?.data) {
      const {
        recurring: { ui },
      } = this.props;
      this.id = this.props.recurring.id;

      const paymentType = this.getInitialPaymentType(sortTypes.desc);

      this.setState({
        subOperationsSort: ui?.subOperationsSort,
        pageUI: ui && { ...ui },
        currentPaymentType: paymentType,
        selectedOperationId: this.getInitialSelectedOperation(paymentType),
      });
    }
  }

  componentWillUnmount(): void {
    const { syncPaymentUI } = this.props;
    const { pageUI } = this.state;
    window.removeEventListener('resize', this.handleResize);
    if (!pageUI) return;
    syncPaymentUI(this.id, pageUI);
  }

  render() {
    const {
      recurring,
      getTranslate,
      historyList,
      isSettingsLoading,
      isLoading,
      isShowHistory,
    } = this.props;
    const { isOpenedPaymentInfo } = this.state;
    let paymentIdWithCopyToClipboard: string | JSX.Element;
    if (recurring?.data?.registrationInfo?.payment?.paymentId) {
      paymentIdWithCopyToClipboard = (
        <div className='payment-card__payment-id-with-copy-to-clipboard'>
          <span className='payment-card__payment-id'>
            {recurring.data.registrationInfo.payment.paymentId}
          </span>
          <CopyToClipboard
            text={recurring.data.registrationInfo.payment.paymentId}
            customClass='ui-copy-to-clipboard_color_white'
          />
        </div>
      );
    } else {
      paymentIdWithCopyToClipboard = ``;
    }
    return (
      <PageTemplate.Container
        customClass={classNames('payment-card payment-card_recurring', {
          'payment-card_recurring-settings': isShowHistory,
        })}>
        <div className='payment-card__inner'>
          <Animation>
            {recurring?.data?.error ? (
              <NoContent text={getTranslate('common.noMatches.text')} />
            ) : (
              <div className='payment-card__wrapper'>
                <div className='payment-card__item payment-card__item_prev'>
                  <Teaser
                    isLoading={isLoading}
                    title={getTranslate(
                      'subscriptions.subscriptionCard.name.header'
                    )}
                    status={
                      recurring?.data?.registrationInfo?.payment
                        .recurring_status
                    }
                    text={paymentIdWithCopyToClipboard}>
                    <Button
                      text={getTranslate(
                        'payments.single.viewPaymentInfo.button'
                      )}
                      disabled={isShowHistory}
                      onClick={this.openPaymentInfo}
                      status='light'
                      size='normal'
                      customClass='payment-card__mobile-button'
                    />
                    <Button
                      text={getTranslate(
                        'subscriptions.subscriptionCard.goToSettings.button'
                      )}
                      loading={isSettingsLoading}
                      status='light'
                      onClick={this.props.openSettingsForm}
                      customClass='payment-card__teaser-button'
                    />
                    <Button
                      text={getTranslate(
                        isShowHistory
                          ? 'subscriptions.subscriptionCard.backToOperations.button'
                          : 'subscriptions.subscriptionCard.goToHistory'
                      )}
                      loading={isSettingsLoading}
                      status='primary'
                      onClick={this.props.toggleHistory}
                      customClass='payment-card__teaser-button ui-button_status-primary_border-light'
                    />
                  </Teaser>
                </div>
                <div className='payment-card__item payment-card__item_operations utils-flex-column'>
                  {!isShowHistory && this.renderOperations()}
                  {!isShowHistory && this.renderRegistrations()}
                </div>
                {isShowHistory ? (
                  <div className='payment-card__item payment-card__item_operation-info'>
                    <SettingsHistory
                      list={historyList}
                      isLoading={isSettingsLoading}
                    />
                  </div>
                ) : (
                  <>
                    <div className='payment-card__item payment-card__item_operation-info'>
                      {this.renderOperationInfo()}
                    </div>
                    {isOpenedPaymentInfo && (
                      <div className='payment-card__item payment-card__item_payment-info'>
                        {this.renderPaymentInfo()}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </Animation>{' '}
        </div>
      </PageTemplate.Container>
    );
  }

  getText = () => {
    const { recurring } = this.props;
    if (
      recurring?.data?.registrationInfo?.payment.recurring_status ===
      statusList.notSet
    ) {
      return {
        title: 'subscriptions.single.noRecurring.label',
        text: 'subscriptions.single.recurring.hint',
      };
    } else if (
      recurring?.data?.registrationInfo?.payment.recurring_status ===
        statusList.active &&
      !recurring?.data?.subscriptionInfo
    ) {
      return {
        title: 'subscriptions.single.recurringNotSet.label',
        text: 'subscriptions.single.recurringNotSet.hint',
      };
    } else if (
      recurring?.data?.registrationInfo?.payment.recurring_status ===
        statusList.cancelled ||
      recurring?.data?.registrationInfo?.payment.recurring_status === 'canceled'
    ) {
      return {
        title: 'subscriptions.single.recurringCancelled.label',
        text: 'subscriptions.single.recurringCancelled.hint',
      };
    }

    return {
      title: '',
      text: '',
    };
  };

  renderOperationInfo = () => {
    const { isLoading, getTranslate } = this.props;
    const selectedOperation = this.getSelectedOperation();
    const data = this.getViewedPayment();
    return (
      <div className='payment-card__operation-info card utils-flex-column'>
        <div className='card__header'>
          <div className='card__subtext'>
            {getTranslate('payments.single.operationCard.header')}
          </div>
          <div className='card__title-l'>
            ID {selectedOperation?.operationId}{' '}
            <CopyToClipboard
              text={selectedOperation?.operationId}
              iconSize={14}
            />
          </div>
        </div>
        <div className='payment-card__content'>
          {isLoading ? (
            <Loader type='bung' />
          ) : (
            <CustomScrollbar customHeight='100%'>
              <div className='card__container card__content'>
                <OperationInfo
                  operation={selectedOperation}
                  transactionId={data && data.payment.transactionId}
                  pageUI={this.state.pageUI}
                  onChangePageUI={this.savePageUI}
                  modern
                />
              </div>
            </CustomScrollbar>
          )}
        </div>
      </div>
    );
  };

  renderPaymentInfo = () => {
    const { pageUI } = this.state;
    const data = this.getViewedPayment();

    return (
      <PaymentInfo
        theme='brand'
        isLoading={this.props.isLoading}
        closePaymentInfo={this.closePaymentInfo}
        data={data}
        pageUI={pageUI}
        onChangePageUI={this.savePageUI}
      />
    );
  };

  renderOperations() {
    const { recurring, getTranslate, isLoading } = this.props;

    const { subOperationsSort, initOperationsIsOpened } = this.state;
    const paymentId = recurring?.data?.subscriptionInfo?.payment.paymentId;
    const operations = this.getSubOperations();
    const { title, text } = this.getText();

    return (
      <div
        className={classNames('recurring-card__payments', {
          'recurring-card__block_50':
            operations && (isLoading || initOperationsIsOpened),
          'recurring-card__payments_empty': !operations && !isLoading,
        })}>
        <div className='card utils-height-100 utils-flex-column'>
          <div
            className={classNames('card__header', {
              card__header_bordered: !isLoading && !!operations,
            })}>
            <div className='card__title'>
              {paymentId || isLoading
                ? getTranslate('subscriptions.single.recurring.label')
                : getTranslate(title)}
            </div>
            {paymentId && (
              <div className='card__subtext utils-overflow-dots'>
                {getTranslate('subscriptions.single.Id.label')} {paymentId}
              </div>
            )}
            {paymentId && (
              <RoundButton
                size={26}
                icon='im-Sort'
                iconSize={12}
                onClick={this.changeSort}
                customClass={classNames('payment-card__header-btn', {
                  'payment-card__header-btn_inverse':
                    subOperationsSort === sortTypes.asc,
                })}
              />
            )}
          </div>

          <div className='recurring-card__content'>
            {isLoading ? (
              <Loader customClass='recurring-card__loader' type='bung' />
            ) : (
              <CustomScrollbar>
                <div className='card__content'>
                  {operations ? (
                    operations.map((operation, index) => {
                      return (
                        <OperationItem
                          key={index}
                          data={operation}
                          isCurrent={this.isCurrentOperation(operation)}
                          onSelect={(operationId) =>
                            this.changeViewedOperation(
                              operationId,
                              paymentTypes.subs
                            )
                          }
                          customClass='recurring-card__operations-item'
                        />
                      );
                    })
                  ) : (
                    <div className='recurring-card__text'>
                      <div className='card__container'>
                        {getTranslate(text)}
                      </div>
                    </div>
                  )}
                </div>
              </CustomScrollbar>
            )}
          </div>
        </div>
      </div>
    );
  }

  getSubOperations = (subOperationsSort = this.state.subOperationsSort) => {
    const { recurring } = this.props;
    if (recurring?.data?.subscriptionInfo) {
      return recurring?.data.subscriptionInfo.operations.sort((o1, o2) => {
        const date1: number = +DateHelpers.createDate(
          o1.creationDate,
          'datetime'
        ).toDate();
        const date2: number = +DateHelpers.createDate(
          o2.creationDate,
          'datetime'
        ).toDate();
        if (subOperationsSort === sortTypes.asc) {
          return date1 - date2;
        }
        return date2 - date1;
      });
    }
  };

  hasToggleMode = (): boolean => {
    const subs = this.getSubOperations();
    return subs && subs.length;
  };

  renderRegistrations() {
    const { recurring, getTranslate, isLoading } = this.props;
    const paymentId = recurring?.data?.registrationInfo?.payment.paymentId;
    const { initOperationsIsOpened } = this.state;

    return (
      <div
        className={classNames('recurring-card__registrations', {
          'recurring-card__block_50': isLoading,
          'recurring-card__registrations_opened': initOperationsIsOpened,
        })}>
        <div className='card utils-height-100 utils-flex-column'>
          <div
            className={classNames('card__header utils-relative', {
              card__header_bordered:
                !isLoading &&
                recurring?.data?.registrationInfo.operations.length &&
                initOperationsIsOpened,
            })}>
            <div className='card__title'>
              {getTranslate('subscriptions.single.init.label')}
            </div>
            <div className='card__subtext utils-overflow-dots'>
              {paymentId &&
                `${getTranslate('subscriptions.single.Id.label')} ${paymentId}`}
            </div>
            {recurring?.data?.registrationInfo.operations.length && (
              <RoundButton
                size={26}
                icon='im-Arrow-down'
                iconSize={10}
                customClass={classNames('payment-card__header-btn', {
                  'payment-card__header-btn_inverse': initOperationsIsOpened,
                })}
                onClick={this.toggleRegistrationOperations}
              />
            )}
          </div>

          <div className='recurring-card__content'>
            {isLoading ? (
              <Loader customClass='recurring-card__loader' type='bung' />
            ) : (
              initOperationsIsOpened && (
                <CustomScrollbar>
                  <div className='card__content'>
                    {this.renderRegistrationContent()}
                  </div>
                </CustomScrollbar>
              )
            )}
          </div>
        </div>
      </div>
    );
  }

  renderRegistrationContent() {
    const { recurring } = this.props;

    if (!recurring?.data?.registrationInfo?.operations) {
      return null;
    }
    return (
      <>
        {recurring.data.registrationInfo.operations.map((operation, index) => {
          return (
            <OperationItem
              key={index}
              data={operation}
              isCurrent={this.isCurrentOperation(operation)}
              onSelect={(operationId) =>
                this.changeViewedOperation(operationId, paymentTypes.init)
              }
            />
          );
        })}
      </>
    );
  }

  isCurrentOperation = (operation): boolean => {
    const { selectedOperationId } = this.state;
    return operation.operationId === selectedOperationId;
  };

  getViewedPayment = (paymentType = this.state.currentPaymentType) => {
    const { recurring } = this.props;
    if (!recurring || !recurring.data) return;

    if (recurring.data.error) {
      return recurring.data;
    }

    return paymentType === paymentTypes.init
      ? recurring.data.registrationInfo
      : recurring.data.subscriptionInfo;
  };

  getSelectedOperation() {
    const data = this.getViewedPayment();
    const { selectedOperationId } = this.state;

    if (!data) return null;
    const { operations } = data;

    return (
      operations.find(
        ({ operationId }) => selectedOperationId === operationId
      ) || operations[0]
    );
  }

  changeViewedOperation = (operationId: string, dataType: paymentTypes) => {
    this.setState({
      currentPaymentType: dataType,
      selectedOperationId: operationId,
    });
    this.savePageUI({ selectedOperationId: operationId });
  };

  changeSort = () => {
    const { subOperationsSort } = this.state;
    const { asc, desc } = sortTypes;
    const newSort = subOperationsSort === sortTypes.desc ? asc : desc;
    this.setState({
      subOperationsSort: newSort,
    });
    this.savePageUI({ subOperationsSort: newSort });
  };

  toggleRegistrationOperations = () => {
    const { initOperationsIsOpened } = this.state;
    const newState = !initOperationsIsOpened;

    this.setState({
      initOperationsIsOpened: newState,
    });
    this.savePageUI({ initOperationsIsOpened: newState });
  };

  savePageUI = (object) => {
    this.setState((prevState) => ({
      ...prevState,
      pageUI: {
        ...prevState.pageUI,
        ...object,
      },
    }));
  };

  getInitialPaymentType = (subOperationsSort: sortTypes) => {
    const {
      recurring: { data, ui },
    } = this.props;
    const selectedOperationId = ui?.selectedOperationId;
    const subOperations = this.getSubOperations(subOperationsSort);

    if (!selectedOperationId || !data) {
      return subOperations ? paymentTypes.subs : paymentTypes.init;
    }

    if (
      (subOperations || []).find(
        ({ operationId }) => operationId === selectedOperationId
      )
    ) {
      return paymentTypes.subs;
    }

    return paymentTypes.init;
  };

  getInitialSelectedOperation = (paymentType: paymentTypes) => {
    const { ui } = this.props.recurring;
    if (ui?.selectedOperationId) {
      return ui.selectedOperationId;
    }

    const payment = this.getViewedPayment(paymentType);
    if (payment && payment.operations) {
      return payment?.operations[0].operationId;
    }
  };

  handleResize = () => {
    if (document.body.clientWidth < 1024) {
      this.closePaymentInfo();
    } else {
      this.openPaymentInfo();
    }
  };

  openPaymentInfo = () => {
    this.setState({ isOpenedPaymentInfo: true });
  };

  closePaymentInfo = () => {
    this.setState({ isOpenedPaymentInfo: false });
  };

  setPaymentInfoState = () => {
    return document.body.clientWidth >= 1024;
  };
}

export default addTranslation(RecurringCard);
