import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import EmptyState from 'components/ui/emptyState';
import { withRouter } from 'react-router-dom';
import { WithRouterProps } from 'decorators/withRouter';
import textsBySection from 'pages/fileDownload/textsBySection';
import path from 'helpers/path';
import { connect } from 'react-redux';
import { AnyObject } from 'types/Common';
import { RootState } from 'store';
import './fileDownload.scss';
import LocalStorage from 'helpers/LocalStorage';

interface ConnectedProps {
  user: AnyObject;
}

type Props = IntlProps & WithRouterProps & ConnectedProps;

class FileDownload extends PureComponent<Props> {
  render() {
    const params = new URLSearchParams(this.props.location.search);
    const fileType = params.get('fileType');

    if (!fileType) return null;

    return (
      <div className='file-download'>
        <EmptyState
          {...textsBySection[fileType]}
          onButtonClick={this.onButtonClick}
        />
      </div>
    );
  }

  onButtonClick = () => {
    const params = new URLSearchParams(this.props.location.search);
    const fallbackUrl = params.get('fallbackUrl');

    if (!fallbackUrl) return;

    LocalStorage.set('goToFromExpired', fallbackUrl);

    this.props.history.push(path('/login'));
  };
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

export default connect(mapStateToProps)(
  withRouter(addTranslation(FileDownload))
);
