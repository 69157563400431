import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import InputAmount from 'components/ui/inputAmount';
import CustomSelect from 'components/ui/customSelect';
import InputDate from 'components/ui/inputDate';
import Form from 'components/ui/form';
import DateHelpers from 'helpers/Date';
import { RecurringSettingsFields } from 'pages/recurring/card/settings/RecurringSettingsTypes';
import { AnyObject } from 'types/Common';
import { Dictionary } from 'types/FilterValue';
import Loader from 'components/ui/loader';
import ModalTitle from 'components/modal/components/ModalTitle';
import ModalFooter from 'components/modal/components/ModalFooter';
import ModalContent from 'components/modal/components/ModalContent';
import Confirm from 'components/modal/modalList/Confirm';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import Date from 'helpers/Date';
import './recurringSettingsForm.scss';
import Tooltip from 'react-tooltip';

interface Props {
  form: RecurringSettingsFields;
  user: AnyObject;
  validationErrors: AnyObject;
  subscriptionPeriod: Dictionary;
  isChangeable: boolean;
  isEditable: boolean;
  isEditMode: boolean;
  canEditPaymentId: boolean;
  isSaving: boolean;
  isConfirm: boolean;
  onChangeField: (field: keyof RecurringSettingsFields, value) => void;
  onExitEdit: () => void;
  onEnterEdit: () => void;
  confirmExit: (isConfirm) => void;
  onCancel: () => void;
  onSubmitForm: () => void;
}

class RecurringSettingsForm extends PureComponent<Props & IntlProps> {
  componentDidUpdate(prevProps: Readonly<Props & IntlProps>) {
    if (prevProps.isEditMode !== this.props.isEditMode) {
      setTimeout(() => {
        Tooltip.rebuild();
      }, 1000);
    }
  }

  render() {
    const {
      user,
      subscriptionPeriod,

      isEditMode,
      canEditPaymentId,
      onChangeField,
      onEnterEdit,
      onExitEdit,
      onCancel,
      onSubmitForm,
      getTranslate,
      isSaving,
      isChangeable,
      isEditable,
      validationErrors,
    } = this.props;
    if (this.props.isConfirm) {
      return (
        <Confirm
          content={{
            title: 'subscriptions.modal.cancel.header',
            text: 'subscriptions.modal.cancel.text',
          }}
          callback={this.props.confirmExit}
        />
      );
    }

    const disabled = isSaving || !isEditMode;
    const startDate = this.getRecurringStartDate();
    const isIncorrectStartDate =
      this.props.form.recurringStartDate && !startDate;
    const startDateError = isIncorrectStartDate
      ? 'subscriptions.settings.nextDateNotPossible'
      : '';

    return (
      <Form
        customClass='recurring-settings-form card utils-flex-column'
        onSubmit={onSubmitForm}>
        <ModalTitle>
          {getTranslate('subscription.single.settings.header')}
        </ModalTitle>
        <ModalContent customClass='recurring-settings-form__inner  utils-flex-column'>
          {isSaving && <Loader customClass='recurring-settings-form__loader' />}
          {!isEditMode && (
            <Button
              type='button'
              status='danger-outline'
              text={getTranslate('subscriptions.cancelRecurring.button')}
              disabled={!isChangeable || !this.props.form.isCancelable}
              tooltip={getTranslate('subscriptions.cancelRecurring.tooltip')}
              tooltipId='recurring-settings-form'
              onClick={onCancel}
              customClass='recurring-settings-form__cancel-button'
            />
          )}
          <div className='recurring-settings-form__content'>
            <div className='recurring-settings-form__item recurring-settings-form__item_subtitle'>
              {getTranslate('subscriptions.additionalDetails.title')}
            </div>
            <Input
              id='recurringScheduledPaymentId'
              label={getTranslate(
                'subscriptions.settings.recurringPaymentId.label'
              )}
              placeholder={getTranslate(
                'subscriptions.settings.recurringPaymentId.label'
              )}
              value={this.props.form.recurringScheduledPaymentId || ''}
              onChange={(event) =>
                onChangeField('recurringScheduledPaymentId', event.target.value)
              }
              disabled={disabled || !canEditPaymentId}
              error={validationErrors.recurringScheduledPaymentId}
              customClass='recurring-settings-form__item'
              modern
            />
            <div className='recurring-settings-form__item recurring-settings-form__item_row'>
              <InputAmount
                id='recurringAmount'
                label={getTranslate('subscriptions.settings.amount.label')}
                placeholder='0.00'
                value={this.props.form.recurringAmount || ''}
                onChange={(event) =>
                  onChangeField('recurringAmount', event.target.value)
                }
                disabled={disabled}
                error={validationErrors.recurringAmount}
                modern
              />
              <Input
                id='recurringCurrency'
                label={getTranslate('subscriptions.settings.currency.label')}
                placeholder={getTranslate(
                  'subscriptions.settings.currency.label'
                )}
                disabled={true}
                value={this.props.form.recurringCurrency || ''}
                onChange={() => null}
                error={validationErrors.recurringCurrency}
                tooltip={getTranslate('select.currency.favorite.tooltip')}
                tooltipId='recurring-settings-form'
                modern
              />
            </div>
            <div className='recurring-settings-form__item recurring-settings-form__item_subtitle'>
              {getTranslate('subscriptions.schedule.title')}
            </div>
            <div className='recurring-settings-form__item recurring-settings-form__item_row'>
              <Input
                id='recurringInterval'
                label={getTranslate('subscriptions.settings.interval.label')}
                placeholder={getTranslate(
                  'subscriptions.settings.interval.placeholder'
                )}
                disabled={disabled}
                value={this.props.form.recurringInterval || ''}
                onChange={(e) =>
                  onChangeField('recurringInterval', e.target.value)
                }
                error={validationErrors.recurringInterval}
                modern
              />
              <CustomSelect
                id='recurringPeriod'
                label={getTranslate(
                  'subscriptions.settings.recurringCycle.label'
                )}
                isLoading={subscriptionPeriod?.isLoading}
                isDisabled={disabled}
                options={this.getList()}
                value={this.getValue()}
                onChange={({ value }) => {
                  onChangeField('recurringPeriod', value);
                }}
                customClass='recurring-settings-form__item'
                error={validationErrors.recurringPeriod}
                modern
              />
            </div>
            <InputDate
              id='recurringStartDate'
              title={getTranslate(
                'subscriptions.settings.firstRecurring.label'
              )}
              selectedDate={isIncorrectStartDate ? '' : startDate}
              onChange={(date) => onChangeField('recurringStartDate', date)}
              minDate={DateHelpers.getFormat(
                DateHelpers.getDateWithTimezone(user.timezone),
                'datetime'
              )}
              maxDate={
                this.props.form.recurringEndDate ||
                DateHelpers.getFormat(DateHelpers.addYears(1), 'datetime')
              }
              isDisabled={disabled}
              customClass='recurring-settings-form__item'
              error={
                validationErrors.recurringStartDate ||
                getTranslate(startDateError, {
                  date: this.props.form.recurringStartDate,
                })
              }
              withCloseButton={false}
              containerSelector='.recurring-settings-form__content'
              tooltipId='recurring-settings-form'
              tooltipPlace='left'
              modern
              closeAfterSelect
            />

            <InputDate
              id='recurringEndDate'
              title={getTranslate('subscriptions.settings.lastRecurring.label')}
              selectedDate={this.props.form.recurringEndDate}
              onChange={(date) => onChangeField('recurringEndDate', date)}
              isDisabled={true}
              withTime={false}
              maxDate='none'
              customClass='recurring-settings-form__item'
              error={validationErrors.recurringEndDate}
              modern
            />

            <Input
              id='recurringDescription'
              label={getTranslate('subscriptions.settings.description.label')}
              placeholder={getTranslate(
                'subscriptions.settings.description.placeholder'
              )}
              maxLength={150}
              type='textarea'
              resize={false}
              value={this.props.form.recurringDescription || ''}
              onChange={(event) =>
                onChangeField('recurringDescription', event.target.value)
              }
              disabled={disabled}
              customClass='recurring-settings-form__item'
              error={validationErrors.recurringDescription}
              modern
            />
          </div>
        </ModalContent>
        <ModalFooter customClass='recurring-settings-form__buttons'>
          {isEditMode ? (
            <>
              <Button
                disabled={isSaving}
                type='button'
                status='outline'
                text={getTranslate('common.cancel.button')}
                onClick={onExitEdit}
              />
              <Button
                loading={isSaving}
                type='submit'
                status='primary'
                text={getTranslate('common.save.button')}
              />
            </>
          ) : (
            <Button
              type='button'
              status='primary'
              text={getTranslate('subscriptions.updateRecurring.button')}
              tooltip={getTranslate('subscriptions.updateRecurring.tooltip')}
              tooltipId='recurring-settings-form'
              disabled={!isEditable || isEditMode}
              onClick={onEnterEdit}
            />
          )}
        </ModalFooter>
      </Form>
    );
  }

  getValue = () => {
    const selectedItem = this.props.subscriptionPeriod?.list?.find((item) => {
      return item.id === this.props.form.recurringPeriod;
    });
    if (selectedItem) {
      return {
        label: this.props.getTranslate(selectedItem.text as string, {
          amount: this.props.form.recurringInterval || 0,
        }),
        value: selectedItem.id,
      };
    }
    return null;
  };

  getList = () => {
    return getCustomSelectItems({
      list: this.props.subscriptionPeriod?.list,
      getLabel: (item) =>
        this.props.getTranslate(item.text, {
          amount: this.props.form.recurringInterval || 0,
        }),
      additionalParam: 'text',
    });
  };

  getRecurringStartDate = () => {
    const { form } = this.props;
    if (!form || !form?.recurringStartDate) return '';
    if (!form?.recurringEndDate) return form.recurringStartDate;
    const maxDate = Date.getMaxDate(
      form.recurringStartDate,
      form.recurringEndDate,
      'date'
    );

    if (
      maxDate ===
      Date.getFormat(Date.createDate(form.recurringStartDate, 'date'), 'date')
    )
      return '';
    return form.recurringStartDate;
  };
}

export default addTranslation(RecurringSettingsForm);
