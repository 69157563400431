import React from 'react';
import classNames from 'classnames';
import Link from 'components/ui/link';
import Button from 'components/ui/button';
import Form from 'components/ui/form';

interface ColumnProps {
  title: string;
  text?: string;
  customClass?: string;
}

interface ContainerProps {
  text?: string;
  onSubmit: () => void;
}

interface ContentProps {
  text?: string;
}

interface FooterProps {
  backUrl: string;
  backText: string;
  canCreate: boolean;
  repeatCreateButtonText: string;
  createButtonText: string;
  isLoading: boolean;
  onBack?: () => void;
}

const Container: React.FC<ContainerProps> = (props) => {
  return (
    <Form customClass='ui-form-fields__form' onSubmit={props.onSubmit}>
      {props.children}
    </Form>
  );
};

const Content: React.FC<ContentProps> = (props) => {
  return (
    <div className='ui-form-fields__container ui-form-fields__container_content ui-form-fields__content'>
      <div className='ui-form-fields__text'>{props.text}</div>
      <div className='ui-form-fields__inner'>{props.children}</div>
    </div>
  );
};

const GeneralColumn: React.FC<ColumnProps> = (props) => {
  return (
    <div className='ui-form-fields__col ui-form-fields__col_general'>
      <div className='ui-form-fields__col-title'>{props.title}</div>
      {props.children}
    </div>
  );
};

const AdditionalColumn: React.FC<ColumnProps> = (props) => {
  return (
    <div
      className={classNames(
        'ui-form-fields__col ui-form-fields__col_additional',
        props.customClass
      )}>
      <div className='ui-form-fields__col-title'>{props.title}</div>
      {props.children}
    </div>
  );
};

const Footer: React.VFC<FooterProps> = (props) => {
  return (
    <div className='ui-form-fields__container ui-form-fields__container_footer utils-flex'>
      <Link
        url={props.backUrl}
        onClick={props.onBack}
        className='ui-form-fields__back-btn ui-button ui-submitted-form__button ui-button_status-outline ui-button_size-normal'>
        {props.backText}
      </Link>
      <Button
        disabled={!props.canCreate}
        text={props.createButtonText}
        loading={props.isLoading}
        status='primary'
        type='submit'
        customClass='ui-form-fields__button'
      />
    </div>
  );
};

export default {
  Container,
  Content,
  GeneralColumn,
  AdditionalColumn,
  Footer,
};
