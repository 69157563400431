import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import ModalTitle from 'components/modal/components/ModalTitle';
import ModalContent from 'components/modal/components/ModalContent';
import NoContent from 'components/ui/noContent';
import { AnyObject } from 'types/Common';
import './redirectHistory.scss';

interface Props extends IntlProps {
  content: {
    redirectHistory: AnyObject;
  };
}

class RedirectHistory extends PureComponent<Props> {
  render() {
    const { getTranslate } = this.props;

    return (
      <>
        <ModalTitle customClass=''>
          {getTranslate('projects.urls.history.label')}
        </ModalTitle>
        <ModalContent customClass='redirect-history'>
          {this.renderHistory()}
        </ModalContent>
      </>
    );
  }

  renderHistory = () => {
    const {
      content: { redirectHistory },
      getTranslate,
    } = this.props;

    if (!redirectHistory || !redirectHistory?.rows) {
      return (
        <div className='redirect-history__no-data'>
          <NoContent />
        </div>
      );
    } else if (redirectHistory.rows.length === 0) {
      return (
        <span className='redirect-history__no-data'>
          {getTranslate('projects.urls.noHistory')}
        </span>
      );
    }
    const columns = Object.keys(redirectHistory.rows[0]).filter(
      (col) => col !== 'id' && col !== 'userId'
    );

    return (
      <>
        <div className='redirect-history__block-label'>
          {getTranslate(redirectHistory.title)}
        </div>
        <div className='redirect-history__table'>
          <div className='redirect-history__table-header'>
            <div className='redirect-history__table-row'>
              {columns.map((column) => (
                <div
                  className={`redirect-history__table-col redirect-history__table-col_${column}`}
                  key={column}>
                  {getTranslate(`projects.urls.history.${column}`)}
                </div>
              ))}
            </div>
          </div>
          <div className='redirect-history__table-body'>
            {redirectHistory.rows.map((row) => (
              <div className='redirect-history__table-row' key={row.id}>
                {columns.map((column) => (
                  <div
                    className={`redirect-history__table-col redirect-history__table-col_${column}`}
                    key={`${row.id}-${row[column]}`}>
                    <div
                      className={`redirect-history__table-key redirect-history__table-key_${column}`}
                      key={column}>
                      {getTranslate(`projects.urls.history.${column}`)}
                    </div>
                    <span className='redirect-history__value'>
                      {row[column]}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };
}

export default addTranslation(RedirectHistory);
