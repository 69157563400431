const MAX_ITEMS_TO_SHOW = 7;

export default (items) => {
  return items
    .map((item) => ({
      id: item.id,
      isFavorite: item.isFavorite,
    }))
    .sort((a, b) => (a.isFavorite && !b.isFavorite ? -1 : 1))
    .slice(0, MAX_ITEMS_TO_SHOW);
};
