import React, { PureComponent } from 'react';
import Loader from 'components/ui/loader';
import './support.scss';

class Support extends PureComponent {
  render() {
    return (
      <div className='page-auth-support'>
        <Loader />
      </div>
    );
  }
}

export default Support;
