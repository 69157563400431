import React from 'react';
import { throttle } from 'lodash';
import api from 'api/user';
import Token from 'helpers/Token';
import LocalStorage from 'helpers/LocalStorage';
import config from 'config';

interface Props {
  instanceId: string;
}

interface State {
  hasActivity: boolean;
}

class UserActivityWatcher extends React.PureComponent<Props, State> {
  pingIntervalId;
  nextPing;

  constructor(props) {
    super(props);
    this.state = { hasActivity: false };
    this.setUserActivity = throttle(this.setUserActivity, 1000);
  }

  componentDidMount() {
    this.setPing();
    this.init();
  }

  componentWillUnmount() {
    this.stopPing();
    this.destroy();
  }

  init = () => {
    document.addEventListener('keypress', this.setUserActivity);
    document.addEventListener('mousedown', this.setUserActivity);
    document.addEventListener('wheel', this.setUserActivity);
  };

  destroy = () => {
    document.removeEventListener('keypress', this.setUserActivity);
    document.removeEventListener('mousedown', this.setUserActivity);
    document.removeEventListener('wheel', this.setUserActivity);
  };

  setUserActivity = () => {
    this.setState({ hasActivity: true });
  };

  startPing = async () => {
    this.nextPing = new Date().valueOf() + config.PING_INTERVAL;
    LocalStorage.set('nextPing', this.nextPing);
    this.pingIntervalId = setInterval(async () => {
      try {
        const nextPing = LocalStorage.get('nextPing');
        if (nextPing !== this.nextPing) {
          await this.setPing();
        }

        await this.singlePing();
      } catch (e) {
        console.error(e);
      }
    }, config.PING_INTERVAL);
  };

  singlePing = async () => {
    const nextPingTime = LocalStorage.get('nextPing');
    const { hasActivity } = this.state;
    const { instanceId } = this.props;
    if (LocalStorage.get('instanceId') === instanceId) {
      this.nextPing =
        (nextPingTime || new Date().valueOf()) + config.PING_INTERVAL;
      LocalStorage.set('nextPing', this.nextPing);
      const { authToken } = await api.ping({ hasActivity });

      Token.setTokens({ authToken });
      this.setState({ hasActivity: false });
    }
  };

  stopPing = () => {
    clearInterval(this.pingIntervalId);
  };

  customPing = () => {
    const nextPingTime = LocalStorage.get('nextPing');
    let timeout = nextPingTime - new Date().valueOf();
    if (timeout < 0) {
      return this.startPing();
    }

    if (timeout > config.PING_INTERVAL) {
      timeout = config.PING_INTERVAL;
    }

    setTimeout(async () => {
      await this.singlePing();
      await this.startPing();
    }, timeout);
  };

  setPing = () => {
    const nextPing = LocalStorage.get('nextPing');
    if (nextPing) {
      this.stopPing();
      return this.customPing();
    }
    return this.startPing();
  };

  render() {
    return null;
  }
}
export default UserActivityWatcher;
