import React, { PureComponent } from 'react';
import { v4 as uuid } from 'uuid';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import CreateRequest from './createRequest';
import PageTemplate from 'components/pageTemplate';
import PaymentCardContainer from 'components/paymentCard';
import SavedEntitiesContainer from 'components/savedEntities';
import savedEntities from 'constants/savedEntities';
import BatchDetailContainer from './batch';
import PayoutListContainer from './list';
import SwitcherTabs from 'components/ui/switcherTabs';
import Messages from 'constants/rpcTypes';
import { QuickFiltersTableName } from 'types/QuickFilters';
import urlsMap from 'constants/urlsMap';
import { tableRoutes } from './routesMapper';
import tableNames from 'constants/tableNames';

interface Props extends IntlProps, WithPermissions {
  id: string;
  isCreating: boolean;
  isBatchDetail: boolean;
  tableName: QuickFiltersTableName;
  changeTable: (tableName: string) => void;
}

interface State {
  id: string;
  key: string;
}

class PayoutsPage extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      key: uuid(),
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.id !== state.id) {
      return {
        id: props.id,
        key: uuid(),
      };
    }
    return null;
  }

  render() {
    return (
      <PageTemplate.Main>
        <PageTemplate.Entities>
          <SwitcherTabs
            activeTab={this.props.tableName}
            sectionName='payouts'
            tabs={this.tabs}
            onClick={this.props.changeTable}
          />
          {(this.props.isEnabled(Messages.Payments_List) ||
            this.props.isEnabled(Messages.BulkPayouts_List)) && (
            <SavedEntitiesContainer
              entityKey={savedEntities.payouts}
              isFirstTab={false}
            />
          )}
        </PageTemplate.Entities>
        {this.renderContent()}
      </PageTemplate.Main>
    );
  }

  renderContent() {
    const { id, isCreating, isBatchDetail, tableName, changeTable } =
      this.props;
    const { key } = this.state;

    if (isCreating) {
      return (
        <PageTemplate.Container>
          <CreateRequest onBack={changeTable} />
        </PageTemplate.Container>
      );
    }

    if (id && !isBatchDetail) {
      return (
        <PaymentCardContainer
          savedEntity={savedEntities.payouts}
          id={id}
          key={key}
        />
      );
    }

    if (id && isBatchDetail) {
      return <BatchDetailContainer id={id} tableName={tableName} key={key} />;
    }
    return (
      <PageTemplate.Container>
        <PayoutListContainer tableName={tableName} />
      </PageTemplate.Container>
    );
  }

  get tabs() {
    const { isEnabled, getTranslate } = this.props;
    const tabs: { id: string; text: string; path?: string }[] = [];

    if (isEnabled(Messages.Payments_List)) {
      tabs.push({
        id: 'payouts',
        text: getTranslate('payouts.request.allReqBtn'),
      });
    }

    if (isEnabled(Messages.BulkPayouts_List)) {
      tabs.push({
        id: 'massPayouts',
        text: getTranslate('payouts.request.massPayouts.button'),
        path: `${urlsMap.payouts}/${tableRoutes[tableNames.massPayouts]}`,
      });
    }

    return tabs;
  }
}

export default addTranslation(addPermissions(PayoutsPage));
