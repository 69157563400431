import { forEach, isEmpty } from 'lodash';

const validateBeforeApply = (getTranslate, payload) => {
  const validationErrors = {
    selectedProjects: '',
    period: '',
  };
  const { selectedProjects, period } = payload;

  let isValid = true;
  if (isEmpty(selectedProjects) && isEmpty(period.from) && isEmpty(period.to)) {
    const ONE_SHOULD_BE_SELECTED = getTranslate(
      'analytics.mainView.severalChartsFilters.oneShouldBeSelected'
    );
    forEach(Object.keys(validationErrors), (key) => {
      validationErrors[key] = ONE_SHOULD_BE_SELECTED;
    });
    isValid = false;
  }

  return { errors: validationErrors, isValid: isValid };
};

export { validateBeforeApply };
