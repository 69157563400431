import React, { PureComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import DOMPurify from 'dompurify';
import './tooltipInfo.scss';

interface Props {
  place?: 'top' | 'right' | 'bottom' | 'left' | undefined;
}

class TooltipInfo extends PureComponent<Props> {
  private tooltipRef;

  static defaultProps = {
    place: 'top',
  };

  render() {
    const { place } = this.props;
    return (
      <div className='component-tooltip-info'>
        <ReactTooltip
          clickable
          effect='solid'
          place={place}
          type='info'
          ref={(el) => {
            this.tooltipRef = el;
          }}
          getContent={(content) => {
            return DOMPurify.sanitize(content);
          }}
          id='html'
          afterShow={() => {
            if (this.tooltipRef) {
              this.tooltipRef.updatePosition();
            }
          }}
        />
      </div>
    );
  }
}

export default TooltipInfo;
