const addClass = (element, containerClassName) => {
  element
    .closest(`.${containerClassName}`)
    ?.classList.add(`${containerClassName}_open-panel`);
};

const removeClass = (element, containerClassName) => {
  element
    .closest(`.${containerClassName}`)
    ?.classList.remove(`${containerClassName}_open-panel`);
};

export const toggleOpenClass = (targetSelector, isOpen) => {
  if (!targetSelector) return;
  const element = document.querySelector(targetSelector);
  if (isOpen) {
    addClass(element, 'quick-filters__item');
    addClass(element, 'quick-filters__col');
  } else {
    removeClass(element, 'quick-filters__item');
    removeClass(element, 'quick-filters__col');
  }
};
