import ActionReducer from 'types/ActionReducer';
import ACTIONS from 'constants/actionTypes';
import { MafState } from 'components/maf/MafTypes';

const initialState = {
  dictionaries: {},
  isDictionaryFetching: false,
  actionsBeforeUpdate: {},
  validatedTabs: {},
};

export const maf = (state: MafState = initialState, action: ActionReducer) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.GET_MAF_DICTIONARIES:
      return { ...state, isDictionaryFetching: true };
    case ACTIONS.SET_MAF_DICTIONARIES:
      return { ...state, dictionaries: payload, isDictionaryFetching: false };
    case ACTIONS.SET_BEFORE_UPDATE_ACTIONS:
      return { ...state, ...payload, actionsBeforeUpdate: payload };
    case ACTIONS.SHOW_VALIDITY_FOR_TABS:
      return { ...state, validatedTabs: payload };
    case ACTIONS.SET_MAF_STATUS:
      return { ...state, ...payload };
    case ACTIONS.RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

export default maf;
