import React from 'react';
import { connect } from 'react-redux';
import { ChargebackFlowType } from 'pages/chargebacks/ChargebacksTypes';
import ChargebackFlow from './ChargebackFlow';

interface OwnProps {
  chargebackTypeId: string;
  timeline: any;
}

interface ConnectedProps {
  user: any;
  isLoading: boolean;
  chargebackFlow: ChargebackFlowType;
}

type Props = OwnProps & ConnectedProps;

const ChargebackFlowContainer: React.VFC<Props> = ({
  chargebackFlow,
  user,
  isLoading,
  timeline,
}) => (
  <ChargebackFlow
    isLoading={isLoading}
    currentLanguage={user.interfaceLang}
    chargebackFlow={chargebackFlow}
    timeline={timeline}
  />
);

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => {
  const flows = state.filtersValues?.chargebackType?.list || [];
  return {
    user: state.user,
    isLoading: Boolean(!state.filtersValues),
    chargebackFlow: flows.find((flow) => flow.id === ownProps.chargebackTypeId),
  };
};

export default connect(mapStateToProps)(ChargebackFlowContainer);
