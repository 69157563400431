import React, { PureComponent } from 'react';
import Input from 'components/ui/input';
import CustomSelect from 'components/ui/customSelect';
import InputAmount from 'components/ui/inputAmount';
import DateUtils from 'helpers/Date';
import { AnyObject } from 'types/Common';

const YEAR_PERIOD = 20;

interface Value {
  value: string | number;
  label: string | number;
}

interface Props {
  fields: any[];
  validationErrors: AnyObject;
  onChangeField: (field: string, value: any) => void;
}

class FormConstructor extends PureComponent<Props> {
  render() {
    const fields = this.prepareFieldsForRender();
    return this.renderList(fields);
  }

  prepareFieldsForRender() {
    const { fields } = this.props;

    const group: any[] = [];
    let groupInserted = false;

    return fields.reduce((result, field) => {
      if (field.id === 'card.year' || field.id === 'card.month') {
        if (group[0]?.id === 'card.year') {
          group.unshift(field);
        } else {
          group.push(field);
        }
        if (!groupInserted) {
          result.push(group);
          groupInserted = true;
        }
      } else {
        result.push(field);
      }
      return result;
    }, []);
  }

  renderList(list) {
    return list.map((field, index) => {
      if (Array.isArray(field)) {
        return (
          <div key={index} className='ui-form-fields__item utils-flex'>
            {this.renderList(field)}
          </div>
        );
      }
      return field.type === 'hidden' ? null : this.renderRow(field);
    });
  }

  renderRow(field) {
    const Component: any = this.fieldConstructor(field);
    return (
      <div className='ui-form-fields__item' key={field.id}>
        {Component}
      </div>
    );
  }

  // eslint-disable-next-line complexity
  fieldConstructor = (field) => {
    const { onChangeField, validationErrors } = this.props;

    let commonParams: any = {
      id: field.id,
      type: field.type,
      label: field.label,
      placeholder: field.placeholder,
      customClass: 'ui-form-fields__field',
    };

    if (field.format && field.format === 'pan' && !field.disabled) {
      commonParams = {
        ...commonParams,
        cleaveOptions: {
          numericOnly: true,
          blocks: [4, 4, 4, 4, 4],
        },
      };
    }

    switch (field.id) {
      case 'recipient.first_name':
      case 'recipient.last_name':
      case 'recipient.city':
      case 'sender.city': {
        commonParams.maxLength = 25;
        break;
      }
      case 'sender.first_name':
      case 'sender.last_name': {
        commonParams.maxLength = 12;
        break;
      }
      case 'sender.wallet_id':
      case 'sender.address': {
        commonParams.maxLength = 50;
        break;
      }
      case 'sender.zip': {
        commonParams.maxLength = 10;
        break;
      }
      case 'sender.country': {
        commonParams.maxLength = 2;
        break;
      }
      case 'general.payment_id': {
        commonParams.clearButton = true;
        commonParams.generateValue = true;
        break;
      }
    }

    if (field.type === 'float') {
      return (
        <InputAmount
          {...commonParams}
          value={field.value || ''}
          onChange={(event) => onChangeField(field.id, event.target.value)}
          error={validationErrors[field.id]}
          tooltip={field.description}
          disabled={field.disabled}
          modern
        />
      );
    }

    if (field.id === 'card.year') {
      const currentDate = DateUtils.getDate();
      const minYear = currentDate.year(currentDate.year() - 1).year();
      const options: Value[] = this.generateOptions(
        minYear,
        minYear + YEAR_PERIOD
      );

      let value: Value | null = null;
      if (field.value) {
        value = {
          label: field.value,
          value: +field.value,
        };
      }

      return (
        <CustomSelect
          {...commonParams}
          options={options}
          value={value}
          onChange={(data) => onChangeField(field.id, data.value)}
          tooltip={field.description}
          isDisabled={field.disabled}
          containerSelector='.ui-form-fields__content'
          modern
        />
      );
    }
    if (field.id === 'card.month') {
      const options: Value[] = this.generateOptions(
        field.minimum,
        field.maximum
      );

      let value: Value | null = null;
      if (field.value) {
        value = {
          label: field.value,
          value: +field.value,
        };
      }

      return (
        <CustomSelect
          {...commonParams}
          options={options}
          value={value}
          onChange={(data) => onChangeField(field.id, data.value)}
          tooltip={field.description}
          isDisabled={field.disabled}
          containerSelector='.ui-form-fields__content'
          modern
        />
      );
    }

    return (
      <Input
        {...commonParams}
        value={field.value || ''}
        onChange={(event) => onChangeField(field.id, event.target.value)}
        error={validationErrors[field.id]}
        tooltip={field.description}
        disabled={field.disabled}
        modern
      />
    );
  };

  generateOptions = (min, max) => {
    const options: Value[] = [];

    for (let year = min; year <= max; year++) {
      options.push({ value: year, label: year });
    }

    return options;
  };
}

export default FormConstructor;
