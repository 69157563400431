import { generaTemplateTitle, prepareProjects } from './helpers';
import { IDictionary } from '../../../../../../../types/Analytics';
import { GetTranslate } from '../../../../../../../decorators/addTranslation';

const templateInoutTurnover = (
  getTranslate: GetTranslate,
  projects: IDictionary
) => {
  return {
    chartParams: {
      chartName: generaTemplateTitle(
        'analytics.editForm.fromTemplate.title.turnover',
        getTranslate
      ),
      chartData: 'amount',
      groupByRows: ['paymentMethod', 'type'],
      groupByColumns: ['currency'],
    },
    filters: {
      // TODO: revise - other chart use projects filter
      projects: prepareProjects(projects),
    },
  };
};

const templateInoutSalesAgentsTurnover = (
    getTranslate: GetTranslate,
    projects: IDictionary
) => {
  return {
    chartParams: {
      chartName: generaTemplateTitle(
          'analytics.editForm.fromTemplate.title.turnover',
          getTranslate
      ),
      chartData: 'amount',
      groupByRows: ['paymentMethod', 'projectId'],
      groupByColumns: ['currency'],
    },
    filters: {
      // TODO: revise - other chart use projects filter
      projects: prepareProjects(projects),
    },
  };
};

export { templateInoutTurnover, templateInoutSalesAgentsTurnover };
