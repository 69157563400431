import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';

import { getRecurringList } from 'api/recurring';
import getConfigurationByName from 'selectors/getConfigurationByName';
import TopPanelContainer from 'components/topPanel';
import DataListContainer from 'components/dataListContainer';
import { Column, Row } from 'components/ui/table';
import Button from 'components/ui/button';
import { AnyObject } from 'types/Common';
import path from 'helpers/path';
import MetricService from 'helpers/metricService/MetricService';
import statusList from 'constants/statusList';
import { minRecurringTableColumnsWidth } from './constants';
import quickFiltersConfig from '../quickFiltersConfig';
import { StoreProps } from 'store';
import Messages from 'constants/rpcTypes';
import permissionReasons from 'constants/permissionReasons';
import './recurringList.scss';

interface ConnectedProps {
  configuration: AnyObject;
}

type Props = ConnectedProps &
  StoreProps &
  IntlProps &
  WithRouterProps &
  WithPermissions;

class RecurringListContainer extends PureComponent<Props> {
  render() {
    return (
      <Fragment>
        <TopPanelContainer
          name='subscriptionPayments'
          filtersConfig={quickFiltersConfig}
          button={this.getButton()}
        />
        <DataListContainer
          minColumnsWidth={minRecurringTableColumnsWidth}
          tableName='subscriptionPayments'
          customClass='table-recurring'
          apiRequest={getRecurringList}
          rowRender={this.renderRow}
        />
      </Fragment>
    );
  }

  renderRow = ({ handleDoubleClick, ...data }) => {
    const { configuration } = this.props;
    const isNoSet = data.recurringStatus === statusList.notSet;

    return (
      <Row
        customClass={classNames('ui-table__row_clickable', {
          'table-recurring__row_noSet': isNoSet,
        })}
        onClick={() => handleDoubleClick(this.goToCard)}
        status={`recurring-${data.recurringStatus}`}>
        {configuration.map((config) => {
          const { id } = config;
          const columnParams = {
            ...config,
            valueId: config.id,
            valueType: config.valueType,
            labelPeriodText: 'subscriptions.list.period.generalValues',
          };

          return (
            <Column
              columnWidths={data.columnWidths}
              key={id}
              id={id}
              modifier={id}
              content={data[id] || '—'}
              customClass={`ui-table__column_${id}`}
              params={columnParams}
            />
          );
        })}
      </Row>
    );
  };

  getButton = () => {
    if (
      this.props.isEnabled(Messages.Invoice_CreateSubscription) ||
      this.props.isDisabledByReason(
        Messages.Invoice_CreateSubscription,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      )
    ) {
      return (
        <Button
          status='primary'
          size='normal'
          text={this.props.getTranslate('subscriptions.addNew.button')}
          onClick={() =>
            this.props.history.push(path('/invoices/create-subscription'))
          }
        />
      );
    }
    return undefined;
  };

  goToCard = (data) => {
    const { history } = this.props;
    const { paymentId, transactionId } = data;
    history.push(
      path(`/recurring-payments/${transactionId}?name=${paymentId}`)
    );

    MetricService.send({
      action: 'viewItem',
      actionKey: 'subscriptions.registry.subscriptionCard',
    });
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  configuration: getConfigurationByName(state, 'subscriptionPayments'),
});

export default withRouter(
  connect(mapStateToProps)(
    addPermissions(addTranslation(RecurringListContainer))
  )
);
