import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { pickBy } from 'lodash';

import { StoreProps } from 'store';
import { createIcon, updateIcon } from 'api/icons';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import showNotification from 'components/ui/notification/showNotification';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import Form from 'components/ui/form';
import Loader from 'components/ui/loader';
import Dropzone from 'components/ui/dropzone';
import ModalTitle from 'components/modal/components/ModalTitle';
import ModalContent from 'components/modal/components/ModalContent';
import ModalFooter from 'components/modal/components/ModalFooter';
import { AnyObject } from 'types/Common';

interface OwnProps {
  callback: ({ isNew, data }) => void;
  content: {
    data: any;
  };
}

interface ConnectedProps {
  user: any;
}

type Props = OwnProps & ConnectedProps & WithPermissions & StoreProps;

interface State {
  paymentMethodId: string;
  paymentMethodName: string;
  file: File | null;
  isLoading: boolean;
  validationErrors: AnyObject;
}

class EditApsIcon extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.getInitialState();
  }

  componentDidMount() {
    const {
      content: { data },
    } = this.props;
    if (data) {
      this.setState({
        paymentMethodId: data.paymentMethodId,
        paymentMethodName: data.paymentMethodName,
        file: data.fileData,
        isLoading: false,
      });
    }
  }

  render() {
    const {
      paymentMethodId,
      paymentMethodName,
      file,
      validationErrors,
      isLoading,
    } = this.state;
    const isNew = this.isNew();
    const iconName = isNew
      ? file?.name
      : this.props.content.data.paymentMethodName;
    return (
      <div className='edit-aps-icon'>
        <ModalTitle>{isNew ? 'Create' : 'Edit'}</ModalTitle>
        <ModalContent>
          <Form onSubmit={() => this.send()}>
            <Input
              autoFocus
              id='paymentMethodId'
              label='Payment method id'
              value={paymentMethodId}
              placeholder='Enter value'
              disabled={!isNew}
              onChange={({ target: { value } }) =>
                this.changeField('paymentMethodId', value)
              }
              cleaveOptions={{ numericOnly: true }}
              error={validationErrors['paymentMethodId']}
            />
            <br />
            <Input
              id='paymentMethodName'
              label='Payment method name'
              value={paymentMethodName}
              placeholder='Enter value'
              onChange={({ target: { value } }) =>
                this.changeField('paymentMethodName', value)
              }
              error={validationErrors['paymentMethodName']}
            />
            <br />
            <Dropzone
              file={file && new File([file], iconName)}
              onDrop={this.loadFile}
              theme='small'
              showText={false}
              fileIcon='im-Calendar'
              onDeleteFile={this.deleteFile}
              acceptFiles='.svg'
              errorMessage={validationErrors['file']}
            />
          </Form>
        </ModalContent>
        <ModalFooter>
          <Button text='Apply' status='success' onClick={() => this.send()} />
        </ModalFooter>
        {isLoading && <Loader />}
      </div>
    );
  }

  async send() {
    const { callback, content } = this.props;
    const { paymentMethodId, paymentMethodName, file } = this.state;

    // if (isNotAvailableForSupport(Messages.Rcs_BulkCreateElement)) return false;

    const isNew = this.isNew();
    const action = isNew ? createIcon : updateIcon;
    const options = pickBy({
      apsIconId: content.data?.apsIconId,
      paymentMethodId,
      paymentMethodName,
      file,
    });

    this.setState({ isLoading: true });

    try {
      const data = await action(options);
      return callback({ isNew: this.isNew(), data });
    } catch (error) {
      const { payload } = error;
      if (payload?.validationErrors) {
        this.setState({ validationErrors: payload.validationErrors });
      } else {
        if (!payload) return;
        showNotification({ status: 'error', content: payload });
      }
    } finally {
      this.setState({ isLoading: false });
    }
  }

  changeField = (key, value) => {
    const { validationErrors } = this.state;
    this.setState((state) => {
      return {
        ...state,
        [key]: value,
        validationErrors: { ...validationErrors, [key]: '' },
      };
    });
  };

  isNew(): boolean {
    const {
      content: { data },
    } = this.props;
    return !data;
  }

  getInitialState() {
    return {
      paymentMethodId: '',
      paymentMethodName: '',
      file: null,
      isLoading: false,
      validationErrors: {},
    };
  }

  loadFile = (file) => {
    const { validationErrors } = this.state;
    this.setState({
      file: file[0],
      validationErrors: { ...validationErrors, file: '' },
    });
  };

  deleteFile = () => {
    const { validationErrors } = this.state;
    this.setState({
      file: null,
      validationErrors: { ...validationErrors, file: '' },
    });
  };

  /* isEnable = () => {
    const { isEnabled, isDisabledByReason } = this.props;
    return (
      isEnabled(Messages.Profile_Update) ||
      !isDisabledByReason(
        Messages.Profile_Update,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      )
    );
  };*/
}

const mapStateToProps = (state): ConnectedProps => ({ user: state.user });

export default connect(mapStateToProps)(addPermissions(EditApsIcon));
