import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 * Список фильтров приложения
 */
const getFiltersList = (): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.FilterSettings_Get,
      },
    ],
  });
};

const getPaymentsFilters = (): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.FilterSettings_Payments,
      },
    ],
  });
};

export default {
  getPaymentsFilters,
  getFiltersList,
};
