import React from 'react';
import DropZone from 'components/ui/dropzone';
import Switch from 'components/ui/switch';
import BatchFile from 'types/BatchFile';

interface Props {
  title: string;
  isFileLoading: boolean;
  isFilePreview: boolean;
  batch: null | BatchFile;
  file: null | File;

  error: string;
  onChangeField: (field: string, value: any) => void;
  onChangeFile: (file: File) => void;
  onDeleteFile: () => void;
  switcherText: string;
}

export const MassPaymentAdditionalColumn: React.VFC<Props> = (props) => {
  return (
    <>
      <div className='ui-form-fields__col-title'>{props.title}</div>
      <div className='ui-form-fields__item ui-form-fields__item_dropzone'>
        <DropZone
          noClick={true}
          file={props.file}
          isFileUploading={props.isFileLoading}
          acceptFiles='.csv'
          onDrop={(files) => {
            files[0] && props.onChangeFile(files[0]);
          }}
          onDeleteFile={props.onDeleteFile}
          errorMessage={props.error}
        />
      </div>

      <div className='ui-form-fields__item ui-form-fields__item_switch'>
        <Switch
          id='isFilePreview'
          checked={props.isFilePreview}
          onChange={(event) =>
            props.onChangeField('isFilePreview', event.target.checked)
          }
          text={props.switcherText}
          disabled={!props.batch}
        />
      </div>
    </>
  );
};
