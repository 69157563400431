import React, { Component } from 'react';
import { connect } from 'react-redux';

import checkAccess from 'decorators/checkAccess';
import getConfigurationByName from 'selectors/getConfigurationByName';
import { openModal } from 'actions/modal';
import { addDataItem, updateDataItem } from 'actions/getData';
import Icons from './Icons';
import Messages from 'constants/rpcTypes';
import tableNames from 'constants/tableNames';
import ItemConfiguration from 'types/ItemConfiguration';
import { StoreProps } from 'store';

interface ConnectedProps {
  configuration: ItemConfiguration[];
  canRender: boolean;
}

type Props = ConnectedProps & StoreProps;

@checkAccess([Messages.ApsIcon_List])
class IconsContainer extends Component<Props> {
  render() {
    const { configuration, canRender } = this.props;

    return (
      <Icons
        canRender={canRender}
        configuration={configuration}
        onEdit={this.openEditModal}
        addItem={this.addItem}
      />
    );
  }

  onUpdateIcon = (payload) => {
    if (!payload) return;
    const { dispatch } = this.props;
    dispatch(updateDataItem(tableNames.apsIcon, 'paymentMethodId', payload));
  };

  addItem = (payload) => {
    const { dispatch } = this.props;
    dispatch(addDataItem(tableNames.apsIcon, payload.data));
  };

  openEditModal = (data) => {
    const { dispatch } = this.props;

    dispatch(
      openModal({
        modalId: 'EditApsIcon',
        content: { data },
        callback: (answer) => !answer.isNew && this.onUpdateIcon(answer.data),
      })
    );
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  configuration: getConfigurationByName(state, tableNames.apsIcon),
  canRender: state.quickFilters[tableNames.apsIcon].isReady,
});

export default connect(mapStateToProps)(IconsContainer);
