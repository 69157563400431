import { v4 as uuid } from 'uuid';
import _lowerCase from 'lodash/fp/lowerCase';

import {
  ChartData,
  ChartViewData,
  GraphViewData,
  IChartDataNormalizerObject,
} from 'types/Analytics';

import getLocalizedText from 'helpers/getLocalizedText';
import { AdvancedAnalyticsEntityType } from 'components/modal/modalList/graphDetails/components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';

interface IServiceChartDataFormatter {
  format: () => ChartViewData;
}

class ServiceChartDataFormatter implements IServiceChartDataFormatter {
  data: GraphViewData;
  chartData: string;

  constructor(data: ChartData['data']) {
    const chartGroup =
      'chart' in data ? data.chart : AdvancedAnalyticsEntityType.operations;
    const colors: Array<string> = [
      '#F88B8A',
      '#7FB2D7',
      '#F2D794',
      '#EEB985',
      '#93CCCE',
    ];
    const { chartData, measureField, measureFunction } = data;

    this.chartData = ServiceChartDataFormatter.normalizeChartData({
      data: {
        chartData,
        measureField,
        measureFunction,
      },
    });
    const yAxisTitle = getLocalizedText(
      ServiceChartDataFormatter.getChartDataName(this.chartData)
    );

    this.data = {
      chartName: data.chartName,
      series: data.series,
      biChartId: data.biChartId,
      chartType: data.chartType,
      points: data.points,
      xAxis: data.xAxis,
      chart: {
        height: 362,
        backgroundColor: '#F7F8FA',
        borderRadius: 4,
        className: '',
        plotBackgroundColor: '',
        plotBorderWidth: null,
        plotShadow: false,
        zoomType: 'xy',
        panning: true,
      },
      legend: { enabled: true },
      credits: { enabled: false },
      exporting: { enabled: false },
      title: ServiceChartDataFormatter.chartName[chartGroup],
      yAxis: {
        title: {
          text: yAxisTitle,
        },
      },
      colors,
      tooltip: {},
      plotOptions: {},
      chartData,
      chartGroup,
      uuid: uuid(),
    };
  }

  static get chartName() {
    return {
      payments: 'Payments',
    };
  }

  static getChartDataName(chartData: string): string {
    const dictionary = {
      count: 'analytics.editForm.params.chartData.number.item',
      count_transactions:
        'analytics.editForm.params.chartData.number_transactions.item',
      amount: 'analytics.editForm.params.chartData.channelAmount.item',
      sum_channel_amount_in_eur:
        'analytics.editForm.params.chartData.channelAmountEUR.item',
      sum_channel_amount_in_usd:
        'analytics.editForm.params.chartData.channelAmountUSD.item',
      avg_channel_amount:
        'analytics.editForm.params.chartData.avgChannelAmount.item',
      avg_channel_amount_in_eur:
        'analytics.editForm.params.chartData.avgChannelAmountEUR.item',
      avg_channel_amount_in_usd:
        'analytics.editForm.params.chartData.avgChannelAmountUSD.item',
      sum_channel_amount_in_gbp:
        'analytics.editForm.params.chartData.channelAmountGBP.item',
      sum_channel_amount_in_rub:
        'analytics.editForm.params.chartData.channelAmountRUB.item',
      unique_customers:
        'analytics.editForm.params.chartData.uniqueCustomers.item',
      count_chargeback_id: 'analytics.editForm.params.chartData.chbNumber.item',
      sum_amount_in_usd: 'analytics.editForm.params.chartData.chbAmount.item',
      ratio_by_count: 'analytics.editForm.params.chartData.chbRatioNumber.item',
      ratio_by_amount:
        'analytics.editForm.params.chartData.chbRatioAmount.item',
      numberOfFraudOperations:
        'analytics.editForm.params.chartData.fraudNumber.item',
      fraudAmountUSD: 'analytics.editForm.params.chartData.fraudAmount.item',
      fraudRatioByNumber:
        'analytics.editForm.params.chartData.fraudRatioNumber.item',
      fraudRatioByAmount:
        'analytics.editForm.params.chartData.fraudRatioAmount.item',
    };

    return dictionary[chartData];
  }

  // eslint-disable-next-line complexity
  static normalizeChartData({
    data,
  }: {
    data: IChartDataNormalizerObject;
  }): string {
    const { chartData, measureField, measureFunction } = data;

    if (chartData === 'count' && measureField === 'operation_id') {
      return 'count';
    } else if (chartData === 'count' && measureField === 'transaction_id') {
      return 'count_transactions';
    } else if (
      chartData === 'count' &&
      measureField === 'customer_id' &&
      measureFunction === 'distinct'
    ) {
      return 'unique_customers';
    } else if (
      chartData === 'amount' &&
      measureField === 'channel_amount_in_eur' &&
      measureFunction === 'sum'
    ) {
      return 'sum_channel_amount_in_eur';
    } else if (
      chartData === 'amount' &&
      measureField === 'channel_amount' &&
      measureFunction === 'sum'
    ) {
      return 'amount';
    } else if (
      chartData === 'amount' &&
      measureField === 'channel_amount_in_gbp' &&
      measureFunction === 'sum'
    ) {
      return 'sum_channel_amount_in_gbp';
    } else if (
      chartData === 'amount' &&
      measureField === 'channel_amount_in_usd' &&
      measureFunction === 'sum'
    ) {
      return 'sum_channel_amount_in_usd';
    } else if (
      chartData === 'amount' &&
      measureField === 'channel_amount' &&
      measureFunction === 'avg'
    ) {
      return 'avg_channel_amount';
    } else if (
      chartData === 'amount' &&
      measureField === 'channel_amount_in_rub' &&
      measureFunction === 'sum'
    ) {
      return 'sum_channel_amount_in_rub';
    } else if (
      chartData === 'amount' &&
      measureField === 'channel_amount_in_eur' &&
      measureFunction === 'avg'
    ) {
      return 'avg_channel_amount_in_eur';
    } else if (
      chartData === 'amount' &&
      measureField === 'channel_amount_in_usd' &&
      measureFunction === 'avg'
    ) {
      return 'avg_channel_amount_in_usd';
    } else if (
      chartData === 'count' &&
      measureField === 'chargeback_id' &&
      measureFunction === 'count'
    ) {
      return 'count_chargeback_id';
    } else if (
      chartData === 'amount' &&
      measureField === 'amount_in_usd' &&
      measureFunction === 'sum'
    ) {
      return 'sum_amount_in_usd';
    } else if (
      chartData === 'count' &&
      measureField === 'chargeback_id' &&
      measureFunction === 'ratio_by_number'
    ) {
      return 'ratio_by_count';
    } else if (
      chartData === 'amount' &&
      measureField === 'amount_in_usd' &&
      measureFunction === 'ratio_by_amount'
    ) {
      return 'ratio_by_amount';
    } else if (
      chartData === 'count' &&
      measureField === 'arn' &&
      measureFunction === 'count'
    ) {
      return 'numberOfFraudOperations';
    } else if (
      chartData === 'amount' &&
      measureField === 'fraud_amount_in_usd' &&
      measureFunction === 'sum'
    ) {
      return 'fraudAmountUSD';
    } else if (
      chartData === 'count' &&
      measureField === 'arn' &&
      measureFunction === 'ratio_by_number'
    ) {
      return 'fraudRatioByNumber';
    } else if (
      chartData === 'amount' &&
      measureField === 'fraud_amount_in_usd' &&
      measureFunction === 'ratio_by_amount'
    ) {
      return 'fraudRatioByAmount';
    }

    return '';
  }

  format() {
    let chartType;

    if ('chartType' in this.data) {
      chartType = this.data.chartType;
    }

    switch (chartType) {
      case 'line': {
        this.data.xAxis = {
          categories: this.data.points,
        };

        return this.data;
      }

      case 'bar': {
        if ('tooltip' in this.data) {
          this.data.tooltip = {
            headerFormat: '',
            pointFormat:
              '<tr><td style="color:{series.color};padding:0">{series.name}: {point.y:.1f}</td></tr>',
          };
        }

        if (this.data.series?.length === 1) {
          this.data.legend.enabled = false;

          this.data.tooltip = {
            headerFormat:
              '<tr><td style="color:{series.color};padding:0">{point.key}: {point.y:.1f}</td></tr>',
            pointFormat: '',
          };
        }

        return this.data;
      }

      case 'horizontal_bar': {
        if (this.data.series?.length === 1) {
          this.data.legend.enabled = false;
          this.data.tooltip = {
            headerFormat:
              '<tr><td style="color:{series.color};padding:0">{point.key}: {point.y:.1f}</td></tr>',
            pointFormat: '',
          };
        }

        return this.data;
      }

      case 'pie chart': {
        if ('tooltip' in this.data) {
          this.data.tooltip = {
            headerFormat: '',
            pointFormat:
              '<tr><td style="color:{series.color};padding:0">{point.name}: {point.y:.1f}</td></tr>',
          };
        }

        if ('chart' in this.data) {
          this.data.chart.plotBackgroundColor = '#F7F8FA';
          this.data.chart.type = 'pie';

          const label = getLocalizedText(
            'analytics.editForm.params.chartData.label'
          );

          const yAxisTitle =
            this.data.yAxis !== undefined && this.data.yAxis.title !== undefined
              ? this.data.yAxis.title.text
              : '';

          this.data.title = {
            text: `${label}: ${_lowerCase(yAxisTitle)}`,
            align: 'left',
            style: { 'font-size': '15px', color: '#666666' },
          };
        }

        if ('plotOptions' in this.data) {
          this.data.plotOptions = {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              },
            },
          };
        }

        return this.data;
      }

      default:
        return this.data;
    }
  }
}

export { ServiceChartDataFormatter };
