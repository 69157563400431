import filtersKeys from 'constants/filters';
import { FilterTypes } from 'constants/FilterTypes';
import modes from 'components/ui/buttonsGroup/modes';

const rcsFraud = [
  {
    filterId: filtersKeys.riskProjectIds,
    type: FilterTypes.multiSelect,
    metric: 'risks.fastFilter.project.apply',
    dictionary: 'enabledProjectExternal',
    title: 'risks.quickFilters.project',
  },
  {
    filterId: filtersKeys.risksCreatedAt,
    type: FilterTypes.dateButtons,
    metricName: 'risks',
    withTime: false,
  },
];

const rcsBlackWhite = [
  [
    {
      filterId: filtersKeys.riskContentType,
      type: FilterTypes.buttonsList,
      valueType: 'string',
      mode: modes.singleRequired,
      metric: 'risks.fastFilter.category',
      dictionary: 'rcsContentType',
    },
    {
      filterId: filtersKeys.riskValue,
      type: FilterTypes.multiText,
      separators: [','],
      title: 'risks.quickFilters.value',
      metric: 'risks.fastFilter.value.enter',
    },
  ],
  [
    {
      filterId: filtersKeys.riskListType,
      type: FilterTypes.buttonsList,
      mode: modes.multi,
      metric: 'risks.fastFilter.listType',
      list: [
        {
          id: 'whitelist',
          text: 'risks.quickFilters.listType.whitelist.button',
        },
        {
          id: 'blacklist',
          text: 'risks.quickFilters.listType.blacklist.button',
        },
      ],
    },
    {
      filterId: filtersKeys.riskMerchantId,
      type: FilterTypes.select,
      metric: 'risks.fastFilter.merchant.enter',
      dictionary: 'userMerchant',
      title: 'risks.quickFilters.merchant',
    },
    {
      filterId: filtersKeys.riskProjectIds,
      type: FilterTypes.multiSelect,
      metric: 'risks.fastFilter.project.apply',
      dictionary: 'enabledProjectExternal',
      title: 'risks.quickFilters.project',
    },
    {
      filterId: filtersKeys.risksCreatedAt,
      type: FilterTypes.dateButtons,
      metricName: 'risks',
      withTime: false,
    },
  ],
];

export default { rcsFraud, rcsBlackWhite };
