import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';
import SavedEntity from 'types/savedEntity';

export interface State {
  [key: string]: {
    isFetch: boolean;
    items: SavedEntity[];
  };
}

const initialState: State = {};

const savedEntities = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;
  const {
    entityKey,
    id,
    urlParams,
    hiddenParams,
    fields,
    subSection,
    caption,
  } = payload || {};
  let currentEntity;

  switch (type) {
    case ACTIONS.GET_SAVED_ENTITIES:
      return {
        ...state,
        [entityKey]: {
          isFetch: true,
          items: payload.items,
        },
      };
    case ACTIONS.ADD_ENTITY_TO_SAVED: {
      currentEntity = state[entityKey];
      if (!currentEntity) {
        return state;
      }

      const copyItems = [...currentEntity.items];
      const foundIndex = copyItems.findIndex((item) => item.id === payload.id);

      if (foundIndex === -1) {
        copyItems.push({
          id,
          urlParams,
          subSection,
          hiddenParams,
          caption,
          isFetch: false,
        });
      }
      return {
        ...state,
        [entityKey]: {
          ...state[entityKey],
          items: copyItems,
        },
      };
    }
    case ACTIONS.RELOAD_SAVED_ENTITY:
      if (!state[entityKey]) {
        return state;
      }
      return {
        ...state,
        [entityKey]: {
          ...state[entityKey],
          items: state[entityKey].items.map((item) => {
            if (id === item.id) {
              if (entityKey === 'chargebacks') {
                return {
                  ...item,
                  [fields.data.chargebackId]: {
                    ...fields,
                    data: {
                      ...fields.data,
                    },
                    disputes: {
                      ...fields.disputes,
                    },
                    isFetch: Boolean(fields.data),
                  },
                };
              }
              return {
                ...fields,
                isFetch: Boolean(item.data || fields.data),
              };
            }
            return {
              ...item,
            };
          }),
        },
      };
    case ACTIONS.UPDATE_SAVED_ENTITY:
      if (!state[entityKey]) {
        return state;
      }
      return {
        ...state,
        [entityKey]: {
          ...state[entityKey],
          items: state[entityKey].items.map((item) => {
            if (id === item.id) {
              if (
                entityKey === 'chargebacks' &&
                fields.data?.chargebackId &&
                item.urlParams.type !== 'payment'
              ) {
                return {
                  ...item,
                  [fields.data.chargebackId]: {
                    ...fields,

                    data: {
                      ...item[fields.data.chargebackId]?.data,
                      ...fields.data,
                    },
                    disputes: {
                      ...item[fields.data.chargebackId]?.disputes,
                      ...fields.disputes,
                    },
                    isFetch: Boolean(fields.data),
                  },
                };
              }
              return {
                ...item,
                ...fields,
                isFetch: Boolean(item.data || fields.data),
              };
            }
            return {
              ...item,
            };
          }),
        },
      };
    case ACTIONS.UPDATE_ALL_SAVED_ENTITY: {
      return {
        ...state,
        [entityKey]: {
          ...state[entityKey],
          items: payload.items,
        },
      };
    }
    case ACTIONS.REMOVE_ENTITY_FROM_SAVED:
      return {
        ...state,
        [entityKey]: {
          ...state[entityKey],
          items: state[entityKey].items.filter((item) => {
            return id !== item.id;
          }),
        },
      };
    case ACTIONS.REMOVE_ALL_ENTITIES:
      return {
        ...state,
        [entityKey]: {
          isFetch: true,
          items: [],
        },
      };
    case ACTIONS.RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

export default savedEntities;
