import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Input from 'components/ui/inputAmount';

interface Props extends IntlProps {
  id: string;
  value: string;
  onChange: (filterId: string, value: any) => void;
  label?: string;
  placeholder?: string;
}

class InputText extends PureComponent<Props> {
  render() {
    const { id, value, onChange, label, placeholder, getTranslate } =
      this.props;

    return (
      <div className='filters-amount'>
        <Input
          id={id}
          label={getTranslate(label || `filters.fields.${id}.label`)}
          placeholder={getTranslate(
            placeholder || `filters.fields.${id}.placeholder`
          )}
          value={value}
          onChange={(event) => onChange(id, event.target.value)}
          modern
        />
      </div>
    );
  }
}

export default addTranslation(InputText);
