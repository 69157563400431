import BaseMessageHandler from '../BaseMessageHandler';
import { updateDataItem } from 'actions/getData';

export default class InvoicesUpdated extends BaseMessageHandler {
  default = () => {
    const { dispatch, message } = this;
    if (Array.isArray(message.payload)) {
      message.payload.forEach((data) => {
        dispatch(updateDataItem('invoices', 'paymentId', data));
      });
    } else {
      dispatch(updateDataItem('invoices', 'paymentId', message.payload));
    }
  };
}
