import React from 'react';
import { connect } from 'react-redux';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { uploadUserFile } from 'api/maf';
import ReactDropZone from 'react-dropzone';
import classNames from 'classnames';
import { setBeforeUpdateActions } from 'actions/maf';
import Icon from 'components/ui/icon';
import Loader from 'components/ui/loader';
import InfoIcon from 'components/ui/infoIcon';
import showNotification from 'components/ui/notification/showNotification';
import DateHelpers from 'helpers/Date';
import { StoreProps } from 'store';

import { MafField, MafState } from '../../MafTypes';
import './fileUpload.scss';

interface OwnProps {
  field: MafField;
  tooltip?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  className?: string;
  fileTypes?: string;
  mafId: string;
  legalEntityId?: string;
  formName: string;
  onChange: ({
    objectName,
    date,
    fileName,
  }: {
    objectName: string;
    date: string;
    fileName: string;
  }) => void;
}

interface ConnectedProps {
  actionsBeforeUpdate: MafState['actionsBeforeUpdate'];
}

type Props = OwnProps & ConnectedProps & IntlProps & StoreProps;

interface State {
  file: File | null;
  isLoading: boolean;
}

class FileUpload extends React.PureComponent<Props, State> {
  dropZoneRef;

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      file: null,
    };
  }

  static defaultProps: Readonly<Partial<OwnProps>> = {
    tooltip: '',
  };
  render() {
    const {
      tooltip,
      field,
      disabled,
      required,
      error,
      className,
      fileTypes,
      getTranslate,
    } = this.props;
    const { file, isLoading } = this.state;

    return (
      <ReactDropZone
        onDrop={(files) => this.onDrop(files)}
        noClick={true}
        ref={(el) => {
          this.dropZoneRef = el;
        }}
        noKeyboard={false}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={classNames(className, 'upload-file', {
              'upload-file_disabled': disabled,
              'upload-file_error': error,
            })}>
            <div className='upload-file__title'>
              <span className='upload-file__label'>
                {getTranslate(field.caption)}
              </span>
              {required && (
                <span
                  data-tip={getTranslate('common.requiredField.tooltip')}
                  className='upload-file__required'>
                  *
                </span>
              )}
              {tooltip && (
                <InfoIcon
                  tooltip={getTranslate(tooltip)}
                  customClass='upload-file__info'
                />
              )}
            </div>
            <div className='upload-file__inner'>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <span className='upload-file__icon upload-file__icon_type'>
                    <Icon name='image' size={30} />
                  </span>
                  <div
                    onClick={this.openFileSystem}
                    className='upload-file__drop-zone'>
                    {this.getDisplayValue()}
                  </div>
                  <span
                    onClick={file ? this.unselectFile : this.openFileSystem}
                    className='upload-file__icon upload-file__icon_download'>
                    <Icon name={file ? 'im-Basket' : 'file_upload'} size={20} />
                  </span>
                  <div>
                    <input
                      {...getInputProps()}
                      accept={fileTypes || 'image/jpeg,image/png,.pdf'}
                    />
                  </div>
                </>
              )}
            </div>

            {error && <div className='upload-file__error-text'>{error}</div>}
          </div>
        )}
      </ReactDropZone>
    );
  }

  getDisplayValue = () => {
    const { field } = this.props;
    const { file } = this.state;

    if (file?.name) {
      return file.name;
    }

    if (field.value) {
      return <FileUploadedInfoMessage fileName={field.lookup_display_value} />;
    }

    return 'Drag file here or click to upload';
  };

  openFileSystem = () => {
    const { dropZoneRef } = this;
    if (dropZoneRef.open && !this.props.disabled) {
      dropZoneRef.open();
    }
  };

  unselectFile = () => {
    const { actionsBeforeUpdate, dispatch } = this.props;
    this.setState({ file: null });
    delete actionsBeforeUpdate[this.props.field.name];
    dispatch(setBeforeUpdateActions(actionsBeforeUpdate));
  };

  onDrop = async (files) => {
    const { actionsBeforeUpdate, dispatch } = this.props;
    const file = files[0] as File;
    this.setState({ file });
    actionsBeforeUpdate[this.props.field.name] = this.loadUserFile;
    dispatch(setBeforeUpdateActions(actionsBeforeUpdate));
  };

  loadUserFile = () => {
    const { file } = this.state;
    const { mafId, field, onChange, formName, legalEntityId } = this.props;

    return new Promise((resolve) => {
      uploadUserFile({
        file,
        mafId,
        legalEntityId,
        attachmentType: field.attach_type_id || '',
        documentType: field.doc_type_id || '',
      })
        .then((result) => {
          const date = this.parseDateFromResponse(result);
          onChange({ ...result, date });
          this.setState({ file: null });
          resolve(result);
        })
        .catch((e) => {
          this.unselectFile();
          if (e.payload?.validationErrors?.file) {
            showNotification({
              status: 'error',
              content: e.payload.validationErrors.file,
            });

            resolve({
              validationErrors: {
                [field.name]: e.payload.validationErrors.file,
              },
              formName,
              fieldName: field.name,
            });
          }
          console.error(e);
          resolve(e);
        });
    });
  };

  parseDateFromResponse = ({ objectName }) => {
    // objectName: ...675-20210712160837.jpeg
    const lastPiece = objectName.split('-').pop(); // 20210712160837.jpeg
    const dateString = lastPiece.split('.').shift(); // 20210712160837
    return DateHelpers.getFormat(
      DateHelpers.createDate(dateString, 'YYYYMMDDHHmmss'),
      'crmDate'
    );
  };
}

const mapStateToProps = (state): ConnectedProps => {
  return {
    actionsBeforeUpdate: state.maf.actionsBeforeUpdate,
  };
};

const FileUploadedInfoMessage = addTranslation(
  ({ getTranslate, fileName }: IntlProps & { fileName?: string }) => (
    <>
      <div className='upload-file__info-message'>
        <span>{fileName || 'File uploaded'}</span>
        <InfoIcon
          tooltip={getTranslate('businessDocs.documentsUpload.tooltip')}
          size={20}
        />
      </div>
    </>
  )
);

export default connect(mapStateToProps)(addTranslation(FileUpload));
