import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';
import request from 'helpers/request';
import { Feature } from 'reducers/additionalFeatures';

export const getOverviewPage = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.GetStarted_View,
      },
    ],
  });
};

export const getAdditionalFeaturesList = (params: {
  language: string;
  offset?: number;
}): Promise<{
  rows: Feature[];
  hasMoreRows: boolean;
}> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.AdditionalFeature_List,
        payload: {
          filter: {
            ...params,
          },
        },
      },
    ],
  });
};

export const deleteAdditionalFeature = (
  list: { additionalFeatureId: number }[]
) => {
  return new RpcService().request({
    messages: list.map((item) => ({
      type: RpcTypes.AdditionalFeature_Delete,
      payload: item,
    })),
    showValidateNotification: false,
  });
};

export const createAdditionalFeature = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.AdditionalFeature_Create,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

export const updateAdditionalFeature = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.AdditionalFeature_Update,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

export const saveSortFeaturesList = (list) => {
  return new RpcService().request({
    messages: list.map((payload) => ({
      type: RpcTypes.AdditionalFeature_UpdateSortIndex,
      payload,
    })),
  });
};

export const loadFeaturePictures = (
  type: 'iconPath' | 'picturePath',
  data: {
    additionalFeatureId: string;
    file;
  }
): Promise<string> => {
  return request({
    method: 'POST',
    url: 'app/additional-feature/upload-image',
    headers: { 'Content-Type': 'multipart/form-data' },

    data: {
      ...data,
      type:
        type === 'iconPath'
          ? 'additional_feature_icon'
          : 'additional_feature_picture',
    },
  }).then((source) => source.data.payload.filePath);
};
