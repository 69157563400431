import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import './chip.scss';

interface Props {
  text: string;
  id: string;
  onClick?: (e, id) => void;
  type?: 'tag';
  theme?: string;
  customClass?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  tooltip?: string;
}

class Chip extends PureComponent<Props> {
  render() {
    const {
      text,
      id,
      tooltip,
      theme,
      type,
      isSelected,
      isDisabled,
      customClass,
      onClick,
    } = this.props;

    return (
      <div
        className={classNames('ui-chip', customClass, {
          [`ui-chip_${type}`]: !!type,
          [`ui-chip_${theme}`]: !!theme,
          'ui-chip_disabled': isDisabled,
          'ui-chip_editable': !isDisabled && !!onClick,
          'ui-chip_active': isSelected,
        })}
        onClick={(e) => {
          e.stopPropagation();
          type && !isDisabled && onClick?.(e, id);
        }}
        tabIndex={0}
        id={id}
        data-delay-hide='200'
        data-tip={tooltip}>
        {type === 'tag' && isSelected && (
          <Icon name='im-Tick' size={10} className='ui-chip__selected-icon' />
        )}
        <span className='ui-chip__title'>{text}</span>
        {!type && !isDisabled && onClick && (
          <span
            className='ui-chip__icon-wrap'
            onClick={(e) => {
              e.stopPropagation();
              onClick(e, id);
            }}>
            <Icon className='ui-chip__icon' name='im-Plus' size={10} />
          </span>
        )}
      </div>
    );
  }
}

export default Chip;
