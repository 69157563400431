import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import './toggleButton.scss';

export const ToggleButton = (props: {
  id?: string;
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  text?: string | JSX.Element;
  disabled?: boolean;
  active?: boolean;
}) => (
  <button
    id={props.id}
    type='button'
    className={classNames('ui-toggle-button', props.className, {
      'ui-toggle-button_disabled': props.disabled,
      'ui-toggle-button_active': !props.disabled && props.active,
    })}
    disabled={props.disabled}
    onClick={(event: MouseEvent<HTMLButtonElement>) => {
      if (props.disabled) return;

      props.onClick && props.onClick(event);
    }}>
    <div className='ui-toggle-button__content'>
      <Icon
        className='ui-toggle-button__icon'
        name={props.active ? 'im-Tick' : 'im-Plus'}
        size={11}
      />
      <span>{props.text}</span>
    </div>
  </button>
);
