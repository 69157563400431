import React, { PureComponent } from 'react';
import api from 'api/user';
import Button from 'components/ui/button';

class DevTools extends PureComponent {
  render() {
    return (
      <div className='utils-ui-block'>
        <div className='utils-header'>Dev tools block</div>
        <div className='utils-ui-block__content'>
          <Button
            onClick={() => api.sendEmail()}
            text='Test email'
            status='primary'
          />
        </div>
      </div>
    );
  }
}
export default DevTools;
