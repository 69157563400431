import BaseMessageHandler from '../BaseMessageHandler';
import { deleteDataItem } from 'actions/getData';

export default class Team_DeleteUser extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;
    const { payload } = message;

    dispatch(deleteDataItem('teamUsers', 'id', payload.id));
  };
}
