import axios from 'axios';
import showNotification from 'components/ui/notification/showNotification';

/**
 * Все запросы к серверу должны осуществляться через функцию request
 * @returns {Promise}
 */
const request = ({
  method = 'post',
  url = '',
  data = {},
  responseType = 'json',
  headers = { 'Content-Type': 'application/json' },
  errorHandle = true,
}: any): any => {
  return new Promise((resolve, reject) => {
    method = method.toUpperCase();

    let params = { errorHandle };
    if (method === 'GET') {
      params = { ...params, ...data };
    }
    axios({
      method,
      url,
      data,
      headers,
      responseType,
      params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        const { response } = error;

        if (response) {
          reject({
            status: response.status,
            error: response.data.error,
            payload: response.data.payload,
          });
        } else {
          console.error('Network error:', error);
          showNotification({
            status: 'error',
            content: 'Network error',
          });
          reject(error);
        }
      });
  });
};

export default request;
