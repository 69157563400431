import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import './infoIcon.scss';

interface Props {
  customClass?: string;
  tooltipType?: string;
  size?: number;
  tooltip: string;
  icon?: string;
  dataPlace?: string;
  type: 'error' | 'info';
  modern?: boolean;
  tooltipId?: string;
}

const iconTypes = {
  error: 'im-Error',
  info: 'im-Info-Square',
};

class InfoIcon extends PureComponent<Props> {
  static defaultProps = {
    size: 11,
    dataPlace: 'bottom',
    type: 'info',
  };

  render() {
    const {
      size,
      customClass,
      tooltip,
      dataPlace,
      type,
      tooltipType,
      icon,
      modern,
    } = this.props;

    return (
      <Icon
        tooltip={tooltip}
        tooltipPlace={dataPlace}
        tooltipId={this.props.tooltipId}
        name={modern ? 'im-Info-Hint' : icon || iconTypes[type]}
        data-class={tooltipType}
        size={modern ? 20 : size}
        className={classNames('ui-info-icon', {
          [`${customClass}`]: customClass,
          'ui-info-icon_modern': modern,
          'ui-info-icon__error': type === 'error',
        })}
      />
    );
  }
}

export default InfoIcon;
