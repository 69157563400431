import React from 'react';
import { connect } from 'react-redux';
import path from 'helpers/path';
import RpcTypes from 'constants/rpcTypes';

export default (permissions: RpcTypes[]) => (WrapperComponent) => {
  class AccessDecorator extends React.Component<any, { isChecked: boolean }> {
    state = { isChecked: false };

    componentDidMount(): void {
      const { history } = this.props;
      const accessIsAllowed = permissions.every((alias) =>
        this.isEnabled(alias)
      );
      if (accessIsAllowed) {
        this.setState({ isChecked: true });
      } else {
        history
          ? history.replace(path('/'))
          : window.location.replace(path('/'));
      }
    }

    render() {
      const { isChecked } = this.state;
      if (!isChecked) return null;
      return <WrapperComponent {...this.props} />;
    }

    isEnabled = (name: RpcTypes) => {
      const { permissionsList } = this.props;
      return permissionsList[name]?.status === 'enabled';
    };
  }

  return connect((state: any) => {
    return {
      permissionsList: state.permissions,
    };
  })(AccessDecorator) as typeof WrapperComponent;
};
