import React, { Component } from 'react';
import { connect } from 'react-redux';

import { activateBot, deactivateBot } from 'api/tgBot';
import userApi from 'api/user';
import { addListeners, IListeners } from 'decorators/addListeners';
import checkIsMafUser from 'decorators/checkIsMafUser';
import TgmBot from './TgmBot';
import Messages from 'constants/rpcTypes';
import { AnyObject } from 'types/Common';
import { StoreProps } from 'store';

interface ConnectedProps {
  user: AnyObject;
}

interface OwnProps extends StoreProps {
  customClass: string;
}

type Props = ConnectedProps & StoreProps & OwnProps;

interface State {
  tgmId: string;
  isLoading: boolean;
  validationErrors: AnyObject;
}

@checkIsMafUser
@addListeners([Messages.Confirm_Telegram])
class TgmBotContainer extends Component<Props, State> implements IListeners {
  constructor(props: Props) {
    super(props);
    const { user } = props;

    this.state = {
      tgmId: user.tgmBotIsActive && user.tgmId ? user.tgmId : '',
      isLoading: false,
      validationErrors: {},
    };
  }

  render() {
    const { user, customClass } = this.props;
    const { tgmId, isLoading, validationErrors } = this.state;

    if (!user.isTelegramBotIntegrationEnabled) {
      return null;
    }

    return (
      <TgmBot
        tgmId={tgmId}
        isLoading={isLoading}
        tgmBotIsActive={Boolean(user.tgmBotIsActive)}
        tgmBotLink={user.tgmBotLink}
        validationErrors={validationErrors}
        onChangeTgId={this.changeTgId}
        onToggle={() => this.toggleStatus()}
        customClass={customClass}
      />
    );
  }

  changeTgId = (tgmId) => {
    this.setState({
      tgmId,
      validationErrors: {},
    });
  };

  async toggleStatus() {
    const { user } = this.props;
    this.setState({ isLoading: true });
    user.tgmBotIsActive ? await this.deactivate() : await this.activate();
  }

  async activate() {
    const { tgmId } = this.state;
    try {
      await activateBot(tgmId);
    } catch ({ payload }) {
      if (payload?.validationErrors) {
        this.setState({ validationErrors: payload.validationErrors });
      }
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async deactivate() {
    const { tgmId } = this.state;
    try {
      await deactivateBot(tgmId);
      await userApi.getUser();
    } catch (error) {
      console.error('deactivate error: ', error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async onEvent({ data, name }) {
    const { rpc } = data;
    if (name === Messages.Confirm_Telegram && rpc.status === 'success') {
      try {
        await userApi.getUser();
        this.setState({ isLoading: true });
      } finally {
        this.setState({ isLoading: false });
      }
    }
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
});

export default connect(mapStateToProps)(TgmBotContainer);
