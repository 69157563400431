import React from 'react';

import {
  addTranslation,
  IntlProps,
} from '../../../../decorators/addTranslation';

import SelectItem from '../../../../types/SelectItem';
import CustomSelect from '../../../../components/ui/customSelect/CustomSelect';

const SHOW_DEFAULT_OPTION = {
  id: 'all',
  value: 'all',
  label: 'analytics.mainView.selectWhatToShow.all.label',
};

const SHOW_ONLY_MY = 'ONLY_MY';
const SHOW_ONLY_SHARED_WITH_ME = 'ONLY_SHARED_WITH_ME';

const OPTIONS = [
  SHOW_DEFAULT_OPTION,
  {
    id: 'only_my',
    value: SHOW_ONLY_MY,
    label: 'analytics.mainView.selectWhatToShow.only_my.label',
  },
  {
    id: 'only_shared_with_me',
    value: SHOW_ONLY_SHARED_WITH_ME,
    label: 'analytics.mainView.selectWhatToShow.only_shared_with_me.label',
  },
];

const translate = (getTranslate, options) => {
  return options.map((option) => {
    option.label = getTranslate(option.label);
    return option;
  });
};

interface ConnectedProps {
  selected?: SelectItem;
  onChange: (value: any) => void;
}

type Props = ConnectedProps & IntlProps;

const _whatToShowSelector = (props: Props) => {
  const { getTranslate, selected = SHOW_DEFAULT_OPTION, onChange } = props;
  const translatedSelected = translate(getTranslate, [selected])[0];

  return (
    <CustomSelect
      options={translate(getTranslate, OPTIONS)}
      value={translatedSelected}
      onChange={(value) => onChange(value)}
      customClass='ui-select_large'
      containerSelector='.filters__content'
    />
  );
};

const WhatToShowSelector = addTranslation(_whatToShowSelector);

export {
  WhatToShowSelector,
  SHOW_DEFAULT_OPTION,
  SHOW_ONLY_MY,
  SHOW_ONLY_SHARED_WITH_ME,
};
