import { saveAs } from 'file-saver';

export const saveFileFromBase64 = (fileName, base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([bytes], { type: 'application/pdf' });
  saveAs(blob, fileName);
};

export const loadFromLink = (src) => {
  const element = document.createElement('a');
  element.setAttribute('href', src);
  element.setAttribute('download', src);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export default async function saveFile(fileName, response) {
  const resource = typeof response === 'string' ? response : response.data;
  saveAs(resource, fileName);
}
