import { ChartViewData } from 'types/Analytics';

// eslint-disable-next-line complexity
const ServiceCheckGraphEmpty: (data: ChartViewData) => boolean = (data) => {
  if (
    'chartType' in data &&
    data.chartType === 'pie chart' &&
    'series' in data &&
    Array.isArray(data.series) &&
    Array.isArray(data.series[0].data) &&
    data.series[0].data.length === 0
  ) {
    return true;
  }

  if (
    'chartType' in data &&
    data.chartType === 'line' &&
    'points' in data &&
    data.points?.length === 0
  ) {
    return true;
  }

  if (
    'chartType' in data &&
    ['horizontal_bar', 'bar'].includes(data.chartType) &&
    'xAxis' in data &&
    Array.isArray(data.xAxis?.categories) &&
    data.xAxis?.categories.length === 0
  ) {
    return true;
  }

  if (
    'chartType' in data &&
    data.chartType === 'table' &&
    'columnsGroup' in data &&
    Array.isArray(data.columnsGroup) === true &&
    data.columnsGroup.length === 0 &&
    'rowsGroup' in data &&
    Array.isArray(data.rowsGroup) === true &&
    data.rowsGroup.length === 0 &&
    'tableValues' in data &&
    Array.isArray(data.tableValues) &&
    data.tableValues.length === 0
  ) {
    return true;
  }

  return (
    'chartType' in data &&
    data.chartType === 'simple_table' &&
    'data' in data &&
    Array.isArray(data.data) &&
    data.data.length === 0
  );
};

export { ServiceCheckGraphEmpty };
