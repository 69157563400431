import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 * Запросить параметры активации 2FA
 */
const enable2FAParams = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.GoogleAuth_EnableParams,
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Включить двухфакторную аутификацию
 * @param verificationCode
 * @param secret
 */
const enableTwoFactor = ({
  verificationCode,
  secret,
}: {
  verificationCode: number;
  secret?: string;
}) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.GoogleAuth_Enable,
        payload: { verificationCode, secret },
      },
    ],
  });
};

/**
 * Выключить двухфакторную аутификацию
 * @param verificationCode
 */
const disableTwoFactor = ({ verificationCode }) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.GoogleAuth_Disable,
        payload: { verificationCode },
      },
    ],
  });
};

export default {
  enable2FAParams,
  enableTwoFactor,
  disableTwoFactor,
};
