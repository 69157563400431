import Repository from 'helpers/Repository';
import filtersKeys from 'constants/filters';
import { FilterTypes } from 'constants/FilterTypes';
import modes from 'components/ui/buttonsGroup/modes';
import { paymentHotCurrency } from 'components/quickFiltersContainer/defaultValues';

const filtersValues = Repository.get('store')?.getState().filtersValues;

export default [
  {
    filterId: filtersKeys.recurringStatus,
    type: FilterTypes.buttonsList,
    mode: modes.single,
    metric: 'subscriptions.fastFilter.status',
    list: [
      { id: 'active', text: 'subscriptions.list.status.values.active' },
      { id: 'not set', text: 'subscriptions.list.status.values.notSet' },
      { id: 'cancelled', text: 'subscriptions.list.status.values.cancelled' },
    ],
  },
  {
    filterId: filtersKeys.recurringCurrency,
    type: FilterTypes.buttonsList,
    mode: modes.multi,
    metric: 'subscriptions.fastFilter.currency',
    dictionary: 'paymentHotCurrency',
    list: filtersValues?.paymentHotCurrency?.isFetched
      ? filtersValues.paymentHotCurrency.list
      : paymentHotCurrency,
  },
  {
    filterId: filtersKeys.recurringAmountFrom,
    type: FilterTypes.amount,
    title: 'quickFilters.amountPlaceholder',
    metric: 'subscriptions.fastFilter.amount.enter',
  },
  {
    filterId: filtersKeys.recurringCreatedAt,
    type: FilterTypes.dateButtons,
    metricName: 'subscriptions',
  },
];
