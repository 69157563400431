import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

export interface SummaryColumnData {
  transactionsNumber: number;
  amount: number;
  currency: string;
}

interface ColumnProps {
  text: string;
  spanClass: string;
}

type Props = SummaryColumnData & ColumnProps & IntlProps;

class Column extends PureComponent<Props> {
  render() {
    const { text, spanClass, transactionsNumber, getTranslate } = this.props;
    return (
      <div className={'column item'}>
        <div className={'pb-5'}>
          <span className={spanClass}>{text}</span>
        </div>
        <div className={'pb-5'}>
          {getTranslate('payments.summary_table.transactions')}:
        </div>
        <div className={'pb-5'}>{transactionsNumber | 0}</div>
        <div>{this.getFormattedAmount()}</div>
      </div>
    );
  }

  private getFormattedAmount() {
    const { amount, currency } = this.props;
    let formattedAmount = amount
      ? Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: currency,
        }).format(amount)
      : 0;

    if (currency === 'KZT' && typeof formattedAmount === 'string') {
      formattedAmount = formattedAmount.replace(/\sKZT/gm, '');

      return <>{formattedAmount} &#8376;</>;
    }

    return formattedAmount;
  }
}

export default addTranslation(Column);
