import React from 'react';
import classNames from 'classnames';

export const FieldContainer = ({ children, onClick, customClass }) => {
  return (
    <div
      className={classNames('ui-input ui-input_modern', customClass)}
      onClick={onClick}>
      <div className='ui-input__wrapper'>{children}</div>
    </div>
  );
};

export const FieldLabel = ({ text }) => {
  return <label className='ui-input__label'>{text}</label>;
};

export const FieldValue = ({ text, customClass }) => {
  return (
    <div className={classNames('ui-input__field', customClass)}>{text}</div>
  );
};
