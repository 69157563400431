export default {
  STATE_DELETED: 'deleted',
  STATE_AWAITING_VERIFICATION: 'awaiting verification',
  STATE_NEW: 'new',
  STATE_PROCESSING: 'processing',
  STATE_AWAITING_CLARIFICATION: 'awaiting clarification',
  STATE_ERROR: 'error',
  STATE_DECLINE_GATE: 'decline in gate',
  STATE_DECLINE_BY_USER_GATE: 'decline by user in gate',
  STATE_SUCCESS_GATE: 'success in gate',
  STATE_PARTIALLY_PAID_OUT_GATE: 'partially paid out in gate',
  STATE_DECLINE_DWH: 'decline in dwh',
  STATE_DECLINE_BY_USER_DWH: 'decline by user in dwh',
  STATE_SUCCESS_DWH: 'success in dwh',
  STATE_PARTIALLY_PAID_OUT_DWH: 'partially paid out in dwh',
} as const;
