import React from 'react';
import { connect } from 'react-redux';
import landingState from 'constants/landingState';
import { AnyObject } from 'types/Common';

export default (WrapperComponent) => {
  class AccessRenderDecorator extends React.Component<{ user: AnyObject }> {
    render() {
      const { user } = this.props;
      if (user.landingState === landingState.maf) return null;
      return <WrapperComponent {...this.props} />;
    }
  }

  return connect((state: any) => {
    return {
      user: state.user,
    };
  })(AccessRenderDecorator) as typeof WrapperComponent;
};
