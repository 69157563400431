import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Slider from 'rc-slider';
import constants from './constants';
import 'rc-slider/assets/index.css';
import './sliderPicker.scss';

interface Props {
  min: number;
  max: number;
  value: number | number[];
  onChange: (value: number | number[]) => void;
  disabled?: boolean;
  customClass?: string;
  isRange?: boolean;
}

class SliderPicker extends PureComponent<Props> {
  private sliderRef;

  componentDidMount() {
    this.centerCurrent(true);

    this.sliderRef.addEventListener('wheel', this.handleScroll);
  }

  render() {
    const { min, max, value, disabled, onChange, customClass, isRange } =
      this.props;
    const marks = this.getMarks();

    return (
      <div
        className={classNames('ui-slider-picker', customClass, {
          'ui-slider-picker_range': isRange || Object.values(marks).length > 5,
        })}
        ref={(el) => {
          this.sliderRef = el;
        }}>
        <div className='ui-slider-picker__inner' style={this.getWidth(marks)}>
          <Slider
            range={Array.isArray(value)}
            min={min}
            max={max}
            marks={marks}
            onChange={(newValue) => {
              onChange(newValue);
              setTimeout(this.centerCurrent, 250);
            }}
            value={value}
            disabled={disabled || this.props.min === this.props.max}
          />
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.sliderRef.removeEventListener('wheel', this.handleScroll);
  }

  getMarks = () => {
    const { min, max } = this.props;
    const marks = {};
    for (let i = 0; i <= max - min; i++) {
      if (max - min === 0) {
        marks[' '] = ' ';
      }
      marks[min + i] = {
        style: { width: `${constants.markWidth}px` },
        label: min + i,
      };
    }
    return marks;
  };

  getWidth = (marks) => {
    const needWidth = Object.keys(marks).length > constants.maxYears;
    return {
      width: needWidth ? Object.keys(marks).length * constants.markWidth : '',
      maxWidth: needWidth ? 'none' : '235px',
    };
  };

  handleScroll = (e) => {
    e.preventDefault();
    this.sliderRef.scrollTo({
      left: this.sliderRef.scrollLeft + e.deltaY,
      behavior: 'smooth',
    });
  };

  centerCurrent = (isOpened = false) => {
    if (!this.sliderRef) return;

    const containerCenterPosition =
      this.sliderRef.getBoundingClientRect().left +
      this.sliderRef.clientWidth / 2;

    const handlerLeft = this.sliderRef
      .querySelector('.rc-slider-handle')
      .getBoundingClientRect().left;
    const handlerPosition =
      handlerLeft + constants.handlerWidth / 2 - containerCenterPosition;

    this.sliderRef.scrollTo({
      left: this.sliderRef.scrollLeft + handlerPosition,
      behavior: isOpened ? 'auto' : 'smooth',
    });
  };
}
export default SliderPicker;
