import { AnyObject } from 'types/Common';

export const getAvailableValuesByDependencies = ({
  list,
  filters,
  dependencies,
}: {
  list: any[];
  filters: AnyObject;
  dependencies?: any[];
}) => {
  if (!dependencies) {
    return list;
  }

  dependencies.forEach((dependency) => {
    const filterValue = filters[dependency];

    if (
      (Array.isArray(filterValue) === false &&
        [null, undefined, ''].includes(filterValue) === false) ||
      (Array.isArray(filterValue) === true && filterValue.length > 0)
    ) {
      list = list.filter((item) => {
        const itemValue = item[dependency];

        if (Array.isArray(filterValue)) {
          if (Array.isArray(itemValue)) {
            return filterValue.some((value) => itemValue.includes(value));
          }

          return filterValue.includes(itemValue);
        }

        if (Array.isArray(itemValue)) {
          return itemValue.includes(filterValue);
        }

        return itemValue === filterValue;
      });
    }
  });

  return list;
};

export const getDependencyAvailableValuesFromSelected = ({
  value,
  originalList,
  dependencyKey,
  filters,
}: {
  value: any[];
  originalList?: any[];
  filters: AnyObject;
  dependencyKey: string;
}) => {
  const filterValue = filters[dependencyKey];

  if (Array.isArray(filterValue) === false || filterValue.length === 0) {
    return [];
  }

  const selectedValues = originalList?.filter((item) =>
    value.includes(item.id)
  );

  const availableValuesForDependency = selectedValues?.reduce(
    (previous, current) => {
      return [...previous, ...current[dependencyKey]];
    },
    []
  );

  return filterValue.filter((id) => availableValuesForDependency.includes(id));
};
