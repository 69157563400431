import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import RoundButton from 'components/ui/roundButton';
import './fileTemplate.scss';
import unknownImage from 'images/fileFormats/unknown.svg';

interface Props {
  title: string;
  url?: string;
  loadable?: boolean;
  disabled?: boolean;
  tooltip?: string;
  id?: string;
  loadedIcon?: string;
  error?: string;
  size?: number;
  onDelete?: (id?: string) => void;
}
class FileTemplate extends PureComponent<Props> {
  static defaultProps = {
    loadable: true,
  };

  render() {
    const { url, loadable } = this.props;

    return (
      <div
        className={classNames('ui-file-template', {
          'ui-file-template_loadable': loadable,
          'ui-file-template_loaded': this.props.onDelete,
          'ui-file-template_error': this.props.error,
          'ui-file-template_disabled': this.props.disabled,
        })}>
        {loadable &&
          (url ? (
            <a className='ui-file-template__wrapper' download href={url}>
              {this.renderLoadableInner()}
            </a>
          ) : (
            <span className='ui-file-template__wrapper'>
              {this.renderLoadableInner()}
            </span>
          ))}
        {!loadable && this.renderInner()}
      </div>
    );
  }

  renderLoadableInner = () => {
    const { title, tooltip } = this.props;

    return (
      <>
        <span className='ui-file-template__icon ui-file-template__icon_download'>
          <Icon name='im-Download1' size={16} />
        </span>
        <span className='ui-file-template__text' data-tip={tooltip}>
          {title}
        </span>
      </>
    );
  };

  renderInner = () => {
    const { id, title, tooltip, loadedIcon, size, error, onDelete } =
      this.props;
    return (
      <div className='ui-file-template__wrapper'>
        <div className='ui-file-template__inner'>
          <img
            src={loadedIcon || unknownImage}
            alt='file-icon'
            className='ui-file-template__icon ui-file-template__icon_file'
          />
          <span className='ui-file-template__text' data-tip={tooltip}>
            {title}
          </span>
          {size && (
            <span className='ui-file-template__size'>
              {this.formatSize(size)}
            </span>
          )}
          {onDelete && (
            <RoundButton
              onClick={() => onDelete(id)}
              icon='im-Basket'
              status='danger'
              size={26}
            />
          )}
        </div>
        {error && <span className='ui-file-template__error'>{error}</span>}
      </div>
    );
  };

  formatSize = (bytes, decimals = 2) => {
    if (!+bytes) return '0b';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  };
}

export default FileTemplate;
