import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import RadioGroup from 'components/ui/radioGroup';
import Radio from 'components/ui/radio';
import './radioList.scss';

interface Props extends IntlProps {
  id: string;
  checkedId: string;
  items: { id: string; text: string; isSelected: boolean }[];
  onChange: (filterId: string, value: any) => void;
  withLabel: boolean;
  label?: string;
}

class RadioList extends PureComponent<Props> {
  static defaultProps = {
    items: [],
  };

  render() {
    const { items, id, checkedId, onChange, withLabel, label, getTranslate } =
      this.props;

    return (
      <div className='filters-radio-list'>
        {withLabel && (
          <div className='filters-radio-list__label'>
            {getTranslate(label || `filters.fields.${id}.label`)}
          </div>
        )}
        <div className='filters-radio-list__content'>
          <RadioGroup
            checkedId={checkedId}
            onChange={(newCheckedId) => onChange(id, newCheckedId)}>
            {items.map((item) => {
              return (
                <Radio
                  key={item.id}
                  id={item.id}
                  text={getTranslate(item.text)}
                />
              );
            })}
          </RadioGroup>
        </div>
      </div>
    );
  }

  /**
   *
   * @param itemId
   * @param isSelected
   */
  handleChange = (itemId, isSelected) => {
    const { id, items, onChange } = this.props;
    const copyItems = items.map((item) => {
      if (item.id === itemId) {
        item.isSelected = isSelected;
      }
      return item;
    });
    onChange(id, copyItems);
  };
}

export default addTranslation(RadioList);
