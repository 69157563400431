import React, { VFC } from 'react';
import { connect } from 'react-redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import { DafStatusInformerLayout } from './DafStatusInformerLayout';
import Button from 'components/ui/button';
import I18nHelpers from 'i18n/I18nHelpers';
import { getCommercialOffer } from 'api/maf';

import waitingImage from 'images/getStarted/waiting.svg';

interface ConnectedProps {
  email: string;
}

const CommercialOfferWasAccepted: VFC<
  ConnectedProps & IntlProps & { needButton?: boolean }
> = ({ email, getTranslate, needButton }) => (
  <DafStatusInformerLayout
    title={getTranslate('getStarted.offerIsAccepted.label')}
    mainText={I18nHelpers.getJsxFromHtml(
      getTranslate('getStarted.offerIsAccepted.text', {
        email: `<a href="mailto:${email}">${email}</a>`,
      })
    )}
    buttons={
      needButton && (
        <Button
          id='comOfferDownload'
          status='outline'
          onClick={() => getCommercialOffer()}
          text={getTranslate('getStarted.downloadOffer.button')}
        />
      )
    }
    image={<img src={waitingImage} alt='illustration' />}
  />
);

const mapStateToProps = (state): ConnectedProps => ({
  email: state.user?.boarding?.email || state.settings.salesEmail,
});

export default connect(mapStateToProps)(
  addTranslation(CommercialOfferWasAccepted)
);
