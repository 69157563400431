import React, { PureComponent, Fragment } from 'react';
import { throttle } from 'lodash';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import CustomScrollbar from 'components/ui/customScrollbar';
import Loader from 'components/ui/loader';
import StatementTotals from './components/statementTotals';
import PickedFilters from './components/pickedFilters';
import BalanceItem from './components/balanceItem';

import { Data } from './StatementFinanceTypes';
import MetricService from 'helpers/metricService/MetricService';
import './statementFinance.scss';

interface Props extends IntlProps {
  currentBalanceType: string;
  isLoading: boolean;
  hasFilters: boolean;
  isFetched: boolean;
  data: Data;
}

class StatementFinance extends PureComponent<Props> {
  constructor(props) {
    super(props);

    this.sendMetric = throttle(this.sendMetric, 1000);
  }

  render() {
    const { isLoading } = this.props;

    return (
      <div className='card statement-finance__card'>
        {this.renderContent()}
        {isLoading && <Loader />}
      </div>
    );
  }

  renderContent() {
    const {
      data,
      hasFilters,
      isFetched,
      currentBalanceType,
      isLoading,
      getTranslate,
    } = this.props;

    if (!hasFilters || !isFetched) {
      return (
        <Fragment>
          {!isLoading && (
            <div className='statement-totals__no-data'>
              {getTranslate('statement.generalInfo.hint')}
            </div>
          )}
          <StatementTotals
            currentBalanceType={currentBalanceType}
            emptyData={true}
          />
        </Fragment>
      );
    } else if (hasFilters && !data.balances.length) {
      return (
        <Fragment>
          {!isLoading && (
            <div className='statement-totals__no-data'>
              {getTranslate('statement.noResults.hint')}
            </div>
          )}
          <StatementTotals
            currentBalanceType={currentBalanceType}
            emptyData={true}
          />
        </Fragment>
      );
    }

    return (
      <CustomScrollbar needResetScrollTop onScrollStop={this.sendMetric}>
        <PickedFilters params={data.reportParams} />
        <StatementTotals
          data={data.totals}
          pendingDates={data.pendingDates}
          currentBalanceType={currentBalanceType}
          columns={Object.keys(data.totals.opening)}
        />
        <div className='statement-finance__balances'>
          {data.balances.map((balance, index) => (
            <BalanceItem key={index} columns={data.columns} data={balance} />
          ))}
        </div>
      </CustomScrollbar>
    );
  }

  sendMetric = ({ direction }) => {
    const { hasFilters, data } = this.props;
    if (!hasFilters || !data.balances.length) return;

    MetricService.send({
      action: 'click',
      actionKey:
        direction === 'horizontal'
          ? 'finance.statement.horizontalScroll'
          : 'finance.statement.verticalScroll',
    });
  };
}

export default addTranslation(StatementFinance);
