import React from 'react';
import Button from 'components/ui/button';
import { MafContainerProps } from 'components/maf/MafProps';
import './formControlButtons.scss';
import { IntlProps, addTranslation } from 'decorators/addTranslation';

interface Props
  extends Pick<
    MafContainerProps,
    | 'onCloseModalTab'
    | 'onCommit'
    | 'onUpdate'
    | 'canCommit'
    | 'canUpdate'
    | 'onBackAndCancelStep'
  > {
  isInnerTabOpen: boolean;
  isOverviewStep: boolean;
  isFirstStep: boolean;
}

class FormControlButtons extends React.Component<Props & IntlProps> {
  render() {
    const {
      isInnerTabOpen,
      canCommit,
      isOverviewStep,
      isFirstStep,
      canUpdate,
      getTranslate,
    } = this.props;

    return (
      <div className='form-control-buttons'>
        <div>
          {!isFirstStep && (
            <Button
              id='goBack'
              status='outline'
              disabled={!canUpdate}
              tooltip={
                canUpdate
                  ? undefined
                  : getTranslate('businessdocs.DAF.button.back.tooltip')
              }
              text={
                isInnerTabOpen
                  ? getTranslate('common.cancel.button')
                  : getTranslate('common.back.button')
              }
              onClick={
                isInnerTabOpen
                  ? this.props.onCloseModalTab
                  : this.props.onBackAndCancelStep
              }
            />
          )}
        </div>
        <div className='form-control-buttons__save-buttons'>
          {isOverviewStep ? (
            <Button
              id='submitMaf'
              status='primary'
              text={getTranslate('businessdocs.DAF.button.send')}
              disabled={!canCommit}
              onClick={this.props.onCommit}
              tooltip={
                canUpdate
                  ? undefined
                  : getTranslate('businessdocs.DAF.button.back.tooltip')
              }
            />
          ) : (
            <Button
              id='saveMaf'
              status='primary'
              disabled={!canUpdate}
              text={getTranslate('businessdocs.DAF.button.save')}
              onClick={this.props.onUpdate}
              tooltip={
                canUpdate
                  ? undefined
                  : getTranslate('businessdocs.DAF.button.back.tooltip')
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export default addTranslation(FormControlButtons);
