import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import './overviewDocs.scss';

interface OwnProps {
  wl: string;
  lang: string;
}

type Props = IntlProps & OwnProps;

class OverviewDocs extends PureComponent<Props> {
  render() {
    const { wl, lang, getTranslate } = this.props;
    return (
      <div className='overview-docs'>
        <div className='overview-docs__col'>
          <div className='overview-docs__block overview-docs__block_payment-methods'>
            <div className='overview-docs__title'>
              {getTranslate('getStarted.docs.paymentMethods.title')}
            </div>
            <div className='overview-docs__text'>
              {getTranslate('getStarted.docs.paymentMethods.text')}
            </div>
            <a
              href={`https://${wl}.com/shop/`}
              target='blank'
              className='overview-docs__link ui-button ui-button_status-primary ui-button_size-normal'>
              {getTranslate('getStarted.docs.paymentMethods.more')}
            </a>
          </div>
        </div>
        <div className='overview-docs__col overview-docs__col_blocks'>
          <div className='overview-docs__block overview-docs__block_dash'>
            <div className='overview-docs__title'>
              {getTranslate('getStarted.docs.dashboard.title')}
              <br />
              <span className='overview-docs__light'>
                {getTranslate('getStarted.docs.documentation')}
              </span>
            </div>
            <a
              href={`https://developers.${wl}.com/${lang}/${lang}_dbl_overview.html#${lang}_dbl_overview `}
              target='blank'
              className='overview-docs__link ui-button ui-button_status-outline ui-button_size-normal'>
              {getTranslate('getStarted.docs.dashboard.link')}
            </a>
          </div>
          <div className='overview-docs__block overview-docs__block_mobile'>
            <div className='overview-docs__title'>
              {getTranslate('getStarted.docs.mobile.title')}
              <br />
              <span className='overview-docs__light'>
                {getTranslate('getStarted.docs.documentation')}
              </span>
            </div>
            <a
              href={`https://developers.${wl}.com/${lang}/${lang}_sdk_overview.html `}
              target='blank'
              className='overview-docs__link ui-button ui-button_status-light-inverse ui-button_size-normal'>
              {getTranslate('getStarted.docs.mobile.link')}
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default addTranslation(OverviewDocs);
