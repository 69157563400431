import React, { PureComponent } from 'react';
import { uploadFinancialData } from 'api/financialData';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import ModalTitle from 'components/modal/components/ModalTitle';
import DropZone from 'components/ui/dropzone/Dropzone';
import Button from 'components/ui/button';
import ModalContent from 'components/modal/components/ModalContent';
import ModalFooter from 'components/modal/components/ModalFooter';
import Loader from 'components/ui/loader';
import { addListeners } from 'decorators/addListeners';
import Messages from 'constants/rpcTypes';
import './uploadFinancialData.scss';

interface State {
  value: File | null;
  error: string;
  isLoading: boolean;
}

@addListeners([Messages.FinancialData_FileUpdated])
class UploadFinancialData extends PureComponent<IntlProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      error: '',
      isLoading: false,
    };
  }

  render() {
    const { getTranslate } = this.props;
    return (
      <div className='upload-financial-data'>
        <ModalTitle>
          {getTranslate('admin.uploadFinancialData.newData.form.header')}
        </ModalTitle>
        <ModalContent>
          {this.state.isLoading && <Loader />}
          <div className='upload-financial-data__text'>
            {getTranslate('admin.uploadFinancialData.newData.form.text')}
          </div>
          <div className='upload-financial-data__load'>
            <DropZone
              noClick={true}
              file={this.state.value}
              onDrop={(files) => {
                files[0] && this.onChange(files[0]);
              }}
              acceptFiles='svg'
              onDeleteFile={this.onDeleteFile}
              errorMessage={this.state.error}
            />
          </div>
        </ModalContent>
        <ModalFooter customClass='upload-financial-data__footer'>
          <Button
            status='primary'
            disabled={!this.state.value}
            text={getTranslate('admin.uploadFinancialData.newData.form.save')}
            customClass='upload-financial-data__btn'
            onClick={this.uploadData}
          />
        </ModalFooter>
      </div>
    );
  }

  onChange = (value) => {
    this.setState({ value, error: '' });
  };

  uploadData = async () => {
    this.setState({ isLoading: true });

    try {
      await uploadFinancialData(this.state.value);
    } catch (e) {
      if (e.payload.validationErrors) {
        this.setState({
          error: e.payload.validationErrors.file,
          isLoading: false,
        });
      }
      console.error('Upload file error: ', e);
    }
  };

  onDeleteFile = () => {
    this.setState({ value: null, error: '' });
  };

  onEvent = ({ data }) => {
    if (data.payload.errors) {
      this.setState({ error: data.payload.errors, isLoading: false });
    }
  };
}

export default addTranslation(UploadFinancialData);
