/**
 * to get custom select list for currencies
 * */

export const getCustomSelectCurrencyItems = (currencies) => {
  if (!currencies) return [];
  return currencies.map((item, i) => {
    return {
      label: item.text,
      value: item.id,
      isLastFavorite: item.isFavorite && !currencies[i + 1]?.isFavorite,
    };
  });
};
