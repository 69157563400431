import React, { Component } from 'react';
import InputDate from 'components/ui/inputDate';
import modes from 'components/ui/inputDate/modes';
import Date from 'helpers/Date';
import './inputDateGroup.scss';

interface Props {
  selectedDates: string[];
  params: {};
  onChange: (dates: string[]) => void;
  modern?: boolean;
  containerSelector?: string;
}

class InputDateGroup extends Component<Props, {}> {
  static defaultProps = {
    selectedDates: [],
    params: {},
  };

  render() {
    const { selectedDates, containerSelector, params } = this.props;

    return (
      <div className='ui-input-date-group'>
        <div className='ui-input-date-group__item'>
          <InputDate
            mode={modes.from}
            selectedDate={selectedDates[0]}
            onChange={(date) => this.changeDate(modes.from, date)}
            modern
            containerSelector={containerSelector}
            closeAfterSelect
            {...params}
          />
        </div>
        <div className='ui-input-date-group__item'>
          <InputDate
            mode={modes.to}
            selectedDate={selectedDates[1]}
            onChange={(date) => this.changeDate(modes.to, date)}
            modern
            containerSelector={containerSelector}
            closeAfterSelect
            {...params}
          />
        </div>
      </div>
    );
  }

  changeDate = (mode, date) => {
    const { selectedDates, onChange } = this.props;
    const format = 'datetime';

    if (!date) {
      return onChange([]);
    }

    const resultDate = [...selectedDates];
    if (mode === modes.from) {
      resultDate[0] = date;
      const newDate = Date.createDate(date, format);

      if (
        !resultDate[1] ||
        +newDate.toDate() > +Date.createDate(resultDate[1], format).toDate()
      ) {
        resultDate[1] = Date.getFormat(Date.getEndDay(newDate), format);
      }
    } else if (mode === modes.to) {
      const newDate = Date.createDate(date, format);
      resultDate[1] = date;
      if (
        !resultDate[0] ||
        +newDate.toDate() < +Date.createDate(resultDate[0], format).toDate()
      ) {
        resultDate[0] = Date.getFormat(Date.getStartDay(newDate), format);
      }
    }

    onChange(resultDate);
  };
}

export default InputDateGroup;
