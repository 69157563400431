import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

interface Dictionary {
  isFetch: boolean;
  isLoading: boolean;
  list: any[];
}

const initialState = {
  timezones: {
    isFetch: false,
    isLoading: false,
    list: [],
  },
  languages: {
    isFetch: false,
    isLoading: false,
    list: [],
  } as Dictionary,
};

const dictionaries = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;
  const { alias, list } = payload || {};

  switch (type) {
    case ACTIONS.FETCH_DICTIONARY:
      if (!state[alias]) return state;
      return {
        ...state,
        [alias]: {
          ...state[alias],
          isLoading: true,
        },
      };
    case ACTIONS.FETCH_DICTIONARY_SUCCESS:
      if (!state[alias]) return state;
      return {
        ...state,
        [alias]: {
          list,
          isLoading: false,
          isFetch: true,
        },
      };
    case ACTIONS.RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

export default dictionaries;
