import { getDictionaryMaf } from 'api/maf';
import { MafState } from 'components/maf/MafTypes';
import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';
import LocalStorage from 'helpers/LocalStorage';

export const fetchMafDictionaries = () => {
  return async (dispatch) => {
    try {
      dispatch(
        createAction({
          type: ACTIONS.GET_MAF_DICTIONARIES,
        })
      );
      const dictionaries = await getDictionaryMaf();
      dispatch(
        createAction({
          type: ACTIONS.SET_MAF_DICTIONARIES,
          payload: dictionaries,
        })
      );
    } catch (e) {
      console.error(e);
      dispatch(
        createAction({
          type: ACTIONS.SET_MAF_DICTIONARIES,
          payload: {},
        })
      );
    }
  };
};

export const setBeforeUpdateActions =
  (payload: MafState['actionsBeforeUpdate']) => (dispatch) =>
    dispatch(
      createAction({ type: ACTIONS.SET_BEFORE_UPDATE_ACTIONS, payload })
    );

export const getPreviouslyValidatedTabs =
  (payload: { mafId: string }) => (dispatch) => {
    const validatedTabs = LocalStorage.get('mafValidatedTabs');
    dispatch(
      createAction({
        type: ACTIONS.SHOW_VALIDITY_FOR_TABS,
        payload: validatedTabs?.[payload.mafId] || {},
      })
    );
  };

export const showValidityForTabs =
  (payload: { mafValidatedTabs: Record<string, boolean>; mafId: string }) =>
  (dispatch, getStore) => {
    const { maf: mafStore } = getStore();
    const { mafValidatedTabs, mafId } = payload;
    const newValidatedTabs = { ...mafStore.validatedTabs, ...mafValidatedTabs };

    const validatedTabs = LocalStorage.get('mafValidatedTabs') || {};
    validatedTabs[mafId] = newValidatedTabs;
    LocalStorage.set('mafValidatedTabs', validatedTabs);
    dispatch(
      createAction({
        type: ACTIONS.SHOW_VALIDITY_FOR_TABS,
        payload: newValidatedTabs,
      })
    );
  };

export const setMafStatus = (status: MafState['status']) => (dispatch) =>
  dispatch(createAction({ type: ACTIONS.SET_MAF_STATUS, payload: { status } }));
