import slugify from 'slugify';
import { ArticleSubTitlesType } from '../types';

export const getArticleBySlug = (articles, slug) => {
  return articles.find((article) => article.slug === slug);
};

/**
 *
 * @param rootNode
 * @param scrollRef
 */
export const getSubTitlesFromContent = (rootNode, scrollRef) => {
  const titles: ArticleSubTitlesType[] = [];
  if (!rootNode || !scrollRef) return titles;

  const domElements = rootNode.querySelectorAll('h2');
  const scrollWrapperPosition =
    scrollRef.wrapperElement.getBoundingClientRect();

  for (const [i, element] of domElements.entries()) {
    const text = element.innerText;
    const id = element.getAttribute('id') || '';
    if (!text) continue;

    const elementPosition = element.getBoundingClientRect();
    const top =
      elementPosition.top - scrollRef.scrollTop - scrollWrapperPosition.top;

    titles.push({
      id,
      text,
      top,
      bottom: scrollRef.scrollHeight,
    });

    const prevTitle = titles[i - 1];
    if (prevTitle) {
      prevTitle.bottom = top;
    }
  }

  return titles;
};

/**
 *
 * @param content
 */
export const setIdsForContentSubTitles = (content: string): string => {
  const tempRoot = document.createElement('div');
  tempRoot.innerHTML = content;

  const domElements = tempRoot.querySelectorAll('h2');
  // eslint-disable-next-line unicorn/no-for-loop
  for (let i = 0; i < domElements.length; i++) {
    const element = domElements[i];
    const text = element.innerText;
    element.setAttribute('id', slugify(text));
  }
  return tempRoot.innerHTML;
};
