import React, { Component, MouseEvent } from 'react';
import ReactModal from 'react-modal';
import RoundButton from 'components/ui/roundButton';
import TooltipInfo from 'components/ui/tooltipInfo';
import breakpoints from 'constants/breakpoints';
import './modal.scss';

interface Props {
  isOpened: boolean;
  onClickAwayClose: boolean;
  customClassName?: string;
  needCloseButton?: boolean;
  tooltipId?: string;
  modalId: string;
  theme: string;
  onClose: (event: MouseEvent) => void;
}

class Modal extends Component<Props> {
  render() {
    const {
      isOpened,
      modalId,
      theme,
      onClose,
      onClickAwayClose,
      customClassName = '',
      needCloseButton,
    } = this.props;

    return (
      <ReactModal
        isOpen={isOpened}
        ariaHideApp={false}
        onRequestClose={(e) => {
          if (onClickAwayClose) {
            onClose(e);
          }
        }}
        className={`react-modal-portal modal modal-${modalId} ${
          theme ? `modal_${theme}` : ''
        } ${customClassName}`}
        overlayClassName='modal-overlay'
        contentLabel='Modal'
        closeTimeoutMS={200}>
        {needCloseButton && (
          <RoundButton
            icon='im-Close'
            iconSize={10}
            size={window.innerWidth > breakpoints.panels ? 30 : 24}
            customClass='modal__close'
            onClick={onClose}
          />
        )}

        <div className='modal__inner'>{this.props.children}</div>

        <TooltipInfo id={this.props.tooltipId} />
      </ReactModal>
    );
  }
}

export default Modal;
