import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import './components/massPayouts/massPayouts.scss';

import { addListeners, IListeners } from 'decorators/addListeners';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';

import apiPayouts from 'api/payouts';
import { StoreProps } from 'store';

import showNotification from 'components/ui/notification/showNotification';
import BatchPreview from 'components/formFields/components/massPayment/components/batchPreview';
import SubmittedForm from 'components/formFields/components/submittedForm';
import MassPayment from 'components/formFields/components/massPayment';
import {
  MassPaymentGeneralColumn,
} from 'components/formFields/components/massPayment/components/MassPaymentGeneralColumn';
import {
  MassPaymentAdditionalColumn,
} from 'components/formFields/components/massPayment/components/MassPaymentAdditionalColumn';
import { MassPaymentFooter } from 'components/formFields/components/massPayment/components/MassPaymentFooter';

import MetricService from 'helpers/metricService/MetricService';
import { ActionKeys } from 'helpers/metricService/metricTypes';
import path from 'helpers/path';
import RpcTypes from 'constants/rpcTypes';
import urlsMap from 'constants/urlsMap';
import { AnyObject } from 'types/Common';
import BatchFile from 'types/BatchFile';
import { Template, TemplateDictionary } from '../../../../types/MassRequest';

const POLLING_INTERVAL = 10000;
const STATUS_AWAITING_UPLOAD_COMPLETE = 0;
const TYPE_PAYOUT = 'payout';

interface OwnProps {
  type: string;
  onBack: () => void;
}

interface ConnectedProps {
  wl: number;
}

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  IntlProps &
  WithRouterProps;

interface State {
  file: File | null;
  batch: BatchFile | null;
  isFilePreview: boolean;
  isFileUploading: boolean;
  isBatchPreview: boolean;
  isBatchDeleting: boolean;
  isBatchUploading: boolean;
  isSuccessRequest: boolean;
  validationErrors: AnyObject;
  idsToCheck: Record<string, boolean>;
}

@addListeners([
  RpcTypes.BulkPayouts_Confirm,
  RpcTypes.PaymentOperationBatch_Uploaded,
  RpcTypes.BulkPayouts_Preview,
])
class MassRequestContainer
  extends PureComponent<Props, State>
  implements IListeners {
  private currentOperationBatchId;
  private pollingTimerId;

  constructor(props: Props) {
    super(props);

    this.state = {
      file: null,
      batch: null,
      isBatchPreview: false,
      isFilePreview: false,
      isFileUploading: false,
      isBatchUploading: false,
      isBatchDeleting: false,
      isSuccessRequest: false,
      validationErrors: {},
      idsToCheck: {},
    };
  }

  componentWillUnmount(): void {
    this.stopPolling();
  }

  render() {
    const { type } = this.props;
    return (
      <MassPayment
        id={type}
        isLoading={this.state.isBatchUploading}
        isSuccess={this.state.isSuccessRequest}
        isFilePreview={this.state.isBatchPreview}
        batch={this.state.batch}
        previewText={`payouts.request.mass.${type}.infoText`}
        renderGeneralColumn={this.renderGeneralColumn}
        renderAdditionalColumn={this.renderAdditionalColumn}
        renderFooter={this.renderFooter}
        renderBatch={this.renderBatchPreview}
        renderSubmitted={this.renderSubmitted}
      />
    );
  }

  renderGeneralColumn = () => {
    const { type, wl, getTranslate } = this.props;

    return (
      <MassPaymentGeneralColumn
        type={type}
        title={getTranslate(
          `payouts.request.mass.${type}.loading.templatesTitle`,
        )}
        templatesText={getTranslate(
          'payouts.request.mass.readymadeTemplate.label',
        )}
        infoText={
          type === 'payout' && wl === 0
            ? getTranslate(
              'payouts.request.mass.readymadeTemplate.info.tooltip',
            )
            : ''
        }
        templates={this.getTemplatesByType(type)}
      />
    );
  };

  getTemplatesByType = (type: string): TemplateDictionary<Template> | Array<Template> => {
    const { getTranslate } = this.props;

    if (type === TYPE_PAYOUT) {
      return {
        [getTranslate('payouts.request.mass.loading.headerCard')]: [
          {
            title: getTranslate('payouts.request.mass.loading.templateCard'),
            url: `files/Mass_${type}s_card.csv`,
            isAvailable: true,
          },
        ],
        [getTranslate('payouts.request.mass.loading.headerAPS')]: [
          {
            title: getTranslate('payouts.request.mass.loading.templateAPS'),
            url: `files/Mass_${type}s_APS.csv`,
            isAvailable: true,
          },
          {
            title: getTranslate('payouts.request.mass.loading.templateWallet'),
            url: `files/Mass_${type}s_wallet.csv`,
            isAvailable: true,
          },
          {
            title: getTranslate('payouts.request.mass.loading.templateBanks'),
            url: `files/Mass_${type}s_banks.csv`,
            isAvailable: true,
          },
          {
            title: getTranslate('payouts.request.mass.loading.templateBankTransfer'),
            url: `files/Mass_${type}s_bank_transfer.csv`,
            isAvailable: true,
          },
          {
            title: getTranslate('payouts.request.mass.loading.templateCrypto'),
            url: `files/Mass_${type}s_crypto.csv`,
            isAvailable: true,
          },
        ],
      };
    }

    return [
      {
        title: getTranslate('payouts.request.mass.loading.templateCard'),
        url: `files/mass_${type}_cards.csv`,
        isAvailable: true,
      },
      {
        title: getTranslate('payouts.request.mass.loading.templateAPS'),
        url: `files/mass_${type}_aps.csv`,
        isAvailable: true,
      },
    ];
  };


  renderAdditionalColumn = () => {
    const { getTranslate } = this.props;

    return (
      <MassPaymentAdditionalColumn
        title={getTranslate('remittance.addPayout.mass.attachFiles.label')}
        batch={this.state.batch}
        isFilePreview={this.state.isFilePreview}
        file={this.state.file}
        isFileLoading={this.state.isFileUploading}
        onChangeFile={(newFile) => this.validateFile(newFile)}
        onChangeField={this.changeField}
        onDeleteFile={this.resetState}
        error={this.state.validationErrors?.file}
        switcherText={getTranslate('payouts.request.mass.loading.filePreview')}
      />
    );
  };

  renderFooter = () => {
    const { type, getTranslate } = this.props;

    return (
      <MassPaymentFooter
        id={type}
        isFileLoading={this.state.isBatchUploading}
        backUrl={path(`/${urlsMap.payouts}`)}
        isFilePreview={this.state.isFilePreview}
        isBatchPreview={this.state.isBatchPreview}
        backButtonText={getTranslate('payouts.request.backBtn')}
        createButtonText={this.setButtonText()}
        canCreate={!this.checkValidation()}
        onBackCallback={this.backToPayouts}
        onCreate={this.setButtonHandler()}
      />
    );
  };

  renderBatchPreview = () => {
    if (
      !this.state.batch ||
      !this.state.idsToCheck ||
      !this.state.isBatchPreview
    )
      return null;

    return (
      <BatchPreview
        type={this.props.type}
        batch={this.state.batch}
        file={this.state.file}
        isDeleting={this.state.isBatchDeleting}
        onDeleteRow={() => this.deletePayoutFromBatch()}
        onSelectToDelete={(id) => this.checkPayoutsToDeleteFromBatch(id)}
        idsToCheck={this.state.idsToCheck}
      />
    );
  };

  renderSubmitted = () => {
    return (
      <SubmittedForm
        id={this.props.type}
        text="payouts.request.mass.prevention.text"
        status="success"
        secondaryButtonText="payouts.request.backBtn"
        onSecondaryButtonLink={path(`/${urlsMap.payouts}`)}
        primaryButtonText="payouts.request.newRequestBtn"
        onPrimaryButtonClick={this.resetState}
      />
    );
  };

  async uploadFile() {
    const { file } = this.state;
    const { type } = this.props;

    this.setState({ isFileUploading: true });
    const request =
      type === 'cancel' || type === 'capture'
        ? apiPayouts.uploadFileCaptureCancel
        : apiPayouts.uploadFile;
    try {
      const batch = await request(file, type);
      this.startPolling();
      if (batch) {
        this.currentOperationBatchId = batch.paymentOperationBatchId;
      }
    } catch (error) {
      if (error.payload) {
        this.setState({
          validationErrors: error.payload.validationErrors,
          isFileUploading: false,
        });
      } else {
        this.setState({
          isFileUploading: false,
        });
      }
    }
  }

  setIdsChecks = (batch: BatchFile | null) => {
    if (!batch) return;

    const { rows } = batch;
    const rowsToCheck = rows.reduce((resultIds, item) => {
      return { ...resultIds, [item.id]: false };
    }, {});

    this.setState({ idsToCheck: rowsToCheck });
  };

  checkPayoutsToDeleteFromBatch(data: number | string) {
    const { idsToCheck, batch } = this.state;
    if (!batch) return;

    let newIdsToCheck;
    if (typeof data === 'number') {
      newIdsToCheck = { ...idsToCheck, [data]: !idsToCheck[data] };
    } else {
      newIdsToCheck = this.toggleAll(data);
    }
    this.setState({ idsToCheck: newIdsToCheck });
  }

  toggleAll = (data) => {
    const { idsToCheck } = this.state;

    if (data === 'checkAll') {
      return Object.keys(idsToCheck).reduce((acc, item) => {
        return { ...acc, [item]: true };
      }, {});
    }
    return Object.keys(idsToCheck).reduce((acc, item) => {
      return { ...acc, [item]: false };
    }, {});
  };

  async deletePayoutFromBatch() {
    const {
      batch: { paymentOperationBatchId },
      idsToCheck,
    }: any = this.state;
    const idsToDelete = Object.keys(idsToCheck).filter(
      (item) => idsToCheck[item],
    );
    this.setState({ isBatchDeleting: true });
    try {
      const { batch } = await apiPayouts.deletePayoutsFromBatch({
        payoutQueueIds: [...idsToDelete],
        paymentOperationBatchId,
      });
      this.setState({ batch });
      this.setIdsChecks(batch);
    } finally {
      this.setState({ isBatchDeleting: false });
    }
  }

  async sendBatch() {
    const { getTranslate } = this.props;
    const { batch } = this.state;

    if (!batch) return;

    this.setState({ isBatchUploading: true });

    try {
      await apiPayouts.sendBatch(batch.paymentOperationBatchId);
    } catch ({ payload }) {
      const { validationErrors } = payload;
      if (validationErrors && Object.values(validationErrors).length) {
        showNotification({
          status: 'error',
          content: getTranslate(Object.values(validationErrors)[0] as string),
        });
      }
    } finally {
      this.setState({
        isBatchUploading: false,
      });
    }
  }

  startPolling = () => {
    this.pollingTimerId = setInterval(() => {
      return apiPayouts.getBatchPreview(this.currentOperationBatchId);
    }, POLLING_INTERVAL);
  };

  stopPolling = () => {
    clearInterval(this.pollingTimerId);
  };

  async validateFile(file) {
    this.resetState();
    this.setState({ file });
    await this.uploadFile();
  }

  async sendData() {
    await this.sendBatch();
  }

  goToFilePreview = () => {
    this.setState({
      isBatchPreview: true,
    });
  };

  checkValidation = () => {
    const {
      batch,
      validationErrors,
      isFileUploading,
      isBatchUploading,
      isFilePreview,
      isBatchPreview,
    } = this.state;

    const checkErrors = isEmpty(validationErrors)
      ? !batch
      : !(batch && isFilePreview);

    const batchErrors = isBatchPreview ? batch && batch.isValid : true;

    return (
      isBatchUploading ||
      isFileUploading ||
      !batch ||
      checkErrors ||
      !batchErrors
    );
  };

  changeField = (field: string, value: any) => {
    this.setState((state) => {
      return {
        ...state,
        [field]: value,
      };
    });
  };

  resetState = () => {
    this.setState({
      validationErrors: {},
      file: null,
      batch: null,
      isFilePreview: false,
      isBatchPreview: false,
      isSuccessRequest: false,
      idsToCheck: {},
    });
  };

  setButtonText = () => {
    const { isFilePreview, isBatchPreview } = this.state;
    const { getTranslate } = this.props;

    if (isFilePreview && !isBatchPreview) {
      return getTranslate('payouts.request.detailsBtn');
    }
    return getTranslate('payouts.request.sendRequestBtn');
  };

  setButtonHandler = () => {
    const { isFilePreview, isBatchPreview } = this.state;

    if (isFilePreview && !isBatchPreview) {
      return this.goToFilePreview;
    }
    return () => this.sendData();
  };

  backToPayouts = () => {
    this.props.onBack();
    this.sendMetric(false);
  };

  sendMetric = (isSendData: boolean) => {
    const { type } = this.props;

    const getActionKey = (): keyof typeof ActionKeys => {
      if (isSendData) {
        return type === 'payout'
          ? 'manualPayments.request.massPayouts.send'
          : 'manualPayments.request.massRefunds.send';
      }
      return type === 'payout'
        ? 'manualPayments.request.massPayouts.backToRegistry'
        : 'manualPayments.request.massRefunds.backToRegistry';
    };

    MetricService.send({
      action: type === 'payout' ? 'click' : 'refund',
      actionKey: getActionKey(),
    });
  };

  onEvent = ({ name, data }) => {
    const { status } = data.rpc;
    const { payload } = data;

    if (
      !this.currentOperationBatchId &&
      payload.paymentOperationBatchId !== this.currentOperationBatchId
    )
      return;
    switch (name) {
      case RpcTypes.BulkPayouts_Confirm:
        if (status === 'success') {
          this.setState({
            isSuccessRequest: true,
          });
          this.sendMetric(true);
        }
        break;
      case RpcTypes.BulkPayouts_Preview: {
        const { batch, validationErrors } = payload;

        if (
          !validationErrors &&
          batch.status !== STATUS_AWAITING_UPLOAD_COMPLETE
        )
          return;

        this.setState({
          validationErrors: validationErrors || {},
          batch: batch || null,
          isFileUploading: false,
        });
        batch && this.setIdsChecks(batch);
        this.stopPolling();
        break;
      }

      case RpcTypes.PaymentOperationBatch_Uploaded:
        return apiPayouts.getBatchPreview(this.currentOperationBatchId);
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  wl: state.settings.wlId,
});

export default withRouter(
  connect(mapStateToProps)(addTranslation(MassRequestContainer)),
);
