import React from 'react';
import Icon from 'components/ui/icon';
import classNames from 'classnames';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { ModalChartTypes } from 'types/Analytics';
import RpcTypes from "../../../../../../constants/rpcTypes";

export enum AdvancedAnalyticsEntityType {
  operations = 'operations',
  payments = 'payments',
  inout = 'inout',
  payments_sa = 'payments_sa',
  inout_sales_agents = 'inout_sales_agents',
  topten = 'topten',
  declineReasons = 'declineReasons', // pivot
  declineCodes = 'declineCodes',
  fraud_report = 'fraud_report',
  chargeback = 'chargeback',
}

interface Props extends IntlProps, WithPermissions {
  setType: (type: AdvancedAnalyticsEntityType) => void;
  selectedType: AdvancedAnalyticsEntityType;
  chartTypes: ModalChartTypes;
}

class AdvancedAnalyticsEntityTypes extends React.PureComponent<Props> {
  getContent({ id, icon, text, title, permission }) {
    const { setType, selectedType, getTranslate } = this.props;
    
    return (
      <div
        key={id}
        className={classNames(`chart-types__item`, {
          'chart-types__item_selected': id === selectedType,
        })}
        onClick={() => setType(id)}>
        <div className='chart-types__title'>
          <div className='chart-types__svg-container'>
            <Icon name={icon.name} size={18} className={icon.className} />
          </div>
          <span>{this.getTitle(title, permission)}</span>
        </div>
        <div className='chart-types__description'>{getTranslate(text)}</div>
      </div>
    );
  }

  whatToRender({ id, icon, text, title, permission }) {
    const { isEnabled } = this.props;

    if (permission !== undefined && isEnabled(permission)) {
      return this.getContent({ id, icon, text, title, permission });
    } else if (permission !== undefined && !isEnabled(permission)) {
      return null;
    }

    return this.getContent({ id, icon, text, title, permission });
  }

  render() {
    const { chartTypes } = this.props;

    return (
      <div className='chart-types'>
        {chartTypes.map(
          ({ id, title, icon, text, permission, permissionsNotFor }) =>
            this.whatToRender({
              id,
              icon,
              text,
              title,
              permission,
            })
        )}
      </div>
    );
  }
  
  getTitle(title, permission) {
    const { isEnabled, getTranslate } = this.props;
    let resultedTitle = getTranslate(title);

    if (permission === RpcTypes.Analytics_SalesAgents && isEnabled(RpcTypes.Analytics_AdvancedAnalyticsOnly)) {
      resultedTitle += ' (' + getTranslate('analytics.editForm.for_sales_postfix.tab') + ')';
    }

    return resultedTitle;
  }
}

const AdvancedAnalyticsEntityTypes_ = addTranslation(
  addPermissions(AdvancedAnalyticsEntityTypes)
);

export { AdvancedAnalyticsEntityTypes_ as AdvancedAnalyticsEntityTypes };
