import { forEach } from 'lodash';
import { createSelector } from 'reselect';
import Utils from 'helpers/Utils';

const getFilters = (state, name, onlyUserData = false) => {
  return {
    onlyUserData,
    filters: state.filters[name]?.fields || state.filters[name],
  };
};

export default createSelector(getFilters, ({ filters, onlyUserData }) => {
  const values = {};

  forEach(filters, (filter, key) => {
    const { value } = filter;
    if (
      (onlyUserData && key === '__lastApply') ||
      !Utils.hasProp(filter, 'filterId')
    )
      return;
    if (typeof value === 'number' || value?.length) {
      values[filter.filterId] = value;
    }
  });
  return values;
});
