import React, { Component } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import classNames from 'classnames';
import path from 'helpers/path';
import { AnyObject } from 'types/Common';
import './link.scss';

interface Props {
  url: string | AnyObject;
  isDisabled?: boolean;
  className?: any;
  onClick?: (event) => void;
  target?: string;
}

class Link extends Component<Props> {
  static defaultProps = {
    isDisabled: false,
    onClick: () => null,
    target: '_self',
  };

  render() {
    const { url, isDisabled, className, children, onClick, target, ...rest } =
      this.props;
    const classes = classNames('ui-link', {
      'ui-link_disabled': isDisabled,
      [className]: !!className,
    });
    const location =
      typeof url === 'string'
        ? path(url)
        : { ...url, pathname: path(url.pathname) };
    return (
      <ReactLink
        onClick={onClick}
        className={classes}
        to={location}
        target={target}
        {...rest}>
        {children}
      </ReactLink>
    );
  }
}

export default Link;
