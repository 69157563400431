import getCustomSelectItems from './getCustomSelectItems';
import getSelectionListItems from './getSelectionListItems';
import FieldTypes from 'types/fieldTypes';
import { getCustomSelectCurrencyItems } from 'helpers/currencyHelper';

export default (
  filterValues,
  filters: {
    name: string;
    type: string; //'select' | 'multiSelect';
    valueKey?: string;
  }[]
) => {
  return filters.reduce((result, item) => {
    const { name, type } = item;
    const list = filterValues[item.name]?.list || [];
    const valueKey = item.valueKey || name === 'projectId' ? 'projectId' : 'id';
    const getSelectList = () => {
      if (type === FieldTypes.select) {
        if (name === 'currency') {
          return getCustomSelectCurrencyItems(list);
        }
        return getCustomSelectItems({ list, valueKey });
      } else if (type === FieldTypes.multiselect) {
        return getSelectionListItems({
          originalList: list,
          selectedList: [],
          keyId: valueKey,
        });
      }
      return list;
    };

    return {
      ...result,
      [name]: {
        ...filterValues[name],
        list: getSelectList(),
      },
    };
  }, {});
};
