import BaseMessageHandler from '../BaseMessageHandler';
import { wl } from 'actions/settings';

const defaultTheme = {
  colors: {
    brand: {
      main: '#00579e',
      highlight: '#004278',
      dark: '#043966',
    },
    accent: {
      main: '#00cc24',
      highlight: '#00a61d',
      dark: '#008016',
    },
  },
};

export default class GET_WL extends BaseMessageHandler {
  default = () => {
    const { dispatch, message } = this;

    const config = message.payload.themeConfig || defaultTheme;

    Object.keys(config.colors).forEach((name) => {
      Object.entries(config.colors[name]).forEach(([type, color]) => {
        this.setCssVariables(name, type, color);
      });
    });

    dispatch(wl(message.payload));
  };

  setCssVariables = (name, type, color) => {
    const root = document.documentElement;
    root.style.setProperty(
      `--${name}-${type}`,
      color || defaultTheme.colors[name][type]
    );
  };
}
