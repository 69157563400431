import BaseMessageHandler from '../BaseMessageHandler';
import { setDocLinks } from 'actions/demoStage';

export default class DemoStage_LandingData extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;

    dispatch(setDocLinks(message.payload));
  };
}
