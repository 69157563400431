import React, { PropsWithChildren, PureComponent } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import PageTemplate from 'components/pageTemplate/PageTemplate';
import FormFieldsContainer from 'components/formFields';
import FormFieldsComponents from 'components/formFields/components/constructorComponents';
import { UserFields } from './UserFieldsInterface';
import MetricService from 'helpers/metricService/MetricService';
import ButtonLikeSelect from 'components/ui/buttonLikeSelect';
import './userBuilder.scss';

interface OwnProps extends IntlProps {
  fields: UserFields;
  isEditing: boolean;
  isLoading: boolean;
  canEdit: boolean;
  canSendForm: boolean;
  isSubmitted: boolean;
  onSend: () => void;
  backendError: boolean;
  isAdmin: boolean;
  renderForm: () => JSX.Element | null;
  backToForm: () => void;
  selectedPermissionsAmount: number;
  isOpenedAdditionalBlock: boolean;
  toggleAdditionalBlock: (isOpen: boolean) => void;
}

type Props = PropsWithChildren<OwnProps>;

class UserBuilder extends PureComponent<Props> {
  render() {
    const { isSubmitted, isLoading } = this.props;

    return (
      <PageTemplate.Main topPadding>
        <PageTemplate.Container>
          <FormFieldsContainer
            id='user-builder'
            title={this.getFormTitle()}
            onReset={this.props.backToForm}
            isLoading={isLoading}
            formFields={this.renderForm()}
            isSubmitted={isSubmitted}
            submittedTitle={this.getSubmittedTitle()}
            submittedText={this.getSubmittedText()}
            backUrl={this.getBackButtonUrl()}
            backText='myteam.adduser.back.button'
            repeatCreateButtonText={this.getGoToFormButtonText()}
          />
        </PageTemplate.Container>
      </PageTemplate.Main>
    );
  }

  renderForm = () => {
    const {
      isAdmin,
      selectedPermissionsAmount,
      toggleAdditionalBlock,
      getTranslate,
    } = this.props;
    return (
      <FormFieldsComponents.Container onSubmit={this.props.onSend}>
        <FormFieldsComponents.Content
          text={getTranslate(
            isAdmin ? 'admin.adduser.infoText' : 'myteam.adduser.infoText'
          )}>
          <FormFieldsComponents.GeneralColumn
            title={getTranslate('myteam.adduser.generalInfo.label')}>
            <ButtonLikeSelect
              label={getTranslate('myteam.adduser.permissions')}
              placeholder={`${selectedPermissionsAmount} ${getTranslate(
                'myteam.adduser.selectedPermissions'
              )}`}
              onClick={() => toggleAdditionalBlock(true)}
              customClass='ui-form-fields__item user-builder__mobile-item'
            />
            {this.props.renderForm()}
          </FormFieldsComponents.GeneralColumn>
          <FormFieldsComponents.AdditionalColumn
            title={getTranslate('myteam.adduser.additionalInfo.label')}
            customClass={classNames(
              'ui-form-fields__col_flex user-builder__roles-permissions',
              {
                'user-builder__roles-permissions_is-open':
                  this.props.isOpenedAdditionalBlock,
              }
            )}>
            {this.props.children}
          </FormFieldsComponents.AdditionalColumn>
        </FormFieldsComponents.Content>
        <FormFieldsComponents.Footer
          backUrl={this.getBackButtonUrl()}
          onBack={this.onBack}
          backText={getTranslate('myteam.adduser.back.button')}
          isLoading={this.props.isLoading}
          createButtonText={this.getApplyButtonText()}
          repeatCreateButtonText={this.getGoToFormButtonText()}
          canCreate={this.props.canEdit && this.props.canSendForm}
        />
      </FormFieldsComponents.Container>
    );
  };

  getFormTitle() {
    const { isEditing, getTranslate, canEdit } = this.props;
    if (isEditing) {
      return canEdit
        ? getTranslate('myteam.edituser.header')
        : getTranslate('myteam.edituser.merchantAdmin.header');
    }
    return getTranslate('myteam.adduser.header');
  }

  getSubmittedTitle = () => {
    const { isEditing } = this.props;
    return isEditing
      ? 'myteam.edituser.success.header'
      : 'myteam.adduser.success.header';
  };

  getSubmittedText = () => {
    const { isEditing, isAdmin, getTranslate } = this.props;
    if (isEditing) {
      return 'myteam.edituser.editsuccessWindow.text';
    }
    return (
      <>
        <p>{getTranslate('myteam.adduser.sentConfirmLetter.text1')}</p>
        <p>{getTranslate('myteam.adduser.sentConfirmLetter.text2')}</p>
        {!isAdmin && (
          <p className='ui-submitted-form__item_subtext'>
            {getTranslate('myteam.adduser.sentConfirmLetter.text3')}
          </p>
        )}
      </>
    );
  };

  getApplyButtonText = () => {
    const { isEditing, getTranslate } = this.props;
    return isEditing
      ? getTranslate('myteam.edituser.savechanges.button')
      : getTranslate('myteam.adduser.createuser.button');
  };

  getBackButtonUrl = () => {
    return this.props.isAdmin ? '/admin' : '/team';
  };

  getGoToFormButtonText = () => {
    const { isEditing } = this.props;
    return isEditing
      ? 'myteam.edituser.backToUser.button'
      : 'myteam.edituser.backToCreate.button';
  };

  onBack = () => {
    MetricService.send({
      action: 'click',
      actionKey: 'myTeam.newUser.backToRegistry',
    });
  };
}

export default addTranslation(UserBuilder);
