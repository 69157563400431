import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

export const getTemplates = (params = {}): Promise<any> => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.ReportTemplate_List,
          payload: { ...params },
        },
      ],
      isCancelable: true,
    })
    .then((data: any) => ({
      items: data.rows,
      totalRows: Infinity,
    }));
};

export const deleteReport = (params): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ReportTemplateTaskHistory_Delete,
        payload: { ...params },
      },
    ],
  });
};

export const activateTemplate = (params): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ReportTemplate_Activate,
        payload: { ...params },
      },
    ],
  });
};

export const deactivateTemplate = (params): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ReportTemplate_Deactivate,
        payload: { ...params },
      },
    ],
  });
};

export const deleteTemplate = (reportTemplateId): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ReportTemplate_RemoveTemplateFlag,
        payload: { reportTemplateId },
      },
    ],
  });
};

export const getReports = (params): Promise<any> => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.ReportTemplateTaskHistory_List,
          payload: { ...params },
        },
      ],
    })
    .then((data: any) => ({
      items: data.rows,
      totalRows: Infinity,
    }));
};

export const createNowReport = (params): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ReportTemplateTaskHistory_ConstructNow,
        payload: { ...params },
      },
    ],
  });
};

export const getReportTemplate = (reportTemplateId): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ReportTemplate_Get,
        payload: { reportTemplateId },
      },
    ],
  });
};

export const createReportTemplate = (params): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ReportTemplate_Create,
        payload: { ...params },
      },
    ],
    showValidateNotification: false,
  });
};

export const getReportTemplates = (): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ReportTemplate_ListTemplates,
      },
    ],
  });
};

export const getReportColumns = (payload: {
  reportType: string;
  projectIds: string[];
}): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ReportTemplate_ListColumns,
        payload,
      },
    ],
  });
};

export default {
  getTemplates,
  getReports,
  createNowReport,
  getReportColumns,
};
