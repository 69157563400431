import FiltersStateFactory from 'factories/FiltersStateFactory';
import { createFilters, resetOneFilter } from './creators';
import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';
import SearchFilters from 'types/SearchFilters';

interface State {
  selectedCount: number;
  list: SearchFilters;
}

const initialState: State = {
  selectedCount: 0,
  list: FiltersStateFactory.getInitialSearchFilters(),
};

const searchFilters = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.APPLY_SEARCH_FILTERS:
      return createFilters(state, payload.filters);
    case ACTIONS.RESET_ONE_SEARCH_FILTER:
      return resetOneFilter(initialState, state, payload.filterId);
    case ACTIONS.RESET_SEARCH_FILTERS:
    case ACTIONS.RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

export default searchFilters;
