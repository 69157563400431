import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import Checkbox from 'components/ui/checkbox';
import Panel from 'components/ui/panel';
import statuses from 'components/ui/button/statuses';
import { AnyObject } from 'types/Common';
import './buttonDropDown.scss';

interface Props extends IntlProps {
  id: string;
  text: string;
  items: AnyObject[];
  position?: 'left' | 'right';
  onChange: (items) => void;
  selected: string[];
  disabled?: boolean;
  status?: keyof typeof statuses;
  customClass?: string;
  isSingle?: boolean;
}

interface State {
  isOpen: boolean;
  isActive: boolean;
}

class ButtonDropDown extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isActive: false,
    };
  }
  static defaultProps = {
    position: 'left',
    status: 'outline',
  } as const;

  componentDidMount() {
    this.checkActive();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (!isEqual(prevProps.selected, this.props.selected)) {
      this.checkActive();
    }
  }

  render() {
    const {
      id,
      items,
      selected,
      onChange,
      position,
      disabled,
      status,
      isSingle,
      customClass,
      getTranslate,
    } = this.props;
    const { isOpen, isActive } = this.state;
    const text = this.getText();
    return (
      <div
        className={classNames(
          `button-drop-down button-drop-down_${position}`,
          customClass,
          {
            'button-drop-down_active': isActive,
            'button-drop-down_opened': isOpen,
            'button-drop-down_single': isSingle,
            [`button-drop-down_status-${status}`]: !!status,
          }
        )}>
        <span className='button-drop-down__button'>
          <Button
            status={status}
            id={id}
            text={text}
            icon={status === 'light' ? 'dropdownTriangle' : 'im-Arrow-down'}
            iconSize={7}
            iconPosition='right'
            onClick={() => this.toggleList()}
            disabled={disabled}
            customClass={isActive ? 'ui-button_status-light-active' : ''}
          />
        </span>
        <Panel
          isOpened={isOpen}
          renderCloseControl={false}
          animationDuration={250}
          excludeFromCloseTrigger={['.button-drop-down']}
          customClass='button-drop-down__panel'
          onClose={() => this.toggleList(false)}>
          {!isSingle && (
            <Button
              type='button'
              customClass='button-drop-down__reset ui-button_panel-button'
              text={getTranslate('filters.header.reset')}
              onClick={this.resetAll}
            />
          )}
          <ul className='button-drop-down__list'>
            {items.map((item) => {
              const itemId = item.id;
              const itemText = item.text;

              return (
                <li
                  className={classNames('button-drop-down__item', {
                    'button-drop-down__item_active': selected.includes(item.id),
                  })}
                  key={itemId}
                  onClick={() => {
                    if (!isSingle) return;
                    onChange(itemId);
                    this.toggleList();
                  }}>
                  {isSingle ? (
                    <span className='button-drop-down__text'>{itemText}</span>
                  ) : (
                    <Checkbox
                      id={itemId}
                      checked={selected.includes(itemId)}
                      onChange={(e) => {
                        onChange([
                          { id: itemId, isSelected: e.target.checked },
                        ]);
                      }}
                      text={itemText}
                      customClass='button-drop-down__checkbox'
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </Panel>
      </div>
    );
  }

  checkActive = () => {
    const { selected, items } = this.props;
    const isActive = !!items.find((item) => {
      return selected.includes(item.id);
    });

    this.setState({ isActive });
  };

  resetAll = () => {
    const { items, onChange } = this.props;
    const newItems = items.map((item) => {
      item.isSelected = false;
      return item;
    });
    onChange(newItems);
  };

  toggleList = (close?) => {
    const { isOpen } = this.state;
    const isClose = close ?? !isOpen;
    this.setState({
      isOpen: isClose,
    });
  };

  getText = (): string => {
    const { text, selected, isSingle, items } = this.props;
    const selectedItems = items.filter((item) => selected.includes(item.id));
    if (!selectedItems.length) return text;

    if (isSingle) {
      const selectedValue = selectedItems.find(
        (item) => item.id === selected[0]
      );
      return selectedValue?.text ? `${text}: ${selectedValue?.text}` : text;
    }
    return `${text}: ${selectedItems.length}`;
  };
}

export default addTranslation(ButtonDropDown);
