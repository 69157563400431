import ActionReducer from 'types/ActionReducer';
import ACTIONS from 'constants/actionTypes';
const initialState: any = null;

const filters = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;
  let newFilters;

  switch (type) {
    case ACTIONS.INIT_FILTERS:
      return { ...payload.filters };
    case ACTIONS.APPLY_FILTERS:
      //TODO remove after finance section update
      if (state[payload.name].tabs) {
        return {
          ...state,
          [payload.name]: {
            ...state[payload.name],
            fields: payload.filters,
          },
        };
      }
      return {
        ...state,
        [payload.name]: { ...payload.filters },
      };
    case ACTIONS.RESET_ONE_FILTER: {
      newFilters = [...state[payload.name].fields];
      const filterToReset = newFilters.find(
        (item) => item.filterId === payload.filterId
      );
      filterToReset.value = getEmptyValue(filterToReset.value);
      return {
        ...state,
        [payload.name]: { ...state[payload.name], fields: newFilters },
      };
    }
    case ACTIONS.RESET_FILTERS:
      newFilters = [...state[payload.name].fields];
      newFilters.forEach((item, key) => {
        if (payload.resetValues && payload.resetValues[item.filterId]) {
          newFilters[key] = {
            ...newFilters[key],
            ...payload.resetValues[item.filterId],
          };
        } else {
          newFilters[key].value = getEmptyValue(newFilters[key].value);
        }
      });
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          fields: newFilters,
        },
      };
    case ACTIONS.RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

function getEmptyValue(value) {
  if (Array.isArray(value)) {
    return [];
  }
  return '';
}

export default filters;
