import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

export interface Feature {
  additionalFeatureId: number;
  languageCode: string;
  sortIndex: number;
  title: string;
  category: string;
  description: string;
  iconPath: string;
  picturePath: string;
  status: string;
}

export interface AdditionalFeatures {
  isFetched: boolean;
  hasMoreRows: boolean;
  offset: number;
  list: Feature[];
}

const initialState: AdditionalFeatures = {
  isFetched: false,
  hasMoreRows: false,
  offset: 0,
  list: [],
};

const additionalFeatures = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.SET_FEATURES:
      return {
        hasMoreRows: payload.hasMoreRows,
        isFetched: true,
        list: payload.rows.sort((a, b) => {
          if (a.sortIndex > b.sortIndex) {
            return 1;
          } else if (a.sortIndex < b.sortIndex) {
            return -1;
          }
          return 0;
        }),
      };

    case ACTIONS.ADD_FEATURE:
      return {
        ...state,
        list: [...state.list, payload],
      };
    case ACTIONS.UPDATE_FEATURE: {
      const newItems = state.list.map((item) => {
        if (item.additionalFeatureId === payload.additionalFeatureId) {
          return { ...item, ...payload };
        }
        return item;
      });
      return {
        ...state,
        list: newItems,
      };
    }
    case ACTIONS.UPDATE_FEATURES_LIST:
      return {
        ...state,
        list: payload,
      };
    case ACTIONS.UPLOAD_FEATURES_LIST:
      return {
        ...state,
        hasMoreRows: payload.hasMoreRows,
        list: state.list.concat(payload.rows),
      };
    case ACTIONS.DELETE_FEATURE:
      return {
        ...state,
        list: state.list.filter(
          (item) => item.additionalFeatureId !== payload.additionalFeatureId
        ),
      };
    case ACTIONS.RESET_FEATURES:
    case ACTIONS.RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

export default additionalFeatures;
