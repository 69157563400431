import React from 'react';
import classNames from 'classnames';
import DOMPurify from 'dompurify';

import CustomScrollbar from 'components/ui/customScrollbar';

import { ServiceConstructTable } from '../../services/ServiceInOutTable';

import {
  DeclineReasonsData,
  IChartDataObject,
  InOutData,
} from 'types/Analytics';

import { AdvancedAnalyticsEntityType } from 'components/modal/modalList/graphDetails/components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';
import { ServiceChartDataFormatter } from 'components/modal/modalList/graphDetails/services/ServiceChartDataFormatter';

import { addTranslation, IntlProps } from 'decorators/addTranslation';

import './InOutTable.scss';

interface IProps extends IntlProps {
  data: InOutData | DeclineReasonsData;
  className?: string;
  setRef?: (ref: React.Ref<HTMLDivElement>) => void;
  header?: string;
}

interface IState {
  table: string;
}

const hideTotalItemsAdapter = (chartDataObject: IChartDataObject): boolean => {
  const SHOULD_HIDE_TOTAL_ITEMS_CHART_DATA = [
    'avg_channel_amount',
    'avg_channel_amount_in_eur',
    'avg_channel_amount_in_usd',
    'unique_customers',
  ];

  if (
    chartDataObject.chartGroup === AdvancedAnalyticsEntityType.declineReasons
  ) {
    const chartData = ServiceChartDataFormatter.normalizeChartData({
      data: chartDataObject,
    });

    return SHOULD_HIDE_TOTAL_ITEMS_CHART_DATA.includes(chartData) === true;
  }

  return false;
};

class InOutTable extends React.Component<IProps, IState> {
  private tableValues: { [key: string]: number } = {};
  private totalRows: { [key: string]: number } = {};
  private totalColumns: { [key: string]: number } = {};
  private rowsGroup:
    | { [key: string]: { [key: string]: Array<string> } | Array<string> }
    | Array<string> = {};
  private columnsGroup: { [key: string]: any } | Array<string> = {};
  private rows: Array<string> = [];
  private cols: Array<string> = [];
  private totalSum: number = 0;
  private chartRef: React.RefObject<HTMLDivElement> | undefined;
  private chartDataObject: IChartDataObject;

  constructor(props) {
    super(props);

    this.setRef = this.setRef.bind(this);
    this.initializeInstance = this.initializeInstance.bind(this);
    this.chartDataObject = {
      measureField: 'operation_id',
      chartGroup: AdvancedAnalyticsEntityType.operations,
      chartData: 'count',
    };

    const { getTranslate } = props;

    this.initializeInstance();

    this.state = {
      table: ServiceConstructTable({
        rows: this.rows,
        cols: this.cols,
        tableValues: this.tableValues,
        totalRows: this.totalRows,
        totalColumns: this.totalColumns,
        columnsGroup: this.columnsGroup,
        rowsGroup: this.rowsGroup,
        totalSum: this.totalSum,
        hideTotalItemsAdapter,
        chartDataObject: this.chartDataObject,
        getTranslate,
      }),
    };
  }

  initializeInstance() {
    const { data } = this.props;

    this.tableValues = data.tableValues;
    this.totalRows = data.totalRows;
    this.totalColumns = data.totalColumns;
    this.rowsGroup = data.rowsGroup;
    this.columnsGroup = data.columnsGroup;
    this.rows = data.rows;
    this.cols = data.columns;
    this.totalSum = data.totalSum;
    this.chartDataObject = {
      chartData: data.chartData,
      chartGroup: data.chartGroup,
      measureField: data.measureField,
      measureFunction:
        'measureFunction' in data ? data.measureFunction : undefined,
    };
  }

  setRef(ref) {
    const { setRef } = this.props;

    if (!ref) {
      return;
    }

    this.chartRef = ref;
    setRef && setRef(ref);
  }

  generateTable() {
    const { getTranslate } = this.props;

    this.setState({
      table: ServiceConstructTable({
        rows: this.rows,
        cols: this.cols,
        tableValues: this.tableValues,
        totalRows: this.totalRows,
        totalColumns: this.totalColumns,
        columnsGroup: this.columnsGroup,
        rowsGroup: this.rowsGroup,
        totalSum: this.totalSum,
        hideTotalItemsAdapter,
        chartDataObject: this.chartDataObject,
        getTranslate,
      }),
    });
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { getTranslate } = this.props;

    if (prevProps.data.uuid !== this.props.data.uuid) {
      this.initializeInstance();
      this.setState({
        table: ServiceConstructTable({
          rows: this.rows,
          cols: this.cols,
          tableValues: this.tableValues,
          totalRows: this.totalRows,
          totalColumns: this.totalColumns,
          columnsGroup: this.columnsGroup,
          rowsGroup: this.rowsGroup,
          totalSum: this.totalSum,
          hideTotalItemsAdapter,
          chartDataObject: this.chartDataObject,
          getTranslate,
        }),
      });
    }
  }

  render() {
    const { table } = this.state;
    const { className, getTranslate, header } = this.props;

    return (
      <div className={classNames('in-out-table__container', className)}>
        {header !== undefined && (
          <div className='settings-form__header'>{getTranslate(header)}</div>
        )}
        <CustomScrollbar customHeight={362}>
          <div
            ref={this.setRef}
            className='in-out-table'
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(table) }}
          />
        </CustomScrollbar>
      </div>
    );
  }
}

const InOutTable_ = addTranslation(InOutTable);
export { InOutTable_ as InOutTable };
