import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 * Список references
 * @param params
 */
export const getReferenceList = (params) => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.PaymentOperationReference_List,
          payload: { ...params },
        },
      ],
      isCancelable: true,
    })
    .then((data: any) => ({
      items: data.rows,
      totalRows: Infinity,
    }));
};

/**
 * Создать справку
 * @param params
 */
export const createReference = (params) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentOperationReference_Create,
        payload: { ...params },
      },
    ],
  });
};

export default {
  getReferenceList,
};
