import { loadDictionary } from 'api/dictionaries';
import { updateFiltersValues } from 'actions/filtersValues';
import { StoreProps } from 'store';

export default async function fetchUpdateDictionary(
  this: { props: StoreProps },
  name
) {
  const { dispatch } = this.props;
  const result = await loadDictionary({ name }, false);
  result.list = result.elements;
  delete result.elements;
  dispatch(updateFiltersValues({ [name]: result }));
}
