import React from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip';

import { RootState } from 'store';
import api from 'api/getStarted';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import CredentialsCard from 'pages/getStarted/onboarding/components/credentialsCard/CredentialsCard';

interface OwnProps {
  width: 4 | 5;
}

interface ConnectedProps {
  wl: string;
}

type Props = OwnProps & ConnectedProps & IntlProps;

interface State {
  isLoading: boolean;
  projectId: string;
  secretKey: string;
}

class CredentialsCardContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      projectId: '',
      secretKey: '',
    };
  }

  componentDidMount() {
    this.getDemoProject();
  }

  getDemoProject = async () => {
    try {
      this.setState({ isLoading: true });
      const { projectId, secretKey } = await api.getDemoProject();
      this.setState({ projectId, secretKey }, () => Tooltip.rebuild());
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { wl, width, getTranslate } = this.props;
    const { projectId, secretKey, isLoading } = this.state;
    return (
      <CredentialsCard
        projectId={projectId}
        secretKey={secretKey}
        wl={wl}
        isLoading={isLoading}
        width={width}
        getTranslate={getTranslate}
      />
    );
  }
}

const mapStateToProps = (state: RootState): ConnectedProps => ({
  wl: state.settings.wl,
});

export default connect(mapStateToProps)(
  addTranslation(CredentialsCardContainer)
);
