import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 * Получение контрактов
 */
const getContracts = (): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Balance_Contracts,
      },
    ],
  });
};

/**
 * Группы балансов
 */
const getBalance = (contractId?): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Balance_Get,
        payload: { contractId },
      },
    ],
  });
};

export default {
  getContracts,
  getBalance,
};
