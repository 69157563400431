import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Checkbox from 'components/ui/checkbox';
import './checkboxList.scss';

interface Props extends IntlProps {
  id: string;
  title: string;
  items: { id: string; text: string; isSelected: boolean }[];
  onChange: (filterId: string, value: any) => void;
}

class CheckboxList extends PureComponent<Props> {
  render() {
    const { items, title, getTranslate } = this.props;

    return (
      <div className='filters-checkbox-list'>
        <div className='filters-checkbox-list__title'>{title}</div>
        <div className='filters-checkbox-list__content'>
          {items.map((item) => {
            return (
              <Checkbox
                key={item.id}
                id={item.id}
                text={getTranslate(item.text)}
                checked={item.isSelected}
                onChange={(e) => this.handleChange(item.id, e.target.checked)}
              />
            );
          })}
        </div>
      </div>
    );
  }

  /**
   *
   * @param itemId
   * @param isSelected
   */
  handleChange = (itemId, isSelected) => {
    const { id, items, onChange } = this.props;
    const copyItems = items.map((item) => {
      if (item.id === itemId) {
        item.isSelected = isSelected;
      }
      return item;
    });
    onChange(id, copyItems);
  };
}

export default addTranslation(CheckboxList);
