import { createAction } from 'helpers/redux';
import ACTIONS from 'constants/actionTypes';

const wl = (payload) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_WL,
        payload,
      })
    );
  };
};

export { wl };
