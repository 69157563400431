import { SupportMessage } from 'types/Api';
import DateHelpers from 'helpers/Date';

export const getSupportMessage = (params?: Partial<SupportMessage>) => {
  const now = DateHelpers.getDate();

  return {
    id: '',
    replyToMessageId: '',
    from: 'support',
    text: '',
    isRead: true,
    createdAt: DateHelpers.getFormat(now.clone(), 'datetime'),
    createdAtTs: Math.trunc(Date.now() / 1000),
    firstOfDate: false,
    isAnotherYear: false,
    displayDate: {
      date: 'dates.today',
      time: now.clone().format('HH:mm'),
      year: new Date().getFullYear().toString(),
    },
    ...params,
  };
};
