import React, { PureComponent, Fragment } from 'react';
import classNames from 'classnames';
import { IntlProps, addTranslation } from 'decorators/addTranslation';

import Icon from 'components/ui/icon';
import Panel from 'components/ui/panel';
import { UserNotificationsPanel } from 'pages/app/components/userNotifications/index';
import './userNoticationsIndicator.scss';

interface Props extends IntlProps {
  unreadCount: number;
}

interface State {
  showPanel: boolean;
}

class UserNotificationsIndicator extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showPanel: false,
    };
  }

  render() {
    const { unreadCount } = this.props;
    const { showPanel } = this.state;

    return (
      <Fragment>
        <div
          onClick={this.togglePanel}
          className={classNames('user-notifications-indicator', {
            'user-notifications-indicator_opened': showPanel,
          })}>
          <Icon
            name='im-Warning'
            className='user-notifications-indicator__icon'
          />
          {unreadCount > 0 && (
            <span className='user-notifications-indicator__count'>
              {unreadCount > 99 ? '99+' : unreadCount}
            </span>
          )}
        </div>
        <Panel
          id='user-notifications-panel'
          isOpened={showPanel}
          renderCloseControl={false}
          customClass='ui-panel_fixed ui-panel_notifications'
          excludeFromCloseTrigger={['.user-notifications-indicator']}
          onClose={this.closePanel}>
          <UserNotificationsPanel />
        </Panel>
      </Fragment>
    );
  }

  togglePanel = (): void => {
    this.setState((state) => {
      return {
        showPanel: !state.showPanel,
      };
    });
  };

  closePanel = () => {
    this.setState({
      showPanel: false,
    });
  };
}

export default addTranslation(UserNotificationsIndicator);
