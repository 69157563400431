import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getPrefilledPayoutClarification } from 'api/payouts';
import { getMassPayouts, getPayments } from 'api/payments';
import { addIsMounted, IsMounted } from 'decorators/addIsMounted';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { addListeners, IListeners } from 'decorators/addListeners';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import getConfigurationByName from 'selectors/getConfigurationByName';

import { Column, Row } from 'components/ui/table';
import Loader from 'components/ui/loader';
import TopPanelContainer from 'components/topPanel';
import DataListContainer from 'components/dataListContainer/DataListContainer';
import MetricService from 'helpers/metricService/MetricService';
import { ActionKeys } from 'helpers/metricService/metricTypes';
import { getPathUrl } from 'helpers/paymentsPayoutsRequest';
import path from 'helpers/path';
import tableNames from 'constants/tableNames';
import Messages from 'constants/rpcTypes';
import urlsMap from 'constants/urlsMap';
import { minPayoutsTableColumnsWidth } from './constants';
import filtersConfig from '../quickFiltersConfig';
import RequestButton from 'pages/payouts/list/components/requestButton/RequestButton';
import permissionReasons from 'constants/permissionReasons';
import getQuickFiltersValues from 'selectors/getQuickFiltersValues';
import filtersKeys from 'constants/filters';
import { QuickFiltersTableName } from 'types/QuickFilters';
import { formIdRoutes } from '../routesMapper';

interface OwnProps {
  tableName: QuickFiltersTableName;
}

interface ConnectedProps {
  configuration: any[];
  selectedQuickFilters: ReturnType<typeof getQuickFiltersValues>;
}

type Props = OwnProps &
  ConnectedProps &
  IsMounted &
  WithPermissions &
  IntlProps &
  WithRouterProps;

interface State {
  isFetchingPreFilled: boolean;
}

@addListeners([Messages.PaymentOperation_PrefilledPayoutClarification])
class PayoutListContainer
  extends Component<Props, State>
  implements IListeners
{
  constructor(props) {
    super(props);

    this.state = {
      isFetchingPreFilled: false,
    };
  }

  render() {
    const { tableName } = this.props;
    const { isFetchingPreFilled } = this.state;

    const apiRequest =
      tableName === tableNames.massPayouts ? getMassPayouts : getPayments;

    return (
      <Fragment>
        <TopPanelContainer
          name={tableName}
          filtersConfig={filtersConfig[tableName]}
          isFilterButton={false}
          hasSelectedFiltersCustom={this.isSelectedQuickFilters()}
          button={this.getButton()}
          onResetCallback={() =>
            MetricService.send({
              action: 'click',
              actionKey: 'manualPayments.registry.filterDeactivation',
            })
          }
        />
        <DataListContainer
          minColumnsWidth={minPayoutsTableColumnsWidth}
          tableName={tableName}
          quickFilter={tableName}
          apiRequest={apiRequest}
          rowRender={this.renderRow}
        />
        {isFetchingPreFilled && <Loader />}
      </Fragment>
    );
  }

  renderRow = ({ handleDoubleClick, ...data }) => {
    const { configuration } = this.props;
    const keyId = data.paymentId || data.id;
    const { columnWidths, ...columnsData } = data;

    return (
      <Row
        customClass='ui-table__row_clickable'
        onClick={() => {
          this.sendMetric();
          handleDoubleClick(this.goToPayoutCard);
        }}
        key={keyId}
        status={data.paymentStatus}>
        {configuration.map((config) => {
          const { id } = config;
          const content = data[id];

          return (
            <Column
              columnWidths={columnWidths}
              customClass={`ui-table__column_${id}`}
              key={id}
              id={id}
              params={{
                ...config,
                valueId: config.id,
                valueType: config.valueType,
              }}
              data={columnsData}
              content={content || '—'}
            />
          );
        })}
      </Row>
    );
  };

  goToPayoutCard = (data) => {
    const { tableName, isEnabled, history } = this.props;
    if (tableName === tableNames.payouts) {
      if (isEnabled(Messages.Payments_Payment)) {
        if (data.paymentStatus === 'awaiting clarification') {
          return this.getPrefilledPayoutClarification(data);
        }
        history.push(path(getPathUrl(tableName, data)));
      }
    } else {
      history.push(path(getPathUrl(tableName, data)));
    }
  };

  async getPrefilledPayoutClarification({
    payoutQueueId,
    paymentId,
    projectId,
  }): Promise<any> {
    const { isMount } = this.props;

    this.setState({ isFetchingPreFilled: true });

    try {
      let params;
      if (payoutQueueId) {
        params = {
          payoutQueueId,
        };
      } else {
        params = {
          paymentId,
          projectId,
        };
      }
      await getPrefilledPayoutClarification(params);
    } finally {
      if (isMount()) {
        this.setState({ isFetchingPreFilled: false });
      }
    }
  }

  sendMetric = () => {
    const { tableName } = this.props;
    MetricService.send({
      action: 'click',
      actionKey: ActionKeys[`${tableName}.registry.card`],
    });
  };

  isSelectedQuickFilters = () => {
    const { selectedQuickFilters } = this.props;
    const selected = Object.keys(selectedQuickFilters).filter((key) => {
      return !(
        (key === filtersKeys.paymentType &&
          selectedQuickFilters[key].length === 1 &&
          selectedQuickFilters[key][0] === 'payout') ||
        key === filtersKeys.payoutsType
      );
    });
    return Boolean(selected.length);
  };

  getButton = () => {
    return (
      this.canRenderRequestButton() && (
        <RequestButton
          guideInfo={this.props.getTranslate('manualPayments.request.tooltip')}
        />
      )
    );
  };

  canRenderRequestButton(): boolean {
    const { isSomeEnabled, isDisabledByReason } = this.props;
    const messagesToCheck = [
      Messages.PaymentOperation_Payout,
      Messages.BulkPayouts_UploadPayout,
      Messages.BulkPayouts_UploadRefund,
      Messages.MotoPayment_Create,
    ];
    return (
      isSomeEnabled(messagesToCheck) ||
      messagesToCheck.some((item) =>
        isDisabledByReason(
          item,
          permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
        )
      )
    );
  }

  onEvent = ({ name, data }) => {
    const { payload } = data;
    const { history } = this.props;

    if (
      name === Messages.PaymentOperation_PrefilledPayoutClarification &&
      payload.payout
    ) {
      history.push({
        pathname: path(`/${urlsMap.payouts}/request/${formIdRoutes.single}`),
        state: {
          data: payload,
          preFilled: true,
          preFilledClarification: true,
        },
      });
    }
  };
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => ({
  configuration: getConfigurationByName(state, ownProps.tableName),
  selectedQuickFilters: getQuickFiltersValues(state, ownProps.tableName, true),
});

export default withRouter(
  connect(mapStateToProps)(
    addIsMounted(addTranslation(addPermissions(PayoutListContainer)))
  )
);
