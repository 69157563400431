const getValue = (data, valueName, valueKey?) => {
  const source = data;
  return source[valueName] ? source[valueName][valueKey] : source[valueKey];
};

const prepareData = (data) => {
  return {
    projectId: getValue(data, 'project', 'projectId'),
    redirectSuccess: {
      url: getValue(data, 'redirectSuccess', 'url'),
      mode: getValue(data, 'redirectSuccess', 'mode'),
      enabled: getValue(data, 'redirectSuccess', 'enabled'),
    },
    redirectFail: {
      url: getValue(data, 'redirectFail', 'url'),
      mode: getValue(data, 'redirectFail', 'mode'),
      enabled: getValue(data, 'redirectFail', 'enabled'),
    },
    redirectTokenize: {
      url: getValue(data, 'redirectTokenize', 'url'),
      mode: getValue(data, 'redirectTokenize', 'mode'),
      enabled: getValue(data, 'redirectTokenize', 'enabled'),
    },
    merchantBackSuccess: {
      url: getValue(data, 'merchantBackSuccess', 'url'),
      enabled: getValue(data, 'merchantBackSuccess', 'enabled'),
    },
    merchantBackFail: {
      url: getValue(data, 'merchantBackFail', 'url'),
      enabled: getValue(data, 'merchantBackFail', 'enabled'),
    },
  };
};

export { prepareData };
