import React from 'react';
import { InferableComponentEnhancer } from 'react-redux';

export interface IsMounted {
  isMount: () => boolean;
}

export const addIsMounted: InferableComponentEnhancer<IsMounted> = (
  Component
) => {
  class MountedWrapper extends React.Component<any> {
    private isMountedComponent: boolean = false;

    componentDidMount(): void {
      this.isMountedComponent = true;
    }

    componentWillUnmount(): void {
      this.isMountedComponent = false;
    }

    render() {
      return (
        <Component
          {...(this.props as any)}
          isMount={() => this.isMountedComponent}
        />
      );
    }

    static WrappedComponent = Component;

    static displayName = `addIsMounted(${
      Component.displayName || Component.name || 'Component'
    })`;
  }

  return MountedWrapper as any;
};
