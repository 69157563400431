import React, { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';

import { changeFixedConfiguration } from 'actions/configuration';
import Icon from 'components/ui/icon';
import tableNames from 'constants/tableNames';
import { StoreProps } from 'store';

import './lock.scss';

interface OwnProps {
  content: string | ReactNode;
  isFixed: boolean;
  tableName: string | tableNames;
}

type Props = OwnProps & StoreProps;

class Lock extends PureComponent<Props> {
  render() {
    const { content, isFixed } = this.props;

    return (
      <span className='ui-table-lock'>
        {content}
        <Icon
          size={12}
          className='ui-table-lock__icon'
          name={isFixed ? 'im-Lock-close' : 'im-Lock-open'}
          onClick={this.fixedColumn}
        />
      </span>
    );
  }

  fixedColumn = () => {
    const { dispatch, tableName } = this.props;
    dispatch(changeFixedConfiguration(tableName));
  };
}

export default connect()(Lock);
