import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Animation from 'components/ui/animation';
import Input from 'components/ui/input';
import Button from 'components/ui/button';
import CustomSelect from 'components/ui/customSelect';
import filtersKeys from 'constants/filters';
import { QuickIconsFiltersType } from 'types/QuickFilters';
import { filtersKeysType } from 'types/Filters';
import SelectItem from 'types/SelectItem';
import { AnyObject } from 'types/Common';
import './quickFilters.scss';

interface Props extends IntlProps {
  filters: QuickIconsFiltersType;
  paymentMethods: SelectItem[];
  onOpenModal: (data: AnyObject) => void;
  onChangeFilter: (key: filtersKeysType, value: any) => void;
}

class QuickFilters extends PureComponent<Props> {
  render() {
    const { onOpenModal } = this.props;

    return (
      <div className='quick-filters quick-filters_icons'>
        <Animation>
          <div className='quick-filters__inner'>
            <Button
              text='Add new icon'
              status='success'
              onClick={onOpenModal}
              customClass='quick-filters__block'
            />
            {this.renderPaymentMethodName()}
            {this.renderPaymentMethods()}
          </div>
        </Animation>
      </div>
    );
  }

  renderPaymentMethodName() {
    const { filters, onChangeFilter } = this.props;

    return (
      <div className='quick-filters__block quick-filters__block_field'>
        <div className='quick-filters__block-title'>Payment method name</div>
        <div className='quick-filters__block-content'>
          <Input
            id={filtersKeys.iconPaymentMethodName}
            placeholder='Enter value'
            value={filters[filtersKeys.iconPaymentMethodName]}
            onChange={({ target }) =>
              onChangeFilter(filtersKeys.iconPaymentMethodName, target.value)
            }
          />
        </div>
      </div>
    );
  }

  renderPaymentMethods = () => {
    const { onChangeFilter, paymentMethods, filters } = this.props;

    return (
      <div className='quick-filters__block quick-filters__block_field'>
        <Animation>
          <div className='quick-filters__block-title'>Payment method id</div>
          <div className='quick-filters__block-content'>
            <CustomSelect
              options={paymentMethods}
              value={this.getPaymentMethodIdValue(
                filters[filtersKeys.iconPaymentMethodId]
              )}
              onChange={(v) =>
                onChangeFilter(filtersKeys.iconPaymentMethodId, v?.value || '')
              }
              deselect={true}
              isSearchable={true}
              customClass='ui-select_large quick-filters__select'
            />
          </div>
        </Animation>
      </div>
    );
  };

  getPaymentMethodIdValue = (value) => {
    const { paymentMethods } = this.props;
    return paymentMethods.find((item) => item.value === value) || null;
  };
}

export default addTranslation(QuickFilters);
