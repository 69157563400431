import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Input from 'components/ui/input';

interface Props extends IntlProps {
  id: string;
  value: string;
  onChange: (filterId: string, value: any) => void;
  error?: string;
  isRow?: boolean;
  placeholder?: string;
  label?: string;
}

class InputText extends PureComponent<Props> {
  render() {
    const {
      id,
      value,
      error,
      isRow,
      onChange,
      label,
      placeholder,
      getTranslate,
    } = this.props;

    return (
      <div className={`filters-text filters-text_${id}`}>
        <Input
          id={id}
          label={label || getTranslate(`filters.fields.${id}.label`)}
          placeholder={getTranslate(
            placeholder || `filters.fields.${id}.placeholder`
          )}
          value={value}
          error={error}
          isRow={isRow}
          onChange={(event) => onChange(id, event.target.value)}
          modern
        />
      </div>
    );
  }
}

export default addTranslation(InputText);
