import showNotification from 'components/ui/notification/showNotification';
import Repository from './Repository';
import getLocalizedText from './getLocalizedText';
import permissionReasons from 'constants/permissionReasons';

const isNotAvailableForSupport = (name: string): boolean => {
  const store = Repository.get('store');
  const { permissions } = store.getState();

  const permission = permissions[name];
  if (!permission) return false;

  if (
    permission.status === 'disabled' &&
    permission.reason === permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
  ) {
    showNotification({
      status: 'error',
      content: getLocalizedText('common.notAvaliableAction.sysmsg'),
    });
    return true;
  }

  return false;
};

export default isNotAvailableForSupport;
