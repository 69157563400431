import React, { PureComponent } from 'react';
import Input from 'components/ui/input';
import Icon from 'components/ui/icon';
import InputProps from 'components/ui/input/InputProps';
import './inputPassword.scss';

interface State {
  type: string;
}

class InputPassword extends PureComponent<InputProps, State> {
  static defaultProps = {
    type: 'password',
    customClass: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      type: props.type,
    };
  }

  render() {
    const { type } = this.state;
    return (
      <Input
        {...this.props}
        customClass={`ui-input-password ${this.props.customClass}`}
        type={type}
        suffix={
          <Icon
            className='ui-input-password__toggle'
            onClick={() =>
              this.changeType(type === 'password' ? 'text' : 'password')
            }
            name={type === 'password' ? 'im-Eye-close' : 'im-Eye-open'}
          />
        }
      />
    );
  }

  changeType = (type) => {
    this.setState({ type });
  };
}

export default InputPassword;
