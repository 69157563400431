import BaseMessageHandler from '../BaseMessageHandler';
import { setArticles } from 'actions/help';

export default class Article_Menu extends BaseMessageHandler {
  default = () => {
    const {
      dispatch,
      message: {
        payload: { items },
      },
    } = this;
    items && dispatch(setArticles(items));
  };
}
