import React, { Component } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { debounce } from 'lodash';
import classNames from 'classnames';

import Button from 'components/ui/button';
import CustomScrollbar from 'components/ui/customScrollbar';
import { SupportMessages } from 'types/Api';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import './supportChat.scss';

interface Props extends IntlProps {
  isLoading: boolean;
  isLoadingMore: boolean;
  isSending: boolean;
  loadMore: () => void;
  messages: SupportMessages;
  onSendMessage: (message: string) => void;
  raiseLimit: () => void;
}

interface State {
  inputMessage: string;
  isScrollable: boolean;
}

class SupportChat extends Component<Props, State> {
  listRef;
  inputRef;
  unreadMessages;

  constructor(props) {
    super(props);

    this.state = {
      inputMessage: '',
      isScrollable: false,
    };
  }

  componentDidMount() {
    this.checkScrollable();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (
      (prevProps.isSending && !this.props.isSending) ||
      (prevProps.isLoading && !this.props.isLoading) ||
      prevProps.messages?.list.length !== this.props.messages?.list.length
    ) {
      this.checkScrollable();
    }
  }

  checkScrollable = () => {
    setTimeout(() => {
      this.setState({ isScrollable: this.isScrollable }, () => {
        this.listRef?.scrollToBottom();
      });
    }, 0); //we need to render it first
  };

  render() {
    const { isLoading, isLoadingMore, messages, isSending, getTranslate } =
      this.props;
    const { isScrollable } = this.state;
    const isLimitReached = messages && !messages.limits.message;
    const _isLoading = isLoading || isLoadingMore;

    return (
      <div className='chat-tab'>
        <div
          className={classNames('chat', {
            chat_scrollable: isScrollable,
          })}>
          <CustomScrollbar
            noScrollY={!isScrollable}
            getRef={(ref) => {
              this.listRef = ref;
            }}
            onScroll={this.handleScroll}>
            {messages.list.map(this.renderChatMessage)}
          </CustomScrollbar>
        </div>
        <div className='chat__controls'>
          <textarea
            ref={(ref) => {
              this.inputRef = ref;
            }}
            disabled={_isLoading || isLimitReached}
            className={classNames('chat__input', {
              'chat__input_no-scroll':
                this.inputRef?.scrollHeight <= this.inputRef?.clientHeight,
            })}
            placeholder={
              isLimitReached
                ? ''
                : getTranslate('chatBot.typeQuestion.placeholder')
            }
            value={isLimitReached ? '' : this.state.inputMessage}
            onKeyPress={this.handleKeyPress}
            onChange={this.onInputMessageChange}
          />

          {isLimitReached && (
            <div className='chat__controls_error'>
              {getTranslate('chatBot.exceededLimit.text').replace(
                '{time}.',
                ''
              )}
              {` `}
              <Countdown
                autoStart
                date={messages.limits.timeoutAtTs * 1000}
                onComplete={this.onCompleteTimer}
                renderer={this.renderTimer}
              />
              .
            </div>
          )}
          <Button
            loading={isSending}
            disabled={_isLoading || isLimitReached || !this.state.inputMessage}
            status='light'
            id='sendMessage'
            text='Send'
            onClick={this.onSend}
            customClass='ui-button_size-small'
          />
        </div>
      </div>
    );
  }

  get isScrollable() {
    return (
      this.listRef?.scrollValues.contentScrollHeight &&
      this.listRef?.scrollValues.clientHeight &&
      this.listRef?.scrollValues.contentScrollHeight >
        this.listRef?.scrollValues.clientHeight
    );
  }

  onCompleteTimer = () => {
    const { raiseLimit } = this.props;
    raiseLimit();
  };

  handleScroll = debounce(({ scrollTop, scrollHeight }) => {
    const { loadMore } = this.props;

    if (scrollHeight * 0.2 > scrollTop) {
      loadMore();
    }
  }, 50);

  renderChatMessage = (message) => {
    const { getTranslate } = this.props;
    const { time, date, year } = message.displayDate;

    return (
      <div key={message.id}>
        {message.firstOfDate && (
          <div className='chat__date-delimiter'>
            {getTranslate(date)}
            {message.isAnotherYear &&
            date !== 'dates.today' &&
            date !== 'dates.yesterday'
              ? `, ${year}`
              : ''}
          </div>
        )}
        <div
          key={`chat-message-${message.id}`}
          className={classNames('chat-message', {
            'chat-message_response': message.from,
          })}>
          <div className='chat-message__title'>
            <div className='chat-message__from'>
              {getTranslate(
                message.from ? 'HelpDesk.label' : 'chatBot.you.label'
              )}
            </div>
            {message.createdAt && (
              <div className='chat-message__message-date'>{time}</div>
            )}
          </div>
          <div className='chat-message__text'>{message.text}</div>
        </div>
      </div>
    );
  };

  renderTimer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return null;
    }

    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  onSend = () => {
    const { onSendMessage } = this.props;
    const inputMessage = this.state.inputMessage.trim();
    if (inputMessage.length) {
      onSendMessage(inputMessage);
    }
    this.setState({ inputMessage: '' });
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.onSend();
    }
  };

  onInputMessageChange = (e) => {
    this.setState({ inputMessage: e.target.value });
  };
}

export default addTranslation(SupportChat);
