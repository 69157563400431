import React, { PropsWithChildren, PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import classNames from 'classnames';
import Icon from 'components/ui/icon';

interface Props extends IntlProps {
  title: string;
  data?: any;
  isHeader?: boolean;
  columns?: any;
  showAllChildren?: boolean;
}

interface State {
  showChildren: boolean;
}

class StatementBlock extends PureComponent<PropsWithChildren<Props>, State> {
  static defaultProps = {
    showAllChildren: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showChildren: props.showAllChildren,
    };
  }

  render() {
    const { title, data, isHeader, columns, getTranslate, children } =
      this.props;
    const { showChildren } = this.state;
    const isOpened = children && showChildren;

    return (
      <div className='statement-totals__table'>
        <div
          className={classNames('statement-totals__balance-info', {
            'statement-totals__balance-info_has-children': children,
            'statement-totals__balance-info_show-children': isOpened,
          })}
          onClick={this.toggleRow}>
          <div className='statement-totals__row'>
            <div className='statement-totals__col statement-totals__col_title'>
              {getTranslate(title)}
              {children && (
                <div className='statement-totals__arrow'>
                  <Icon
                    size={10}
                    name='im-Arrow-down'
                    className='statement-totals__arrow-icon'
                  />
                </div>
              )}
            </div>
            {columns.map((item) => (
              <div className='statement-totals__col' key={`column-${item}`}>
                <div className='statement-totals__col-inner'>
                  {isHeader && (
                    <div className='statement-totals__col-title'>{item}</div>
                  )}
                  <div>{data[item]}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {isOpened && <div className='statement-totals__main'>{children}</div>}
      </div>
    );
  }

  toggleRow = (event) => {
    const { children } = this.props;
    const { showChildren } = this.state;

    if (children) {
      event.stopPropagation();
      this.setState({
        showChildren: !showChildren,
      });
    }
  };
}

export default addTranslation(StatementBlock);
