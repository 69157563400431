import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

const initialState = {
  isOpened: false,
  callback: () => null,
  onClose: () => null,
  content: null,
};

const modal = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.OPEN_MODAL:
      return {
        isOpened: true,
        modalId: payload.modalId,
        onClose: payload.onClose,
        callback: payload.callback,
        content: payload.content,
        theme: payload.theme,
        customClassName: payload.customClassName,
        onClickAwayClose: payload.onClickAwayClose,
        needCloseButton: payload.needCloseButton,
        tooltipId: payload.tooltipId,
        invertButtons: payload.invertButtons,
      };
    case ACTIONS.EDIT_CONTENT:
      return {
        ...state,
        content: { ...(state.content || {}), ...payload.content },
      };
    case ACTIONS.EDIT_OPTIONS:
      return {
        ...state,
        ...payload,
      };
    case ACTIONS.CLOSE_MODAL:
      return {
        ...state,
        isOpened: false,
      };
    case ACTIONS.RESET_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default modal;
