import React from 'react';
import { connect } from 'react-redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Loader from 'components/ui/loader';
import MafContainer from 'components/maf';
import MafForm from 'components/maf/components/form';
import Button from 'components/ui/button';
import { Maf } from 'components/maf/MafTypes';
import { MafContainerProps } from 'components/maf/MafProps';
import './charge-submit-form.scss';

interface OwnProps {
  isLoading: boolean;
  mafData: any;
  onDisputeCommit: (data: Maf) => void;
  onCancelDispute: () => void;
  mafId: string;
  loadingText?: string;
}

interface ConnectedProps {
  email: string;
}

type Props = OwnProps & ConnectedProps & IntlProps;

const ChargeSubmitForm: React.VFC<Props> = ({
  onDisputeCommit,
  onCancelDispute,
  isLoading,
  email,
  mafId,
  mafData,
  loadingText,
  getTranslate,
}) => (
  <div className='charge-submit-form card'>
    <div className='card__header charge-submit-form__title'>
      {getTranslate('chargebackCard.submitEvidence.title')}
    </div>
    {isLoading && <Loader text={getTranslate(loadingText)} />}
    <div className='charge-submit-form__note'>
      {getTranslate('chargebackCard.submitEvidence.contact.text', {
        node: true,
        email: (
          <a className='ui-link' target='blanc' href={`mailto:${email}`}>
            {email}
          </a>
        ),
      })}
    </div>
    {mafData && (
      <MafContainer
        externalMaf={mafData}
        mafId={mafId}
        onSendData={onDisputeCommit}>
        {(props: MafContainerProps) => (
          <MafForm
            {...props}
            fileTypes='image/jpeg,image/png,.pdf,.zip,.rar,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'>
            <Button
              status='outline'
              size='normal'
              text={getTranslate('common.cancel.button')}
              onClick={() => {
                onCancelDispute();
              }}
              customClass='charge-submit-form__button'
            />
            <Button
              disabled={!props.canUpdate}
              status='primary'
              size='normal'
              text={getTranslate('chargebacks.chargebackCard.submit.button')}
              onClick={props.onCommit}
              customClass='charge-submit-form__button'
            />
          </MafForm>
        )}
      </MafContainer>
    )}
  </div>
);

const mapStateToProps = (state): ConnectedProps => ({
  email: state.settings.chargebacksEmail,
});

export default connect(mapStateToProps)(addTranslation(ChargeSubmitForm));
