import { createSelector } from 'reselect';
import PaymentPageUI from 'types/PaymentPageUI';

const singleInit: PaymentPageUI = {
  mainBlockScrollTop: 0,
  operationsScrollTop: 0,
  operationInfoScrollTop: 0,
  pageScrollTop: 0,
  paymentInfoScrollTop: 0,
  paymentSelectedTab: 0,
  selectedOperationId: '',
  isMaxHeight: false,
};

const tableInit: {
  scrollLeft: number;
  scrollTop: number;
} = {
  scrollLeft: 0,
  scrollTop: 0,
};

const getUI = (state, name, type) => {
  return { ui: state.ui[name], type };
};

const getUIByName = createSelector([getUI], ({ ui, type }) => {
  if (ui) {
    return ui;
  }

  if (type === 'single') {
    return singleInit;
  }
  return tableInit;
});

export default getUIByName;
