import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';

import { IntlProps } from 'decorators/addTranslation';
import I18nHelpers from 'i18n/I18nHelpers';
import Grid from 'components/grid';
import Icon from 'components/ui/icon';
import CopyToClipboard from 'components/ui/copyToClipboard';
import SlideDown from 'components/ui/slideDown';

import Card from 'pages/getStarted/onboarding/components/card';

interface Props extends IntlProps {
  projectId: string;
  secretKey: string;
  wl: string;
  isLoading: boolean;
  width: 4 | 5;
}

const CredentialsCard: React.VFC<Props> = (props) => {
  const [showSecretKey, setShowSecretKey] = useState(false);
  const toggleSecretKey = useCallback(() => {
    setShowSecretKey((old) => !old);
  }, []);

  const { getTranslate, projectId, secretKey, isLoading, width, wl } = props;

  const wmRaw = getTranslate('getStarted.welcome.label');
  const WelcomeMessage = useMemo(() => {
    const welcomeMessage = I18nHelpers.parseString(wmRaw);

    return (
      <h1>
        <b>{welcomeMessage.shift()}</b> {welcomeMessage} {wl.toUpperCase()}
      </h1>
    );
  }, [wmRaw, wl]);

  return (
    <Card
      size='lg'
      className={classNames('credentials-card', `grid-column-${width}`)}>
      <div className='credentials-card__title'>
        <Grid direction='column'>
          {WelcomeMessage}
          <p>{getTranslate('getStarted.welcome.text')}</p>
        </Grid>
      </div>
      <div className='credentials-card__content'>
        <Grid spacing='xxs' className='credentials-card__item'>
          <div>{getTranslate('getStarted.projectID.label')}</div>
          {isLoading ? (
            <Icon
              size={18}
              name='im-Ellipse'
              className='ui-select__loader rotating'
            />
          ) : (
            <div>{projectId}</div>
          )}
          <CopyToClipboard text={projectId} />
        </Grid>
        <Grid spacing='xxs' className='credentials-card__item'>
          <div>{getTranslate('getStarted.secretKey.label')}</div>
          {isLoading ? (
            <div>
              <Icon
                size={18}
                name='im-Ellipse'
                className='ui-select__loader rotating'
              />
            </div>
          ) : (
            <div
              className={classNames('credentials-card__secret-key', {
                'credentials-card__secret-key_closed': !showSecretKey,
              })}>
              {!showSecretKey && secretKey}
              <SlideDown isOpen={showSecretKey}>
                <div className='credentials-card__secret-key'>{secretKey}</div>
              </SlideDown>
            </div>
          )}
          <Grid spacing='xxs' className='credentials-card__secret-key-controls'>
            <Icon
              size={14}
              className='credentials-card__expand-button'
              name={showSecretKey ? 'im-Close' : 'im-Arrow-down'}
              onClick={toggleSecretKey}
            />
            <CopyToClipboard text={secretKey} />
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default CredentialsCard;
