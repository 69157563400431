import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateField } from 'actions/templateBuilder';
import DateHelpers from 'helpers/Date';
import { getLatestDate } from '../../getLatestDate';
import BuilderSchedule from './BuilderSchedule';
import { AnyObject } from 'types/Common';
import { Dictionary } from 'types/FilterValue';
import reportTypes from '../../reportTypes';
import { StoreProps } from 'store';

interface OwnProps {
  onManualChange?: (isManualInput: boolean) => void;
}

interface ConnectedProps {
  timezone: string;
  fields: AnyObject;
  validationErrors: AnyObject;
  reportTemplateLimits: Dictionary;
}

type Props = OwnProps & ConnectedProps & StoreProps;

interface State {
  lastChanged: string;
}

class BuilderScheduleContainer extends Component<Props, State> {
  state = { lastChanged: '' };

  componentDidMount() {
    if (this.props.fields.reportType === reportTypes.chargebacks) {
      this.removeTime();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { timezone, fields, dispatch } = this.props;
    const { lastChanged } = this.state;

    if (prevProps.fields.reportType !== fields.reportType) {
      this.setDateTime();
    }

    if (timezone === prevProps.timezone) {
      return;
    }
    let value = fields[lastChanged];
    switch (lastChanged) {
      case 'singlePeriodRange': {
        value = fields.singlePeriodRange.map((date) =>
          DateHelpers.convertTimeZone(date, prevProps.timezone, timezone)
        );
        dispatch(updateField('singlePeriodRange', value));
        break;
      }
      case 'singlePeriod': {
        this.onChange(lastChanged, value);
        break;
      }
      case 'regularPeriod':
      case 'regularStartAt': {
        let regValue = fields.regularStartAt;
        if (!regValue) return;
        regValue = DateHelpers.convertTimeZone(
          value,
          prevProps.timezone,
          timezone
        );
        dispatch(updateField('regularStartAt', regValue));
        break;
      }
    }
  }

  render() {
    const { fields, reportTemplateLimits, validationErrors } = this.props;
    return (
      <BuilderSchedule
        fields={fields}
        validationErrors={validationErrors}
        onChangeField={this.onChange}
        onFocusDateField={this.onFocusDateField}
        reportTemplateLimits={reportTemplateLimits}
      />
    );
  }

  onChange = (key: string, value: any, isManualInput = false): void => {
    const { dispatch, timezone, fields, onManualChange } = this.props;
    switch (key) {
      case 'singlePeriod': {
        const dateFormat =
          fields.reportType === reportTypes.chargebacks ? 'date' : 'datetime';
        const period = DateHelpers.getPeriod(value, timezone, dateFormat);
        dispatch(
          updateField('singlePeriodRange', {
            dateFrom: period[0],
            dateTo: period[1],
          })
        );
        break;
      }
      case 'singlePeriodRange':
        dispatch(updateField('singlePeriod', ''));
        break;
    }
    this.setState({ lastChanged: key });
    dispatch(updateField(key, value));
    onManualChange && onManualChange(isManualInput);
  };

  onFocusDateField = () => {
    const {
      fields: { reportScheduleDateType, regularStartAt },
      dispatch,
    } = this.props;

    if (reportScheduleDateType === 'regular' && !regularStartAt) {
      return dispatch(updateField('regularStartAt', getLatestDate()));
    }
  };

  removeTime = () => {
    const { fields, dispatch } = this.props;

    if (fields.regularStartAt) {
      const date = DateHelpers.createDate(fields.regularStartAt, 'date');
      dispatch(
        updateField('regularStartAt', DateHelpers.getFormat(date, 'date'))
      );
    }
    if (fields.singlePeriodRange.dateFrom || fields.singlePeriodRange.dateTo) {
      dispatch(
        updateField('singlePeriodRange', {
          dateFrom: fields.singlePeriodRange.dateFrom.slice(0, 11),
          dateTo: fields.singlePeriodRange.dateTo.slice(0, 11),
        })
      );
    }
  };

  setDateTime = () => {
    const { fields, reportTemplateLimits, dispatch } = this.props;

    const dateFormat =
      fields.reportType === reportTypes.chargebacks ? 'date' : 'datetime';

    if (fields.regularStartAt) {
      const date = DateHelpers.createDate(fields.regularStartAt, dateFormat);
      dispatch(
        updateField('regularStartAt', DateHelpers.getFormat(date, dateFormat))
      );
    }
    const minDateConfig =
      reportTemplateLimits.list[fields.reportType].periodType.single.minDate;

    if (fields.singlePeriodRange.dateFrom || fields.singlePeriodRange.dateTo) {
      const minDate = DateHelpers.createDate(
        minDateConfig || DateHelpers.getDefaultMinDate(),
        dateFormat
      );

      const minDateSetting: string = DateHelpers.getFormat(
        DateHelpers.getStartDay(minDate),
        dateFormat
      );
      const date = DateHelpers.createDate(
        fields.singlePeriodRange.dateFrom,
        dateFormat
      );

      let dateFrom: any = DateHelpers.getMaxDate(
        DateHelpers.getFormat(date, dateFormat),
        minDateSetting,
        dateFormat
      );
      dateFrom = DateHelpers.createDate(dateFrom, dateFormat);

      let dateTo = DateHelpers.createDate(
        fields.singlePeriodRange.dateTo,
        dateFormat
      );

      if (dateFormat === 'datetime') {
        if (fields.singlePeriodRange.dateFrom.length < 19) {
          dateFrom = DateHelpers.getStartDay(dateFrom);
        }
        if (
          fields.singlePeriodRange.dateTo.length < 19 //длина строки с датой и временем - если времени нет - выставляем конец дня
        ) {
          dateTo = DateHelpers.createDate(
            DateHelpers.getMaxDate(dateFrom, dateTo, dateFormat),
            dateFormat
          );

          dateTo = DateHelpers.getEndDay(dateTo);
        }
      }
      dispatch(
        updateField('singlePeriodRange', {
          dateFrom: DateHelpers.getFormat(dateFrom, dateFormat),
          dateTo: DateHelpers.getFormat(dateTo, dateFormat),
        })
      );
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  timezone: state.user.timezone,
  fields: state.templateBuilder.fields,
  validationErrors: state.templateBuilder.validationErrors,
  reportTemplateLimits: state.filtersValues?.reportTemplateLimits,
});

export default connect(mapStateToProps)(BuilderScheduleContainer);
