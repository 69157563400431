import React, { Component, Fragment } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';

import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import { Column, Row } from 'components/ui/table';
import DataListContainer from 'components/dataListContainer/DataListContainer';
import TopPanelContainer from 'pages/team/components/topPanel/TopPanelContainer';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import Switch from 'components/ui/switch';
import { AnyObject } from 'types/Common';
import { minUsersTableColumnsWidth } from './constants';
import './admin.scss';

interface Props extends IntlProps {
  tableName: string;
  canRenderTable: boolean;
  isLoading: boolean;
  configuration: AnyObject;
  apiRequest: (data?: any) => Promise<any>;
  onDeleteUser: (id: number) => void;
  onToggleActivate: (data: AnyObject) => void;
  onEditUser: (id: number) => void;
}

const quickFilter = 'users';

@wrapAppWithCssClass('layout-app_desktop-width')
class AdminPage extends Component<Props> {
  render() {
    return (
      <PageTemplate.Main topPadding customClass='admin-page'>
        <PageTemplate.Container>{this.renderContent()}</PageTemplate.Container>
      </PageTemplate.Main>
    );
  }

  renderContent() {
    const { tableName, isLoading, apiRequest } = this.props;
    const { canRenderTable } = this.props;

    return (
      <Fragment>
        <TopPanelContainer
          isAdmin
          tableName={tableName}
          quickFilter={quickFilter}
        />
        {canRenderTable && (
          <DataListContainer
            minColumnsWidth={minUsersTableColumnsWidth}
            apiRequest={apiRequest}
            tableName={tableName}
            rowRender={this.rowRender}
            quickFilter={quickFilter}
          />
        )}
        {isLoading && <Loader />}
      </Fragment>
    );
  }

  rowRender = (data) => {
    const { configuration } = this.props;

    return (
      <Row key={data.id}>
        {configuration.map((config) => {
          const { id } = config;
          let content = data[id];

          if (id === 'edit') {
            content = (
              <>
                <Button
                  icon='im-Edit1'
                  status='transparent'
                  size='inline'
                  customClass='admin-page__edit-btn ui-button_no-border'
                  onClick={() => this.props.onEditUser(data.id)}
                />
                <Button
                  icon='im-Basket'
                  status='transparent'
                  size='inline'
                  customClass='admin-page__edit-btn ui-button_no-border'
                  onClick={() => this.props.onDeleteUser(data.id)}
                />
              </>
            );
          } else if (id === 'activate') {
            content = (
              <Switch
                id={'switch-status'}
                checked={data['status'] === 'Active'}
                onChange={() => this.props.onToggleActivate(data)}
                customClass='admin-page__switch'
              />
            );
          } else if (id === 'merchants' || id === 'projects') {
            content = content.reduce((result, item) => {
              result.push(item.name);
              return result;
            }, []);
          }
          return (
            (content !== undefined && (
              <Column
                columnWidths={data.columnWidths}
                key={id}
                id={id}
                content={
                  content ?? <span className='ui-table__column-empty'>—</span>
                }
                params={{
                  ...config,
                  valueId: config.id,
                  valueType: config.valueType,
                }}
                customClass={`ui-table__column_${id}`}
              />
            )) ||
            null
          );
        })}
      </Row>
    );
  };
}

export default addTranslation(AdminPage);
