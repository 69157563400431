import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Tooltip from 'react-tooltip';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import apiDictionaries from 'api/dictionaries';
import apiPayouts from 'api/payouts';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import { StoreProps } from 'store';

import Form from 'components/ui/form';
import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import SubmittedForm from 'components/formFields/components/submittedForm';
import showNotification from 'components/ui/notification/showNotification';

import SingleRequest from './SingleRequest';
import AdditionalFields from './components/additionalFields';
import ClarificationFields from './components/clarificationFields';
import ClarificationLoader from './components/clarificationLoader';
import Link from 'components/ui/link';
import { addIsMounted, IsMounted } from 'decorators/addIsMounted';
import { addListeners, IListeners } from 'decorators/addListeners';
import Utils from 'helpers/Utils';
import getNameWithId from 'helpers/getNameWithId';
import { getCustomSelectCurrencyItems } from 'helpers/currencyHelper';
import MetricService from 'helpers/metricService/MetricService';
import { ActionKeys } from 'helpers/metricService/metricTypes';
import checkFilters from 'helpers/checkFilters';

import RpcTypes from 'constants/rpcTypes';
import urlsMap from 'constants/urlsMap';
import RequestStates from '../../requestStates';
import SelectItemType from 'types/SelectItem';
import { AnyObject } from 'types/Common';
import { getMethodItem, getMethods } from 'creators/paymentMethods';
import { scrollToError } from 'components/formFields/helpers';
import { generateId } from 'components/formFields/formStateFactory';
import { WithRouterProps } from 'decorators/withRouter';
import { BaseFields } from '../../../../types/SinglePayout';

const POLLING_INTERVAL = 10000;

interface ConnectedProps {
  isPaymentIdGeneratorEnabled: boolean;
  settings: AnyObject;
  filtersValues: AnyObject;
  currency: SelectItemType[];
  enabledProjects: AnyObject[];
}

type Props = ConnectedProps &
  StoreProps &
  IntlProps &
  IsMounted &
  WithRouterProps;

interface State {
  baseFields: {
    projectId: any;
    amount: string;
    currency: string | null;
    paymentMethodUrlAlias: any;
    extraParam: string;
  },
  dictionaries: {
    projects: SelectItemType[];
    methods: SelectItemType[];
  };
  request: AnyObject | null;
  additionalFields: any[] | null;
  clarificationFields: any[] | null;
  validationErrors: AnyObject;
  isAdditionFieldsFetching: boolean;
  isDataSending: boolean;
  isRejecting: boolean;
  isMethodsFetching: boolean;
  requiredFieldsError: boolean;
}

@addListeners([
  RpcTypes.PaymentOperation_Payout,
  RpcTypes.PaymentOperation_StatusUpdate,
  RpcTypes.PaymentOperation_Status,
  RpcTypes.Confirm_Psd2,
  RpcTypes.Confirm_Reject,
])
class SingleRequestContainer
  extends PureComponent<Props, State>
  implements IListeners
{
  private requestId;
  private isPreFilled: boolean;
  private readonly isPreFilledClarification: boolean;
  private pollingTimerId;

  constructor(props: Props) {
    super(props);
    const { state } = props.location;
    this.isPreFilled = Boolean(state && state.preFilled && state.data);
    this.isPreFilledClarification = Boolean(
      state && state.preFilledClarification && state.data
    );

    this.state = {
      baseFields: this.getBaseFields(),
      dictionaries: {
        projects: getCustomSelectItems({
          list: props.enabledProjects,
          valueKey: 'projectId',
          getLabel: (project) => getNameWithId(project.name, project.projectId),
        }),
        methods: [],
      },
      request: null,
      additionalFields: null,
      clarificationFields: null,
      validationErrors: {},
      isAdditionFieldsFetching: false,
      isDataSending: false,
      isRejecting: false,
      isMethodsFetching: false,
      requiredFieldsError: false,
    };
  }

  async componentDidMount() {
    await checkFilters('payouts');

    if (this.isPreFilled) {
      this.initPreFilled();
    }
  }

  componentWillUnmount(): void {
    this.stopPolling();
  }

  getAdditionalFieldValues(fields: BaseFields, exclude: Array<string> = ['extraParam']) {
    const filteredFields = Object.fromEntries(
      Object.entries(fields).filter(
        ([key, val]) => !exclude.includes(key)
      )
    );

    return Object.values(filteredFields);
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ) {
    const { projectId, paymentMethodUrlAlias, currency } =
      this.state.baseFields;

    if (projectId && !isEqual(prevState.baseFields.projectId, projectId)) {
      this.createPaymentMethods(await this.fetchPaymentMethods());
    }

    const isFilled = this.getAdditionalFieldValues(this.state.baseFields).every(
      (field) => !!field
    );
    const prevFilled = this.getAdditionalFieldValues(prevState.baseFields).every(
      (field) => !!field
    );

    const isPrefilledAmountUpdated =
      (this.isPreFilled || this.isPreFilledClarification) &&
      prevState.baseFields.amount !== this.state.baseFields.amount;
    if (
      (!prevFilled && isFilled) ||
      (isFilled &&
        (prevState.baseFields.paymentMethodUrlAlias !== paymentMethodUrlAlias ||
          prevState.baseFields.currency !== currency)) ||
      isPrefilledAmountUpdated
    ) {
      this.createFields('additionalFields', await this.fetchFields());
    }

    if (
      isEmpty(prevState.validationErrors) &&
      !isEmpty(this.state.validationErrors)
    ) {
      scrollToError(this.state.validationErrors);
    }

    Tooltip.rebuild();
  }

  render() {
    const { getTranslate, currency } = this.props;
    const {
      baseFields,
      dictionaries,
      additionalFields,
      clarificationFields,
      request,
      validationErrors,
      isAdditionFieldsFetching,
      isDataSending,
      isRejecting,
      isMethodsFetching,
      requiredFieldsError,
    } = this.state;

    const isClarificationLoading =
      !!request &&
      (request.state === undefined || request.state === 'external processing');
    const hasResult = this.hasResult();
    return (
      <>
        <Form
          customClass={classNames('ui-form-fields__form', {
            'ui-form-fields__no-additional':
              (!additionalFields && !clarificationFields) ||
              requiredFieldsError ||
              isAdditionFieldsFetching,
          })}
          onSubmit={this.submit}>
          <>
            {hasResult ? (
              this.renderResult()
            ) : (
              <SingleRequest
                baseFields={baseFields}
                dictionaries={{ ...dictionaries, currencies: currency }}
                onChange={this.changeBaseField}
                validationErrors={validationErrors}
                isClarification={this.isClarification()}
                isProjectFetch={isMethodsFetching}
                isPreFilled={this.isPreFilled}>
                {isAdditionFieldsFetching ? <Loader /> : this.renderContent()}
              </SingleRequest>
            )}
          </>
          {!hasResult && (
            <div className='ui-form-fields__container ui-form-fields__container_footer utils-flex'>
              <Link
                url={`/${urlsMap.payouts}`}
                className='ui-form-fields__back-btn ui-button ui-submitted-form__button ui-button_status-outline ui-button_size-normal'>
                {getTranslate('payouts.request.backBtn')}
              </Link>

              <div className='ui-form-fields__buttons utils-flex'>
                {this.isClarification() && (
                  <Button
                    type='button'
                    status='outline'
                    text='Reject Payout'
                    customClass='create-single-request__reject-button'
                    onClick={() => this.rejectPayout()}
                    loading={isRejecting}
                    disabled={isDataSending}
                    id='single-payout-button-reject'
                  />
                )}
                <Button
                  type='submit'
                  status='primary'
                  text={this.getButtonText()}
                  id='single-payout-button-submit'
                  loading={
                    (isDataSending && !isRejecting) || isClarificationLoading
                  }
                  disabled={!this.canSendForm() && !hasResult}
                />
              </div>
            </div>
          )}
        </Form>
      </>
    );
  }

  renderContent = () => {
    const { getTranslate, settings } = this.props;
    const {
      additionalFields,
      clarificationFields,
      request,
      validationErrors,
      isDataSending,
      requiredFieldsError,
    } = this.state;
    const isClarificationLoading =
      !!request &&
      (request.state === undefined || request.state === 'external processing');
    return isClarificationLoading ? (
      <ClarificationLoader state={request && request.state} />
    ) : (
      <>
        {!additionalFields && !clarificationFields && (
          <div className='ui-form-fields__empty'>
            {this.props.getTranslate('manual.addPayout.noAdditionalFields')}
          </div>
        )}
        <AdditionalFields
          fields={additionalFields}
          validationErrors={validationErrors}
          onChangeField={this.changeField}
        />
        <ClarificationFields
          fields={clarificationFields}
          validationErrors={validationErrors}
          onChangeField={this.changeField}
        />
        {requiredFieldsError && (
          <div className='ui-form-fields__empty'>
            <a href={`mailto:${settings.supportEmail}`} className='utils-link'>
              {getTranslate('payouts.request.single.requiredFieldsError.text')}
            </a>
          </div>
        )}
        {isDataSending && <Loader />}
      </>
    );
  };

  renderResult() {
    const { request } = this.state;
    let status: string = 'success';
    let message: string = 'payouts.request.single.success.text';

    if (this.isReject()) {
      status = 'reject';
      message = 'payouts.request.single.reject.text';
    } else if (this.isFailed()) {
      status = 'error';
      message = 'payouts.request.single.failed.text';
    } else if (this.isDecline()) {
      status = 'decline';
      message = 'payouts.request.single.timeout.text';
    } else if (this.isPartiallyPaid()) {
      status = 'partiallyPaid';
      message = 'payouts.request.single.partiallyPaid.text';
    }
    return (
      <SubmittedForm
        id='payout'
        status={status}
        text={message}
        errors={request?.errors}
        onSecondaryButtonLink={`/${urlsMap.payouts}`}
        secondaryButtonText='payouts.request.backBtn'
        primaryButtonText='payouts.request.newRequestBtn'
        onPrimaryButtonClick={this.resetForm}
      />
    );
  }

  initPreFilled() {
    const {
      history,
      location: { state },
    } = this.props;
    const {
      payoutPaymentMethods,
      payoutPaymentMethodRules,
      payout,
      payoutFormValues,
      clarificationRules,
      clarificationValues,
    }: any = state.data;
    const methodsList = Object.values(payoutPaymentMethods);

    this.createPaymentMethods(methodsList, () => {
      this.createFields(
        'additionalFields',
        payoutPaymentMethodRules,
        payoutFormValues || {},
        () => {
          if (clarificationRules) {
            this.createFields(
              'clarificationFields',
              clarificationRules,
              clarificationValues || {}
            );
          }
        }
      );
      history.replace({});
    });

    if (this.isPreFilledClarification && payout) {
      this.updateRequest(payout);
    }
  }

  async fetchPaymentMethods() {
    const { projectId } = this.state.baseFields;
    this.setState({ isMethodsFetching: true });

    try {
      return await apiDictionaries.getMethods(projectId.value);
    } catch ({ payload }) {
      this.setState({
        validationErrors: payload,
      });
    } finally {
      if (this.props.isMount()) {
        this.setState({ isMethodsFetching: false });
      }
    }
  }

  createPaymentMethods(methodsList, callback?: () => void) {
    const { dictionaries } = this.state;
    if (methodsList?.length) {
      this.setState(
        {
          dictionaries: {
            ...dictionaries,
            methods: getMethods(methodsList, 'urlAlias'),
          },
        },
        () => {
          callback && callback();
        }
      );
    }
  }

  async fetchFields() {
    const { baseFields } = this.state;
    this.setState({
      isAdditionFieldsFetching: true,
      requiredFieldsError: false,
    });

    try {
      const result = await apiDictionaries.getMethodsRules({
        ...baseFields,
        amount: this.getAmount(baseFields.amount),
        projectId: baseFields.projectId.value,
        paymentMethodUrlAlias: baseFields.paymentMethodUrlAlias.value,
      });
      const noRequiredFields = !Object.values(result).some(
        (field: any) => field.required
      );
      if (noRequiredFields) {
        this.setState({
          requiredFieldsError: true,
        });
        return {};
      }
      return result;
    } catch ({ payload }) {
      this.setState({
        requiredFieldsError: !payload?.validationErrors,
        validationErrors: payload?.validationErrors || payload,
      });
      return {};
    } finally {
      if (this.props.isMount()) {
        this.setState({ isAdditionFieldsFetching: false });
        if (this.isPreFilled || this.isPreFilledClarification) {
          this.initPreFilled();
        }
      }
    }
  }

  createFields(
    keyForState: string,
    fieldsList,
    fieldsValues = {},
    callback?: () => void
  ) {
    const { getTranslate } = this.props;
    const fieldsListResult: any = [];

    Object.keys(fieldsList).forEach((key: string) => {
      const field = fieldsList[key];
      fieldsListResult.push({
        ...field,
        id: key,
        label: field.title,
        placeholder: `${getTranslate('common.enter.placeholder')} ${
          field.title || 'value'
        }`,
        value:
          this.props.isPaymentIdGeneratorEnabled &&
          key === 'general.payment_id' &&
          !this.isPreFilledClarification
            ? generateId()
            : fieldsValues[key] || '',
      });
    });

    const newState = { ...this.state };
    this.setState(
      {
        ...newState,
        [keyForState]: fieldsListResult,
      },
      () => {
        callback && callback();
      }
    );
  }

  markAsDisabled(keyForState: string) {
    this.setState((state) => {
      return {
        ...state,
        [keyForState]: state[keyForState]?.map((field) => {
          return {
            ...field,
            disabled: true,
          };
        }),
      };
    });
  }

  changeBaseField = (field: string, value) => {
    const { baseFields, dictionaries } = this.state;

    if (value === baseFields[field]) return;

    if (field === 'projectId') {
      this.setState({
        baseFields: {
          ...baseFields,
          [field]: value,
          paymentMethodUrlAlias: null,
        },
        dictionaries: {
          ...dictionaries,
          methods: [],
        },
        validationErrors: {},
        additionalFields: null,
        clarificationFields: null,
        requiredFieldsError: false,
      });
    } else if (field === 'paymentMethodUrlAlias') {
      this.setState({
        baseFields: {
          ...baseFields,
          [field]: value,
        },
        validationErrors: {},
        additionalFields: null,
        clarificationFields: null,
      });
      this.isPreFilled = false;
    } else {
      this.setState({
        baseFields: {
          ...baseFields,
          [field]: value,
        },
      });
    }
  };

  changeField = (id: string, value: any, type: string) => {
    const validationErrors = { ...this.state.validationErrors };
    delete validationErrors[id];

    this.setState((state) => {
      return {
        ...state,
        validationErrors,
        [type]: state[type].map((data) => {
          if (data.id === id) {
            return {
              ...data,
              value,
            };
          }
          return data;
        }),
      };
    });
  };

  canSendForm = () => {
    const {
      baseFields: { projectId, amount, currency, paymentMethodUrlAlias },
      additionalFields,
      clarificationFields,
    } = this.state;

    if (!projectId || !amount || !currency || !paymentMethodUrlAlias)
      return false;

    if (!additionalFields) return null;

    const isValidAdditional = this.checkRequiredFields(additionalFields);
    if (!isValidAdditional) return false;

    if (clarificationFields) {
      const isValidClarification =
        this.checkRequiredFields(clarificationFields);
      if (!isValidClarification) return false;
    }

    return true;
  };

  checkRequiredFields = (fields) => {
    return fields.every((field) => {
      if (Utils.hasProp(field, 'required') && field.required === false) {
        return true;
      }
      return Boolean(field.value);
    });
  };

  getFieldsForSend = (fields) => {
    if (!fields) return {};
    const result = {};

    fields.forEach(({ id, value }) => {
      result[id] = value;
    });

    return result;
  };

  submit = async () => {
    await this.sendForm();
    if (this.state.validationErrors) {
      scrollToError(this.state.validationErrors);
    }
  };

  sendForm = () => {
    if (this.hasResult()) {
      return this.resetForm();
    }
    return this.isClarification()
      ? this.sendClarificationPayout()
      : this.sendData();
  };

  sendData = () => {
    const { getTranslate } = this.props;
    const { baseFields, additionalFields } = this.state;

    if (!this.canSendForm()) {
      return false;
    }

    this.setState({ isDataSending: true });

    const prepareData = {
      operation: {
        ...this.getFieldsForSend(additionalFields),
        method: baseFields.paymentMethodUrlAlias.value,
        'payment.amount': this.getAmount(baseFields.amount),
        'payment.currency': baseFields.currency,
        'payment.extra_param': baseFields.extraParam || '',
        'general.project_id': baseFields.projectId.value,
      },
    };

    // Custom handling
    // Replace all spaces from '1111 111111 111111 111'
    if (prepareData.operation['card.pan']) {
      prepareData.operation['card.pan'] = prepareData.operation[
        'card.pan'
      ].replace(/ /g, '');
    }

    const id = Utils.getHash();
    this.requestId = id;

    apiPayouts
      .sendPayoutData(id, { ...prepareData })
      .then(() => this.sendMetric('send'))
      .catch(({ payload }) => {
        if (payload.validationErrors) {
          this.setState({
            validationErrors: payload.validationErrors,
          });
        } else if (payload.gateError) {
          this.setState({
            validationErrors: payload,
            additionalFields: null,
          });
        }

        if (
          ['user_contact_phone_invalid', 'user_tfa_disabled'].includes(
            payload.errorCode
          )
        ) {
          showNotification({
            status: 'error',
            content: getTranslate(
              Object.values(payload.validationErrors)[0] as string
            ),
          });
        }
      })
      .finally(() => {
        this.setState({
          isDataSending: false,
        });
      });
  };

  async rejectPayout() {
    const { request } = this.state;
    this.setState({
      isDataSending: true,
      isRejecting: true,
    });
    try {
      await apiPayouts.rejectPayout(request?.payoutQueueId);
    } catch {}
  }

  async sendClarificationPayout() {
    const { request, clarificationFields } = this.state;

    if (!this.canSendForm()) {
      return false;
    }

    const prepareData = {
      payoutQueueId: request?.payoutQueueId,
      clarificationFieldsValues: this.getFieldsForSend(clarificationFields),
    };
    this.setState({ isDataSending: true });

    try {
      await apiPayouts.sendClarification(prepareData);
      this.startPolling(request?.payoutQueueId);
    } catch ({ payload }) {
      this.setState({
        validationErrors: payload?.validationErrors,
        isDataSending: false,
      });
    }
  }

  startPolling = (payoutQueueId) => {
    this.pollingTimerId = setInterval(() => {
      return apiPayouts.getPayoutStatus(payoutQueueId);
    }, POLLING_INTERVAL);
  };

  stopPolling = () => {
    clearInterval(this.pollingTimerId);
  };

  updateRequest = (data: any) => {
    const { request } = this.state;
    this.setState({
      request: {
        ...request,
        ...data,
      },
    });
  };

  hasResult = (): boolean => {
    const { request } = this.state;
    if (!request) return false;

    const { status } = request;
    return (
      status === RequestStates.STATE_ERROR ||
      this.isSuccess() ||
      this.isFailed() ||
      this.isReject() ||
      this.isDecline() ||
      this.isPartiallyPaid()
    );
  };

  isSuccess = (): boolean => {
    const { request } = this.state;
    if (!request) return false;

    return (
      request.state === RequestStates.STATE_SUCCESS_GATE ||
      request.state === RequestStates.STATE_SUCCESS_DWH
    );
  };

  isFailed = (): boolean => {
    const { request } = this.state;
    if (!request) return false;

    return request.state === RequestStates.STATE_ERROR;
  };

  isReject = (): boolean => {
    const { request } = this.state;
    if (!request) return false;

    return (
      request.state === RequestStates.STATE_DECLINE_BY_USER_GATE ||
      request.state === RequestStates.STATE_DECLINE_BY_USER_DWH
    );
  };

  isDecline = (): boolean => {
    const { request } = this.state;
    if (!request) return false;

    return (
      request.state === RequestStates.STATE_DECLINE_DWH ||
      request.state === RequestStates.STATE_DECLINE_GATE
    );
  };

  isClarification = (): boolean => {
    const { request } = this.state;
    if (!request) return false;

    return request.state === RequestStates.STATE_AWAITING_CLARIFICATION;
  };

  isPartiallyPaid = (): boolean => {
    const { request } = this.state;
    if (!request) return false;

    return (
      request.state === RequestStates.STATE_PARTIALLY_PAID_OUT_DWH ||
      request.state === RequestStates.STATE_PARTIALLY_PAID_OUT_GATE
    );
  };

  getAmount = (amount) => {
    return parseFloat(amount.toString().replace(/ /g, ''));
  };

  getButtonText = (): string => {
    const { getTranslate } = this.props;

    if (this.hasResult()) {
      return getTranslate('payouts.request.newRequestBtn');
    }

    return getTranslate(
      this.isClarification()
        ? 'common.proceed.button'
        : 'payouts.request.sendRequestBtn'
    );
  };

  getBaseFields = () => {
    return this.isPreFilled
      ? this.getPreFilledBaseFields()
      : this.getEmptyBaseFields();
  };

  getEmptyBaseFields = (): BaseFields => {
    return {
      projectId: null,
      amount: '',
      currency: null,
      paymentMethodUrlAlias: null,
      extraParam: '',
    };
  };

  getPreFilledBaseFields() {
    const {
      enabledProjects,
      location: { state },
    } = this.props;
    const { generalFormValues, payoutPaymentMethods }: any = state.data;
    const { projectId, paymentMethodId, amount, currency, extraParam } = generalFormValues;

    const foundMethod = Object.values(payoutPaymentMethods).find(
      (method: any) => +method.id === +paymentMethodId
    );
    const paymentMethodUrlAlias = foundMethod
      ? getMethodItem(foundMethod, 'urlAlias')
      : null;

    const currentProject = enabledProjects.find(
      (project) => project.projectId === projectId
    );
    let project: any = null;
    if (currentProject) {
      project = {
        value: currentProject.projectId,
        label: getNameWithId(currentProject.name, currentProject.projectId),
      };
    }

    return {
      amount,
      currency,
      paymentMethodUrlAlias,
      projectId: project,
      extraParam
    };
  }

  resetForm = () => {
    this.setState({
      baseFields: this.getEmptyBaseFields(),
      additionalFields: null,
      clarificationFields: null,
      request: null,
      validationErrors: {},
      isAdditionFieldsFetching: false,
      isDataSending: false,
      isRejecting: false,
    });
    this.isPreFilled = false;
  };

  sendMetric = (key) => {
    MetricService.send({
      action: 'click',
      actionKey: ActionKeys[`manualPayments.request.singlePayout.${key}`],
    });
  };

  onEvent = ({ name, data }) => {
    const { request } = this.state;
    const { status, id } = data.rpc;
    const { payload } = data;

    switch (name) {
      case RpcTypes.PaymentOperation_Payout:
        if (id === this.requestId && status === 'success') {
          this.setState({
            isDataSending: true,
          });
          if (payload.payoutQueueId) {
            this.startPolling(payload.payoutQueueId);
          }
          this.updateRequest(payload);
        }
        break;
      case RpcTypes.Confirm_Psd2:
        this.setState({
          isDataSending: false,
        });
        break;
      case RpcTypes.Confirm_Reject:
        if (
          payload.unblockedTypes.includes(
            RpcTypes.PaymentOperation_ClarificationReject
          )
        ) {
          this.setState({
            isRejecting: false,
            isDataSending: false,
          });
        }
        break;
      case RpcTypes.PaymentOperation_StatusUpdate:
      case RpcTypes.PaymentOperation_Status:
        if (
          request?.payoutQueueId === payload.payoutQueueId &&
          status === 'success' &&
          payload.state !== 'processing'
        ) {
          if (request?.state !== payload.state) {
            this.setState({
              isDataSending: false,
              isRejecting: false,
            });
          }
          this.updateRequest(payload);
          if (
            payload.state === RequestStates.STATE_AWAITING_CLARIFICATION &&
            payload.clarificationRules
          ) {
            this.createFields(
              'clarificationFields',
              payload.clarificationRules,
              {},
              () => this.markAsDisabled('additionalFields')
            );
          }
          this.stopPolling();
          this.setState({ isDataSending: false });
        }
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  isPaymentIdGeneratorEnabled: state.user.isPaymentIdGeneratorEnabled,
  settings: state.settings,
  filtersValues: state.filtersValues,
  currency: getCustomSelectCurrencyItems(state.filtersValues.currency?.list),
  enabledProjects: state.user.enabledProjects,
});

export default withRouter(
  connect(mapStateToProps)(addTranslation(addIsMounted(SingleRequestContainer)))
);
