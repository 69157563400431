import React, { Fragment, PureComponent } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import ModalTitle from 'components/modal/components/ModalTitle';
import ModalContent from 'components/modal/components/ModalContent';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import Form from 'components/ui/form';
import Loader from 'components/ui/loader';
import PaymentVerification from './details/paymentVerification';
import { SCENES, VERIFICATION_TYPES } from './constants';
import { BaseInterface, ContentInterface } from './types';
import './codeVerification.scss';

interface Props extends BaseInterface, IntlProps {
  content: ContentInterface;
  canSendCode: boolean;
  onChangeCode: (event) => void;
  onCompleteTimer: () => void;
  onSendCode: () => void;
  onResendCode: () => void;
  onCloseModal: () => void;
}

class CodeVerification extends PureComponent<Props> {
  render() {
    const { isLoading, getTranslate } = this.props;

    return (
      <div className='code-verification'>
        <ModalTitle>
          {getTranslate('payment.psd2.verificationCode.title')}
        </ModalTitle>
        <ModalContent>
          {this.renderContent()}
          {this.renderFooter()}
        </ModalContent>
        {isLoading && <Loader />}
      </div>
    );
  }

  renderContent() {
    const { scene } = this.props;

    switch (scene) {
      case SCENES.MAIN:
        return this.renderMain();
      case SCENES.ATTEMPTS_ERROR:
      case SCENES.TIMEOUT_ERROR:
      case SCENES.TRANSACTION_LOCKED_ERROR:
        return this.renderError();
      default:
        console.error(
          `${scene} not supported! You can use ${Object.values(
            SCENES
          ).toString()}`
        );
        return null;
    }
  }

  renderMain() {
    const {
      content,
      code,
      errorMessage,
      onChangeCode,
      onSendCode,
      getTranslate,
    } = this.props;
    const { verificationId, subjectDetails } = content;

    return (
      <div className='code-verification__main'>
        <div className='code-verification__header'>
          <PaymentVerification id={verificationId} data={subjectDetails} />
        </div>
        <Form customClass='code-verification__form' onSubmit={onSendCode}>
          <div className='code-verification__info'>{this.renderInfo()}</div>
          <Input
            autoFocus
            id='verification-code'
            value={code}
            label={getTranslate('payment.psd2.verificationCode')}
            placeholder='123 456'
            cleaveOptions={{
              numericOnly: true,
              blocks: [3, 3],
              delimiters: [' '],
            }}
            onChange={onChangeCode}
            error={errorMessage}
            customClass='code-verification__input-field'
            modern
          />
        </Form>
      </div>
    );
  }

  renderInfo() {
    const {
      content: { confirmationDetails, verificationType },
      getTranslate,
    } = this.props;
    if (VERIFICATION_TYPES.GOOGLE_AUTH === verificationType) {
      return (
        <Fragment>
          <div className='code-verification__info-text'>
            {getTranslate('payment.psd2.gAuth.text')}
          </div>
          <div className='code-verification__info-text'>
            {getTranslate('payment.psd2.validRequestGA.text')}
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className='code-verification__info-text'>
          {getTranslate('payment.psd2.sms.text')}{' '}
          <span className='code-verification__info-phone'>
            {confirmationDetails.contactPhoneNumber}
          </span>
        </div>
        <div className='code-verification__info-text'>
          {getTranslate('payment.psd2.validRequestSms.text')}
        </div>
      </Fragment>
    );
  }

  renderError() {
    return (
      <div className='code-verification__error'>{this.getErrorContent()}</div>
    );
  }

  getErrorContent() {
    const { scene, getTranslate } = this.props;

    switch (scene) {
      case SCENES.ATTEMPTS_ERROR:
        return (
          <Fragment>
            <div className='code-verification__error-text'>
              {getTranslate('payment.psd2.attempts.text')}
            </div>
            <div className='code-verification__error-text'>
              {getTranslate('payment.psd2.tryAgain.text')}
            </div>
          </Fragment>
        );
      case SCENES.TIMEOUT_ERROR:
        return (
          <Fragment>
            <div className='code-verification__error-text'>
              {getTranslate('payment.psd2.timeOut.text')}
            </div>
            <div className='code-verification__error-text'>
              {getTranslate('payment.psd2.tryAgain.text')}
            </div>
          </Fragment>
        );
      default:
        return (
          <div className='code-verification__error-text'>
            {getTranslate('payment.psd2.Settings.text')}
          </div>
        );
    }
  }

  renderFooter() {
    const {
      scene,
      canSendCode,
      canResendCode,
      dateResendCode,
      onSendCode,
      onResendCode,
      onCompleteTimer,
      onCloseModal,
      getTranslate,
    } = this.props;

    if (scene === SCENES.MAIN) {
      return (
        <div className='code-verification__buttons'>
          <Button
            status='outline'
            onClick={onResendCode}
            disabled={!canResendCode}
            customClass='ui-button_full-width code-verification__resend-button'
            text={
              <>
                {getTranslate('payment.psd2.resendCode.button')}{' '}
                {!canResendCode ? (
                  <Countdown
                    renderer={this.renderTime}
                    onComplete={onCompleteTimer}
                    date={dateResendCode}
                  />
                ) : (
                  ''
                )}
              </>
            }
          />

          <Button
            status='primary'
            onClick={onSendCode}
            disabled={!canSendCode}
            customClass='ui-button_full-width'
            text={getTranslate('payment.psd2.confirm.button')}
          />
        </div>
      );
    }
    return (
      <Button
        text='OK'
        status='primary'
        customClass='ui-button_full-width'
        onClick={onCloseModal}
      />
    );
  }

  renderTime = ({ minutes, seconds, completed }) => {
    if (completed) {
      return '';
    }
    return (
      <span>
        ({zeroPad(minutes)}:{zeroPad(seconds)})
      </span>
    );
  };
}

export default addTranslation(CodeVerification);
