import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { AnyObject } from 'types/Common';
import PreviewText from 'components/ui/previewText';
import './pickedFilters.scss';

interface Props extends IntlProps {
  params: AnyObject;
}

class PickedFilters extends PureComponent<Props> {
  render() {
    const { getTranslate, params } = this.props;
    const {
      reportDate,
      merchant,
      project,
      reportPeriod,
      balance,
      contract,
      contractWith,
    } = params;

    return (
      <div className='statement-picked-filters'>
        <div className='statement-picked-filters__inner'>
          <div className='statement-picked-filters__col grid-column-4'>
            <div className='statement-picked-filters__item'>
              <div className='statement-picked-filters__key'>
                {getTranslate(`statement.generalInfo.reportDate`)}
              </div>
              <div className='statement-picked-filters__value'>
                {reportDate}
              </div>
            </div>

            <div className='statement-picked-filters__item'>
              <div className='statement-picked-filters__key'>
                {getTranslate(`statement.generalInfo.merchant`)}
              </div>
              <div className='statement-picked-filters__value'>
                {this.renderPreview(merchant, 60)}
              </div>
            </div>

            {project && (
              <div className='statement-picked-filters__item'>
                <div className='statement-picked-filters__key'>
                  {getTranslate(`statement.generalInfo.project`)}
                </div>
                <div className='statement-picked-filters__value'>
                  {this.renderPreview(project, 60)}
                </div>
              </div>
            )}

            {reportPeriod && (
              <div className='statement-picked-filters__item'>
                <div className='statement-picked-filters__key'>
                  {getTranslate(`statement.generalInfo.reportPeriod`)}
                </div>
                <div className='statement-picked-filters__value'>
                  {reportPeriod.join(' - ')}
                </div>
              </div>
            )}
          </div>
          <div className='statement-picked-filters__col grid-column-8'>
            {balance && (
              <div className='statement-picked-filters__item'>
                <div className='statement-picked-filters__key'>
                  {getTranslate(`statement.generalInfo.balance`)}
                </div>
                <div className='statement-picked-filters__value'>
                  {this.renderPreview(balance, 120)}
                </div>
              </div>
            )}

            {contract && (
              <div className='statement-picked-filters__item'>
                <div className='statement-picked-filters__key'>
                  {getTranslate(`statement.generalInfo.contract`)}
                </div>
                <div className='statement-picked-filters__value'>
                  {this.renderPreview(contract, 120)}
                </div>
              </div>
            )}
            {contractWith && (
              <div className='statement-picked-filters__item'>
                <div className='statement-picked-filters__key'>
                  {getTranslate(`statement.generalInfo.contractWith`)}
                </div>
                <div className='statement-picked-filters__value'>
                  {this.renderPreview(contractWith, 120)}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderPreview(data, maxLength) {
    const serializedData = data.join(', ');

    if (serializedData.length > maxLength) {
      const lengthCorrectedToComma = serializedData
        .slice(0, maxLength)
        .lastIndexOf(',');

      return (
        <PreviewText text={serializedData} maxLength={lengthCorrectedToComma} />
      );
    }
    return serializedData;
  }
}

export default addTranslation(PickedFilters);
