import React, { PureComponent } from 'react';
import classNames from 'classnames';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Input from 'components/ui/input';
import Icon from 'components/ui/icon';
import Button from 'components/ui/button';
import SliderPicker from 'components/ui/sliderPicker/SliderPicker';
import DateHelpers from 'helpers/Date';
import { getMinYear, getMaxYear, getMinDate, getMaxDate } from './helpers';
import modes from './modes';
import dateFormats from 'constants/dateFormats';
import './datePicker.scss';

interface OwnProps {
  selectedDate?: Date | string; // Date or DD.MM.YYYY HH:mm:ss
  mode: keyof typeof modes;
  onChange?: (date: string, type?: 'day') => void;
  customClass?: string;
  withTime?: boolean;
  withApplyButton?: boolean;
  applyFormat?: keyof typeof dateFormats;
  inputLabel?: string;
  minDate?: string;
  maxDate?: string;
  isUnselectDate?: boolean;
  initialDate?: string;
  disableYear?: boolean;
  isHideYearPicker?: boolean;
  pickerRef?: { current: any };
}

interface State {
  month: any;
  date: Date | undefined;
  time: string;
}

type Props = OwnProps & IntlProps;

class DatePicker extends PureComponent<Props, State> {
  static defaultProps = {
    mode: modes.from,
    customClass: '',
    withTime: true,
    applyFormat: 'datetime',
    inputLabel: '',
    isUnselectDate: true,
  } as const;

  constructor(props) {
    super(props);

    if (props.selectedDate) {
      const initialDate = DateHelpers.createDate(
        props.selectedDate,
        'datetime'
      ).toDate();
      this.state = {
        date: props.selectedDate ? initialDate : undefined,
        month: DateHelpers.createDate(initialDate, 'date').toDate(),
        time: DateHelpers.getTimeFromDate(initialDate),
      };
    } else if (props.initialDate) {
      this.state = {
        date: undefined,
        month: DateHelpers.createDate(props.initialDate, 'date').toDate(),
        time: '',
      };
    } else {
      this.state = {
        date: undefined,
        month: DateHelpers.getDate().toDate(),
        time: '',
      };
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      const { selectedDate } = this.props;
      const initialDate = DateHelpers.createDate(
        selectedDate,
        'datetime'
      ).toDate();
      this.setState({
        date: selectedDate ? initialDate : undefined,
        month: selectedDate
          ? DateHelpers.createDate(initialDate, 'date').toDate()
          : DateHelpers.getDate().toDate(),
        time: selectedDate ? DateHelpers.getTimeFromDate(initialDate) : '',
      });
    }
  }

  render() {
    const {
      withTime,
      withApplyButton,
      customClass,
      inputLabel,
      pickerRef,
      getTranslate,
    } = this.props;
    const { date, time, month } = this.state;

    const minDate = this.getMinDate();
    const maxDate = this.getMaxDate();

    return (
      <div
        className={classNames(`ui-date-picker date-picker-custom`, customClass)}
        ref={pickerRef}>
        <div className='ui-date-picker__main'>
          {!this.props.isHideYearPicker && this.renderYear()}
          <DayPicker
            locale={moment.locale()}
            localeUtils={MomentLocaleUtils}
            selectedDays={date}
            fromMonth={minDate}
            toMonth={maxDate}
            month={month}
            firstDayOfWeek={1}
            showOutsideDays={true}
            onMonthChange={this.changeMonth}
            onDayClick={this.changeDay}
            captionElement={(options) => this.renderCaption(options.date)}
            renderDay={(day) => this.renderDay(day)}
            navbarElement={(options) => this.renderNavigation(options)}
            disabledDays={[
              {
                before: minDate,
                after: maxDate,
              },
            ]}
          />
        </div>
        {(withApplyButton || withTime) && (
          <div className='ui-date-picker__footer'>
            {withTime && (
              <div className='ui-date-picker__time'>
                <div className='ui-date-picker__time-input'>
                  <Input
                    id='date-picker-time'
                    prefix={<Icon size={16} name='im-Timezone' />}
                    placeholder={this.getDefaultTime()}
                    value={time}
                    cleaveOptions={{
                      time: true,
                      timePattern: ['h', 'm', 's'],
                    }}
                    onChange={({ target }) => this.changeTime(target.value)}
                  />
                </div>
                {inputLabel && (
                  <div className='ui-date-picker-range__info'>
                    {getTranslate(inputLabel)}
                  </div>
                )}
              </div>
            )}
            {withApplyButton && (
              <Button
                onClick={() => this.emitChange()}
                status='primary'
                id='apply-quick-dates'
                text={getTranslate('ui.datePicker.apply')}
              />
            )}
          </div>
        )}
      </div>
    );
  }

  renderDay = (day) => {
    return <span>{DateHelpers.createDate(day).date()}</span>;
  };

  renderNavigation = (options) => {
    return (
      <div className='DayPicker-NavBar'>
        <Icon
          name='im-Arrow-left-Option-2'
          size={16}
          className={classNames(
            'DayPicker-NavButton DayPicker-NavButton--prev',
            {
              'DayPicker-NavButton--interactionDisabled':
                !options.showPreviousButton,
            }
          )}
          onClick={() => options.onPreviousClick()}
        />
        <Icon
          name='im-Arrow-right-Option-2'
          size={16}
          className={classNames(
            'DayPicker-NavButton DayPicker-NavButton--next',
            {
              'DayPicker-NavButton--interactionDisabled':
                !options.showNextButton,
            }
          )}
          onClick={() => options.onNextClick()}
        />
      </div>
    );
  };

  renderCaption(date) {
    const months = DateHelpers.getMonths();
    const currentMonth = months[DateHelpers.getMonth(date)];
    const currentYear = DateHelpers.getYear(date);

    return (
      <div className='DayPicker-Caption'>
        {currentMonth} {currentYear}
      </div>
    );
  }

  renderYear() {
    const { month } = this.state;

    const maxYear =
      this.props.maxDate === 'none' ? getMaxYear(month) : this.getMaxYear();
    return (
      <div className='DayPicker-Year'>
        <SliderPicker
          min={Number(this.getMinYear())}
          max={Number(maxYear)}
          value={DateHelpers.getYear(month)}
          disabled={this.props.disableYear}
          onChange={this.changeYear}
        />
      </div>
    );
  }

  getDefaultTime = () => {
    return this.props.mode === modes.from ? '00:00:00' : '23:59:59';
  };

  getMinYear = () => {
    return getMinYear(this.props.minDate);
  };

  getMaxYear = () => {
    return getMaxYear(this.props.maxDate);
  };

  getMinDate = () => {
    return getMinDate(this.props.minDate);
  };

  getMaxDate = () => {
    return getMaxDate(this.props.maxDate);
  };

  changeDay = (day, { disabled, selected }: any) => {
    const { withApplyButton, isUnselectDate } = this.props;
    const { time } = this.state;

    if (disabled) return;

    if (isUnselectDate) {
      this.setState({ date: !selected ? day : undefined });
    } else {
      this.setState({ date: day });
    }

    if (!selected && !time) {
      this.changeTime(this.getDefaultTime());
    }
    if (!withApplyButton) {
      setTimeout(() => this.emitChange('day'));
    }
  };

  changeMonth = (month) => {
    this.setState({ month });
  };

  changeYear = (newYear) => {
    const { month } = this.state;
    let newDate = DateHelpers.getDate()
      .month(DateHelpers.createDate(month).month())
      .year(newYear)
      .toDate();

    const minDate = this.getMinDate();
    const maxDate = this.getMaxDate();

    if (minDate > newDate) {
      newDate = minDate;
    } else if (maxDate && newDate > maxDate) {
      newDate = maxDate;
    }

    this.changeMonth(newDate);
  };

  changeTime = (value: string): void => {
    const { withApplyButton } = this.props;

    this.setState(
      {
        time: value,
      },
      () => {
        if (!withApplyButton) {
          this.emitChange();
        }
      }
    );
  };

  emitChange = (type?) => {
    const { onChange, mode, applyFormat } = this.props;
    const { time, date } = this.state;

    if (!date) {
      return onChange && onChange('');
    }

    const correctTime: string = DateHelpers.validateTime(time, mode === 'from');
    const dateWithTime = DateHelpers.createDate(
      DateHelpers.setTime(date, correctTime)
    );

    onChange &&
      onChange(DateHelpers.getFormat(dateWithTime, applyFormat), type);
  };
}

export default addTranslation(DatePicker);
