import React from 'react';

function handleCopy(e, text) {
  e.preventDefault();
  navigator.clipboard.writeText(text);
}

export default (text) => {
  return <span onCopy={(e) => handleCopy(e, text)}>&#x200E;{text}</span>;
};
