import React from 'react';
import { MafAppendix, MafSection } from 'components/maf/MafTypes';

interface Props {
  selectedEntity: MafSection | MafAppendix | any;
}

class MafHeader extends React.Component<Props> {
  render() {
    const { selectedEntity } = this.props;
    return (
      <div className='maf-content__header'>
        <span className='maf-content__header-caption'>
          {selectedEntity?.caption}
        </span>
      </div>
    );
  }
}

export default MafHeader;
