import phoneDialCodes from 'constants/phoneDialCodes';

export default (phone) => {
  if (!phone) return null;

  let phoneStr = phone;
  if (phone.startsWith('+')) {
    phoneStr = phone.slice(1);
  }
  return phoneDialCodes.find(({ dial_code }) => {
    return phoneStr.startsWith(dial_code.slice(1));
  });
};
