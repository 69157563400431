export const configurationBottom = [
  {
    id: 'bank_name',
    localId: 'bank_name',
    valueType: 'string',
    active: true,
  },

  {
    id: 'EUR',
    localId: 'EUR',
    valueType: 'string',
    active: true,
    params: {
      isEditable: true,
    },
  },
  {
    id: 'GBP',
    localId: 'GBP',
    valueType: 'string',
    active: true,
    params: {
      isEditable: true,
    },
  },
  {
    id: 'PLN',
    localId: 'PLN',
    valueType: 'string',
    active: true,
    params: {
      isEditable: true,
    },
  },
  {
    id: 'RUB',
    localId: 'RUB',
    valueType: 'string',
    active: true,
    params: {
      isEditable: true,
    },
  },
  {
    id: 'USD',
    localId: 'USD',
    valueType: 'string',
    active: true,
    params: {
      isEditable: true,
    },
  },
  {
    id: 'UAH',
    localId: 'UAH',
    valueType: 'string',
    active: true,
    params: {
      isEditable: true,
    },
  },
  {
    id: 'IDR',
    localId: 'IDR',
    valueType: 'string',
    active: true,
    params: {
      isEditable: true,
    },
  },
];

export const configurationTop = [
  {
    id: 'bank',
    localId: 'bank',
    valueType: 'string',
    active: true,
  },
  {
    id: 'service_provider',
    localId: 'service_provider',
    valueType: 'string',
    active: true,
  },
  {
    id: 'safeguarded',
    localId: 'safeguarded',
    valueType: 'string',
    active: true,
  },
  {
    id: 'bank_name',
    localId: 'bank_name',
    valueType: 'date',
    active: true,
  },
  {
    id: 'EUR',
    localId: 'EUR',
    valueType: 'string',
    active: true,
    params: {
      isEditable: true,
    },
  },
  {
    id: 'GBP',
    localId: 'GBP',
    valueType: 'string',
    active: true,
    params: {
      isEditable: true,
    },
  },
  {
    id: 'PLN',
    localId: 'PLN',
    valueType: 'string',
    active: true,
    params: {
      isEditable: true,
    },
  },
  {
    id: 'RUB',
    localId: 'RUB',
    valueType: 'string',
    active: true,
    params: {
      isEditable: true,
    },
  },
  {
    id: 'USD',
    localId: 'USD',
    valueType: 'string',
    active: true,
    params: {
      isEditable: true,
    },
  },
  {
    id: 'UAH',
    localId: 'UAH',
    valueType: 'string',
    active: true,
    params: {
      isEditable: true,
    },
  },
  {
    id: 'IDR',
    localId: 'IDR',
    valueType: 'string',
    active: true,
    params: {
      isEditable: true,
    },
  },
];
