import ActionReducer from 'types/ActionReducer';
import ACTIONS from 'constants/actionTypes';
import { DocLinks } from '../types/Api';

type State = {
  docLinks: DocLinks;
  canChangeMafStatus: boolean;
};

const initialState = {
  docLinks: null,
  canChangeMafStatus: true,
};

export const demoStage = (
  state: State = initialState,
  action: ActionReducer
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_DOC_LINKS:
      return { ...state, docLinks: payload };
    case ACTIONS.MAF_STATUS_CHANGE_AVAILABILITY:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default demoStage;
