export default {
  payments: 'payments',
  payouts: 'payouts',
  massPayouts: 'payouts',
  reports: 'reports',
  risks: 'risks',
  analytics: 'analytics',
  recurring: 'subscription',
  chargebacks: 'chargebacks',
  remittance: 'remittance-payments',
  businessDocs: 'maf',
  myTeam: 'myTeam', //?

  /* rcs: 'rcsBlackWhite', //?
  references: 'paymentOperationReference',
  admin: 'admin', //admin
  subscriptionPayments: 'subscriptionPayments',
  apsIcon: 'apsIcon',
  finance: 'finance', //?
  remittances: 'remittances', //?
  invoices: 'invoices',*/
} as const;
