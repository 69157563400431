import api from 'api/user';
import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';

const getTableColumnsConfiguration = (
  configurationState,
  name,
  changeFixed = false
) => {
  return {
    [name]: configurationState.map(({ id, active, fixed }) => {
      return {
        id,
        active,
        fixed: changeFixed && fixed !== undefined ? !fixed : fixed,
      };
    }),
  };
};

export const initAllConfiguration = (list) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.INIT_ALL_CONFIGURATION,
        payload: {
          list,
        },
      })
    );
  };
};

export const updateConfiguration = (param) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPDATE_CONFIGURATION,
        payload: {
          ...param,
        },
      })
    );
  };
};

export const applyConfiguration = (configuration, name) => {
  return (dispatch) => {
    api
      .setConfig({
        param: 'tableColumns',
        value: getTableColumnsConfiguration(configuration, name),
      })
      .then(() => {
        dispatch(
          createAction({
            type: ACTIONS.APPLY_CONFIGURATION,
            payload: {
              body: configuration,
              name,
            },
          })
        );
      });
  };
};

export const changeFixedConfiguration = (name: string) => {
  return (dispatch, getState) => {
    api
      .setConfig({
        param: 'tableColumns',
        value: getTableColumnsConfiguration(
          getState().configuration[name],
          name,
          true
        ),
      })
      .then(() => {
        dispatch(
          createAction({
            type: ACTIONS.CHANGE_FIXED_CONFIGURATION,
            payload: { name },
          })
        );
      });
  };
};
