import { addTranslation, IntlProps } from 'decorators/addTranslation';

export const LinkText = addTranslation(
  ({
    status,
    getTranslate,
  }: {
    status: string;
  } & IntlProps) => {
    const statusToTextMap = {
      daf_fill: 'businessdocuments.doc.action.edit',
      daf_review: 'businessdocuments.doc.action.view',
      accepted: 'businessdocuments.doc.action.view',
      decline: 'businessdocuments.doc.action.view',
    };

    return (
      <div className='merchant-legal-entity-table__link'>
        {getTranslate(statusToTextMap[status])}
      </div>
    );
  }
);
