import React from 'react';
import { connect } from 'react-redux';

import { ById, ByOrder, IFiltersById, ISharedWith } from 'types/Analytics';
import { RootState, StoreProps } from 'store';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';

import { openModal } from 'actions/modal';
import checkFilters from 'helpers/checkFilters';
import Analytics from 'pages/analytics/Analytics';
import { LayoutTypes } from 'components/modal/modalList/editLayout/EditLayout';

import LocalStorage from 'helpers/LocalStorage';
import {
  fetchChartFilters,
  fetchChartItems,
  fetchUserCharts,
} from 'actions/analytics';
import {
  fetchChartsSharedWith,
} from 'actions/analytics/shareChartsFeature';
import Messages from 'constants/rpcTypes';
import CustomScrollbar from 'components/ui/customScrollbar';
import PageTemplate from 'components/pageTemplate/PageTemplate';

interface ConnectedProps {
  byOrder: ByOrder;
  byId: ById;
  filtersById: IFiltersById;
  sharedWith: ISharedWith;
}

type Props = ConnectedProps & StoreProps & WithPermissions;

interface State {
  layoutType: LayoutTypes;
  chartsFetched: boolean;
}

@wrapAppWithCssClass('layout-app_desktop-l-width')
class AnalyticsContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      chartsFetched: false,
      layoutType: LocalStorage.get('graphsLayoutType') || LayoutTypes.modules,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;

    await Promise.all([
      checkFilters('advancedAnalytics'),
      dispatch(fetchUserCharts()),
    ]);

    this.setState({
      chartsFetched: true,
    });

    await dispatch(fetchChartItems());
    await dispatch(fetchChartsSharedWith());
    await dispatch(fetchChartFilters());
  }

  render() {
    const { layoutType, chartsFetched } = this.state;
    const { byOrder, byId, filtersById, sharedWith, isEnabled, dispatch } =
      this.props;

    return (
      <PageTemplate.Main>
        {isEnabled(Messages.Analytics_AdvancedAnalytics) && (
          <CustomScrollbar>
            <PageTemplate.Container>
              <Analytics
                layoutType={layoutType}
                openLayoutModal={this.openLayoutModal}
                openChartEditModal={this.openChartEditModal}
                chartsFetched={chartsFetched}
                byOrder={byOrder}
                byId={byId}
                dispatch={dispatch}
                filtersById={filtersById}
                sharedWith={sharedWith}
              />
            </PageTemplate.Container>
          </CustomScrollbar>
        )}
      </PageTemplate.Main>
    );
  }

  openChartEditModal = () => {
    const { dispatch } = this.props;

    dispatch(
      openModal({
        modalId: 'GraphDetails',
      })
    );
  };

  openLayoutModal = () => {
    const { dispatch } = this.props;

    dispatch(
      openModal({
        modalId: 'EditLayout',
        callback: (layoutType) => {
          if (layoutType !== false) {
            this.setState({ layoutType });
            LocalStorage.set('graphsLayoutType', layoutType);
          }
        },
      })
    );
  };
}

const mapStateToProps = (store: RootState): ConnectedProps => ({
  byOrder: store.analytics.gridData.byOrder,
  byId: store.analytics.chartData.byId,
  filtersById: store.analytics.chartData.filtersById,
  sharedWith: store.analytics.chartData.sharedWith,
});

export default connect(mapStateToProps)(addPermissions(AnalyticsContainer));
