import React, { PureComponent, ReactNode } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import './accordion.scss';

interface Props {
  title: string | ReactNode;
  isOpened: boolean;
  isDisabled?: boolean;
  customClass?: string;
  onOpen?: () => void;
}

interface State {
  isOpened: boolean;
}

class AccordionItem extends PureComponent<Props, State> {
  private accordionItem;

  static defaultProps = {
    isOpened: false,
    isDisabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpened: props.isOpened,
    };
  }

  componentDidUpdate(prevProps) {
    const { isOpened } = this.props;
    if (isOpened !== prevProps.isOpened) {
      this.setState({ isOpened });
    }
  }

  render() {
    const { title, children, isDisabled, customClass } = this.props;
    const { isOpened } = this.state;

    return (
      <div
        className={classNames('ui-accordion-item', customClass, {
          'ui-accordion-item_opened': isOpened,
          'ui-accordion-item_disabled': isDisabled,
        })}
        ref={(e) => {
          this.accordionItem = e;
        }}>
        <div className='ui-accordion-item__title' onClick={this.openItem}>
          <span className='ui-accordion-item__title-inner'>{title}</span>
          {!isDisabled && (
            <Icon
              size={10}
              name='im-Arrow-down'
              className='ui-accordion-item__arrow'
            />
          )}
        </div>
        {isOpened && (
          <div className='ui-accordion-item__content'>{children}</div>
        )}
      </div>
    );
  }

  openItem = () => {
    const { isDisabled, onOpen } = this.props;
    if (isDisabled) return false;

    this.setState(
      (state) => {
        return {
          isOpened: !state.isOpened,
        };
      },
      () => {
        if (this.state.isOpened) {
          onOpen && onOpen();
        }
      }
    );
  };
}
export default AccordionItem;
