import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import DynamicTable from 'components/ui/table/DynamicTable';
import CustomScrollbar from 'components/ui/customScrollbar';
import Loader from 'components/ui/loader';
import tableNames from 'constants/tableNames';
import { Balance } from './OnlineFinanceTypes';
import './onlineFinance.scss';

interface Props extends IntlProps {
  data: Balance;
  isLoading: boolean;
  onSort: (data: any) => void;
  wlCount: number;
  isBalanceFilterByCompaniesEnabled: boolean;
}

class OnlineFinance extends PureComponent<Props> {
  render() {
    const { isLoading } = this.props;

    return (
      <div className='online-finance'>
        {this.renderContent()}
        {isLoading && <Loader />}
      </div>
    );
  }

  renderContent() {
    const { data, onSort, getTranslate } = this.props;
    if (!data?.isFetched) {
      return null;
    }

    return (
      <div className='online-finance__content'>
        <div className='grid-row'>
          <div className='online-finance__totals'>
            <div className='online-finance__totals-header online-finance__totals-container'>
              {getTranslate(`onlinebalance.currentBalanceList.totalHeader`)}
            </div>
            <div className='online-finance__totals-list'>
              <CustomScrollbar>
                <div className='online-finance__totals-container'>
                  {data.totals.map(({ amount, currency }, index) => {
                    return (
                      <div key={index} className='online-finance__totals-item'>
                        {amount} {currency}
                      </div>
                    );
                  })}
                </div>
              </CustomScrollbar>
            </div>
            {this.getDisclaimer()}
          </div>
          <div className='grid-column-12 grid-row-item'>
            <div className='online-finance__table-wrap'>
              <DynamicTable
                items={data.items}
                outerSort={data.sort}
                onSort={onSort}
                tableName={tableNames.balanceRealTime}
                customClass='online-finance__table'
                sendMetric={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  getDisclaimer = () => {
    const { wlCount, isBalanceFilterByCompaniesEnabled, getTranslate } =
      this.props;

    if (wlCount < 2 || isBalanceFilterByCompaniesEnabled) return null;

    return (
      <div className='online-finance__totals-footer online-finance__totals-container'>
        {getTranslate('onlinebalance.currentBalanceList.total.textMixt')}
      </div>
    );
  };
}

export default addTranslation(OnlineFinance);
