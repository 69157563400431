import LocalStorage from './LocalStorage';
import DateHelpers from './Date';
import { AnyObject } from 'types/Common';

const CACHE_MINUTES_TO_LIVE = 5;
export default class CacheService {
  readonly storageKey;
  readonly cache;
  readonly fetchData;

  constructor(storageKey, fetchData) {
    this.storageKey = storageKey;
    this.cache = LocalStorage.get(storageKey) || {};
    this.fetchData = fetchData;
  }

  cacheOutDated = (lastUpdated) =>
    !lastUpdated ||
    DateHelpers.getDate().diff(DateHelpers.createDate(lastUpdated), 'minutes') >
      CACHE_MINUTES_TO_LIVE;

  getData(itemKey) {
    const currentCache = this.cache && this.cache[itemKey];

    if (!currentCache) {
      this.fetchData(itemKey);
      return null;
    }
    const { lastUpdated, ...data } = currentCache;
    if (this.cacheOutDated(lastUpdated)) {
      this.fetchData(itemKey);
    }
    return data;
  }

  setData(itemKey, data: AnyObject, memorize?: boolean) {
    if (itemKey) {
      this.cache[itemKey] = {
        ...data,
        lastUpdated: DateHelpers.getDate().format(),
      };
      memorize && this.memorizeCache();
    }
  }

  removeCache(itemKey) {
    this.cache[itemKey] = null;
  }

  memorizeCache() {
    LocalStorage.set(this.storageKey, this.cache);
  }
}
