import React, { PureComponent } from 'react';
import { isEqual } from 'lodash';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';

import PageTemplate from 'components/pageTemplate';
import Button from 'components/ui/button';
import InputDate from 'components/ui/inputDate';
import TableTop from './components/tableTop/TableTop';
import CustomScrollbar from 'components/ui/customScrollbar';
import Input from 'components/ui/input';
import Totals from './components/totals/Totals';

import Utils from 'helpers/Utils';
import Messages from 'constants/rpcTypes';
import { currency } from './constants';
import { initialCalcs } from './initialState';

import { AnyObject } from 'types/Common';

interface Props extends WithPermissions {
  date: string;
  dateToSet: string;
  data: any;
  dataSectionRate: any;
  relevantFundsRate: any;
  relevantFunds: any;
  isLoading: boolean;
  configuration: AnyObject;
  configurationRelevantFunds: AnyObject;
  changeData: (rowId, colId, value, isCurrency?) => void;
  changeDate: (value: string, toSet?: boolean) => void;
  changeRate: (rateName: string, currency: string, value: string) => void;
  changeDeposit: (
    name: string,
    cur: string,
    value: string,
    callback: () => void,
    isOver: boolean
  ) => void;
  saveData: () => void;
  commentary: string;
  onCommentaryEdit: (value: any) => void;
  openCSVModal: (tableName: string) => void;

  deposit: AnyObject;
}

interface State {
  safeguardedEur: AnyObject;
  needToSafeguardEur: AnyObject;
  totalSafeguarded: AnyObject;
  totalNeedToSafeguard: AnyObject;
  assets: AnyObject;
  assetsEur: AnyObject;
  safeguardedSum: string;
  needToSafeguardSum: string;
  assetsSum: string;

  total: AnyObject;
  totalEur: AnyObject;
  totalRelevantFunds: string;

  difference: string;

  visaDepositRated: AnyObject;
  mcDepositRated: AnyObject;

  totalDepositEur: string;
  fundsInTransit: string;
}

@wrapAppWithCssClass('layout-app_desktop-width')
class Reconciliation extends PureComponent<Props, State> {
  state = {
    safeguardedEur: {},
    needToSafeguardEur: {},
    totalSafeguarded: {},
    totalNeedToSafeguard: {},
    assets: {},
    assetsEur: {},

    safeguardedSum: '0.00',
    needToSafeguardSum: '0.00',
    assetsSum: '0.00',

    total: {},
    totalEur: {},
    totalRelevantFunds: '0.00',

    difference: '0.00',

    visaDepositRated: initialCalcs,
    mcDepositRated: initialCalcs,

    totalDepositEur: '0.00',
    fundsInTransit: '0.00',
  };

  componentDidMount() {
    this.calcTotals();
    this.calcRelevantFundsTotals();
    this.calcDeposit();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState) {
    if (
      !isEqual(prevProps.dataSectionRate, this.props.dataSectionRate) ||
      !isEqual(prevProps.data, this.props.data)
    ) {
      this.calcTotals();
    }
    if (
      !isEqual(prevProps.relevantFundsRate, this.props.relevantFundsRate) ||
      !isEqual(prevProps.relevantFunds, this.props.relevantFunds)
    ) {
      this.calcRelevantFundsTotals();
    }

    if (
      !isEqual(prevProps.deposit.visaDeposit, this.props.deposit.visaDeposit) ||
      !isEqual(prevProps.deposit.mcDeposit, this.props.deposit.mcDeposit) ||
      !isEqual(prevProps.relevantFundsRate, this.props.relevantFundsRate)
    ) {
      this.calcDeposit();
    }
  }

  render() {
    const {
      data,
      relevantFunds,
      dataSectionRate,
      relevantFundsRate,
      date,
      dateToSet,
      isLoading,
      configuration,
      configurationRelevantFunds,
      changeData,
      changeRate,
      saveData,
      changeDate,
      isEnabled,
      commentary,
      onCommentaryEdit,
      openCSVModal,
    } = this.props;

    const {
      safeguardedEur,
      needToSafeguardEur,
      totalSafeguarded,
      totalNeedToSafeguard,
      assets,
      assetsEur,
      safeguardedSum,
      needToSafeguardSum,
      assetsSum,
    } = this.state;

    const {
      total,
      totalEur,
      totalRelevantFunds,
      visaDepositRated,
      mcDepositRated,
      difference,
      totalDepositEur,
      fundsInTransit,
    } = this.state;
    const { deposit, changeDeposit } = this.props;
    if (!isEnabled(Messages.Reconciliation_Get)) return null;
    const isUserCanEdit = isEnabled(Messages.Reconciliation_SetData);
    const rateOptions = {
      numeral: true,
      numeralDecimalScale: 100,
      numeralDecimalMark: '.',
      delimiter: ' ',
    };

    return (
      <PageTemplate.Main topPadding>
        <PageTemplate.Container customClass='reconciliation-page'>
          <div className='reconciliation-page__filters'>
            <div className='reconciliation-page__date-value'>
              <InputDate
                title='Set date & time'
                initialDate={dateToSet}
                onChange={(value) => changeDate(value, true)}
                selectedDate={dateToSet}
                withCloseButton={false}
                closeAfterSelect
              />
            </div>
            <div className='reconciliation-page__date-filter'>
              <InputDate
                title='Date Filter'
                withTime={false}
                initialDate={date}
                onChange={changeDate}
                selectedDate={date}
                withCloseButton={false}
                closeAfterSelect
              />
            </div>
          </div>
          <div className='reconciliation-page__item'>
            {isUserCanEdit && (
              <div className='reconciliation-page__item__upload-button'>
                <Button
                  text='Upload CSV file'
                  status='primary'
                  id='upload-csv'
                  icon='upload'
                  onClick={() => openCSVModal('dataSection')}
                />
              </div>
            )}
            <TableTop
              data={data}
              tableName='dataSection'
              configuration={configuration}
              changeData={changeData}
              isLoading={isLoading}
              isUserCanEdit={isUserCanEdit}
            />
            <div className='reconciliation-page__section reconciliation-page__section_totals'>
              <CustomScrollbar>
                <Totals title='' data={dataSectionRate} isHeader />
                <Totals
                  title='FX Rate'
                  inputOptions={rateOptions}
                  data={dataSectionRate}
                  onChange={(cur, value) => {
                    changeRate('dataSection', cur, value);
                  }}
                  isUserCanEdit={isUserCanEdit}
                />
                <Totals data={totalSafeguarded} title='Total safeguarded' />
                <Totals data={safeguardedEur} title='Safeguarded (EUR)' />
                <Totals
                  data={totalNeedToSafeguard}
                  title='Total Need To Safeguard'
                />
                <Totals
                  data={needToSafeguardEur}
                  title='Need To Safeguard (EUR)'
                />
                <Totals data={assets} title='Total Assets' />
                <Totals data={assetsEur} title='Total Assets (EUR)' />
              </CustomScrollbar>
            </div>
            <div className='reconciliation-page__section'>
              <div className='reconciliation-page__sum'>
                Total safeguarded (EUR): {safeguardedSum}
              </div>
              <div className='reconciliation-page__sum'>
                Total need to safeguard (EUR): {needToSafeguardSum}
              </div>
              <div className='reconciliation-page__sum'>
                Total assets (EUR): {assetsSum}
              </div>
            </div>
          </div>
          {isUserCanEdit && (
            <>
              <div className='reconciliation-page__item'>
                <Input
                  value={commentary}
                  id='textarea'
                  label='Commentary'
                  placeholder='Type here...'
                  type='textarea'
                  resize={false}
                  customClass='reconciliation-page__item__user-commentary'
                  onChange={onCommentaryEdit}
                />
                <div className='reconciliation-page__item__upload-button'>
                  <Button
                    text='Upload CSV file'
                    status='primary'
                    id='upload-csv'
                    icon='upload'
                    onClick={() => openCSVModal('relevantFunds')}
                  />
                </div>
                <TableTop
                  data={relevantFunds}
                  tableName='relevantFunds'
                  configuration={configurationRelevantFunds}
                  changeData={changeData}
                  isLoading={isLoading}
                  isUserCanEdit={isUserCanEdit}
                />
                <div className='reconciliation-page__section reconciliation-page__section_totals'>
                  <CustomScrollbar>
                    <Totals title='' data={relevantFundsRate} isHeader />
                    <Totals
                      title='FX Rate'
                      inputOptions={rateOptions}
                      data={relevantFundsRate}
                      onChange={(cur, value) => {
                        changeRate('relevantFunds', cur, value);
                      }}
                      isUserCanEdit={isUserCanEdit}
                    />
                    <Totals title='Total' data={total} />
                    <Totals title='Total (EUR)' data={totalEur} />
                  </CustomScrollbar>
                </div>
                <div className='reconciliation-page__sum'>
                  Total assets (EUR): {assetsSum}
                </div>
                <div className='reconciliation-page__sum'>
                  Total relevant funds: {totalRelevantFunds}
                </div>
                <div className='reconciliation-page__sum'>
                  Difference: {difference}
                </div>
              </div>
              <div className='reconciliation-page__item'>
                <div className='reconciliation-page__subtitle'>
                  DIFFERENCE EXPLANATIONS
                </div>
                <div className='reconciliation-page__section reconciliation-page__section_totals'>
                  <CustomScrollbar>
                    <Totals
                      title=''
                      isHeader
                      isSimpleCurrencyLabel
                      data={relevantFundsRate}
                    />
                    <Totals
                      title='MC deposit'
                      inputType='amount'
                      data={deposit.mcDeposit}
                      isUserCanEdit={isUserCanEdit}
                      onChange={(cur, value, isOver) =>
                        changeDeposit(
                          'mcDeposit',
                          cur,
                          value,
                          this.calcDeposit,
                          isOver
                        )
                      }
                    />
                    <Totals
                      title='Visa deposit'
                      data={deposit.visaDeposit}
                      inputType='amount'
                      isUserCanEdit={isUserCanEdit}
                      onChange={(cur, value, isOver) =>
                        changeDeposit(
                          'visaDeposit',
                          cur,
                          value,
                          this.calcDeposit,
                          isOver
                        )
                      }
                    />
                    <Totals
                      title='Visa deposit (EUR)'
                      data={visaDepositRated}
                    />
                    <Totals title='MC deposit (EUR)' data={mcDepositRated} />
                  </CustomScrollbar>
                </div>

                <div className='reconciliation-page__sum'>
                  Total (EUR): {totalDepositEur}
                </div>
                <div className='reconciliation-page__sum'>
                  Funds in transit: {fundsInTransit}
                </div>
              </div>
            </>
          )}
          {isUserCanEdit && (
            <div className='reconciliation-page__footer'>
              <Button text='Save' status='success' onClick={saveData} />
            </div>
          )}
        </PageTemplate.Container>
      </PageTemplate.Main>
    );
  }

  getNumber = (string) => {
    if (string) {
      return +Utils.getNumberWithoutSpace(string);
    }
    return 0;
  };

  calcTotals = (): any => {
    const { data, dataSectionRate } = this.props;
    const safeguardedEur = {},
      needToSafeguardEur = {},
      totalSafeguarded = {},
      totalNeedToSafeguard = {};

    currency.forEach((cur) => {
      safeguardedEur[cur] = 0;
      needToSafeguardEur[cur] = 0;

      totalSafeguarded[cur] = 0;
      totalNeedToSafeguard[cur] = 0;
      data.forEach((row) => {
        if (row.safeguarded) {
          totalSafeguarded[cur] = (
            +totalSafeguarded[cur] + this.getNumber(row[cur])
          ).toFixed(2);
        } else {
          totalNeedToSafeguard[cur] = (
            +totalNeedToSafeguard[cur] + this.getNumber(row[cur])
          ).toFixed(2);
        }
      }, 0);
    });

    currency.forEach((cur) => {
      const rate = dataSectionRate[cur];
      safeguardedEur[cur] = (
        this.getNumber(totalSafeguarded[cur]) / rate
      ).toFixed(2);
      needToSafeguardEur[cur] = (
        this.getNumber(totalNeedToSafeguard[cur]) / rate
      ).toFixed(2);
    });

    this.setState(
      {
        safeguardedEur,
        needToSafeguardEur,
        totalSafeguarded,
        totalNeedToSafeguard,
      },
      this.calcAssets
    );
  };

  calcAssets = () => {
    const {
      totalSafeguarded,
      totalNeedToSafeguard,
      safeguardedEur,
      needToSafeguardEur,
    } = this.state;
    const assets = {};
    const assetsEur = {};
    currency.forEach((cur) => {
      assets[cur] = (
        +totalSafeguarded[cur] + this.getNumber(totalNeedToSafeguard[cur])
      ).toFixed(2);
      assetsEur[cur] = (
        +safeguardedEur[cur] + this.getNumber(needToSafeguardEur[cur])
      ).toFixed(2);
    });
    this.setState({ assets, assetsEur }, () => {
      this.calcSum();
    });
  };

  calcSum = () => {
    const { safeguardedEur, needToSafeguardEur, assetsEur } = this.state;

    const safeguardedSum = this.getSum(safeguardedEur);
    const needToSafeguardSum = this.getSum(needToSafeguardEur);
    const assetsSum = this.getSum(assetsEur);

    this.setState(
      {
        safeguardedSum: Utils.getNumberWithSpace(safeguardedSum),
        needToSafeguardSum: Utils.getNumberWithSpace(needToSafeguardSum),
        assetsSum: Utils.getNumberWithSpace(assetsSum),
      },
      this.calcDifference
    );
  };

  getSum = (valuesObj) => {
    return Object.values(valuesObj)
      .reduce((a: number, b) => a + this.getNumber(b), 0)
      .toFixed(2);
  };

  getSumWithRates = (valuesObj) => {
    const { relevantFundsRate } = this.props;
    return Object.entries(valuesObj)
      .reduce(
        (a: number, [cur, value]) =>
          a + this.getNumber(value) / relevantFundsRate[cur],
        0
      )
      .toFixed(2);
  };

  calcRelevantFundsTotals = (): any => {
    const { relevantFunds, relevantFundsRate } = this.props;
    const total = {};
    const totalEur: AnyObject = {};

    currency.forEach((cur) => {
      totalEur[cur] = 0;
      total[cur] = 0;
      relevantFunds.forEach((row) => {
        const volume = +row[cur];
        const rate = relevantFundsRate[cur];

        totalEur[cur] = (+totalEur[cur] + volume / rate).toFixed(2);
        total[cur] = (+total[cur] + volume).toFixed(2);
      }, 0);
    });

    const totalRelevantFunds: string = this.getSum(totalEur);

    this.setState(
      {
        total,
        totalEur,
        totalRelevantFunds: Utils.getNumberWithSpace(totalRelevantFunds),
      },
      this.calcDifference
    );
  };

  calcDifference = () => {
    const { assetsSum, totalRelevantFunds } = this.state;
    const difference = (
      this.getNumber(assetsSum) - this.getNumber(totalRelevantFunds)
    ).toFixed(2);
    this.setState({ difference: Utils.getNumberWithSpace(difference) }, () => {
      this.calcFundsInTransit();
    });
  };

  calcTotalEur = () => {
    const {
      deposit: { visaDeposit, mcDeposit },
    } = this.props;

    const totalVisaDepositEur = this.getSumWithRates(visaDeposit);
    const totalMcDepositEur = this.getSumWithRates(mcDeposit);

    const totalDepositEur = (
      this.getNumber(totalVisaDepositEur) + this.getNumber(totalMcDepositEur)
    ).toFixed(2);
    this.setState({
      totalDepositEur: Utils.getNumberWithSpace(totalDepositEur),
    });
  };

  calcFundsInTransit = () => {
    const { difference, totalDepositEur } = this.state;
    const fundsInTransit = (
      this.getNumber(difference) + this.getNumber(totalDepositEur)
    ).toFixed(2);
    this.setState({
      fundsInTransit: Utils.getNumberWithSpace(fundsInTransit),
    });
  };

  calcDeposit = () => {
    const {
      relevantFundsRate,
      deposit: { mcDeposit, visaDeposit },
    } = this.props;
    const visaDepositRated = {};
    const mcDepositRated = {};

    currency.forEach((cur) => {
      const relevantFundsRateValue = relevantFundsRate[cur];
      mcDepositRated[cur] = (
        this.getNumber(mcDeposit[cur]) / this.getNumber(relevantFundsRateValue)
      ).toFixed(2);
      visaDepositRated[cur] = (
        this.getNumber(visaDeposit[cur]) /
        this.getNumber(relevantFundsRateValue)
      ).toFixed(2);
    });
    this.setState({ visaDepositRated, mcDepositRated }, () => {
      this.calcTotalEur();
    });
  };
}

export default addPermissions(Reconciliation);
