export enum MafOp {
  view,
  insert,
  update,
  delete,
}

export enum MafServerStatus {
  received = 'received',
  failed = 'failed',
  error = 'error',
}
