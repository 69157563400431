import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import api from 'api/user';
import { addListeners, IListeners } from 'decorators/addListeners';
import checkIsMafUser from 'decorators/checkIsMafUser';
import { WithRouterProps } from 'decorators/withRouter';
import ProfileActivity from './ProfileActivity';
import Activity from './ActivityType';
import Messages from 'constants/rpcTypes';
import { logoutAll } from 'actions/user';
import path from 'helpers/path';
import MetricService from 'helpers/metricService/MetricService';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import { StoreProps } from 'store';

interface ConnectedProps {
  isMenuExpanded: boolean;
}

interface OwnProps {
  customClass: string;
}

interface State {
  offset: number;
  moreRows: boolean;
  list: Activity[];
  isLoading: boolean;
}

type Props = ConnectedProps & StoreProps & WithRouterProps & OwnProps;

@checkIsMafUser
@addListeners([Messages.UserActivity_CurrentUser])
class ProfileActivityContainer
  extends Component<Props, State>
  implements IListeners
{
  constructor(props: Props) {
    super(props);

    this.state = {
      offset: 0,
      list: [],
      isLoading: false,
      moreRows: false,
    };
  }

  async componentDidMount() {
    await this.fetch();
  }

  render() {
    const { list, isLoading, moreRows } = this.state;

    return (
      <ProfileActivity
        list={list}
        isLoading={isLoading}
        moreRows={moreRows}
        onClick={() => this.fetch()}
        terminateSessions={() => this.terminateSessions()}
        customClass={this.props.customClass}
      />
    );
  }

  async fetch() {
    const { offset } = this.state;
    this.setState({
      isLoading: true,
    });
    try {
      await api.getActivity(offset);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  terminateSessions = async () => {
    if (isNotAvailableForSupport(Messages.Auth_LogoutFromAll)) return;

    const { dispatch, history } = this.props;

    try {
      MetricService.send({
        action: 'click',
        actionKey: 'userProfile.completeSessions.button ',
      });

      await api.logoutAll();
      history.push(path('/login'), {});
      dispatch(logoutAll());
    } catch (e) {
      console.error('Logout_All error');
    }
  };

  onEvent = ({ data }) => {
    const { offset, list } = this.state;
    const { activities, moreRows } = data.payload;

    this.setState({
      moreRows,
      list: list.concat(activities),
      offset: offset + 5,
    });
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  isMenuExpanded: state.user.config?.isMenuExpanded,
});

export default withRouter(connect(mapStateToProps)(ProfileActivityContainer));
