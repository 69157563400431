import React, { PureComponent } from 'react';
import Input from 'components/ui/input';
import InputProps from 'components/ui/input/InputProps';
import Utils from 'helpers/Utils';

class InputAmount extends PureComponent<InputProps> {
  render() {
    return <Input {...this.props} onChange={this.handleChange} />;
  }

  handleChange = (event) => {
    const { onChange } = this.props;
    const value = event.target.value.replace(/ /gi, '');

    const caret = event.target.selectionStart;
    const element = event.target;

    window.requestAnimationFrame(() => {
      if (value.length > caret) {
        element.selectionStart = caret;
        element.selectionEnd = caret;
      }
    });

    // eslint-disable-next-line unicorn/no-unsafe-regex
    if (/(^-?\d+(\.[0-9]{0,2})?$)/.test(value) || ['', '-'].includes(value)) {
      event.target.value = Utils.getNumberWithSpace(value);
      onChange(event);
    }
  };
}

export default InputAmount;
