import React, { PureComponent, ReactNode, useCallback } from 'react';
import classNames from 'classnames';
import { throttle } from 'lodash';
import Animation from 'components/ui/animation';
import Icon from 'components/ui/icon';
import RoundButton from 'components/ui/roundButton';
import Utils from 'helpers/Utils';
import { AnyObject } from 'types/Common';
import { APP_PADDING, MENU_OPENED_WIDTH, MENU_WIDTH } from 'constants/ui';
import breakpoints from 'constants/breakpoints';
import { BLOCK_PADDING } from 'components/ui/inputDateRange/constants';
import './panel.scss';

interface Props {
  isOpened: boolean;
  onClose: (e?) => void;
  title?: ReactNode | string;
  id?: string;
  renderCloseControl?: boolean;
  excludeFromCloseTrigger?: string[];
  animationNameShow?: string;
  animationNameHide?: string;
  animationDuration?: number;
  customClass?: string;
  contentStyle?: AnyObject;
  containerStyle?: AnyObject;
  modern?: boolean;
  panelRef?: { current: any };
  containerRef?: any;
  isMenuExpanded?: boolean;
  closeOnScroll?: boolean;
  relativeMenu?: boolean;
}

interface State {
  canRender: boolean;
  animationName?: string;
  coords: { top: string; left: string };
}

class Panel extends PureComponent<Props, State> {
  private timerId;
  private readonly panelId;

  static defaultProps = {
    animationNameShow: 'fadeInUp',
    animationNameHide: 'fadeOutDown',
    animationDuration: 100,
    renderCloseControl: true,
    customClass: '',
    contentStyle: {},
    onClose: () => null,
  };

  constructor(props) {
    super(props);

    this.state = {
      animationName: props.animationNameShow,
      canRender: props.isOpened,
      coords: {
        top: '',
        left: '',
      },
    };

    this.panelId = Utils.getHash();
    this.handleResize = throttle(this.handleResize, 1000);
  }

  componentDidMount() {
    if (this.props.isOpened) {
      this.changeRenderPanel();
    }
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const { isOpened } = this.props;
    if (prevProps.isOpened !== isOpened) {
      this.changeRenderPanel();
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.timerId);
    this.detachEvents();
  }

  render() {
    const {
      title,
      animationDuration,
      children,
      id,
      customClass,
      contentStyle,
      containerStyle,
      modern,
    } = this.props;
    const { canRender, animationName, coords } = this.state;

    if (!canRender) return null;

    return (
      <div
        style={containerStyle || coords}
        id={id}
        className={classNames('ui-panel', customClass, {
          'ui-panel_modern': modern,
        })}
        data-panel-id={this.panelId}
        ref={this.props.panelRef}>
        <Animation name={animationName} duration={animationDuration}>
          <div className='ui-panel__inner' style={contentStyle}>
            {this.renderCloseButton()}
            {title && <div className='ui-panel__header'>{title}</div>}
            <div className='ui-panel__content'>{children}</div>
          </div>
        </Animation>
      </div>
    );
  }

  renderCloseButton = () => {
    const { renderCloseControl, modern, onClose } = this.props;
    if (!renderCloseControl) return null;

    if (modern) {
      return (
        <RoundButton
          size={30}
          icon='im-Close'
          iconSize={10}
          customClass='ui-panel__close'
          onClick={onClose}
        />
      );
    }
    return (
      <button type='button' className='ui-panel__close' onClick={onClose}>
        <Icon name='im-Close' size={11} />
      </button>
    );
  };

  attachEvents = () => {
    document.addEventListener('mousedown', this.closePanel);
    if (this.props.closeOnScroll) {
      document.body
        .querySelector(`#${this.props.id}`)
        ?.closest('.ui-scrollbar')
        ?.addEventListener('wheel', this.props.onClose);
    }
  };

  detachEvents = () => {
    document.removeEventListener('mousedown', this.closePanel);
    if (this.props.closeOnScroll) {
      document.body
        .querySelector(`#${this.props.id}`)
        ?.closest('.ui-scrollbar')
        ?.removeEventListener('wheel', this.props.onClose);
    }
  };

  changeRenderPanel() {
    const {
      isOpened,
      animationDuration,
      animationNameShow,
      animationNameHide,
    } = this.props;

    const timeoutMs = (animationDuration || 0) - 100;

    if (isOpened) {
      this.setState(
        {
          animationName: animationNameShow,
          canRender: true,
        },
        this.getCoords
      );

      this.attachEvents();
    } else {
      this.setState({
        animationName: animationNameHide,
      });
      this.timerId = setTimeout(() => {
        this.setState({
          canRender: false,
        });
      }, timeoutMs);
      this.detachEvents();
    }
  }

  closePanel = (e) => {
    const { excludeFromCloseTrigger, onClose } = this.props;

    if (excludeFromCloseTrigger) {
      const targetInExclude = excludeFromCloseTrigger.find((selector) =>
        e.target.closest(selector)
      );
      if (targetInExclude) {
        return false;
      }
    }

    if (
      !e.target.closest(`[data-panel-id='${this.panelId}']`) &&
      !e.target.closest('.Toastify')
    ) {
      onClose(e);
    }
  };

  getCoords = () => {
    const { containerRef, id, relativeMenu } = this.props;
    const panelEl = document.body.querySelector(`#${id}`);
    if ((!containerRef || !id || !panelEl) && !relativeMenu) {
      this.setState({
        coords: { left: '', top: '' },
      });
      return;
    }

    const menuWidth = this.props.isMenuExpanded
      ? MENU_OPENED_WIDTH
      : MENU_WIDTH;

    if (!containerRef && relativeMenu) {
      const minLeftPosition =
        window.innerWidth > breakpoints.panels
          ? `${menuWidth + APP_PADDING}px`
          : 'unset';
      this.setState({ coords: { left: minLeftPosition, top: '' } });
      return;
    }

    const containerRefPosition = containerRef.current.getBoundingClientRect();
    const minLeftPosition =
      window.innerWidth > breakpoints.commonTablet
        ? menuWidth + APP_PADDING
        : containerRefPosition.left - BLOCK_PADDING;
    let topPosition = 'unset';
    const contentHeight = panelEl?.querySelector('div')?.clientHeight;
    const appLayout = document.querySelector('.layout-app__inner');
    if (appLayout && contentHeight) {
      const layoutHeight = appLayout.getBoundingClientRect().height;
      topPosition =
        contentHeight < layoutHeight - containerRefPosition.bottom
          ? containerRefPosition.bottom.toFixed()
          : (containerRefPosition.top - contentHeight).toFixed();
    }
    this.setState({
      coords: { left: `${minLeftPosition}px`, top: `${topPosition}px` },
    });
  };

  handleResize = () => {
    this.getCoords();
  };
}

export default Panel;
