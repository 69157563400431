import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Input from 'components/ui/input';
import SelectionList from 'components/ui/selectionList';
import CustomSelect from 'components/ui/customSelect';
import TextBlock from 'components/ui/textBlock';
import { AnyObject } from 'types/Common';
import reportTypes from '../../reportTypes';
import './builderGeneral.scss';

interface Props extends IntlProps {
  fields: AnyObject;
  availableReportTypes: AnyObject[];
  validationErrors: AnyObject;
  onChangeField: (key: string, value: any) => void;
}

class BuilderGeneral extends PureComponent<Props> {
  render() {
    const {
      fields,
      availableReportTypes,
      validationErrors,
      onChangeField,
      getTranslate,
    } = this.props;

    return (
      <div className='templates-builder-general card'>
        <div className='card__header'>
          <div className='card__title'>
            {getTranslate('reports.new.general.label')}
          </div>
        </div>
        <div className='card__content card__container'>
          <Input
            isRow={true}
            label='reports.new.general.name.label'
            placeholder={getTranslate('reports.new.general.name.label')}
            id='name'
            value={fields.reportName}
            maxLength={60}
            error={validationErrors.name}
            onChange={({ target }) => onChangeField('reportName', target.value)}
            customClass='templates-builder-general__item'
          />
          <div className='templates-builder-general__item templates-builder-general__item_row grid-row'>
            <Input
              isRow={true}
              label='reports.new.general.id.label'
              placeholder=''
              id='templates-builder-id'
              disabled={true}
              value={fields.reportId}
              error={validationErrors.reportId}
              onChange={({ target }) => onChangeField('reportId', target.value)}
              customClass='templates-builder-general__item templates-builder-general__item_id'
            />
            <CustomSelect
              id='type'
              isRow={true}
              label='reports.new.general.type.label'
              options={availableReportTypes.map(({ id }) =>
                this.createOption(id)
              )}
              value={{
                value: fields.reportType,
                label: getTranslate(
                  `reports.new.general.type.values.${fields.reportType}`
                ),
              }}
              onChange={({ value }) => onChangeField('reportType', value)}
              customClass='ui-select_large templates-builder-general__item templates-builder-general__item_report-type'
            />
          </div>
          <SelectionList
            id='project_id'
            isRow={true}
            label='reports.new.general.projects.label'
            items={fields.projects}
            isFullMode={true}
            error={validationErrors.template?.filter?.project_id}
            onChange={(items) => onChangeField('projects', items)}
            customClass='templates-builder-general__item'
          />
          {fields.reportType === reportTypes.interchange && (
            <div className='templates-builder-general__note utils-ui-block__row grid-row'>
              <TextBlock
                text={getTranslate('reports.new.general.InfoIC.text')}
                theme='bordered'
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  createOption(id) {
    const { getTranslate } = this.props;
    return {
      value: id,
      label: getTranslate(`reports.new.general.type.values.${id}`),
    };
  }
}

export default addTranslation(BuilderGeneral);
