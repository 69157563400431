import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import { changeDataForceUpdate } from 'actions/getData';
import { bulkCreate } from 'api/risks';
import showNotification from 'components/ui/notification/showNotification';
import CustomScrollbar from 'components/ui/customScrollbar';
import WbMulti from './WbMulti';
import MetricService from 'helpers/metricService/MetricService';
import Messages from 'constants/rpcTypes';
import tableNames from 'constants/tableNames';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import { StoreProps } from 'store';

interface OwnProps {
  onCloseForm: () => void;
}

type Props = OwnProps & IntlProps & StoreProps;

interface State {
  file: File | null;
  isCreating: boolean;
  errorMessage: string;
}

class WbMultiContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      file: null,
      isCreating: false,
      errorMessage: '',
    };
  }

  render() {
    const { onCloseForm } = this.props;
    const { file, isCreating, errorMessage } = this.state;

    return (
      <WbMulti
        file={file}
        isCreating={isCreating}
        errorMessage={errorMessage}
        onUpload={this.uploadFile}
        onDelete={this.deleteFile}
        onCreate={() => this.create()}
        onCancel={onCloseForm}
      />
    );
  }

  uploadFile = (file) => {
    this.setState({ file, errorMessage: '' });
  };

  deleteFile = () => {
    this.setState({ file: null, errorMessage: '' });
  };

  async create() {
    const { getTranslate } = this.props;
    const { file } = this.state;

    if (isNotAvailableForSupport(Messages.Rcs_BulkCreateElement)) return false;

    this.setState({ isCreating: true });
    try {
      const data = await bulkCreate(file);
      this.checkCreateResponse(data);
    } catch ({ payload }) {
      if (payload?.validationErrors) {
        this.setState({
          errorMessage: getTranslate(payload.validationErrors.file),
        });
      }
    } finally {
      this.setState({
        isCreating: false,
      });
    }
  }

  checkCreateResponse(data: { failed: any[]; success: any[] }) {
    const { onCloseForm, dispatch, getTranslate } = this.props;
    if (data.failed.length === 0) {
      onCloseForm();
      // waiting form to close
      setTimeout(() => {
        showNotification({
          status: 'info',
          content: getTranslate('common.successfulAdding.sysmsg'),
        });
        dispatch(changeDataForceUpdate(tableNames.rcs, true));

        MetricService.send({
          action: 'click',
          actionKey: 'risks.blackWhiteLists.manage.apply',
        });
      }, 300);
    } else if (data.success.length === 0) {
      showNotification({
        status: 'error',
        content: getTranslate('common.failAdding.sysmsg'),
      });
    } else {
      const errorContent: ReactNode[] = [];
      data.failed.forEach((err, index) => {
        const {
          merchantId,
          projectId: merchantProjectId,
          element,
          contentType,
          listType,
        } = err;
        if (merchantId && contentType === 'ip') {
          return errorContent.push(
            <div key={index} className='wb-multi-form__notification-error-item'>
              {getTranslate('common.partiallyfailAddingMerchant.sysmsg', {
                contentType,
                listType,
                merchantId,
                element,
              })}
            </div>
          );
        } else if (merchantProjectId) {
          return errorContent.push(
            <div key={index} className='wb-multi-form__notification-error-item'>
              {getTranslate('common.partiallyfailAddingProject.sysmsg', {
                contentType,
                listType,
                element,
                merchantProjectId,
              })}
            </div>
          );
        }
      });

      if (errorContent.length === 0) return;

      showNotification({
        status: 'error',
        content: (
          <div className='wb-multi-form__notification-error'>
            <CustomScrollbar hideHorizontal={true}>
              <div className='wb-multi-form__notification-error-list'>
                {errorContent}
              </div>
            </CustomScrollbar>
          </div>
        ),
      });
    }
  }
}

export default connect()(addTranslation(WbMultiContainer));
