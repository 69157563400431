import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';
import { AnyObject } from 'types/Common';

interface ActionParams {
  transactionId: number;
  data: AnyObject;
}

export const updateActions = ({ transactionId, data }: ActionParams) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPDATE_PAYMENT_ACTIONS,
        payload: {
          transactionId,
          data,
        },
      })
    );
  };
};
