import BaseMessageHandler from '../BaseMessageHandler';
import { createAction } from '../../helpers/redux';
import ACTIONS from '../../constants/actionTypes';

export default class Payments_SummaryDataWs extends BaseMessageHandler {
  success = () => {
    const { dispatch } = this;
    const { payload } = this.message;

    if (payload.error) {
      dispatch(
        createAction({
          type: ACTIONS.FETCH_PAYMENT_SUMMARY_DATA_FAIL,
          payload: {
            message: payload.error.message,
            onlyInfo: payload.error.onlyInfo || false,
            rpcId: payload.error.rpcId,
          },
        })
      );
    } else {
      const action = createAction({
        type: ACTIONS.FETCH_PAYMENT_SUMMARY_DATA_SUCCESS,
        payload: {
          ...payload,
        },
      });
      dispatch(action);
    }
  };
}
