import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { addPermissions } from 'decorators/addPermissions';
import { addListeners } from 'decorators/addListeners';
import Callbacks from './Callbacks';
import tableNames from 'constants/tableNames';
import Messages from 'constants/rpcTypes';
import api from 'api/projects';
import landingState from 'constants/landingState';
import { ProjectSetting } from './types';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import permissionReasons from 'constants/permissionReasons';
import showNotification from 'components/ui/notification/showNotification';
import getLocalizedText from 'helpers/getLocalizedText';
import { AnyObject } from 'types/Common';
import { StoreProps } from 'store';
import { openModal, closeModal } from 'actions/modal';

interface ConnectedProps {
  permissions: AnyObject[];
  projectId: string;
  isDemoUser: boolean;
  status: boolean;
}

type Props = ConnectedProps & StoreProps;

interface State {
  list: ProjectSetting[];
  isLoading: boolean;
  isOverLimit: boolean;
}

@addListeners([
  Messages.ProjectCallbackSettings_Delete,
  Messages.ProjectCallbackSettings_Create,
])
class CallbacksContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      list: [],
      isLoading: false,
      isOverLimit: false,
    };
  }

  componentDidMount() {
    this.getTableDataList();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.projectId !== this.props.projectId) {
      this.getTableDataList();
    }
  }

  render() {
    const { projectId, status } = this.props;
    const { list, isLoading, isOverLimit } = this.state;

    return (
      <Callbacks
        list={list}
        isLoading={isLoading}
        projectId={projectId}
        status={status}
        isOverLimit={isOverLimit}
        tableName={tableNames.projectCallbacks}
        updateProjectSetting={this.updateProjectSetting}
        deleteCallback={this.deleteCallback}
        isNotAvailable={this.isNotAvailable}
        onOpenForm={this.openFormModal}
      />
    );
  }

  updateProjectSettingsList = (projectSetting: ProjectSetting) => {
    this.setState((prevState) => ({
      list: prevState.list.concat(projectSetting),
    }));
  };

  updateProjectSetting = async ({ id, status }) => {
    if (this.isNotAvailable(Messages.ProjectCallbackSettings_Update)) return;

    try {
      const { list } = this.state;
      const callback = list.find((c) => c.id === id);
      if (!callback) {
        return;
      }
      this.setState({ isLoading: true });
      const result = await api.updateProjectCallbackSettings({
        projectId: this.props.projectId,
        callbackId: id,
        transactionType: callback.transactionType,
        url: callback.url,
        resultType: callback.resultType,
        status,
        paymentMethodId: callback.paymentMethodId,
      });
      this.setState((prevState) => ({
        list: prevState.list.map((item) => (item.id === id ? result : item)),
      }));
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  isNotAvailable = (message) => {
    const { permissions } = this.props;

    if (isNotAvailableForSupport(message)) return true;

    if (
      permissions[message].status === 'disabled' &&
      permissions[message].reason ===
        permissionReasons.REASON_NOT_ENOUGH_PERMISSIONS
    ) {
      showNotification({
        status: 'error',
        content: getLocalizedText('common.notAvaliableAction.sysmsg'),
      });
      return true;
    }
    return false;
  };

  getTableDataList = async () => {
    const { projectId, isDemoUser } = this.props;
    try {
      this.setState({ isLoading: true });
      const { list, limit } = await api.getProjectCallbackSettingList({
        projectId,
      });
      this.setState({ list, isOverLimit: isDemoUser && limit <= list.length });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  deleteCallback = async (callbackId: string) => {
    if (this.isNotAvailable(Messages.ProjectCallbackSettings_Delete)) return;

    await api.deleteProjectCallbackSettings({
      callbackId,
      projectId: this.props.projectId,
    });
  };

  openFormModal = () => {
    this.props.dispatch(
      openModal({
        modalId: 'CreateCallbackForm',
        tooltipId: 'modal',
        content: { isNotAvailable: this.isNotAvailable },
      })
    );
  };

  onEvent = ({ name, data }) => {
    const { list } = this.state;
    if (name === Messages.ProjectCallbackSettings_Create) {
      if (data.payload.validationErrors) return;
      this.updateProjectSettingsList(data.payload);
      this.props.dispatch(closeModal());
      return;
    }
    const updatedList = list.filter((item) => item.id !== data.payload.id);
    this.setState({ list: updatedList });
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  permissions: state.permissions,
  projectId: state.projects.project.projectId,
  isDemoUser: state.user.landingState === landingState.demoStage,
  status: state.projects.project.status,
});

export default connect(mapStateToProps)(addPermissions(CallbacksContainer));
