import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import api from 'api/lcManage';
import LcManageHelpers from 'pages/lcmanage/LcManageHelpers';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import Form from 'components/ui/form';
import Loader from 'components/ui/loader';
import { StoreProps } from 'store';

import ModalTitle from '../components/ModalTitle';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';

interface OwnProps {
  callback: ({ data }) => void;
  content: {
    data: any;
  };
}

interface ConnectedProps {
  user: any;
}

type Props = OwnProps & ConnectedProps & StoreProps;

interface State {
  form: {
    ticket: string;
    token: string;
    en: string;
    ru: string;
  };
  isLoading: boolean;
}

class EditLocale extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      form: this.getForm(),
      isLoading: false,
    };
  }

  render() {
    const {
      content: { data },
    } = this.props;
    const { isLoading, form } = this.state;
    const { ticket, token, en, ru } = form;
    const editableToken = data.editable_token;
    const editableTicket = data.editable_ticket_number;

    return (
      <Fragment>
        <ModalTitle>Edit</ModalTitle>
        <ModalContent>
          <Form onSubmit={() => this.sendForm()}>
            <Input
              autoFocus
              id='lc-ticket'
              label='Ticket'
              value={ticket}
              placeholder=''
              disabled={!editableTicket}
              onChange={({ target: { value } }) =>
                this.changeForm('ticket', value)
              }
            />
            <br />
            <Input
              id='lc-token'
              label='Token'
              value={token}
              placeholder=''
              disabled={!editableToken}
              onChange={({ target: { value } }) =>
                this.changeForm('token', value)
              }
            />
            <br />
            <Input
              id='lc-en'
              label='En'
              value={en}
              placeholder=''
              onChange={({ target: { value } }) => this.changeForm('en', value)}
            />
            <br />
            <Input
              id='lc-ru'
              label='Ru'
              value={ru}
              placeholder=''
              onChange={({ target: { value } }) => this.changeForm('ru', value)}
            />
          </Form>
        </ModalContent>
        <ModalFooter>
          <Button
            text='Apply'
            status='success'
            onClick={() => this.sendForm()}
          />
        </ModalFooter>
        {isLoading && <Loader />}
      </Fragment>
    );
  }

  changeForm = (key, value) => {
    this.setState((state) => {
      return {
        form: {
          ...state.form,
          [key]: value,
        },
      };
    });
  };

  async sendForm() {
    const {
      callback,
      content: { data },
    } = this.props;
    const { form } = this.state;
    const { ticket, token, en, ru } = form;

    let id;

    const template = LcManageHelpers.getTemplate(data);
    if (template) {
      id = template.id;
    }

    this.setState({ isLoading: true });

    try {
      const editedRow = await api.edit({
        id,
        token,
        ticket_number: ticket,
        texts: [
          { lang: 'en', text: en },
          { lang: 'ru', text: ru },
        ],
      });
      editedRow.customId = data.customId;
      return callback({ data: editedRow });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  getForm() {
    const {
      content: { data },
    } = this.props;
    const template = LcManageHelpers.getTemplate(data);
    if (!template) {
      return EditLocale.getInitialForm();
    }

    const locale = {
      en: '',
      ru: '',
    };

    template.texts.forEach((item) => {
      if (item.text) {
        locale[item.lang] = item.text;
      }
    });

    return {
      ...locale,
      ticket: data.ticket_number || '',
      token: data.token || '',
    };
  }

  static getInitialForm() {
    return {
      ticket: '',
      token: '',
      en: '',
      ru: '',
    };
  }
}

const mapStateToProps = (state): ConnectedProps => ({ user: state.user });

export default connect(mapStateToProps)(EditLocale);
