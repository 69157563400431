import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

const initialState: any = {};

const ui = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.SAVE_UI: {
      const { name, value } = payload;
      return {
        ...state,
        [name]: value,
      };
    }
    case ACTIONS.RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

export default ui;
