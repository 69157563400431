import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 * Запрос на активацию бота
 * @param tgmId
 */
export const activateBot = (tgmId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Profile_TelegramConfirmInit,
        payload: { tgmId },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Деактивация бота
 * @param tgmId
 */
export const deactivateBot = (tgmId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Profile_TelegramDeactivate,
        payload: { tgmId },
      },
    ],
  });
};

/**
 * Подтверждение активации бота
 * @param tgmId
 * @param publicKey
 */
export const confirmVerify = ({ tgmId, publicKey }) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Profile_TelegramConfirmVerify,
        payload: { tgmId, publicKey },
      },
    ],
    showValidateNotification: false,
  });
};
