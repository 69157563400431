import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import './textBlock.scss';

interface Props {
  text: string;
  iconEnabled?: boolean;
  status?: string;
  theme?: 'bordered' | 'grey';
}

class TextBlock extends PureComponent<Props> {
  static defaultProps = {
    status: 'info',
  };
  render() {
    const { text, status, theme, iconEnabled } = this.props;
    return (
      <div
        className={classNames(`ui-text-block`, {
          [`ui-text-block_${status}`]: !!status,
          [`ui-text-block_${theme}`]: !!theme,
        })}>
        {iconEnabled && (
          <span className='ui-text-block__icon'>
            <Icon
              name={status === 'success' ? 'im-Tick' : 'im-Error'}
              size={status === 'success' ? 10 : 18}
            />
          </span>
        )}

        <span className='ui-text-block__text'>{text}</span>
      </div>
    );
  }
}
export default TextBlock;
