import tableNames from 'constants/tableNames';

export const tableRoutes: {
  [tableNames.remittancePayments]: string;
  [tableNames.massRemittancePayments]: string;
  [tableNames.remittanceRecipientAccounts]: string;
} = {
  [tableNames.remittancePayments]: '',
  [tableNames.massRemittancePayments]: 'mass',
  [tableNames.remittanceRecipientAccounts]: 'recipient-accounts',
};

export const createRoutes = {
  [tableNames.remittancePayments]: 'create-remittance',
  [tableNames.massRemittancePayments]: 'create-remittance',
  [tableNames.remittanceRecipientAccounts]: 'create-recipient-account',
};

export const formIdRoutes = {
  single: 'single',
  mass: 'mass',
};
