import React, { PureComponent } from 'react';
import Tooltip from 'react-tooltip';
import { isEmpty, isEqual } from 'lodash';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Input from 'components/ui/input';
import CustomSelect from 'components/ui/customSelect';
import Switch from 'components/ui/switch';
import Button from 'components/ui/button';
import RoundButton from 'components/ui/roundButton';
import NoContent from 'components/ui/noContent';
import Utils from 'helpers/Utils';
import { HistoryActionsTypes, Redirect, UrlType } from '../../types';
import { AnyObject } from 'types/Common';
import {
  DictionaryCustomSelect,
  DictionaryMultiSelect,
} from 'types/FilterValue';
import SelectItem from 'types/SelectItem';
import { getTooltip } from 'helpers/getTooltip';

interface Props extends IntlProps {
  redirectHistory: AnyObject;
  status: boolean;

  redirectSuccess: Redirect;
  redirectFail: Redirect;
  redirectTokenize: Redirect;
  merchantBackSuccess: Redirect;
  merchantBackFail: Redirect;

  paymentPageMerchantReturnState: DictionaryCustomSelect;
  redirectUrlMode: DictionaryMultiSelect;
  currentProjectId: string;
  validationErrors: AnyObject;
  canSaveRedirectSettings: boolean;
  editPermission: boolean;

  saveRedirectSettings: () => void;
  onChangeField: (type, name, value: any) => void;
  getHistory: (id, action) => void;
}

interface State {
  refList: Record<string, any>;
  tooltips: Record<string, string>;
}
const redirects = [
  'redirectSuccess',
  'redirectFail',
  'redirectTokenize',
  'merchantBackSuccess',
  'merchantBackFail',
];
class Redirects extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      refList: {},
      tooltips: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.tooltips, this.state.tooltips)) {
      Tooltip.rebuild();
    }
    redirects.forEach((item) => {
      if (item === 'merchantBackSuccess' || item === 'merchantBackFail') {
        if (
          this.props[item].enabled !== prevProps[item].enabled ||
          prevState.refList[item] !== this.state.refList[item]
        ) {
          this.setTooltip(item);
        }
        return;
      }
      if (
        this.props[item].mode !== prevProps[item].mode ||
        prevState.refList[item] !== this.state.refList[item]
      ) {
        this.setTooltip(item);
      }
    });
  }

  render() {
    const {
      redirectSuccess,
      redirectFail,
      redirectTokenize,
      merchantBackSuccess,
      merchantBackFail,
      saveRedirectSettings,
      canSaveRedirectSettings,
      getTranslate,
    } = this.props;

    if (
      isEmpty(redirectSuccess) ||
      isEmpty(redirectFail) ||
      isEmpty(redirectTokenize) ||
      isEmpty(merchantBackSuccess) ||
      isEmpty(merchantBackFail)
    )
      return (
        <div className='page-projects__no-content'>
          <NoContent />
        </div>
      );
    return (
      <div className='page-projects__block page-projects__block_redirects'>
        <div className='card card_l'>
          <div className='card__header page-projects__block-title'>
            {getTranslate('projects.urls.customized.title')}
          </div>
          <div className='card__content card__container'>
            <div className='page-projects__block-text'>
              {getTranslate('projects.urls.customized.hint')}
            </div>
            {this.renderRedirectRow(
              'redirectSuccess',
              HistoryActionsTypes.success
            )}
            {this.renderRedirectRow(
              'redirectFail',
              HistoryActionsTypes.decilned
            )}
            {this.renderRedirectRow(
              'redirectTokenize',
              HistoryActionsTypes.token
            )}
          </div>
        </div>
        <div className='card card_l'>
          <div className='card__header page-projects__block-title'>
            {getTranslate('projects.urls.return.title')}
          </div>
          <div className='card__content card__container'>
            <div className='page-projects__block-text'>
              {getTranslate('projects.urls.return.hint')}
            </div>
            {this.renderRedirectRow(
              'merchantBackSuccess',
              HistoryActionsTypes.successReturn
            )}
            {this.renderRedirectRow(
              'merchantBackFail',
              HistoryActionsTypes.decilneReturn
            )}
          </div>
        </div>
        <div className='card'>
          <div className='page-projects__block-footer'>
            <Button
              text={getTranslate('projects.urls.save.button')}
              status='primary'
              onClick={saveRedirectSettings}
              disabled={this.canEdit() ? !canSaveRedirectSettings : true}
            />
          </div>
        </div>
      </div>
    );
  }

  renderRedirectRow = (type: UrlType, action) => {
    const {
      onChangeField,
      getHistory,
      currentProjectId,
      validationErrors,
      getTranslate,
      paymentPageMerchantReturnState,
    } = this.props;
    const section = this.props[type];
    const selectedMode = this.setModeOptions().find(
      (option) => +option.value === section.mode
    );
    const returnState: SelectItem | null =
      paymentPageMerchantReturnState?.list.find(
        (item) => item.value === section.enabled
      ) || null;

    return (
      <div className='page-projects__row page-projects__row_redirect'>
        <div className='page-projects__block-key'>
          {getTranslate(`projects.urls.customized.${section.key}.label`)}
        </div>
        <div className='page-projects__block-value'>
          <Input
            id={`url-${section.key}`}
            value={section.url}
            placeholder={getTranslate(
              `projects.urls.customized.redirectSuccess.placeholder`
            )}
            onChange={(e) => {
              onChangeField(type, 'url', e.target.value);
            }}
            customClass='page-projects__block-value-item page-projects__block-value-item_url'
            disabled={!this.canEdit()}
            error={validationErrors[type]?.url}
          />
          {Utils.hasProp(section, 'mode') && (
            <div
              ref={(el) => {
                this.setRef(section.key, el);
              }}
              data-tip={this.state.tooltips[section.key]}
              className='page-projects__block-value-item page-projects__block-value-item_mode'>
              <CustomSelect
                options={this.setModeOptions()}
                value={selectedMode}
                onChange={({ value }) => {
                  Tooltip.hide();
                  onChangeField(type, 'mode', value);
                }}
                customClass='ui-select_large'
                isDisabled={!this.canEdit()}
              />
            </div>
          )}
          {type === 'merchantBackSuccess' || type === 'merchantBackFail' ? (
            <div
              ref={(el) => {
                this.setRef(section.key, el);
              }}
              className='page-projects__left page-projects__block-value-item page-projects__block-value-item_return'
              data-tip={this.state.tooltips[section.key]}>
              <CustomSelect
                id={`paymentPageMerchantReturnState-${type}`}
                options={paymentPageMerchantReturnState.list}
                value={returnState}
                onChange={({ value }) => {
                  Tooltip.hide();
                  onChangeField(type, 'enabled', value);
                }}
                isLoading={paymentPageMerchantReturnState.isLoading}
                customClass='ui-select_large'
                isDisabled={!this.canEdit()}
              />
            </div>
          ) : (
            <Switch
              id={`redirectSettings-enabled-${section.url}`}
              checked={!!section.enabled}
              onChange={(e) => {
                onChangeField(type, 'enabled', e.target.checked ? 1 : 0);
              }}
              customClass='page-projects__block-value-item page-projects__switch page-projects__right'
              disabled={!this.canEdit()}
            />
          )}

          <RoundButton
            icon='im-Changes-history'
            iconSize={11}
            status='primary'
            customClass='page-projects__btn-history page-projects__block-value-item'
            onClick={() => {
              getHistory(currentProjectId, action);
            }}
          />
        </div>
      </div>
    );
  };

  getTooltip = (key) => {
    if (!this.state.refList[key]) {
      return '';
    }
    return getTooltip(
      this.state.refList[key].querySelector('.ui-select__control'),
      '.ui-select__single-value'
    );
  };

  setTooltip = (key) => {
    this.setState((state) => ({
      tooltips: { ...state.tooltips, [key]: this.getTooltip(key) },
    }));
  };

  setRef = (key, el) => {
    if (this.state.refList[key]) return;

    this.setState(
      (state) => ({
        refList: { ...state.refList, [key]: el },
      }),
      () => this.setTooltip(key)
    );
  };

  setModeOptions = () => {
    const { redirectUrlMode, getTranslate } = this.props;
    if (!redirectUrlMode?.list) {
      return [];
    }

    return redirectUrlMode.list.map(({ id, text }) => {
      return {
        value: id,
        label: getTranslate(text),
      };
    });
  };

  canEdit = () => {
    const { editPermission, status } = this.props;
    return editPermission && status;
  };
}

export default addTranslation(Redirects);
