import BaseMessageHandler from '../BaseMessageHandler';
import { addDataItem, updateDataItem } from 'actions/getData';
import Repository from 'helpers/Repository';
import tableNames from 'constants/tableNames';
import { updateEntity } from 'actions/savedEntities';
import savedEntities from 'constants/savedEntities';

export default class RemittanceQueue_StatusUpdated extends BaseMessageHandler {
  success = () => {
    const {
      dispatch,
      message: { payload },
    } = this;

    const store = Repository.get('store');
    const {
      data: { remittancePayments },
      savedEntities: { remittance },
    } = store.getState();

    if (remittancePayments.isFetched) {
      if (
        remittancePayments.items.find(
          ({ remittancePaymentQueueId }) =>
            remittancePaymentQueueId === payload.remittancePaymentQueueId
        )
      ) {
        dispatch(
          updateDataItem(
            tableNames.remittancePayments,
            'remittancePaymentQueueId',
            payload
          )
        );
      } else {
        dispatch(addDataItem(tableNames.remittancePayments, payload));
      }
    }

    remittance.items.forEach((entity) => {
      if (entity.id === payload.remittancePaymentBatchId?.toString()) {
        dispatch(
          updateEntity({
            entityKey: savedEntities.remittance,
            id: payload.remittancePaymentBatchId.toString(),
            fields: {
              data: {
                ...entity.data,
                rows: entity.data.rows.map((item) => {
                  if (
                    item.remittancePaymentQueueId ===
                    payload.remittancePaymentQueueId
                  ) {
                    return payload;
                  }
                  return item;
                }),
              },
            },
          })
        );
      }
    });
  };
}
