import {
  IDeclineCodesFormValues,
  IInOutFormValues,
  IPaymentsFormValues,
  TopTenFormValues,
} from '../../../types/Analytics';
import { ServiceSettingsFormHelpers } from '../../../components/modal/modalList/graphDetails/services/ServiceSettingsFormHelpers';
import { measureFieldsSerializer } from '../../analytics';
import { AdvancedAnalyticsEntityType } from '../../../components/modal/modalList/graphDetails/components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';

export const preparePaymentsRequest = (
  values: IPaymentsFormValues,
  chart: AdvancedAnalyticsEntityType = AdvancedAnalyticsEntityType.payments
) => {
  if (values.chartData === undefined) {
    throw new Error('Chart Data should not be empty');
  }

  return {
    filters: {
      paymentMethod:
        'paymentMethod' in values ? values.paymentMethod : undefined,
      paymentMethodId:
        'paymentMethodId' in values ? values.paymentMethodId : undefined,
      paymentType: 'paymentType' in values ? values.paymentType : undefined,
      paymentStatus:
        'paymentStatus' in values ? values.paymentStatus : undefined,
      currency: 'currency' in values ? values.currency : undefined,
      country: 'country' in values ? values.country : undefined,
      projectId:
        'projects' in values
          ? values.projects.map((i) => Number(i))
          : undefined,
      issuerCountry:
        'issuerCountry' in values ? values.issuerCountry : undefined,
    },
    chartParameters: {
      chartName: values.chartName,
      chart: chart,
      chartType: 'chartType' in values ? values.chartType : undefined,
      groupBy: 'groupBy' in values ? values.groupBy : undefined,
      groupByDate: 'groupByDate' in values ? values.groupByDate : undefined,
      period: ServiceSettingsFormHelpers.formatDate(values),
      ...measureFieldsSerializer({ chartData: values.chartData }),
    },
  };
};

export const prepareInOutRequest = (
  values: IInOutFormValues,
  chart: AdvancedAnalyticsEntityType = AdvancedAnalyticsEntityType.inout
) => {
  return {
    filters: {
      projectId: 'projects' in values && values.projects.map((i) => Number(i)),
      paymentMethod:
        'paymentMethod' in values ? values.paymentMethod : undefined,
      currency: 'currency' in values ? values.currency : undefined,
      type: 'type' in values ? values.type : undefined,
      issuerCountry: 'country' in values ? values.country : undefined,
    },
    chartParameters: {
      chartName: values.chartName,
      chartData: values.chartData,
      chart: chart,
      chartType: 'table',
      groupByDate: 'groupByDate' in values ? values.groupByDate : undefined,
      period: ServiceSettingsFormHelpers.formatDate(values),
      rows: 'groupByRows' in values ? values.groupByRows : undefined,
      columns: 'groupByColumns' in values ? values.groupByColumns : undefined,
    },
  };
};

export const prepareDeclineCodesRequest = (
  values: IDeclineCodesFormValues,
  id?: string
) => {
  const { chartName, chartData, chartType, dimension, type, projectId } =
    values;

  const conditionalFilters = {
    paymentMethod: 'paymentMethod' in values ? values.paymentMethod : undefined,
    issuerCountry: 'issuerCountry' in values ? values.issuerCountry : undefined,
  };

  return {
    filters: {
      projectId,
      type,
      ...conditionalFilters,
    },
    chartParameters: {
      id,
      chartName,
      chartType,
      dimension,
      chart: AdvancedAnalyticsEntityType.declineCodes,
      period: ServiceSettingsFormHelpers.formatDate(values),
      ...measureFieldsSerializer({ chartData }),
    },
  };
};

export const prepareTopTenRequest = (values: TopTenFormValues, id?: string) => {
  const {
    chartName,
    chartData,
    chartType,
    dimension,
    paymentMethod,
    issuerCountry,
    type,
    projectId,
  } = values;

  const conditionalFilters = {
    paymentMethod: dimension !== 'paymentMethod' ? paymentMethod : undefined,
    issuerCountry: dimension !== 'issuerCountry' ? issuerCountry : undefined,
  };

  return {
    filters: {
      projectId,
      type,
      ...conditionalFilters,
    },
    chartParameters: {
      id,
      chartName,
      chartType,
      dimension,
      chart: AdvancedAnalyticsEntityType.topten,
      period: ServiceSettingsFormHelpers.formatDate(values),
      ...measureFieldsSerializer({ chartData }),
    },
  };
};
