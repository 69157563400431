import React from 'react';
import { connect } from 'react-redux';
import DOMPurify from 'dompurify';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { getSupportTicketRequests, sendPaymentIssueReport } from 'api/payments';
import { closeModal, editOptions } from 'actions/modal';
import { StoreProps } from 'store';

import ModalTitle from 'components/modal/components/ModalTitle';
import ModalContent from 'components/modal/components/ModalContent';
import Animation from 'components/ui/animation';
import Button from 'components/ui/button';
import CustomSelect from 'components/ui/customSelect';
import Input from 'components/ui/input';
import Checkbox from 'components/ui/checkbox';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import MetricService from 'helpers/metricService/MetricService';

import SelectItem from 'types/SelectItem';
import Messages from 'constants/rpcTypes';

import './createSupportTicket.scss';

type IssueParams = {
  issueType?: string;
  type: string;
};

interface OwnProps {
  content: {
    operationId: string;
    transactionId: string;
  };
}

type Props = OwnProps & IntlProps & StoreProps;

interface State {
  requests: any[];
  isLoading: boolean;
  isConfirmed: boolean;
  selectedRequest: (SelectItem & IssueParams) | null;
  commentary: string;
  messages: string[];
  isReportSuccessful: boolean | undefined;
  validationErrors: Record<string, string> | null;
}

class CreateSupportTicket extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      requests: [],
      isLoading: false,
      isConfirmed: false,
      commentary: '',
      messages: [],
      selectedRequest: null,
      validationErrors: null,
      isReportSuccessful: undefined,
    };
  }

  componentDidMount() {
    this.loadRequests();
  }

  render() {
    const {
      requests,
      isLoading,
      commentary,
      isConfirmed,
      messages,
      selectedRequest,
      validationErrors,
      isReportSuccessful,
    } = this.state;
    const { getTranslate } = this.props;

    if (isReportSuccessful !== undefined) {
      const statusString = isReportSuccessful ? 'success' : 'error';
      return (
        <Animation>
          <div className='create-support-ticket create-support-ticket_submitted'>
            <ModalTitle>
              <img
                alt={statusString}
                src={require(`../../../../images/paymentSupportRequest/${statusString}.png`)}
                className={`create-support-ticket__pic create-support-ticket__pic_${statusString}`}
              />
              <h2>
                {getTranslate(
                  `payments.single.ticketToSupport.${statusString}.header`
                )}
              </h2>
            </ModalTitle>
            <ModalContent>
              <span className='create-support-ticket__text'>
                {getTranslate(
                  `payments.single.ticketToSupport.${statusString}.text`
                )}
              </span>
              <div className='create-support-ticket__footer create-support-ticket__footer_centered'>
                <Button
                  status='primary'
                  onClick={
                    isReportSuccessful ? this.closeModal : this.showCleanForm
                  }
                  text={getTranslate(
                    isReportSuccessful
                      ? 'payments.single.ticketToSupport.success.continue.button'
                      : 'payments.single.ticketToSupport.error.tryAgain.button'
                  )}
                />
              </div>
            </ModalContent>
          </div>
        </Animation>
      );
    }

    const isMessageForSupport =
      this.state.selectedRequest?.type === 'custom_ticket';

    return (
      <>
        <ModalTitle customClass='modal__title_left-align'>
          {getTranslate('payments.single.ticketToSupport.header')}
          {!!messages.length && (
            <div className='create-support-ticket__message'>
              {messages.map((message, i) => {
                if (i + 1 === messages.length) {
                  return (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(message),
                      }}
                    />
                  );
                }
                return (
                  <>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(message),
                      }}
                    />
                    <br />
                  </>
                );
              })}
            </div>
          )}
        </ModalTitle>
        <ModalContent>
          <div className='create-support-ticket__content'>
            <CustomSelect
              options={requests}
              label={getTranslate(
                'payments.single.ticketToSupport.ticketRequest.label'
              )}
              isRequired
              error={getTranslate(validationErrors?.selectedRequest)}
              value={selectedRequest}
              customClass='create-support-ticket__form-item'
              isLoading={isLoading}
              onChange={(value) => this.handleRequestsChange(value)}
              modern
            />
            <Input
              value={commentary}
              id='commentary'
              error={validationErrors?.commentary}
              label={getTranslate(
                isMessageForSupport
                  ? 'payments.single.ticketToSupport.commentRequired.label'
                  : 'payments.single.ticketToSupport.commentOptional.label'
              )}
              placeholder={getTranslate(
                isMessageForSupport
                  ? 'payments.single.ticketToSupport.commentRequired.placeholder'
                  : 'payments.single.ticketToSupport.commentOptional.placeholder'
              )}
              disabled={isLoading}
              type='textarea'
              resize={false}
              isRequired={isMessageForSupport}
              customClass='create-support-ticket__user-commentary create-support-ticket__form-item'
              onChange={this.handleCommentaryChange}
              modern
            />
            <Checkbox
              text={getTranslate(
                'payments.single.ticketToSupport.confirmation.checkbox'
              )}
              id='sending-confirmation'
              checked={isConfirmed}
              onChange={this.handleConfirmChange}
              customClass='create-support-ticket__confirmation-check create-support-ticket__form-item'
            />
          </div>
          <div className='create-support-ticket__footer'>
            <Button
              status='primary'
              disabled={!isConfirmed || isLoading}
              onClick={this.sendReport}
              text={getTranslate('payments.single.ticketToSupport.send.button')}
            />
          </div>
        </ModalContent>
      </>
    );
  }

  handleConfirmChange = () => {
    this.setState((prevState) => ({ isConfirmed: !prevState.isConfirmed }));
  };

  handleCommentaryChange = (e) => {
    const { value } = e.target;
    let error = '';
    if (value.length > 4096) {
      error = 'common.tooLongString.sysmsg';
    }

    this.setState((prevState) => ({
      commentary: value,
      validationErrors: {
        ...prevState.validationErrors,
        commentary: error,
      },
    }));
  };

  handleRequestsChange = (selectedRequest) => {
    this.setState((prevState) => ({
      selectedRequest,
      validationErrors: {
        ...prevState.validationErrors,
        selectedRequest: '',
      },
    }));
  };

  closeModal = () => {
    this.props.dispatch(closeModal());
  };

  showCleanForm = () => {
    this.setState({
      isReportSuccessful: undefined,
      commentary: '',
      selectedRequest: null,
    });

    if (!this.state.requests.length) {
      this.loadRequests();
    }
  };

  loadRequests = async () => {
    const { getTranslate } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await getSupportTicketRequests(this.props.content);
      this.setState({
        messages: response.messages,
        requests: response.actions.map((r) => {
          const { id, title, ...rest } = r;
          return {
            ...rest,
            value: r.id,
            label: getTranslate(r.title),
          };
        }),
      });
    } catch (e) {
      console.error(e);
      this.setState({ isReportSuccessful: false }, () => {
        this.props.dispatch(
          editOptions({
            needCloseButton: !this.state.isReportSuccessful,
          })
        );
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  sendReport = async () => {
    const { selectedRequest, commentary, validationErrors } = this.state;

    if (isNotAvailableForSupport(Messages.PaymentIssue_PerformAction)) return;

    if (!selectedRequest) {
      this.setState({
        validationErrors: {
          selectedRequest: 'common.requiredField.tooltip',
        },
      });
      return;
    }

    if (selectedRequest?.type === 'custom_ticket' && !commentary) {
      this.setState({
        validationErrors: {
          commentary: 'common.requiredField.tooltip',
        },
      });
      return;
    }

    if (validationErrors?.commentary || validationErrors?.selectedRequest) {
      return;
    }

    try {
      this.setState({ isLoading: true });
      await sendPaymentIssueReport({
        actionId: selectedRequest.value as string,
        comment: commentary,
      });
      this.setState({ isReportSuccessful: true });
      MetricService.send({
        action: 'click',
        actionKey: 'payments.paymentCard.createTicket.save.button',
      });
    } catch (e) {
      this.setState({ isReportSuccessful: false });
    } finally {
      this.setState({ isLoading: false });
      this.props.dispatch(
        editOptions({
          needCloseButton: !this.state.isReportSuccessful,
        })
      );
    }
  };
}

export default connect()(addTranslation(CreateSupportTicket));
