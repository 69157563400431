import BaseMessageHandler from '../BaseMessageHandler';
import { openModal } from 'actions/modal';
import { updateUser } from 'actions/user';
import Token from 'helpers/Token';

export default class Auth_ConfirmSecondFactorStateRequest extends BaseMessageHandler {
  default = () => {
    const { dispatch, response } = this;

    const message = response.data.messages.find(
      (m) => m.type === 'Auth_ConfirmSecondFactorStateRequest'
    );

    dispatch(
      openModal({
        modalId: 'Confirm2FA',
        content: {
          authToken: response.data.authToken,
          requestId: message.id,
          dispatch,
          ...message.payload,
        },
        onClose: () => {
          dispatch(updateUser({}));
        },
      })
    );
    Token.clearTokens();
  };
}
