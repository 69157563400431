import React from 'react';
import { connect } from 'react-redux';

import _noop from 'lodash/fp/noop';

import { StoreProps } from 'store';
import { closeModal } from 'actions/modal';
import { editChartItem } from 'actions/analytics';
import { getChartFilters } from 'api/analytics';
import { GraphDetails } from './GraphDetails';

import {
  ChartTypesUnion,
  ChartViewData,
  IChargebackFormValues,
  IDeclineReasonsFormValues,
  IDictionary,
  IFormValidateHandler,
  IFormValues,
  IFraudReportFormValues,
  IInOutFormValues,
  IOnChange,
  IOperationsFormValues,
  IPaymentsFormValues,
  IValidateHandler,
  TopTenFormValues,
  IDeclineCodesFormValues,
} from 'types/Analytics';

import { ServiceGetMockedOptionsByType } from './services/ServiceGetMockedOptionsByType';
import { ServiceSettingsFormHelpers } from './services/ServiceSettingsFormHelpers';
import { ServiceChartDataFormatter } from 'components/modal/modalList/graphDetails/services/ServiceChartDataFormatter';

import DateHelpers from 'helpers/Date';

import { types } from './services/ServiceGetModalChartTypes';
import { AdvancedAnalyticsEntityType } from './components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';
import { groupByHiddenAdapter as ChargebackGroupByHiddenAdapter } from 'components/modal/modalList/graphDetails/components/settingsForm/ChargebackFormConfig';
import { groupByHiddenAdapter as FraudReportGroupByHiddenAdapter } from 'components/modal/modalList/graphDetails/components/settingsForm/FraudReportFormConfig';

import DateFormats from 'constants/dateFormats';
import { FormValidate } from './components/settingsForm/InOutFormConfig';
import { RENAME_PARAM_MAPPING } from 'constants/renameParamMapping';

const FORM_VALIDATORS = {
  [AdvancedAnalyticsEntityType.inout]: FormValidate,
};

interface OwnProps {
  content: {
    chartId: string;
    chartType: ChartTypesUnion;
    graphType: AdvancedAnalyticsEntityType;
  };
}

interface ConnectedProps {
  projects: { list: IDictionary; isLoading: boolean };
  country: { list: IDictionary; isLoading: boolean };
  paymentMethod: { list: IDictionary; isLoading: boolean };
  currency: { list: IDictionary; isLoading: boolean };
  analyticsBrand: { list: IDictionary; isLoading: boolean };
  paymentMethodFromDictionary: { list: IDictionary; isLoading: boolean };
  issuerBank: { list: IDictionary; isLoading: boolean };
}

type IProps = OwnProps & ConnectedProps & StoreProps;

interface IState {
  values: {
    [key: string]:
      | IOperationsFormValues
      | IPaymentsFormValues
      | TopTenFormValues
      | IInOutFormValues
      | IDeclineReasonsFormValues
      | IChargebackFormValues
      | IFraudReportFormValues
      | IDeclineCodesFormValues;
  };
  options?: ChartViewData | null;
  validationErrors: { [key: string]: string } | {};
  isLoading: boolean;
  formValidationErrors: { [key: string]: string } | {};
  pristineFields: { [key: string]: boolean };
  initialValues?: IFormValues;
}

class ModalEditChart extends React.PureComponent<IProps, IState> {
  private readonly onChange: IOnChange;
  private readonly closeModal: () => void;
  private readonly saveChanges: () => void;

  constructor(props: IProps) {
    super(props);

    this.onChange = this._onChange.bind(this);
    this.closeModal = this._closeModal.bind(this);
    this.saveChanges = this._saveChanges.bind(this);

    const graphType: AdvancedAnalyticsEntityType = props.content.graphType;
    const chartType = props.content.chartType;

    if (graphType === AdvancedAnalyticsEntityType.operations) {
      this.state = {
        isLoading: true,
        options: ServiceGetMockedOptionsByType({
          type: chartType,
          group: graphType,
        }),
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: {
            chart: AdvancedAnalyticsEntityType.operations,
            chartName: '',
            groupByDate: '',
            groupBy: '',
            chartType: 'line',
            chartData: '',
            operationStatus: [],
            period: { from: '', to: '' },
            projects: [],
          } as IOperationsFormValues,
        },
        validationErrors: {},
        formValidationErrors: {},
        pristineFields: {},
      };
    } else if (graphType === AdvancedAnalyticsEntityType.payments) {
      this.state = {
        isLoading: true,
        options: ServiceGetMockedOptionsByType({
          type: chartType,
          group: graphType,
        }),
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: {
            chart: AdvancedAnalyticsEntityType.payments,
            chartName: '',
            groupByDate: '',
            groupBy: '',
            chartType: 'line',
            chartData: '',
            paymentStatus: [],
            period: { from: '', to: '' },
            projects: [],
          } as IPaymentsFormValues,
        },
        validationErrors: {},
        formValidationErrors: {},
        pristineFields: {},
      };
    } else if (
      graphType === AdvancedAnalyticsEntityType.payments_sa
    ) {
      this.state = {
        isLoading: true,
        options: ServiceGetMockedOptionsByType({
          type: chartType,
          group: graphType,
        }),
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: {
            chart: AdvancedAnalyticsEntityType.payments_sa,
            chartName: '',
            groupByDate: '',
            groupBy: '',
            chartType: 'line',
            chartData: '',
            paymentStatus: [],
            period: { from: '', to: '' },
            projects: [],
          } as IPaymentsFormValues,
        },
        validationErrors: {},
        formValidationErrors: {},
        pristineFields: {},
      };
    } else if (graphType === AdvancedAnalyticsEntityType.inout) {
      this.state = {
        isLoading: true,
        options: ServiceGetMockedOptionsByType({
          type: chartType,
          group: graphType,
        }),
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: {
            chart: AdvancedAnalyticsEntityType.inout,
            chartType: 'table',
            chartName: '',
            chartData: '',
            groupByRows: [],
            groupByColumns: [],
            projects: [],
            paymentMethod: [],
            channelCurrency: [],
            type: [],
            period: {
              from: '',
              to: '',
            },
            country: [],
          } as IInOutFormValues,
        },
        validationErrors: {},
        formValidationErrors: {},
        pristineFields: {},
      };
    } else if (graphType === AdvancedAnalyticsEntityType.inout_sales_agents) {
      this.state = {
        isLoading: true,
        options: ServiceGetMockedOptionsByType({
          type: chartType,
          group: graphType,
        }),
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: {
            chart: AdvancedAnalyticsEntityType.inout_sales_agents,
            chartType: 'table',
            chartName: '',
            chartData: '',
            groupByRows: [],
            groupByColumns: [],
            projects: [],
            paymentMethod: [],
            channelCurrency: [],
            type: [],
            period: {
              from: '',
              to: '',
            },
            country: [],
          } as IInOutFormValues,
        },
        validationErrors: {},
        formValidationErrors: {},
        pristineFields: {},
      };
    } else if (graphType === AdvancedAnalyticsEntityType.declineReasons) {
      this.state = {
        isLoading: true,
        options: ServiceGetMockedOptionsByType({
          type: chartType,
          group: graphType,
        }),
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: {
            chart: AdvancedAnalyticsEntityType.declineReasons,
            chartType: 'table',
            chartName: '',
            chartData: 'count',
            groupByRows: [],
            groupByColumns: [],
            groupByDate: '1d',
            period: {
              from: '',
              to: '',
            },
            projectId: [],
            operationType: [],
            operationStatus: [],
            countryByBin: [],
            countryByIp: [],
            paymentMethod: [],
            currency: [],
          } as IDeclineReasonsFormValues,
        },
        validationErrors: {},
        formValidationErrors: {},
        pristineFields: {},
      };
    } else if (graphType === AdvancedAnalyticsEntityType.topten) {
      this.state = {
        isLoading: true,
        options: ServiceGetMockedOptionsByType({
          type: chartType,
          group: graphType,
        }),
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: {
            chart: AdvancedAnalyticsEntityType.topten,
            chartName: '',
            chartData: 'channel_amount_in_usd',
            dimension: 'issuerCountry',
            chartType: 'bar',
            period: {
              from: '',
              to: '',
            },
            projectId: [],
            paymentMethod: [],
            issuerCountry: [],
            type: [],
          },
        },
        validationErrors: {},
        formValidationErrors: {},
        pristineFields: {},
      };
    } else if (graphType === AdvancedAnalyticsEntityType.declineCodes) {
      this.state = {
        isLoading: true,
        options: ServiceGetMockedOptionsByType({
          type: chartType,
          group: graphType,
        }),
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: {
            chart: graphType,
            chartName: '',
            chartData: 'count',
            dimension: 'declineReasons',
            chartType: 'bar',
            period: {
              from: '',
              to: '',
            },
            projectId: [],
            paymentMethod: [],
            issuerCountry: [],
            type: [],
          } as IDeclineCodesFormValues,
        },
        validationErrors: {},
        formValidationErrors: {},
        pristineFields: {},
      };
    } else if (graphType === AdvancedAnalyticsEntityType.chargeback) {
      this.state = {
        isLoading: false,
        options: ServiceGetMockedOptionsByType({
          type: chartType,
          group: graphType,
        }),
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: {
            chart: AdvancedAnalyticsEntityType.chargeback,
            chartName: '',
            chartData: 'count',
            chartType: 'bar',
            groupBy: '',
            groupByMultiSelect: [],
            groupByDate: '',
            period: {
              from: '',
              to: '',
            },
            projects: [],
            paymentMethod: [],
            cardType: '',
            issuerCountry: [],
          },
        },
        validationErrors: {},
        formValidationErrors: {},
        pristineFields: {},
      };
    } else if (graphType === AdvancedAnalyticsEntityType.fraud_report) {
      this.state = {
        isLoading: false,
        options: ServiceGetMockedOptionsByType({
          type: chartType,
          group: graphType,
        }),
        values: {
          [AdvancedAnalyticsEntityType[graphType]]: {
            chart: AdvancedAnalyticsEntityType.fraud_report,
            chartName: '',
            chartData: 'numberOfFraudOperations',
            chartType: 'simple_table',
            period: { from: '', to: '' },
            projectId: [],
            groupByMultiSelect: [],
            groupBy: 'cardType',
            brandId: [],
            groupByDate: '',
            issuerCountry: [],
            cardType: [],
          },
        },
        validationErrors: {},
        formValidationErrors: {},
        pristineFields: {},
      };
    }
  }

  _onChange: IOnChange = (name, value, onChangeCallback) => {
    const { graphType } = this.props.content;
    const values = this.state.values[AdvancedAnalyticsEntityType[graphType]];
    const newValues = { ...values, [name]: value };
    const cbHandledValues =
      onChangeCallback !== undefined
        ? { ...onChangeCallback(newValues, values) }
        : newValues;

    ServiceSettingsFormHelpers.adjustOptionalFieldsValues({
      values: cbHandledValues,
      chartType: graphType,
    });

    this.setState({
      values: {
        [AdvancedAnalyticsEntityType[graphType]]: { ...cbHandledValues },
      },
    });
  };

  _saveChanges = async () => {
    const { values } = this.state;
    const {
      content: { chartId, graphType },
      dispatch,
    } = this.props;

    this.setState({ isLoading: true });

    await dispatch(
      editChartItem({
        id: chartId,
        values: values[graphType],
        chartType: graphType,
      })
    );

    this.setState({ isLoading: false });
  };

  get dictionariesLoading() {
    const { projects, currency, paymentMethod, country } = this.props;

    return (
      projects.isLoading &&
      currency.isLoading &&
      paymentMethod.isLoading &&
      country.isLoading
    );
  }

  _closeModal = () => {
    const { dispatch } = this.props;

    dispatch(closeModal());
  };

  validateHandler: IValidateHandler = (name, value, formValues, validator) => {
    const { validationErrors } = this.state;
    const validationResult = validator
      .map((func) => func(name))
      .filter((err) => err !== undefined);

    if (validationResult.length !== 0) {
      const newErrors = { [name]: validationResult };

      this.setState({
        validationErrors: { ...validationErrors, ...newErrors },
      });
    } else {
      const withoutCurrentFieldErrors = Object.entries(validationErrors).reduce(
        (acc, [field, err]) => {
          if (field === name) {
            return acc;
          }

          acc[field] = err;

          return acc;
        },
        {}
      );

      this.setState({
        validationErrors: withoutCurrentFieldErrors,
      });
    }
  };

  formValidateHandler: IFormValidateHandler = (name, value, formValues) => {
    const { formValidationErrors } = this.state;
    const {
      content: { graphType },
    } = this.props;
    const formValidate = FORM_VALIDATORS[graphType];

    if (formValidate !== undefined) {
      const newErrors = formValidate.reduce((acc, { _, errId, validator }) => {
        const validationResult = validator(name, value, formValues);

        acc[errId] = validationResult;

        return acc;
      }, {});

      this.setState({
        formValidationErrors: { ...formValidationErrors, ...newErrors },
      });
    }
  };

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    const {
      content: { graphType },
    } = this.props;
    const prevValues = prevState.values[AdvancedAnalyticsEntityType[graphType]];
    const currentValues =
      this.state.values[AdvancedAnalyticsEntityType[graphType]];

    const prevChartType =
      prevValues !== undefined && 'chartType' in prevValues
        ? prevValues.chartType
        : null;
    const chartType =
      prevValues !== undefined && 'chartType' in currentValues
        ? currentValues.chartType
        : null;

    if (
      (graphType === AdvancedAnalyticsEntityType.operations ||
        graphType === AdvancedAnalyticsEntityType.payments ||
        graphType === AdvancedAnalyticsEntityType.payments_sa ||
        graphType === AdvancedAnalyticsEntityType.topten ||
        graphType === AdvancedAnalyticsEntityType.declineCodes ||
        graphType === AdvancedAnalyticsEntityType.fraud_report ||
        graphType === AdvancedAnalyticsEntityType.chargeback) &&
      chartType &&
      prevChartType &&
      chartType !== prevChartType
    ) {
      this.setState(
        {
          options: null,
        },
        () =>
          this.setState({
            options: ServiceGetMockedOptionsByType({
              type: chartType,
              group: graphType,
            }),
          })
      );
    }
  }

  async componentDidMount() {
    const {
      content: { chartId, graphType, chartType },
    } = this.props;

    const { data } = await getChartFilters({ id: chartId });
    const existingChartData = {
      graphType,
      chart: chartType,
    };

    const preparedValues = {
      ...Object.entries(data).reduce((acc, [key, value]) => {
        if (RENAME_PARAM_MAPPING[graphType][key] !== undefined) {
          acc[RENAME_PARAM_MAPPING[graphType][key]] = value;
        } else if (key === 'period') {
          const _period = value;
          const _from = DateHelpers.createDate(value.from);
          const _to = DateHelpers.createDate(value.to);

          _period.from = _from.format(DateFormats.datetime);
          _period.to = _to.format(DateFormats.datetime);

          acc[key] = _period;

          return acc;
        } else if (
          key === 'chartData' &&
          [
            AdvancedAnalyticsEntityType.operations,
            AdvancedAnalyticsEntityType.payments,
            AdvancedAnalyticsEntityType.payments_sa,
            AdvancedAnalyticsEntityType.topten,
            AdvancedAnalyticsEntityType.declineCodes,
            AdvancedAnalyticsEntityType.declineReasons,
            AdvancedAnalyticsEntityType.fraud_report,
            AdvancedAnalyticsEntityType.chargeback,
          ].includes(graphType)
        ) {
          acc[key] = ServiceChartDataFormatter.normalizeChartData({ data });

          return acc;
        } else if (
          (graphType === AdvancedAnalyticsEntityType.chargeback ||
            graphType === AdvancedAnalyticsEntityType.fraud_report) &&
          key === 'groupBy'
        ) {
          const isGroupByHidden =
            graphType === AdvancedAnalyticsEntityType.chargeback
              ? ChargebackGroupByHiddenAdapter(data)
              : FraudReportGroupByHiddenAdapter(data);

          if (isGroupByHidden) {
            acc['groupByMultiSelect'] = value;
            acc['groupBy'] = '';
          } else {
            acc[key] = Array.isArray(value) === true ? value[0] : [];
            acc['groupByMultiSelect'] = [];
          }

          return acc;
        } else {
          acc[key] = value;
        }

        return acc;
      }, {}),
      ...existingChartData,
    } as unknown as IFormValues;

    this.setState({
      values: { [graphType]: preparedValues },
      isLoading: false,
      options: ServiceGetMockedOptionsByType({
        type: chartType,
        group: graphType,
      }),
    });
  }

  get openedType() {
    const {
      content: { graphType },
    } = this.props;
    const selectedType = AdvancedAnalyticsEntityType[graphType];

    return types.filter((type) => type.id === selectedType);
  }

  render() {
    const {
      projects,
      country,
      paymentMethod,
      currency,
      analyticsBrand,
      paymentMethodFromDictionary,
      issuerBank,
      content: { graphType },
      dispatch,
    } = this.props;
    const {
      validationErrors,
      isLoading,
      options,
      values,
      formValidationErrors,
      pristineFields,
    } = this.state;
    const selectedType = AdvancedAnalyticsEntityType[graphType];

    const formValidators =
      FORM_VALIDATORS[graphType] === undefined
        ? []
        : FORM_VALIDATORS[graphType];
    const formValidate = formValidators.reduce((acc, { fields }) => {
      acc = [...acc, ...fields];

      return acc;
    }, []);

    return (
      <GraphDetails
        isLoading={isLoading || this.dictionariesLoading}
        chartTypes={this.openedType}
        validationErrors={validationErrors}
        options={options}
        values={values[AdvancedAnalyticsEntityType[graphType]]}
        titleToken='analytics.editForm.editChart.label'
        multiSelectItems={{
          projects: projects.list,
          currency: currency.list,
          paymentMethod: paymentMethod.list,
          country: country.list,
          analyticsBrand: analyticsBrand.list,
          paymentMethodFromDictionary: paymentMethodFromDictionary.list,
          issuerBank: issuerBank.list,
        }}
        onChange={this.onChange}
        selectedType={selectedType}
        setType={_noop}
        closeModal={this.closeModal}
        saveChanges={this.saveChanges}
        validateHandler={this.validateHandler}
        formValidationErrors={formValidationErrors}
        submitButtonDisabledConditions={[]}
        pristineFields={pristineFields}
        formValidatedFields={formValidate}
        dispatch={dispatch}
      />
    );
  }
}

const mapStateToProps = (state): ConnectedProps => {
  const { filtersValues } = state;

  return {
    projects: filtersValues?.enabledProject ?? {},
    country: filtersValues?.country ?? {},
    paymentMethod: filtersValues?.paymentMethodType ?? {},
    currency: filtersValues?.currency ?? {},
    analyticsBrand: filtersValues?.analyticsBrand ?? {},
    paymentMethodFromDictionary:
      filtersValues?.paymentMethodFromDictionary ?? {},
    issuerBank: filtersValues?.issuerBank ?? {},
  };
};

const ModalEditChart_ = connect(mapStateToProps)(ModalEditChart);

export { ModalEditChart_ as ModalEditChart };
