import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Link from 'components/ui/link';
import './policyLinks.scss';

interface ConnectedProps {
  settings: {
    visibleLegalLink: boolean;
  };
}

type Props = ConnectedProps & IntlProps;

interface State {
  isShow: boolean;
}

class PolicyLinks extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isShow: true,
    };
  }

  render() {
    const { settings, getTranslate } = this.props;
    const { isShow } = this.state;
    if (!isShow) return null;
    //надо ли пути прописать из publicRoutes а не строкой? можно сделать publicRoutes.find..

    return (
      <div className='policy-links'>
        {settings.visibleLegalLink && (
          <Fragment>
            <Link
              url='/cookie-policy'
              target='_blank'
              className='policy-links__item'>
              {getTranslate('common.cookies.link')}
            </Link>
            <Link
              url='/privacy-policy'
              target='_blank'
              className='policy-links__item'>
              {getTranslate('common.privacyPolicy.link')}
            </Link>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  settings: state.settings,
});

export default connect(mapStateToProps)(addTranslation(PolicyLinks));
