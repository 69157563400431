import financialReportsPicture from './images/computingTaxes.svg';

const textsByConfig: {
  [key: string]: {
    title: string;
    text: string;
    buttonText: string;
    picturePath: string;
  };
} = {
  financialReport: {
    title: 'common.linkExpired',
    text: 'financialReports.linkExpired.text',
    buttonText: 'financialReports.linkExpired.button',
    picturePath: financialReportsPicture,
  },
};

export default textsByConfig;
