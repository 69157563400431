import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 * Получить token
 */
const getToken = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserApiToken_List,
      },
    ],
  });
};

/**
 * Сгенерировать token
 */
const generateToken = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserApiToken_Generate,
      },
    ],
  });
};

/**
 * Удалить token
 */
const removeToken = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserApiToken_Remove,
      },
    ],
  });
};

/**
 * Обновить token
 */
const updateToken = (key) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Auth_LoginToken,
        payload: { redirect_token: key },
      },
    ],
  });
};

export default {
  getToken,
  generateToken,
  removeToken,
  updateToken,
};
