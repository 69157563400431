import React, { PureComponent } from 'react';
import { invert } from 'lodash';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import statusList from 'constants/statusList';
import './labelStatus.scss';
const statusKeys = invert(statusList);

type theme = 'colored' | 'bordered';

interface Props {
  text: string;
  status: keyof typeof statusKeys;
  theme?: theme;
  customClass?: string;
}

class LabelStatus extends PureComponent<Props & IntlProps> {
  render() {
    const {
      text,
      status,
      theme = 'colored',
      customClass = '',
      getTranslate,
    } = this.props;

    return (
      <div
        className={`ui-label-status ui-label-status_${theme} ui-label-status_${theme}_${status} ${customClass}`}>
        <span>{getTranslate(text)}</span>
      </div>
    );
  }
}

export default addTranslation(LabelStatus);
