import LocalStorage from 'helpers/LocalStorage';
import HelpSection from 'pages/help/types';
import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 *
 * @param language
 */
export const getMenu = (language) => {
  return new RpcService().request({
    messages: [
      { type: RpcTypes.Article_Menu, payload: { parentId: 0, language } },
    ],
  });
};

/**
 *
 * @param data
 */
export const addArticle = (data) => {
  return new RpcService().request({
    messages: [{ type: RpcTypes.Article_Create, payload: { ...data } }],
    showValidateNotification: false,
  });
};

/**
 *
 * @param articleId
 * @param slug
 * @param language
 */
export const getArticle = ({
  articleId,
  slug,
  language,
}: {
  articleId: string;
  slug: string;
  language: string;
}) => {
  return new RpcService().request({
    messages: [
      { type: RpcTypes.Article_View, payload: { articleId, slug, language } },
    ],
  });
};

/**
 *
 * @param data
 */
export const updateArticle = (data) => {
  return new RpcService().request({
    messages: [{ type: RpcTypes.Article_Update, payload: { ...data } }],
    showValidateNotification: false,
  });
};

/**
 *
 * @param articleId
 */
export const deleteArticle = (articleId) => {
  return new RpcService().request({
    messages: [{ type: RpcTypes.Article_Delete, payload: { articleId } }],
  });
};

/**
 *
 * @param payload
 */
export const updateArticles = (payload): Promise<HelpSection[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      LocalStorage.set('help', payload);
      resolve(payload);
    }, 500);
  });
};

/**
 *
 * @param articleId
 */
export const publishArticle = (articleId) => {
  return new RpcService().request({
    messages: [{ type: RpcTypes.Article_Publish, payload: { articleId } }],
  });
};

/**
 *
 * @param messagesToSend
 */
export const changeArticlePosition = (messagesToSend) => {
  return new RpcService().request({
    messages: messagesToSend.map((message) => {
      return {
        type: RpcTypes.Article_SetSortMenuIndex,
        payload: message,
      };
    }),

    /*[
      {
        type: RpcTypes.Article_SetSortMenuIndex,
        payload: { articleId, sortMenuIndex },
      },
    ],*/
  });
};

/**
 *
 * @param filter
 * @param pagination
 */
export const searchArticles = ({ filter, pagination }) => {
  return new RpcService()
    .request({
      messages: [
        { type: RpcTypes.Article_Search, payload: { filter, pagination } },
      ],
    })
    .then((data) => {
      return {
        items: data.rows,
        hasMore: data.hasMoreRows,
        totalCount: data.rowsTotalCount,
      };
    });
};
