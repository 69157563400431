export default {
  paymentId: 'paymentId',
  projectId: 'projectId',
  paymentType: 'paymentType',
  paymentMethod: 'paymentMethodGroup',
  paymentStatus: 'paymentStatus',
  currencies: 'currency',
  paymentCurrency: 'paymentCurrency',
  paymentHotCurrency: 'paymentHotCurrency',
  amount: 'amount',
  amountFrom: 'paymentAmountFrom',
  amountTo: 'paymentAmountTo',
  customerId: 'customerId',
  customerEmail: 'customerEmail',
  cardHolderName: 'cardHolder',
  accountNumber: 'paymentAccount',
  date: 'creationDate',
  updatedDate: 'updatedDate',
  operationId: 'operationId',
  acquirerId: 'acquirerId',
  arn: 'arn',
  rrn: 'rrn',
  customerPhone: 'customerPhone',

  // Payouts
  payoutsType: 'payoutsType',
  status: 'status',
  motoType: 'motoType',
  motoPaymentProject: 'motoPaymentProject',

  // Reports
  reportId: 'reportTemplateTaskHistoryId',
  reportType: 'reportType',
  reportCreatedAt: 'processingDate',
  reportStatus: 'status',
  reportName: 'name',
  reportPeriod: 'periodType',
  reportRegularity: 'regularity',

  // My Team
  teamUsers: 'teamUsers',
  teamActivities: 'teamActivities',
  teamReportType: 'teamReportType',
  userName: 'username',
  ip: 'ip',
  myTeamStatus: 'myTeamStatus',
  role: 'role',
  teamDate: 'date',

  // Invoices
  invoicePaymentId: 'paymentId',
  invoiceProjectId: 'projectId',
  invoiceCurrency: 'currency',
  invoiceStatus: 'status',
  invoiceCreatedAt: 'createdAt',

  // Recurring
  recurringStatus: 'recurringStatus',
  recurringCurrency: 'paymentCurrency',
  recurringAmountFrom: 'recurringAmountFrom',
  recurringAmountTo: 'recurringAmountTo',
  recurringCreatedAt: 'creationDate',

  // References
  referenceOperationId: 'operationId',
  referencePaymentId: 'paymentId',
  referenceAmount: 'amount',
  referenceOperationDate: 'operationCreatedAt',
  referenceConfirmDate: 'uploadedAt',

  // Risks
  riskMerchantId: 'merchantId',
  riskProjectIds: 'projectId',
  riskContentType: 'contentType',
  riskListType: 'listType',
  riskValue: 'element',
  risksCreatedAt: 'date',

  // Chargebacks
  chargebacksProjectIds: 'projectId',
  chargebacksStatuses: 'status',
  chargebacksArn: 'arn',
  chargebacksId: 'chargebackId',
  chargebacksDate: 'reportDate',
  caseId: 'caseId',

  // Icons
  iconPaymentMethodId: 'paymentMethodId',
  iconPaymentMethodName: 'paymentMethodName',

  // Admin
  merchantId: 'merchantId',
  userId: 'userId',
  email: 'email',
  wlId: 'wlId',

  // Remittance recipient account
  remittanceStatus: 'status',
  transferCurrency: 'transferCurrency',
  remittanceCreationDate: 'createdAt',

  // RemittancePayments
  remittancePaymentStatus: 'paymentStatus',
  remittancePaymentCurrency: 'paymentCurrency',

  // Balance statement
  balanceStatementBalanceType: 'balanceType',
  balanceStatementBalanceId: 'balanceId',
  balanceStatementCompanyId: 'companyId',
  balanceStatementContractId: 'contractId',
  balanceStatementCurrencyId: 'currencyId',
  balanceStatementPeriod: 'period',
  balanceStatementPeriodPreset: 'periodPreset',
  balanceStatementGroupPeriodBy: 'groupPeriodBy',

  // Balance RealTime
  balanceRealTimeBalanceType: 'balanceType',
  balanceRealTimeCompanyId: 'companyId',

  // Currency Rates
  currencyRatesActualDate: 'actualDate',
  currencyRatesCurrency: 'currency',
  currencyRatesMerchantId: 'merchantId',

  //financial data
  fileName: 'originalFileName',

  // Business documents
  merchantLegalEntityName: 'merchantLegalEntityName',

  // Payment Methods
  method: 'name',
  currency: 'currency',
  direction: 'direction',
  limits: 'limits',
  min_amount: 'min_amount',
  max_amount: 'max_amount',
} as const;
