export enum quickActionsRoutes {
  '/analytics' = 1,
  '/payments',
  '/manual/request/single-payout',
  '/manual/request/moto',
  '/manual/request/mass-payouts',
  '/manual/request/mass-refunds',
  '/manual/request/mass-capture',
  '/manual/request/mass-cancel',
  '/invoices/create',
  '/recurring-payments',
  '/reports/create',
  '/finance',
  '/balance',
  '/risks',
  '/chargebacks',
}
