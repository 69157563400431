import request from 'helpers/request';
import LocalStorage from 'helpers/LocalStorage';
import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 * Получение всех языков
 */
const getLocalization = (): Promise<any> => {
  const ticketNumbers = LocalStorage.get('lcTicketsPreview') || [];
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Localize_Dictionaries,
        payload: { ticketNumbers },
      },
    ],
  });
};

/**
 * Получение шаблона
 */
const downloadTemplate = ({ format }): Promise<any> => {
  const tag = LocalStorage.get('localizationTag');

  return request({
    method: 'POST',
    url: 'localization/download',
    data: {
      format,
      tag,
    },
  }).then(({ data }: any) => data);
};

/**
 * загрузка файла
 */
const uploadFile = (file: null | File, tag): Promise<any> => {
  return request({
    method: 'POST',
    url: 'localization/upload',
    data: { file, tag },
    headers: { 'Content-Type': 'multipart/form-data' },
    errorHandle: false,
  }).then((data: any) => data.payload);
};

export default {
  getLocalization,
  downloadTemplate,
  uploadFile,
};
