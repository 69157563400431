import tableNames from 'constants/tableNames';
import sectionNames from 'constants/sectionNames';
import savedEntities from 'constants/savedEntities';

export const getSectionByTableName = (tableName: string) => {
  if (!tableName) return undefined;

  switch (tableName) {
    case tableNames.payments:
      return sectionNames.payments;
    case tableNames.payouts:
      return sectionNames.payouts;
    case tableNames.remittanceRecipientAccounts:
    case tableNames.remittancePayments:
      return sectionNames.remittance;
    case tableNames.reports:
      return sectionNames.reports;
    case tableNames.chargebacks:
      return sectionNames.chargebacks;
    case tableNames.teamUsers:
    case tableNames.teamActivities:
      return sectionNames.myTeam;

    default:
      console.warn('No section for tableName: ', tableName);
      return undefined;
  }
};
export const getSectionBySavedEntity = (savedEntity: string) => {
  if (!savedEntity) return undefined;

  switch (savedEntity) {
    case savedEntities.payments:
      return sectionNames.payments;
    case savedEntities.payouts:
      return sectionNames.payouts;
    case savedEntities.recurring:
      return sectionNames.recurring;
    case savedEntities.reports:
      return sectionNames.reports;
    case savedEntities.chargebacks:
      return sectionNames.chargebacks;
    case savedEntities.businessDocs:
      return sectionNames.businessDocs;
    default:
      console.warn('No section found for ', savedEntity, 'savedEntity');
      return undefined;
  }
};
