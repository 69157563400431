import BaseMessageHandler from '../BaseMessageHandler';
import { addDataItem, updateDataItem } from 'actions/getData';
import Repository from 'helpers/Repository';
import tableNames from 'constants/tableNames';
import showNotification from 'components/ui/notification/showNotification';
import getLocalizedText from 'helpers/getLocalizedText';

export default class FinancialData_FileUpdated extends BaseMessageHandler {
  success = () => {
    const {
      dispatch,
      message: { payload },
    } = this;

    if (payload.errors) return;

    const store = Repository.get('store');
    const {
      data: { financialFile },
    } = store.getState();

    if (!financialFile.isFetched) return;

    if (
      financialFile.items.find(
        ({ financialFileId }) => financialFileId === payload.financialFileId
      )
    ) {
      dispatch(
        updateDataItem(tableNames.financialFile, 'financialFileId', payload)
      );
    } else {
      dispatch(addDataItem(tableNames.financialFile, payload));
      showNotification({
        status: 'success',
        content: getLocalizedText('common.uploadFile.success'),
      });
    }
  };
}
