import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';

export const setQuickActions = (quickActions) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_QUICK_ACTIONS,
        payload: quickActions,
      })
    );
  };
};
