import { WithRouterProps, LocationState } from 'decorators/withRouter';

function setCurrentTab<Props extends WithRouterProps = WithRouterProps>(
  this: { props: Props },
  currentTab: NonNullable<LocationState['currentTab']>
) {
  const { history } = this.props;
  history.replace(history.location.pathname, {
    ...history.location.state,
    currentTab: {
      ...(history.location.state?.currentTab || {}),
      ...currentTab,
    },
  });
}

export default { setCurrentTab };
