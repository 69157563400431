import { createAction } from '../../helpers/redux';
import _chunk from 'lodash/fp/chunk';

import ACTIONS from '../../constants/actionTypes';
import { CHUNK_THRESHOLD } from '../analytics';

import {
  getChart,
  getChartFilters,
  getChartsSharedWithRequest,
  getSharedWithMeChartsRequest,
  shareChartsRequest,
} from '../../api/analytics';

import showNotification from '../../components/ui/notification/showNotification';
import getLocalizedText from '../../helpers/getLocalizedText';
import getMessageError from '../../helpers/getMessageError';
import {
  chartDataFormatterWrapper,
  filtersNormalizer,
  loadSharedWithMeChartData,
} from '../analytics';

import { ById, ByOrder } from '../../types/Analytics';

const shareCharts = (chartIds, userIds) => {
  return async (dispatch) => {
    try {
      const requestData = {
        userIds,
        chartIds,
      };
      const { sharedWith } = await shareChartsRequest(requestData);

      await dispatch(
        createAction({
          type: ACTIONS.CHARTS_SHARED_USERS_UPDATED,
          payload: { sharedWith },
        })
      );

      showNotification({
        status: 'success',
        content: getLocalizedText('analytics.shareCharts.successfully_shared'),
      });
    } catch (e) {
      showNotification({
        status: 'error',
        content: getLocalizedText('analytics.shareCharts.share_charts_error'),
      });
    }
  };
};

const fetchSharedWithMeCharts = () => {
  return async (dispatch) => {
    try {
      const chartsData = await getSharedWithMeChartsRequest();

      dispatch(
        createAction({
          type: ACTIONS.CHARTS_SHARED_WITH_ME_LOADED,
          payload: { chartsData },
        })
      );
    } catch (e) {}
  };
};

const fetchSharedWithMeChartFilters = () => {
  return async (dispatch, getStore) => {
    const {
      analytics: {
        sharedChartData: { byId },
      },
    }: { analytics: { sharedChartData: { byId: ById } } } = getStore();

    const idsList = Object.keys(byId);
    const filtersList = await Promise.all(
      idsList.map(async (id) => {
        const filters = await getChartFilters({ id });

        return filtersNormalizer({ filters: filters.data });
      })
    );

    const filtersById = idsList.reduce((acc, id, index) => {
      acc[id] = filtersList[index];

      return acc;
    }, {});

    dispatch(
      createAction({
        type: ACTIONS.CHART_SHARED_WITH_ME_FILTERS_LOADED,
        payload: { filtersById },
      })
    );
  };
};

function fetchSharedWithMeChartItems() {
  return async (dispatch, getStore) => {
    const {
      analytics: {
        sharedChartData: { byOrder },
      },
    }: { analytics: { sharedChartData: { byOrder: ByOrder } } } = getStore();
    const orderList = Object.keys(byOrder);
    const batchedData = _chunk(CHUNK_THRESHOLD, orderList);

    for (const orderBatchItem of batchedData) {
      const promiseList = orderBatchItem.map(async (orderItem) => {
        try {
          const { data } = await getChart({
            id: byOrder[orderItem].biChartId,
            type: 'SHARED_WITH_ME_CHART',
          });
          const chartData = chartDataFormatterWrapper(data);

          dispatch(
            loadSharedWithMeChartData({
              id: data.biChartId,
              chartData,
              order: Number(orderItem),
            })
          );
        } catch (err) {
          const errText = getMessageError(err);

          dispatch(
            loadSharedWithMeChartData({
              error: errText,
              order: Number(orderItem),
              id: byOrder[orderItem].biChartId,
            })
          );
        }
      });

      try {
        await Promise.all(promiseList);
      } catch (err) {}
    }
  };
}

function fetchChartsSharedWith() {
  return async (dispatch, getStore) => {
    try {
      const { sharedWith } = await getChartsSharedWithRequest();

      dispatch(
        createAction({
          type: ACTIONS.CHARTS_SHARED_USERS_LOADED,
          payload: { sharedWith },
        })
      );
    } catch (err) {}
  };
}

export {
  shareCharts,
  fetchSharedWithMeCharts,
  fetchSharedWithMeChartFilters,
  fetchSharedWithMeChartItems,
  fetchChartsSharedWith,
};
