import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateField, updateFilterField } from 'actions/templateBuilder';
import BuilderFilters from './BuilderFilters';
import reportTypes from '../../reportTypes';
import { getReportFilterSet } from 'selectors/getReportFilterSet';
import { AnyObject } from 'types/Common';
import { StoreProps } from 'store';

interface ConnectedProps {
  fields: AnyObject;
  validationErrors: AnyObject;
  filters: AnyObject;
}

type Props = ConnectedProps & StoreProps;

interface State {
  isFilters: boolean;
}

class BuilderFiltersContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isFilters: false,
    };
  }

  async componentDidMount() {
    this.checkReportType();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const {
      fields: { reportType: prevReportType },
    } = prevProps;

    const {
      fields: { reportType },
    } = this.props;

    if (prevReportType !== reportType) {
      this.checkReportType();
    }
  }

  render() {
    const { fields, filters, validationErrors } = this.props;
    const { isFilters } = this.state;

    return (
      <BuilderFilters
        fields={fields}
        filters={filters}
        validationErrors={validationErrors}
        onChangeField={this.change}
        isFilters={isFilters}
      />
    );
  }

  change = (key: string, value: any, reportType?: string): void => {
    const { dispatch } = this.props;
    if (reportType) {
      dispatch(updateFilterField(key, value, reportType));
    } else {
      dispatch(updateField(key, value));
    }
  };

  checkReportType = () => {
    const {
      fields: { reportType },
    } = this.props;
    this.setState({
      isFilters: !(
        reportType === reportTypes.interchange ||
        reportType === reportTypes.fraud
      ),
    });
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  fields: state.templateBuilder.fields,
  filters: getReportFilterSet(state, state.templateBuilder.fields.reportType),
  validationErrors: state.templateBuilder.validationErrors,
});

export default connect(mapStateToProps)(BuilderFiltersContainer);
