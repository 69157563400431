import React from 'react';
import { connect } from 'react-redux';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { closeModal, editOptions } from 'actions/modal';
import { checkMafStatusChangeAvailability } from 'actions/demoStage';
import api from 'api/getStarted';
import { addListeners } from 'decorators/addListeners';
import { StoreProps } from 'store';

import ModalTitle from '../../components/ModalTitle';
import ModalContent from '../../components/ModalContent';
import ModalFooter from '../../components/ModalFooter';
import Button from 'components/ui/button';
import Radio from 'components/ui/radio';
import RadioGroup from 'components/ui/radioGroup';
import Input from 'components/ui/input';
import Loader from 'components/ui/loader';
import successImage from 'images/paymentSupportRequest/success.png';

import Messages from 'constants/rpcTypes';
import LocalStorage from 'helpers/LocalStorage';
import I18nHelpers from 'i18n/I18nHelpers';

import './contactSales.scss';

interface ConnectedProps {
  userEmail: string;
}

type Props = ConnectedProps & IntlProps & StoreProps;

interface State {
  type: string;
  message: string;
  validationErrors: Record<string, string>;
  isLoading: boolean;
  requestSent: boolean;
  gotWSResponse: boolean;
}

@addListeners([Messages.Maf_Status])
class ContactSales extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      type: 'customized_offer',
      message: '',
      validationErrors: {},
      isLoading: false,
      gotWSResponse: false,
      requestSent: false,
    };
  }

  render() {
    const { isLoading, requestSent } = this.state;
    return (
      <div className='contact-sales'>
        {requestSent ? this.renderSuccessMessage : this.renderForm}
        {isLoading && <Loader />}
      </div>
    );
  }

  values = [
    {
      text: I18nHelpers.getJsx(
        this.props.getTranslate(
          'getStarted.benefits.modal.customisedOffer.radiobutton'
        ),
        [
          {
            tagName: 'i',
            wrapper: (content) => <span className='text-gray'>{content}</span>,
          },
        ]
      ),
      value: 'customized_offer',
    },
    {
      text: this.props.getTranslate(
        'getStarted.benefits.modal.additionalPM.radiobutton'
      ),
      value: 'additional_payment_methods',
    },
    {
      text: I18nHelpers.getJsx(
        this.props.getTranslate('getStarted.benefits.modal.other.radiobutton'),
        [
          {
            tagName: 'i',
            wrapper: (content) => <span className='text-gray'>{content}</span>,
          },
        ]
      ),
      value: 'other',
    },
  ];

  get isCustomMessage() {
    return this.state.type === 'other';
  }

  get renderForm() {
    const { type, message, validationErrors, isLoading } = this.state;
    const { getTranslate } = this.props;

    return (
      <>
        <ModalTitle>
          <span className='bold'>
            {getTranslate('getStarted.benefits.modal.contactSales.label')}
          </span>
          <br />
          {getTranslate('getStarted.benefits.modal.customisedOffer.label')}
        </ModalTitle>
        <ModalContent>
          <div className='contact-sales__subtitle'>
            {getTranslate('getStarted.benefits.modal.customisedOffer.text')}
          </div>
          <form className='contact-sales__form'>
            <div className='contact-sales__form-title'>
              {getTranslate('getStarted.benefits.modal.iWant.label')}
            </div>
            <RadioGroup checkedId={type} onChange={this.handleChange}>
              {this.values.map((item) => {
                return (
                  <Radio
                    key={item.value}
                    id={item.value.toString()}
                    text={item.text}
                  />
                );
              })}
            </RadioGroup>
            {this.isCustomMessage && (
              <Input
                value={message}
                id='message'
                error={validationErrors.message}
                label={getTranslate(
                  'getStarted.benefits.modal.whatDoYouWant.text'
                )}
                placeholder={getTranslate(
                  'getStarted.benefits.modal.iWant.placeholder'
                )}
                disabled={isLoading}
                type='textarea'
                resize={false}
                isRequired
                customClass='contact-sales__user-message'
                onChange={this.handleUserMessageChange}
                modern
              />
            )}
          </form>
        </ModalContent>
        <ModalFooter>
          <Button
            status='primary'
            loading={isLoading}
            disabled={!!this.state.validationErrors.message}
            onClick={this.sendRequest}
            text={getTranslate('getStarted.benefits.modal.sendRequest.button')}
          />
        </ModalFooter>
      </>
    );
  }

  get renderSuccessMessage() {
    const { getTranslate, userEmail } = this.props;

    return (
      <div className='contact-sales_submitted'>
        <ModalTitle>
          <img
            alt='success'
            src={successImage}
            className='contact-sales__submitted-image'
          />
          <div>
            {getTranslate('getStarted.benefits.modal.messageSent.label')}
          </div>
        </ModalTitle>
        <ModalContent>
          {getTranslate('getStarted.benefits.modal.messageSent.text', {
            username: userEmail,
            node: true,
          })}
        </ModalContent>
        <ModalFooter>
          <Button
            status='primary'
            onClick={this.closeModal}
            text={getTranslate('getStarted.benefits.modal.continueDas.button')}
          />
        </ModalFooter>
      </div>
    );
  }

  handleUserMessageChange = (e) => {
    this.setState({ message: e.target.value, validationErrors: {} });
  };

  handleChange = (value) => {
    this.setState({ type: value }, () => {
      if (!this.isCustomMessage) {
        this.setState({ validationErrors: {} });
      }
    });
  };

  sendRequest = async () => {
    const { message, type } = this.state;
    const { dispatch } = this.props;
    if (this.isCustomMessage && !this.state.message) {
      this.setState({
        validationErrors: {
          message: this.props.getTranslate('common.requiredField.error'),
        },
      });
      return;
    }

    try {
      this.setState({ isLoading: true, gotWSResponse: false });
      const response = await api.contactSales({ type, message });
      if (!this.state.gotWSResponse) {
        LocalStorage.set('ForbidMafStatusChangeTill', response.queue.blockTill);
        dispatch(checkMafStatusChangeAvailability());
      }

      dispatch(
        editOptions({
          needCloseButton: false,
        })
      );
      this.setState({ requestSent: true });
    } catch (e) {
      const { payload } = e;
      if (payload.validationErrors) {
        this.setState({ validationErrors: payload.validationErrors });
      }
    } finally {
      this.setState({ isLoading: false });
    }
  };

  closeModal = () => {
    this.props.dispatch(closeModal());
  };

  onEvent = () => {
    this.setState({ gotWSResponse: true });
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  userEmail: state.user.email,
});

export default connect(mapStateToProps)(addTranslation(ContactSales));
