import getLocalizedText from 'helpers/getLocalizedText';

function getCustomSelectItems({
  list = [],
  getLabel,
  valueKey = 'id',
  getValue,
  additionalParam,
}: {
  list: any[];
  getLabel?: (item) => any;
  valueKey?: string;
  getValue?: (item) => any;
  additionalParam?: any;
}) {
  // console.log('list', list, getLocalizedText(list[0].text), list[0][valueKey]);
  return list.map((item) => ({
    value: getValue ? getValue(item) : item[valueKey],
    label: getLabel ? getLabel(item) : getLocalizedText(item.text),
    [additionalParam]: item[additionalParam],
  }));
}

export default getCustomSelectItems;
