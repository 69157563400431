import tableNames from 'constants/tableNames';

export const formIdRoutes = {
  single: 'single-payout',
  moto: 'moto',
  payout: 'mass-payouts',
  refund: 'mass-refunds',
  capture: 'mass-capture',
  cancel: 'mass-cancel',
};

export const tableRoutes = {
  [tableNames.massPayouts]: 'mass',
};
