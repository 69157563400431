import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AnyObject } from 'types/Common';
import OnlineFilters from './OnlineFilters';
import {
  changeQuickFilter,
  resetConcreteQuickFilters,
} from 'actions/quickFilters';
import checkFilters from 'helpers/checkFilters';
import tableNames from 'constants/tableNames';
import { StoreProps } from 'store';

interface OwnProps {
  isLoading: boolean;
}

interface ConnectedProps {
  filters: AnyObject;
  balanceType: AnyObject;
  balanceCompany: AnyObject;
}

type Props = OwnProps & ConnectedProps & StoreProps;

class OnlineFiltersContainer extends PureComponent<Props> {
  async componentDidMount() {
    await checkFilters('financeRealTime');
  }

  render() {
    const { isLoading, filters, balanceType, balanceCompany } = this.props;

    return (
      <OnlineFilters
        isLoading={isLoading}
        filters={filters}
        balanceType={balanceType}
        balanceCompany={balanceCompany}
        hasSelectedFilters={this.hasSelectedFilters()}
        onChangeFilter={this.onChangeFilter}
        onResetFilter={this.onResetFilter}
      />
    );
  }

  hasSelectedFilters = () => {
    const { filters } = this.props;
    return Object.values(filters).find(
      (item) => typeof item === 'number' || Boolean(item.length)
    );
  };

  onChangeFilter = (data: { key: string; value: any }): void => {
    const { dispatch } = this.props;
    const { key, value } = data;

    dispatch(changeQuickFilter(tableNames.balanceRealTime, key, value));
  };

  onResetFilter = (): void => {
    const { dispatch } = this.props;

    dispatch(resetConcreteQuickFilters({ name: tableNames.balanceRealTime }));
  };
}

const mapStateToProps = (state): ConnectedProps => {
  const { filtersValues, quickFilters } = state;

  return {
    filters: quickFilters.balanceRealTime,
    balanceType: filtersValues?.balanceType,
    balanceCompany: filtersValues?.balanceCompany,
  };
};

export default connect(mapStateToProps)(OnlineFiltersContainer);
