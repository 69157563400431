import React, { PureComponent, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { IntlProps, addTranslation } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';

import Icon from 'components/ui/icon';
import Link from 'components/ui/link';
import PreviewText from 'components/ui/previewText';
import LinkFactory from './LinkFactory';
import Messages from 'constants/rpcTypes';
import { UserNotificationItemType } from 'types/UserNotifications';
import './notificationItem.scss';

interface Props extends IntlProps, WithPermissions {
  item: UserNotificationItemType;
  typeDictionary: {
    id: string;
    text: string;
  };
  onMarkAsRead: (id: string) => void;
  onDelete: (id: string) => void;
}

class NotificationItem extends PureComponent<Props> {
  render() {
    const { item, typeDictionary, isEnabled, getTranslate } = this.props;
    const { isRead, data } = item;

    return (
      <div
        className={classNames(`user-notifications-item`, {
          'user-notifications-item_read': isRead,
        })}
        onClick={this.readNotification}>
        <div className='user-notifications-item__date'>{item.createdAt}</div>
        <div className='user-notifications-item__text'>
          {typeDictionary && (
            <PreviewText
              text={getTranslate(typeDictionary.text, data)}
              maxLength={125}
            />
          )}
        </div>
        {this.renderLink()}
        {isEnabled(Messages.UserNotification_Delete) && (
          <Icon
            className='user-notifications-item__close'
            name='im-Close'
            size={10}
            onClick={this.deleteNotification}
          />
        )}
        {!isRead && (
          <div
            title='New notification'
            className='user-notifications-item__new-indicator'
          />
        )}
      </div>
    );
  }

  renderLink() {
    const { item, getTranslate } = this.props;
    const { type, data } = item;
    const linkOptions = LinkFactory.create(type, data);

    if (!linkOptions.url) return null;

    if (linkOptions.isExternal) {
      return (
        <a
          className='ui-link user-notifications-item__link'
          href={linkOptions.url}>
          {getTranslate(linkOptions.text)}
          <Icon
            className='user-notifications-item__link-icon'
            name='pointerRight'
            size={10}
          />
        </a>
      );
    }

    return (
      <Link url={linkOptions.url} className='user-notifications-item__link'>
        {getTranslate(linkOptions.text)}
        <Icon
          className='user-notifications-item__link-icon'
          name='pointerRight'
          size={10}
        />
      </Link>
    );
  }

  readNotification = () => {
    const { item, onMarkAsRead, isEnabled } = this.props;
    if (isEnabled(Messages.UserNotification_MarkAsRead) && !item.isRead) {
      onMarkAsRead(item.id);
    }
  };

  deleteNotification = (event: SyntheticEvent) => {
    const { item, onDelete } = this.props;
    event.stopPropagation();
    onDelete(item.id);
  };
}

export default addTranslation(addPermissions(NotificationItem));
