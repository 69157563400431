import api from 'api/user';
import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';
import Token from 'helpers/Token';

const setUserError = (dispatch, payload) => {
  dispatch(
    createAction({
      type: ACTIONS.SET_USER_ERROR,
      payload,
    })
  );
};

export const updateUser = (fields = {}) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPDATE_USER,
        payload: {
          fields,
        },
      })
    );
  };
};

export const setUserTips = (tips) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_USER_TIPS,
        payload: tips,
      })
    );
  };
};

export const updateUserTips = (payload) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPDATE_USER_TIPS,
        payload,
      })
    );
  };
};

export const setSupportMessages = (messages) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_SUPPORT_MESSAGES,
        payload: {
          supportMessages: messages,
        },
      })
    );
  };
};

export const editSupportMessages = (payload) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.EDIT_SUPPORT_MESSAGES,
        payload,
      })
    );
  };
};

export const updateLanguage = (fields = {}) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.USER_CHANGE_LANGUAGE,
        payload: {
          ...fields,
        },
      })
    );
  };
};

export const resetUserErrors = () => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_USER_ERROR,
      })
    );
  };
};

export const changePassword = ({ password, passwordConfirm, token }) => {
  return (dispatch) => {
    api
      .resetPasswordComplete({ password, passwordConfirm, token })
      .catch(({ payload }) => setUserError(dispatch, payload));
  };
};

export const resetPassword = ({ email }, onSuccess) => {
  return (dispatch) => {
    api
      .resetPassword({ email })
      .then(() => {
        onSuccess();
      })
      .catch(({ payload }) => setUserError(dispatch, payload));
  };
};

export const logoutUser = (onSuccess) => {
  return (dispatch) => {
    api.logout().then((user) => {
      onSuccess();
      dispatch(
        createAction({
          type: ACTIONS.LOGOUT_USER,
          payload: {
            user,
          },
        })
      );
      Token.clearTokens();
    });
  };
};

export const logoutAll = () => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.LOGOUT_USER,
      })
    );
    Token.clearTokens();
  };
};
