import BaseMessageHandler from '../BaseMessageHandler';
import { addDataItem } from 'actions/getData';
import Repository from 'helpers/Repository';

export default class User_Create extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;
    const { payload } = message;

    const store = Repository.get('store');
    const {
      data: { users },
    } = store.getState();

    if (users.items.length) {
      dispatch(addDataItem('users', this.getTableFormat(payload)));
    }
  };

  getTableFormat = (user) => {
    const {
      id,
      merchants,
      projectsAvailable,
      email,
      roles,
      createdAt,
      status,
      isChargebackMailingEnabled,
      wlId,
      legalEntities,
    } = user;

    return {
      id,
      email,
      merchants: merchants?.map(({ merchantId, text }) => ({
        id: merchantId,
        name: text,
      })),
      projects: projectsAvailable?.map(({ projectId, text }) => ({
        id: projectId,
        name: text,
      })),
      legalEntities: legalEntities?.map(({ text }) => text),
      status,
      roles,
      createdAt,
      isChargebackMailingEnabled,
      wlId,
    };
  };
}
