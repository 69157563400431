import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { isEqual, omit } from 'lodash';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import { createSingleRisk } from 'api/risks';
import { confirmLeavePage } from 'actions/app';
import { changeDataForceUpdate } from 'actions/getData';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import getSelectionListItems from 'creators/getSelectionListItems';
import { StoreProps } from 'store';

import showNotification from 'components/ui/notification/showNotification';
import AddToList from './WbSingle';
import WbListFields from './WbListFields';
import ListTypes from './ListTypes';
import { Dictionary } from 'types/FilterValue';
import { AnyObject } from 'types/Common';
import SelectItemType from 'types/SelectItem';
import { addListeners, IListeners } from 'decorators/addListeners';
import Messages from 'constants/rpcTypes';
import { PROJECTS_LIMIT } from '../../../constants';
import tableNames from 'constants/tableNames';
import MetricService from 'helpers/metricService/MetricService';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';

interface OwnProps {
  onCloseForm: () => void;
}

interface ConnectedProps {
  userMerchants: Dictionary;
  enabledProjects: Dictionary;
  isRcsBinBlackEnabled: boolean;
  isRcsBinWhiteEnabled: boolean;
}

type Props = OwnProps & ConnectedProps & StoreProps & IntlProps;

interface State {
  isCreating: boolean;
  fields: WbListFields;
  merchants: SelectItemType[];
  validationErrors: AnyObject;
}

@addListeners([Messages.Rcs_CreateElement])
class WbSingleContainer extends Component<Props, State> implements IListeners {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      validationErrors: {},
      merchants: getCustomSelectItems({
        list: props.userMerchants.list,
        valueKey: 'id',
        getLabel: ({ text }) => text,
      }),
      fields: this.getInitialFields(),
    };
  }

  componentDidUpdate(prevProps, prevState): void {
    if (
      prevState.fields.selectedMerchant &&
      !isEqual(
        prevState.fields.selectedMerchant,
        this.state.fields.selectedMerchant
      )
    ) {
      this.changeFields('projectList', this.getProjectList());
    }

    if (
      !prevProps.userMerchants?.isFetched &&
      this.props.userMerchants?.isFetched
    ) {
      this.setState({
        merchants: getCustomSelectItems({
          list: this.props.userMerchants.list,
          valueKey: 'id',
          getLabel: ({ text }) => text,
        }),
        fields: this.getInitialFields(),
      });
    }

    if (!isEqual(prevState.fields, this.state.fields)) {
      this.props.dispatch(
        confirmLeavePage({
          isConfirm: !isEqual(this.state.fields, this.getInitialFields()),
        })
      );
    }
  }

  render() {
    const { fields, merchants, isCreating, validationErrors } = this.state;
    const {
      isRcsBinBlackEnabled,
      isRcsBinWhiteEnabled,
      enabledProjects,
      userMerchants,
      onCloseForm,
    } = this.props;

    return (
      <Fragment>
        <AddToList
          fields={fields}
          dictionaries={{ merchants }}
          isLoading={userMerchants.isLoading || enabledProjects.isLoading}
          isCreating={isCreating}
          isRcsBinBlackEnabled={isRcsBinBlackEnabled}
          isRcsBinWhiteEnabled={isRcsBinWhiteEnabled}
          validationErrors={validationErrors}
          canCreate={this.canCreate()}
          onChangeField={this.changeFields}
          onCreate={() => this.create()}
          onCancel={onCloseForm}
        />
      </Fragment>
    );
  }

  changeFields = (field: keyof WbListFields, value) => {
    const { validationErrors, fields } = this.state;

    if (field === 'listType') {
      return this.handleListTypeChange(value);
    }

    this.setState({
      fields: {
        ...fields,
        [field]: value,
      },
      validationErrors: {
        ...validationErrors,
        [field]: '',
      },
    });
  };

  handleListTypeChange = (listType) => {
    const { isRcsBinBlackEnabled, isRcsBinWhiteEnabled } = this.props;
    const { fields, validationErrors } = this.state;
    let bin = fields.bin;
    if (
      (listType === ListTypes.blackList && !isRcsBinBlackEnabled) ||
      (listType === ListTypes.whiteList && !isRcsBinWhiteEnabled)
    ) {
      bin = '';
    }
    this.setState({
      fields: {
        ...fields,
        listType,
        bin,
      },
      validationErrors: {
        ...validationErrors,
        bin: '',
      },
    });
  };

  async create() {
    const { onCloseForm, getTranslate, dispatch } = this.props;
    const { fields } = this.state;
    if (isNotAvailableForSupport(Messages.Rcs_CreateElement)) return;

    this.setState({ isCreating: true });

    try {
      await createSingleRisk({
        ...omit(fields, ['projectList', 'selectedMerchant']),
        merchantId: fields.selectedMerchant?.value,
        projectId: fields.projectList
          .filter((item) => item.isSelected)
          .map((item) => item.id),
      });
      dispatch(
        confirmLeavePage({
          isConfirm: false,
        })
      );
      this.setState({ fields: this.getInitialFields() }, onCloseForm);
    } catch (error) {
      const { payload } = error;
      const validationErrors = payload?.validationErrors || payload;
      if (validationErrors?.common) {
        showNotification({
          status: 'error',
          content: getTranslate(validationErrors.common),
        });
      } else {
        this.setState({
          validationErrors,
        });
      }
    } finally {
      this.setState({ isCreating: false });
    }
  }

  canCreate = (): boolean => {
    const {
      fields: { selectedMerchant, projectList, customer, pan, email, ip, bin },
    } = this.state;
    if (!selectedMerchant) return false;
    if (!projectList.find((project) => project.isSelected)) return false;
    return !!(customer || pan || email || ip || bin);
  };

  getInitialFields(): WbListFields {
    let selectedMerchant: any = this.getSelectedMerchant();
    const projectList: any = this.getProjectList();

    if (selectedMerchant) {
      selectedMerchant = {
        value: selectedMerchant.id,
        label: selectedMerchant.text,
      };
    }

    return {
      listType: ListTypes.blackList,
      contentType: '',
      ip: '',
      email: '',
      customer: '',
      pan: '',
      selectedMerchant,
      projectList,
      comment: '',
      bin: '',
    };
  }

  getSelectedMerchant() {
    const { userMerchants } = this.props;
    if (!userMerchants.isFetched) return null;

    const selectedMerchant = this.state?.fields.selectedMerchant;
    if (selectedMerchant) {
      return userMerchants.list.find(
        ({ id }) => +id === +selectedMerchant.value
      );
    } else if (userMerchants.list.length) {
      return userMerchants.list[0];
    }
    return null;
  }

  getProjectList() {
    const { enabledProjects } = this.props;
    if (!enabledProjects.isFetched) return [];

    const selectedMerchant: any = this.getSelectedMerchant();
    if (!selectedMerchant) return [];

    const projects: any[] = [];
    selectedMerchant.projectId.forEach((id) => {
      const project = enabledProjects.list.find(
        (eProject) => eProject.id === id
      );
      if (project) {
        projects.push(project);
      }
    });

    let selectedProjects;
    if (projects.length > PROJECTS_LIMIT) {
      selectedProjects = projects.slice(0, PROJECTS_LIMIT);
    } else {
      selectedProjects = projects;
    }
    return getSelectionListItems({
      originalList: projects,
      selectedList: selectedProjects,
    });
  }

  onEvent({ data, name }) {
    const { rpc } = data;
    const { dispatch } = this.props;
    if (name === Messages.Rcs_CreateElement && rpc.status === 'success') {
      // waiting form to close
      setTimeout(() => {
        dispatch(changeDataForceUpdate(tableNames.rcs, true));
      }, 300);
      MetricService.send({
        action: 'click',
        actionKey: 'risks.blackWhiteLists.manage.apply',
      });
    }
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  userMerchants: state.filtersValues.userMerchant,
  enabledProjects: state.filtersValues.enabledProjectExternal,
  isRcsBinBlackEnabled: state.user.isRcsBinBlackEnabled,
  isRcsBinWhiteEnabled: state.user.isRcsBinWhiteEnabled,
});

export default connect(mapStateToProps)(addTranslation(WbSingleContainer));
