import { FormField } from 'components/formFields/types';
import { FilterTypes } from 'constants/FilterTypes';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import availableLanguages from 'constants/languages';

const fieldsConfig: FormField[] = [
  {
    id: 'languageCode',
    fieldType: FilterTypes.select,
    valueType: 'string',
    isSearchable: false,
    required: true,
    label: 'additionalFeatures.language.label',
    placeholder: 'placeholder',
    list: getCustomSelectItems({
      list: availableLanguages,
      getLabel: (item) => item,
      getValue: (item) => item,
    }),
  },
  {
    id: 'title',
    fieldType: FilterTypes.text,
    valueType: 'string',
    required: true,
    label: 'additionalFeatures.featureCard.header.label',
    placeholder: 'placeholder',
    maxLength: 60,
  },
  {
    id: 'category',
    fieldType: FilterTypes.text,
    valueType: 'string',
    required: true,
    label: 'additionalFeatures.featureCard.tag.label',
    placeholder: 'placeholder',
    maxLength: 15,
  },
  {
    id: 'description',
    fieldType: 'textarea',
    valueType: 'string',
    required: true,
    label: 'additionalFeatures.featureCard.description.label',
    placeholder: 'placeholder',
    maxLength: 200,
  },
  {
    id: 'iconPath',
    fieldType: 'fileLoader',
    valueType: 'string',
    required: true,
    label: 'additionalFeatures.featureCard.icon.label',
    placeholder: 'placeholder',
  },
  {
    id: 'picturePath',
    fieldType: 'fileLoader',
    valueType: 'string',
    required: true,
    label: 'additionalFeatures.featureCard.picture.label',
    placeholder: 'placeholder',
  },
  /*{
    id: 'status',
    fieldType: FilterTypes.select,
    valueType: 'string',
    required: true,
    label: 'status',
    placeholder: 'placeholder',
    containerSelector: '.modal__content',
    initialValue: 'more',
    list: getCustomSelectItems({
      list: ['success', 'more'],
      getLabel: (item) => item,
      getValue: (item) => item,
    }),
  },*/
];

export default fieldsConfig;
