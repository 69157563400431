import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { AnyObject } from 'types/Common';
import TreeTableRow from './TreeTableRow';
import './treeTable.scss';

interface Props extends IntlProps {
  rows: AnyObject[];
  columns: string[];
  showAllRows: boolean;
  rowTotal?: AnyObject;
  localize?: object;
}

class TreeTable extends PureComponent<Props> {
  static defaultProps = {
    showAllRows: false,
  };

  render() {
    const { rows, columns, rowTotal, showAllRows, localize, getTranslate } =
      this.props;

    return (
      <div className='ui-tree-table'>
        <div className='ui-tree-table__header'>
          {columns.map((column, index) => (
            <div
              className={`ui-tree-table__column ui-tree-table__column_${column}`}
              key={`column-${index}-${column}`}>
              {localize ? getTranslate(localize[column]) : column}
            </div>
          ))}
        </div>
        <div className='ui-tree-table__body'>
          {rows.map((row, index) => (
            <TreeTableRow
              key={index}
              data={row}
              columns={columns}
              showAllChildren={showAllRows}
            />
          ))}
        </div>
        {rowTotal && (
          <div className='ui-tree-table__footer'>
            <TreeTableRow data={rowTotal} columns={columns} />
          </div>
        )}
      </div>
    );
  }
}

export default addTranslation(TreeTable);
