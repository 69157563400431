import request from 'helpers/request';
import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

const fileLoadContentType = 'multipart/form-data';
/**
 * Список Bulk Payouts
 * @param params
 */
const getBulkPayouts = (params) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.BulkPayouts_List,
        payload: { ...params },
      },
    ],
    isCancelable: true,
  });
};

/**
 * Отправка данных одиночной выплаты
 * @param id
 * @param data
 */
const sendPayoutData = (id: string, data): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        id,
        type: RpcTypes.PaymentOperation_Payout,
        payload: { ...data },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 *
 * @param payoutQueueId
 */
const getPayoutStatus = (payoutQueueId: string): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentOperation_Status,
        payload: { payoutQueueId },
      },
    ],
  });
};

/**
 *
 * @param payoutQueueId
 */
const rejectPayout = (payoutQueueId: string): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentOperation_ClarificationReject,
        payload: { payoutQueueId },
      },
    ],
  });
};

/**
 *
 * @param data
 */
const sendClarification = (data): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentOperation_ClarificationProceed,
        payload: { ...data },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 *
 * @param params
 */
export const getPrefilledPayoutClarification = (params) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentOperation_PrefilledPayoutClarification,
        payload: {
          ...params,
        },
      },
    ],
  });
};

/**
 *
 * @param data
 */
export const generatePayoutTemplate = (data) => {
  return request({
    method: 'POST',
    url: 'file-download/template-payout-csv',
    responseType: 'blob',
    data,
  });
};

/**
 * Загрузка файла для Payouts
 */
const uploadFile = (file: null | File, type: string) => {
  return request({
    method: 'POST',
    url: 'bulk-payouts/upload',
    data: { file, type },
    headers: { 'Content-Type': fileLoadContentType },
    errorHandle: false,
  }).then(({ data }: any) => data.payload.batch);
};

/**
 * Загрузка файла для Capture/Cancel
 */
const uploadFileCaptureCancel = (file: null | File, type: string) => {
  return request({
    method: 'POST',
    url: `app/bulk-payouts/upload-${type}`,
    data: { file, type },
    headers: { 'Content-Type': fileLoadContentType },
    errorHandle: false,
  }).then(({ data }: any) => data.payload.batch);
};

/**
 * Удаление строк
 */
const deletePayoutsFromBatch = ({
  paymentOperationBatchId,
  payoutQueueIds,
}) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.BulkPayouts_RemoveOperations,
        payload: { paymentOperationBatchId, payoutQueueIds },
      },
    ],
  });
};

/**
 * Подтверждение выплаты
 * @param paymentOperationBatchId
 */
const sendBatch = (paymentOperationBatchId: number) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.BulkPayouts_Confirm,
        payload: { paymentOperationBatchId },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Информация о массовой выплате
 * @param paymentOperationBatchId
 */
const getBatch = (paymentOperationBatchId: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.BulkPayouts_View,
        payload: { paymentOperationBatchId },
      },
    ],
  });
};

/**
 * Информация о предзагруженной массовой выплате
 * @param paymentOperationBatchId
 */
const getBatchPreview = (paymentOperationBatchId: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.BulkPayouts_Preview,
        payload: { paymentOperationBatchId },
      },
    ],
  });
};

/**
 * Скачать файл кларификации
 * @param paymentOperationBatchId
 */
const downloadBatchClarification = (paymentOperationBatchId: string) => {
  return request({
    method: 'POST',
    url: 'file-download/bulk-payout-clarification-csv',
    data: { paymentOperationBatchId },
    responseType: 'blob',
  });
};

/**
 * Загрузить файл кларификации
 * @param paymentOperationBatchId,
 * @param file
 */
const uploadBatchClarification = ({ paymentOperationBatchId, file }) => {
  return request({
    method: 'POST',
    url: 'bulk-payouts/clarification-upload',
    data: { paymentOperationBatchId, file },
    headers: { 'Content-Type': 'multipart/form-data' },
    errorHandle: false,
  }).then(({ data }: any) => data.payload);
};

/**
 * @params
 * payoutQueueId
 * paymentOperationBatchId
 * field
 * value
 */
const updateField = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.BulkPayouts_ClarificationFieldUpdate,
        payload: { ...payload },
      },
    ],
  });
};

/**
 * @param paymentOperationBatchId
 */
const sendEditedClarification = (paymentOperationBatchId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.BulkPayouts_ClarificationProceed,
        payload: { paymentOperationBatchId },
      },
    ],
  });
};

/**
 *  @param paymentOperationBatchId
 *   @param payoutQueueIds[]
 */
const rejectPayouts = ({ paymentOperationBatchId, payoutQueueIds }) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.BulkPayouts_ClarificationReject,
        payload: { paymentOperationBatchId, payoutQueueIds },
      },
    ],
  });
};

export const createMoto = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.MotoPayment_Create,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

export const finalizeMoto = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.MotoPayment_Finalize,
        payload,
      },
    ],
  });
};

export default {
  getBulkPayouts,
  sendPayoutData,
  getPayoutStatus,
  sendClarification,
  rejectPayout,
  deletePayoutsFromBatch,
  uploadFile,
  sendBatch,
  getBatch,
  getBatchPreview,
  downloadBatchClarification,
  uploadBatchClarification,
  updateField,
  sendEditedClarification,
  rejectPayouts,
  uploadFileCaptureCancel,
  createMoto,
  finalizeMoto,
};
