import filtersValuesPerSection from 'constants/filtersValuesPerSection';
import Repository from 'helpers/Repository';
import { getDynamicDictionaries } from 'api/dictionaries';
import {
  updateFiltersValues,
  uploadFiltersValues,
} from 'actions/filtersValues';
import { AnyObject } from 'types/Common';

export default async (section, additionalSectionName?) => {
  const dispatch = Repository.get('store').dispatch;
  const filtersValues = Repository.get('store').getState().filtersValues;
  let filtersToLoad = Array.isArray(section)
    ? section
    : filtersValuesPerSection[section].filter(
        (filterName) => !filtersValues[filterName]
      );

  if (additionalSectionName) {
    filtersToLoad = [
      ...new Set(
        filtersToLoad.concat(
          filtersValuesPerSection[additionalSectionName] || []
        )
      ),
    ];
  }
  if (!filtersToLoad || !filtersToLoad.length) return;

  const filtersIsLoading = filtersToLoad.reduce((result, item) => {
    return {
      ...result,
      [item.name || item]: {
        ...filtersValues[item.name || item],
        isFetched: false,
        isLoading: true,
      },
    };
  }, {});
  dispatch(updateFiltersValues(filtersIsLoading));

  let dynamicFilters = await getDynamicDictionaries(filtersToLoad);

  dynamicFilters = dynamicFilters.reduce((result, item) => {
    return {
      ...result,
      [item.name]: {
        isFetched: true,
        isLoading: false,
        list: item.elements,
        hasMoreRows: item.hasMoreRows,
        params: item.params,
        error: item.status === 'error',
      },
    };
  }, {});

  dispatch(updateFiltersValues(dynamicFilters));
};

export const uploadFilter = async (filter) => {
  const dispatch = Repository.get('store').dispatch;

  dispatch(uploadFiltersValues({ name: filter.name, isLoading: true }));

  let uploadedFilters: AnyObject = await getDynamicDictionaries([filter]);
  uploadedFilters = uploadedFilters.map((item) => ({
    isFetched: true,
    isLoading: false,
    list: item.elements,
    hasMoreRows: item.hasMoreRows,
    name: item.name,
    error: item.status === 'error',
  }));

  dispatch(uploadFiltersValues(uploadedFilters[0]));
};
