import BaseMessageHandler from '../BaseMessageHandler';
import { updateUser } from 'actions/user';
import getStringWithSpecialSymbols from 'helpers/getStringWithSpecialSymbols';

export default class Profile_Get extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;

    const name = getStringWithSpecialSymbols(message.payload.name);
    dispatch(updateUser({ ...message.payload, name }));
  };
}
