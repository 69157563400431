import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import './financialTabEmptyState.scss';

interface Props {
  title?: string;
  text: string;
  picturePath?: string;
}

class FinancialTabEmptyState extends PureComponent<Props & IntlProps> {
  render() {
    const { title, text, picturePath, getTranslate } = this.props;

    return (
      <div className='financial-tab-empty-state'>
        <div className='currency-rates-empty-state__content'>
          {picturePath && (
            <img
              src={picturePath}
              alt={getTranslate(title)}
              className='financial-tab-empty-state__image'
            />
          )}
          {title && (
            <div className='financial-tab-empty-state__title'>
              {getTranslate(title)}
            </div>
          )}
          <div className='financial-tab-empty-state__text'>
            {getTranslate(text)}
          </div>
        </div>
      </div>
    );
  }
}

export default addTranslation(FinancialTabEmptyState);
