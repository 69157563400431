import React, { Component, FormEvent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Form from 'components/ui/form';
import Button from 'components/ui/button';
import Input from 'components/ui/input';

interface Props extends IntlProps {
  email: string;
  validation: any;
  settings: any;
  onChange: any;
  onReset: (event: FormEvent) => void;
  goBack: () => void;
}

class PasswordResetForm extends Component<Props> {
  private emailInput;

  componentDidMount() {
    this.emailInput.querySelector('.ui-input__field').focus();
  }

  render() {
    const { getTranslate, email, settings, onChange, goBack, validation } =
      this.props;

    return (
      <div className='login-form'>
        <Form onSubmit={this.resetPassword}>
          <div
            className='login-form__fields'
            ref={(el) => {
              this.emailInput = el;
            }}>
            <Input
              id='email'
              label={getTranslate('auth.email.label')}
              placeholder={getTranslate('auth.email.placeholder')}
              value={email}
              onChange={(event) => onChange('email', event.target.value)}
              error={validation && validation.email}
              modern
            />
          </div>
          <div className='login-form__row'>
            <Button
              icon='im-Arrow-left-Option-2'
              type='button'
              size='large'
              customClass='page-auth__btn page-auth__btn-back'
              onClick={goBack}
            />
            <Button
              text={getTranslate('auth.reset.button')}
              status='primary'
              type='submit'
              disabled={!this.canReset()}
              size='large'
              customClass='page-auth__btn ui-button_full-width'
            />
          </div>

          <div className='page-auth__link'>
            <a href={`mailto:${settings.supportEmail}`} className='utils-link'>
              {getTranslate('auth.contact.link')}
            </a>
          </div>
        </Form>
      </div>
    );
  }

  resetPassword = (event) => {
    const { onReset } = this.props;
    if (this.canReset()) {
      onReset(event);
    }
  };

  canReset = (): boolean => {
    const { email } = this.props;
    return Boolean(email);
  };
}

export default addTranslation(PasswordResetForm);
