import Messages from 'constants/rpcTypes';

export interface PayoutTab {
  id: string;
  type: string;
  permission: Messages;
  label: string;
  formText?: string;

  rejectButtonText?: string;
  clarificationButtonText?: string;
  repeatCreateButtonText?: string;
  createButtonText?: {
    create: string;
    clarification: string;
  };
}

export const payoutTabs: PayoutTab[] = [
  {
    id: 'single',
    type: 'single',
    permission: Messages.PaymentOperation_Payout,
    label: 'payouts.request.tabs.single',
    rejectButtonText: 'Reject Payout no lexeme',
    clarificationButtonText: 'payouts.request.sendRequestBtn',
    createButtonText: {
      create: 'payouts.request.sendRequestBtn',
      clarification: 'common.proceed.button',
    },
    repeatCreateButtonText: 'payouts.request.newRequestBtn',
  },
  {
    id: 'moto',
    type: 'moto',
    label: 'payouts.request.tabs.MOTO',
    permission: Messages.MotoPayment_Create,
    formText: 'payouts.request.moto.infoText',
  },
  {
    id: 'payout',
    type: 'payout',
    label: 'payouts.request.tabs.mass',
    permission: Messages.BulkPayouts_UploadPayout,
  },
  {
    id: 'refund',
    type: 'refund',
    label: 'payouts.request.tabs.mass.refund',
    permission: Messages.BulkPayouts_UploadRefund,
  },
  {
    id: 'capture',
    type: 'capture',
    label: 'payouts.request.tabs.capture',
    permission: Messages.BulkPayouts_UploadCapture,
  },
  {
    id: 'cancel',
    type: 'cancel',
    label: 'payouts.request.tabs.cancel',
    permission: Messages.BulkPayouts_UploadCancel,
  },
];
