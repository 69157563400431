import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import './clarificationLoader.scss';

interface Props extends IntlProps {
  state: undefined | string;
}

const ClarificationLoader: React.VFC<Props> = ({ state, getTranslate }) => (
  <div className='payout-clarification-loader'>
    <div className='payout-clarification-loader__spin'>
      <div className='payout-clarification-loader__spin-outer'>
        <div className='payout-clarification-loader__spin-inner' />
      </div>
    </div>
    <div className='payout-clarification-loader__text'>
      {getTranslate(
        state
          ? 'payouts.request.single.externalProcessing.text'
          : 'payouts.request.single.chackingClarification.text'
      )}
    </div>
  </div>
);

export default addTranslation(ClarificationLoader);
