import React from 'react';
import classNames from 'classnames';
import 'pages/getStarted/onboarding/components/card/card.scss';

interface Props {
  size: 's' | 'md' | 'lg';
  className?: string;
}

class Card extends React.Component<Props> {
  static defaultProps = {
    size: 'md',
  };

  render() {
    const { size, children, className } = this.props;
    return (
      <div className={classNames('card', `card_${size}`, className)}>
        {children}
      </div>
    );
  }
}

export default Card;
