import React, { PureComponent, ReactElement } from 'react';
import { getFinancialDataList } from 'api/financialData';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import TopPanelContainer from 'components/topPanel/TopPanelContainer';
import Button from 'components/ui/button';
import TableItemsManage from 'components/ui/tableItemsManage/TableItemsManage';
import DataListContainer from 'components/dataListContainer/DataListContainer';
import { Column, Header, Row } from 'components/ui/table';
import Checkbox from 'components/ui/checkbox/Checkbox';
import RoundButton from 'components/ui/roundButton';
import quickFiltersConfig from './quickFiltersConfig';
import tableNames from 'constants/tableNames';
import ItemConfiguration from 'types/ItemConfiguration';
import minFinancialDataTableColumnsWidth from './minFinancialDataTableColumnsWidth';

interface OwnProps {
  configuration: ItemConfiguration[];
  dataListLength: number;
  selectedAmount: number;
  checkedList: Record<number, boolean>;
  openModal: () => void;
  onDelete: () => void;
  handleCheck: (id) => void;
  selectAll: () => void;
  downloadFile: (id) => void;
  isDeleting: boolean;
  loadingFiles: string[];
}

type Props = IntlProps & OwnProps;

class FinancialData extends PureComponent<Props> {
  render() {
    return (
      <div className='financial-data'>
        <PageTemplate.Container>
          <TopPanelContainer
            name={tableNames.financialFile}
            filtersConfig={quickFiltersConfig}
            isConfigurationButton={false}
            isFilterButton={false}
            button={
              <Button
                status='primary'
                text={this.props.getTranslate(
                  'admin.uploadFinancialData.newData.button'
                )}
                onClick={this.props.openModal}
              />
            }
          />
          <TableItemsManage
            amount={this.props.selectedAmount}
            action={() => this.props.onDelete()}
            actionButtonStatus='danger'
            actionButtonText='admin.uploadFinancialData.deleteCsv.button'
            actionButtonLoading={this.props.isDeleting}
          />
          <div className='financial-data__table'>
            <DataListContainer
              minColumnsWidth={minFinancialDataTableColumnsWidth}
              apiRequest={getFinancialDataList}
              tableName={tableNames.financialFile}
              headerRender={this.headerRender()}
              rowRender={(data) => this.rowRender(data)}
              quickFilter={tableNames.financialFile}
            />
          </div>
        </PageTemplate.Container>
      </div>
    );
  }

  rowRender(data) {
    return (
      <Row key={data.financialFileId}>
        {this.props.configuration.map((config) => {
          let content = data[config.id];

          if (config.id === 'downloadCsv') {
            content = (
              <>
                <Checkbox
                  id={`checkbox-${data.financialFileId}`}
                  checked={
                    this.props.checkedList[data.financialFileId] || false
                  }
                  disabled={this.props.isDeleting}
                  onChange={() => this.props.handleCheck(data.financialFileId)}
                  customClass='financial-data__checkbox'
                />
                <RoundButton
                  status='success'
                  icon='im-Download1'
                  iconSize={11}
                  loading={this.props.loadingFiles.includes(
                    data.financialFileId
                  )}
                  onClick={() => this.props.downloadFile(data.financialFileId)}
                  customClass='financial-data__download-btn'
                />
              </>
            );
          }
          return (
            <Column
              key={`${config.id}-${data[config.id]}`}
              id={config.id}
              columnWidths={data.columnWidths}
              modifier={config.id}
              content={content || '-'}
              params={config}
            />
          );
        })}
      </Row>
    );
  }

  headerRender = () => {
    return (
      <Header>
        {this.props.configuration?.map(({ id, localId }) => {
          let content: ReactElement | string = this.props.getTranslate(localId);
          if (id === 'downloadCsv') {
            content = (
              <>
                <Checkbox
                  id='checkbox-select-all'
                  checked={Boolean(
                    this.props.selectedAmount &&
                      this.props.selectedAmount === this.props.dataListLength
                  )}
                  disabled={this.props.isDeleting}
                  onChange={this.props.selectAll}
                  customClass='financial-data__select-all'
                />
                {content}
              </>
            );
          }
          return <Column key={id} id={id} modifier={id} content={content} />;
        })}
      </Header>
    );
  };
}

export default addTranslation(FinancialData);
