import React from 'react';
import BaseMessageHandler from '../BaseMessageHandler';
import Button from 'components/ui/button';
import showNotification from 'components/ui/notification/showNotification';
import getLocalizedText from 'helpers/getLocalizedText';

export default class User_AccessUpdated extends BaseMessageHandler {
  success = () => {
    showNotification({
      content: (
        <div className='notification__inner'>
          <span className='notification__inner-text notification__inner-text_fixed-width'>
            {getLocalizedText('common.permissionsChangedReload.sysmsg')}
          </span>
          <Button
            text={getLocalizedText('common.reload.button')}
            status='outline'
            size='small'
            customClass='notification__inline-button'
            onClick={() => {
              window.location.reload();
            }}
          />
        </div>
      ),
      status: 'info',
    });
  };
}
