import BaseMessageHandler from '../BaseMessageHandler';
import { createAction } from 'helpers/redux';
import Token from 'helpers/Token';
import ACTIONS from 'constants/actionTypes';

export default class Auth_LoginToken extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;
    dispatch(
      createAction({
        type: ACTIONS.LOGIN_USER,
        payload: {
          user: message.payload.user,
        },
      })
    );

    Token.setTokens({ authToken: message.payload.authToken });
  };
}
