import { IFormDescriptorItem } from 'types/Analytics';

const topTenChartParams: Array<IFormDescriptorItem> = [
  {
    id: 'chartName',
    label: 'analytics.editForm.params.chartTitle.label',
    placeholder: 'analytics.editForm.params.chartTitle.placeholder',
    type: 'input',
  },
  {
    id: 'chartData',
    type: 'singleSelect',
    label: 'analytics.editForm.params.chartData.label',
    options: [
      {
        value: 'sum_channel_amount_in_rub',
        label: 'analytics.editForm.params.chartData.channelAmountRUB.item',
      },
      {
        value: 'sum_channel_amount_in_eur',
        label: 'analytics.editForm.params.chartData.channelAmountEUR.item',
      },
      {
        value: 'sum_channel_amount_in_usd',
        label: 'analytics.editForm.params.chartData.channelAmountUSD.item',
      },
      {
        value: 'sum_channel_amount_in_gbp',
        label: 'analytics.editForm.params.chartData.channelAmountGBP.item',
      },
      {
        value: 'count',
        label: 'analytics.editForm.params.chartData.number.item',
      },
    ],
  },
  {
    id: 'dimension',
    type: 'singleSelect',
    label: 'analytics.editForm.params.determineBy.label',
    options: [
      {
        value: 'paymentMethod',
        label: 'analytics.editForm.params.groupBy.paymentMethodType.item',
      },
      {
        value: 'issuerCountry',
        label: 'analytics.editForm.params.groupBy.countryByBIN.item',
      },
      {
        value: 'issuerBankName',
        label: 'analytics.editForm.params.groupBy.issuerBankName.item',
      },
      {
        value: 'mappingResultMessage',
        label: 'analytics.editForm.params.groupBy.declineReasons.item',
      },
    ],
  },
  {
    id: 'chartType',
    type: 'singleSelect',
    label: 'analytics.editForm.params.chartType.label',
    options: [
      { value: 'bar', label: 'analytics.editForm.params.chartType.bar.item' },
      {
        value: 'horizontal_bar',
        label: 'analytics.editForm.params.chartType.horizontalBar.item',
      },
    ],
  },
];

const topTenChartFilters: Array<IFormDescriptorItem> = [
  {
    id: 'period',
    type: 'date',
    label: 'analytics.editForm.params.period.label',
  },
  {
    id: 'projectId',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.project.label',
    dictionaryKey: 'projects',
  },
  {
    id: 'paymentMethod',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.paymentMethodType.label',
    dictionaryKey: 'paymentMethod',
    renderOptions: {
      hidden: {
        condition: (values) => {
          if ('dimension' in values) {
            return values.dimension === 'paymentMethod';
          }
          return false;
        },
      },
    },
  },
  {
    id: 'issuerCountry',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.countryByBIN.label',
    dictionaryKey: 'country',
    renderOptions: {
      hidden: {
        condition: (values) => {
          if ('dimension' in values) {
            return values.dimension === 'issuerCountry';
          }
          return false;
        },
      },
    },
  },
  {
    id: 'type',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.inOutType.label',
    options: [
      {
        id: 'payin',
        text: 'Pay-In',
      },
      {
        id: 'payout',
        text: 'Pay-Out',
      },
      {
        id: 'refund',
        text: 'Refund',
      },
    ],
  },
];

export { topTenChartParams, topTenChartFilters };
