import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';
import { filtersKeysType } from 'types/Filters';

export const changeQuickFilter = (
  name: string,
  key: filtersKeysType,
  value: any
) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.CHANGE_QUICK_FILTER,
        payload: {
          name,
          key,
          value,
        },
      })
    );
  };
};

export const resetPayoutQuickFilters = () => {
  return (dispatch, getState) => {
    const { permissions } = getState();
    const {
      PaymentOperation_Payout,
      BulkPayouts_UploadPayout,
      BulkPayouts_UploadRefund,
    } = permissions;
    let initialType = 'single';
    if (
      PaymentOperation_Payout.status !== 'enabled' &&
      (BulkPayouts_UploadPayout.status === 'enabled' ||
        BulkPayouts_UploadRefund.status === 'enabled')
    ) {
      initialType = 'mass';
    }
    dispatch(
      createAction({
        type: ACTIONS.RESET_PAYOUT_QUICK_FILTERS,
        payload: { payoutsType: [initialType] },
      })
    );
  };
};

export const resetConcreteQuickFilters = ({ name }: { name: string }) => {
  return (dispatch) => {
    if (name === 'payouts') {
      dispatch(resetPayoutQuickFilters());
    } else {
      dispatch(
        createAction({
          type: ACTIONS.RESET_CONCRETE_QUICK_FILTERS,
          payload: { name },
        })
      );
    }
  };
};

export const resetConcreteQuickFiltersProps = ({
  name,
  keys,
}: {
  name: string;
  keys: Array<string>;
}) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_CONCRETE_QUICK_FILTERS_PROPS,
        payload: { name, keys },
      })
    );
  };
};

export const resetQuickFilters = () => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_QUICK_FILTERS,
      })
    );
  };
};

export const initQuickFilters = (payload) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.INIT_QUICK_FILTERS,
        payload,
      })
    );
  };
};
