import { FilterTypes } from 'constants/FilterTypes';
import FormFieldsConfig from 'components/formFields/types';

// Attention! для корректной проверки на заполнение полей необходимо сохранять порядок их следования - paymentMethodCode должно предшествовать cardToken
const fieldsConfig: FormFieldsConfig = {
  general: [
    {
      id: 'projectId',
      fieldType: FilterTypes.select,
      valueType: 'string',
      required: true,
      label: 'payouts.request.moto.project.label',
      dictionaryId: 'motoPaymentProject',
      dictionaryValueKey: 'projectId',
    },
    {
      id: 'paymentId',
      fieldType: FilterTypes.text,
      valueType: 'string',
      required: true,
      generateValue: true,
      clearButton: true,
      label: 'payouts.request.moto.paymentID.label',
      placeholder: 'payouts.request.moto.paymentID.palceholder',
      subText: 'payouts.request.moto.paymentId.hint',
      tooltip: 'paymentIdGenerator.common.info.hint',
    },
    {
      id: 'motoType',
      fieldType: FilterTypes.select,
      valueType: 'number',
      required: true,
      label: 'payouts.request.moto.moto.label',
      dictionaryId: 'motoType',
      customOptions: true,
    },
    {
      id: 'paymentMethodCode',
      fieldType: FilterTypes.select,
      valueType: 'number',
      required: true,
      dictionaryId: 'paymentPageMethod',
      customOptions: true,
      label: 'payouts.request.moto.method.label',
    },
    [
      {
        id: 'amount',
        fieldType: FilterTypes.amount,
        valueType: 'string',
        required: true,
        label: 'payouts.request.moto.amount.label',
        placeholder: 'payouts.request.moto.amount.placeholder',
      },
      {
        id: 'currency',
        fieldType: FilterTypes.select,
        valueType: 'string',
        required: true,
        dictionaryId: 'currency',
        label: 'payouts.request.moto.currency.label',
        tooltip: 'select.currency.favorite.tooltip',
      },
    ],
  ],
  additional: [
    {
      id: 'cardToken',
      fieldType: FilterTypes.text,
      valueType: 'string',
      required: true,
      label: 'payouts.request.moto.token.label',
      placeholder: 'payouts.request.moto.token.label',
    },
    {
      id: 'customerId',
      fieldType: FilterTypes.text,
      valueType: 'string',
      required: true,
      label: 'payouts.request.moto.customerID.label',
      placeholder: 'payouts.request.moto.customerID.placeholder',
      subText: 'payouts.request.moto.customerId.hint',
    },
    {
      id: 'description',
      fieldType: 'textarea',
      valueType: 'string',
      required: false,
      label: 'payouts.request.moto.description.label',
      placeholder: 'payouts.request.moto.description.placeholder',
    },
  ],
};
export default fieldsConfig;
