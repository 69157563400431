import React, { Component } from 'react';
import { connect } from 'react-redux';

import apiDictionaries from 'api/dictionaries';
import apiFilters from 'api/filters';
import apiPermissions from 'api/permissions';
import apiUser from 'api/user';
import { initFilters } from 'actions/filters';
import {
  initAllConfiguration,
  updateConfiguration,
} from 'actions/configuration';
import { closeModal } from 'actions/modal';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import SocketService from 'helpers/SocketService';
import LocalStorage from 'helpers/LocalStorage';
import { isEmpty } from 'components/ui/table/helpers';
import UserActivityWatcher from 'components/userActivityWatcher';
import Loader from 'components/ui/loader';
import { AnyObject } from 'types/Common';
import ThemeConfig from 'types/ThemeConfig';
import App from './App';
import { StoreProps } from 'store';
import { IQuickActionItem } from 'pages/getStarted/overview/components/quickActions/QuickActionItem';
import { setQuickActions } from 'actions/quickActions';
import { quickActionsRoutes } from 'pages/getStarted/overview/components/quickActions/quickActionRoutes';
import getQuickActionsToSave from 'pages/getStarted/overview/components/quickActions/getQuickActionsToSave';
import Messages from 'constants/rpcTypes';

interface ConnectedProps {
  user: AnyObject;
  app: AnyObject;
  permissions: boolean;
  configuration: AnyObject;
  filtersValues: AnyObject;
  filters: AnyObject;
  settings: {
    themeConfig: ThemeConfig;
    wl: string;
    wlId: number;
  };
  isConfirmLogout: boolean;
  quickActions: { isFetched: boolean; items: IQuickActionItem[] };
}

type Props = ConnectedProps & StoreProps & WithPermissions;

class AppContainer extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.setUserId();
    this.attachChangeUserIdListener();
  }

  async componentDidMount() {
    await Promise.all([
      this.initFiltersList(),
      this.getTableColumns(),
      this.getPermissions(),
      this.getConfig(),
      this.fetchQuickActions(),
      SocketService.init(),
    ]);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.permissions !== this.props.permissions &&
      !this.props.quickActions.isFetched
    ) {
      this.fetchQuickActions();
    }
  }

  componentWillUnmount(): void {
    const { dispatch } = this.props;
    dispatch(closeModal());
    SocketService.destroy();
  }

  render() {
    const { user, settings, app, configuration, isConfirmLogout } = this.props;
    if (!this.appIsReady()) return <Loader />;

    return (
      <>
        <UserActivityWatcher instanceId={app.instanceId} />
        <App
          user={user}
          settings={settings}
          isMenuExpanded={configuration.isMenuExpanded}
          isConfirmLogout={isConfirmLogout}
          saveRoute={this.saveRoute}
          updateConfig={this.updateConfig}
        />
      </>
    );
  }

  async getTableColumns() {
    const { dispatch } = this.props;

    try {
      const list = await apiDictionaries.getTableColumns();
      dispatch(initAllConfiguration(list));
    } catch (e) {}
  }

  async initFiltersList() {
    const { dispatch } = this.props;

    try {
      const filters = await apiFilters.getFiltersList();
      dispatch(initFilters(filters));
    } catch (e) {}
  }

  async getPermissions() {
    try {
      await apiPermissions.getPermissions();
    } catch (e) {}
  }

  async getConfig() {
    try {
      const { isMenuExpanded } = await apiUser.getConfig({
        param: 'isMenuExpanded',
      });
      this.props.dispatch(updateConfiguration({ isMenuExpanded }));
    } catch (e) {
      console.error('Error occurred while getting menu config', e);
    }
  }

  setUserId = () => {
    const { user } = this.props;
    if (user) {
      LocalStorage.set('userId', user.id);
    }
  };

  attachChangeUserIdListener = () => {
    LocalStorage.addEventListener((event) => {
      const { key, oldValue, newValue } = event;
      if (key === 'userId' && oldValue && newValue && oldValue !== newValue) {
        window.location.reload();
      }
    });
  };

  appIsReady = (): boolean => {
    const { app, filters, configuration, permissions } = this.props;
    return !!app && !!filters && !!configuration && permissions;
  };

  saveRoute = async (route) => {
    if (
      !this.props.isEnabled(Messages.GetStarted_View) ||
      !this.props.isEnabled(Messages.UserConfig_Set)
    )
      return;

    const {
      quickActions: { items },
    } = this.props;
    if (!quickActionsRoutes[route]) return;
    const actions = items || [];
    const existedRouteIndex = actions?.findIndex((item) => item.id === route);
    if (actions[existedRouteIndex]?.isFavorite) return;

    let actionsToSet;
    if (existedRouteIndex > -1) {
      if (actions[existedRouteIndex - 1]?.isFavorite) return;

      const filteredActions = actions.filter((_, i) => i !== existedRouteIndex);
      const lastFavoriteIndex = filteredActions.findIndex(
        (item, i) =>
          filteredActions[i].isFavorite && !actions[i + 1]?.isFavorite
      );
      actionsToSet = [
        ...filteredActions?.slice(0, lastFavoriteIndex + 1),
        { id: route },
        ...filteredActions?.slice(lastFavoriteIndex + 1),
      ];
    } else {
      actionsToSet = [{ id: route }, ...(actions || [])];
    }
    try {
      const { quickActions } = await apiUser.setQuickActions(
        getQuickActionsToSave(actionsToSet)
      );

      this.props.dispatch(setQuickActions(quickActions));
    } catch (e) {
      console.error('Set quick actions error: ', e);
    }
  };

  fetchQuickActions = async () => {
    if (!this.props.isEnabled(Messages.GetStarted_View)) return;

    try {
      const { quickActions } = await apiUser.getConfig({
        param: 'quickActions',
      });
      this.props.dispatch(setQuickActions(quickActions));
    } catch (e) {
      console.error('Fetch quick actions error:', e);
    }
  };

  updateConfig = (isOpen) => {
    if (!this.props.isEnabled(Messages.UserConfig_Set)) return;
    try {
      this.props.dispatch(updateConfiguration({ isMenuExpanded: isOpen }));
      apiUser.setConfig({ param: 'isMenuExpanded', value: isOpen });
    } catch (e) {
      console.error('Error occurred while updating menu config', e);
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
  app: state.app,
  permissions: !isEmpty(state.permissions),
  configuration: state.configuration,
  filtersValues: state.filtersValues,
  filters: state.filters,
  settings: state.settings,
  isConfirmLogout: state.app.isConfirm,
  quickActions: state.quickActions,
});

export default connect(mapStateToProps)(addPermissions(AppContainer));
