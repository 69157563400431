const shouldContainCurrency = (field, value, formValues) => {
  if (
    formValues['groupByColumns'].includes('currency') === false &&
    formValues['groupByRows'].includes('currency') === false
  ) {
    return 'analytics.editForm.params.groupBy.needCurrency.text';
  }

  return undefined;
};

const notEmpty = (field = '') => {
  if (field === '') {
    return 'Must not be empty';
  }
};

export { shouldContainCurrency, notEmpty };
