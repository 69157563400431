import classNames from 'classnames';
import Button from '../../ui/button';
import React from 'react';
import { connect } from 'react-redux';

type Props = {
  refreshAction: () => void;
  customButtonClass?: string;
};

const RefreshButton = ({ customButtonClass = '', refreshAction }: Props) => {
  return (
    <Button
      id='refresh-button'
      status='light'
      onClick={refreshAction}
      icon='im-Refresh'
      customClass={classNames(
        'button-refresh ui-button_no-border',
        customButtonClass
      )}
    />
  );
};

export default connect(() => {})(RefreshButton);
