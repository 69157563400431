import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';

export const updateFiltersValues = (list) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPDATE_FILTERS_VALUES,
        payload: {
          list,
        },
      })
    );
  };
};

export const uploadFiltersValues = (filter) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPLOAD_FILTERS_VALUES,
        payload: {
          ...filter,
        },
      })
    );
  };
};
