import React from 'react';
import queryString from 'query-string';
import { WithRouterProps } from 'decorators/withRouter';
import Chargebacks from './Chargebacks';

type Props = WithRouterProps<{ id?: string }>;

const ChargebacksContainer: React.VFC<Props> = ({ match, location }) => {
  const { type, projectId } = queryString.parse(location.search);
  const isPayment = Boolean(type && type === 'payment');

  return (
    <Chargebacks
      id={match.params.id}
      isPayment={isPayment}
      projectId={projectId}
    />
  );
};

export default ChargebacksContainer;
