import React, { PureComponent } from 'react';
import * as Redux from 'redux';

import CustomScrollbar from 'components/ui/customScrollbar';
import Animation from 'components/ui/animation';
import PolicyLinks from '../app/components/policyLinks';
import path from 'helpers/path';
import ThemeConfig from 'types/ThemeConfig';
import './policy.scss';

interface Props {
  history?: any;
  dispatch?: Redux.Dispatch<any>;
  children?: any;
  user?: null | { id: string };
  settings: {
    themeConfig: ThemeConfig;
  };
}

class PolicyContainer extends PureComponent<Props> {
  render() {
    const {
      settings: { themeConfig },
    } = this.props;

    return (
      <div className='policy'>
        <div className='policy__header'>
          <div className='policy__logo' onClick={this.awayFromPolicy}>
            {themeConfig?.logoUrl && (
              <img src={themeConfig.logoUrl} alt='logo' />
            )}
          </div>
        </div>

        <div className='policy__scroll'>
          <CustomScrollbar>
            <div className='policy__content'>
              <Animation>{this.props.children}</Animation>
            </div>
          </CustomScrollbar>
          <div className='policy__footer'>
            <PolicyLinks />
          </div>
        </div>
      </div>
    );
  }

  awayFromPolicy = () => {
    const { history } = this.props;
    if (this.props.user && this.props.user.id) {
      history.push(path('/'));
    } else {
      history.push(path('/login'));
    }
  };
}

export default PolicyContainer;
