import { ReactComponent as arrowRight } from './arrow-right.svg'
import { ReactComponent as information } from './information.svg'
import { ReactComponent as download } from './download.svg'
import { ReactComponent as upload } from './upload.svg'
import { ReactComponent as maf } from './maf.svg'
import { ReactComponent as notifications } from './notifications.svg'
import { ReactComponent as settings } from './settings.svg'
import { ReactComponent as pointerRight } from './pointer-right.svg'
import { ReactComponent as empty } from './empty.svg'
import { ReactComponent as eye } from './eye.svg'
import { ReactComponent as trash } from './trash.svg'
import { ReactComponent as expand } from './expand.svg'
import { ReactComponent as showMore } from './showMore.svg'
import { ReactComponent as top10question } from './top10question.svg';
import { ReactComponent as dropdownTriangle } from './dropdownTriangle.svg';
import { ReactComponent as dataset } from './dataset.svg';
import { ReactComponent as share } from './share.svg';
import { ReactComponent as person } from './person.svg';
import { ReactComponent as payments } from './payments.svg';
import { ReactComponent as fact_check } from './fact_check.svg';

export default {
  arrowRight,
  information,
  download,
  upload,
  maf,
  empty,
  eye,
  trash,
  notifications,
  settings,
  pointerRight,
  expand,
  showMore,
  top10question,
  dropdownTriangle,
  person,
  share,
  dataset,
  payments,
  fact_check,
} as any;
