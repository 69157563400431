import React, { ReactNode } from 'react';
import DOMPurify from 'dompurify';
import { v4 as uuid } from 'uuid';

type Tag = { tagName: string; wrapper: (ReactNode) => ReactNode };

const defaults = {
  jsx: {
    b: (content) => (
      <span key={uuid()} className='utils-ui-block__text-bold'>
        {content}
      </span>
    ),
    i: (content) => (
      <span key={uuid()} className='utils-ui-block__text-light-italic'>
        {content}
      </span>
    ),
    a: (content) => (
      <span key={uuid()} className='utils-link'>
        {content}
      </span>
    ),
    br: () => <br key={uuid()} />,
  },
  html: {
    b: (content) => `<span class='utils-ui-block__text-bold'>${content}</span>`,
    i: (content) =>
      `<span class='utils-ui-block__text-light-italic'>${content}</span>`,
    a: (content) => `<span class='utils-link'>${content}</span>`,
    br: () => `<br>`,
  },
};

const getTagType = (string, startIndex) => {
  const restString = string.slice(startIndex + 1);
  return restString.slice(0, restString.indexOf('|'));
};

const parseMarkdownLinksToHtml = (string: string) => {
  const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
  return string.replace(regex, '<a href="$2">$1</a>');
};

export default class I18nHelpers {
  /*
  get structure like this: { 'a': [wrapper1, wrapper2], 'b': [wrapper1, ...] } -- easier to access needed wrapper
 */
  static mapTags = (tags: Tag[] = []) =>
    tags.reduce((acc, tag) => {
      const { tagName, wrapper } = tag;
      if (acc[tagName]) {
        acc[tagName].push(wrapper);
      } else {
        acc[tagName] = [wrapper];
      }
      return acc;
    }, {});

  static getJsx = (str: string, tags: Tag[] = []): ReactNode => (
    <>{I18nHelpers.parseString(str, I18nHelpers.mapTags(tags))}</>
  );

  static getJsxFromHtml = (str: string): ReactNode => (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(I18nHelpers.getHtmlString(str)),
      }}
    />
  );

  static getHtmlString = (str: string, tags: Tag[] = []): string => {
    return I18nHelpers.parseString(
      str,
      I18nHelpers.mapTags(tags),
      defaults.html
    ).join('');
  };

  static parseString = (
    initialMessage,
    tags = {},
    defaultWrappers: any = defaults.jsx,
    tagsMet = {
      a: 0,
      b: 0,
      i: 0,
    },
    initialIndex = 0
  ) => {
    const result: { [key: number]: ReactNode } = {};
    let index = initialIndex;

    let currTag: string | null = null;
    let currWrapper: ((node: ReactNode) => ReactNode) | null = null;
    let tagIndex = 0;
    let tagContent = [''] as ReactNode[];
    let currWord = '';

    while (index <= initialMessage.length) {
      const char = initialMessage[index];
      if (!char) {
        result[index - 1] = parseMarkdownLinksToHtml(currWord);
        break;
      }
      if (char !== '|') {
        if (currWrapper) {
          tagContent[tagContent.length - 1] += char;
          index++;
          continue;
        }
        currWord += char;
        index++;
        continue;
      }
      result[index - 1] = currWord;
      currWord = '';

      const tagType = getTagType(initialMessage, index); // a, b, i

      if (tagType === 'br') {
        result[index] = defaultWrappers.br();
        index = index + 4;
        continue;
      }

      if (currTag) {
        if (tagType === currTag) {
          // closing tag found
          result[tagIndex] = currWrapper!(tagContent);
          currTag = null;
          currWrapper = null;
          tagContent = [''];
          index = index + 3;
          continue;
        }
        const restString = initialMessage.slice(index + 3);
        const tag = `|${tagType}|`;
        const tagEnclosedString = `${tag}${restString.slice(
          0,
          restString.indexOf(tag)
        )}${tag}`;

        tagContent.push(
          I18nHelpers.parseString(tagEnclosedString, tags, tagsMet) // put inside a tag
        );

        tagContent.push('');
        index = index + tagEnclosedString.length;
        continue;
      }
      //put wrapper
      currWrapper =
        tags[tagType]?.[tagsMet[tagType]] || defaultWrappers[tagType];
      if (currWrapper) {
        currTag = tagType;
        tagIndex = index;
        tagsMet[tagType]++;
        index = index + 3;
        continue;
      }
      //otherwise keep putting strings
      currWord += char;
      index++;
    }

    return Object.values(result);
  };
}
