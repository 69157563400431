import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Switch from 'components/ui/switch';

import Button from 'components/ui/button';
import ModalTitle from 'components/modal/components/ModalTitle';
import ModalContent from 'components/modal/components/ModalContent';
import './cookieSettings.scss';

interface Props extends IntlProps {
  callback: (data: any) => void;
  content: {
    title?: string;
    text: string;
  };
}

interface State {
  essential: boolean;
  performance: boolean;
}

class CookieSettings extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      essential: true,
      performance: true,
    };
  }
  render() {
    const { getTranslate, callback } = this.props;
    const { essential, performance } = this.state;
    return (
      <div className='cookie-settings'>
        <ModalTitle>{getTranslate('cookies.settings.label')}</ModalTitle>

        <ModalContent>
          <div className='cookie-settings__item'>
            <div className='cookie-settings__item-header'>
              <span className='cookie-settings__item-title'>
                {getTranslate('cookies.settings.essential.label')}
              </span>
              <Switch
                id='essential-cookies'
                checked={essential}
                disabled
                onChange={() => null}
              />
            </div>

            <p className='cookie-settings__text'>
              {getTranslate('cookies.settings.essential.text')}
            </p>
          </div>
          <div className='cookie-settings__item'>
            <div className='cookie-settings__item-header'>
              <span className='cookie-settings__item-title'>
                {getTranslate('cookies.settings.functional.label')}
              </span>
              <Switch
                id='performance-cookies'
                checked={performance}
                onChange={this.setCheckbox}
              />{' '}
            </div>

            <p className='cookie-settings__text'>
              {getTranslate('cookies.settings.functional.text1', {
                text1a: (
                  <span className='cookie-settings__accent'>
                    {getTranslate('cookies.settings.functional.text1a')}
                  </span>
                ),
                node: true,
              })}
            </p>
            <p className='cookie-settings__text'>
              {getTranslate('cookies.settings.functional.text2', {
                text2a: (
                  <span className='cookie-settings__accent'>
                    {getTranslate('cookies.settings.functional.text2a')}
                  </span>
                ),
                node: true,
              })}
            </p>
          </div>
        </ModalContent>
        <div className='cookie-settings__footer'>
          <Button
            text={getTranslate('cookies.popup.settings.saveSettings.button')}
            status='outline'
            customClass='cookie-settings__btn'
            onClick={() => callback(this.state)}
          />
          <Button
            text={getTranslate('cookies.popup.acceptAll.button')}
            status='primary'
            customClass='cookie-settings__btn'
            onClick={() => callback({ essential: true, performance: true })}
          />
        </div>
      </div>
    );
  }
  setCheckbox = () => {
    const { performance } = this.state;
    this.setState({ performance: !performance });
  };
}

export default addTranslation(CookieSettings);
