import { batch } from 'react-redux';
import BaseMessageHandler from '../BaseMessageHandler';
import {
  newUserNotificationsItem,
  updateUserNotificationsCounts,
} from 'actions/userNotifications';

export default class UserNotification extends BaseMessageHandler {
  default = () => {
    const { dispatch, message } = this;
    batch(() => {
      dispatch(newUserNotificationsItem(message.payload));
      dispatch(
        updateUserNotificationsCounts({
          unread: 1,
        })
      );
    });
  };
}
