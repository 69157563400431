import BaseMessageHandler from '../BaseMessageHandler';
import { openModal } from 'actions/modal';
import { updateUser } from 'actions/user';
import Token from 'helpers/Token';

export default class Auth_ConfirmSecondFactorRequest extends BaseMessageHandler {
  default = () => {
    const { dispatch, response } = this;

    dispatch(
      openModal({
        modalId: 'TwoFactorAuth',
        content: {
          authToken: response.data.authToken,
        },
        onClose: () => {
          dispatch(updateUser({ validationErrors: {} }));
        },
      })
    );
    Token.clearTokens();
  };
}
