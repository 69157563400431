import ActionReducer from 'types/ActionReducer';
import ACTIONS from 'constants/actionTypes';

interface State {
  wl: string;
}

const initialState: State = {
  wl: '',
};

export const settings = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_WL:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default settings;
