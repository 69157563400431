import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Data, DataItem, MultiLineData, TableData } from './types';
import './statStatementData.scss';

interface Props {
  text: string;
  data: Data;
}

class StatStatementData extends PureComponent<Props> {
  render() {
    const { data } = this.props;

    return (
      <div className='statement-data'>
        <div className='statement-data__inner'>
          <div className='statement-data__content'>
            <div className='statement-data__note'>{this.props.text}</div>
            {data.header && this.renderHeader()}
            {data.dataFormations.map((dataFormation) =>
              this.getRender(dataFormation)
            )}
          </div>
        </div>
      </div>
    );
  }

  renderHeader = () => {
    const {
      data: { header },
    } = this.props;
    const cols = header.data.reduce<DataItem[][]>((columns, item, i) => {
      if (i % 4 === 0) {
        return [...columns, [item]];
      }

      return [...columns.slice(0, -1), [...columns[columns.length - 1], item]];
    }, []);

    return (
      <div className='statement-data-header statement-data-bg'>
        {cols.map((item, i) => {
          return (
            <div className='statement-data-header__col' key={`column-${i}`}>
              {item.map((cell) => {
                return (
                  <div
                    className='statement-data-header__cell'
                    key={`${cell.title}-header-cell`}>
                    <div className='statement-data-header__key statement-data-key'>
                      {cell.title}
                    </div>
                    <div className='statement-data-header__value'>
                      {cell.value}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  getRender = (dataFormation) => {
    switch (dataFormation.type) {
      case 'singleLineDataFormation':
        return this.renderSingleLine(dataFormation.data);
      case 'multiLineDataFormation':
        return this.renderMultiLine(dataFormation.data);
      case 'tableDataFormation':
        return this.renderTable(dataFormation.data);
    }
  };

  renderSingleLine = (data: DataItem) => {
    const tableDataFormation:
      | { type: 'tableDataFormation'; data: TableData }
      | undefined = this.props.data.dataFormations.find(
      (dataFormation) => dataFormation.type === 'tableDataFormation'
    ) as { type: 'tableDataFormation'; data: TableData } | undefined;
    const columnCount = tableDataFormation?.data.columns.length || 0;

    return (
      <div
        className='statement-data-table statement-data-table_single'
        key={`${data.title}-single`}>
        <div className='statement-data-table__row statement-data-key statement-data-bg'>
          <div
            className='statement-data-table__cell statement-data-table__cell_title'
            key={`${data.title}-single-cell`}>
            {data.title}
          </div>
          {columnCount
            ? new Array(columnCount - 2).fill('').map((_, i) => {
                return (
                  <div
                    className='statement-data-table__cell'
                    key={`ghost-cell-${i}`}
                  />
                );
              })
            : null}
          <div
            className='statement-data-table__cell'
            key={`${data.currency}-single-cell`}>
            {data.currency}
          </div>
          <div
            className='statement-data-table__cell'
            key={`${data.value}-single-cell`}>
            {data.value}
          </div>
        </div>
      </div>
    );
  };

  renderMultiLine = (data: MultiLineData) => {
    return (
      <div
        className='statement-data-table statement-data-table_multiline'
        key={`${data.title}-multiline`}>
        <div
          className='statement-data-table__row statement-data-key  statement-data-bg'
          key={`${data.title}-multiline`}>
          <div className='statement-data-table__cell statement-data-table__cell_title'>
            {data.title}
          </div>
        </div>
        {data.rows.map((row, i) => {
          return (
            <div
              className='statement-data-table__row statement-data-table__row_double-cell'
              key={`${row.title}-${i}`}>
              <div className='statement-data-table__cell'>{row.title}</div>
              <div className='statement-data-table__cell'>{row.value}</div>
            </div>
          );
        })}
        <div
          className='statement-data-table__row statement-data-table__row_totals statement-data-table__row_double-cell statement-data-key statement-data-bg'
          key={`${data.total}-total-multiline`}>
          <div className='statement-data-table__cell'>Total</div>
          <div className='statement-data-table__cell'> {data.total}</div>
        </div>
      </div>
    );
  };

  renderTable = (data: TableData) => {
    return (
      <div className='statement-data-table' key={`${data}-table`}>
        <div
          className={classNames(
            'statement-data-table__header statement-data-bg statement-data-key',
            {
              'statement-data-table__header_no-title': !data.title,
            }
          )}>
          {data.title && (
            <div
              className='statement-data-table__cell statement-data-table__cell_title'
              key={`${data.title}-table-header`}>
              {data.title}
            </div>
          )}
          {data.columns.map((column, i) => {
            return (
              <div
                className='statement-data-table__cell'
                key={`${column.title}-${i}`}>
                {column.title}
              </div>
            );
          })}
        </div>
        <div className='statement-data-table__body'>
          {data.rows.map((row, i) => {
            return (
              <div
                className='statement-data-table__row'
                key={`${row.title}-${i}`}>
                <div className='statement-data-table__cell statement-data-table__cell_title'>
                  {row.title}
                </div>
                {data.columns.map((column, index) => {
                  return (
                    <div
                      className='statement-data-table__cell'
                      key={`${column.title}-${index}`}>
                      {row.cells[column.id]}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className='statement-data-table__row statement-data-table__row_totals statement-data-key statement-data-bg'>
          <div
            className='statement-data-table__cell statement-data-table__cell_title'
            key='total-title'>
            Total
          </div>
          {data.columns.map((column, i) => {
            return (
              <div
                className='statement-data-table__cell'
                key={`${column.title}-${i}`}>
                {data.totals[column.id]}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
}

export default StatStatementData;
