import ACTIONS from 'constants/actionTypes';
import Utils from 'helpers/Utils';
import ActionReducer from 'types/ActionReducer';
import { AnyObject } from 'types/Common';
import { Redirect, RedirectError } from 'pages/projects/types';

interface State {
  isLoading: boolean;
  canSaveRedirectSettings: boolean;
  canSavePaymentMethods: boolean;
  canSaveSalt: string;
  isEditable: boolean;
  redirectHistory: AnyObject;
  project: {
    url: string;
    name: string;
    projectId: string;
    isSaltSpecified: boolean;
  };
  isPaymentPageEditorEnabled: boolean;
  paymentPageEditorUrl: string;
  salt: string;
  redirectSuccess: Redirect;
  redirectFail: Redirect;
  redirectTokenize: Redirect;
  merchantBackSuccess: Redirect;
  merchantBackFail: Redirect;
  paymentMethods: AnyObject;
  paymentMethodsStatuses: AnyObject;
  oldData: {
    redirectSettings: AnyObject;
    paymentMethodsStatuses: AnyObject;
  };
  validationErrors: {
    redirectSuccess: RedirectError;
    redirectFail: RedirectError;
    redirectTokenize: RedirectError;
    merchantBackSuccess: RedirectError;
    merchantBackFail: RedirectError;
    [key: string]: any;
  };
}

const initialState: State = {
  isLoading: true,
  canSaveRedirectSettings: false,
  canSavePaymentMethods: false,
  canSaveSalt: '',
  isEditable: false,
  redirectHistory: {},
  validationErrors: {
    redirectSuccess: { url: '' },
    redirectFail: { url: '' },
    redirectTokenize: { url: '' },
    merchantBackSuccess: { url: '' },
    merchantBackFail: { url: '' },
    salt: '',
  },
  project: {
    url: '',
    name: '',
    projectId: '',
    isSaltSpecified: false,
  },
  isPaymentPageEditorEnabled: false,
  paymentPageEditorUrl: '',
  salt: '',
  redirectSuccess: {
    url: '',
    mode: 0,
    enabled: 0,
    key: '',
  },
  redirectFail: {
    url: '',
    mode: 0,
    enabled: 0,
    key: '',
  },
  redirectTokenize: {
    url: '',
    mode: 0,
    enabled: 0,
    key: '',
  },
  merchantBackSuccess: {
    url: '',
    mode: 0,
    enabled: 0,
    key: '',
  },
  merchantBackFail: {
    url: '',
    mode: 0,
    enabled: 0,
    key: '',
  },

  paymentMethods: {},
  paymentMethodsStatuses: [],
  oldData: {
    redirectSettings: {},
    paymentMethodsStatuses: [],
  },
};

const projectSettings = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_PROJECT:
      return {
        ...initialState,
        ...payload,
      };
    case ACTIONS.UPDATE_PROJECT:
      return {
        ...state,
        ...payload,
      };
    case ACTIONS.UPDATE_PROJECT_FIELD:
      if (!Utils.hasProp(state, payload.key)) {
        return state;
      }
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case ACTIONS.RESET_PROJECT:
      return initialState;
    default:
      return state;
  }
};

export default projectSettings;
