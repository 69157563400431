import { AnyObject } from 'types/Common';

class Utils {
  /**
   * Получить рандомный хеш
   */
  static getHash(): string {
    return Math.random().toString(36).slice(7);
  }

  /**
   * Получить случайное число в интервале от min до max
   */
  static randomIntFromInterval(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /**
   * Склонение числительных
   * @param number - количество
   * @param words - массив слов, например ['получен', 'получено', 'получены']
   */
  static declOfNum(number: number, words: string[]): string {
    const cases = [2, 0, 1, 1, 1, 2];
    return words[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  }

  /**
   * Преобразование переданного числа в строку с пробелами. Например 10000.00 -> 10 000.00
   */
  static getNumberWithSpace(value: number | string, groupSize = 3): string {
    const regexp = new RegExp(`\\B(?=(\\d{${groupSize}})+(?!\\d))`, 'gi');
    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(regexp, ' ');
    return parts.join('.');
  }

  /**
   * 10 000.00 -> 10000.00
   * @param value
   */
  static getNumberWithoutSpace(value: number | string): string {
    return value.toString().replace(/ /gi, '');
  }

  /**
   *
   * @param array
   * @param key
   */
  static uniqBy(array, key) {
    const keysMap = {};
    return array.filter((item) => {
      if (!item[key]) return true;
      if (!keysMap[item[key]]) {
        keysMap[item[key]] = true;
        return true;
      }
      return false;
    });
  }

  /**
   *
   * @param object
   * @param key
   */
  static hasProp(object: AnyObject, key: string): boolean {
    return Object.prototype.hasOwnProperty.call(object, key);
  }
}

export default Utils;
