import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getReportTemplates, deleteTemplate } from 'api/reports';
import { update } from 'actions/templateBuilder';
import BuilderPatterns from './BuilderPatterns';
import { addListeners } from 'decorators/addListeners';
import MetricService from 'helpers/metricService/MetricService';
import { Pattern } from 'types/ReportsTempateBuilder';
import { AnyObject } from 'types/Common';
import Messages from 'constants/rpcTypes';
import { StoreProps } from 'store';

interface OwnProps {
  onLoadTemplate: (id: string) => void;
}

interface ConnectedProps {
  fields: AnyObject;
}

type Props = OwnProps & ConnectedProps & StoreProps;

interface State {
  isFetched: boolean;
  systemTemplates: Pattern[];
  userTemplates: Pattern[];
}

@addListeners([Messages.ReportTemplate_RemoveTemplateFlag])
class BuilderPatternsContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isFetched: false,
      systemTemplates: [],
      userTemplates: [],
    };
  }

  async componentDidMount() {
    await this.fetchTemplates();
  }

  render() {
    const { fields } = this.props;
    const { systemTemplates, userTemplates, isFetched } = this.state;

    return (
      <BuilderPatterns
        isFetched={isFetched}
        systemTemplates={systemTemplates}
        userTemplates={userTemplates}
        fields={fields}
        onUseTemplate={(id) => this.useTemplate(id)}
        onDeleteTemplate={(id) => this.deleteTemplate(id)}
      />
    );
  }

  async fetchTemplates() {
    try {
      const { userTemplates, systemTemplates } = await getReportTemplates();
      this.setState({ userTemplates, systemTemplates, isFetched: true });
    } catch (error) {
      console.error(error);
    }
  }

  async useTemplate(reportTemplateId: string) {
    const { onLoadTemplate, dispatch } = this.props;

    dispatch(update('isTemplateFetching', true));
    dispatch(update('isTemplate', true));

    try {
      await onLoadTemplate(reportTemplateId);
    } finally {
      dispatch(update('isTemplateFetching', false));
    }
  }

  deleteTemplate = async (reportTemplateId: string) => {
    try {
      await deleteTemplate(reportTemplateId);
    } catch (error) {
      console.error('Delete template error: ', error);
    }
  };

  onEvent = ({ data: { payload } }) => {
    MetricService.send({
      action: 'click',
      actionKey: 'reports.newReport.deleteTemplate',
    });
    this.setState((state) => ({
      userTemplates: state.userTemplates.filter(
        (template) => template.reportTemplateId !== payload.reportTemplateId
      ),
    }));
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  fields: state.templateBuilder.fields,
});

export default connect(mapStateToProps)(BuilderPatternsContainer);
