import { updateEntity } from './savedEntities';
import savedEntities from 'constants/savedEntities';
import api from 'api/recurring';

export const getRecurring = (id: string, onReady) => {
  return (dispatch) => {
    api
      .getRecurringById(id)
      .then((data) => {
        dispatch(
          updateEntity({
            entityKey: savedEntities.recurring,
            id,
            fields: {
              data,
            },
          })
        );
        onReady();
      })
      .catch(({ error }) => {
        dispatch(
          updateEntity({
            entityKey: savedEntities.recurring,
            id,
            fields: {
              data: {
                error,
              },
            },
          })
        );
        onReady();
      });
  };
};
