import React, { PureComponent } from 'react';
import DataSection from '../dataSection';
import UIHelpers from 'helpers/ui';
import { AnyObject } from 'types/Common';

interface Props {
  tableName: string;
  data: any;
  isLoading: boolean;
  isUserCanEdit: boolean;
  configuration: AnyObject;
  changeData: (rowId, colId, value, isCurrency, isOver?) => void;
}

class TableTop extends PureComponent<Props> {
  componentDidMount() {
    setTimeout(UIHelpers.initResizeEvent, 100);
  }

  render() {
    const {
      tableName,
      data,
      isLoading,
      configuration,
      changeData,
      isUserCanEdit,
    } = this.props;

    return (
      <div className='reconciliation-page__section'>
        <DataSection
          tableName={tableName}
          data={data}
          configuration={configuration}
          changeData={changeData}
          isLoading={isLoading}
          isUserCanEdit={isUserCanEdit}
        />
      </div>
    );
  }
}

export default TableTop;
