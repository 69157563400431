import layoutsList from './list';

export default {
  name: 'layouts',
  display: 'submenu',
  title: 'Content Layouts',
  buttonClass: '',
  innerHTML:
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>',

  add(core, targetElement) {
    const context = core.context;
    context.template = {};

    let templateDiv = this.setSubmenu(core);
    templateDiv
      .querySelector('ul')
      .addEventListener('click', this.pickup.bind(core));
    core.initMenuTarget(this.name, targetElement, templateDiv);
    templateDiv = null;
  },

  setSubmenu(core) {
    const listDiv = core.util.createElement('DIV');
    listDiv.className = 'se-list-layer';

    let list =
      // eslint-disable-next-line no-useless-concat
      '<div class="se-submenu se-list-inner">' + '<ul class="se-list-basic">';
    for (let i = 0, len = layoutsList.length, t; i < len; i++) {
      t = layoutsList[i];
      list +=
        '<li><button type="button" class="se-btn-list" data-value="' +
        i +
        '">' +
        t.name +
        '</button></li>';
    }
    list += '</ul></div>';

    listDiv.innerHTML = list;

    return listDiv;
  },

  pickup(e) {
    if (!/^BUTTON$/i.test(e.target.tagName)) return false;

    e.preventDefault();
    e.stopPropagation();

    const temp = layoutsList[e.target.getAttribute('data-value')];

    if (temp.html) {
      this.functions.insertHTML(temp.html);
    }
    this.submenuOff();
    this.functions.toolbar.hide();
  },
};
