import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { withAuthPing } from 'decorators/withAuthPing';
import { StoreProps } from 'store';

import { resendVerificationCode, sendVerificationCode } from 'api/verification';
import { closeModal } from 'actions/modal';
import showNotification from 'components/ui/notification/showNotification';
import CodeVerification from './CodeVerification';
import {
  BACKEND_ERRORS,
  CODE_LENGTH,
  RESEND_CODE_INTERVAL,
  SCENES,
} from './constants';
import { BaseInterface, ContentInterface } from './types';

interface OwnProps {
  content: ContentInterface;
  user: any;
}

type Props = OwnProps & IntlProps & StoreProps;

interface State extends BaseInterface {}

@withAuthPing
class CodeVerificationContainer extends PureComponent<Props, State> {
  private timeoutTimerId;

  constructor(props: Props) {
    super(props);

    this.timeoutTimerId = null;

    this.state = {
      scene: SCENES.MAIN,
      code: '',
      errorMessage: '',
      isLoading: false,
      canResendCode: false,
      dateResendCode: CodeVerificationContainer.getDateResendCode(),
    };

    this.startTimers();
  }

  componentWillUnmount() {
    this.clearTimers();
  }

  render() {
    const { content } = this.props;
    const {
      scene,
      code,
      errorMessage,
      isLoading,
      canResendCode,
      dateResendCode,
    } = this.state;

    return (
      <CodeVerification
        scene={scene}
        code={code}
        errorMessage={errorMessage}
        content={content}
        isLoading={isLoading}
        canResendCode={canResendCode}
        dateResendCode={dateResendCode}
        canSendCode={this.canSendCode()}
        onCloseModal={() => this.closeModal()}
        onCompleteTimer={() => this.completeResendTimer()}
        onChangeCode={(event) => this.changeCode(event)}
        onSendCode={() => this.sendCode()}
        onResendCode={() => this.resendCode()}
      />
    );
  }

  async sendCode() {
    if (!this.canSendCode()) {
      return false;
    }

    this.setState({ isLoading: true });

    try {
      const {
        content: { verificationId, requestInfo, rpc },
      } = this.props;

      const code = this.getCodeWithoutSpace();
      await sendVerificationCode(rpc.id, { verificationId, code, requestInfo });
    } catch ({ payload }) {
      this.handleError(payload);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async resendCode() {
    const { canResendCode } = this.state;
    if (!canResendCode) {
      return false;
    }

    try {
      const {
        content: { verificationId },
      } = this.props;

      await resendVerificationCode(verificationId);

      this.setState({
        dateResendCode: CodeVerificationContainer.getDateResendCode(),
        canResendCode: false,
      });
    } catch ({ payload }) {
      this.handleError(payload);
    }
  }

  handleError = (error) => {
    const { getTranslate } = this.props;

    if (!error) {
      return this.setState({
        errorMessage: getTranslate('confirmation.internalServerError.sysmsg'),
      });
    }

    const { errorCode, validationErrors } = error;

    switch (errorCode) {
      case BACKEND_ERRORS.ERROR_CODE_TRANSACTION_LOCKED:
        return this.changeScene(SCENES.TRANSACTION_LOCKED_ERROR);
      case BACKEND_ERRORS.ERROR_CODE_ATTEMPTS_EXCEEDED:
        return this.changeScene(SCENES.ATTEMPTS_ERROR);
      case BACKEND_ERRORS.ERROR_CODE_OPERATION_EXPIRED:
        return this.changeScene(SCENES.TRANSACTION_LOCKED_ERROR);
      case BACKEND_ERRORS.ERROR_CODE_VERIFICATION_CODE_INVALID:
        return this.setState({
          errorMessage: getTranslate('payment.psd2.invalidCode.text'),
        });
      default:
        if (Object.values(validationErrors).length) {
          showNotification({
            status: 'error',
            content: getTranslate(Object.values(validationErrors)[0] as string),
          });
        }
    }
  };

  canSendCode = () => {
    const { isLoading } = this.state;
    return this.getCodeWithoutSpace().length === CODE_LENGTH && !isLoading;
  };

  getCodeWithoutSpace = () => {
    return this.state.code.replace(/\s/g, '');
  };

  changeCode = (event) => {
    this.setState({
      code: event.target.value,
      errorMessage: '',
    });
  };

  startTimers() {
    const {
      confirmationDetails: { codeValidTillTimeLeft },
    } = this.props.content;

    this.timeoutTimerId = setTimeout(() => {
      this.changeScene(SCENES.TIMEOUT_ERROR);
    }, codeValidTillTimeLeft * 1000);
  }

  clearTimers() {
    clearTimeout(this.timeoutTimerId);
  }

  completeResendTimer = () => {
    this.setState({
      canResendCode: true,
    });
  };

  changeScene = (scene) => {
    this.setState({ scene });
  };

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(closeModal());
  };

  static getDateResendCode() {
    return +Date.now() + RESEND_CODE_INTERVAL;
  }
}

export default connect()(addTranslation(CodeVerificationContainer));
