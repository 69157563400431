import { periodTypes } from 'constants/dateFormats';

export enum dictionaries {
  currency = 'financialDataStatementCurrency',
  balance = 'financialDataStatementBalance',
}

export const periods = [
  periodTypes.Yesterday,
  periodTypes.LastWeek,
  periodTypes.LastMonth,
];
