import showNotification from 'components/ui/notification/showNotification';
import getLocalizedText from 'helpers/getLocalizedText';
import { PROJECTS_LIMIT } from 'pages/risks/constants';

export const checkSelectedRcsProjects = (list) => {
  const selected =
    typeof list[0] !== 'string' ? list.filter((item) => item.isSelected) : list;
  if (selected.length > PROJECTS_LIMIT) {
    showNotification({
      status: 'warn',
      content: getLocalizedText('risks.tooManyProjectsSelected.sysmsg'),
    });
    return true;
  }
  return false;
};
