import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { generateTestPayments, isTestPaymentsAvailable } from 'api/payments';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import Button from 'components/ui/button';
import GuideIcon from 'components/ui/guideIcon';
import Messages from 'constants/rpcTypes';

interface Props extends IntlProps, WithPermissions {
  isDemoUser: boolean;
}

interface State {
  loading: boolean;
  generationAllowed: boolean;
}

class GenerateTestPaymentsButton extends React.PureComponent<Props, State> {
  isEnabled;
  constructor(props) {
    super(props);
    this.state = { loading: false, generationAllowed: false };
    this.isEnabled = props.isEnabled(
      Messages.PaymentOperationGenerator_GenerateInit
    );
  }

  componentDidMount() {
    this.checkIsAllowed();
  }

  render() {
    const { getTranslate, isDemoUser } = this.props;
    const { loading, generationAllowed } = this.state;

    if (!this.isEnabled) {
      return null;
    }

    return (
      <>
        <Button
          text={getTranslate('payments.paymentGenerate.button')}
          size='normal'
          status='primary'
          disabled={!generationAllowed}
          type='submit'
          loading={loading}
          onClick={this.generateTestPayments}
          tooltip={
            generationAllowed
              ? ''
              : getTranslate('payments.paymentGenerate.tooltip')
          }
        />

        {isDemoUser && (
          <GuideIcon
            tooltip={getTranslate('payments.generateTestPayments.tooltip')}
          />
        )}
      </>
    );
  }

  checkIsAllowed = async () => {
    if (!this.props.isDemoUser) {
      return;
    }
    try {
      const { generationAllowed } = await isTestPaymentsAvailable();
      this.setState({ generationAllowed });
    } catch {
      this.setState({ generationAllowed: false });
    }
  };

  generateTestPayments = async () => {
    try {
      this.setState({ loading: true });
      const { generationAllowed } = await generateTestPayments();
      this.setState({ generationAllowed });
    } finally {
      this.setState({ loading: false });
    }
  };
}

export default addTranslation(addPermissions(GenerateTestPaymentsButton));
