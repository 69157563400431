import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

export const enableApiLogs = (timeout = 60) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Qa_EnableApiLogs,
        payload: {
          timeout,
          runtime: true,
        },
      },
    ],
  });
};
