import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addListeners } from 'decorators/addListeners';
import { WithRouterProps } from 'decorators/withRouter';
import Checkbox from 'components/ui/checkbox';
import Loader from 'components/ui/loader';
import CustomScrollbar from 'components/ui/customScrollbar';
import OperationItem from 'components/paymentCard/operationItem';
import { AnyObject } from 'types/Common';
import Messages from 'constants/rpcTypes';
import { StoreProps } from 'store';
import permissionReasons from 'constants/permissionReasons';
import { statusMapper } from '../../../statuses';
import './caseIdList.scss';

interface OwnProps {
  isButtonsDisabled: boolean;
  isFormOpened: boolean;
  firstChargeId: string;
  list: any[];
  isCheckedAll: boolean;
  onToggleChb: (id: string, isSelected: boolean) => void;
  goToCharge: (id: string) => void;
  toggleCheckAll: () => void;
  isNeedResponseStatus: (chargeback) => boolean;
}

interface ConnectedProps {
  email: string;
}

type RouterProps = WithRouterProps<{ id?: string }>;

type Props = OwnProps &
  RouterProps &
  ConnectedProps &
  StoreProps &
  IntlProps &
  WithPermissions;

interface State {
  isAcceptProcessing: boolean;
}

@addListeners([Messages.Chargeback_Accept, Messages.Confirm_Reject])
class CaseIdListContainer extends PureComponent<Props, State> {
  state = {
    isAcceptProcessing: false,
  };

  render() {
    const {
      isButtonsDisabled,
      isFormOpened,
      getTranslate,
      list,
      isCheckedAll,
    } = this.props;
    const { isAcceptProcessing } = this.state;
    const isAcceptEnabled =
      this.props.isEnabled(Messages.Chargeback_Accept) ||
      this.props.isDisabledByReason(
        Messages.Chargeback_Accept,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );
    const isSubmitEnabled =
      this.props.isEnabled(Messages.Chargeback_DisputeStart) ||
      this.props.isDisabledByReason(
        Messages.Chargeback_DisputeStart,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );

    return (
      <div className='card case-id'>
        <div className='card__header card__title-l'>
          {getTranslate('chargebackCard.list.title')}
        </div>
        <div className='case-id__inner'>
          <div className='case-id__select-all'>
            <Checkbox
              disabled={
                isButtonsDisabled ||
                isFormOpened ||
                (!isAcceptEnabled && !isSubmitEnabled)
              }
              id='select-all-charges'
              checked={isCheckedAll}
              onChange={this.props.toggleCheckAll}
              text={getTranslate('chargebackCard.selectAll.button')}
            />
          </div>
          {isAcceptProcessing ? (
            <Loader />
          ) : (
            <div className='case-id__list'>
              <CustomScrollbar customHeight='100%'>
                {list.map((item: AnyObject) => (
                  <OperationItem
                    key={item.chargebackId}
                    type='chargeback'
                    data={item}
                    isCurrent={item.isCurrent}
                    onSelect={(id) => this.props.goToCharge(id)}
                    onCheckboxChange={(id, isSelected) =>
                      this.props.onToggleChb(id, isSelected)
                    }
                    isCheckboxDisabled={
                      item.status !== statusMapper.needsResponse ||
                      isButtonsDisabled ||
                      (!isAcceptEnabled && !isSubmitEnabled)
                    }
                    isDisabled={isButtonsDisabled && isFormOpened}
                  />
                ))}
              </CustomScrollbar>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state): ConnectedProps => {
  return {
    email: state.settings.chargebacksEmail,
  };
};

const CaseIdListContainer_ = connect(mapStateToProps)(
  addPermissions(addTranslation(CaseIdListContainer))
);
export default withRouter(CaseIdListContainer_);
