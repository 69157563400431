import React from 'react';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from '../../../../components/ui/button';
import { IDictionary } from '../../../../types/Analytics';
import DateRenderableField from '../../../../components/modal/modalList/graphDetails/components/settingsForm/formComponents/DateRenderableField';
import { connect } from 'react-redux';
import SelectionList from '../../../../components/ui/selectionList/SelectionList';
import Loader from '../../../../components/ui/loader';
import 'pages/analytics/components/AllChartsFilters/all-charts.scss';
import { validateBeforeApply } from './validation/BeforeApply';
import { sendAllChartsFilters } from '../../api/charts';
import { StoreProps } from '../../../../store';

interface ConnectedProps {
  projects: { list: IDictionary; isLoading: boolean };
}

interface OwnProps extends IntlProps {
  chartIds: string[];
  isApplyFiltersToAllDisabled: boolean;
  projects: IDictionary;
  beforeApplyFunction: () => void;
  afterApplyFunction: () => void;
}

type IProps = OwnProps & ConnectedProps & StoreProps;

interface State {
  processApply: boolean;
  panelPosition: {
    top: number;
  };
  selectedProjects: string[];
  period: {
    from: string;
    to: string;
  };
  validationErrors: { [key: string]: string };
}

const initialState = {
  processApply: false,
  panelPosition: { top: 0 },
  selectedProjects: [],
  period: {
    from: '',
    to: '',
  },
  validationErrors: {
    selectedProjects: '',
    period: '',
  },
};

class _AllChartsFilters extends React.PureComponent<IProps, State> {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  render() {
    const { getTranslate, isApplyFiltersToAllDisabled, projects } = this.props;
    const { period, selectedProjects, validationErrors } = this.state;

    return (
      <>
        {this.dictionariesLoading && <Loader />}
        <DateRenderableField
          id={'all-charts-period'}
          formItemClassName={'all-charts-period-input'}
          isDisabled={false}
          label={'analytics.editForm.params.period.label'}
          data={period}
          onChange={(id, selectedPeriod) => {
            this.setState({
              period: selectedPeriod,
              validationErrors: {},
            });
          }}
          toggleDatePicker={this.toggleDatePicker}
          validationErrors={validationErrors}
          panelPosition={this.state.panelPosition}
          getTranslate={getTranslate}
        />
        <SelectionList
          customClass={'all-filters-projects advanced-section__edit-layout'}
          label={getTranslate('analytics.editForm.filters.project.label')}
          // @ts-ignore
          items={this.getMultiSelectItems(
            'projects',
            {
              projects: projects.list,
            },
            selectedProjects
          )}
          isSingleMode={false}
          onChange={(itemsList) => {
            const selectedItems = itemsList
              .filter((i) => i.isSelected)
              .map((i) => i.id);

            this.setState({
              selectedProjects: selectedItems,
              validationErrors: {},
            });
          }}
          error={validationErrors['selectedProjects']}
        />
        <Button
          status={'primary'}
          onClick={this.onApply}
          customClass={'all-charts-apply-button advanced-section__edit-layout'}
          text={getTranslate('analytics.mainView.applyChanges')}
          disabled={isApplyFiltersToAllDisabled}
        />
      </>
    );
  }

  toggleDatePicker = () => {
    let panelPositionTop = 0;
    const input = document.querySelector('.all-charts-period-input');
    if (!input) return;

    panelPositionTop = (input?.getBoundingClientRect().top + 32 || 0) + 30;
    this.setState({
      panelPosition: {
        top: panelPositionTop,
      },
    });
  };

  getMultiSelectItems = (dictionaryKey, dictionaries, values) => {
    const data = dictionaries[dictionaryKey];
    return data === undefined
      ? []
      : data.map((item) => ({
          ...item,
          isSelected: values.includes(item.id),
        }));
  };

  get dictionariesLoading() {
    const { projects } = this.props;

    return projects.isLoading;
  }

  resetState() {
    this.setState(initialState);
  }

  onApply = () => {
    const { selectedProjects, period } = this.state;
    const {
      getTranslate,
      chartIds,
      beforeApplyFunction,
      afterApplyFunction,
      dispatch,
    } = this.props;

    const payload = { chartIds, selectedProjects, period };

    const validationResult = validateBeforeApply(getTranslate, payload);

    if (!validationResult.isValid) {
      this.setState({
        validationErrors: validationResult.errors,
      });

      return;
    }

    this.setState(
      {
        processApply: true,
      },
      () => {
        beforeApplyFunction();
        dispatch(
          sendAllChartsFilters(payload, () => {
            this.resetState();
            afterApplyFunction();
          })
        );
      }
    );
  };
}

const mapStateToProps = (state): ConnectedProps => {
  const { filtersValues } = state;

  return {
    projects: filtersValues?.enabledProject ?? {},
  };
};

const AllChartsFilters = connect(mapStateToProps)(_AllChartsFilters);

export default addTranslation(AllChartsFilters);
