import filtersKeys from 'constants/filters';
import { FilterTypes } from 'constants/FilterTypes';

export default [
  {
    filterId: filtersKeys.invoicePaymentId,
    type: FilterTypes.text,
    title: 'invoicing.quickFilters.paymentID',
    metric: 'invoicing.fastFilter.paymentId.enter',
  },
  {
    filterId: filtersKeys.invoiceProjectId,
    type: FilterTypes.multiSelect,
    metric: 'invoicing.fastFilter.projectId.apply',
    title: 'invoicing.quickFilters.projectId',
    dictionary: 'enabledProject',
  },
  {
    filterId: filtersKeys.invoiceStatus,
    type: FilterTypes.multiSelect,
    metric: 'invoicing.fastFilter.status.apply',
    dictionary: 'invoiceStatus',
    title: 'invoicing.quickFilters.status',
  },
  {
    filterId: filtersKeys.invoiceCurrency,
    type: FilterTypes.multiSelect,
    metric: 'invoicing.fastFilter.currency.apply',
    dictionary: 'currency',
    title: 'invoicing.quickFilters.currency',
  },
  {
    filterId: filtersKeys.invoiceCreatedAt,
    type: FilterTypes.dateButtons,
    metricName: 'invoicing',
  },
];
