import React, { PureComponent, ReactNode } from 'react';
import classNames from 'classnames';
import InfoIcon from 'components/ui/infoIcon';
import './radio.scss';

interface Props {
  id: string;
  text?: string | ReactNode;
  onChange?: (event: any) => void;
  isChecked?: boolean;
  isDisabled?: boolean;
  customClass?: string;
  infoIcon?: string;
}

class Radio extends PureComponent<Props> {
  render() {
    const { id, text, isChecked, isDisabled, onChange, customClass, infoIcon } =
      this.props;

    return (
      <label
        className={classNames('ui-radio', customClass, {
          'ui-radio_checked': isChecked,
          'ui-radio_disabled': isDisabled,
        })}
        id={id}>
        <span className='ui-radio__wrapper'>
          <input
            className='ui-radio__input'
            type='radio'
            id={id}
            disabled={isDisabled}
            checked={isChecked}
            onChange={onChange}
          />
          <span className='ui-radio__inner' />
        </span>
        <span className='ui-radio__text'>{text}</span>
        {infoIcon && (
          <InfoIcon
            tooltip={infoIcon}
            customClass='ui-radio__info'
            dataPlace='top'
            modern
          />
        )}
      </label>
    );
  }
}

export default Radio;
