import Utils from 'helpers/Utils';
import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';
import { AnyObject } from 'types/Common';

interface State {
  isLoading: boolean;
  isTemplateFetching: boolean;
  isTemplate: boolean;
  fields: any;
  validationErrors: AnyObject;
}

const initialState: State = {
  isLoading: false,
  isTemplateFetching: false,
  isTemplate: false,
  fields: null,
  validationErrors: {},
};

const templateBuilder = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.UPDATE_TEMPLATE_BUILDER:
      if (!Utils.hasProp(state, payload.key)) {
        console.warn(`${payload.key} not found in templateBuilder reducer`);
        return state;
      }
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case ACTIONS.UPDATE_TEMPLATE_BUILDER_FIELD:
      if (!Utils.hasProp(state.fields, payload.key)) {
        return state;
      }
      return {
        ...state,
        validationErrors: {},
        fields: {
          ...state.fields,
          [payload.key]: payload.value,
        },
      };
    case ACTIONS.UPDATE_TEMPLATE_BUILDER_FILTER_FIELD:
      if (!Utils.hasProp(state.fields, payload.reportType)) {
        return state;
      }
      return {
        ...state,
        validationErrors: {},
        fields: {
          ...state.fields,
          [payload.reportType]: {
            ...state.fields[payload.reportType],
            [payload.key]: payload.value,
          },
        },
      };
    case ACTIONS.RESET_TEMPLATE_BUILDER:
      return initialState;
    default:
      return state;
  }
};

export default templateBuilder;
