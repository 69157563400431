import Env from 'helpers/Env';
import React from 'react';
declare const hj: any;

class HotjarService {
  static init() {
    if (Env.isDevelopment()) return;

    // eslint-disable-next-line max-params
    (function (h, o, t, j, a?: HTMLElement, r?: HTMLScriptElement) {
      h['hj'] =
        h['hj'] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (h['hj'].q = h['hj'].q || []).push(arguments);
        };

      h['_hjSettings'] = {
        hjid: 2549097,
        hjsv: 6,
      };

      a = o.querySelectorAll('head')[0];
      r = o.createElement('script');
      r.async = true;
      r.src = t + h['_hjSettings'].hjid + j + h['_hjSettings'].hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }

  static routeChangeStats = (WrapperComponent) => {
    if (Env.isDevelopment()) return WrapperComponent;

    class MountedDecorator extends React.Component<any> {
      componentDidMount(): void {
        this.updateScreen();
      }
      componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<{}>,
        snapshot?: any
      ) {
        if (this.props.path !== prevProps.path) {
          this.updateScreen();
        }
      }

      render() {
        return <WrapperComponent {...this.props} />;
      }

      updateScreen = () => {
        // eslint-disable-next-line no-undef
        hj('stateChange', this.props.path);
      };
    }
    return MountedDecorator as typeof WrapperComponent;
  };
}

export default HotjarService;
