import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { addTranslation, IntlProps } from 'decorators/addTranslation';

import { changeQuickFilter } from 'actions/quickFilters';
import { StoreProps } from 'store';

import InputDate from 'components/ui/inputDate';
import CustomSelect from 'components/ui/customSelect';

import DateHelpers from 'helpers/Date';
import checkFilters from 'helpers/checkFilters';
import { QuickCurrencyRatesFiltersType } from 'types/QuickFilters';
import { AnyObject } from 'types/Common';
import { Dictionary } from 'types/FilterValue';
import tableNames from 'constants/tableNames';
import filtersKeys from 'constants/filters';
import './currencyRatesFilters.scss';

const LOCAL_STORAGE_FILTER_MAP = {
  [filtersKeys.currencyRatesMerchantId]: 'dash-currencyRatesFilterMerchantId',
};

interface OwnProps {
  filters: QuickCurrencyRatesFiltersType;
  currency: Dictionary;
  merchants: Dictionary;
}
type Props = OwnProps & StoreProps & IntlProps;

class CurrencyRatesFilters extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.onChangeSelectLocalStorage =
      this.onChangeSelectLocalStorage.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.initCurrentDate = this.initCurrentDate.bind(this);
  }

  initCurrentDate() {
    const { dispatch } = this.props;

    const currentDate = DateHelpers.getFormat(
      DateHelpers.getDate().startOf('day'),
      'datetime'
    );

    dispatch(
      changeQuickFilter(
        tableNames.currencyRates,
        filtersKeys.currencyRatesActualDate,
        currentDate
      )
    );
  }

  async componentDidMount() {
    this.initCurrentDate();

    await checkFilters('currencyRates');
  }

  async componentDidUpdate() {
    const { filters, merchants, dispatch } = this.props;

    // Select from localStorage or first merchantId after loading
    if (
      merchants?.isFetched &&
      merchants?.list?.length > 0 &&
      !filters[filtersKeys.currencyRatesMerchantId]
    ) {
      const localStorageName =
        LOCAL_STORAGE_FILTER_MAP[filtersKeys.currencyRatesMerchantId];
      const localMerchantId = localStorage.getItem(localStorageName);

      const merchantIdExistInList =
        localMerchantId &&
        merchants.list.find(({ id }) => String(id) === localMerchantId) !==
          undefined;

      if (localMerchantId && !merchantIdExistInList) {
        localStorage.removeItem(localStorageName);
      }

      const value =
        merchantIdExistInList === true
          ? Number(localMerchantId)
          : merchants.list[0].id;

      dispatch(
        changeQuickFilter(
          tableNames.currencyRates,
          filtersKeys.currencyRatesMerchantId,
          value
        )
      );
    }
  }

  render() {
    return (
      <div className='currency-rates-filters'>
        {this.renderActualDate()}
        {this.renderMerchant()}
        {this.renderCurrency()}
      </div>
    );
  }

  renderActualDate() {
    const { filters, getTranslate } = this.props;

    return (
      <div className='currency-rates-filters__item currency-rates-filters__item_date'>
        <InputDate
          selectedDate={filters[filtersKeys.currencyRatesActualDate]}
          withTime={false}
          onChange={(date) =>
            this.onChangeDate({
              key: filtersKeys.currencyRatesActualDate,
              date,
            })
          }
          isUnselectDate={false}
          title={getTranslate('currencyRates.filters.actualDate')}
          closeAfterSelect
          modern
        />
      </div>
    );
  }

  renderMerchant() {
    const { merchants, filters, getTranslate } = this.props;
    const options = merchants?.list
      ? merchants.list.map((item) => ({
          label: item.text,
          value: item.id,
        }))
      : [];

    return (
      <div className='currency-rates-filters__item currency-rates-filters__item_merchant'>
        <CustomSelect
          label={getTranslate('currencyRates.filters.merchantId')}
          options={options}
          value={options?.find(
            (item) =>
              item.value === filters[filtersKeys.currencyRatesMerchantId]
          )}
          isSearchable
          modern
          onChange={(item) => {
            this.onChangeSelectLocalStorage({
              key: filtersKeys.currencyRatesMerchantId,
              item,
            });
          }}
        />
      </div>
    );
  }

  renderCurrency() {
    const { currency, filters, getTranslate } = this.props;
    const options = currency?.list
      ? currency.list
          .filter((item) => item.text !== 'USD')
          .map((item) => ({
            label: item.text,
            value: item.text,
          }))
      : [];

    return (
      <div className='currency-rates-filters__item'>
        <CustomSelect
          label={getTranslate('currencyRates.filters.currency')}
          options={options}
          value={options?.find(
            (item) => item.value === filters[filtersKeys.currencyRatesCurrency]
          )}
          isSearchable
          modern
          onChange={(item) => {
            this.onChangeSelect({
              key: filtersKeys.currencyRatesCurrency,
              item,
            });
          }}
        />
      </div>
    );
  }

  onChangeSelect({ key, item }: { key: string; item: AnyObject }): void {
    if (!item) {
      return;
    }

    const { value } = item;

    this.onChangeFilter({
      key,
      value,
    });
  }

  onChangeSelectLocalStorage({
    key,
    item,
  }: {
    key: string;
    item: AnyObject;
  }): void {
    if (!item) {
      return;
    }

    const { value } = item;
    const localStorageKey = LOCAL_STORAGE_FILTER_MAP[key];

    if (localStorageKey) {
      localStorage.setItem(localStorageKey, value);
    }

    this.onChangeSelect({ key, item });
  }

  onChangeDate({ key, date }: { key: string; date?: any }): void {
    this.onChangeFilter({
      key,
      value: date,
    });
  }

  onChangeFilter({ key, value }): void {
    const { dispatch } = this.props;

    dispatch(changeQuickFilter(tableNames.currencyRates, key, value));
  }
}

export default connect()(addTranslation(CurrencyRatesFilters));
