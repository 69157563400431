import { createSelector } from 'reselect';

const getProjects = (state) => {
  return state.user.enabledProjects;
};

export const sortProjectsById = createSelector([getProjects], (projects) =>
  projects.sort((a, b) => {
    if (+a.projectId > +b.projectId) {
      return 1;
    } else if (+a.projectId < +b.projectId) {
      return -1;
    }
    return 0;
  })
);
