import React, { PureComponent, ReactElement } from 'react';
import { AnyObject } from 'types/Common';
import './header.scss';
import classNames from 'classnames';

interface Props {
  style: AnyObject;
  children: any;
  childrenProps?: any;
  setHeaderRef?: (HTMLDivElement) => void;
}

interface State {
  isResizing: boolean;
}

class TableHeader extends PureComponent<Props, State> {
  state = { isResizing: false };
  static defaultProps = {
    style: {},
  };

  render() {
    const { style, children, setHeaderRef, childrenProps } = this.props;
    const { isResizing } = this.state;
    return (
      <div
        style={style}
        className={classNames('ui-table__header', {
          'ui-table__header__resizing': isResizing,
        })}
        ref={setHeaderRef}>
        {React.Children.map(children, (child: ReactElement, index) => {
          if (!child) return null;
          return React.cloneElement(child, {
            ...childrenProps,
            setIsResizing: this.setIsResizing,
            key: index,
            header: true,
            customClass: `ui-table__column ${child.props.customClass}`,
          });
        })}
      </div>
    );
  }

  setIsResizing = (isResizing) => this.setState({ isResizing });
}

export default TableHeader;
