import './accordion.scss';

const accordionClassName = 'ui-editor-accordion';

const emptyAccordion = `
      <thead>
      <tr>
        <th class="__se__tag ui-editor-accordion__title js-accordion-title">
          <div>
            Title
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="__se__tag ui-editor-accordion__content js-accordion-content"></td>
      </tr>
    </tbody>`;

let hasSharedListeners = false;

export default {
  // @Required @Unique
  // plugin name
  name: 'accordion',
  display: 'command',
  innerHTML:
    '<svg xmlns="http://www.w3.org/2000/svg" x="0" y="0"  viewBox="0 0 477.867 477.867"><path d="M392.533 0h-307.2C38.228.056.056 38.228 0 85.333v307.2c.056 47.105 38.228 85.277 85.333 85.333h307.2c47.105-.056 85.277-38.228 85.333-85.333v-307.2C477.81 38.228 439.638.056 392.533 0zm51.2 392.533c0 28.277-22.923 51.2-51.2 51.2h-307.2c-28.277 0-51.2-22.923-51.2-51.2v-307.2c0-28.277 22.923-51.2 51.2-51.2h307.2c28.277 0 51.2 22.923 51.2 51.2v307.2z"></path><path d="M324.267 221.867H256V153.6c0-9.426-7.641-17.067-17.067-17.067s-17.067 7.641-17.067 17.067v68.267H153.6c-9.426 0-17.067 7.641-17.067 17.067S144.174 256 153.6 256h68.267v68.267c0 9.426 7.641 17.067 17.067 17.067S256 333.692 256 324.267V256h68.267c9.426 0 17.067-7.641 17.067-17.067s-7.642-17.066-17.067-17.066z"></path></svg>',

  // @Options
  title: 'Accordion',
  buttonClass: '',

  // @Required
  // add function - It is called only once when the plugin is first run.
  // This function generates HTML to append and register the event.
  // arguments - (core : core object, targetElement : clicked button element)
  add(core, targetElement) {
    const context = core.context;

    context.accordion = {};
    context.accordion.controlBlock = null;

    const rangeTag = core.util.createElement('table');
    core.util.addClass(
      rangeTag,
      '__se__tag ui-editor-accordion is-open js-accordion'
    );
    rangeTag.innerHTML = emptyAccordion;

    // @Required
    // Registering a namespace for caching as a plugin name in the context object
    context.customCommand = {
      targetButton: targetElement,
      tag: rangeTag,
    };

    this.addSharedListeners();
  },

  // @Override core+
  // Plugins with active methods load immediately when the editor loads.
  // Called each time the selection is moved.
  active(element) {
    if (!element) {
      return false;
    }
    if (this.util.hasClass(element, accordionClassName)) {
      this.util.addClass(this.context.customCommand.targetButton, 'active');
      this.controllersOff();
      this.isActive = true;

      return true;
    }
    this.util.removeClass(this.context.customCommand.targetButton, 'active');
    this.isActive = false;

    return false;
  },

  // @Required, @Override core
  // The behavior of the "command plugin" must be defined in the "action" method.
  action() {
    const selectedElements = this.getSelectedElements();

    if (this.isActive) {
      // this.detachRangeFormatElement(rangeTag, null, null, false, false);
      return;
    }
    const node = this.context.customCommand.tag.cloneNode(true);
    this.accordionContent = node.querySelector('.ui-editor-accordion__content');
    selectedElements.forEach((el) => {
      this.accordionContent.appendChild(el.cloneNode(true));
    });

    this.insertNode(node);
  },

  addSharedListeners() {
    if (hasSharedListeners) return;
    hasSharedListeners = true;
    document.addEventListener('click', function (event) {
      const { target } = event;
      if (target && target.classList.contains('js-accordion-title')) {
        const parent = target.closest('.js-accordion');
        parent.classList.toggle('is-open');
        setTimeout(() => {
          const content = parent.querySelector('.ui-editor-accordion__content');
          content.classList.toggle('is-hidden');
        }, 200);
      }
    });
  },
};
