import { IFormDescriptorItem, IFormValues } from 'types/Analytics';
import DateHelpers from 'helpers/Date';
import DateFormats from 'constants/dateFormats';

const groupByHiddenAdapter = (values) =>
  'chartType' in values && values.chartType === 'simple_table';

const groupByMultiSelectHiddenAdapter = (values) =>
  'chartType' in values && values.chartType !== 'simple_table';

const groupByDateHiddenAdapter = (values: IFormValues) => {
  const isPieChart = 'chartType' in values && values.chartType === 'pie chart';

  if (
    'groupByMultiSelect' in values &&
    Array.isArray(values.groupByMultiSelect) &&
    values.groupByMultiSelect.includes('chargebackCreatedDate') &&
    !isPieChart
  ) {
    return false;
  } else if (
    'groupBy' in values &&
    values.groupBy === 'chargebackCreatedDate' &&
    !isPieChart
    // eslint-disable-next-line sonarjs/no-duplicated-branches
  ) {
    return false;
  }
  return true;
};

const chartTypeOnChangeCallback = (values) => {
  const isGroupByHidden = groupByHiddenAdapter(values);
  const isGroupByMultiSelectHidden = groupByMultiSelectHiddenAdapter(values);
  let newValues = values;

  if (isGroupByHidden === true) {
    newValues = { ...newValues, groupBy: '', groupByDate: '' };
  }

  if (isGroupByMultiSelectHidden === true) {
    newValues = { ...newValues, groupByMultiSelect: [], groupByDate: '' };
  }

  if (
    values.chartType === 'pie chart' ||
    (values.chartType === 'table' &&
      values.groupBy === 'chargebackCreatedDate') ||
    (values.chartType !== 'table' &&
      values.groupByMultiSelect?.includes('chargebackCreatedDate') === true)
  ) {
    newValues = { ...newValues, groupByDate: '' };
  }

  return newValues;
};

const groupByFieldsOnChangeCallback = (values) => {
  const isGroupByDateHidden = groupByDateHiddenAdapter(values);

  if (isGroupByDateHidden === true) {
    return { ...values, groupByDate: '' };
  }

  return values;
};

const groupByOptions = [
  {
    value: 'cardType',
    label: 'analytics.editForm.params.groupBy.cardType.item',
  },
  {
    value: 'issuerCountry',
    label: 'analytics.editForm.params.groupBy.countryByBIN.item',
  },
  {
    value: 'projectId',
    label: 'analytics.editForm.params.groupBy.projectID.item',
  },
];

const groupByOptionsMultiSelect = groupByOptions.map(({ value, label }) => ({
  id: value,
  text: label,
}));

const groupByConditionalOptionsAdapter = (values) =>
  !['ratio_by_count', 'ratio_by_amount'].includes(values.chartData);

const ChargebackParams: Array<IFormDescriptorItem> = [
  {
    id: 'chartName',
    label: 'analytics.editForm.params.chartTitle.label',
    placeholder: 'analytics.editForm.params.chartTitle.placeholder',
    type: 'input',
  },
  {
    id: 'chartData',
    type: 'singleSelect',
    label: 'analytics.editForm.params.chartData.label',
    options: [
      {
        value: 'count_chargeback_id',
        label: 'analytics.editForm.params.chartData.chbNumber.item',
      },
      {
        value: 'sum_amount_in_usd',
        label: 'analytics.editForm.params.chartData.chbAmount.item',
      },
      {
        value: 'ratio_by_count',
        label: 'analytics.editForm.params.chartData.chbRatioNumber.item',
      },
      {
        value: 'ratio_by_amount',
        label: 'analytics.editForm.params.chartData.chbRatioAmount.item',
      },
    ],
    onChangeCallback: (values) => {
      if ('chartType' in values) {
        values.chartType = 'bar';
      }

      if ('groupBy' in values) {
        values.groupBy = 'cadType';
      }

      if ('groupByMultiSelect' in values) {
        values.groupByMultiSelect = [];
      }

      if ('groupByDate' in values) {
        values.groupByDate = '';
      }

      return values;
    },
  },
  {
    id: 'chartType',
    type: 'singleSelect',
    label: 'analytics.editForm.params.chartType.label',
    options: [
      {
        value: 'simple_table',
        label: 'analytics.editForm.params.chartType.table.item',
      },
    ],
    conditionalOptions: [
      {
        data: [
          {
            value: 'bar',
            label: 'analytics.editForm.params.chartType.bar.item',
          },
          {
            value: 'horizontal_bar',
            label: 'analytics.editForm.params.chartType.horizontalBar.item',
          },
        ],
        condition: groupByConditionalOptionsAdapter,
      },
      {
        data: [
          {
            value: 'pie chart',
            label: 'analytics.editForm.params.chartType.pie.item',
          },
        ],
        condition: (values) => {
          const isRatio = groupByConditionalOptionsAdapter(values);

          if (isRatio === false) {
            return isRatio;
          }

          return (
            ('groupBy' in values &&
              values.groupBy !== 'chargebackCreatedDate') ||
            ('groupByMultiSelect' in values &&
              values.groupByMultiSelect.includes('chargebackCreatedDate'))
          );
        },
      },
    ],
    onChangeCallback: chartTypeOnChangeCallback,
  },
  {
    id: 'groupBy',
    type: 'singleSelect',
    label: 'analytics.editForm.params.groupBy.label',
    conditionalOptions: [
      {
        data: [
          {
            value: 'claimType',
            label: 'analytics.editForm.params.groupBy.claimType.item',
          },
          {
            value: 'chargebackReason',
            label: 'analytics.editForm.params.groupBy.chargebackReason.item',
          },
          {
            value: 'chargebackStatus',
            label: 'analytics.editForm.params.groupBy.chargebackStatus.item',
          },
        ],
        condition: groupByConditionalOptionsAdapter,
      },
      {
        data: [
          {
            value: 'chargebackCreatedDate',
            label:
              'analytics.editForm.params.groupBy.chargebackCreatedDate.item',
          },
        ],
        condition: (values) =>
          'chartType' in values && values.chartType !== 'pie chart',
      },
    ],
    options: groupByOptions,
    renderOptions: {
      hidden: {
        condition: groupByHiddenAdapter,
      },
    },
    onChangeCallback: groupByFieldsOnChangeCallback,
  },
  {
    id: 'groupByMultiSelect',
    type: 'multiSelect',
    label: 'analytics.editForm.params.groupBy.label',
    options: groupByOptionsMultiSelect,
    conditionalOptions: [
      {
        data: [
          {
            id: 'claimType',
            text: 'analytics.editForm.params.groupBy.claimType.item',
          },
          {
            id: 'chargebackReason',
            text: 'analytics.editForm.params.groupBy.chargebackReason.item',
          },
          {
            id: 'chargebackStatus',
            text: 'analytics.editForm.params.groupBy.chargebackStatus.item',
          },
        ],
        condition: groupByConditionalOptionsAdapter,
      },
      {
        data: [
          {
            id: 'chargebackCreatedDate',
            text: 'analytics.editForm.params.groupBy.chargebackCreatedDate.item',
          },
        ],
        condition: (values) =>
          'chartType' in values && values.chartType !== 'pie chart',
      },
    ],
    renderOptions: {
      hidden: {
        condition: groupByMultiSelectHiddenAdapter,
      },
    },
    onChangeCallback: groupByFieldsOnChangeCallback,
  },
  {
    id: 'groupByDate',
    type: 'singleSelect',
    label: 'analytics.editForm.params.groupPeriodBy.label',
    options: [
      {
        value: '1d',
        label: 'analytics.editForm.params.groupPeriodBy.day.item',
        order: 2,
      },
      {
        value: '1w',
        label: 'analytics.editForm.params.groupPeriodBy.week.item',
        order: 3,
      },
      {
        value: '1m',
        label: 'analytics.editForm.params.groupPeriodBy.month.item',
        order: 4,
      },
      {
        value: '3m',
        label: 'analytics.editForm.params.groupPeriodBy.quarter.item',
        order: 5,
      },
    ],
    renderOptions: {
      hidden: {
        condition: groupByDateHiddenAdapter,
      },
    },
  },
];

const ChargebackFilters: Array<IFormDescriptorItem> = [
  {
    id: 'period',
    type: 'date',
    label: 'analytics.editForm.params.period.label',
    renderOptions: {
      minDate: DateHelpers.createDate('01.01.2021', 'date').format(
        DateFormats.datetime
      ),
    },
  },
  {
    id: 'projectId',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.project.label',
    dictionaryKey: 'projects',
  },
  {
    id: 'cardType',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.cardType.label',
    options: [
      {
        id: 'Visa',
        text: 'Visa',
      },
      {
        id: 'Mastercard',
        text: 'MasterCard',
      },
    ],
  },
  {
    id: 'issuerCountry',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.countryByBIN.label',
    dictionaryKey: 'country',
  },
];

export { ChargebackParams, ChargebackFilters, groupByHiddenAdapter };
