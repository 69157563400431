const localStorageConfig = {
  providerId: 'dash',
};

class LocalStorage {
  /**
   *
   * @param key
   */
  static get(key: string) {
    const data = localStorage.getItem(
      `${localStorageConfig.providerId}-${key}`
    );
    if (data) {
      try {
        return JSON.parse(data);
      } catch (error) {
        console.error(error);
      }
    }
  }

  /**
   *
   * @param key
   * @param value
   */
  static set(key: string, value: any) {
    return localStorage.setItem(
      `${localStorageConfig.providerId}-${key}`,
      JSON.stringify(value)
    );
  }

  /**
   *
   * @param key
   */
  static remove(key: string) {
    return localStorage.removeItem(`${localStorageConfig.providerId}-${key}`);
  }

  /**
   *
   * @param callback
   */
  static addEventListener(callback) {
    window.addEventListener('storage', (event) => {
      const { key, oldValue, newValue } = event;
      callback({
        key: key?.replace(`${localStorageConfig.providerId}-`, ''),
        oldValue,
        newValue,
      });
    });
  }
}

export default LocalStorage;
