import React, { Component, ReactNode } from 'react';
import { startCase } from 'lodash';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import classNames from 'classnames';

import Loader from 'components/ui/loader';
import { Tab, TabsContainer as Tabs } from 'components/ui/tabs';
import Animation from 'components/ui/animation';
import InputText from './list/text';
import InputAmount from './list/amount';
import InputNumbers from './list/numbers';
import CheckboxList from './list/checkboxList';
import MultiSelect from './list/multiSelect';
import DateRange from './list/dateRange';
import Select from './list/select';
import RadioList from './list/radioList';

import Utils from 'helpers/Utils';
import { FilterTypes } from 'constants/FilterTypes';
import { AnyObject } from 'types/Common';
import Button from 'components/ui/button';
import './filters.scss';

interface Props extends IntlProps {
  filters: AnyObject;
  tabs: AnyObject;
  errors: {};
  canApply: boolean;
  isLoading: boolean;
  onChangeFilter: (fieldId, value, options?: { valueKey?: string }) => void;
  onResetFilters: (tab) => void;
  onApplyFilters: (e) => void;
  customClass?: string;
  timezone: string;
  isMobile: boolean;
}

class Filters extends Component<Props, {}> {
  render() {
    const {
      canApply,
      onApplyFilters,
      onResetFilters,
      customClass,
      isLoading,
      getTranslate,
    } = this.props;

    return (
      <div id='filters' className={classNames('filters', customClass)}>
        <div className='filters__header'>
          <span className='filters__title'>
            {getTranslate('filters.header')}
          </span>
          <Button
            status='outline'
            text={getTranslate('common.clearAll.label')}
            onClick={onResetFilters}
            customClass='filters__header-button'
          />
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <Tabs
            mode={this.props.isMobile ? undefined : 'vertical'}
            theme='brand'
            customClass='ui-tabs_filters'>
            {this.props.tabs.map((tab, index) => {
              return (
                <Tab key={index} title={this.getTabText(tab)}>
                  <div className='filters__content'>
                    <Animation>
                      <div className='filters__inner'>
                        <div className='filters__fields'>
                          {this.buildTabs(tab.id).map((filter) => {
                            return this.filterCreator(filter);
                          })}
                        </div>
                      </div>
                    </Animation>
                  </div>
                </Tab>
              );
            })}
          </Tabs>
        )}
        <div className='filters__footer'>
          <Button
            text={getTranslate('filters.footer.apply')}
            status='primary'
            customClass='ui-button_full-width text-semi'
            disabled={!canApply}
            onClick={onApplyFilters}
          />
        </div>
      </div>
    );
  }

  getTabText = (tab) => {
    const { filters, getTranslate } = this.props;
    const tabFilters = Object.values(filters).filter((item) => {
      return item.tabId === tab.id;
    });

    const selected = tabFilters.filter((item) => {
      if (!Utils.hasProp(item, 'value')) return false;

      if (Array.isArray(item.value)) {
        return item.value.filter((value) => {
          return value.length || value.isSelected;
        })?.length;
      }
      return typeof item.value === 'number' || item.value?.length;
    });

    return selected.length
      ? `${getTranslate(tab.text)} (${selected.length})`
      : getTranslate(tab.text);
  };

  buildTabs(tabId) {
    const { filters } = this.props;
    const tab: any[] = [];
    const groupsMap: any = {};

    for (const key in filters) {
      if (Utils.hasProp(filters, key)) {
        const currentFilter = filters[key];

        if (currentFilter.tabId !== tabId) continue;

        const { groupId, filterId } = currentFilter;
        const fixed = !!currentFilter.fixed;

        if (!groupId) {
          if (currentFilter.type === FilterTypes.dateTime) {
            tab.push({
              tabId: currentFilter.tabId,
              fixed,
              id: filterId,
              type: FilterTypes.dateTime,
              options: [
                {
                  id: filterId,
                  value: currentFilter.value,
                },
              ],
              periodPreset: currentFilter.periodPreset || [],
              activePreset: currentFilter.activePreset || '',
              params: [
                {
                  id: filterId,
                  minDate: currentFilter.minDate,
                  maxDate: currentFilter.maxDate,
                },
              ],
            });
          } else {
            tab.push({
              id: filterId,
              fixed,
              tabId: currentFilter.tabId,
              ...currentFilter,
            });
          }
          continue;
        }

        if (!groupsMap[groupId]) {
          tab.push({
            groupId,
            fixed,
            fields: [],
          });
          groupsMap[groupId] = [tabId, tab.length - 1];
        }
        const group = tab[groupsMap[groupId][1]];

        if (group) {
          if (currentFilter.type === FilterTypes.dateTime) {
            let elementInGroup = group.fields.find(
              (field) => FilterTypes.dateTime === field.type
            );
            // eslint-disable-next-line max-depth
            if (!elementInGroup) {
              elementInGroup = {
                groupId,
                id: filterId,
                type: FilterTypes.dateTime,
                options: [],
                params: [],
                periodPreset: currentFilter.periodPreset || [],
                activePreset: currentFilter.activePreset || '',
              };
              group.fields.push({
                ...elementInGroup,
              });
            }
            elementInGroup.options.push({
              id: filterId,
              value: currentFilter.value,
            });
            let minDate: undefined | string;
            if (currentFilter.minDate !== undefined) {
              minDate = currentFilter.minDate;
            } else if (currentFilter?.years?.[0] !== undefined) {
              minDate = `01.01.${currentFilter.years[0]}`;
            }
            elementInGroup.params.push({
              id: filterId,
              minDate: minDate,
              maxDate: currentFilter.maxDate,
            });
          } else {
            group.fields.push({
              id: filterId,
              ...currentFilter,
            });
          }
        }
      }
    }

    return tab;
  }

  filterCreator(filter) {
    const { groupId, fields } = filter;
    if (!groupId) {
      return (
        <div
          className={`filters__item filters__item_${filter.type}`}
          key={`item-${filter.filterId || filter.id}`}>
          {this.renderFilter(filter)}
        </div>
      );
    }
    return this.renderGroup({
      id: groupId,
      content: fields.map((field) => this.renderFilter(field)),
    });
  }

  renderGroup({ id, content }: { id: string; content: ReactNode }): ReactNode {
    if (!id) return null;

    const groupName = startCase(id).toLowerCase().replace(/ /gi, '-');
    return (
      <div className='filters__item filters__item_group' key={`group-${id}`}>
        <div
          id={`filters-group-${groupName}`}
          key={id}
          className={`filters__group filters__group_${groupName}`}>
          {id !== 'dateTime' && (
            <div className='filters__group-title'>
              {this.props.getTranslate(`filters.types.${id}`)}
            </div>
          )}
          <div className='utils-flex filters__group-content'>{content}</div>
        </div>
      </div>
    );
  }

  renderFilter = (data) => {
    const { errors, onChangeFilter, timezone, getTranslate } = this.props;
    const {
      filterId,
      type,
      value,
      placeholderKey,
      groupId,
      labelKey,
      tooltip,
    } = data;

    switch (type) {
      case FilterTypes.text:
        return (
          <InputText
            key={filterId}
            id={filterId}
            label={getTranslate(labelKey || `filters.types.${filterId}`)}
            placeholder={placeholderKey}
            value={value}
            onChange={onChangeFilter}
            error={errors[filterId]}
          />
        );
      case FilterTypes.numbers:
        return (
          <InputNumbers
            key={filterId}
            id={filterId}
            label={getTranslate(labelKey || `filters.types.${filterId}`)}
            value={value}
            onChange={onChangeFilter}
            placeholder={placeholderKey}
          />
        );
      case FilterTypes.amount:
        return (
          <InputAmount
            key={filterId}
            id={filterId}
            label={getTranslate(labelKey || `filters.types.${filterId}`)}
            placeholder={placeholderKey}
            value={value}
            onChange={onChangeFilter}
          />
        );
      case FilterTypes.checkboxList:
        return (
          <CheckboxList
            title={getTranslate(labelKey || `filters.types.${filterId}`)}
            key={filterId}
            id={filterId}
            items={value}
            onChange={onChangeFilter}
          />
        );
      case FilterTypes.multiSelect:
        return (
          <MultiSelect
            key={filterId}
            id={filterId}
            label={getTranslate(labelKey || `filters.types.${filterId}`)}
            placeholder={placeholderKey}
            items={value}
            withLabel={!groupId}
            onChange={onChangeFilter}
            tooltip={tooltip}
          />
        );
      case FilterTypes.dateTime:
        return (
          <DateRange
            key={data.id}
            id={data.id}
            timezone={timezone}
            withLabel={data.options.length <= 1}
            label={labelKey}
            options={data.options}
            periodPreset={data.periodPreset}
            activePreset={data.activePreset}
            params={data.params}
            onChange={onChangeFilter}
            containerSelector='.filters__content'
          />
        );
      case FilterTypes.radioList:
        return (
          <RadioList
            id={filterId}
            key={filterId}
            items={data.items}
            checkedId={value}
            withLabel={!groupId}
            onChange={onChangeFilter}
            label={labelKey}
          />
        );
      case FilterTypes.select:
        return (
          <Select
            id={filterId}
            key={filterId}
            label={getTranslate(labelKey || `filters.types.${filterId}`)}
            placeholder={placeholderKey}
            options={data.options}
            selectedValue={value}
            withLabel={!groupId}
            onChange={onChangeFilter}
            tooltip={tooltip}
          />
        );
      default:
        return null;
    }
  };
}

export default addTranslation(Filters);
