import React, { PureComponent, Fragment } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Template from 'components/ui/reportTemplate';
import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import SlideDown from 'components/ui/slideDown';
import { Tab, TabsContainer as Tabs } from 'components/ui/tabs';
import { AnyObject } from 'types/Common';
import { Pattern } from 'types/ReportsTempateBuilder';
import './builderPatterns.scss';

interface Props extends IntlProps {
  fields: AnyObject;
  systemTemplates: Pattern[];
  userTemplates: Pattern[];
  isFetched: boolean;
  onUseTemplate: (reportTemplateId: string) => void;
  onDeleteTemplate: (reportTemplateId: string) => void;
}

interface State {
  isShown: boolean;
  needUpdateHeight: boolean;
  activeTab: number;
}

class BuilderPatterns extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isShown: false,
      needUpdateHeight: false,
      activeTab: props.systemTemplates.length ? 0 : 1,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!prevProps.isFetched && this.props.isFetched) {
      this.setState({ isShown: true });
    }

    if (
      !prevProps.systemTemplates.length &&
      this.props.systemTemplates.length
    ) {
      this.setState({ activeTab: 0 });
    }
  }

  render() {
    const { isFetched } = this.props;

    return (
      <div className='card templates-builder-patterns'>
        <div className='card__content'>
          {isFetched && (
            <Fragment>
              {this.renderTabs()}
              {this.renderButton()}
            </Fragment>
          )}
          {!isFetched && <Loader />}
        </div>
      </div>
    );
  }

  renderTabs() {
    const { systemTemplates, userTemplates, getTranslate } = this.props;
    const { activeTab, isShown, needUpdateHeight } = this.state;

    return (
      <Tabs
        defaultActiveTabIndex={activeTab}
        theme='brand'
        customClass='templates-builder-patterns__container'
        infoText={getTranslate('reports.new.templates.hint')}
        onClick={(tabIndex) => {
          this.setState({ needUpdateHeight: true, activeTab: tabIndex }, () => {
            setTimeout(() => this.setState({ needUpdateHeight: false }), 150);
          });
        }}>
        <Tab
          isDisabled={systemTemplates.length === 0}
          title={getTranslate('reports.new.templates.basic.label')}>
          <SlideDown
            isOpen={isShown}
            duration={500}
            updateHeight={needUpdateHeight}>
            <div>{this.renderList(systemTemplates)}</div>
          </SlideDown>
        </Tab>
        <Tab title={getTranslate('reports.new.templates.custom.label')}>
          <SlideDown
            isOpen={isShown}
            duration={500}
            updateHeight={needUpdateHeight}>
            <div>{this.renderList(userTemplates)}</div>
          </SlideDown>
        </Tab>
      </Tabs>
    );
  }

  renderList(templates: Pattern[]) {
    const { onUseTemplate, onDeleteTemplate } = this.props;

    return templates.map(({ name, reportTemplateId, isSystem }) => {
      return (
        <div
          className='templates-builder-patterns__item'
          key={reportTemplateId}>
          <Template
            title={name}
            onClick={() => onUseTemplate(reportTemplateId)}
            onDelete={
              isSystem ? undefined : () => onDeleteTemplate(reportTemplateId)
            }
          />
        </div>
      );
    });
  }

  renderButton = () => {
    const { isFetched, userTemplates, getTranslate } = this.props;
    const { isShown } = this.state;
    if (!isFetched || !userTemplates.length) return null;

    let classes = 'ui-button_fly templates-builder-patterns__button';
    if (isShown) {
      classes += ' templates-builder-patterns__button_reverse';
    }

    return (
      <Button
        customClass={classes}
        text={
          isShown
            ? getTranslate('reports.new.template.hide.button')
            : getTranslate('reports.new.template.expand.button')
        }
        icon='im-Arrow-down'
        size='inline'
        iconSize={9}
        onClick={this.toggleState}
      />
    );
  };

  toggleState = () => {
    this.setState((state) => {
      return {
        isShown: !state.isShown,
      };
    });
  };
}

export default addTranslation(BuilderPatterns);
