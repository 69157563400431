import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Icon from 'components/ui/icon';
import MafHelpers from 'components/maf/MafHelpers';
import { MafState, MafStepsType, MafTab } from 'components/maf/MafTypes';
import Utils from '../../../../helpers/Utils';
import { AnyObject } from 'types/Common';
import './mafsteps.scss';

interface OwnProps {
  form: MafTab | undefined;
  validationErrors: AnyObject;
  currentStep: number;
  onChangeStep: (index: number) => void;
  tabs?: MafTab[];
}

interface ConnectedProps {
  validatedTabs: MafState['validatedTabs'];
}

type Props = OwnProps & ConnectedProps & IntlProps;

/* eslint-disable sonarjs/no-duplicate-string */
// disable no-duplicate-string because of the same strings is allowed in maps
const iconsMap = {
  //foremaf
  [MafStepsType.GENERAL]: 'im-Forms',
  [MafStepsType.UBOS]: 'im-Avatar',
  //simplemaf
  [MafStepsType.BASIC_INFO]: 'im-Forms',
  [MafStepsType.UBO]: 'im-Avatar',
  [MafStepsType.WEBSITE_AND_APPS]: 'im-Desktop',
  [MafStepsType.SIGNATORIES]: 'im-My-team',
  [MafStepsType.DETAILS]: 'im-Reports',
  [MafStepsType.OVERVIEW]: 'im-List',
  //daf
  [MafStepsType.DAF_INFO]: 'im-Avatar',
  [MafStepsType.DAF_MERCH]: 'im-House',
  [MafStepsType.DAF_UBO]: 'im-My-team',
  [MafStepsType.DAF_MANAGERS]: 'im-My-team',
  [MafStepsType.DAF_CONTACTS]: 'im-Avatar',
  [MafStepsType.DAF_ADD_CONTACTS]: 'im-Phone',
  [MafStepsType.DAF_SIGNERS]: 'im-Edit',
  [MafStepsType.DAF_DIRECTORS]: 'im-My-team',
  [MafStepsType.DAF_SHAREHOLDERS]: 'im-My-team',
  [MafStepsType.DAF_WEBSITE_INFO]: 'im-Desktop',
  [MafStepsType.DAF_LICENSING]: 'im-Document',
  [MafStepsType.DAF_AML]: 'im-Catalog',
  [MafStepsType.DAF_PREVENTION]: 'im-Risk',
  [MafStepsType.DAF_PAYIN]: 'im-Card-pay',
  [MafStepsType.DAF_PAYOUT]: 'im-Card-pay',
  [MafStepsType.DAF_ANTIFRAUD]: 'im-Configurator',
  [MafStepsType.DAF_ACCOUNT]: 'im-Marker',
  [MafStepsType.DAF_ADD_INFO]: 'im-References',
  [MafStepsType.DAF_DOCS_UPLOAD]: 'im-Forms',
  //daf additional
  [MafStepsType.ADDITIONAL_DAF_COMPANY_DOCS]: 'im-Forms',
  [MafStepsType.ADDITIONAL_DAF_UBO_DOCS]: 'im-Avatar',
  [MafStepsType.ADDITIONAL_DAF_DIRECTOR_DOCS]: 'im-My-team',
  [MafStepsType.ADDITIONAL_DAF_SHAREHOLDERS_DOCS]: 'im-Single-Payout',
};
/* eslint-enable sonarjs/no-duplicate-string */

const OverView = {
  name: 'overview.maf.tab',
  caption: 'overview.maf.tab',
};

class MafSteps extends React.PureComponent<Props> {
  render() {
    const tabs = this.props.tabs && this.updateTabs(this.props.tabs);

    return (
      <div className='maf-steps'>
        {tabs?.map(this.renderStep)}
        {this.renderStep(OverView, tabs?.length)}
      </div>
    );
  }

  renderStep = (step, index) => {
    const { currentStep } = this.props;

    return (
      <div
        key={`${step.name}${Utils.getHash()}`}
        className={classNames('maf-steps__item', {
          'maf-steps__item_current': currentStep === index,
        })}
        onClick={() => this.changeStep(index)}>
        <span className='maf-steps__item-inner'>
          {this.getStepLayout(step, index)}
        </span>
      </div>
    );
  };

  getStepLayout(step: MafTab, index: number) {
    const { validatedTabs, validationErrors, getTranslate } = this.props;
    const isValid = MafHelpers.validateSection(step, validationErrors);
    const groupByTag = step?.tabs ? step.tabs[0]?.group_by_tag : null;
    const text = getTranslate(groupByTag || step.caption);

    const validatedAndInvalid = validatedTabs[step.name] && !isValid;

    const isPending = step.tabs?.some((tab) => {
      return (
        !MafHelpers.validateSection(tab, validationErrors) &&
        MafHelpers.isTabAwaitingClarification(tab)
      );
    });

    if (!isValid && isPending) {
      return (
        <>
          <Icon
            className='maf-steps__icon maf-steps__icon_pending'
            size={16}
            name='im-Refill'
          />
          <span className='maf-steps__text_pending'>{text}</span>
        </>
      );
    }

    if (validatedAndInvalid) {
      return (
        <>
          <Icon
            className='maf-steps__icon maf-steps__icon_error'
            size={12}
            name='im-Warning-2'
          />
          <span className='maf-steps__text_error'>{text}</span>
        </>
      );
    }

    if (validatedTabs[step.name] && isValid) {
      return (
        <>
          <Icon
            className='maf-steps__icon maf-steps__icon_success'
            size={12}
            name='im-Tick'
          />
          <span className='maf-steps__text_success'>{text}</span>
        </>
      );
    }

    const isCurrent = this.props.currentStep === index;

    return (
      <>
        <Icon
          className={classNames('maf-steps__icon', {
            'maf-steps__icon_current': isCurrent,
          })}
          size={16}
          name={
            iconsMap[groupByTag?.toLowerCase() || step.caption?.toLowerCase()]
          }
        />
        <span>{text}</span>
      </>
    );
  }

  changeStep = (newStep: number) => {
    const { onChangeStep } = this.props;
    onChangeStep(newStep);
  };

  updateTabs = (tabs: MafTab[]): MafTab[] => {
    return tabs.map((tab) => {
      if (tab.caption === '') {
        return {
          ...tab,
          caption: tab.tabs[0].caption,
          name: tab.tabs[0].name,
        };
      }
      return tab;
    });
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  validatedTabs: state.maf.validatedTabs,
});

export default connect(mapStateToProps)(addTranslation(MafSteps));
