import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { create } from 'api/lcManage';
import Create from './Create';
import Utils from 'helpers/Utils';
import path from 'helpers/path';
import { RowItem } from 'pages/lcmanage/LcManageTypes';
import { WithRouterProps } from 'decorators/withRouter';

interface OwnProps {
  onCreateSuccess: (row: RowItem) => void;
}

type Props = OwnProps & WithRouterProps;

interface State {
  form: {
    ticket: string;
    token: string;
    en: string;
    ru: string;
  };
  isLoading: boolean;
}

class CreateContainer extends PureComponent<Props, State> {
  private inputToken;

  constructor(props: Props) {
    super(props);

    this.state = {
      form: {
        ticket: '',
        token: '',
        en: '',
        ru: '',
      },
      isLoading: false,
    };
  }

  render() {
    const { form, isLoading } = this.state;
    return (
      <Create
        form={form}
        isLoading={isLoading}
        onClickUpload={this.goToUpload}
        onChange={this.changeForm}
        onCreate={() => this.create()}
      />
    );
  }

  changeForm = (key, value) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [key]: value,
      },
    });
  };

  async create() {
    const { onCreateSuccess } = this.props;
    const { form } = this.state;
    const { token, ticket, en, ru } = form;
    this.setState({ isLoading: true });

    try {
      const newRow = await create({
        token,
        ticket_number: ticket,
        texts: [
          { lang: 'en', text: en },
          { lang: 'ru', text: ru },
        ],
      });
      newRow.customId = Utils.getHash();
      this.setState({
        form: {
          ...form,
          token: '',
          en: '',
          ru: '',
        },
      });
      onCreateSuccess(newRow);
      const inputToken: any = document.querySelector('.lc-create-input-token');
      if (inputToken) {
        inputToken.querySelector('input')?.focus();
      }
    } finally {
      this.setState({ isLoading: false });
    }
  }

  goToUpload = () => {
    const { history } = this.props;
    history.push(path('/lcmanage/upload'));
  };
}

export default withRouter(CreateContainer);
