import React from 'react';
import CopyToClipboard from 'components/ui/copyToClipboard/CopyToClipboard';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import './AdditionalInfo.scss';

interface Props extends IntlProps {
  content: string;
}

class AdditionalInfo extends React.Component<Props> {
  render(): JSX.Element {
    const content: string = JSON.stringify(
      JSON.parse(this.props.content),
      null,
      4
    );
    return (
      <div className='ui-callback-items ui-callback-items_modern'>
        <div className='ui-callback-items__content'>
          <CopyToClipboard
            text={content}
            customClass='ui-callback-items__copy'
          />
          <pre className='ui-callback-items__inner'>{content}</pre>
        </div>
      </div>
    );
  }
}

export default addTranslation(AdditionalInfo);
