import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';
import { GraphData, ChartFilters } from 'types/Analytics';

const createChart = (payload): Promise<GraphData> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Analytics_CreateChart,
        payload,
      },
    ],
  });
};

const getUserCharts = (
  payload?
): Promise<Array<{ biChartId: string; order: number }>> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Analytics_GetUserCharts,
        payload,
      },
    ],
  });
};

const getChart = ({
  id,
  type = '',
}: {
  id: string;
  type?: string;
}): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Analytics_GetChart,
        payload: { id, type },
      },
    ],
  });
};

const deleteChart = ({ id }: { id: string }) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Analytics_DeleteChart,
        payload: { id },
      },
    ],
  });
};

const moveChart = (payload: { from: string; to: string }) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Analytics_MoveChart,
        payload,
      },
    ],
  });
};

const getChartFilters = (payload: {
  id: string;
}): Promise<{ data: ChartFilters }> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Analytics_GetChartFilters,
        payload,
      },
    ],
  });
};

const editChart = (payload): Promise<GraphData> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Analytics_EditChart,
        payload,
      },
    ],
  });
};

const applySelectedChartFiltersRequest = (payload): Promise<GraphData> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Analytics_ApplySelectedChartFilters,
        payload,
      },
    ],
  });
};

const shareChartsRequest = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Analytics_ShareCharts,
        payload,
      },
    ],
  });
};

const getSharedWithMeChartsRequest = (): Promise<
  Array<{ biChartId: string; order: number }>
> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Analytics_FetchSharedForMeCharts,
      },
    ],
  });
};

const getChartsSharedWithRequest = (): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Analytics_FetchChartsSharedWith,
      },
    ],
  });
};

export {
  getChart,
  createChart,
  getUserCharts,
  deleteChart,
  moveChart,
  getChartFilters,
  editChart,
  shareChartsRequest,
  getSharedWithMeChartsRequest,
  getChartsSharedWithRequest,
  applySelectedChartFiltersRequest,
};
