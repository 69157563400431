import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import DynamicTable from 'components/ui/table/DynamicTable';
import tableNames from 'constants/tableNames';
import { Column, Header } from 'components/ui/table';
import Row from '../../../../../components/ui/table/row';
import './chbHistory.scss';

interface OwnProps {
  data: { date: string; event: string }[];
  isFetch: boolean;
}

type Props = OwnProps & IntlProps;

const configuration = [
  {
    id: 'event',
    localId: 'charges.history.event.header',
  },
  {
    id: 'date',
    localId: 'charges.history.date.header',
  },
];

class ChbHistory extends PureComponent<Props> {
  render() {
    return (
      <div className='chb-history'>
        <DynamicTable
          tableName={tableNames.chbHistory}
          resizableColumns={false}
          items={this.props.data}
          rowRender={this.renderRow}
          customConfiguration={configuration}
          headerRender={this.renderTableHeader()}
          isFetch={this.props.isFetch}
        />
      </div>
    );
  }

  renderRow = (data) => {
    return (
      <Row>
        {configuration.map((item) => {
          return (
            <Column
              id={data[item.id]}
              key={data[item.id]}
              modifier={item.id}
              content={this.props.getTranslate(data[item.id])}
            />
          );
        })}
      </Row>
    );
  };

  renderTableHeader = () => {
    return (
      <Header>
        {configuration.map((item) => {
          return (
            <Column
              id={item.id}
              key={item.id}
              modifier={item.id}
              content={this.props.getTranslate(item.localId)}
            />
          );
        })}
      </Header>
    );
  };
}

export default addTranslation(ChbHistory);
