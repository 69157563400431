import React from 'react';
import { connect } from 'react-redux';
import { addTranslation } from '../../../../decorators/addTranslation';

type Props = {
  message: string;
};

const Info = ({ message }: Props) => (
  <div className='item'>
    <div className={'info'}>
      <div>
        <i className='ui-icon  icomoon icomoon-im-Info-Square main-menu__item-icon fs-18'></i>
      </div>
      <div className='data-column'>
        <div>{message}</div>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => {
  return {
    message: state.paymentSummaryData.message,
  };
};

const Info_ = connect(mapStateToProps)(Info);

export default addTranslation(Info_);
