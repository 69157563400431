import React, { Component } from 'react';
import './counter.scss';

interface Props {
  success: number | string;
  decline: number | string;
  neutral?: number | string;
}

class Counter extends Component<Props> {
  render() {
    const { success, decline, neutral } = this.props;

    return (
      <span className='ui-counter'>
        <span className='ui-counter__item ui-counter__item_success'>
          +{success}
        </span>
        <span className='ui-counter__item ui-counter__item_decline'>
          -{decline}
        </span>
        {neutral !== undefined ? (
          <span className='ui-counter__item ui-counter__item_waiting'>
            {neutral}
          </span>
        ) : null}
      </span>
    );
  }
}

export default Counter;
