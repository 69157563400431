import React, { Component } from 'react';
import { connect } from 'react-redux';
import FinancialReports from './FinancialReports';
import { downloadFinancialReport, getFinancialReport } from 'api/finance';
import { addListeners } from 'decorators/addListeners';
import Messages from 'constants/rpcTypes';
import ItemConfiguration from 'types/ItemConfiguration';
import tableNames from 'constants/tableNames';
import { loadFromLink } from 'helpers/saveFile';
import MetricService from 'helpers/metricService/MetricService';
import showNotification from 'components/ui/notification/showNotification';

export interface DataItem {
  balanceId: string;
  balanceTitle: string;
  fileName: string;
  fileFormat: string;
  fileSize: string;
  fileKey: string;
  startDate: string;
  endDate: string;
  isSelected: boolean;
}

interface ConnectedProps {
  configuration: ItemConfiguration[];
}

interface State {
  date: { dateFrom: string; dateTo: string };
  exactMatch: boolean;
  items: DataItem[];
  state: 'loading' | 'empty' | 'data' | 'notFound';
  isAllSelected: boolean;
  isEmailSending: boolean;
  loadingFiles: string[];
}

@addListeners([
  Messages.FinancialReport_ListStatus,
  Messages.FinancialReport_DownloadStatus,
  Messages.Rpc_FinancialReport_DownloadRequest_Success,
])
class FinancialReportsContainer extends Component<ConnectedProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      date: { dateFrom: '', dateTo: '' },
      exactMatch: true,
      items: [],
      state: 'empty',
      isAllSelected: false,
      isEmailSending: false,
      loadingFiles: [],
    };
  }
  componentDidUpdate(
    prevProps: Readonly<ConnectedProps>,
    prevState: Readonly<State>
  ) {
    if (
      (prevState.date !== this.state.date &&
        this.state.date.dateFrom.length === 10 &&
        this.state.date.dateTo.length === 10) ||
      (prevState.exactMatch !== this.state.exactMatch && this.state.date)
    ) {
      this.fetchReport();
    }
  }

  render() {
    const { date, items, isAllSelected, loadingFiles, isEmailSending, state } =
      this.state;
    return (
      <FinancialReports
        date={date}
        items={items}
        exactMatch={this.state.exactMatch}
        isAllSelected={isAllSelected}
        state={state}
        loadingFiles={loadingFiles}
        isEmailSending={isEmailSending}
        onChange={this.onChange}
        onDownload={(fileKey) => this.onDownload(fileKey)}
        configuration={this.props.configuration}
        onSelectAllData={this.onSelectAllData}
        onSelectDataItem={this.onSelectDataItem}
        onSendToEmail={this.onSendToEmail}
      />
    );
  }

  fetchReport = async () => {
    const { date } = this.state;
    if (!date.dateFrom || !date.dateTo) return;

    this.setState({ state: 'loading' });

    try {
      const period = [date.dateFrom, date.dateTo];
      await getFinancialReport({ period, exactMatch: this.state.exactMatch });
    } catch (e) {
      console.error('Get financial report error: ', e);
      this.setState({ state: 'empty' });
      showNotification({ status: 'error', content: e.error?.message });
    }
  };

  onChange = (key, value) => {
    this.setState({ [key]: value } as Pick<State, keyof State>);
  };

  getSelected = (fileKey?) => {
    const selected = this.state.items.reduce((result: string[], item) => {
      if (item.isSelected) {
        return [...result, item.fileKey];
      }
      return result;
    }, []);

    if (fileKey) {
      selected.push(fileKey);
    }

    return selected;
  };

  onDownload = async (fileKey) => {
    try {
      const selected = this.getSelected(fileKey);

      this.setState({
        loadingFiles: selected,
      });
      await downloadFinancialReport(selected);
      MetricService.send({
        action: 'click',
        actionKey: 'finance.reports.download',
      });
    } catch (e) {
      console.error('Report download error: ', e);
    }
  };

  onSendToEmail = async () => {
    try {
      this.setState({ isEmailSending: true });

      const selected = this.getSelected();

      await downloadFinancialReport(selected, 'email');
      MetricService.send({
        action: 'click',
        actionKey: 'finance.reports.sendToEmail',
      });
    } catch (e) {
      console.error('Report email send error: ', e);
    } finally {
      this.setState({ isEmailSending: false });
    }
  };

  onSelectDataItem = (fileKey) => {
    this.setState((state) => ({
      items: state.items.map((item) => {
        if (item.fileKey === fileKey) {
          return {
            ...item,
            isSelected: !item.isSelected,
          };
        }
        return item;
      }),
    }));
  };

  onSelectAllData = (isSelected) => {
    this.setState((state) => ({
      items: state.items.map((item) => ({
        ...item,
        isSelected,
      })),
    }));
  };

  onEvent = async ({ name, data }) => {
    if (name === Messages.FinancialReport_DownloadStatus) {
      if (data.payload.status === 'ready') {
        this.setState({ loadingFiles: [] });
        await loadFromLink(data.payload.url);
      }
    } else if (name === Messages.FinancialReport_ListStatus) {
      this.setState({
        items: data.payload.rows.map((item) => ({
          ...item,
          isSelected: false,
        })),
        state: data.payload.rows.length ? 'data' : 'notFound',
      });
    }
  };
}

const mapStateToProps = (state): ConnectedProps => {
  return {
    configuration: state.configuration[tableNames.financialReport],
  };
};

export default connect(mapStateToProps)(FinancialReportsContainer);
