import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { addListeners, IListeners } from 'decorators/addListeners';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import { changePassword, resetUserErrors } from 'actions/user';
import api from 'api/user';
import showNotification from 'components/ui/notification/showNotification';
import PageAuthWrapper from '../components/PageAuthWrapper';
import PasswordChangeForm from './PasswordChangeForm';
import Messages from 'constants/rpcTypes';
import path from 'helpers/path';
import { AnyObject } from 'types/Common';
import Scenarios from 'pages/auth/passwordChangePage/Scenarios';
import { StoreProps } from 'store';

interface ConnectedProps {
  user: AnyObject;
  settings: AnyObject;
}

type Props = ConnectedProps & StoreProps & IntlProps & WithRouterProps;

interface State {
  password: string;
  passwordType: string;
  passwordConfirm: string;
  passwordConfirmType: string;
  isTokenValid: boolean;
}

@addListeners([Messages.Auth_PasswordResetComplete])
class PasswordChangePage extends Component<Props, State> implements IListeners {
  private readonly token;

  constructor(props: Props) {
    super(props);

    this.state = {
      isTokenValid: false,
      password: '',
      passwordType: 'password',
      passwordConfirm: '',
      passwordConfirmType: 'password',
    };

    this.token = this.getUrlParams().get('token');
    props.dispatch(resetUserErrors());
  }

  componentDidMount(): void {
    this.checkToken(this.token);
  }

  render() {
    const {
      isTokenValid,
      password,
      passwordType,
      passwordConfirm,
      passwordConfirmType,
    } = this.state;
    const { user, settings } = this.props;

    if (!isTokenValid) return null;

    return (
      <PageAuthWrapper
        title='auth.changePassword.header'
        text={this.getTitle()}
        subtext='auth.changePassword.info.description'>
        <PasswordChangeForm
          validation={user && user.validationErrors}
          password={password}
          passwordType={passwordType}
          passwordConfirm={passwordConfirm}
          passwordConfirmType={passwordConfirmType}
          settings={settings}
          onChange={this.changeFields}
          onSave={this.changePassword}
        />
      </PageAuthWrapper>
    );
  }

  getTitle() {
    const { location } = this.props;

    const { scenario }: any = queryString.parse(location.search);

    switch (scenario) {
      case Scenarios.new:
        return 'auth.changeTemporaryPassword.info.title';
      case Scenarios.renew:
        return 'auth.changeOldPassword.info.title';
      case Scenarios.reset:
        return 'auth.changeResetPassword.info.title';
      default:
        return '';
    }
  }

  changeFields = (key: string, value: string | boolean) => {
    this.setState({
      [key]: value,
    } as Pick<State, keyof State>);
  };

  changePassword = () => {
    const { dispatch } = this.props;
    const { password, passwordConfirm } = this.state;

    dispatch(changePassword({ password, passwordConfirm, token: this.token }));
  };

  getUrlParams(): URLSearchParams {
    if (!this.props.history.location.search) return new URLSearchParams();
    return new URLSearchParams(this.props.history.location.search);
  }

  checkToken = (token) => {
    const { history, getTranslate } = this.props;

    api.checkToken(token).then(({ isValid }) => {
      if (isValid) {
        this.setState({ isTokenValid: isValid });
      } else {
        showNotification({
          status: 'error',
          content: (
            <span>
              {getTranslate('auth.passwordChangeExpiredNotification')}
            </span>
          ),
        });
        history.push(path('/login'));
      }
    });
  };

  async onEvent({ data, name }) {
    const { history, getTranslate } = this.props;
    const { status } = data.rpc;

    if (name === Messages.Auth_PasswordResetComplete && status === 'success') {
      showNotification({
        status: 'success',
        content: <span>{getTranslate('auth.passwordChangeNotification')}</span>,
      });
      history.push(path('/login'));
    }
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
  settings: state.settings,
});

export default withRouter(
  connect(mapStateToProps)(addTranslation(PasswordChangePage))
);
