import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { deleteSalt, updateSalt } from 'api/projects';
import { updateProject, updateProjectField } from 'actions/projects';
import { addListeners } from 'decorators/addListeners';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import SaltSettings from './SaltSettings';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import { AnyObject } from 'types/Common';
import Messages from 'constants/rpcTypes';
import { StoreProps } from 'store';

interface ConnectedProps {
  isEditable: boolean;
  status: boolean;
  project: AnyObject;
  salt: string;
  canSaveSalt: string;
  validationErrors: AnyObject;
}

type Props = ConnectedProps & StoreProps & WithPermissions;

interface State {
  isEditing: boolean;
  isLoading: boolean;
  isFocus: boolean;
}

@addListeners([
  Messages.Project_UpdateSalt,
  Messages.Project_DeleteSalt,
  Messages.Confirm_Accept,
  Messages.Confirm_Reject,
])
class SaltSettingsContainer extends PureComponent<Props, State> {
  private mode: AnyObject;
  private inputRef: any = React.createRef();
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditing: false,
      isLoading: false,
      isFocus: false,
    };
    this.mode = {
      delete: false,
      update: false,
    };
  }
  componentDidMount() {
    this.setState({
      isEditing:
        !!this.props.canSaveSalt || !this.props.project.isSaltSpecified,
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevProps.project.projectId !== this.props.project.projectId) {
      this.updateState(this.props.project);
    }
    if (!prevState.isFocus && this.state.isFocus) {
      this.focusInput();
    }
  }

  render() {
    const {
      project: { isSaltSpecified },
      salt,
      canSaveSalt,
      isEnabled,
      status,
      validationErrors,
    } = this.props;
    const { isEditing, isLoading } = this.state;
    return (
      <SaltSettings
        isEnabled={isEnabled(Messages.Project_UpdateSalt) && status}
        isSaltSpecified={isSaltSpecified}
        salt={salt}
        canSaveSalt={canSaveSalt}
        isEditing={isEditing}
        isLoading={isLoading}
        error={validationErrors.salt}
        onChangeField={this.onChange}
        editSalt={this.canEdit()}
        onSubmit={this.submit}
        onEdit={this.enableEdit}
        preDeleteSalt={this.preDeleteSalt}
        inputRef={this.inputRef}
      />
    );
  }

  onChange = (name, value) => {
    const { dispatch, validationErrors } = this.props;
    dispatch(
      updateProject({
        salt: value,
        validationErrors: { ...validationErrors, salt: '' },
      })
    ).then(() => {
      this.checkChanges();
    });
    this.mode.update = true;
  };

  submit = () => {
    const { canSaveSalt } = this.props;
    if (isNotAvailableForSupport(Messages.Project_UpdateSalt) || !canSaveSalt)
      return;

    if (canSaveSalt === 'update') {
      return this.saveSalt();
    }
    if (canSaveSalt === 'delete') {
      return this.onDelete();
    }
  };

  async saveSalt() {
    const {
      project: { projectId },
      salt,
    } = this.props;

    try {
      this.setState({ isLoading: true });
      await updateSalt({ projectId, salt });
    } catch {
      this.setState({ isLoading: false });
    }
  }

  checkChanges = () => {
    const { salt, dispatch } = this.props;
    const { isEditing } = this.state;

    if (isEditing && salt) {
      dispatch(updateProjectField('canSaveSalt', 'update'));
    } else if (this.mode.delete) {
      dispatch(updateProjectField('canSaveSalt', 'delete'));
    } else {
      dispatch(updateProjectField('canSaveSalt', ''));
    }
  };

  enableEdit = () => {
    this.setState({ isEditing: true, isFocus: true });
  };

  preDeleteSalt = () => {
    this.onChange('salt', '');
    this.setState({ isEditing: true }, this.checkChanges);
    this.mode.delete = true;
  };

  onDelete = async () => {
    const {
      project: { projectId },
    } = this.props;

    this.setState({ isLoading: true });
    try {
      await deleteSalt(projectId);
    } catch {
      this.setState({ isLoading: false });
    }
  };

  focusInput = () => {
    this.inputRef.current.querySelector('.ui-input__field').focus();
  };

  updateState = (data) => {
    const { dispatch } = this.props;

    dispatch(
      updateProject({
        project: { ...data },
        salt: '',
        canSaveSalt: false,
      })
    );
    this.setState({ isEditing: !data.isSaltSpecified, isFocus: false });
    this.mode = {
      update: false,
      delete: false,
    };
  };

  canEdit = () => {
    const { isEditable } = this.props;
    return isEditable;
  };

  onEvent = ({ name, data }) => {
    const { validationErrors, dispatch } = this.props;
    if (
      name === Messages.Project_UpdateSalt ||
      name === Messages.Project_DeleteSalt
    ) {
      if (data.payload.validationErrors) {
        dispatch(
          updateProjectField('validationErrors', {
            ...validationErrors,
            ...data.payload.validationErrors,
          })
        );
      } else {
        this.updateState(data.payload);
      }
    }

    this.setState({ isLoading: false });
  };
}

const mapStateToProps = ({ projects }): ConnectedProps => ({
  isEditable: projects.isEditable,
  project: projects.project,
  status: projects.project.status,
  salt: projects.salt,
  canSaveSalt: projects.canSaveSalt,
  validationErrors: projects.validationErrors,
});
export default connect(mapStateToProps)(addPermissions(SaltSettingsContainer));
