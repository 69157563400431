import React, { PureComponent } from 'react';
import { AutoSizer } from 'react-virtualized';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { throttle } from 'lodash';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Checkbox from 'components/ui/checkbox';
import CustomScrollbar from 'components/ui/customScrollbar';
import Switch from 'components/ui/switch';
import SlideDown from 'components/ui/slideDown';
import Icon from 'components/ui/icon';
import Loader from 'components/ui/loader';
import Animation from 'components/ui/animation';
import NotificationItem from './components/notificationItem';

import { UserNotificationsType } from 'types/UserNotifications';
import { AnyObject } from 'types/Common';
import './userNotificationsPanel.scss';

interface Props extends IntlProps {
  userNotifications: UserNotificationsType;
  categoriesDictionary: AnyObject;
  typesDictionary: AnyObject;
  isLoading: boolean;
  onMarkAsRead: (id: string) => void;
  onDelete: (id: string) => void;
  onDeleteAll: () => void;
  onLoadMore: () => void;
  onChangeFilter: (alias: string, value: any) => void;
  onChangeSetting: (category: string, isEnabled: boolean) => void;
}

interface State {
  isOpenSettings: boolean;
}

const THRESHOLD = 100;

class UserNotificationsPanel extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isOpenSettings: false,
    };

    this.onScroll = throttle(this.onScroll, 100);
  }

  render() {
    const { isLoading } = this.props;

    return (
      <div className='user-notifications-panel'>
        {this.renderHeader()}
        {this.renderSettings()}
        {this.renderFilters()}
        {!isLoading && this.renderContent()}
        {isLoading && <Loader />}
      </div>
    );
  }

  renderHeader() {
    const { getTranslate } = this.props;
    const { isOpenSettings } = this.state;

    return (
      <div className='user-notifications-panel__header'>
        <div className='user-notifications-panel__header-title'>
          {getTranslate('common.notifications.label')}
        </div>
        <Icon
          name='settings'
          className={classNames(
            'user-notifications-panel__header-open-settings',
            {
              'user-notifications-panel__header-open-settings_opened':
                isOpenSettings,
            }
          )}
          onClick={this.toggleSettings}
        />
      </div>
    );
  }

  renderContent() {
    const {
      userNotifications: { items, isFetched },
    } = this.props;

    return (
      <Animation>
        {items.length === 0 && isFetched
          ? this.renderEmptyBlock()
          : this.renderList()}
      </Animation>
    );
  }

  renderFilters() {
    const {
      userNotifications: { filters },
      onDeleteAll,
      getTranslate,
    } = this.props;

    return (
      <div className='user-notifications-panel__filters'>
        <Switch
          id='user-notifications-filter-is-read'
          checked={filters.onlyNew}
          text={getTranslate('common.onlyNew.label')}
          customClass='user-notifications-panel__filter-only-new'
          onChange={({ target }) =>
            this.changeFilter('onlyNew', target.checked)
          }
        />
        <div
          className='user-notifications-panel__filter-clear-all'
          onClick={onDeleteAll}>
          {getTranslate('common.clearAll.label')}
        </div>
      </div>
    );
  }

  changeFilter = (key: string, value: any) => {
    const { onChangeFilter } = this.props;
    onChangeFilter(key, value);
  };

  renderSettings() {
    const {
      userNotifications: { settings },
      categoriesDictionary,
      onChangeSetting,
      getTranslate,
    } = this.props;
    const { isOpenSettings } = this.state;

    return (
      <SlideDown isOpen={isOpenSettings}>
        <div className='user-notifications-panel__settings'>
          <div className='user-notifications-panel__settings-title'>
            {getTranslate('common.showCategories.label')}
          </div>
          <div className='user-notifications-panel__settings-list'>
            {settings.map((setting) => {
              const { category, isEnabled, isDisplayed, isChangeable } =
                setting;
              const dictionaryData = categoriesDictionary[category];

              if (!dictionaryData || !isDisplayed) return null;

              return (
                <div
                  key={category}
                  className='user-notifications-panel__settings-item'>
                  <Checkbox
                    id={category}
                    text={getTranslate(dictionaryData.text)}
                    checked={Boolean(isEnabled)}
                    disabled={!isChangeable}
                    onChange={({ target }) =>
                      onChangeSetting(category, target.checked)
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </SlideDown>
    );
  }

  renderList() {
    const {
      userNotifications: { items },
      typesDictionary,
      onMarkAsRead,
      onDelete,
    } = this.props;

    return (
      <div className='user-notifications-panel__list'>
        <AutoSizer>
          {({ width, height }) => (
            <CustomScrollbar
              onScroll={this.onScroll}
              customHeight={height}
              customWidth={width}>
              <div className='user-notifications-panel__list-inner'>
                <TransitionGroup>
                  {items.map((data) => {
                    return (
                      <CSSTransition
                        key={data.id}
                        timeout={700}
                        enter={true}
                        exit={true}
                        unmountOnExit={true}
                        classNames='user-notifications-animation'>
                        <NotificationItem
                          item={data}
                          typeDictionary={typesDictionary[data.type]}
                          onMarkAsRead={onMarkAsRead}
                          onDelete={onDelete}
                        />
                      </CSSTransition>
                    );
                  })}
                </TransitionGroup>
              </div>
            </CustomScrollbar>
          )}
        </AutoSizer>
      </div>
    );
  }

  renderEmptyBlock() {
    const {
      userNotifications: { filters },
      getTranslate,
    } = this.props;

    return (
      <div className='user-notifications-panel__empty'>
        {getTranslate(
          filters.onlyNew
            ? 'common.noNewMessages.label'
            : 'common.noMessages.label'
        )}
      </div>
    );
  }

  onScroll = (data) => {
    const { onLoadMore } = this.props;
    const { clientHeight, scrollTop, scrollHeight } = data;
    if (THRESHOLD >= scrollHeight - (clientHeight + scrollTop)) {
      onLoadMore();
    }
  };

  toggleSettings = () => {
    this.setState((state) => {
      return {
        isOpenSettings: !state.isOpenSettings,
      };
    });
  };
}

export default addTranslation(UserNotificationsPanel);
