import React, { Component } from 'react';
import api from 'api/balance';
import checkAccess from 'decorators/checkAccess';
import { BalanceType } from './BalanceTypes';
import BalancePage from './BalancePage';
import MetricService from 'helpers/metricService/MetricService';
import Messages from 'constants/rpcTypes';

interface ContractType {
  id: number | string;
  text: string;
  isSelected: boolean;
}

interface State {
  isFetch: boolean;
  balanceList: BalanceType[];
  contractList: ContractType[];
}

@checkAccess([Messages.Balance_Get])
class BalanceContainer extends Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      isFetch: false,
      balanceList: [],
      contractList: [],
    };
  }

  async componentDidMount() {
    await this.fetchBalanceList();
    await this.fetchContractsList();
  }

  render() {
    const { isFetch, balanceList, contractList } = this.state;

    return (
      <BalancePage
        isFetch={isFetch}
        balanceList={balanceList}
        contractList={contractList}
        applyFilter={() => {
          this.sendMetric();
          return this.fetchBalanceList();
        }}
        setSelectedContracts={this.setSelectedContracts}
      />
    );
  }

  async fetchBalanceList() {
    const { contractList } = this.state;
    const selectedContracts = contractList.reduce((result, item): any => {
      if (item.isSelected) {
        return [...result, item.id];
      }
      return result;
    }, []);

    this.setState({ isFetch: false });
    try {
      const balanceList = await api.getBalance(selectedContracts);
      this.setState({ balanceList });
    } finally {
      this.setState({ isFetch: true });
    }
  }

  async fetchContractsList() {
    try {
      const contractList = await api.getContracts();
      const mappedContractList = contractList.map((item) => ({
        id: item.id,
        text: item.title,
        isSelected: false,
      }));
      this.setState({ contractList: mappedContractList });
    } catch (e) {
      console.error(e);
    }
  }

  setSelectedContracts = (contractList) => {
    MetricService.send({
      action: 'click',
      actionKey: 'finance.old.filter.agreementNumber.apply',
    });
    this.setState({ contractList });
  };

  sendMetric = () => {
    MetricService.send({
      action: 'click',
      actionKey: 'finance.old.filter.agreementBrandLe',
    });
  };
}

export default BalanceContainer;
