import BaseMessageHandler from '../BaseMessageHandler';
import path from 'helpers/path';
import urlsMap from 'constants/urlsMap';
import { formIdRoutes } from 'pages/payouts/routesMapper';

export default class PaymentOperation_PreFilledPayout extends BaseMessageHandler {
  success = () => {
    const { history, message } = this;
    const { payload } = message;
    history.push({
      pathname: path(`/${urlsMap.payouts}/request/${formIdRoutes.single}`),
      state: { data: payload, preFilled: true },
    });
  };
}
