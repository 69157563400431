import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

const initialState: any = {};

const filtersValues = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.UPDATE_FILTERS_VALUES:
      return {
        ...state,
        ...payload.list,
      };
    case ACTIONS.UPLOAD_FILTERS_VALUES: {
      const concatedList = state[payload.name]?.list
        ? state[payload.name].list.concat(payload.list)
        : payload.list;
      const newData = {
        ...state[payload.name],
        ...payload,
        list: payload.list ? concatedList : state[payload.name]?.list,
      };
      return {
        ...state,
        [payload.name]: newData,
      };
    }
    case ACTIONS.RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

export default filtersValues;
