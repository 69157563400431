import { createSelector } from 'reselect';

const getDictionary = (state, dictionaryId, primaryKey) => {
  return {
    primaryKey,
    values: state.filtersValues?.[dictionaryId]?.list || [],
  };
};

export default createSelector(getDictionary, ({ primaryKey, values }) => {
  const map = {};
  (values || []).forEach((val) => {
    map[val[primaryKey]] = { ...val };
  });
  return map;
});
