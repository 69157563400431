import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';
import { AnyObject } from 'types/Common';

interface ActionParams {
  data: AnyObject;
}

interface FailActionParams {
  error: any;
}

export const fetchPaymentSummaryDataSuccess = ({ data }: ActionParams) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.FETCH_PAYMENT_SUMMARY_DATA_SUCCESS,
        payload: {
          data,
        },
      })
    );
  };
};

export const fetchPaymentSummaryDataFail = ({ error }: FailActionParams) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.FETCH_PAYMENT_SUMMARY_DATA_FAIL,
        payload: error,
      })
    );
  };
};
