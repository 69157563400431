import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getFilledPayout } from 'api/payment';
import { addIsMounted, IsMounted } from 'decorators/addIsMounted';
import { AnyObject } from 'types/Common';
import Payout from './Payout';
import statuses from 'components/ui/button/statuses';
import { StoreProps } from 'store';

interface OwnProps {
  transactionId: string;
  projectId: string;
  customClass: string;
  buttonTheme?: keyof typeof statuses;
}

interface ConnectedProps {
  payout: AnyObject;
}

type Props = OwnProps & ConnectedProps & StoreProps & IsMounted;

interface State {
  isLoading: boolean;
}

class PayoutContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  render() {
    const { payout, customClass, buttonTheme } = this.props;
    const { isLoading } = this.state;

    if (!payout?.isEnabled) return null;

    return (
      <Payout
        isLoading={isLoading}
        onLoadData={() => this.getFilledPayout()}
        customClass={customClass}
        buttonTheme={buttonTheme}
      />
    );
  }

  async getFilledPayout(): Promise<any> {
    const { transactionId, projectId, isMount } = this.props;

    this.setState({ isLoading: true });

    try {
      await getFilledPayout({
        projectId,
        transactionId,
      });
    } finally {
      if (isMount()) {
        this.setState({ isLoading: false });
      }
    }
  }
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => ({
  payout: state.paymentActions[ownProps.transactionId]?.payout,
});

export default connect(mapStateToProps)(addIsMounted(PayoutContainer));
