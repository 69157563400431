import React, { PureComponent } from 'react';
import { cloneDeep, isEqual } from 'lodash';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import ButtonsGroup from 'components/ui/buttonsGroup';
import InputAmount from 'components/ui/inputAmount';

import Utils from 'helpers/Utils';
import MetricService from 'helpers/metricService/MetricService';

import DateFilter from 'components/dateFilter';
import FiltersButton from 'components/filters/FiltersButton';
import ConfigurationButton from 'components/configuration';
import FeatureConfigurationButton from 'components/configuration/FeatureConfigurationButton';
import SelectionList from 'components/ui/selectionList';
import { Item } from 'components/ui/selectionList/SelectionList';
import Input from 'components/ui/input';
import InputList from 'components/ui/inputList';
import ButtonDropDown from 'components/ui/buttonDropDown';
import CustomSelect from 'components/ui/customSelect';
import SelectItem from 'types/SelectItem';
import { AnyObject } from 'types/Common';
import { QuickFiltersTableName } from 'types/QuickFilters';
import { FilterTypes } from 'constants/FilterTypes';
import filtersKeys from 'constants/filters';
import pageFeatures from 'constants/pageFeatures';

import QuickFiltersProps from './QuickFiltersProps';
import './quickFilters.scss';

interface Props extends QuickFiltersProps<QuickFiltersTableName>, IntlProps {
  onResetFilters: () => void;
  filtersConfig: AnyObject[];
  isFilterButton: boolean;
  isConfigurationButton?: boolean;
  isFeatureConfigurationButton?: boolean;
  hasSelectedFilters: boolean;
  hasSelectedQuickFilters: boolean;
  disableFilters?: AnyObject;
}

interface State {
  selectionListItems?: Record<string, Item[]>;
  customSelectItems?: Record<string, SelectItem[]>;
}

class QuickFilters extends PureComponent<Props, State> {
  private hasColumns: boolean = false;

  constructor(props) {
    super(props);
    this.state = { ...this.setValues() };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState) {
    if (
      !isEqual(prevProps.filtersConfig, this.props.filtersConfig) ||
      !isEqual(prevProps.filters, this.props.filters)
    ) {
      this.setState(this.setValues());
    }
  }

  render() {
    const { name, filtersConfig, hasSelectedFilters } = this.props;

    return (
      <div className='quick-filters'>
        <div className='quick-filters__inner'>
          {filtersConfig &&
            !hasSelectedFilters &&
            filtersConfig.map((filter, i) => {
              this.hasColumns = false;
              if (Array.isArray(filter)) {
                this.hasColumns = true;
                return (
                  <div className='quick-filters__col' key={i}>
                    {filter.map((innerFilter) => {
                      return (
                        <div
                          className={`quick-filters__item quick-filters__item_${name}-${innerFilter.filterId} quick-filters__item_${innerFilter.type}`}
                          key={innerFilter.filterId}>
                          {this.renderFilter(innerFilter)}
                        </div>
                      );
                    })}
                    {i === filtersConfig.length - 1 && this.renderSettings()}
                  </div>
                );
              }

              return (
                <div
                  className={`quick-filters__item quick-filters__item_${name}-${filter.filterId} quick-filters__item_${filter.type}`}
                  key={filter.filterId}>
                  {this.renderFilter(filter)}
                </div>
              );
            })}
          {!this.hasColumns && this.renderSettings()}
        </div>
      </div>
    );
  }

  setValues = () => {
    const { filtersConfig } = this.props;
    if (!filtersConfig) return;
    return this.setStateValues(filtersConfig);
  };

  setStateValues = (filters) => {
    return filters.reduce((initialState, filter) => {
      if (Array.isArray(filter)) {
        return { ...initialState, ...this.setStateValues(filter) };
      }
      if (filter.type === FilterTypes.multiSelect) {
        return {
          ...initialState,
          selectionListItems: {
            ...initialState.selectionListItems,
            [filter.filterId]: cloneDeep(filter.list),
          },
        };
      } else if (filter.type === FilterTypes.select) {
        return {
          ...initialState,
          customSelectItems: {
            ...initialState.customSelectItems,
            [filter.filterId]: filter.list,
          },
        };
      }
      return initialState;
    }, {});
  };

  renderSettings = () => {
    const {
      isConfigurationButton = true,
      isFeatureConfigurationButton = false,
      isFilterButton,
      name,
      onResetFilters,
      filtersConfig,
      hasSelectedFilters,
      hasSelectedQuickFilters,
    } = this.props;
    return (
      <div className='quick-filters__item quick-filters__item_settings'>
        {filtersConfig && (
          <div className='quick-filters__filter-settings ui-buttons-group_single-choice'>
            <div className='ui-buttons-group__inner'>
              <Button
                status='danger-light'
                id='reload'
                icon='im-Reset-filter'
                onClick={() => {
                  onResetFilters && onResetFilters();
                }}
                disabled={!hasSelectedQuickFilters && !hasSelectedFilters}
                customClass='ui-button_no-border'
              />
              {isFilterButton && <FiltersButton name={name} />}
            </div>
          </div>
        )}

        {isConfigurationButton && <ConfigurationButton name={name} />}
        {isFeatureConfigurationButton && (
          <FeatureConfigurationButton name={pageFeatures[name]} />
        )}
      </div>
    );
  };

  renderFilter = (filter) => {
    const {
      filters,
      onChangeFilter,
      filtersConfig,
      disableFilters,
      getTranslate,
    } = this.props;
    const { filterId, metric } = filter;
    const isDisabled =
      disableFilters &&
      Utils.hasProp(disableFilters, filterId) &&
      disableFilters[filterId];
    switch (filter.type) {
      case FilterTypes.text: {
        return (
          <Input
            id={filters[filterId]}
            placeholder={getTranslate(filter.title)}
            customClass='ui-input_quick-filter'
            value={filters[filterId]}
            disabled={isDisabled}
            onChange={({ target }) => {
              metric && MetricService.sendInputField(metric);
              onChangeFilter(filterId, target.value);
            }}
          />
        );
      }
      case FilterTypes.numbers: {
        return (
          <Input
            id={filters[filterId]}
            placeholder={getTranslate(filter.title)}
            customClass='ui-input_quick-filter'
            value={filters[filterId]}
            disabled={isDisabled}
            onChange={({ target }) => {
              metric && MetricService.sendInputField(metric);
              onChangeFilter(filterId, target.value);
            }}
            cleaveOptions={{ numericOnly: true }}
          />
        );
      }
      case FilterTypes.amount: {
        return (
          <InputAmount
            id={filters[filterId]}
            placeholder={getTranslate(filter.title)}
            value={filters[filterId]}
            customClass='ui-input_quick-filter'
            disabled={isDisabled}
            onChange={({ target }) => {
              metric && MetricService.sendInputField(metric);
              onChangeFilter(
                filterId,
                Utils.getNumberWithoutSpace(target.value)
              );
            }}
          />
        );
      }
      case FilterTypes.buttonsList: {
        const isStringValue = filter.valueType === 'string';
        return (
          <ButtonsGroup
            activeButtons={
              isStringValue ? [filters[filterId]] : filters[filterId]
            }
            mode={filter.mode}
            disabled={isDisabled}
            onClick={(values) => {
              if (isStringValue) {
                onChangeFilter(filterId, values[0]);
              } else {
                onChangeFilter(filterId, values);
              }

              if (!metric) return;
              if (filter.mode === 'multi') {
                MetricService.sendButtonsGroup(
                  metric,
                  filters[filterId],
                  values
                );
              } else {
                MetricService.sendButtonsSingleChoice(metric, values);
              }
            }}
            className='ui-buttons-group_fixed-mobile'>
            {filter.list.map((button) => {
              if (button.sublist) {
                return (
                  <ButtonDropDown
                    key={button.id}
                    id={button.id}
                    text={button.text}
                    position='right'
                    customClass='ui-buttons-group__last-item button-drop-down_fixed-mobile'
                    items={button.sublist}
                    selected={filters[filterId]}
                    onChange={(value) => {
                      const selected = [...filters[filterId]];
                      value.forEach((val) => {
                        if (val.isSelected) {
                          selected.push(val.id);
                        } else {
                          selected.splice(selected.indexOf(val.id), 1);
                        }
                      });
                      metric &&
                        MetricService.sendButtonsGroup(
                          metric,
                          filters[filterId],
                          selected
                        );
                      onChangeFilter(filterId, selected);
                    }}
                  />
                );
              }
              return (
                <Button
                  id={button.id}
                  key={button.id}
                  text={getTranslate(button.text)}
                />
              );
            })}
          </ButtonsGroup>
        );
      }
      case FilterTypes.dateButtons: {
        return (
          <DateFilter
            filterKey={filterId}
            date={filters[filterId]}
            onChangeFilter={onChangeFilter}
            onApplyRange={(value) => this.applyRange(filterId, value)}
            metricName={filter.metricName}
            minDate={filter.minDate}
            disabled={isDisabled}
            customClass='date-filter_fixed-mobile'
          />
        );
      }
      case FilterTypes.dateSelect: {
        return (
          <DateFilter
            mode='select'
            filterKey={filterId}
            date={filters[filterId]}
            onChangeFilter={onChangeFilter}
            onApplyRange={(value) => this.applyRange(filterId, value)}
            metricName={filter.metricName}
            title={filter.title}
            withTime={filter.withTime}
            disabled={isDisabled}
            customClass='date-filter_fixed-mobile'
          />
        );
      }
      case FilterTypes.multiSelect: {
        return (
          <SelectionList
            theme='light'
            modern
            mode='noSetValue'
            placeholder={filter.title}
            items={this.state.selectionListItems?.[filterId] || []}
            onChange={(items) => {
              this.changeSelectionListFilter(filterId, items);
            }}
            onApply={(items) => {
              MetricService.send({
                action: 'click',
                actionKey: metric,
              });
              onChangeFilter(filterId, this.getSelectedIds(items));
            }}
            isValidBeforeApply={(items) =>
              this.props.onBeforeChange?.[filterId]?.(filterId, items)
            }
            isLoading={filtersConfig?.[filterId]?.isLoading}
            isDisabled={isDisabled}
            customClass='ui-selection-list_fixed-mobile'
            tooltip={filter.tooltip}
          />
        );
      }
      case FilterTypes.select: {
        return (
          <CustomSelect
            theme='light'
            modern
            customClass='quick-filters__select ui-select_fixed-mobile'
            placeholder={filter.title}
            value={this.state.customSelectItems?.[filterId].find(
              (item) => item.value === filters[filterId]
            )}
            options={this.state.customSelectItems?.[filterId] || []}
            onChange={(value) => {
              onChangeFilter(filterId, value?.value);

              MetricService.send({
                action: 'click',
                actionKey: metric,
              });
            }}
            isDisabled={isDisabled}
          />
        );
      }
      case FilterTypes.multiText: {
        let inputProps: AnyObject = {};
        if (filters[filtersKeys.riskContentType].includes('pan')) {
          inputProps = {
            cleaveOptions: { numericOnly: true },
            maxLength: 20,
          };
        }

        return (
          <InputList
            id={filterId}
            onChange={(items) => {
              metric && MetricService.sendInputField(metric);
              onChangeFilter(filterId, items);
            }}
            placeholder={getTranslate(filter.title)}
            items={filters[filterId]}
            separators={[',']}
            inline={true}
            width='250px'
            theme='light'
            disabled={isDisabled}
            {...inputProps}
          />
        );
      }
    }
  };

  applyRange = (filterId, { from, to }) => {
    const { onChangeFilter } = this.props;

    onChangeFilter(filterId, {
      type: 'custom',
      values: [from, to],
    });
  };

  getSelectedIds = (items) => {
    return items.map((item) => item.id);
  };

  changeSelectionListFilter = (filterId, value) => {
    if (
      this.props.onBeforeChange?.[filterId] &&
      this.props.onBeforeChange[filterId](filterId, value)
    ) {
      return;
    }

    this.setState((state) => ({
      selectionListItems: {
        ...state.selectionListItems,
        [filterId]: value,
      },
    }));
  };
}

export default addTranslation(QuickFilters);
