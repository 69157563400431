import request from 'helpers/request';
import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

export const uploadFinancialData = (data) => {
  return request({
    method: 'POST',
    url: 'app/financial-data/file-upload',
    headers: { 'Content-Type': 'multipart/form-data' },

    data: {
      file: data,
    },
    errorHandle: false,
  });
};

export const getFinancialDataList = (params) => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.FinancialData_FileList,
          payload: { ...params },
        },
      ],
      isCancelable: true,
    })
    .then((data) => {
      return { ...data, items: data.rows };
    });
};

export const deleteFinancialFile = (financialFileIds) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.FinancialData_FileDelete,
        payload: { financialFileIds },
      },
    ],
  });
};

export const downloadFinancialFile = (financialFileIds) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.FinancialData_FileDownloadRequest,
        payload: { financialFileIds },
      },
    ],
  });
};

export const downloadStatement = (payload: {
  reportPeriod: string[];
  balanceId: string;
  currency: string;
}) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.FinancialData_StatementView,
        payload,
      },
    ],
  });
};
