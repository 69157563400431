import RpcService from 'helpers/RpcService';
import Env from 'helpers/Env';
import RpcTypes from 'constants/rpcTypes';
import { normalizeElementsIds } from 'pages/admin/helpers';
import { AdminFilterPayload, FilterNames } from 'pages/admin/types';
import { AnyObject } from 'types/Common';

/**
 * Список таймзон
 */
const getTimezones = (): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Dictionary_Timezones,
      },
    ],
  });
};

/**
 * Список вылют
 */
const getCurrencies = (): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Dictionary_PayoutCurrencies,
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Список методов оплаты для формы создания единичной оплаты
 */
const getMethods = (projectId): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Dictionary_PayoutPaymentMethods,
        payload: { projectId },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Список правил дополнительных полей для формы создания единичной оплаты
 */
const getMethodsRules = (fields): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Dictionary_PayoutPaymentMethodRules,
        payload: { ...fields },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Список колонок для таблиц
 */
const getTableColumns = (forceDefault = false): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Dictionary_TableColumns,
        payload: { forceDefault },
      },
    ],
  });
};

/**
 * Список значений для заполнения (напр. фильтров)
 */
const getAvailableValues = (): Promise<any> => {
  let query: any;
  const dwhNMocked = Env.getDwhNMocked();
  if (Env.isDevelopment() && dwhNMocked) {
    query = {
      dwhmocked: dwhNMocked,
    };
  }
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Dictionary_AvailableValues,
      },
    ],
    query,
  });
};

export const loadDictionary = async (
  payload: AdminFilterPayload,
  isNormalise: boolean = true
): Promise<any> => {
  const result = await new RpcService().request({
    messages: [
      {
        type: RpcTypes.Dictionary_Load,
        payload,
      },
    ],
  });
  return isNormalise ? normalizeElementsIds(result, payload.name) : result;
};

export const loadAdminDictionaries = async (): Promise<AnyObject[]> => {
  return Promise.all(
    [FilterNames.project, FilterNames.merchant].map((name) =>
      loadDictionary({ name })
    )
  );
};

export const getDynamicDictionaries = (
  dictionaries:
    | string[]
    | { name: string; params?: AnyObject; offset?: number }[]
): Promise<any[]> => {
  const messages = dictionaries.map((dictionary) => {
    const payload =
      typeof dictionary === 'string' ? { name: dictionary } : dictionary;
    return {
      type: RpcTypes.Dictionary_Load,
      payload,
    };
  });

  return new RpcService()
    .request({
      messages,
      mapTo: 'name',
    })
    .then((data) => {
      if (!Array.isArray(data)) {
        return [data];
      }
      return data;
    })
    .catch((errorData) => {
      console.error('Dictionary load error', errorData);
      if (Array.isArray(errorData)) {
        return errorData;
      }
      return [errorData];
    });
};

export default {
  getTimezones,
  getCurrencies,
  getMethods,
  getMethodsRules,
  getTableColumns,
  getAvailableValues,
  getDynamicDictionaries,
};
