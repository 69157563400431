import BaseMessageHandler from '../BaseMessageHandler';
import { editOptions, openModal } from 'actions/modal';
import RpcService from 'helpers/RpcService';
import Repository from 'helpers/Repository';

export default class Confirm extends BaseMessageHandler {
  default = () => {
    const { dispatch, message } = this;
    const { payload } = message;
    const modal = Repository.get('store').getState().modal;

    const prevCallback = modal.callback;

    const callbackOnConfirm = (isAccept) => {
      const actionType = isAccept ? payload.accept : payload.reject;

      if (!actionType) return false;

      try {
        return new RpcService().request({
          messages: [
            {
              id: message.id,
              type: actionType,
            },
          ],
        });
      } catch (error) {
        console.error('Confirm callback error: ', error);
      } finally {
        dispatch(
          editOptions({
            confirm: undefined,
            callback: prevCallback,
            needCloseButton: true,
          })
        );
      }
    };

    if (modal.isOpened) {
      dispatch(
        editOptions({
          confirm: payload,
          callback: callbackOnConfirm,
          needCloseButton: false,
        })
      );
      return;
    }
    dispatch(
      openModal({
        modalId: 'Confirm',
        content: {
          ...payload,
        },
        callback: callbackOnConfirm,
      })
    );
  };
}
