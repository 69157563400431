import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

const wl = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.GET_WL,
      },
    ],
  });
};

export default wl;
