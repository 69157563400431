import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import ModalTitle from '../components/ModalTitle';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import Button from 'components/ui/button';

interface Props extends IntlProps {
  callback: (data: any) => void;
  content: {
    title?: string;
    text?: string;
    buttonYes?: string;
    buttonNo?: string;
  };
}

class Confirm extends PureComponent<Props> {
  render() {
    const {
      getTranslate,
      callback,
      content: { title, text, buttonYes, buttonNo },
    } = this.props;
    return (
      <>
        <ModalTitle customClass='modal__content_centered'>
          {title ? this.getText(title) : getTranslate('modals.common.title')}
        </ModalTitle>
        <ModalContent customClass='modal__content_centered modal__content_confirm'>
          {text && this.getText(text)}
        </ModalContent>
        <ModalFooter>
          <Button
            text={
              buttonNo
                ? this.getText(buttonNo)
                : getTranslate('common.no.button')
            }
            status='outline'
            size='normal'
            onClick={() => callback(false)}
          />
          <Button
            text={
              buttonYes
                ? this.getText(buttonYes)
                : getTranslate('common.yes.button')
            }
            status='primary'
            size='normal'
            onClick={() => callback(true)}
          />
        </ModalFooter>
      </>
    );
  }

  getText = (text) => {
    const { getTranslate } = this.props;
    return /\w\.\w/gi.test(text) ? getTranslate(text) : text;
  };
}

export default addTranslation(Confirm);
