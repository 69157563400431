import React, { Component } from 'react';
import { connect } from 'react-redux';

import checkIsMafUser from 'decorators/checkIsMafUser';
import { addListeners } from 'decorators/addListeners';
import api from 'api/secure';
import { closeModal, openModal } from 'actions/modal';
import { updateUser } from 'actions/user';
import ProfileSecure from './ProfileSecure';
import { AnyObject } from 'types/Common';
import Messages from 'constants/rpcTypes';
import { StoreProps } from 'store';

interface ConnectedProps {
  user: AnyObject;
}

interface OwnProps {
  customClass: string;
}

type Props = ConnectedProps & StoreProps & OwnProps;

interface State {
  validationErrors: AnyObject;
  loading: boolean;
  requestId: string;
}

@checkIsMafUser
@addListeners([Messages.GoogleAuth_EnableParams])
class ProfileSecureContainer extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { requestId: '', validationErrors: {}, loading: false };
  }

  render() {
    const { user, customClass } = this.props;
    const { validationErrors, loading } = this.state;
    return (
      <ProfileSecure
        user={user}
        loading={loading}
        validationErrors={validationErrors}
        handle2FAConfirmation={this.handle2FAConfirmation}
        customClass={customClass}
      />
    );
  }

  handle2FAConfirmation = async () => {
    this.setState({ loading: true });
    try {
      await api.enable2FAParams();
    } finally {
      this.setState({ loading: false });
    }
  };

  onEvent = ({ data }) => {
    const { payload, rpc } = data;
    const { dispatch } = this.props;
    const { requestId } = this.state;

    this.setState({ validationErrors: {} });

    if (payload.validationErrors) {
      this.setState({ validationErrors: payload.validationErrors });
      return;
    }
    if (requestId === rpc.id) {
      return;
    }

    this.setState({ requestId: rpc.id });

    dispatch(
      openModal({
        modalId: 'Confirm2FA',
        content: {
          requestId: rpc.id,
          isProfile: true,
          authToken: data.authToken,
          ...payload,
        },
        callback: (twoFactorAuth = false) => {
          dispatch(updateUser({ twoFactorAuth }));
          dispatch(closeModal());
        },
      })
    );
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
});

export default connect(mapStateToProps)(ProfileSecureContainer);
