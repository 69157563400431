import React, { PureComponent } from 'react';
import { invert } from 'lodash';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import PageTemplate from 'components/pageTemplate/PageTemplate';
import SwitcherTabs from 'components/ui/switcherTabs';
import Balance from './balance';
import OnlineFinance from './online';
import StatementFinance from './statement';
import CurrencyRates from './currencyRates';
import FinancialReports from './finacialReports';
import StatStatement from './statStatement';

import views from './viewsList';
import SwitcherTabsItem from 'components/ui/switcherTabs/types';
import './finance.scss';
import './financialTab.scss';

interface OwmProps {
  tabs: SwitcherTabsItem[];
  currentView: string;
  onChangeView: (view: string) => void;
}
type Props = OwmProps & IntlProps;
const viewsSections = invert(views);

class Finance extends PureComponent<Props> {
  render() {
    const { currentView, onChangeView, tabs, getTranslate } = this.props;

    return (
      <PageTemplate.Main customClass='page-finance'>
        <PageTemplate.Entities>
          <SwitcherTabs
            activeTab={currentView}
            sectionName='finance'
            tabs={tabs}
            onClick={onChangeView}
          />
        </PageTemplate.Entities>

        <PageTemplate.Container>
          <div className='page-finance__content'>{this.renderContent()}</div>

          {currentView !== viewsSections[views.finReports] &&
            currentView !== viewsSections[views.currencyRates] &&
            currentView !== viewsSections[views.statStatement] && (
              <div className='page-finance__footer'>
                {getTranslate('onlinebalance.currentBalanceList.hint')}
              </div>
            )}
        </PageTemplate.Container>
      </PageTemplate.Main>
    );
  }

  renderContent() {
    const { currentView } = this.props;

    switch (currentView) {
      case viewsSections[views.balance]:
        return <Balance />;
      case viewsSections[views.statement]:
        return <StatementFinance />;
      case viewsSections[views.online]:
        return <OnlineFinance />;
      case viewsSections[views.finReports]:
        return <FinancialReports />;
      case viewsSections[views.currencyRates]:
        return <CurrencyRates />;
      case viewsSections[views.statStatement]:
        return <StatStatement />;
      default:
        return null;
    }
  }
}

export default addTranslation(Finance);
