import React from 'react';
import { connect } from 'react-redux';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import userApi from 'api/user';
import secureApi from 'api/secure';
import { addListeners } from 'decorators/addListeners';
import { withAuthPing } from 'decorators/withAuthPing';
import { StoreProps } from 'store';

import showNotification from 'components/ui/notification/showNotification';
import Confirm2FA from './Confirm2FA';

import Messages from 'constants/rpcTypes';
import { confirmationTypes } from './types';
import { AnyObject } from 'types/Common';
import './confirm2FA.scss';

interface OwnProps {
  callback: (twoFactorEnabled: boolean) => void;
  canResend: boolean;
  modal: AnyObject;
  authToken: string;
}

interface ConnectedProps {
  content: {
    authToken: string;
    type: confirmationTypes;
    requestId: string;
    qrImageBase64: string;
    isProfile: boolean;
    verification: {
      codeResendSinceTimeLeft: number;
      contactPhoneNumber: string;
      contactPhoneCountry: string;
    };
  };
  app: AnyObject;
  user: AnyObject;
}

type Props = OwnProps & ConnectedProps & IntlProps & StoreProps;

interface State {
  code: string;
  validationErrors: AnyObject;
  codeResendSinceTimeLeft: number;
  canResend: boolean;
  isLoading: boolean;
  requestId: string;
}

@withAuthPing
@addListeners([Messages.Auth_ConfirmSecondFactorStateRequest])
class Confirm2FAContainer extends React.PureComponent<Props, State> {
  private timeoutTimerId;
  private countDown;

  constructor(props: Props) {
    super(props);

    this.state = {
      code: '',
      isLoading: false,
      requestId: props.content.requestId,
      ...this.initialState,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { content } = this.props;
    if (prevProps.content.requestId !== content.requestId) {
      this.resetTimer(
        content.requestId,
        content.verification.codeResendSinceTimeLeft
      );
    }
  }

  componentDidMount() {
    this.setState({ ...this.initialState }, () => {
      this.startTimer();
    });
  }

  render() {
    const { content } = this.props;
    const { canResend, code, isLoading, validationErrors } = this.state;

    return (
      <Confirm2FA
        countDown={this.countDown}
        sendCode={this.sendCode}
        onComplete={this.onComplete}
        onChangeCode={this.onChangeCode}
        onPaste={this.onPaste}
        content={content}
        canResend={canResend}
        code={code}
        isLoading={isLoading}
        validationErrors={validationErrors}
      />
    );
  }

  componentWillUnmount() {
    this.destroy();
  }

  get initialState() {
    const { content } = this.props;
    const codeResendSinceTimeLeft =
      content.type === confirmationTypes.SMSCode
        ? content.verification.codeResendSinceTimeLeft
        : 0;
    return {
      canResend: !!codeResendSinceTimeLeft,
      validationErrors: {},
      codeResendSinceTimeLeft,
    };
  }

  onComplete = () => {
    this.setState({ canResend: true });
  };

  sendCode = async (regenerate = false) => {
    //regenerate = resend code param
    const { code } = this.state;
    const { authToken } = this.props;
    const {
      content: { isProfile },
      callback,
      getTranslate,
    } = this.props;

    if (code.length !== 6 && !regenerate) {
      return;
    }

    this.setState({ isLoading: true, validationErrors: {} });

    try {
      if (!isProfile) {
        return await userApi.confirm2FA({
          authToken,
          verificationCode: Number(code),
          regenerate,
        });
      }
      if (regenerate) {
        await secureApi.enable2FAParams();
      } else {
        await secureApi.enableTwoFactor({
          verificationCode: Number(code),
        });
        showNotification({
          status: 'success',
          content: getTranslate('profile.twoFA.enableSuccess.popup'),
        });
        this.destroy();
        return callback(true);
      }
    } catch ({ payload }) {
      this.setState({ validationErrors: payload?.validationErrors || {} });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onChangeCode = ({ target }) => {
    const { code } = this.state;
    const value = target.rawValue;
    if (value === code) return;
    this.setState({ code: value, validationErrors: {} }, this.sendCode);
  };

  onPaste = (e) => {
    const value = e.clipboardData.getData('Text');
    this.setState({ code: value }, this.sendCode);
  };

  startTimer = () => {
    const { codeResendSinceTimeLeft } = this.state;
    const codeResendSinceTimeLeftMilliSeconds = codeResendSinceTimeLeft * 1000;
    this.setState({ canResend: false });
    this.countDown = Date.now() + codeResendSinceTimeLeftMilliSeconds;
    this.timeoutTimerId = setTimeout(() => {
      this.setState({ canResend: true });
    }, codeResendSinceTimeLeftMilliSeconds);
  };

  resetTimer = (requestId, codeResendSinceTimeLeft) => {
    this.setState(
      {
        requestId,
        codeResendSinceTimeLeft,
      },
      () => this.startTimer()
    );
  };
  destroy = () => {
    clearTimeout(this.timeoutTimerId);
  };

  onEvent = (response) => {
    const { data, name } = response;
    const { payload } = data;
    const { requestId } = this.state;

    if (name === Messages.Auth_Ping) {
      return;
    }

    const { id } = data.rpc;

    if (requestId === id) {
      return;
    }
    this.resetTimer(id, payload.verification.codeResendSinceTimeLeft);
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  content: state.modal.content,
  app: state.app,
  user: state.user,
});

export default connect(mapStateToProps)(addTranslation(Confirm2FAContainer));
