import filtersKeys from 'constants/filters';
import { FilterTypes } from 'constants/FilterTypes';
import modes from 'components/ui/buttonsGroup/modes';
import { statuses } from 'pages/chargebacks/statuses';

export default [
  {
    filterId: filtersKeys.chargebacksArn,
    type: FilterTypes.text,
    metric: 'chargebacks.fastFilter.arn.enter',
    title: 'charges.quickFilters.ARN',
  },
  {
    filterId: filtersKeys.chargebacksId,
    type: FilterTypes.text,
    metric: 'chargebacks.fastFilter.chargebackId.enter',
    title: 'charges.quickFilters.chargebackId',
  },
  {
    filterId: filtersKeys.caseId,
    type: FilterTypes.text,
    metric: 'chargebacks.fastFilter.caseId.enter',
    title: 'charges.quickFilters.caseId',
  },
  {
    filterId: filtersKeys.chargebacksStatuses,
    type: FilterTypes.buttonsList,
    mode: modes.multi,
    metric: 'chargebacks.fastFilter.status',
    list: statuses,
  },
  {
    filterId: filtersKeys.projectId,
    type: FilterTypes.multiSelect,
    metric: 'chargebacks.fastFilter.projectId.apply',
    title: 'charges.quickFilters.projectId',
    dictionary: 'enabledProject',
  },
  {
    filterId: filtersKeys.chargebacksDate,
    type: FilterTypes.dateButtons,
    metricName: 'chargebacks',
    minDate: '01.01.2020',
  },
];
