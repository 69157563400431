const currency = {
  EUR: '0.00',
  GBP: '0.00',
  PLN: '0.00',
  RUB: '0.00',
  USD: '0.00',
  UAH: '0.00',
  IDR: '0.00',
};

export const initialCalcs = {
  ...currency,
};

export const targetWiresInitial = {
  bank_name: 'TARGETWIRES HK LIMITED',
  safeguarded: false,
  bank: false,
  service_provider: true,
  ...currency,
};

export const initialState = {
  dataSection: [
    {
      bank_name: 'RIETUMU BANKA AS',
      safeguarded: true,
      bank: true,
      service_provider: false,
      ...currency,
    },
    {
      bank_name: 'ALPHA BANK CYPRUS LIMITED',
      safeguarded: true,
      bank: true,
      service_provider: false,
      ...currency,
    },
    {
      bank_name: 'HERMES BANK LIMITED (1)',
      safeguarded: true,
      bank: true,
      service_provider: false,
      ...currency,
    },
    {
      bank_name: 'HERMES BANK LIMITED (2)',
      safeguarded: true,
      bank: false,
      service_provider: true,
      ...currency,
    },
    {
      bank_name: 'PAYSAFE FINANCIAL SERVICES LIMITED',
      safeguarded: false,
      bank: false,
      service_provider: true,
      ...currency,
    },
    {
      bank_name: 'PT. NUSA SATU INTI ARTHA',
      safeguarded: false,
      bank: false,
      service_provider: true,
      ...currency,
    },
    {
      bank_name: 'SKRILL LIMITED',
      safeguarded: false,
      bank: false,
      service_provider: true,
      ...currency,
    },
    {
      bank_name: 'TRUSTLY GROUP AB',
      safeguarded: false,
      bank: false,
      service_provider: true,
      ...currency,
    },
    targetWiresInitial,
  ],
  dataSectionRate: {
    EUR: '1',
    GBP: '1',
    PLN: '1',
    RUB: '1',
    USD: '1',
    UAH: '1',
    IDR: '1',
  },

  relevantFunds: [
    {
      bank_name: 'ICPP BALANCE',
      ...currency,
    },
    {
      bank_name: 'ICPP HOLDBACK',
      ...currency,
    },
    {
      bank_name: 'ACQ+PSP BALANCE',
      ...currency,
    },
    {
      bank_name: 'ACQ+PSP HOLDBACK',
      ...currency,
    },
    {
      bank_name: 'DKU',
      ...currency,
    },
    {
      bank_name: 'SKR',
      ...currency,
    },
    {
      bank_name: 'DACH',
      ...currency,
    },
    {
      bank_name: 'PO-W',
      ...currency,
    },
    {
      bank_name: 'PPR',
      ...currency,
    },
    {
      bank_name: 'OCT',
      ...currency,
    },
    {
      bank_name: 'TRL',
      ...currency,
    },
    {
      bank_name: 'PO',
      ...currency,
    },
    {
      bank_name: 'PF-PAY-IN',
      ...currency,
    },
    {
      bank_name: 'SUB QIWI',
      ...currency,
    },
    {
      bank_name: 'NTL',
      ...currency,
    },
    {
      bank_name: 'NTL HOLDBACK',
      ...currency,
    },
    {
      bank_name: 'ISS',
      ...currency,
    },
    {
      bank_name: 'P2P',
      ...currency,
    },
  ],
  relevantFundsRate: {
    EUR: '1',
    GBP: '1',
    PLN: '1',
    RUB: '1',
    USD: '1',
    UAH: '1',
    IDR: '1',
  },
  deposit: {
    visaDeposit: { ...initialCalcs },
    mcDeposit: { ...initialCalcs },
  },
};
