import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import SelectionList from 'components/ui/selectionList';
import ButtonsGroup from 'components/ui/buttonsGroup';
import Button from 'components/ui/button';
import MetricService from 'helpers/metricService/MetricService';
import { AnyObject } from 'types/Common';
import getSelectionListItems from 'creators/getSelectionListItems';
import filtersKeys from 'constants/filters';
import { DEFAULT_BALANCE_TYPES } from '../../../constants';
import './onlineFilters.scss';
import CustomSelect from 'components/ui/customSelect';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import modes from 'components/ui/buttonsGroup/modes';

interface Props extends IntlProps {
  filters: AnyObject;
  balanceType: AnyObject;
  balanceCompany: AnyObject;
  isLoading: boolean;
  hasSelectedFilters: boolean;
  onChangeFilter: (data: { key: string; value: any }) => void;
  onResetFilter: () => void;
}

interface State {
  filter: AnyObject;
}

class OnlineFilters extends PureComponent<Props, State> {
  render() {
    return (
      <div className='online-filters'>
        {this.renderBalanceType()}
        {this.renderClearButton()}
      </div>
    );
  }

  renderBalanceType = () => {
    const { filters, balanceType, onChangeFilter } = this.props;
    const balanceTypeList = balanceType?.list || DEFAULT_BALANCE_TYPES;

    return (
      <div className='online-filters__item'>
        <div className='quick-filters__block-content'>
          <ButtonsGroup
            activeButtons={filters[filtersKeys.balanceRealTimeBalanceType]}
            mode={modes.multi}
            isLoading={balanceType?.isLoading}
            disabled={!balanceType?.isFetched || balanceType?.error}
            onClick={(values) =>
              onChangeFilter({
                key: filtersKeys.balanceRealTimeBalanceType,
                value: values,
              })
            }>
            {balanceTypeList?.map((item) => (
              <Button
                key={item.id}
                status='outline'
                id={item.id}
                text={item.text}
              />
            ))}
          </ButtonsGroup>
        </div>
      </div>
    );
  };

  renderClearButton() {
    const { isLoading, hasSelectedFilters } = this.props;

    return (
      <div className='top-panel__item online-filters__item online-filters__item_reset'>
        <Button
          status='danger-light'
          icon='im-Reset-filter'
          disabled={isLoading || !hasSelectedFilters}
          onClick={this.onResetFilter}
          customClass='ui-button_no-border'
        />
      </div>
    );
  }

  onChangeSelect(data: {
    key: string;
    itemsResult: Array<any>;
    isSingleMode?: boolean;
    isLocal?: boolean;
  }): void {
    const { key, itemsResult, isSingleMode, isLocal } = data;
    const { onChangeFilter } = this.props;
    const values = itemsResult
      .filter((item) => item.isSelected)
      .map((item) => item.id);
    const value = isSingleMode ? values[0] : values;

    if (isLocal) {
      const { filter } = this.state;

      this.setState({
        filter: {
          ...filter,
          [key]: value,
        },
      });
    } else {
      onChangeFilter({
        key,
        value,
      });
    }
  }

  onApplySelect = (data: { key: string; values: any }): void => {
    const { key, values } = data;
    const { onChangeFilter } = this.props;

    const value = values.map((item) => item.id);

    onChangeFilter({
      key,
      value,
    });
  };

  onResetFilter = (): void => {
    const { onResetFilter } = this.props;

    onResetFilter();
  };
}

export default addTranslation(OnlineFilters);
