import BaseMessageHandler from '../BaseMessageHandler';
import { closeModal, openModal } from 'actions/modal';

export default class Confirm_Psd2 extends BaseMessageHandler {
  request = () => {
    const { dispatch, message, response } = this;
    const { payload } = message;

    dispatch(
      openModal({
        modalId: 'CodeVerification',
        content: {
          authToken: response.data.authToken,
          ...payload,
          rpc: {
            id: message.id,
          },
        },
      })
    );
  };

  success = () => {
    const { dispatch } = this;
    dispatch(closeModal());
  };
}
