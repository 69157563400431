import React from 'react';

export const wrapAppWithCssClass =
  (classNames: string[] | string) => (WrapperComponent) => {
    class WrapperDecorator extends React.Component<any> {
      componentDidMount(): void {
        if (Array.isArray(classNames)) {
          classNames.forEach(this.appendClassName);
        } else {
          this.appendClassName(classNames);
        }
      }

      componentWillUnmount(): void {
        if (Array.isArray(classNames)) {
          classNames.forEach(this.removeClassName);
        } else {
          this.removeClassName(classNames);
        }
      }

      appendClassName(className: string) {
        document.body.querySelector('.layout-app')?.classList.add(className);
      }

      removeClassName(className: string) {
        document.body.querySelector('.layout-app')?.classList.remove(className);
      }

      render() {
        return <WrapperComponent {...this.props} />;
      }
    }

    return WrapperDecorator as typeof WrapperComponent;
  };
