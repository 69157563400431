import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import CleaveInput from 'cleave.js/react';
import classNames from 'classnames';
import InputProps from './InputProps';
import InfoIcon from 'components/ui/infoIcon';
import './input.scss';
import './inputModern.scss';
import './inputLight.scss';
import Icon from 'components/ui/icon';

class InputNative extends PureComponent {
  render() {
    return <input {...this.props} />;
  }
}

class Textarea extends PureComponent {
  render() {
    return <textarea {...this.props} />;
  }
}

class Input extends PureComponent<InputProps & IntlProps> {
  static defaultProps = {
    type: 'text',
    autoComplete: 'off',
    resize: true,
  } as const;

  render() {
    const {
      id,
      value,
      placeholder,
      disabled,
      maxLength,
      label,
      error,
      type,
      inputContainerRef,
      cleaveOptions,
      customClass,
      isRequired,
      prefix,
      suffix,
      onKeyDown,
      onBlur,
      onFocus,
      onPaste,
      isRow,
      size,
      autoComplete,
      tooltip,
      autoFocus,
      modern,
      theme,
      resize,
      clearButton,
      getTranslate,
    } = this.props;

    const classes = classNames({
      'ui-input': true,
      'ui-input_row': isRow,
      'ui-input_error': !!error,
      'ui-input_disabled': disabled,
      'ui-input_modern': modern,
      'ui-input_light': theme === 'light',
      'ui-input_modern-tooltip': modern && tooltip,
      'ui-input_modern-buttons': modern && tooltip && clearButton,
      'ui-input_textarea-no-resize': type === 'textarea' && !resize,
      'ui-input_prefix': !!prefix,
      [`ui-input_${size}`]: !!size,
      [`${customClass}`]: !!customClass,
    });

    let InputComponent = InputNative;
    const additionalParams: any = {};

    if (type === 'textarea') {
      InputComponent = Textarea;
    } else if (cleaveOptions) {
      InputComponent = CleaveInput;

      additionalParams.options = cleaveOptions;
    }

    return (
      <div ref={inputContainerRef} className={classes}>
        <div
          className={classNames('ui-input__wrapper', {
            'ui-input__wrapper_modern': modern,
          })}>
          {label && (
            <label htmlFor={id} className='ui-input__label'>
              {getTranslate(label)}
              {isRequired && (
                <span
                  className='ui-input__required'
                  data-tip={getTranslate('common.requiredField.tooltip')}>
                  *
                </span>
              )}
              {tooltip && !modern && (
                <InfoIcon tooltip={tooltip} customClass='ui-input__info' />
              )}
            </label>
          )}
          <div className='ui-input__inner'>
            {prefix && <span className='ui-input__prefix'>{prefix}</span>}
            <InputComponent
              id={id}
              value={value}
              placeholder={getTranslate(placeholder)}
              type={type}
              disabled={disabled}
              maxLength={maxLength}
              onChange={this.onChange}
              onPaste={onPaste}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              autoComplete={autoComplete}
              autoFocus={autoFocus}
              className={classNames('ui-input__field', {
                'ui-input__field_textarea': type === 'textarea',
              })}
              {...additionalParams}
            />

            {suffix && <span className='ui-input__suffix'>{suffix}</span>}
            {error && isRow && (
              <div
                className={classNames('ui-input__error', {
                  'ui-input__error_modern': modern,
                })}>
                {getTranslate(error)}
              </div>
            )}
          </div>
          {this.renderIcons()}
        </div>
        {error && !isRow && (
          <div
            className={classNames('ui-input__error', {
              'ui-input__error_modern': modern,
            })}>
            {getTranslate(error)}
          </div>
        )}
      </div>
    );
  }

  renderIcons = () => {
    const { clearButton, tooltip } = this.props;
    return (
      this.props.modern &&
      (clearButton || tooltip) && (
        <div className='ui-input__icons'>
          {clearButton && this.props.value && (
            <Icon
              name='im-Close'
              size={11}
              onClick={this.clearValue}
              className='ui-input__clear'
            />
          )}
          {tooltip && (
            <InfoIcon
              icon='im-Info'
              size={16}
              tooltip={tooltip}
              customClass='ui-input__info'
              dataPlace='top'
              tooltipId={this.props.tooltipId}
            />
          )}
        </div>
      )
    );
  };

  onChange = (event, params) => {
    const { onChange } = this.props;
    onChange(event, params);
  };

  clearValue = (event) => {
    event.target.value = '';
    this.props.onChange(event);
  };
}

export default addTranslation(Input);
