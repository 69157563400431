import React, { PureComponent } from 'react';
import Form from 'components/ui/form';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import Loader from 'components/ui/loader';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import './twoFactorAuth.scss';

import appstoreImage from 'images/social/appstore.svg';
import googleplayImage from 'images/social/googleplay.svg';

interface Props extends IntlProps {
  user: any;
  qrCode: string;
  qrCodeError: string;
  isLoading: boolean;
  onEnable: (code: string) => void;
  onDisable: (code: string) => void;
}

interface State {
  verificationCode: string;
}

const CODE_LENGTH = 6;

class TwoFactorAuth extends PureComponent<Props, State> {
  private readonly refInputCode;
  private readonly userTwoFactorAuth;

  constructor(props) {
    super(props);

    this.state = {
      verificationCode: '',
    };

    this.userTwoFactorAuth = props.user.twoFactorAuth;
    this.refInputCode = React.createRef();
  }

  componentDidMount() {
    this.focusInput();
  }

  render() {
    const { isLoading } = this.props;

    return (
      <div className='profile-two-factor-auth'>
        {this.userTwoFactorAuth
          ? this.renderDisableForm()
          : this.renderEnableForm()}
        {isLoading && <Loader />}
      </div>
    );
  }

  renderEnableForm() {
    const { qrCode, qrCodeError, getTranslate } = this.props;
    const { verificationCode } = this.state;

    return (
      <Form onSubmit={() => this.sendForm('enable')}>
        <div className='profile-two-factor-auth__group'>
          <div className='profile-two-factor-auth__row profile-two-factor-auth__row_apps'>
            <span className='profile-two-factor-auth__number'>1</span>
            {getTranslate('profile.twoFA.instruction1')}
            <div className='profile-two-factor-auth__apps'>
              <a
                className='profile-two-factor-auth__app'
                href='https://itunes.apple.com/app/id388497605'
                rel='noopener noreferrer'
                target='_blank'>
                <img src={appstoreImage} alt='appstore' />
              </a>
              <a
                className='profile-two-factor-auth__app'
                href='http://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
                rel='noopener noreferrer'
                target='_blank'>
                <img src={googleplayImage} alt='googleplay' />
              </a>
            </div>
          </div>
          <div className='profile-two-factor-auth__row'>
            <span className='profile-two-factor-auth__number'>2</span>
            {getTranslate('profile.twoFA.instruction2')}
          </div>
          <div className='profile-two-factor-auth__row'>
            <span className='profile-two-factor-auth__number'>3</span>
            {getTranslate('profile.twoFA.instruction3')}
          </div>
          {qrCode && (
            <img
              className='profile-two-factor-auth__image'
              src={`data:image/gif;base64,${qrCode}`}
              alt='auth-qr-code'
            />
          )}
          {qrCodeError && (
            <div className='profile-two-factor-auth__error'>
              {getTranslate(qrCodeError)}
            </div>
          )}
        </div>
        <div className='profile-two-factor-auth__group'>
          <div className='profile-two-factor-auth__row'>
            <span className='profile-two-factor-auth__number'>4</span>
            {getTranslate('profile.twoFA.verificationCode')}
            <Input
              id='verification-code'
              value={verificationCode}
              disabled={Boolean(qrCodeError)}
              placeholder='123 456'
              cleaveOptions={{
                numericOnly: true,
                blocks: [3, 3],
                delimiters: [' '],
              }}
              customClass='profile-two-factor-auth__input'
              inputContainerRef={this.refInputCode}
              onChange={({ target }) =>
                this.changeVerificationCode(target.value)
              }
              modern
            />
          </div>
        </div>
        <div className='profile-two-factor-auth__footer'>
          <Button
            text={getTranslate('common.confirm.button')}
            type='button'
            status='primary'
            customClass='ui-button_full-width'
            onClick={() => this.sendForm('enable')}
            disabled={this.getCodeWithoutSpace().length !== CODE_LENGTH}
          />
        </div>
      </Form>
    );
  }

  renderDisableForm() {
    const { verificationCode } = this.state;
    const { getTranslate } = this.props;

    return (
      <Form onSubmit={() => this.sendForm('disable')}>
        <div className='profile-two-factor-auth__group'>
          <Input
            id='verification-code'
            label={getTranslate('auth.twoFA.text')}
            value={verificationCode}
            placeholder='123 456'
            cleaveOptions={{
              numericOnly: true,
              blocks: [3, 3],
              delimiters: [' '],
            }}
            inputContainerRef={this.refInputCode}
            onChange={({ target }) => this.changeVerificationCode(target.value)}
            modern
          />
        </div>
        <div className='profile-two-factor-auth__footer'>
          <Button
            text={getTranslate('common.confirm.button')}
            type='button'
            status='primary'
            customClass='ui-button_full-width'
            onClick={() => this.sendForm('disable')}
            disabled={this.getCodeWithoutSpace().length !== CODE_LENGTH}
          />
        </div>
      </Form>
    );
  }

  focusInput = () => {
    const { current: node } = this.refInputCode;
    if (node) {
      node.querySelector('.ui-input__field').focus();
    }
  };

  sendForm = (actionName: 'enable' | 'disable') => {
    const { onEnable, onDisable } = this.props;
    const code = this.getCodeWithoutSpace();
    if (this.canSendForm()) {
      actionName === 'enable' ? onEnable(code) : onDisable(code);
    }
  };

  canSendForm = () => {
    const { isLoading } = this.props;
    return !isLoading && this.getCodeWithoutSpace().length === CODE_LENGTH;
  };

  getCodeWithoutSpace = () => {
    return this.state.verificationCode.replace(/\s/g, '');
  };

  changeVerificationCode = (verificationCode: string): void => {
    this.setState({ verificationCode });
  };
}

export default addTranslation(TwoFactorAuth);
