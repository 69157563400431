import BaseMessageHandler from '../BaseMessageHandler';
import path from 'helpers/path';
import { getPathUrl } from 'helpers/paymentsPayoutsRequest';
import tableNames from 'constants/tableNames';

export default class RedirectPayoutCard extends BaseMessageHandler {
  request = () => {
    const { history, message } = this;
    const { payload } = message;

    const pathUrl = getPathUrl(tableNames.payouts, payload);
    if (pathUrl) {
      history.push(path(pathUrl));
    }
  };
}
