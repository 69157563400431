import React from 'react';
import { connect } from 'react-redux';
import { StoreProps } from 'store';
import { changeDataSorting } from 'actions/getData';
import Icon from 'components/ui/icon';
import { asc, desc, SortingType } from '../../../../types/sortings';
import classNames from 'classnames';

interface OwnProps {
  sort: {
    field: string;
    order: SortingType;
  };
  onSort?: ({ field, order }: { field: string; order: SortingType }) => void;
  tableName: string;
  id: string;
  children: any;
}

type Props = OwnProps & StoreProps;

class SortingCell extends React.Component<Props> {
  render() {
    const { id, sort } = this.props;
    return (
      <span
        className={`ui-table__header-item_${id} ui-table__header-item_sort`}
        onClick={() => this.changeSorting(id)}>
        {this.props.children}
        <Icon
          name='im-Sort'
          size={8}
          className={classNames('ui-table__sort-icon', {
            'ui-table__sort-icon_reverse':
              sort && id === sort.field && sort.order === asc,
            'ui-table__sort-icon_active': sort && id === sort.field,
          })}
        />
      </span>
    );
  }

  changeSorting = (field) => {
    const { dispatch, sort, onSort, tableName } = this.props;

    if (!sort) {
      console.warn('sort is not available!');
      return;
    }

    const newSort: { field: string, order: SortingType } = {
      field,
      order: desc,
    };

    if (sort.field === field) {
      newSort.order = sort.order === asc ? desc : asc;
    }

    if (onSort) {
      onSort(newSort);
    }

    dispatch(changeDataSorting(tableName, newSort));
  };
}

export default connect()(SortingCell);
