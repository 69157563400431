import React, { PureComponent, ReactNode } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Select from 'react-select';

import InfoIcon from 'components/ui/infoIcon';
import SelectItem from 'types/SelectItem';
import {
  ControlComponent,
  DropdownIndicatorComponent,
  LoadingIndicatorComponent,
  OptionComponent,
  MultiValueLabelComponent,
  MultiValueRemoveComponent,
  MenuListComponent,
} from 'components/ui/customSelect/selectInnerComponents';
import Utils from 'helpers/Utils';
import {
  isRightPositionByWindow,
  isTopPositionByBlock,
} from 'helpers/setCorrectPosition';
import './customSelect.scss';
import './customSelectModern.scss';
import './customSelectLight.scss';

export interface Value {
  value: string | number;
  label: string | number | ReactNode;
  isFavorite?: boolean;
}

interface Props extends IntlProps {
  id?: string;
  value?: any; //Value | Value[] | ReactNode | null;
  options: SelectItem[];
  customClass?: string;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  onChange?: (event: any) => void;
  onInputChange?: (inputValue: string) => void;
  label?: string;
  isRequired?: boolean;
  placeholder?: string;
  error?: string;
  tooltip?: string;
  isRow?: boolean;
  noOptionsMessage?: string;
  deselect?: boolean;
  menuIsOpen?: boolean;
  autoScrollToSelectedOption?: boolean;
  isLoadMore?: boolean;
  defaultMenuIsOpen?: boolean;
  backspaceRemovesValue?: boolean;
  modern?: boolean;
  theme?: 'light';
  loadMore?: () => void;
  containerSelector?: string;
  onBlur?: () => void;
  tooltipId?: string;
  tooltipPosition?: string;
  onOpen?: () => void;
  filterOption?: (option: Value, value: string) => boolean;
  menuStyle?: () => void;
}

interface State {
  isOpened: boolean;
  isTopPosition: boolean;
}

class CustomSelect extends PureComponent<Props, State> {
  private selectRef;
  static defaultProps = {
    id: Utils.getHash(),
    isSearchable: false,
    isDisabled: false,
    deselect: false,
    autoScrollToSelectedOption: false,
  };
  state = {
    isOpened: false,
    isTopPosition: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isOpened && this.state.isOpened) {
      this.setState({
        isTopPosition: isTopPositionByBlock(
          this.selectRef,
          this.props.containerSelector,
          '.ui-select__menu'
        ),
      });
    }
  }

  render() {
    const {
      options,
      customClass,
      isSearchable,
      isDisabled,
      isMulti,
      isLoading,
      value,
      onChange,
      label,
      isRequired,
      id,
      placeholder,
      error,
      tooltip,
      isRow,
      getTranslate,
      noOptionsMessage,
      deselect,
      modern,
      theme,
      onInputChange,
      onBlur,
      menuStyle = () => null,
      ...rest
    } = this.props;
    const { isOpened, isTopPosition } = this.state;

    const customStyles = {
      option: () => null,
      control: () => null,
      dropdownIndicator: () => null,
      indicatorSeparator: () => null,
      menuList: () => null,
      menu: menuStyle,
      singleValue: () => null,
      input: () => null,
      placeholder: () => null,
      multiValue: () => null,
      multiValueLabel: () => null,
      multiValueRemove: () => null,
      valueContainer: () => null,
      loadingIndicator: () => null,
    };

    const classes = classNames('ui-select', {
      'ui-select_opened': isOpened,
      'ui-select_selected': value?.value,
      'ui-select_disabled': isDisabled,
      'ui-select_loading': isLoading,
      [`${customClass}`]: !!customClass,
      [`ui-select_${theme}`]: !!theme,
      'ui-select_error': !!error,
      'ui-select_row': !!isRow,
      'ui-select_modern': !!modern,
      'ui-select_right': isRightPositionByWindow(this.selectRef),
      'ui-select_top': isTopPosition,
    });

    return (
      <div
        id={id}
        className={classes}
        ref={(el) => {
          this.selectRef = el;
        }}>
        <div
          className={classNames('ui-select__wrapper', {
            'ui-select__wrapper_modern': modern,
          })}>
          {label && (
            <label htmlFor={id} className='ui-select__label'>
              {getTranslate(label)}
              {isRequired && (
                <span
                  className='ui-select__required'
                  data-tip={getTranslate('common.requiredField.tooltip')}>
                  *
                </span>
              )}
              {tooltip && !modern && (
                <InfoIcon
                  icon='im-Info'
                  tooltip={tooltip}
                  dataPlace='top'
                  customClass='ui-select__info'
                />
              )}
            </label>
          )}
          <div className='ui-select__inner'>
            <Select
              {...rest}
              className='ui-select__component'
              classNamePrefix='ui-select'
              styles={customStyles}
              components={{
                Control: ControlComponent,
                DropdownIndicator: DropdownIndicatorComponent,
                MenuList: MenuListComponent,
                Option: OptionComponent,
                MultiValueLabel: MultiValueLabelComponent,
                MultiValueRemove: MultiValueRemoveComponent,
                LoadingIndicator: LoadingIndicatorComponent,
                ClearIndicator: null,
              }}
              placeholder={
                placeholder
                  ? getTranslate(placeholder)
                  : getTranslate('common.selectSingle.placeholder')
              }
              noOptionsMessage={() => getTranslate(noOptionsMessage)}
              options={options}
              isDisabled={this.state.isOpened ? false : isDisabled}
              onInputChange={onInputChange}
              isSearchable={isSearchable}
              isClearable={isSearchable}
              value={value}
              onChange={deselect ? this.changeValue : onChange}
              onMenuOpen={this.openSelect}
              onMenuClose={this.closeSelect}
              closeMenuOnSelect={!isMulti}
              isMulti={isMulti}
              isLoading={isLoading}
              menuIsOpen={rest.menuIsOpen}
              menuShouldScrollIntoView={true}
              maxMenuHeight={165}
              maxHeight={165}
              //need for inner components
              modern={modern}
              tooltip={tooltip}
              theme={theme}
              // setScrollTop={this.setScrollTop}
              onBlur={onBlur}
            />
            {isRow && error && <div className='ui-select__error'>{error}</div>}
          </div>
        </div>
        {!isRow && error && <div className='ui-select__error'>{error}</div>}
      </div>
    );
  }

  openSelect = () => {
    this.setState({ isOpened: true }, () => {
      const { onOpen } = this.props;
      onOpen && onOpen();
    });
  };

  changeValue = (newValue) => {
    const { onChange, value } = this.props;

    if (value?.value === newValue.value) {
      onChange && onChange(null);
    } else {
      onChange && onChange(newValue);
    }
  };

  closeSelect = () => {
    const { isOpened } = this.state;
    if (isOpened) {
      this.setState({ isOpened: false });
    }
  };
}

export default addTranslation(CustomSelect);
