import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';

export const initApp = (data) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.INIT_APP,
        payload: {
          ...data,
        },
      })
    );
  };
};

export const resetAll = () => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_ALL,
      })
    );
  };
};

export const confirmLeavePage = (data) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.CONFIRM_LEAVE_PAGE,
        payload: { ...data },
      })
    );
  };
};
