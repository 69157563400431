import React, { ComponentProps, VFC } from 'react';
import classNames from 'classnames';

import Button from 'components/ui/button';

import './roundButton.scss';

type sizes = 20 | 24 | 26 | 30 | 35;
interface Props
  extends Pick<
    ComponentProps<typeof Button>,
    | 'onClick'
    | 'id'
    | 'text'
    | 'iconSize'
    | 'iconPosition'
    | 'loading'
    | 'disabled'
    | 'customClass'
    | 'type'
    | 'tooltip'
    | 'infoTooltip'
    | 'status'
    | 'tooltipDelay'
    | 'buttonRef'
  > {
  icon: string;
  size?: string | sizes | number;
}

const DEFAULT_ICON_SIZE: Record<NonNullable<Props['size']>, number> = {
  20: 8,
  24: 8,
  26: 12,
  30: 14,
  35: 15,
};

const RoundButton: VFC<Props> = (props) => {
  const {
    size = 24,
    status = 'primary',
    customClass,
    iconSize,
    ...buttonProps
  } = props;

  return (
    <Button
      {...buttonProps}
      customClass={classNames(
        customClass,
        'ui-round-button',
        `ui-round-button_size_${size}`,
        `ui-round-button_status_${status}`
      )}
      status='none'
      size='inline'
      iconSize={iconSize || DEFAULT_ICON_SIZE[size]}
    />
  );
};

export default RoundButton;
