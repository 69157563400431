import React, { PureComponent } from 'react';
import classNames from 'classnames';

interface Props {
  customClass?: string;
}

class ModalFooter extends PureComponent<Props> {
  render() {
    const { customClass } = this.props;

    return (
      <div className={classNames('modal__footer', customClass)}>
        {this.props.children}
      </div>
    );
  }
}

export default ModalFooter;
