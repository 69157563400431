import Login from 'pages/auth/loginPage';
import PasswordResetPage from 'pages/auth/passwordResetPage';
import PasswordChangePage from 'pages/auth/passwordChangePage';
import AuthSupport from 'pages/auth/support';
import PrivacyPolicy from 'pages/policy/privacyPolicy/PrivacyPolicy';
import CookiePolicy from 'pages/policy/cookiePolicy';
import DemoStageInvite from 'pages/demoStageInvite';
import FileDownload from 'pages/fileDownload';
import getPath from 'helpers/path';
import { Route } from './routeTypes';

const publicRoutes: Route[] = [
  {
    path: getPath('/login'),
    component: Login,
  },
  {
    path: getPath('/reset-password'),
    component: PasswordResetPage,
  },
  {
    path: getPath('/change-password'),
    component: PasswordChangePage,
  },
  {
    path: getPath('/privacy-policy'),
    component: PrivacyPolicy,
  },
  {
    path: getPath('/cookie-policy'),
    component: CookiePolicy,
  },
  {
    path: getPath('/login-as-support'),
    component: AuthSupport,
  },
  {
    path: getPath('/demo-stage-invite'),
    component: DemoStageInvite,
  },
  {
    path: getPath('/file-download-failure'),
    component: FileDownload,
  },
];

export default publicRoutes;
