import React, { PureComponent, ReactNode } from 'react';
import './notification.scss';

interface Props {
  content: string | ReactNode;
}

class Notification extends PureComponent<Props> {
  //так как content может быть и строкой и ReactNode, сюда должны приходить уже локализованные данные
  render() {
    const { content } = this.props;

    return (
      <div className='notification__content'>
        <span className='notification__text'>{content}</span>
      </div>
    );
  }
}
export default Notification;
