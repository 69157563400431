import React from 'react';
import classNames from 'classnames';
import { SortableHandle } from 'components/ui/sortable';
import { Chart } from 'highcharts';
import ReactTooltip from 'react-tooltip';
import { indexOf } from 'lodash';

import ChartView from 'components/modal/modalList/graphDetails/components/chartView/ChartViewContainer';
import { InOutTable } from 'components/modal/modalList/graphDetails/components/InOutTable/InOutTable';
import Icon from 'components/ui/icon';
import NoContent from 'components/ui/noContent';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { StoreProps } from 'store';

import { LayoutTypes } from 'components/modal/modalList/editLayout/EditLayout';
import { ServiceCheckGraphEmpty } from 'components/modal/modalList/graphDetails/services/ServiceCheckGraphEmpty';
import { ServiceGenerateChartFiltersText } from 'pages/analytics/components/GraphItem/ServiceGenerateChartFiltersText';

import {
  ChartFilters,
  ChartViewData,
  FraudReportViewData,
  ChargebackViewData,
  InOutData,
  ISharedWithItem,
} from 'types/Analytics';
import { GraphRelation } from './GraphCardContainer';
import { AdvancedAnalyticsEntityType } from 'components/modal/modalList/graphDetails/components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';
import { AdvancedAnalyticsTable } from 'components/modal/modalList/graphDetails/components/AdvancedAnalyticsTable/AdvancedAnalyticsTable';
import MenuContext from 'components/ui/menuContext';
import Loader from 'components/ui/loader';

import 'pages/analytics/components/GraphItem/GraphCard.scss';
import 'styles/common.scss';

interface Props extends IntlProps, StoreProps {
  data: ChartViewData;
  className?: string;
  actionFunction: (item: any) => void;
  setRef?: (ref: Chart | React.Ref<HTMLDivElement>) => void;
  fetched: boolean;
  error?: string;
  layoutType: LayoutTypes;
  filters: ChartFilters;
  onChartSelectChange?: (chardId: string, selected: boolean) => void;
  selectedCharts?: string[] | undefined;
  sortable?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  relationType?: GraphRelation;
  showRelation?: boolean;
  sharedWith?: Array<ISharedWithItem> | undefined;
}

interface State {
  isActionsOpen: boolean;
}

class GraphCard extends React.Component<Props, State> {
  state = {
    isActionsOpen: false,
  };

  get whatToRender() {
    const { fetched, error } = this.props;

    if (error !== undefined) {
      return (
        <NoContent
          className={'graph-card__error graph-card__no-content'}
          text={error}
        />
      );
    }

    if (fetched === true) {
      const { data } = this.props;

      if (ServiceCheckGraphEmpty(data) === true) {
        const { getTranslate } = this.props;

        return (
          <NoContent
            className={'graph-card__no-content'}
            text={getTranslate('analytics.mainView.noData.text')}
          />
        );
      }

      switch ('chartGroup' in data && data.chartGroup) {
        case AdvancedAnalyticsEntityType.inout:
        case AdvancedAnalyticsEntityType.inout_sales_agents: {
          return this.inOutTable;
        }

        case AdvancedAnalyticsEntityType.declineReasons: {
          return this.inOutTable;
        }

        case AdvancedAnalyticsEntityType.fraud_report: {
          const { setRef } = this.props;

          if ('chartType' in data && data.chartType === 'simple_table') {
            return (
              <AdvancedAnalyticsTable
                data={data as unknown as FraudReportViewData}
                setRef={setRef}
              />
            );
          }

          return this.chartView;
        }

        case AdvancedAnalyticsEntityType.chargeback: {
          const { setRef } = this.props;

          if ('chartType' in data && data.chartType === 'simple_table') {
            return (
              <AdvancedAnalyticsTable
                data={data as ChargebackViewData}
                setRef={setRef}
              />
            );
          }

          return this.chartView;
        }

        default: {
          return this.chartView;
        }
      }
    }

    return <Loader customClass={'graph-card__loader'} />;
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevProps.filters === undefined && this.props.filters !== undefined) {
      ReactTooltip.rebuild();
    }
  }

  render() {
    const { className } = this.props;

    return (
      <div className={classNames('graph-item', className)}>
        <div className='graph-item__wrapper'>
          {this.renderControls()}
          <div className='graph-item__container'>{this.whatToRender}</div>
        </div>
      </div>
    );
  }

  get inOutTable() {
    const { data, setRef } = this.props;

    return <InOutTable data={data as InOutData} setRef={setRef} />;
  }

  get chartView() {
    const { data, layoutType, setRef, dispatch } = this.props;

    return (
      <ChartView
        setRef={setRef}
        withExport
        data={data}
        className={'graph-card__chart-view'}
        layoutType={layoutType}
        shouldResize={true}
        dispatch={dispatch}
      />
    );
  }

  renderControls = () => {
    const {
      data,
      filters,
      getTranslate,
      layoutType,
      onChartSelectChange,
      selectedCharts,
      sortable = true,
      showRelation,
      fetched,
    } = this.props;

    if (!fetched) return;

    return (
      <div className='graph-item__controls'>
        <div
          className={classNames(
            'graph-item__drag-title-container',
            layoutType === LayoutTypes.grid
              ? 'graph-item__drag-title-container_grid'
              : 'graph-item__drag-title-container_modules'
          )}>
          {onChartSelectChange !== undefined && 'biChartId' in data ? (
            <input
              className='select-checkbox'
              type='checkbox'
              onChange={(e) => {
                onChartSelectChange(data.biChartId, e.target.checked);
              }}
              checked={indexOf(selectedCharts, data.biChartId) >= 0}
            />
          ) : (
            ''
          )}
          {sortable && (
            <SortableHandle>
              <span className='graph-item__drag-handler'>
                <Icon name='im-Draganddrop' size={14} />
              </span>
            </SortableHandle>
          )}
          {showRelation && this.renderRelation()}
          <div
            className='graph-item__title'
            title={'chartName' in data ? data.chartName : ''}>
            {'chartName' in data ? data.chartName : ''}
          </div>
        </div>
        <div className='graph-item__icon-buttons-container'>
          <div className='graph-item__icon-buttons'>
            {this.renderActionsPanel({ data, filters, getTranslate })}
          </div>
        </div>
      </div>
    );
  };

  renderActionsPanel = ({
    data,
    filters,
    getTranslate,
  }: {
    data: ChartViewData;
    filters: ChartFilters;
    getTranslate: (string) => string;
  }) => {
    const { sharedWith } = this.props;
    let tooltipText;

    if (filters !== undefined && data !== undefined) {
      const { biChartId } = data;
      const chartGroup =
        'chartGroup' in data
          ? data.chartGroup
          : AdvancedAnalyticsEntityType.operations;

      tooltipText =
        filters === undefined || ('error' in data && data.error !== undefined)
          ? undefined
          : ServiceGenerateChartFiltersText({
              id: biChartId,
              chartGroup,
              filters,
              getTranslate,
            });
    }

    if (sharedWith && sharedWith?.length > 0) {
      const usersSharedWith = sharedWith.reduce(
        (toolTip: string[], { userName }, index) => {
          toolTip.push(userName);
          return toolTip;
        },
        []
      );
      tooltipText += `${getTranslate(
        'analytics.shareCharts.info.sharedWithLabel'
      )}: ${usersSharedWith.join(', ')}\n`;
    }

    return (
      <>
        {filters !== undefined && 'error' in data === false && (
          <Icon
            name='im-Info'
            className={classNames('graph-item__icon-info')}
            tooltip={tooltipText}
            data-place='bottom'
          />
        )}
        <MenuContext
          customClass='graph-item__actions'
          name='graph-item'
          list={this.itemActions.filter(({ visible }) => visible === true)}
          value={{
            buttonContent: (
              <div onClick={this.handleActions}>
                <Icon name='showMore' size={14} />
              </div>
            ),
          }}
        />
      </>
    );
  };

  get hasError() {
    const { data } = this.props;

    return 'error' in data && data.error !== undefined;
  }

  get itemActions() {
    const {
      getTranslate,
      actionFunction,
      data,
      canEdit = true,
      canDelete = true,
    } = this.props;
    const chartType = 'chartType' in data ? data.chartType : 'line';

    return [
      {
        id: 'edit',
        content: (
          <>
            <Icon size={14} name='im-Edit1' />
            {getTranslate('analytics.mainView.chartMenu.edit.button')}
          </>
        ),
        visible: !this.hasError && canEdit,
      },
      {
        id: 'delete',
        content: (
          <>
            <Icon size={14} name='im-Basket' />
            {getTranslate('analytics.mainView.chartMenu.delete.button')}
          </>
        ),
        visible: canDelete,
      },
      {
        id: 'fullscreen',
        content: (
          <>
            <Icon name='expand' size={14} />
            {getTranslate('analytics.mainView.chartMenu.viewFullscreen.button')}
          </>
        ),
        visible: !this.hasError,
      },
      {
        id: 'print',
        content: (
          <>
            <Icon name='print' size={14} />
            {getTranslate('analytics.mainView.chartMenu.printChart.button')}
          </>
        ),
        visible:
          !this.hasError &&
          chartType !== 'table' &&
          chartType !== 'simple_table',
      },
      {
        id: 'download-png',
        content: (
          <>
            <Icon name='download' size={14} />
            {getTranslate('analytics.mainView.chartMenu.downloadPNG.button')}
          </>
        ),
        visible:
          !this.hasError &&
          chartType !== 'table' &&
          chartType !== 'simple_table',
      },
      {
        id: 'download-jpeg',
        content: (
          <>
            <Icon name='download' size={14} />
            {getTranslate('analytics.mainView.chartMenu.downloadJPEG.button')}
          </>
        ),
        visible:
          !this.hasError &&
          chartType !== 'table' &&
          chartType !== 'simple_table',
      },
      {
        id: 'download-pdf',
        content: (
          <>
            <Icon name='download' size={14} />
            {getTranslate('analytics.mainView.chartMenu.downloadPDF.button')}
          </>
        ),
        visible:
          !this.hasError &&
          chartType !== 'table' &&
          chartType !== 'simple_table',
      },
      {
        id: 'download-svg',
        content: (
          <>
            <Icon name='download' size={14} />
            {getTranslate('analytics.mainView.chartMenu.downloadSVG.button')}
          </>
        ),
        visible:
          !this.hasError &&
          chartType !== 'table' &&
          chartType !== 'simple_table',
      },
      {
        id: 'download-xlsx',
        content: (
          <>
            <Icon name='download' size={14} />
            {getTranslate('analytics.mainView.chartMenu.downloadXLS.button')}
          </>
        ),
        visible:
          !this.hasError &&
          (chartType === 'table' || chartType === 'simple_table'),
      },
    ].map((item) => ({ ...item, onClick: actionFunction }));
  }

  handleActions = () => {
    const { isActionsOpen } = this.state;

    this.setState({ isActionsOpen: !isActionsOpen });
  };

  renderRelation = () => {
    const { showRelation, relationType, getTranslate } = this.props;

    if (!showRelation) return;

    const params = {
      default: {
        iconName: 'person',
        className: 'advanced-section__icon-person',
        tooltip: 'analytics.mainView.icon.tooltip.myChart',
      },
      [GraphRelation.SHARED_WITH_ME]: {
        iconName: 'share',
        className: 'advanced-section__icon-share',
        tooltip: 'analytics.mainView.icon.tooltip.sharedWithMeChart',
      }
    };
    const key = relationType ?? 'default';
    const config = params[key] ?? params['default'];

    return (
      <span className={'advanced-section__icon'}>
        <Icon
            className={config.className}
            name={config.iconName}
            tooltip={getTranslate(config.tooltip)}
        />
      </span>
    );
  };
}

export default addTranslation(GraphCard);
