import React, { ReactNode } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import classNames from 'classnames';

interface Props extends IntlProps {
  lcKey: string;
  value?: string | Element | ReactNode;
  customClass?: string;
}

class DataBlockRow extends React.PureComponent<Props> {
  static defaultProps = {
    value: '',
    customClass: '',
  };
  render() {
    const { lcKey, value, getTranslate, customClass } = this.props;
    return (
      <div className={classNames('ui-data-block__row', customClass)}>
        <div className='ui-data-block__key'>{getTranslate(lcKey)}</div>
        <div className='ui-data-block__value'>{value}</div>
      </div>
    );
  }
}

export default addTranslation(DataBlockRow);
