import BaseMessageHandler from '../BaseMessageHandler';
import { deleteDataItem } from 'actions/getData';
import tableNames from 'constants/tableNames';

export default class ReportTemplateTaskHistory_Delete extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;
    const { payload } = message;
    dispatch(
      deleteDataItem(
        tableNames.reports,
        'reportTemplateTaskHistoryId',
        payload.reportTemplateTaskHistoryId
      )
    );
  };
}
