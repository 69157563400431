import React from 'react';
import {
  List,
  CellMeasurerCache,
  CellMeasurer,
  InfiniteLoader,
} from 'react-virtualized';
import { components, MenuListComponentProps } from 'react-select';
import classNames from 'classnames';
import InfoIcon from 'components/ui/infoIcon';
import Icon from 'components/ui/icon';
import getLocalizedText from 'helpers/getLocalizedText';

export const ControlComponent = (props) => {
  const needTooltip = props.selectProps.modern && props.selectProps.tooltip;
  const isLightTheme =
    props.selectProps.theme === 'light' && props.selectProps.value?.value;
  const label = isLightTheme ? (
    <>
      {getLocalizedText(props.selectProps.placeholder)}
      <>&nbsp;</>
    </>
  ) : (
    ''
  );
  return (
    <components.Control
      {...props}
      className={needTooltip ? 'ui-select__control_tooltip' : ''}>
      <span className='ui-select__value-wrapper'>
        {label}
        {props.children}{' '}
      </span>
      {needTooltip && (
        <InfoIcon
          icon='im-Info'
          size={16}
          tooltip={props.selectProps.tooltip || ''}
          tooltipId={props.selectProps.tooltipId}
          dataPlace={props.selectProps.tooltipPosition || 'top'}
          customClass='ui-select__info'
        />
      )}
    </components.Control>
  );
};

interface State {
  scrollToIndex: number | undefined;
  prevValue: string;
}

export const SELECT_ITEM_HEIGHT = 24;
export const MODERN_SELECT_ITEM_HEIGHT = 34;
export class MenuListComponent extends React.Component<
  MenuListComponentProps,
  State
> {
  optionHeight = SELECT_ITEM_HEIGHT;
  cache;

  constructor(props) {
    super(props);

    this.state = {
      scrollToIndex: undefined,
      prevValue: '',
    };
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: props.selectProps.modern
        ? MODERN_SELECT_ITEM_HEIGHT
        : SELECT_ITEM_HEIGHT,
      keyMapper: (index) => props.children[index]?.key,
    });

    if (props.selectProps.modern) {
      this.optionHeight = MODERN_SELECT_ITEM_HEIGHT;
    }
  }

  getSnapshotBeforeUpdate(prevProps: Readonly<MenuListComponentProps>) {
    if (this.props.children.length !== prevProps.children.length) {
      this.cache.clearAll();
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // should go after getSnapshotBeforeUpdate, that need to update rows height before it renders
  }

  static getDerivedStateFromProps(
    props: Readonly<MenuListComponentProps>,
    state
  ) {
    const selectedValue = props.getValue()[0]?.value;

    if (!props.selectProps.autoScrollToSelectedOption || !props.hasValue) {
      return null;
    }

    if (state.prevValue !== selectedValue) {
      const valueIndex =
        props.options.findIndex((option) => option.value === selectedValue) + 1;
      return {
        prevValue: selectedValue,
        scrollToIndex: props.options[valueIndex] ? valueIndex : valueIndex - 1,
      };
    }
    return null;
  }

  renderRow = ({ index, key, parent, style }) => {
    const { children } = this.props;
    const item = children[index];

    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={0}
        key={item.key}
        rowIndex={index}
        parent={parent}>
        {({ registerChild }) => (
          <div
            ref={registerChild}
            className='ui-select__option-wrapper'
            style={style}
            key={index}>
            {item}
          </div>
        )}
      </CellMeasurer>
    );
  };

  render() {
    const { children, selectProps } = this.props;
    const rowCount = children.length;

    const height =
      rowCount < 6 ? rowCount * this.optionHeight + 10 : this.props.maxHeight;

    if (
      !rowCount ||
      (rowCount === 1 && children?.props?.children === 'No options')
    ) {
      return (
        <span className='ui-select__empty-list'>
          {selectProps.noOptionsMessage() ||
            getLocalizedText('common.selectSingle.noOptions')}
        </span>
      );
    }

    return (
      <>
        <InfiniteLoader
          isRowLoaded={({ index }) => !!children[index]}
          loadMoreRows={this.loadMore}
          threshold={2}
          rowCount={rowCount + 1}>
          {({ onRowsRendered, registerChild }) => (
            <List
              height={height}
              width={300} //width is rewritten in the stylesheet
              ref={registerChild}
              onRowsRendered={onRowsRendered}
              rowCount={children.length || 0}
              rowHeight={this.cache.rowHeight}
              rowRenderer={this.renderRow}
              scrollToIndex={this.state.scrollToIndex}
              scrollToAlignment='center'
            />
          )}
        </InfiniteLoader>
        {selectProps.isLoading && (
          <div className='ui-select__loader-wrapper'>
            <LoadingIndicatorComponent />
          </div>
        )}
      </>
    );
  }

  loadMore = () => {
    if (this.props.selectProps.isLoadMore) {
      this.props.selectProps.loadMore?.();
    }
  };
}

export const DropdownIndicatorComponent = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.modern ? (
        <Icon size={6} name='dropdownTriangle' />
      ) : (
        <Icon size={8} name='im-Arrow-down' />
      )}
    </components.DropdownIndicator>
  );
};

export const LoadingIndicatorComponent = () => {
  return (
    <Icon size={18} name='im-Ellipse' className='ui-select__loader rotating' />
  );
};

export const OptionComponent = (props) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };

  return (
    <components.Option
      {...newProps}
      className={classNames({
        'ui-select__option_favorite': newProps.data.isLastFavorite,
      })}>
      <div className='ui-select__option-value'>
        <div className='ui-select__option-value-inner'>{newProps.children}</div>
      </div>
      {!newProps.selectProps.modern && (
        <span className='ui-select__icon-checked'>
          <Icon size={6} name='im-Check-mark' />
        </span>
      )}
    </components.Option>
  );
};

export const MultiValueLabelComponent = (props) => {
  return (
    <span className='ui-select__multi-item'>
      <components.MultiValueLabel {...props} />
    </span>
  );
};

export const MultiValueRemoveComponent = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon name='close' size={12} />
    </components.MultiValueRemove>
  );
};
