import EventManager from 'helpers/EventManager';
import { AnyObject } from 'types/Common';
import { StoreProps } from 'store';
import { WithRouterProps } from 'decorators/withRouter';
import RpcTypes from 'constants/rpcTypes';

import BaseMessageHandler from './BaseMessageHandler';
import MessageHandlers from './list';

interface Params extends StoreProps {
  history: WithRouterProps['history'];
  message: {
    id: string;
    type: RpcTypes;
    status: string;
    payload: AnyObject;
  };
  response: AnyObject;
}

export default (data: Params) => {
  const { status, id, type, payload } = data.message;
  EventManager.emit(type, { payload, rpc: { id, status } });

  try {
    const Handler = MessageHandlers[type];
    if (!Handler) return;
    const instanceHandler = new Handler(data);
    return (instanceHandler[status] || instanceHandler.default) as InstanceType<
      typeof BaseMessageHandler
    >['default'];
  } catch (e) {}
};
