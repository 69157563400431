import BaseMessageHandler from '../BaseMessageHandler';
import { deleteDataItem } from 'actions/getData';

export default class User_Delete extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;
    const { payload } = message;

    dispatch(deleteDataItem('users', 'id', payload.id));
  };
}
