import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 * Список recurring
 * @param params
 */
export const getRecurringList = (params) => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.SubscriptionPayment_List,
          payload: { ...params },
        },
      ],
      isCancelable: true,
    })
    .then((data: any) => ({
      items: data.list,
      totalRows: data.pagination?.totalRows || Infinity,
    }));
};

/**
 * Получить рекаринг по айди
 * @param transactionId
 */
export const getRecurringById = (transactionId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.SubscriptionPayment_Info,
        payload: {
          transactionId,
        },
      },
    ],
  });
};

/**
 * Получить настройки + историю
 * @param transactionId
 */
export const getRecurringSettings = (transactionId: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.SubscriptionPayment_Settings,
        payload: {
          transactionId,
        },
      },
    ],
  });
};

/**
 * Обновить настройки
 * @param transactionId
 * @param data
 */
export const updateRecurringSettings = (transactionId: string, data) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.SubscriptionPayment_Update,
        payload: {
          ...data,
          transactionId,
        },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Отмена подписки
 * @param transactionId
 */
export const cancelRecurringSettings = (transactionId: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.SubscriptionPayment_Cancel,
        payload: {
          transactionId,
        },
      },
    ],
    showValidateNotification: false,
  });
};

export default {
  getRecurringList,
  getRecurringById,
};
