import React from 'react';
import {
  getTableWidthFromStorage,
  isEmpty,
  memorizeTableWidth,
  parseColumnsWidthsFromHeader,
} from 'components/ui/table/helpers';

import { MIN_COLUMN_WIDTH } from 'constants/ui';
import ItemConfiguration, { Width } from 'types/ItemConfiguration';
import { AnyObject } from 'types/Common';

interface Props {
  configuration: ItemConfiguration[];
  customConfiguration: { id: string }[];
  resizableColumns: boolean;
  tableName: string;
  items: AnyObject[];
  minColumnsWidth?: AnyObject;
  forbidResizeFor?: string[];
}

interface State {
  width?: Width;
}

export interface ResizeProps {
  updateWidth?: (id: string, value: number) => void;
  setWidth?: (width?: Width, cb?: () => void) => void;
  width?: Width;
  setRefForResize?: (setRefForResizeParams) => void;
}

export const addResize = (WrapperComponent) => {
  class ResizeDecorator extends React.Component<Props, State> {
    private readonly wrapped;
    private configuration: { id: string }[];
    private headerRef;
    private scrollBarRef;

    constructor(props) {
      super(props);
      this.state = {
        width: undefined,
      };
      this.configuration = [];

      this.wrapped = React.createRef();
    }

    static defaultProps = {
      customConfiguration: [],
      resizableColumns: true,
    };
    componentDidMount = () => {
      this.updateConfiguration();
      this.setInitialWidth();
    };

    componentDidUpdate = (prevProps) => {
      const { tableName: oldName, configuration: oldConfiguration } = prevProps;
      const { tableName, configuration } = this.props;
      if (oldName && oldName !== tableName) {
        this.updateConfiguration();
        this.saveTableWidth(oldName);
        this.setInitialWidth();
      } else if (
        configuration &&
        oldConfiguration &&
        oldConfiguration.length !== configuration.length
      ) {
        setTimeout(() => {
          // can't fire it right away, because ref needs time to update
          this.setWidth(parseColumnsWidthsFromHeader(this.headerRef)); // for scroll content in SmartList.tsx line.151
        }, 300);
      }
    };

    render() {
      const { width } = this.state;
      return (
        <WrapperComponent
          {...this.props}
          updateWidth={this.updateWidth}
          setWidth={this.setWidth}
          setRefForResize={this.setRefForResize}
          width={width}
        />
      );
    }

    setRefForResize = ({ headerRef, scrollBarRef }) => {
      const { width } = this.state;
      if (headerRef) {
        this.headerRef = headerRef;
        if (!width) {
          this.setWidth(parseColumnsWidthsFromHeader(this.headerRef));
        }
      }
      if (scrollBarRef) {
        this.scrollBarRef = scrollBarRef;
      }
    };

    updateConfiguration = () => {
      const { configuration, customConfiguration } = this.props;
      this.configuration = customConfiguration.length
        ? customConfiguration
        : configuration || [];
      if (!this.configuration.length) {
        this.defineConfiguration();
      }
    };

    setInitialWidth = () => {
      const { resizableColumns, tableName } = this.props;
      if (resizableColumns) {
        this.setWidth(getTableWidthFromStorage(tableName));
      }
    };

    defineConfiguration = () => {
      const { items } = this.props;
      this.configuration =
        (items &&
          items.length &&
          items[0].columns &&
          items[0].columns.map((col) => ({ id: col.attribute }))) ||
        [];
    };

    setWidth = (width, cb?: () => void) => {
      this.setState({ width }, () => {
        this.saveTableWidth();
        cb && cb();
      });
    };

    validateValue = (value, columnId, oldWidth?) => {
      const { minColumnsWidth = {}, forbidResizeFor } = this.props;
      if (forbidResizeFor?.includes(columnId)) {
        return oldWidth;
      }
      const minValue = minColumnsWidth[columnId] || MIN_COLUMN_WIDTH;
      return value > minValue ? value : minValue;
    };

    getColumnIndex = (id) => {
      return this.configuration.findIndex((item) => item.id === id);
    };

    updateWidth = (id, value) => {
      const { forbidResizeFor } = this.props;

      if (forbidResizeFor?.includes(id)) {
        return;
      }

      const volume = this.validateValue(value, id);
      let { width } = this.state;

      if (id && volume) {
        if (isEmpty(width)) {
          width = (parseColumnsWidthsFromHeader(this.headerRef) as Width) || {};
        }
        const { scrollValues } = this.scrollBarRef;
        const { scrollWidth, clientWidth } = scrollValues;
        /*
         * if table is scrollable sidewards than we simply change
         * the volume, otherwise we need to recalculate other column's widths
         * to fill all the space and prevent flex-grow from blocking resize
         */
        if (scrollWidth > clientWidth) {
          this.setWidth({ ...width, [id]: volume }, () => {
            if (volume < width![id]) {
              this.forceUpdate();
            }
          });
        } else {
          const keys = Object.keys(width!);
          const changedColumnIndex = this.getColumnIndex(id);
          const columnsChanged =
            this.configuration.length - 1 - changedColumnIndex;
          const diff = Math.round((volume - width![id]) / columnsChanged);
          const sameColumn = (columnId) => columnId === id;
          const newWidth = keys.reduce((acc, columnId) => {
            const validate = (val) =>
              this.validateValue(val, columnId, width![columnId]);

            if (sameColumn(columnId)) {
              acc[columnId] = volume;
            } else if (this.getColumnIndex(columnId) < changedColumnIndex) {
              acc[columnId] = validate(width![columnId]);
            } else {
              const newValue = width![columnId] - diff;
              acc[columnId] = validate(newValue);
            }
            return acc;
          }, {});
          console.log('newWidth', newWidth);
          this.setWidth(newWidth);
        }
      }
    };

    saveTableWidth = (tableName?) => {
      const { width } = this.state;
      const tableNameToSave = tableName || this.props.tableName;
      memorizeTableWidth(tableNameToSave, { ...width });
    };
  }

  return ResizeDecorator as typeof WrapperComponent;
};
