import {
  chartParams,
  filters as PaymentFormFilters,
} from 'components/modal/modalList/graphDetails/components/settingsForm/OperationsFormConfig';
import {
  PaymentChartParams,
  paymentFilters,
} from 'components/modal/modalList/graphDetails/components/settingsForm/PaymentsFormConfig';
import {
  PaymentSalesAgentFilters,
  PaymentSalesAgentsChartParams,
} from 'components/modal/modalList/graphDetails/components/settingsForm/PaymentsSalesAgentsFormConfig';
import {
  DeclineReasonsFilters,
  DeclineReasonsParams,
} from 'components/modal/modalList/graphDetails/components/settingsForm/DeclineReasonsFormConfig';
import {
  InOutTableFilters,
  InOutTableGroupByParams,
} from 'components/modal/modalList/graphDetails/components/settingsForm/InOutFormConfig';
import {
  InOutSalesAgentsTableGroupByParams,
  InOutSalesAgentsTableFilters,
} from 'components/modal/modalList/graphDetails/components/settingsForm/InOutSalesAgentsFormConfig';
import {
  topTenChartFilters,
  topTenChartParams,
} from 'components/modal/modalList/graphDetails/components/settingsForm/Top10FormConfig';

import {
  ChargebackFilters,
  ChargebackParams,
} from 'components/modal/modalList/graphDetails/components/settingsForm/ChargebackFormConfig';

import {
  FraudReportFilters,
  FraudReportParams,
} from 'components/modal/modalList/graphDetails/components/settingsForm/FraudReportFormConfig';

import {
  DeclineCodesChartParams,
  DeclineCodesFilters,
} from 'components/modal/modalList/graphDetails/components/settingsForm/DeclineCodesFormConfig';

import {
  ChartFilters,
  DictionaryItem,
  IFormDescriptorItem,
  IOption,
} from 'types/Analytics';
import Repository from 'helpers/Repository';
import DateHelpers from 'helpers/Date';
import DateFormats from 'constants/dateFormats';
import { AdvancedAnalyticsEntityType } from 'components/modal/modalList/graphDetails/components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';

import { FILTERS_RENAME_PARAM_MAPPING } from 'constants/renameParamMapping';

const MAX_MULTI_SELECT_ITEMS = 7;
const ANALYTICS_DICTIONARY_NAMES_MAP = {
  projects: 'enabledProject',
  country: 'country',
  currency: 'currency',
  paymentMethod: 'paymentMethodType',
  paymentMethodFromDictionary: 'paymentMethodFromDictionary',
  analyticsBrand: 'analyticsBrand',
  issuerBank: 'issuerBank',
};
const SKIPPED_FIELDS = ['chartName', 'chartType'];

const filterIsEmpty = (
  value: string | string[] | null | undefined
): boolean => {
  return (
    value === '' ||
    value === null ||
    value === undefined ||
    (Array.isArray(value) === true && value.length === 0) ||
    (typeof value === 'object' && !!value && Object.keys(value).length === 0)
  );
};

const getAllOptions = (formItem: IFormDescriptorItem): IOption[] => {
  const { options, conditionalOptions, renderOptions } = formItem;
  const optionsIterable = options === undefined ? [] : options;
  const conditionalOptionsIterable =
    conditionalOptions === undefined
      ? []
      : conditionalOptions.reduce((acc: IOption[], option) => {
          const _data =
            option !== undefined && option.data !== undefined
              ? option.data.map((item) => item)
              : [];
          const resultData = _data === undefined ? [] : _data;

          acc = [...acc, ...resultData];

          return acc;
        }, []);

  const fallbackOptions: { value: string; label: string } | undefined =
    renderOptions?.disabled?.fallBackValue;
  const fallbackOptionsIterable =
    fallbackOptions === undefined ? [] : [fallbackOptions];

  return [
    ...optionsIterable,
    ...conditionalOptionsIterable,
    ...fallbackOptionsIterable,
  ];
};

const ServiceGenerateChartFiltersText = ({
  filters,
  chartGroup,
  getTranslate,
}: {
  id: string;
  chartGroup: AdvancedAnalyticsEntityType;
  filters: ChartFilters;
  getTranslate: (string) => string;
}): string => {
  let allParams: IFormDescriptorItem[] = [];

  switch (chartGroup) {
    case AdvancedAnalyticsEntityType.operations: {
      allParams = [...chartParams, ...PaymentFormFilters];

      break;
    }
    case AdvancedAnalyticsEntityType.payments: {
      allParams = [...PaymentChartParams, ...paymentFilters];

      break;
    }
    case AdvancedAnalyticsEntityType.payments_sa: {
      allParams = [...PaymentSalesAgentsChartParams, ...PaymentSalesAgentFilters];

      break;
    }
    case AdvancedAnalyticsEntityType.declineReasons: {
      allParams = [...DeclineReasonsParams, ...DeclineReasonsFilters];

      break;
    }
    case AdvancedAnalyticsEntityType.inout: {
      allParams = [...InOutTableGroupByParams, ...InOutTableFilters];

      break;
    }
    case AdvancedAnalyticsEntityType.inout_sales_agents: {
      allParams = [...InOutSalesAgentsTableGroupByParams, ...InOutSalesAgentsTableFilters];

      break;
    }
    case AdvancedAnalyticsEntityType.topten: {
      allParams = [...topTenChartParams, ...topTenChartFilters];

      break;
    }

    case AdvancedAnalyticsEntityType.chargeback: {
      allParams = [...ChargebackParams, ...ChargebackFilters];

      break;
    }

    case AdvancedAnalyticsEntityType.fraud_report: {
      allParams = [...FraudReportParams, ...FraudReportFilters];

      break;
    }

    case AdvancedAnalyticsEntityType.declineCodes: {
      allParams = [...DeclineCodesChartParams, ...DeclineCodesFilters];

      break;
    }
  }

  return allParams.reduce((acc, formItem) => {
    const { type, label, id } = formItem;

    if (SKIPPED_FIELDS.includes(id)) {
      return acc;
    }

    const filterId: string =
      FILTERS_RENAME_PARAM_MAPPING[chartGroup][id] === undefined
        ? id
        : FILTERS_RENAME_PARAM_MAPPING[chartGroup][id];

    if (filterIsEmpty(filters[filterId])) {
      return acc;
    }

    switch (type) {
      case 'input': {
        const text = `${getTranslate(label)}: ${filters[id]}\n`;

        acc += text;

        break;
      }

      case 'singleSelect': {
        const { dictionaryKey } = formItem;

        if (dictionaryKey !== undefined) {
          const dictionary =
            Repository.get('store').getState().filtersValues[dictionaryKey];
          const item = dictionary.items.find(
            ({ id: dicId }) => dicId === filters[filterId]
          );

          if (item === undefined) {
            acc += `${getTranslate(label)}: \n`;
          } else {
            acc += `${getTranslate(label)}: ${getTranslate(item.label)}\n`;
          }
        } else {
          const allOptions = getAllOptions(formItem);
          const item = allOptions.find(
            (currentItem) =>
              'value' in currentItem && currentItem.value === filters[filterId]
          );

          if (item === undefined) {
            acc += `${getTranslate(label)}: \n`;
          } else {
            acc += `${getTranslate(label)}: ${
              'label' in item && getTranslate(item.label)
            }\n`;
          }
        }

        break;
      }

      case 'date': {
        const value = filters[filterId];
        const from = DateHelpers.createDate(value.from);
        const to = DateHelpers.createDate(value.to);

        acc += `${getTranslate(label)}: ${from.format(
          DateFormats.datetime
        )} \u2014 ${to.format(DateFormats.datetime)}\n`;

        break;
      }

      case 'multiSelect': {
        const { dictionaryKey } = formItem;
        const value =
          Array.isArray(filters[filterId]) === true ? filters[filterId] : [];

        if (dictionaryKey !== undefined) {
          const dictionary =
            Repository.get('store').getState().filtersValues[
              ANALYTICS_DICTIONARY_NAMES_MAP[dictionaryKey]
            ];

          const resultItemsList = value
            .map((item: DictionaryItem, index: number) => {
              if (index > MAX_MULTI_SELECT_ITEMS) {
                return undefined;
              }
              const found = dictionary.list.find(
                ({ id: dicId }) => dicId === item
              );

              if (found !== undefined) {
                if (index !== 0) {
                  return ' ' + found.text;
                }
                return found.text;
              }
              return undefined;
            })
            .filter((item) => item !== undefined);
          acc += `${getTranslate(label)}: ${resultItemsList}${
            value.length > MAX_MULTI_SELECT_ITEMS ? '...' : ''
          }\n`;
        } else {
          const options = getAllOptions(formItem);
          const resultItemsList = value
            .map((item: string, index: number) => {
              if (index > MAX_MULTI_SELECT_ITEMS) {
                return undefined;
              }
              const found =
                options !== undefined
                  ? options.find(
                      (option) => 'id' in option && option.id === item
                    )
                  : undefined;

              if (found !== undefined && 'text' in found) {
                const resultString = getTranslate(found.text);

                if (index !== 0) {
                  return ' ' + resultString;
                }
                return resultString;
              }
              return undefined;
            })
            .filter((item) => item !== undefined);

          acc += `${getTranslate(label)}: ${resultItemsList}${
            value.length > MAX_MULTI_SELECT_ITEMS ? '...' : ''
          }\n`;
        }

        break;
      }

      case 'selectGroup': {
        const value =
          Array.isArray(filters[filterId]) === true ? filters[filterId] : [];
        const { items } = formItem;

        const result = value
          .map((item, index) => {
            const found =
              items !== undefined
                ? items.find(
                    (option) => 'value' in option && option.value === item
                  )
                : undefined;

            if (found !== undefined && 'label' in found) {
              if (index !== 0) {
                return ' ' + found.label;
              }
              return found.label;
            }
            return undefined;
          })
          .filter((item) => item !== undefined);

        acc += `${getTranslate(label)}: ${result}\n`;

        break;
      }
    }

    return acc;
  }, '');
};

export { ServiceGenerateChartFiltersText };
