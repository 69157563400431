const dateFilters = [
  'date',
  'creationDate',
  'createdAt',
  'updatedAt',
  'updatedDate',
  'expDate',
];

export default (filterId: string) => {
  if (!filterId) return false;
  return dateFilters.includes(filterId);
};
