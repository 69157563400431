import classNames from 'classnames';
import React, { PureComponent } from 'react';
import Loader from 'components/ui/loader';
import LabelStatus from 'components/ui/labelStatus';
import Mapper from 'helpers/Mapper';
import './teaser.scss';

interface Props {
  title: string;
  text: string | JSX.Element;
  status?: string;
  isLoading?: boolean;
}

class Teaser extends PureComponent<Props> {
  render() {
    const { status, isLoading } = this.props;
    return (
      <div
        className={classNames('ui-teaser', {
          'ui-teaser_loading': isLoading,
        })}>
        {status && (
          <div className='ui-teaser__status'>
            {isLoading ? (
              <Loader type='bung' />
            ) : (
              <LabelStatus
                customClass='ui-teaser__status'
                status={Mapper.getStatus(status?.toLowerCase())}
                text={status}
              />
            )}
          </div>
        )}
        <div className='ui-teaser__title'>
          {isLoading ? <Loader type='bung' /> : this.props.title}
        </div>
        <div className='ui-teaser__text'>
          {isLoading ? <Loader type='bung' /> : this.props.text}
        </div>
        <div className='ui-teaser__content'>
          {isLoading ? <Loader type='bung' /> : this.props.children}
        </div>
      </div>
    );
  }
}

export default Teaser;
