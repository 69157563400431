import PaymentPageUI from 'types/PaymentPageUI';
import RecurringCardPageUI from 'types/RecurringCardPageUI';
import { desc } from '../types/sortings';

class UiStateFactory {
  static getInitialPageUI(): PaymentPageUI {
    return {
      pageScrollTop: 0,
      operationsScrollTop: 0,
      selectedOperationId: '',
      operationInfoScrollTop: 0,
      paymentInfoScrollTop: 0,
      paymentSelectedTab: 0,
    };
  }

  static getInitialRecurringCardUI(): RecurringCardPageUI {
    return {
      pageScrollTop: 0,
      selectedOperationId: '',
      operationInfoScrollTop: 0,
      subsOperationsScrollTop: 0,
      initOperationsScrollTop: 0,
      paymentInfoScrollTop: 0,
      paymentSelectedTab: 0,
      subOperationsSort: desc,
      initOperationsIsOpened: false,
      isMaxHeight: false,
    };
  }
}

export default UiStateFactory;
