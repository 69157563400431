import React, { ChangeEvent } from 'react';
import { List } from 'react-virtualized';
import { MODERN_SELECT_ITEM_HEIGHT } from 'components/ui/customSelect/selectInnerComponents';
import Input from 'components/ui/input';
import './combo-lookup.scss';

type Option = { label: string; value: string };

interface Props {
  id: string;
  value?: any; //Value | Value[] | ReactNode | null;
  options: Option[];
  onSelect: (option: Option) => void;
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  error?: string;
  tooltip?: string;
  modern?: boolean;
  onBlur?: () => void;
}

interface State {
  suggestionsShown: boolean;
}

const MAX_LIST_HEIGHT = 165;

class ComboLookup extends React.Component<Props, State> {
  optionHeight = MODERN_SELECT_ITEM_HEIGHT;
  wrapper;
  constructor(props) {
    super(props);
    this.state = {
      suggestionsShown: false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const { suggestionsShown } = this.state;
    const {
      isDisabled,
      value,
      label,
      isRequired,
      id,
      error,
      tooltip,
      modern,
      onBlur,
    } = this.props;

    return (
      <div
        ref={(ref) => {
          this.wrapper = ref;
        }}
        className='maf-field combo-lookup'>
        <Input
          autoComplete='off'
          id={id}
          label={label}
          value={value}
          error={error}
          modern={modern}
          tooltip={tooltip}
          customClass='maf-field'
          placeholder='Enter value'
          disabled={isDisabled}
          isRequired={isRequired}
          onFocus={this.openSuggestions}
          onChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          onBlur={onBlur}
        />
        {suggestionsShown && this.renderOptions()}
      </div>
    );
  }

  handleInputChange = (e) => {
    this.props.onInputChange(e);
    if (!this.state.suggestionsShown) {
      this.openSuggestions();
    }
  };

  handleKeyDown = (e) => {
    if (e.keyCode !== 13) {
      return;
    }

    const filteredOptions = this.filteredOptions;
    if (filteredOptions?.length) {
      this.handleSelect(filteredOptions[0]);
    }
  };

  handleClickOutside(event) {
    if (!this.wrapper?.contains(event.target)) {
      this.closeSuggestions();
    }
  }

  openSuggestions = () => this.setState({ suggestionsShown: true });
  closeSuggestions = () => this.setState({ suggestionsShown: false });

  renderOptions = () => {
    const filteredOptions = this.filteredOptions;

    if (!filteredOptions?.length) {
      return null;
    }

    const height =
      filteredOptions?.length < 6
        ? filteredOptions?.length * this.optionHeight + 10
        : MAX_LIST_HEIGHT;

    return (
      <div className='combo-lookup__list' style={{ height }}>
        <List
          height={height}
          width={300}
          rowCount={filteredOptions.length}
          rowHeight={this.optionHeight}
          rowRenderer={({ index, key, style }) => {
            const item = filteredOptions[index];
            return (
              <div
                onClick={() => this.handleSelect(item)}
                className='combo-lookup__option'
                style={style}
                key={key}>
                {item.label}
              </div>
            );
          }}
        />
      </div>
    );
  };

  handleSelect = (item: Option) => {
    this.props.onSelect(item);
    this.closeSuggestions();
  };

  get filteredOptions(): Option[] | null {
    const { options, value } = this.props;

    if (!value) {
      return options;
    }

    const _value = value.toLowerCase();

    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(_value)
    );

    if (filteredOptions.length) {
      return filteredOptions;
    }

    return null;
  }
}

export default ComboLookup;
