import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';
import request from 'helpers/request';

/**
 *
 * @param params
 */
export const getApsIcons = (params): Promise<any> => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.ApsIcon_List,
          payload: { ...params },
        },
      ],
    })
    .then((data) => ({ ...data, items: data.rows, totalRows: Infinity }));
};

/**
 *
 */
export const getApsIconsPaymentMethods = (): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.ApsIcon_UsedPaymentMethods,
      },
    ],
  });
};

/**
 *
 * @param requestData
 */
export const createIcon = (requestData): Promise<any> => {
  return request({
    method: 'POST',
    url: 'app/aps-icon/create',
    data: requestData,
    headers: { 'Content-Type': 'multipart/form-data' },
    errorHandle: false,
  }).then(({ data }: any) => data.payload);
};

/**
 *
 * @param requestData
 */
export const updateIcon = (requestData): Promise<any> => {
  return request({
    method: 'POST',
    url: 'app/aps-icon/update',
    data: requestData,
    headers: { 'Content-Type': 'multipart/form-data' },
    errorHandle: false,
  }).then(({ data }: any) => data.payload);
};

export default {
  getApsIcons,
  getApsIconsPaymentMethods,
  createIcon,
  updateIcon,
};
