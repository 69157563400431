import React, { Fragment } from 'react';
import { IntlProps } from 'decorators/addTranslation';
import Input from 'components/ui/input';
import Switch from 'components/ui/switch';
import RoundButton from 'components/ui/roundButton';
import { UserFields } from 'components/userBuilder/UserFieldsInterface';
import { AnyObject } from 'types/Common';
import './trustedIp.scss';

interface Props extends IntlProps {
  fields: UserFields;
  validationErrors: AnyObject;
  canEdit: boolean;
  isEnabled: boolean;
  onChangeField: (key: string, value: any) => void;
  onToggleIps: (isEnabled: boolean) => void;
}

export default class TrustedIP extends React.PureComponent<Props> {
  render() {
    const { getTranslate, fields, validationErrors, canEdit, isEnabled } =
      this.props;
    return (
      <Fragment>
        <div className='trusted-ip'>
          <div className='ui-form-fields__item'>
            <Switch
              id='add-ip-range'
              text={getTranslate('myteam.adduser.trustedIP.title')}
              textPosition='right'
              checked={isEnabled}
              disabled={!canEdit}
              onChange={(e) => this.toggleFields(e.target.checked)}
            />
          </div>

          {isEnabled &&
            fields.ipList.map(({ from, to }, i) => (
              <div
                className='ui-form-fields__item  ui-form-fields__item_trusted-ip utils-flex'
                key={i}>
                <Input
                  label={getTranslate('myteam.adduser.trustedIP.from')}
                  disabled={!canEdit}
                  customClass='ui-form-fields__field'
                  placeholder={getTranslate(
                    'myteam.adduser.trustedIP.placeholder'
                  )}
                  id={`user-builder-ip-from-${i}`}
                  value={from}
                  onChange={({ target }) =>
                    this.changeIpField(i, 'from', target.value)
                  }
                  modern
                />
                <Input
                  disabled={!canEdit}
                  label={getTranslate('myteam.adduser.trustedIP.to')}
                  customClass='ui-form-fields__field'
                  placeholder={getTranslate(
                    'myteam.adduser.trustedIP.placeholder'
                  )}
                  id={`user-builder-ip-to-${i}`}
                  value={to}
                  onChange={({ target }) =>
                    this.changeIpField(i, 'to', target.value)
                  }
                  modern
                />
                {i < 1 ? (
                  <RoundButton
                    icon='im-Plus'
                    status='primary'
                    size={26}
                    disabled={!canEdit || fields.ipList.length >= 15}
                    onClick={this.addIpRange}
                    customClass='ui-form-fields__add-button'
                  />
                ) : (
                  <RoundButton
                    icon='im-Basket'
                    status='danger'
                    size={26}
                    disabled={!canEdit}
                    onClick={() => this.removeIpRange(i)}
                    customClass='ui-form-fields__add-button'
                  />
                )}
              </div>
            ))}
          {validationErrors.ipRanges && (
            <div
              className='ui-form-fields__error trusted-ip__error'
              id='ipRanges'>
              {validationErrors.ipRanges}
            </div>
          )}
        </div>
      </Fragment>
    );
  }

  addIpRange = () => {
    const { onChangeField, fields } = this.props;
    const { ipList } = fields;
    onChangeField('ipList', [...ipList, { from: '', to: '' }]);
  };

  removeIpRange = (index) => {
    const { onChangeField, fields } = this.props;
    const newIpRange = fields.ipList.filter((_, i) => i !== index);
    onChangeField('ipList', newIpRange);
  };

  changeIpField = (i, dir, value) => {
    const { fields, onChangeField } = this.props;
    const ipList = [...fields.ipList];
    ipList[+i][dir] = value;
    onChangeField('ipList', ipList);
  };

  toggleFields = (isEnabled) => {
    if (!this.props.fields.ipList.length) {
      this.addIpRange();
    }
    this.props.onToggleIps(isEnabled);
  };
}
