import React, { PureComponent } from 'react';
import { toast, ToastContainer, cssTransition } from 'react-toastify';
import Icon from 'components/ui/icon';
import { toastIdsStore } from 'components/ui/notification/showNotification';

class NotificationContainer extends PureComponent {
  render() {
    const CloseButton = ({ closeToast }: { closeToast?: () => void }) => (
      <span
        onClick={(e) => {
          const toastId = this.getToastIdIndex(e);
          toast.dismiss(toastIdsStore[toastId]);
          toastIdsStore.splice(toastId, 1);
        }}
        className='notification__close'>
        <Icon size={8} name='im-Close' />
      </span>
    );

    return (
      <ToastContainer
        hideProgressBar={true}
        position='bottom-left'
        className='notification'
        toastClassName='notification__toast'
        transition={cssTransition({
          enter: 'notification__animated notification__in',
          exit: 'notification__animated notification__out',
        })}
        autoClose={false}
        draggable={false}
        closeOnClick={false}
        closeButton={<CloseButton />}
        limit={4}
      />
    );
  }

  getToastIdIndex = (e) => {
    const $toasts = document.body.querySelectorAll('.notification__toast');
    const $parent = e.target.closest('.notification__toast');
    return Array.prototype.slice.call($toasts).indexOf($parent);
  };
}
export default NotificationContainer;
