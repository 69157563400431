import { WithRouterProps } from 'decorators/withRouter';
import { StoreProps } from 'store';

interface Interface {
  default: () => void;
  success?: () => void;
  validation?: () => void;
  request?: () => void;
}

class BaseMessageHandler implements Interface {
  protected dispatch: StoreProps['dispatch'];
  protected history: WithRouterProps['history'];
  protected message;
  protected response;

  constructor({ dispatch, history, message, response }) {
    this.dispatch = dispatch;
    this.history = history;
    this.message = message;
    this.response = response;
  }

  // eslint-disable-next-line no-empty-function
  default = () => {};
}

export default BaseMessageHandler;
