import BaseMessageHandler from '../BaseMessageHandler';
import { updateArticle } from 'actions/help';

export default class Article_Publish extends BaseMessageHandler {
  default = () => {
    const {
      dispatch,
      message: { payload },
    } = this;
    dispatch(updateArticle(payload));
  };
}
