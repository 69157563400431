import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { addTranslation } from 'decorators/addTranslation';

function getSubtitle(conversion, translation) {
  return `
        <div class="subtitle">
            <div>
                <span class='conversion'>${translation}</span>
            </div>
            <div>
                <b class="percent">${conversion | 0} %</b>
            </div>
        </div>`;
}

const Chart = ({ conversion, getTranslate }) => {
  const declines = 100 - conversion;
  const options: Highcharts.Options = {
    title: {
      text: '',
    },
    mapNavigation: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    chart: {
      showAxes: false,
      width: '150',
      height: '150',
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    subtitle: {
      useHTML: true,
      text: getSubtitle(
        conversion,
        getTranslate('payments.summary_table.conversion')
      ),
      floating: true,
      verticalAlign: 'middle',
      y: 20,
    },
    rangeSelector: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        gapSize: 20,
      },
      pie: {},
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        size: '100%',
        innerSize: '85%',
        type: 'pie',
        name: 'Conversion',
        data: [
          {
            name: 'success',
            y: conversion,
            color: '#009acc',
          },
          {
            name: 'decline',
            y: declines,
            color: '#fff',
          },
        ],
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options}
      />
    </div>
  );
};

export default addTranslation(Chart);
