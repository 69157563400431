export const availableRoles = {
  isChargebackMailingEnabled: ['merchant_admin', 'risk'],
  isFinancialReportMailingEnabled: ['merchant_admin', 'finance'],
  isFraudMailingEnabled: ['merchant_admin', 'risk'],
};

export const requiredPermissions = {
  isChargebackMailingEnabled: 'chargeback_manage',
  isFinancialReportMailingEnabled: 'financial_report_view',
  isFraudMailingEnabled: 'rcs_view',
};

export const MAX_AVAILABLE_MERCHANTS = 50;

export const SALES_AGENTS_ROLE = 'sales_agent';