import React from 'react';
import { connect } from 'react-redux';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { openModal } from 'actions/modal';
import MetricService from 'helpers/metricService/MetricService';
import { StoreProps } from 'store';

import Button from 'components/ui/button';

interface OwnProps {
  operationId: string;
  transactionId: string;
}

type Props = OwnProps & IntlProps & StoreProps;

interface State {
  isLoading: boolean;
}

class CreateSupportTicketButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  render() {
    const { isLoading } = this.state;
    const { getTranslate } = this.props;

    if (!this.props.transactionId || !this.props.operationId) {
      return null;
    }

    return (
      <Button
        loading={isLoading}
        onClick={this.openModal}
        text={getTranslate('payments.single.ticketToSupport.header')}
        status='outline'
        type='submit'
        size='normal'
      />
    );
  }

  openModal = () => {
    MetricService.send({
      action: 'click',
      actionKey: 'payments.paymentCard.createTicket.button',
    });

    this.props.dispatch(
      openModal({
        modalId: 'CreateSupportTicket',
        customClassName: 'create-support-ticket',
        content: {
          transactionId: this.props.transactionId,
          operationId: this.props.operationId,
        },
      })
    );
  };
}

export default connect()(addTranslation(CreateSupportTicketButton));
