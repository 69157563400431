import reportTypes from './reportTypes';

const getChecked = (type) => {
  switch (type) {
    case reportTypes.payments:
      return `transaction_completed_at`;
    case reportTypes.operations:
      return 'operation_completed_at';
    case reportTypes.remittancePayments:
      return `transaction_completed_at`;
    case reportTypes.remittanceOperations:
      return 'operation_completed_at';
    case reportTypes.interchange:
      return 'operation_completed_at';
    default:
      return `${type}_created_at`;
  }
};

export default (reportTemplateIntervalBy, type, checkedInterval?) => {
  return reportTemplateIntervalBy[type].map((item) => {
    return {
      ...item,
      isChecked: checkedInterval
        ? checkedInterval === item.id
        : getChecked(type) === item.id,
    };
  });
};
