import React from 'react';
import Link from 'components/ui/link';
import Button from 'components/ui/button';

interface Props {
  id: string;
  createButtonText: string;
  backButtonText: string;
  backUrl: string;
  canCreate: boolean;
  isFileLoading: boolean;
  isFilePreview: boolean;
  isBatchPreview: boolean;
  onBackCallback?: () => void;
  onCreate: () => void;
}

export const MassPaymentFooter: React.VFC<Props> = (props) => {
  return (
    <>
      <Link
        url={props.backUrl}
        onClick={props.onBackCallback}
        className='ui-form-fields__back-btn ui-button ui-submitted-form__button ui-button_status-outline ui-button_size-normal'>
        {props.backButtonText}
      </Link>
      <Button
        type='submit'
        status='primary'
        text={props.createButtonText}
        id={`mass-${props.id}-button-submit`}
        disabled={
          (!props.canCreate && !props.isFilePreview) ||
          (props.isBatchPreview && !props.canCreate)
        }
        loading={props.isFileLoading}
        onClick={props.onCreate}
      />
    </>
  );
};
