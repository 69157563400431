import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';
import getStringWithSpecialSymbols from 'helpers/getStringWithSpecialSymbols';

export const getUsers = (params) => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Team_ListUsers,
          payload: { ...params },
        },
      ],
      isCancelable: true,
    })
    .then((data) => ({
      ...data,
      items:
        data.rows.map((user) => {
          user.userName = getStringWithSpecialSymbols(user.userName);
          return user;
        }) || [],
      totalRows: Infinity,
    }));
};

export const getActivities = (params) => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Team_ListActivities,
          payload: { ...params },
        },
      ],
      isCancelable: true,
    })
    .then((data) => ({ ...data, items: data.rows || [], totalRows: Infinity }));
};

export const createUser = (params) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Team_CreateUser,
        payload: { ...params },
      },
    ],
    showValidateNotification: false,
  });
};

export const updateUser = (params) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Team_UpdateUser,
        payload: { ...params },
      },
    ],
    showValidateNotification: false,
  });
};
export const disableUser = (userId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Team_DisableUser,
        payload: { userId },
      },
    ],
  });
};

export const enableUser = (userId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Team_EnableUser,
        payload: { userId },
      },
    ],
  });
};

export const deleteUser = (userId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Team_DeleteUser,
        payload: { userId },
      },
    ],
  });
};

export const getTeamUser = (userId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Team_GetUser,
        payload: { userId },
      },
    ],
  });
};
