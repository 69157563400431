import { generaTemplateTitle, prepareProjects } from './helpers';
import { IDictionary } from '../../../../../../../types/Analytics';
import { GetTranslate } from '../../../../../../../decorators/addTranslation';

const templateOperationConversion = (
  getTranslate: GetTranslate,
  projects: IDictionary
) => {
  return {
    chartParams: {
      chartName: generaTemplateTitle(
        'analytics.editForm.fromTemplate.title.conversion',
        getTranslate
      ),
      chartData: 'count',
      groupBy: 'operationStatus',
      groupByDate: 'all',
      chartType: 'pie chart',
    },
    filters: {
      projects: prepareProjects(projects),
    },
  };
};

export { templateOperationConversion };
