import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { replace } from 'lodash';

import { openModal } from 'actions/modal';
import Loader from 'components/ui/loader';
import Checkbox from 'components/ui/checkbox';
import { Column, Header, Row } from 'components/ui/table';
import FileTemplate from 'components/ui/fileTemplate';
import Button from 'components/ui/button';
import DynamicTable from 'components/ui/table/DynamicTable';
import { StoreProps } from 'store';

import { minBatchPreviewColumnsWidth } from 'components/formFields/components/massPayment/components/batchPreview/constants';
import BatchFile from 'types/BatchFile';
import './batchPreview.scss';

interface OwnProps {
  file: File | null;
  batch: BatchFile;
  isDeleting: boolean;
  resizableColumns: boolean;
  onDeleteRow: () => void;
  onSelectToDelete: (rowId) => void;
  idsToCheck: Record<number, boolean>;
  type: string;
}

type Props = OwnProps & StoreProps & IntlProps;

class BatchPreview extends PureComponent<Props> {
  static defaultProps: Readonly<Partial<OwnProps>> = {
    resizableColumns: true,
  };

  render() {
    const { isDeleting, resizableColumns } = this.props;
    return (
      <div className='batch-preview'>
        {this.renderPreviewHeader()}
        {/* @ts-ignore TODO: t.melashchenko, выручай! */}
        <DynamicTable
          minColumnsWidth={minBatchPreviewColumnsWidth}
          forbidResizeFor={['delete']}
          resizableColumns={resizableColumns}
          items={this.getFilteredBatch()}
          rowRender={this.renderRow}
          headerRender={this.renderTableHeader()}
        />
        {isDeleting && <Loader />}
      </div>
    );
  }

  renderPreviewHeader() {
    const {
      file,
      getTranslate,
      batch: { paymentOperationBatchId, totalCount, totalAmounts },
      type,
    }: any = this.props;

    return (
      <div className='batch-preview__header'>
        <div className='batch-preview__header-section'>
          <div className='batch-preview__header-block'>
            <div className='batch-preview__header-file'>
              {file && (
                <FileTemplate
                  title={file.name}
                  loadable={false}
                  tooltip={file.name}
                  disabled
                />
              )}
            </div>
          </div>
          <div className='batch-preview__header-block'>
            <div className='batch-preview__header-block-item'>
              <div className='batch-preview__header-col'>
                <div className='batch-preview__header-item'>
                  {getTranslate('payments.mass.header.payoutID')}
                </div>

                <div className='batch-preview__header-item'>
                  {getTranslate('payments.mass.header.requestCount')}
                </div>
              </div>

              <div className='batch-preview__header-col batch-preview__header-col_value'>
                <div className='batch-preview__header-item'>
                  {paymentOperationBatchId}
                </div>

                <div className='batch-preview__header-item'>
                  {totalCount &&
                    getTranslate(`payments.mass.${type}.preview.totalCount`, {
                      totalCount,
                    })}
                </div>
              </div>
            </div>
            {totalAmounts && (
              <div className='batch-preview__header-block-item batch-preview__header-block-item_right-align'>
                <div className='batch-preview__header-row'>
                  <div className='batch-preview__header-col'>
                    <div className='batch-preview__header-item'>
                      {getTranslate('payments.mass.header.totalSum')}
                    </div>
                  </div>

                  <div className='batch-preview__header-col batch-preview__header-col_value'>
                    <div className='batch-preview__header-item'>
                      {totalAmounts.map(({ amount, currency }) => {
                        return (
                          <div
                            className='batch-preview__header-value-item'
                            key={amount}>{`${amount} ${currency}`}</div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {totalCount > 1 && (
          <div className='batch-preview__header-section batch-preview__header-section_centred'>
            <div className='batch-preview__selected'>
              <span className='batch-preview__selected-amount'>
                {`${getTranslate('payments.mass.header.selectedToDelete')}: 
            ${this.getCheckedAmount()}`}
              </span>

              <Button
                status='outline'
                icon='im-Basket'
                customClass='batch-preview__btn-delete'
                onClick={this.confirmDelete}
                disabled={this.getCheckedAmount() <= 0}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  renderTableHeader() {
    const {
      batch: { columns, totalCount },
    } = this.props;

    return (
      <Header>
        <Column
          header={true}
          id='delete'
          content={
            totalCount > 1 && (
              <Checkbox
                id='delete'
                checked={this.getCheckedAmount() === totalCount}
                onChange={() =>
                  this.selectIds(
                    this.getCheckedAmount() !== totalCount
                      ? 'checkAll'
                      : 'uncheckAll'
                  )
                }
              />
            )
          }
          modifier={'delete'}
        />
        {columns.map((column) => {
          return (
            <Column
              key={column.attribute}
              id={column.attribute}
              modifier={replace(column.attribute, '.', '-')}
              content={column.title}
            />
          );
        })}
      </Header>
    );
  }

  renderRow = (data) => {
    const {
      batch: { totalCount },
      idsToCheck,
    } = this.props;
    const { id, columns } = data;
    const idToCheck = idsToCheck[id];
    return (
      <Row isActive={idToCheck}>
        <Column
          id='delete'
          content={
            totalCount > 1 && (
              <Checkbox
                id={id}
                checked={idToCheck}
                onChange={() => this.selectIds(data.id)}
              />
            )
          }
          modifier={'delete'}
        />
        {columns.map((column) => {
          return (
            <Column
              columnWidths={data.columnWidths}
              key={column.attribute}
              id={column.attribute}
              content={column.value}
              modifier={replace(column.attribute, '.', '-')}
              params={{ valueId: column.attribute, error: column.error }}
              data={column}
            />
          );
        })}
      </Row>
    );
  };

  getFilteredBatch = () => {
    const {
      batch: { rows },
    } = this.props;

    if (!rows.length) return [];

    const successRows: object[] = [];
    const errorRows: object[] = [];
    rows.forEach((item) => {
      if (item.isValid) {
        successRows.push(item);
      } else {
        errorRows.push(item);
      }
    });

    return errorRows.concat(successRows);
  };

  selectIds = (data) => {
    const { onSelectToDelete } = this.props;
    onSelectToDelete(data);
  };

  confirmDelete = () => {
    const {
      dispatch,
      batch: { totalCount },
    } = this.props;

    if (this.getCheckedAmount() === totalCount) {
      dispatch(
        openModal({
          modalId: 'Notification',
          content: {
            title: 'modals.breakDeleteFromBatch.title',
            text: 'modals.breakDeleteFromBatch.text',
          },
        })
      );
    } else {
      dispatch(
        openModal({
          modalId: 'Confirm',
          content: {
            text: 'modals.deleteFromBatch.text',
          },
          callback: this.callback,
        })
      );
    }
  };

  callback = (isConfirm: string) => {
    if (isConfirm) {
      const { onDeleteRow } = this.props;
      onDeleteRow();
    }
  };

  getCheckedAmount = () => {
    const { idsToCheck } = this.props;
    return Object.keys(idsToCheck).filter((item) => idsToCheck[item]).length;
  };
}

export default connect()(addTranslation(BatchPreview));
