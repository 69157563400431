import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import ModalTitle from '../components/ModalTitle';
import ModalContent from '../components/ModalContent';
import ModalFooter from '../components/ModalFooter';
import Button from 'components/ui/button';

interface Props extends IntlProps {
  callback: (data: any) => void;
  content: {
    title: string;
    text: string;
  };
}

class Notification extends PureComponent<Props> {
  render() {
    const {
      callback,
      content: { title, text },
      getTranslate,
    } = this.props;
    return (
      <>
        <ModalTitle customClass='modal__title_centered'>
          {getTranslate(title)}
        </ModalTitle>
        {text && (
          <ModalContent customClass='modal__content_centered'>
            {getTranslate(text)}
          </ModalContent>
        )}
        <ModalFooter>
          <Button
            text={getTranslate('common.ok.button')}
            status='primary'
            onClick={() => callback('yes')}
          />
        </ModalFooter>
      </>
    );
  }
}

export default addTranslation(Notification);
