import filtersKeys from 'constants/filters';
import SearchFilters from 'types/SearchFilters';

class FiltersStateFactory {
  static getInitialSearchFilters(): SearchFilters {
    return {
      [filtersKeys.paymentId]: '',
      [filtersKeys.projectId]: '',
      [filtersKeys.operationId]: '',
      [filtersKeys.customerId]: '',
      [filtersKeys.customerEmail]: '',
      [filtersKeys.accountNumber]: '',
      [filtersKeys.customerPhone]: '',
      [filtersKeys.cardHolderName]: '',
      [filtersKeys.arn]: '',
      [filtersKeys.rrn]: '',
    };
  }
}

export default FiltersStateFactory;
