import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import {
  activateTemplate,
  createNowReport,
  deactivateTemplate,
  deleteReport,
  getReports,
} from 'api/reports';
import { addListeners } from 'decorators/addListeners';
import { WithRouterProps } from 'decorators/withRouter';
import { updateDataItem } from 'actions/getData';
import { StoreProps } from 'store';

import TopPanelContainer from 'components/topPanel';
import DataListContainer from 'components/dataListContainer';
import { Column, Row } from 'components/ui/table';
import Icon from 'components/ui/icon';
import Loader from 'components/ui/loader';
import Switch from 'components/ui/switch';
import Button from 'components/ui/button';

import getConfigurationByName from 'selectors/getConfigurationByName';
import { AnyObject } from 'types/Common';
import tableNames from 'constants/tableNames';
import MetricService from 'helpers/metricService/MetricService';
import path from 'helpers/path';
import reportStatusList from '../reportStatusList';
import Messages from 'constants/rpcTypes';
import { minReportsTableColumnsWidth } from './constants';
import quickFiltersConfig from '../quickFiltersConfig';
import filtersKeys from 'constants/filters';
import { changeQuickFilter } from 'actions/quickFilters';
import './reportsList.scss';

interface ConnectedProps {
  configuration: AnyObject;
  quickFilters: AnyObject;
}

type Props = ConnectedProps & IntlProps & StoreProps & WithRouterProps;

interface State {
  isCreating: boolean;
}

@addListeners([
  Messages.ReportTemplate_Activate,
  Messages.ReportTemplate_Deactivate,
  Messages.Confirm_Accept,
])
class ReportsListContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isCreating: false,
    };
  }

  render() {
    const { isCreating } = this.state;

    return (
      <Fragment>
        <TopPanelContainer
          name={tableNames.reports}
          filtersConfig={quickFiltersConfig}
          isConfigurationButton={false}
          isFilterButton={false}
          button={
            <Button
              status='primary'
              onClick={() => this.props.history.push(path('/reports/create'))}
              text={this.props.getTranslate('reports.list.button.create')}
              customClass='reports-create-button'
            />
          }
          onChange={this.handleChange}
        />
        <div className='page-reports__table'>
          <DataListContainer
            minColumnsWidth={minReportsTableColumnsWidth}
            forbidResizeFor={['download', 'view']}
            tableName='reports'
            customClass='table-reports'
            apiRequest={getReports}
            rowRender={this.renderRow}
          />
        </div>
        {isCreating && <Loader />}
      </Fragment>
    );
  }

  renderRow = (data) => {
    const { configuration, getTranslate } = this.props;

    return (
      <Row
        customClass={data.isEmpty ? 'table-reports__empty-report-row' : ''}
        needAnimate={data.needAnimate}
        isBordered={false}>
        {configuration.map(({ id, valueType }) => {
          let content = data[id];
          if (id === 'view') {
            content = (
              <Button
                icon='im-Eye-open'
                iconSize={20}
                onClick={() =>
                  this.goToTemplatesBuilder(data.reportTemplateId, data.name)
                }
                status='transparent'
                size='inline'
              />
            );
          } else if (id === 'download') {
            content = this.getControls(data);
          } else if (id === 'reportType' && content) {
            content = getTranslate(`reports.list.reportType.values.${content}`);
          } else if (id === 'activate') {
            const isSingle = data.periodType === 'single';
            content = (
              <Switch
                id={data.reportTemplateId}
                checked={data.taskStatus === 'active' && !isSingle}
                disabled={isSingle}
                onChange={(event) => {
                  return this.toggleTemplate(
                    data.reportTemplateId,
                    event.target.checked
                  );
                }}
              />
            );
          } else if (id === 'delete') {
            content = (
              <Button
                icon='im-Basket'
                iconSize={16}
                onClick={() =>
                  this.deleteTemplate(data.reportTemplateTaskHistoryId)
                }
                status='transparent'
                size='inline'
              />
            );
          }
          return (
            <Column
              columnWidths={data.columnWidths}
              forbidResizeFor={data.forbidResizeFor}
              key={id}
              id={id}
              content={content || '—'}
              modifier={id}
              params={{
                valueType,
                labelPeriodText: 'reports.list.period.values',
                labelStatusText: 'reports.list.status.values',
              }}
            />
          );
        })}
      </Row>
    );
  };

  getControls = (data) => {
    if (data.isEmpty) {
      return (
        <Icon
          name='empty'
          size={16}
          className='table-reports__icon'
          tooltip={this.props.getTranslate('reports.list.report.empty')}
        />
      );
    } else if (data.status === reportStatusList.processing) {
      return (
        <Icon
          name='im-Ellipse'
          size={14}
          className='table-reports__icon rotating'
        />
      );
    } else if (data.isExpired || data.status === reportStatusList.error) {
      const disabled =
        data.taskStatus === 'inactive' && data.periodType !== 'single';

      return (
        <Button
          onClick={() => this.createReport(data.reportTemplateTaskHistoryId)}
          icon='im-Refill'
          iconSize={14}
          status='transparent'
          size='inline'
          customClass='table-reports__recreate-icon'
          disabled={disabled}
          tooltip={
            disabled
              ? this.props.getTranslate(
                  'reports.list.report.disabledRegularRefresh'
                )
              : ''
          }
        />
      );
    } else if (data.s3url) {
      return (
        <a
          download
          href={data.s3url}
          onClick={() =>
            // this workaround related to FF bug, which causes sendMetric request to fail due to simultaneous download request from a tag
            setTimeout(() => this.sendMetric('reports.registry.download'), 50)
          }
          className='table-reports__download-link'>
          <Icon
            name='im-Download1'
            size={16}
            className='table-reports__download-icon'
          />
        </a>
      );
    }

    return null;
  };

  async createReport(reportTemplateTaskHistoryId) {
    const { dispatch } = this.props;

    this.setState({ isCreating: true });

    try {
      const updatedReport = await createNowReport({
        reportTemplateTaskHistoryId,
      });
      dispatch(
        updateDataItem('reports', 'reportTemplateTaskHistoryId', updatedReport)
      );
    } finally {
      this.setState({ isCreating: false });
    }
  }

  sendMetric = (key) => {
    MetricService.send({
      action: 'click',
      actionKey: key,
    });
  };

  async deleteTemplate(reportTemplateTaskHistoryId) {
    try {
      await deleteReport({ reportTemplateTaskHistoryId });
    } catch (e) {
      console.error('report deleting error', e);
    }
  }

  async toggleTemplate(reportTemplateId, isChecked: boolean) {
    const action = isChecked ? activateTemplate : deactivateTemplate;

    try {
      await action({
        reportTemplateId,
      });
    } catch (e) {}
  }

  goToTemplatesBuilder = (reportTemplateId, reportName) => {
    const { history } = this.props;
    history.push(path(`/reports/${reportTemplateId}?name=${reportName}`));
  };

  handleChange = (key, value) => {
    const { quickFilters, dispatch } = this.props;

    if (key === filtersKeys.reportPeriod) {
      if (quickFilters[filtersKeys.reportRegularity] !== 'regular') {
        dispatch(
          changeQuickFilter(
            tableNames.reports,
            filtersKeys.reportRegularity,
            'regular'
          )
        );
      }
    } else if (key === filtersKeys.reportRegularity && value === 'single') {
      dispatch(
        changeQuickFilter(tableNames.reports, filtersKeys.reportPeriod, [])
      );
    }
  };

  onEvent = ({ name, data: { payload } }) => {
    switch (name) {
      case Messages.ReportTemplate_Activate:
      case Messages.ReportTemplate_Deactivate:
        this.sendMetric('reports.registry.activation');
        break;
      case Messages.Confirm_Accept:
        if (
          payload.unblockedTypes.includes(
            Messages.ReportTemplateTaskHistory_Delete
          )
        ) {
          this.sendMetric('reports.registry.delete.yes');
        }
        break;
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  configuration: getConfigurationByName(state, tableNames.reports),
  quickFilters: state.quickFilters[tableNames.reports],
});

export default withRouter(
  connect(mapStateToProps)(addTranslation(ReportsListContainer))
);
