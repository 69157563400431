export const MAX_NUMBER_OF_FILE_TO_UPLOAD = 15;
export const MAX_FILE_SIZE_MB = 20;

// The only maf/upload-document accepted files are listed below:
export const ACCEPTED_FILES_CONFIG = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'application/pdf': ['.pdf'],
  'application/msword': ['.docx', '.doc'],
  'application/zip': ['.zip'],
  'application/x-rar-compressed': ['.rar'],
};
