import React, { PureComponent } from 'react';
import { IntlProps, addTranslation } from 'decorators/addTranslation';
import classNames from 'classnames';

import Button from 'components/ui/button';
import Panel from 'components/ui/panel';
import ConfigurationContainer from './ConfigurationContainer';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import Messages from 'constants/rpcTypes';
import permissionReasons from 'constants/permissionReasons';
import { toggleOpenClass } from 'components/quickFiltersContainer/helper';

interface OwnProps {
  name: string;
  customButtonClass?: string;
  isDisabled?: boolean;
}

type Props = OwnProps & IntlProps & WithPermissions;

interface State {
  showConfigPanel: any;
}

class ConfigurationButton extends PureComponent<Props, State> {
  static defaultProps: Readonly<Partial<OwnProps>> = {
    isDisabled: false,
  };

  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      showConfigPanel: false,
    };
  }

  render() {
    const { showConfigPanel } = this.state;
    const { name, customButtonClass, isDisabled, getTranslate } = this.props;

    if (!this.canRenderButton()) return null;

    return (
      <>
        <Button
          id='settings'
          status='light'
          onClick={this.togglePanel}
          icon='im-Configurator-2'
          disabled={isDisabled}
          customClass={classNames(
            'button-open-configuration ui-button_no-border',
            customButtonClass,
            {
              'button-open_active': showConfigPanel,
            }
          )}
        />
        <Panel
          id='configuration-panel'
          isOpened={showConfigPanel}
          title={getTranslate('configuration.title')}
          customClass='ui-panel_fixed ui-panel_configuration'
          excludeFromCloseTrigger={['.button-open-configuration']}
          onClose={this.closePanel}
          modern>
          <ConfigurationContainer onClose={this.closePanel} name={name} />
        </Panel>
      </>
    );
  }

  canRenderButton(): boolean {
    const { isEnabled, isDisabledByReason } = this.props;
    return (
      isEnabled(Messages.UserConfig_Set) ||
      isDisabledByReason(
        Messages.UserConfig_Set,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      )
    );
  }

  togglePanel = (): void => {
    this.setState(
      (state) => {
        return {
          showConfigPanel: !state.showConfigPanel,
        };
      },
      () => toggleOpenClass('#settings', this.state.showConfigPanel)
    );
  };

  closePanel = () => {
    this.setState(
      {
        showConfigPanel: false,
      },
      () => toggleOpenClass('#settings', false)
    );
  };
}

export default addTranslation(addPermissions(ConfigurationButton));
