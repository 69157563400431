import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { StoreProps } from 'store';
import { confirmVerify } from 'api/tgBot';
import ConfirmTelegram from './ConfirmTelegram';
import { AnyObject } from 'types/Common';

interface OwnProps {
  content: {
    tgmId: string;
  };
}

type Props = OwnProps & IntlProps & StoreProps;

interface State {
  isLoading: boolean;
  validationErrors: AnyObject;
}

class ConfirmTelegramContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      validationErrors: {},
    };
  }

  render() {
    const { isLoading, validationErrors } = this.state;

    return (
      <ConfirmTelegram
        isLoading={isLoading}
        validationErrors={validationErrors}
        onSubmit={(code) => this.sendForm(code)}
      />
    );
  }

  async sendForm(publicKey: string) {
    const {
      content: { tgmId },
    } = this.props;
    try {
      await confirmVerify({ publicKey, tgmId });
    } catch ({ payload }) {
      if (payload?.validationErrors) {
        this.setState({ validationErrors: payload.validationErrors });
      }
    }
  }
}

export default connect()(addTranslation(ConfirmTelegramContainer));
