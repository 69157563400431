import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

const initialState: any = null;

const configuration = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.INIT_ALL_CONFIGURATION:
      return {
        ...state,
        ...payload.list,
      };

    case ACTIONS.UPDATE_CONFIGURATION:
      return {
        ...state,
        ...payload,
      };
    case ACTIONS.APPLY_CONFIGURATION:
      return {
        ...state,
        // ...state[payload.name] - для сохранения initialConfig
        // ...payload.body - изменения в табах конфигуратора
        [payload.name]: [...payload.body],
      };
    case ACTIONS.CHANGE_FIXED_CONFIGURATION: {
      const columns = [...state[payload.name]];
      const newItem = { ...columns[0], fixed: !columns[0].fixed };
      columns.splice(0, 1, newItem);
      return { ...state, [payload.name]: columns };
    }
    default:
      return state;
  }
};

export default configuration;
