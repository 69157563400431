import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import ModalTitle from '../../components/ModalTitle';
import ModalContent from '../../components/ModalContent';
import ModalFooter from '../../components/ModalFooter';
import Button from 'components/ui/button';
import Icon from 'components/ui/icon';
import './mafConfirm.scss';

interface Props extends IntlProps {
  callback: (data: any) => void;
  content: {
    title?: string;
    text?: string;
    buttonYes?: string;
    buttonNo?: string;
  };
  invertButtons?: boolean;
}

class MafConfirm extends PureComponent<Props> {
  render() {
    const {
      getTranslate,
      callback,
      content: { title, text, buttonYes, buttonNo },
      invertButtons,
    } = this.props;
    const yesButtonText = buttonYes
      ? this.getText(buttonYes)
      : getTranslate('common.yes.button');
    const noButtonText = buttonNo
      ? this.getText(buttonNo)
      : getTranslate('common.no.button');

    return (
      <>
        <div className='modal__content_centered modal__icon'>
          <Icon name='im-Info-Hint' size={40} />
        </div>
        <ModalTitle customClass='modal__content_centered'>
          {title ? this.getText(title) : getTranslate('modals.common.title')}
        </ModalTitle>
        <ModalContent customClass='modal__content_centered'>
          {text && this.getText(text)}
        </ModalContent>
        <ModalFooter>
          <Button
            text={invertButtons ? yesButtonText : noButtonText}
            status='outline'
            size='normal'
            onClick={() => callback(invertButtons)}
          />
          <Button
            text={invertButtons ? noButtonText : yesButtonText}
            status='primary'
            size='normal'
            onClick={() => callback(!invertButtons)}
          />
        </ModalFooter>
      </>
    );
  }

  getText = (text) => {
    const { getTranslate } = this.props;
    return /\w\.\w/gi.test(text) ? getTranslate(text) : text;
  };
}

export default addTranslation(MafConfirm);
