export const inputFields = [
  {
    id: 'userId',
    type: 'number',
    label: 'admin.quickfilters.userId.label',
    placeholder: 'admin.quickfilters.userId.placeholder',
  },
  {
    id: 'email',
    label: 'admin.quickfilters.email.label',
    placeholder: 'admin.quickfilters.email.placeholder',
  },
];

export const userPropertiesFilterItems = [
  {
    valueProp: 'userStatusAdmin',
    filterProp: 'status',
    labelId: 'admin.quickfilters.status.label',
  },
  {
    valueProp: 'roleToSee',
    filterProp: 'role',
    labelId: 'admin.quickfilters.role.label',
  },
];

type ConnectedFiltersType = {
  valueProp: string;
  filterProp: string;
  labelId: string;
  connectedTo: string[];
  hideForQuickFilters?: boolean;
};
export const connectedFilters: ConnectedFiltersType[] = [
  {
    valueProp: 'merchant',
    filterProp: 'merchantId',
    labelId: 'admin.quickfilters.merchant.label',
    connectedTo: ['projectId', 'legalEntities'],
  },
  {
    valueProp: 'project',
    filterProp: 'projectId',
    labelId: 'admin.quickfilters.project.label',
    connectedTo: ['merchantId'],
  },
  {
    valueProp: 'legalEntity',
    filterProp: 'legalEntities',
    labelId: 'admin.legalEntity.label',
    connectedTo: ['merchantId'],
    hideForQuickFilters: true,
  },
];
