import Utils from 'helpers/Utils';
import { RowItem, TranslationVariant } from './LcManageTypes';

class LcManageHelpers {
  static isTesting(data: RowItem): boolean {
    return Boolean(
      data &&
        Array.isArray(data.variants) &&
        data.variants.find((v) => v.status === 'testing')
    );
  }

  static getTemplate(data: RowItem): TranslationVariant | undefined {
    if (data && Array.isArray(data.variants)) {
      return data.variants.find((v) => v.isTemplate);
    }
  }

  static createItem(lexeme) {
    const result = { ...lexeme };
    result.variants = [];
    if (lexeme.released) {
      result.variants.push({ ...lexeme.released });
    }
    if (lexeme.staging) {
      result.variants.push({ ...lexeme.staging });
    }
    if (!lexeme.customId) {
      result.customId = Utils.getHash();
    }
    return result;
  }
}

export default LcManageHelpers;
