import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import DynamicTable from 'components/ui/table/DynamicTable';
import { Column, Row } from 'components/ui/table';
import tableNames from 'constants/tableNames';
import ItemConfiguration from 'types/ItemConfiguration';
import {
  RecurringSettingsHistory,
  RecurringSort,
} from 'pages/recurring/card/settings/RecurringSettingsTypes';
import './settingsHistory.scss';

interface Props extends IntlProps {
  list: RecurringSettingsHistory | null;
  sort: RecurringSort;
  configuration: ItemConfiguration[];
  onSort: (sort) => void;
}

class SettingsHistory extends PureComponent<Props> {
  render() {
    const { list, sort, onSort } = this.props;
    if (!list) return null;

    return (
      <div className='recurring-settings-history'>
        {/* @ts-ignore TODO: t.melashchenko, выручай! */}
        <DynamicTable
          resizableColumns={!!list.length}
          items={list}
          outerSort={sort}
          onSort={onSort}
          rowRender={this.renderRow}
          tableName={tableNames.subscriptionPaymentsSettingsHistory}
          noContentText='subscriptions.history.empty.txt'
          customClass='recurring-settings-history__table'
        />
      </div>
    );
  }

  renderRow = (data) => {
    const { configuration } = this.props;
    return (
      <Row>
        {configuration.map((config) => {
          const { id } = config;
          const columnParams = {
            ...config,
            valueId: config.id,
            valueType: config.valueType,
            labelPeriodText: 'subscriptions.list.period.generalValues',
          };

          return (
            <Column
              columnWidths={data.columnWidths}
              key={id}
              id={id}
              content={data[id] || '—'}
              customClass={`ui-table__column_${id}`}
              params={columnParams}
            />
          );
        })}
      </Row>
    );
  };
}

export default addTranslation(SettingsHistory);
