import BaseMessageHandler from '../BaseMessageHandler';
import { closeModal } from 'actions/modal';
import Token from 'helpers/Token';
import { createAction } from 'helpers/redux';
import path from 'helpers/path';
import LocalStorage from 'helpers/LocalStorage';
import getStringWithSpecialSymbols from 'helpers/getStringWithSpecialSymbols';
import ACTIONS from 'constants/actionTypes';
import CONFIG from 'config';

export default class Auth_Login extends BaseMessageHandler {
  success = () => {
    const { dispatch, message, history, response } = this;
    const { payload } = message;

    const name = getStringWithSpecialSymbols(message.payload.user.name);

    dispatch(closeModal());
    dispatch(
      createAction({
        type: ACTIONS.LOGIN_USER,
        payload: {
          user: {
            ...payload.user,
            name,
            jwt: payload.jwt,
          },
        },
      })
    );
    LocalStorage.remove('nextPing');
    Token.setTokens({ authToken: response.data.authToken });

    const { state } = history.location;
    const autoLogoutEmail = LocalStorage.get('autoLogoutEmail');
    const goToFromExpired = LocalStorage.get('goToFromExpired');

    if (
      state?.from &&
      state.from !== '/login' &&
      state.from !== '/login-as-support' &&
      autoLogoutEmail &&
      autoLogoutEmail === payload.user.email
    ) {
      history.push(path(state.from));
      autoLogoutEmail && LocalStorage.remove('autoLogoutEmail');
    } else if (goToFromExpired) {
      history.push(goToFromExpired);
      LocalStorage.remove('goToFromExpired');
    } else {
      history.push(path(CONFIG.DEFAULT_URL));
    }

    if (LocalStorage.get('noAuthLang') !== payload.user.interfaceLang) {
      LocalStorage.set('noAuthLang', payload.user.interfaceLang);
    }
  };

  validation = () => {
    const { dispatch, message } = this;
    const { payload } = message;

    dispatch(
      createAction({
        type: ACTIONS.SET_USER_ERROR,
        payload,
      })
    );
  };
}
