import React, { PureComponent } from 'react';

import PageTemplate from 'components/pageTemplate/PageTemplate';
import SavedEntitiesContainer from 'components/savedEntities';
import ReportsList from './list';
import ReportsTemplatesBuilder from './templatesBuilder';
import LocalStorage from 'helpers/LocalStorage';
import savedEntities from 'constants/savedEntities';
import './reportsPage.scss';

interface Props {
  id?: string;
  isNewTab: boolean;
  isTemplatesBuilder: boolean;
}

interface State {
  renderContent: boolean;
}

class ReportsPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      renderContent: true,
    };

    ReportsPage.deleteOldReports();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { id } = this.props;
    if (prevProps.id && id && prevProps.id !== id) {
      this.setState(
        {
          renderContent: false,
        },
        () => {
          this.setState({ renderContent: true });
        }
      );
    }
  }

  render() {
    return (
      <PageTemplate.Main customClass='page-reports'>
        <PageTemplate.Entities>
          <SavedEntitiesContainer
            entityKey={savedEntities.reports}
            isNew={this.props.isNewTab}
          />
        </PageTemplate.Entities>
        {this.renderContent()}
      </PageTemplate.Main>
    );
  }

  renderContent() {
    const { isTemplatesBuilder } = this.props;
    const { renderContent } = this.state;

    if (!renderContent) return null;

    if (isTemplatesBuilder) {
      return <ReportsTemplatesBuilder />;
    }

    return (
      <PageTemplate.Container>
        <ReportsList />
      </PageTemplate.Container>
    );
  }

  static deleteOldReports() {
    const savedTabs = LocalStorage.get(`savedReports`);
    if (
      Array.isArray(savedTabs) &&
      savedTabs.find(({ id }) => id?.includes('templates'))
    ) {
      LocalStorage.remove(`savedReports`);
    }
  }
}

export default ReportsPage;
