import React from 'react';
import InfoIcon from 'components/ui/infoIcon';
import FileTemplate from 'components/ui/fileTemplate';
import Env from 'helpers/Env';
import { Template, TemplateDictionary } from '../../../../../types/MassRequest';

interface Props {
  type: string,
  title: string;
  templatesText: string;
  infoText?: string;
  templates: TemplateDictionary<Template> | Array<Template>;
}

const groupTemplates = (templates) => templates.map((template) =>
  <div className="ui-form-fields__item" key={template.title}>
    <FileTemplate
      title={template.title}
      url={Env.getApiUrl() + template.url}
    />
  </div>,
);
export const MassPaymentGeneralColumn: React.VFC<Props> = (props) => {

  const { templates, type, title, templatesText, infoText } = props;

  const isArrayTemplates = Array.isArray(templates);

  return (
    <div key={title} className={`mass-${type}`}>
      <div className="ui-form-fields__col-title">{title}</div>
      <div className="ui-form-fields__text ui-form-fields__text_subtext">
        {templatesText}{' '}
        {infoText && (
          <InfoIcon tooltip={infoText} dataPlace="top" modern />
        )}
      </div>
      {isArrayTemplates === false ? Object.keys(templates).map((groupName) => {
        return (
          <div key={title + '-' + groupName} className={`mass-${type}-group`}>
            <div className="ui-form-fields__col-subtitle">{groupName}</div>
            <div className={`mass-${type}-templates`}>
              {groupTemplates(templates[groupName])}
            </div>
          </div>
        );
      }) : groupTemplates(templates)}
    </div>
  );
};

