import { pickBy } from 'lodash';
import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';
import LocalStorage from 'helpers/LocalStorage';
import { AnyObject } from 'types/Common';
import SavedEntity from 'types/savedEntity';
import savedEntities from 'constants/savedEntities';
import { getTabList, updateTabList } from 'api/tabs';
import Repository from 'helpers/Repository';

const getEntityName = (entityKey) => {
  return `saved${entityKey.slice(0, 1).toUpperCase() + entityKey.slice(1)}`;
};

const syncEntity = ({
  entityKey,
  items,
}: {
  entityKey: keyof typeof savedEntities;
  items: SavedEntity[];
}) => {
  const { isLoginAsSupport } = Repository.get('store').getState().user;
  if (isLoginAsSupport) return;

  updateTabList({
    category: entityKey,
    data: items?.map((item) => {
      return {
        id: item.id,
        caption: item.caption,
        urlParams: item.urlParams,
        hiddenParams: item.hiddenParams,
      };
    }),
  });
};

const migrateTabs = (entityKey, entities) => {
  const entityName = getEntityName(entityKey);
  const localStorageTabs = LocalStorage.get(entityName);
  if (!localStorageTabs?.length) return false;

  const uniqTabs = localStorageTabs.filter(
    (tab) => tab.id !== entities.find((item) => item.id === tab.id)?.id
  );

  const mergedTabs = uniqTabs.concat(entities);
  syncEntity({ entityKey, items: mergedTabs });
  LocalStorage.remove(entityName);
  return mergedTabs;
};

export const getSavedEntities = ({
  entityKey,
  canGetFromStorage = true,
}: {
  entityKey: keyof typeof savedEntities;
  canGetFromStorage?: boolean;
}) => {
  let items: any[] = [];

  if (canGetFromStorage) {
    return (dispatch) => {
      const callback = (data) => {
        items = data || [];

        const mergedTabs = migrateTabs(entityKey, items);

        if (mergedTabs) {
          items = mergedTabs;
        }

        dispatch(
          createAction({
            type: ACTIONS.GET_SAVED_ENTITIES,
            payload: {
              entityKey,
              items,
            },
          })
        );
      };

      getTabList({ category: entityKey }).then(callback);
    };
  }
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.GET_SAVED_ENTITIES,
        payload: {
          entityKey,
          items,
        },
      })
    );
  };
};

export const addEntityToSaved = ({
  entityKey,
  id,
  caption,
  urlParams = {},
  hiddenParams = {},
}: {
  entityKey: keyof typeof savedEntities;
  id: string;
  caption?: string;
  urlParams?: AnyObject;
  hiddenParams?: AnyObject;
}) => {
  const { isLoginAsSupport } = Repository.get('store').getState().user;
  return (dispatch, getState) => {
    dispatch(
      createAction({
        type: ACTIONS.ADD_ENTITY_TO_SAVED,
        payload: {
          entityKey,
          id,
          caption,
          urlParams,
          hiddenParams: pickBy({
            ...hiddenParams,
            isSupport: isLoginAsSupport,
          }),
        },
      })
    );
    syncEntity({
      entityKey,
      items: getState().savedEntities[entityKey]?.items,
    });
  };
};

export const updateEntity = ({
  entityKey,
  id,
  fields = {},
}: {
  entityKey: string;
  id: string;
  fields?: AnyObject;
}) => {
  //записываем данные в редакс, на бэк не отправляем
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPDATE_SAVED_ENTITY,
        payload: {
          entityKey,
          id,
          fields,
        },
      })
    );
  };
};

export const reloadEntity = ({
  entityKey,
  id,
  fields = {},
}: {
  entityKey: string;
  id: string;
  fields?: AnyObject;
}) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RELOAD_SAVED_ENTITY,
        payload: {
          entityKey,
          id,
          fields,
        },
      })
    );
  };
};

export const updateEntityParams = ({
  entityKey,
  id,
  params,
}: {
  entityKey: keyof typeof savedEntities;
  id: string;
  params: AnyObject;
}) => {
  return (dispatch, getState) => {
    const items = getState().savedEntities[entityKey].items.map((item) => {
      if (item.id === id) {
        return { ...item, ...params };
      }
      return item;
    });

    dispatch(
      createAction({
        type: ACTIONS.UPDATE_ALL_SAVED_ENTITY,
        payload: {
          entityKey,
          id,
          items,
        },
      })
    );

    syncEntity({ entityKey, items });
  };
};

export const removeEntityFromSaved = ({
  entityKey,
  id,
}: {
  entityKey: keyof typeof savedEntities;
  id: string;
}) => {
  return (dispatch, getState) => {
    dispatch(
      createAction({
        type: ACTIONS.REMOVE_ENTITY_FROM_SAVED,
        payload: {
          entityKey,
          id,
        },
      })
    );
    syncEntity({
      entityKey,
      items: getState().savedEntities[entityKey].items,
    });
  };
};

export const removeAllEntities = ({
  entityKey,
}: {
  entityKey: keyof typeof savedEntities;
}) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.REMOVE_ALL_ENTITIES,
        payload: {
          entityKey,
        },
      })
    );
    syncEntity({ entityKey, items: [] });
  };
};
