import React, { PureComponent } from 'react';
import Button from 'components/ui/button';
import './topPanel.scss';

interface Props {
  onResetFilters: () => void;
}

class TopPanel extends PureComponent<Props> {
  render() {
    const { onResetFilters } = this.props;

    return (
      <div className='top-panel-icons'>
        <div className='top-panel-icons__inner'>
          <div className='top-panel-icons__left'>{this.props.children}</div>
          <div className='top-panel-icons__right'>
            <div className='top-panel-icons__item'>
              <Button
                status='light'
                id='reload'
                icon='im-Reset-filter'
                onClick={onResetFilters}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopPanel;
