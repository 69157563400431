import React, { PureComponent } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import classNames from 'classnames';
import scrollDirections from 'constants/scrollDirections';

import './customScroll.scss';

interface Props {
  customHeight?: any;
  customWidth?: any;
  getRef?: any;
  noScrollY?: boolean;
  hideHorizontal?: boolean;
  fixedColumnWidth?: number;
  hideVertical?: boolean;
  initialScrollTop?: number;
  needResetScrollTop?: boolean;
  onEnd?: (values?) => void;
  onScroll?: (event) => void;
  onScrollStop?: (values) => void;
  onUpdate?: (values) => void;
  scrollThreshold?: number;
  recalculateHeightOnResize?: boolean;
}

interface State {
  clientHeight: number;
}

class CustomScrollbar extends PureComponent<Props, State> {
  contentRef;
  scrollbarRef;
  verticalScroll = 0;
  horizontalScroll = 0;

  static defaultProps = {
    hideHorizontal: false,
    hideVertical: false,
    needResetScrollTop: false,
    initialScrollTop: 0,
    scrollThreshold: 200,
    onScroll: () => null,
  };

  constructor(props) {
    super(props);

    this.state = {
      clientHeight: 0,
    };
  }

  componentDidMount() {
    const { recalculateHeightOnResize } = this.props;
    this.calcHeight();
    if (recalculateHeightOnResize) {
      this.attachEvents();
    }
  }

  componentWillUnmount() {
    this.detachEvents();
  }

  componentDidUpdate() {
    if (this.contentRef?.clientHeight !== this.state.clientHeight) {
      this.calcHeight();
    }
  }

  handleHorizontalScroll = (ref, fixedColumnWidth) => {
    if (!ref) {
      return;
    }
    const setStyle = (attr, value) =>
      ref.style.setProperty(attr, value, 'important');

    if (fixedColumnWidth) {
      setStyle('width', `calc(100% - ${fixedColumnWidth}px)`);
      setStyle('left', `${fixedColumnWidth}px`);
    } else {
      setStyle('width', '100%');
      setStyle('left', 0);
    }
  };

  render() {
    const {
      fixedColumnWidth,
      hideHorizontal,
      hideVertical,
      noScrollY,
      children,
      onScroll,
      getRef,
    } = this.props;

    return (
      <div className='ui-scrollbar'>
        <Scrollbar
          noScrollY={noScrollY}
          trackYProps={{
            renderer: (props) => {
              const { elementRef, ...restProps } = props;
              return (
                <span
                  {...restProps}
                  ref={elementRef}
                  className={classNames('ui-scrollbar__track-vertical', {
                    'ui-scrollbar__track_hidden': hideVertical,
                  })}
                />
              );
            },
          }}
          trackXProps={{
            renderer: (props) => {
              const { elementRef, ...restProps } = props;
              return (
                <span
                  {...restProps}
                  ref={(ref) => {
                    if (elementRef) {
                      elementRef(ref as HTMLDivElement);
                    }
                    this.handleHorizontalScroll(ref, fixedColumnWidth);
                  }}
                  className={classNames('ui-scrollbar__track-horizontal', {
                    'ui-scrollbar__track_hidden': hideHorizontal,
                  })}
                />
              );
            },
          }}
          thumbYProps={{
            renderer: (props) => {
              const { elementRef, ...restProps } = props;
              return (
                <span
                  {...restProps}
                  ref={elementRef}
                  className='ui-scrollbar__thumb ui-scrollbar__thumb-vertical fadeIn'
                />
              );
            },
          }}
          thumbXProps={{
            renderer: (props) => {
              const { elementRef, ...restProps } = props;
              return (
                <span
                  {...restProps}
                  ref={elementRef}
                  className='ui-scrollbar__thumb ui-scrollbar__thumb-horizontal fadeIn'
                />
              );
            },
          }}
          ref={(el) => {
            this.scrollbarRef = el;
            getRef && getRef(el);
          }}
          onUpdate={this.onUpdate}
          onScroll={onScroll}
          onScrollStop={this.onStop}
          style={this.styleInit()}>
          <div
            ref={(ref) => {
              this.contentRef = ref;
            }}>
            {children}
          </div>
        </Scrollbar>
      </div>
    );
  }

  calcHeight = () => {
    const { scrollbarRef } = this;
    const { initialScrollTop, needResetScrollTop } = this.props;
    const clientHeight = this.contentRef?.clientHeight || 0;

    if (scrollbarRef && needResetScrollTop) {
      scrollbarRef.scrollTop = initialScrollTop;
    }

    this.setState({ clientHeight });
  };

  attachEvents = () => {
    window.addEventListener('resize', this.calcHeight);
  };

  detachEvents = () => {
    window.removeEventListener('resize', this.calcHeight);
  };

  styleInit = () => {
    const { customHeight, customWidth } = this.props;
    const style = {
      width: '100%',
      height: this.state.clientHeight || '100%',
      minHeight: '100%',
    };

    if (customWidth) {
      style['minWidth'] = customWidth;
    }

    if (customHeight) {
      style['minHeight'] = customHeight;
    }

    return style;
  };

  onUpdate = (values) => {
    const { scrollThreshold, onUpdate, onEnd } = this.props;

    if (typeof onUpdate === 'function') {
      onUpdate(values);
    }

    if (typeof onEnd === 'function') {
      const { scrollTop, scrollHeight, clientHeight } = values;

      if (scrollTop + scrollThreshold + clientHeight > scrollHeight) {
        onEnd(values);
      }
    }
  };

  onStop = (values) => {
    const { scrollbarRef } = this;
    const { onScrollStop } = this.props;

    if (scrollbarRef) {
      onScrollStop &&
        onScrollStop({ ...values, direction: this.getDirection(values) });
    }
  };

  getDirection = (values): keyof typeof scrollDirections | undefined => {
    if (this.verticalScroll !== values.scrollTop) {
      this.verticalScroll = values.scrollTop;
      return 'vertical';
    } else if (this.horizontalScroll !== values.scrollLeft) {
      this.horizontalScroll = values.scrollLeft;
      return 'horizontal';
    }
  };
}

export default CustomScrollbar;
