import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { IntlProps, addTranslation } from 'decorators/addTranslation';
import LabelStatus from 'components/ui/labelStatus';
import Mapper from 'helpers/Mapper';
import dataMapper from './operationItemDataMapper';
import { AnyObject } from 'types/Common';
import './operationItem.scss';
import Checkbox from '../../ui/checkbox';
import ConditionalWrapper from '../../ui/conditionalWrapper';
import CopyToClipboard from '../../ui/copyToClipboard';

interface Props extends IntlProps {
  data: AnyObject;
  isCurrent: boolean;
  onSelect: (operationId: string) => void;
  onCheckboxChange?: (id: string, isSelected: boolean) => void;
  customClass?: string;
  isDisabled?: boolean;
  isCheckboxDisabled?: boolean;
  type?: 'chargeback' | 'operation';
}

class OperationItem extends PureComponent<Props> {
  static defaultProps = {
    type: 'operation' as const,
  };

  render() {
    const { isCurrent, onSelect, type, isDisabled } = this.props;

    const data = dataMapper(this.props.data, type);
    const isChargeback = type === 'chargeback';

    return (
      <div
        className={classNames('operation-item', {
          'operation-item_active': isCurrent,
          'operation-item_disabled': isDisabled,
          'operation-item_chargeback': isChargeback,
        })}
        id={`operation-item-${data.id}`}
        onClick={(e) => {
          e.stopPropagation();
          onSelect(data.id);
        }}>
        {isChargeback && (
          <div className='operation-item__col'>
            <Checkbox
              id={data.value}
              customClass='operation-item__checkbox'
              checked={data.isSelected}
              disabled={this.props.isCheckboxDisabled}
              onChange={(e) => {
                e.persist();
                e.nativeEvent.stopImmediatePropagation();
                e.stopPropagation();
                this.props.onCheckboxChange?.(data.id, e.target.checked);
              }}
            />
          </div>
        )}
        <ConditionalWrapper
          condition={isChargeback}
          wrap={(children) => (
            <div className='operation-item__col'>{children}</div>
          )}>
          {!isChargeback && (
            <div className='operation-item__line operation-item__line_type'>
              {data.operationType}
            </div>
          )}
          <div className='operation-item__line operation-item__line_title'>
            {data.amount}{' '}
            <span className='operation-item__currency'>{data.currency}</span>
          </div>
          <div className='operation-item__line operation-item__line_label'>
            <LabelStatus
              text={data.status}
              status={Mapper.getStatus(data.status)}
              customClass='operation-item__label-status'
            />
            {Boolean(data.fraud) && (
              <LabelStatus
                text='fraud'
                theme='bordered'
                status={Mapper.getStatus('fraud')}
                customClass='payment-operation-item__label payment-operation-item__label_fraud'
              />
            )}
            {Boolean(data.isChargeback) && (
              <LabelStatus
                text='chargeback'
                theme='bordered'
                status={Mapper.getStatus('chargeback')}
                customClass='payment-operation-item__label payment-operation-item__label_chargeback'
              />
            )}
          </div>
          <div className='operation-item__line operation-item__line_footer'>
            <span>
              <span className='operation-item__label'>{data.label}:</span>{' '}
              <span className='operation-item__value'>
                {isChargeback ? data.value : data.id}
              </span>
              {isChargeback && (
                <CopyToClipboard
                  text={data.value}
                  customClass='operation-item__copy'
                />
              )}
            </span>
            {!isChargeback && (
              <span className='operation-item__light'>{data.updatedDate}</span>
            )}
          </div>
        </ConditionalWrapper>
      </div>
    );
  }
}

export default addTranslation(OperationItem);
