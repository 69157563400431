import React, { ChangeEvent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Countdown, { zeroPad } from 'react-countdown';
import classNames from 'classnames';

import ReactCountryFlag from 'react-country-flag';
import ModalTitle from '../../components/ModalTitle';
import Button from 'components/ui/button';
import Loader from 'components/ui/loader';
import Input from 'components/ui/input';
import Form from 'components/ui/form';
import ConfirmSlider from './confirmSlider';

import { confirmationTypes } from './types';
import { AnyObject } from 'types/Common';
import './confirm2FA.scss';

interface Props extends IntlProps {
  content: {
    type: confirmationTypes;
    qrImageBase64: string;
    verification: {
      codeResendSinceTimeLeft: number;
      contactPhoneNumber: string;
      contactPhoneCountry: string;
    };
  };
  sendCode: (isSuccess?: boolean) => void;
  onComplete: () => void;
  onChangeCode: (event: ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (event: ChangeEvent<HTMLInputElement>) => void;
  canResend: boolean;
  code: string;
  isLoading: boolean;
  validationErrors: AnyObject;
  countDown: number;
}

class Confirm2FA extends React.PureComponent<Props> {
  render() {
    const { getTranslate } = this.props;
    return (
      <>
        <ModalTitle customClass={'modal__title_left-align'}>
          <p>{getTranslate('auth.login.2faActivation.header')}</p>
        </ModalTitle>
        <div className='confirm-code'>
          {this.renderInfo()}
          {this.renderForm()}
        </div>
      </>
    );
  }

  renderInfo = () => {
    const {
      content: { verification, type, qrImageBase64 },
      getTranslate,
    } = this.props;

    return (
      <div
        className={classNames('confirm-code__info-block', {
          'confirm-code__info-block_qr': !verification?.contactPhoneNumber,
        })}>
        {verification?.contactPhoneNumber && (
          <div className='confirm-code__phone confirm-code__container'>
            {getTranslate('auth.login.2faActivation.contactPhone.header')}
            {` `}
            {verification?.contactPhoneCountry && (
              <ReactCountryFlag
                countryCode={verification.contactPhoneCountry}
                svg
              />
            )}
            {` `}
            {verification.contactPhoneNumber}
          </div>
        )}
        <ConfirmSlider type={type} qrImageBase64={qrImageBase64} />
      </div>
    );
  };

  renderForm = () => {
    const {
      getTranslate,
      content: { type },
      sendCode,
      onComplete,
      canResend,
      isLoading,
      countDown,
      code,
      onChangeCode,
      onPaste,
      validationErrors,
    } = this.props;

    const isTypeSMS = type === confirmationTypes.SMSCode;
    return (
      <div className='confirm-code__container'>
        <Form customClass='confirm-code__form' onSubmit={() => sendCode()}>
          {isTypeSMS && (
            <p className='confirm-code__note'>
              {getTranslate('auth.login.keyValidation.text')}
            </p>
          )}
          {isLoading ? (
            <Loader />
          ) : (
            <Input
              modern
              disabled={isLoading}
              autoFocus
              id='verification-code'
              value={code}
              label={getTranslate('auth.login.verificationCode.label')}
              placeholder={getTranslate(
                'auth.login.verificationCode.placeholder'
              )}
              cleaveOptions={{
                numericOnly: true,
                blocks: [3, 3],
                delimiters: [' '],
              }}
              onPaste={onPaste}
              onChange={onChangeCode}
              error={getTranslate(validationErrors.verificationCode)}
            />
          )}
          {isTypeSMS && (
            <Button
              disabled={isLoading || !canResend}
              customClass='confirm-code__button'
              text={
                <div>
                  {getTranslate('auth.login.2faActivation.resendKey.button')}
                  {` `}
                  {!canResend && (
                    <Countdown
                      autoStart
                      date={countDown}
                      onComplete={onComplete}
                      renderer={this.renderTimer}
                    />
                  )}
                </div>
              }
              status='outline'
              size='large'
              onClick={() => sendCode(true)}
            />
          )}
        </Form>
      </div>
    );
  };

  renderTimer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return null;
    }

    return (
      <span>
        ({zeroPad(minutes)}:{zeroPad(seconds)})
      </span>
    );
  };
}

export default addTranslation(Confirm2FA);
