import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import BusinessDocumentsFormContainer from './components/BusinessDocumentsForm/BusinessDocumentsFormContainer';
import BusinessDocumentsFormsListContainer from './components/BusinessDocumentsFormsList';
import PageTemplate from 'components/pageTemplate';
import SavedEntitiesContainer from 'components/savedEntities';
import savedEntities from 'constants/savedEntities';
import { TopPageInfromer } from './components/TopPageInformer/TopPageInformer';
import { MafState } from 'components/maf/MafTypes';
import { BoardingStage } from 'types/BoardingStage';

export const BusinessDocuments = () => {
  const { path } = useRouteMatch();
  const mafStatus = useSelector<{ maf: MafState }, BoardingStage | undefined>(
    (state) => state.maf.status
  );

  const statusToInform: BoardingStage[] = ['accepted', 'decline', 'daf_review'];

  return (
    <PageTemplate.Main>
      <PageTemplate.Entities>
        <SavedEntitiesContainer entityKey={savedEntities.businessDocs} />
      </PageTemplate.Entities>
      {mafStatus && statusToInform.includes(mafStatus) && (
        <TopPageInfromer status={mafStatus} />
      )}
      <PageTemplate.Container>
        <Switch>
          <Route
            path={`${path}/:id`}
            component={BusinessDocumentsFormContainer}
          />
          <Route path={path} component={BusinessDocumentsFormsListContainer} />
        </Switch>
      </PageTemplate.Container>
    </PageTemplate.Main>
  );
};

export default BusinessDocuments;
