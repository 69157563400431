import { idPropToType } from './constants';

export function normalizeElementsIds(filters, type) {
  const { elements, ...restFilters } = filters;
  const idPropName = idPropToType[type];
  return {
    ...restFilters,
    elements: elements.map((filter) => {
      const { [idPropName]: id, ...rest } = filter;
      return { ...rest, id };
    }),
  };
}
