import BaseMessageHandler from '../BaseMessageHandler';
import { updateUserTips } from 'actions/user';
import Repository from '../../helpers/Repository';

export default class HelpTips_MarkAsRead extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;

    const store = Repository.get('store').getState();

    const { tips } = store.user;

    dispatch(
      updateUserTips({ list: Object.assign(tips?.list || {}, message.payload) })
    );
  };
}
