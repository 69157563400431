import React, { VFC } from 'react';
import Grid from 'components/grid';

interface Props {
  tag?: React.ReactNode;
  title: string | React.ReactNode;
  mainText: string | React.ReactNode;
  buttons: React.ReactNode;
  image: React.ReactNode;
}

export const DafStatusInformerLayout: VFC<Props> = ({
  tag,
  title,
  mainText,
  buttons,
  image,
}) => (
  <Grid align='center' spacing='l' className='daf-status-informer'>
    <div className='daf-status-informer__content utils-flex-column'>
      {tag}
      <div className='daf-status-informer__title'>{title}</div>
      <div className='daf-status-informer__text'>{mainText}</div>
      <div className='daf-status-informer__buttons'>{buttons}</div>
    </div>
    <div className='daf-status-informer__image'>{image}</div>
  </Grid>
);
