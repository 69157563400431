import BaseMessageHandler from '../BaseMessageHandler';
import { addDataItem, updateDataItem } from 'actions/getData';
import Repository from 'helpers/Repository';
import tableNames from 'constants/tableNames';

export default class Remittance_PaymentBatchUpdated extends BaseMessageHandler {
  success = () => {
    const {
      dispatch,
      message: { payload },
    } = this;

    const store = Repository.get('store');
    const {
      data: { massRemittancePayments },
    } = store.getState();

    if (!massRemittancePayments.isFetched) return;

    if (massRemittancePayments.items.find(({ id }) => id === payload.id)) {
      dispatch(
        updateDataItem(tableNames.massRemittancePayments, 'id', payload)
      );
    } else {
      dispatch(addDataItem(tableNames.massRemittancePayments, payload));
    }
  };
}
