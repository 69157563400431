import { isEmpty } from 'lodash';
import FormFieldsConfig, { FormField } from 'components/formFields/types';

const getFields = (fieldsConfig: FormFieldsConfig, callback) => {
  if (!fieldsConfig) return [];
  const config = Object.values(fieldsConfig).map((value) => value);
  const flatt: FormField[] = config.flat(2);
  return callback(flatt).filter((item) => !!item);
};

const getDictionaries = (fields: FormField[]) => {
  return fields.map((field) => {
    if (typeof field.dictionaryId === 'object') {
      return !field.customOptions && field.dictionaryId;
    }
    return !field.customOptions && field.dictionaryId;
  });
};

const getRequired = (fields: FormField[]): FormField[] => {
  return fields.filter((field) => {
    return field.required;
  });
};

export const getDictionariesConfig = (fieldsConfig: FormFieldsConfig) => {
  return getFields(fieldsConfig, getDictionaries);
};

export const getRequiredFields = (fieldsConfig: FormFieldsConfig) => {
  return getFields(fieldsConfig, getRequired);
};

const prepareErrors = (validationErrors, complexName, name?) => {
  return Object.keys(validationErrors).reduce((errors, key) => {
    const item = validationErrors[key];
    if (typeof item === 'object') {
      return { ...errors, ...prepareErrors(item, complexName, key) };
    }
    const keyName = complexName ? `${key}-${name}` : key;
    return { ...errors, [keyName]: item };
  }, {});
};

export const scrollToError = (
  validationErrors: Record<string, string> | {},
  direction: 'top' | 'bottom' = 'top',
  complexName: boolean = false
) => {
  if (isEmpty(validationErrors) || !validationErrors) return;

  let targetElement;

  Object.keys(prepareErrors(validationErrors, complexName)).forEach((key) => {
    // eslint-disable-next-line unicorn/prefer-query-selector
    const item = document.getElementById(key); // querySelector не находит id с точкой
    if (!targetElement && item) {
      targetElement = item;
    }

    const condition =
      item &&
      (direction === 'top'
        ? item.getBoundingClientRect().top <
          targetElement?.getBoundingClientRect().top
        : item.getBoundingClientRect().bottom >
          targetElement?.getBoundingClientRect().bottom);

    if (condition) {
      targetElement = item;
    }
  });
  targetElement && targetElement.scrollIntoView({ block: 'nearest' });
};
