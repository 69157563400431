import React from 'react';
import classNames from 'classnames';
import path from 'helpers/path';
import Icon from 'components/ui/icon';
import Link from 'components/ui/link';
import './quickActionItem.scss';

export interface IQuickActionItem {
  id: string;
  title: string;
  icon: string;
  isFavorite?: boolean;
}

interface Props {
  customClass?: string;
  toggleFavorite: (id: string) => void;
}

const QuickActionItem: React.VFC<Props & IQuickActionItem> = ({
  id,
  title,
  icon,
  isFavorite,
  customClass,
  toggleFavorite,
}) => (
  <Link url={path(id)} className={classNames('quick-action-item', customClass)}>
    <Icon
      className={classNames('quick-action-item__favorite', {
        'quick-action-item__favorite_filled': isFavorite,
      })}
      onClick={(e) => {
        e.preventDefault();
        toggleFavorite(id);
      }}
      name={isFavorite ? 'im-Favourite-Filled' : 'im-Favourite'}
    />
    <Icon className='quick-action-item__icon' name={icon} size={22} />
    <div className='quick-action-item__title'>{title}</div>
  </Link>
);

export default QuickActionItem;
