import modes from 'components/ui/buttonsGroup/modes';
import filtersKeys from 'constants/filters';
import { FilterTypes } from 'constants/FilterTypes';

const remittancePayments = [
  {
    filterId: filtersKeys.remittancePaymentStatus,
    type: FilterTypes.buttonsList,
    mode: modes.multi,
    view: 'separate',
    list: [
      {
        id: 'awaiting approval',
        text: 'remittance.payouts.quickFilters.status.awaitingApprove',
      },
      { id: 'success', text: 'remittance.payouts.quickFilters.status.success' },
      { id: 'decline', text: 'remittance.payouts.quickFilters.status.decline' },
    ],
  },
  {
    filterId: filtersKeys.remittancePaymentCurrency,
    type: FilterTypes.buttonsList,
    mode: modes.multi,
    view: 'separate',
    list: [
      {
        id: 'EUR',
        text: 'remittance.recipientAccList.quickFilters.currency.eur',
      },
      {
        id: 'GBP',
        text: 'remittance.recipientAccList.quickFilters.currency.gbp',
      },
    ],
  },
  {
    filterId: filtersKeys.remittanceCreationDate,
    type: FilterTypes.dateButtons,
  },
];

const remittanceRecipientAccounts = [
  {
    filterId: filtersKeys.remittanceStatus,
    type: FilterTypes.buttonsList,
    mode: modes.multi,
    view: 'separate',
    list: [
      {
        id: 'draft',
        text: 'remittance.recipientAccList.quickFilters.status.draft',
      },
      {
        id: 'created',
        text: 'remittance.recipientAccList.quickFilters.status.created',
      },
      {
        id: 'active',
        text: 'remittance.recipientAccList.quickFilters.status.active',
      },
      {
        id: 'denied',
        text: 'remittance.recipientAccList.quickFilters.status.denied',
      },
      {
        id: 'expired',
        text: 'remittance.recipientAccList.quickFilters.status.expired',
      },
    ],
  },
  {
    filterId: filtersKeys.transferCurrency,
    type: FilterTypes.buttonsList,
    mode: modes.multi,
    view: 'separate',
    list: [
      {
        id: 'EUR',
        text: 'remittance.recipientAccList.quickFilters.currency.eur',
      },
      {
        id: 'GBP',
        text: 'remittance.recipientAccList.quickFilters.currency.gbp',
      },
    ],
  },
  {
    filterId: filtersKeys.remittanceCreationDate,
    type: FilterTypes.dateButtons,
  },
];

export default {
  remittancePayments,
  remittanceRecipientAccounts,
};
