import BaseMessageHandler from '../BaseMessageHandler';
import showNotification from 'components/ui/notification/showNotification';
import getLocalizedText from 'helpers/getLocalizedText';

export default class Notification extends BaseMessageHandler {
  success = () => {
    const { payload } = this.message;
    showNotification({
      status: payload.level,
      content: getLocalizedText(payload.text),
    });
  };
}
