import DateHelpers from 'helpers/Date';

export const DateDisplay = ({ dateString }: { dateString?: string }) => {
  if (!dateString) return null;

  const date = new Date(dateString);
  const formattedDate = DateHelpers.formatDateForTableCell(date);

  return <div className='date-display'>{formattedDate}</div>;
};
