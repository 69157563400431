import React, { PureComponent } from 'react';
import Tooltip from 'react-tooltip';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';

import Row from 'components/ui/table/row';
import Column from 'components/ui/table/column/Column';
import DynamicTable from 'components/ui/table/DynamicTable';
import { Header } from 'components/ui/table';
import Switch from 'components/ui/switch';
import Button from 'components/ui/button';
import ApsIcon from 'components/ui/apsIcon';
import ConditionalWrapper from 'components/ui/conditionalWrapper';

import tableNames from 'constants/tableNames';
import { AnyObject } from 'types/Common';
import { CallbackStatuses } from 'components/modal/modalList/createCallbackForm/types';
import { projectSettingsCallbacksColumnsWidth } from './constants';
import Messages from 'constants/rpcTypes';

interface Props extends IntlProps, WithPermissions {
  tableName: string;
  projectId: string;
  status: boolean;
  list: AnyObject[];
  isLoading: boolean;
  isOverLimit: boolean;
  updateProjectSetting: ({
    id,
    status,
  }: {
    id: string;
    status: string;
  }) => void;
  deleteCallback: (id: string) => void;
  isNotAvailable: (message: string) => boolean;
  onOpenForm: () => void;
}

const tableConfig = [
  { id: 'id', content: 'projects.callbacks.callbackID.label' },
  { id: 'url', content: 'projects.callbacks.url.label' },
  { id: 'resultType', content: 'projects.callbacks.resultType.label' },
  {
    id: 'transactionType',
    content: 'projects.callbacks.transactionType.label',
  },
  {
    id: 'paymentMethodName',
    content: 'projects.callbacks.paymentMethod.label',
  },
  { id: 'status', content: 'projects.callbacks.status.label' },
  { id: 'createdAt', content: 'projects.callbacks.created.label' },
  { id: 'updatedAt', content: 'projects.callbacks.updated.label' },
  { id: 'delete', content: 'projects.callbacks.delete.label' },
];

class Callbacks extends PureComponent<Props> {
  componentDidMount() {
    Tooltip.rebuild();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.list.length !== this.props.list.length) {
      Tooltip.rebuild();
    }
  }

  render() {
    const { list, isLoading, status, isOverLimit, onOpenForm, getTranslate } =
      this.props;
    const canCreate =
      !isLoading &&
      status &&
      this.props.isEnabled(Messages.ProjectCallbackSettings_Create);
    return (
      <div className='page-projects__block page-projects__block_callback'>
        <div
          className='page-projects__add-callback-button'
          data-tip={
            isOverLimit ? getTranslate('projects.callbacksLimit.tooltip') : ''
          }>
          <Button
            disabled={isOverLimit || !canCreate}
            text={getTranslate('projects.callbacks.addNew.button')}
            status='primary'
            onClick={onOpenForm}
          />
        </div>
        <DynamicTable
          isFetch={isLoading}
          minColumnsWidth={projectSettingsCallbacksColumnsWidth}
          resizableColumns={!!list.length}
          customConfiguration={tableConfig}
          items={list}
          customClass='page-projects__settings-table'
          rowRender={this.rowRender}
          headerRender={this.headerRender()}
          tableName={tableNames.projectCallbacks}
        />
      </div>
    );
  }

  headerRender() {
    const { getTranslate } = this.props;
    return (
      <Header>
        {tableConfig.map((column) => {
          return (
            <Column
              key={column.id}
              id={column.id === 'id' ? 'callbackId' : column.id}
              content={getTranslate(column.content)}
            />
          );
        })}
      </Header>
    );
  }

  rowRender = ({ handleDoubleClick, ...data }) => {
    const {
      updateProjectSetting,
      deleteCallback,
      status,
      getTranslate,
      isEnabled,
    } = this.props;
    const tooltip = !data.isEditable
      ? getTranslate('projects.callbacks.disabledDeletion.tooltip')
      : undefined;

    return (
      <Row key={data.id}>
        {tableConfig.map((config) => {
          const { id } = config;
          let content = data[id];
          if (id === 'status') {
            const checked = data.status === CallbackStatuses.active;
            content = (
              <Switch
                id='status'
                checked={checked}
                disabled={
                  !status ||
                  !data.isEditable ||
                  !isEnabled(Messages.ProjectCallbackSettings_Update)
                }
                tooltip={tooltip}
                onChange={() =>
                  updateProjectSetting({
                    id: data.id,
                    status: checked
                      ? CallbackStatuses.disabled
                      : CallbackStatuses.active,
                  })
                }
              />
            );
          } else if (id === 'delete') {
            content = (
              <ConditionalWrapper
                condition={!data.isEditable}
                wrap={(child) => <span data-tip={tooltip}>{child}</span>}>
                <Button
                  icon='im-Basket'
                  size='inline'
                  onClick={() => deleteCallback(data.id)}
                  status='transparent'
                  disabled={
                    !status ||
                    !data.isEditable ||
                    !isEnabled(Messages.ProjectCallbackSettings_Delete)
                  }
                />
              </ConditionalWrapper>
            );
          } else if (id === 'paymentMethodName') {
            if (data[id]) {
              content = (
                <ApsIcon
                  iconUrl={data.paymentMethodIconUrl}
                  paymentType={data[id]}
                  showTooltip
                />
              );
            } else {
              content = '—';
            }
          }
          return (
            <Column
              columnWidths={data.columnWidths}
              key={id}
              id={id === 'id' ? 'callbackId' : id}
              data={data}
              content={content || '—'}
              modifier={id}
            />
          );
        })}
      </Row>
    );
  };
}
export default addTranslation(addPermissions(Callbacks));
