import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { AnyObject } from 'types/Common';
import { currency } from '../../constants';
import Utils from 'helpers/Utils';
import { Column } from 'components/ui/table';
import './totals.scss';

interface Props {
  title: string;
  inputType?: string;
  data: AnyObject;
  isHeader?: boolean;
  onChange?: (cur, value, isOver?) => void;
  isUserCanEdit?: boolean;
  isSimpleCurrencyLabel?: boolean;
  inputOptions?: AnyObject;
}

class Totals extends PureComponent<Props> {
  render() {
    const {
      data,
      title,
      isHeader,
      onChange,
      inputType,
      isUserCanEdit,
      isSimpleCurrencyLabel,
      inputOptions,
    } = this.props;
    return (
      <div
        className={classNames('reconciliation-totals', {
          'reconciliation-totals_header': isHeader,
        })}>
        <div className='reconciliation-totals__title'>{title}</div>
        {currency.map((cur) => {
          const content = () => {
            if (isHeader) {
              return isSimpleCurrencyLabel ? cur : `EUR/${cur}`;
            }
            return data[cur] && !Number.isNaN(parseFloat(data[cur]))
              ? Utils.getNumberWithSpace(data[cur])
              : data[cur];
          };

          return (
            <div className='reconciliation-totals__item' key={cur}>
              <div
                className={classNames('reconciliation-totals__value', {
                  'reconciliation-totals__value_padding':
                    !isUserCanEdit && !isHeader,
                })}>
                <Column
                  id={`rate-${cur}`}
                  content={content()}
                  params={{
                    isEditable: isUserCanEdit,
                    edit: {
                      type: inputType || '',
                      inputOptions,
                      canEdit: isUserCanEdit,
                      isForbiddenEdit: !isUserCanEdit,
                      onChange: (value) => {
                        onChange && onChange(cur, value);
                      },
                      onTableInputBlur: (value) => {
                        onChange && onChange(cur, value, true);
                      },
                    },
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Totals;
