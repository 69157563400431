import filtersKeys from 'constants/filters';
import { FilterTypes } from 'constants/FilterTypes';

export default [
  {
    filterId: filtersKeys.fileName,
    type: FilterTypes.text,
    title: 'admin.uploadFinancialData.quickFilters.csvName',
  },
];
