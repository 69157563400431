import { createSelector } from 'reselect';
import reportTypes from 'constants/reportTypes';

const getFilterSet = (state, name) => {
  return state.filters[reportTypes[name]] || {};
};

export const getReportFilterSet = createSelector(
  [getFilterSet],
  (filterSet) => filterSet
);
