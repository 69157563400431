export default {
  payments: 'payments',
  payouts: 'payouts',
  massPayouts: 'payouts',
  reports: 'reports',
  risks: 'risks',
  analytics: 'analytics',
  recurring: 'recurring',
  chargebacks: 'chargebacks',
  remittance: 'remittance',
  businessDocs: 'businessDocs',
} as const;
