export const minPaymentTableColumnsWidth = {
  paymentId: 150,
  recurringScheduledPaymentId: 120,
  updatedDate: 103,
  paymentAmount: 97,
  paymentCurrency: 77,
  paymentMethodType: 87,
  last3dsCheck: 76,
  mapping_result_code: 76,
};

export const refundablePaymentTypes = ['purchase', 'invoice', 'recurring'];
export const refundablePaymentStatuses = [
  'partially refunded',
  'partially reversed',
  'success',
  'scheduled recurring processing',
];
