import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';
import api from 'api/dictionaries';
import dictionaries from 'constants/dictionaries';

const setFailDictionary = (dispatch, error) => {
  dispatch(
    createAction({
      type: ACTIONS.FETCH_DICTIONARY_FAIL,
      payload: {
        error,
      },
    })
  );
};

// eslint-disable-next-line unicorn/consistent-function-scoping
export const getTimezone = () => (dispatch) => {
  dispatch(
    createAction({
      type: ACTIONS.FETCH_DICTIONARY,
      payload: {
        alias: dictionaries.timezones,
      },
    })
  );

  api
    .getTimezones()
    .then((list) => {
      dispatch(
        createAction({
          type: ACTIONS.FETCH_DICTIONARY_SUCCESS,
          payload: {
            list,
            alias: dictionaries.timezones,
          },
        })
      );
    })
    .catch((error) => setFailDictionary(dispatch, error));
};
