import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

interface SummaryItem {
  transactionsNumber: number;
  amount: number;
}

interface Payload {
  conversion: number;
  success: SummaryItem;
  decline: SummaryItem;
  canceled: SummaryItem;
  refunded: SummaryItem;
  currency: string;
  message: string;
  total: number;
}

export interface State {
  isFetch: boolean;
  rpcId: string;
  data: Payload;
  message: string;
  onlyInfo: boolean;
}

const initialData: Payload = {
  conversion: 0,
  success: {
    transactionsNumber: 0,
    amount: 0,
  },
  decline: {
    transactionsNumber: 0,
    amount: 0,
  },
  canceled: {
    transactionsNumber: 0,
    amount: 0,
  },
  refunded: {
    transactionsNumber: 0,
    amount: 0,
  },
  currency: 'USD',
  total: 0,
  message: '',
};

const initialState: State = {
  isFetch: false,
  data: initialData,
  message: '',
  rpcId: '',
  onlyInfo: false,
};

const paymentSummaryData = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;
  const { rpcId } = payload || {};

  switch (type) {
    case ACTIONS.FETCH_PAYMENT_SUMMARY_DATA_SUCCESS:
      if (rpcId !== state.rpcId) {
        return;
      }

      return {
        ...state,
        data: payload,
        message: payload.message,
        isFetch: false,
      };
    case ACTIONS.FETCH_PAYMENT_SUMMARY_DATA_FAIL:
      if (state.rpcId && rpcId !== state.rpcId) {
        return;
      }

      const { message, onlyInfo = false } = payload;
      return {
        ...state,
        data: initialData,
        message: message,
        isFetch: false,
        onlyInfo: onlyInfo,
      };
    case ACTIONS.FETCH_PAYMENT_SUMMARY_DATA_START:
      return {
        ...state,
        rpcId: '',
        isFetch: true,
        onlyInfo: false,
      };
    case ACTIONS.FETCH_PAYMENT_SUMMARY_DATA_START_CALCULATION:
      return {
        ...state,
        rpcId,
      };
    case ACTIONS.RESET_ALL:
      return {
        ...state,
        initialState,
      };
    default:
      return state;
  }
};

export default paymentSummaryData;
