import React from 'react';
import { MafDigest, MafPreview } from 'components/maf/MafTypes';
import './businessDocumentsFormsList.scss';
import { IntlProps, addTranslation } from 'decorators/addTranslation';
import CustomSelect from 'components/ui/customSelect/CustomSelect';
import { LegalEntity, MafListOption } from './BusinessDocumentsFormsList.types';
import tableNames from 'constants/tableNames';
import DynamicTable from 'components/ui/table/DynamicTable';
import { Column, Header, Row } from 'components/ui/table';
import { LinkText } from './components/LinkText';
import { StatusTag } from './components/StatusTag';
import { DateDisplay } from './components/DateDisplay';

class BusinessDocumentsFormsList extends React.PureComponent<
  {
    goToForm: (id: string, caption: string, wasMafOpen: boolean) => void;
    digest?: MafDigest;
    legalEntities: LegalEntity[];
    changeSelectedEntity: (selectedEtity: MafListOption) => void;
    selectedLegalEntity: MafListOption;
  } & IntlProps
> {
  render() {
    return (
      <div className='page-maf-list'>
        <div className='merchant-legal-entity-custom-select-wrapper'>
          <CustomSelect
            customClass='merchant-legal-entity-custom-select'
            theme='light'
            modern
            placeholder={' '} // ?
            value={{
              value: this.props.selectedLegalEntity?.value,
              label: this.props.selectedLegalEntity?.label,
            }}
            options={this.props.legalEntities?.map((le) => ({
              value: le.id,
              label: le.title,
            }))}
            onChange={(selectedEtity: MafListOption) => {
              this.props.changeSelectedEntity(selectedEtity);
            }}
            isDisabled={false}
          />
        </div>
        <div className='merchant-legal-entity-table'>
          <DynamicTable
            isFetch={!Boolean(this.props.digest?.mafs)}
            resizableColumns={false}
            noContentText={'add.entities.info.text'}
            items={this.props.digest?.mafs || [{}]}
            headerRender={
              <Header>
                <Column
                  header={true}
                  key='action'
                  id='action'
                  content={this.props.getTranslate(
                    'businessdocuments.doc.action'
                  )}
                />
                <Column
                  key='name'
                  id='name'
                  content={this.props.getTranslate(
                    'businessdocuments.doc.name'
                  )}
                />
                <Column
                  key='status'
                  id='status'
                  content={this.props.getTranslate(
                    'businessdocuments.doc.status'
                  )}
                />
                <Column
                  key='created'
                  id='created'
                  content={this.props.getTranslate(
                    'businessdocuments.doc.created'
                  )}
                />
                <Column
                  key='updated'
                  id='updated'
                  content={this.props.getTranslate(
                    'businessdocuments.doc.updated'
                  )}
                />
              </Header>
            }
            rowRender={(maf: MafPreview) => (
              <Row
                key={maf.crm_id}
                customClass='ui-table__row_clickable'
                onClick={() =>
                  this.props.goToForm(maf.crm_id, maf.caption, maf.maf_was_open)
                }>
                <Column
                  id={maf.crm_id + '_action'}
                  customClass='merchant-legal-entity-table__cell'
                  content={<LinkText status={maf.boarding_status} />}
                />
                <Column
                  id={maf.crm_id + '_name'}
                  customClass='merchant-legal-entity-table__cell'
                  content={maf.caption}
                />
                <Column
                  id={maf.crm_id + '_status'}
                  customClass='merchant-legal-entity-table__cell'
                  content={
                    maf.boarding_status && (
                      <StatusTag
                        status={maf.boarding_status}
                        wasMafUpdated={maf.maf_was_open}
                      />
                    )
                  }
                />
                <Column
                  customClass='merchant-legal-entity-table__cell'
                  id={maf.crm_id + '_created'}
                  content={<DateDisplay dateString={maf.created_on} />}
                />
                <Column
                  customClass='merchant-legal-entity-table__cell'
                  id={maf.crm_id + '_updated'}
                  content={<DateDisplay dateString={maf.updated_on} />}
                />
              </Row>
            )}
            tableName={tableNames.merchantLegalEntities}
          />
        </div>
      </div>
    );
  }
}

export default addTranslation(BusinessDocumentsFormsList);
