import { createSelector } from 'reselect';
import ItemConfiguration from 'types/ItemConfiguration';

const getConfiguration = (state, name): ItemConfiguration[] => {
  return state.configuration[name] || [];
};

const prepare = (config: ItemConfiguration[]) =>
  config.filter((item) => item.active);

const getConfigurationByName = createSelector(
  [getConfiguration],
  (configuration) => {
    return [...prepare(configuration)];
  }
);

export default getConfigurationByName;
