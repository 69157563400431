import React, { Component, PropsWithChildren, ReactNode } from 'react';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import classNames from 'classnames';
import MenuContext from 'components/ui/menuContext';
import CustomScrollbar from 'components/ui/customScrollbar';
import Button from 'components/ui/button';
import RoundButton from 'components/ui/roundButton';
import Icon from 'components/ui/icon';
import Messages from 'constants/rpcTypes';
import HelpArticleType, { ArticleStatus } from 'pages/help/types';
import './helpArticle.scss';

interface Props extends WithPermissions {
  data: HelpArticleType;
  isEditing: boolean;
  hasNotData: boolean;
  isDraft: boolean;
  getScrollRef: (node) => void;
  onScroll: (event) => void;
  onStartEdit: () => void;
  onCancel: () => void;
  onSave: () => void;
  onDelete: (articleId) => void;
  publishArticle: (articleId) => void;
  onEditArticleInfo: (article) => void;
  toggleDraft: (isOn: boolean, callback?) => void;
}

class HelpArticle extends Component<PropsWithChildren<Props>> {
  render() {
    const {
      data,
      isEditing,
      children,
      onSave,
      onStartEdit,
      onCancel,
      onScroll,
      getScrollRef,
      isDraft,
      isEnabled,
    } = this.props;
    const editModeMenuOptions = this.getMenuOptions();
    const enableControls =
      isEnabled(Messages.Article_Update) && data.isEditMode;

    return (
      <div className='help-article'>
        {enableControls && (
          <div className='help-article__header help-article__header_controls'>
            <div className='help-article__status'>
              <Icon
                name={isDraft ? 'im-Edit1' : 'im-Check-mark'}
                size={12}
                tooltip={isDraft ? 'Draft' : 'Published'}
                className={classNames('help-article__label', {
                  'help-article__label_draft': isDraft,
                })}
              />
              <h1 className='help-article__title'>{data.title}</h1>
            </div>
            <div className='help-article__controls'>
              {isEditing ? (
                <div className='help-article__edit-buttons'>
                  <Button
                    key='cancel'
                    text='Cancel'
                    status='outline'
                    onClick={onCancel}
                  />
                  <Button
                    key='save'
                    text='Save changes'
                    status='primary'
                    onClick={onSave}
                  />
                </div>
              ) : (
                <Button
                  key='edit'
                  text='Start edit'
                  status='outline'
                  onClick={onStartEdit}
                />
              )}
              {!!editModeMenuOptions.length && !isEditing && (
                <div className='help-article__menu'>
                  <MenuContext
                    list={editModeMenuOptions}
                    customClass='help-article__menu-context'
                    name='no-hover'
                    value={{
                      rawContent: (
                        <RoundButton
                          size={30}
                          icon='im-Dots'
                          iconSize={10}
                          customClass='help-article__menu-icon'
                        />
                      ),
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <div className='help-article__content'>
          <CustomScrollbar
            onScroll={onScroll}
            getRef={(el) => {
              getScrollRef(el);
            }}>
            <div className='help-article__inner'>
              {enableControls && (
                <div className='help-article__metadata'>
                  <div className='help-article__metadata-row'>
                    <span>Edited by</span> {data.editBy || data.createdBy} at{' '}
                    {data.editAt || data.createdAt}
                  </div>
                  {data.publishedBy && (
                    <div className='help-article__metadata-row'>
                      <span>Approved by</span> {data.publishedBy} at{' '}
                      {data.publishedAt}
                    </div>
                  )}
                  <div className='help-article__metadata-row'>
                    <span>View Count</span> {data.viewCount}
                  </div>
                </div>
              )}
              {enableControls === false && (
                <div className='help-article__header'>
                  <h1 className='help-article__title'>{data.title}</h1>
                </div>
              )}
              {children}
            </div>
          </CustomScrollbar>
        </div>
      </div>
    );
  }

  getMenuOptions = () => {
    const {
      data,
      isDraft,
      isEditing,
      toggleDraft,
      onEditArticleInfo,
      onDelete,
      publishArticle,
      isEnabled,
    } = this.props;
    const options: {
      content: string | ReactNode;
      id: string;
      customClass?: string;
      onClick: () => void;
    }[] = [];

    if (data.isChanged) {
      if (isEnabled(Messages.Article_Publish)) {
        options.push({
          content: isEditing ? 'You need to save changes first' : 'Publish',
          id: 'publish',
          onClick: () => !isEditing && publishArticle(data.articleId),
        });
      }

      if (data.status === ArticleStatus.published) {
        options.push({
          content: isDraft ? 'Show published' : 'Show draft',
          id: 'draft',
          onClick: () => toggleDraft(!isDraft),
        });
      }
    }
    if (isEnabled(Messages.Article_Update)) {
      options.push({
        content: <span>Edit article info</span>,
        id: 'Edit',
        onClick: () => onEditArticleInfo(data),
      });
    }
    if (isEnabled(Messages.Article_Delete)) {
      options.push({
        content: 'Delete',
        id: 'Delete',
        customClass: 'help-article__delete-btn',
        onClick: () => onDelete(data.articleId),
      });
    }

    return options;
  };
}

export default addPermissions(HelpArticle);
