import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

const initialState = {};

const paymentActions = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.UPDATE_PAYMENT_ACTIONS:
      return {
        ...state,
        [payload.transactionId]: payload.data,
      };
    default:
      return state;
  }
};

export default paymentActions;
