import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import './paymentVerification.scss';

interface Props extends IntlProps {
  id: number;
  data: {
    key: string;
    value: string;
  }[];
}

class PaymentVerification extends PureComponent<Props> {
  render() {
    const { data } = this.props;

    return (
      <div className='payment-verification'>
        {data.map((item) => {
          return (
            <div className='payment-verification__row'>
              {item.key}
              <span className='payment-verification__row-value'>
                {item.value}
              </span>
            </div>
          );
        })}
      </div>
    );
  }
}

export default addTranslation(PaymentVerification);
