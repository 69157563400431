import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { IntlProps, addTranslation } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import Panel from 'components/ui/panel';
import Form from 'components/ui/form';
import SelectionList from 'components/ui/selectionList';
import Input from 'components/ui/input';
import RadioGroup from 'components/ui/radioGroup';
import Radio from 'components/ui/radio';
import Loader from 'components/ui/loader';
import ListTypes from 'pages/risks/components/addtoList/single/ListTypes';
import { checkSelectedRcsProjects } from 'pages/risks/helpers';
import ConfigType from './configType';
import './addBlackList.scss';

interface Props extends IntlProps {
  isOpened: boolean;
  isLoadingTypes: boolean;
  isShowBinTooltip: boolean;
  isLoading: boolean;
  reasonValue: any;
  listType: string;
  projectList: any[];
  onOpen: () => void;
  onClose: () => void;
  onSend: (data) => void;
  onChange: (name: string, data) => void;
  customClass?: string;
  config: ConfigType;
}

class AddBlackList extends PureComponent<Props> {
  private scrollbarRef;

  render() {
    const { onOpen, isLoadingTypes, customClass, getTranslate } = this.props;
    return (
      <div className={classNames('add-black-list', customClass)}>
        <Button
          id='button-open-backList-panel'
          text={getTranslate('risks.addToList.list')}
          status='primary'
          size='normal'
          onClick={onOpen}
          loading={isLoadingTypes}
        />
        {this.renderPanel()}
      </div>
    );
  }

  renderPanel = () => {
    const {
      isLoading,
      isOpened,
      reasonValue,
      listType,
      projectList,
      config,
      onClose,
      onSend,
      onChange,
      getTranslate,
      isShowBinTooltip,
    } = this.props;

    return (
      <Panel
        id='add-black-list-panel'
        isOpened={isOpened}
        onClose={onClose}
        title={getTranslate('risks.addToList.list')}
        customClass='ui-panel_add-black-list'
        modern>
        <Form customClass='add-black-list__form' onSubmit={onSend}>
          {isLoading && <Loader />}
          <div
            className='add-black-list__content'
            ref={(el) => {
              this.scrollbarRef = el;
            }}>
            <div className='add-black-list__item'>
              {getTranslate(
                isShowBinTooltip
                  ? 'payment.addToListBIN.tooltip'
                  : 'payment.addToList.tooltip'
              )}
            </div>
            <div className='add-black-list__item'>
              <RadioGroup
                customClass='ui-radio-group_row'
                onChange={(value) => onChange('listType', value)}
                checkedId={listType}>
                <Radio
                  id={ListTypes.blackList}
                  text={getTranslate('risks.addToList.list.black')}
                />
                <Radio
                  id={ListTypes.whiteList}
                  text={getTranslate('risks.addToList.list.white')}
                />
              </RadioGroup>
            </div>
            <div className='add-black-list__item'>
              <SelectionList
                label={getTranslate('payment.addToList.category')}
                items={config[listType].contentTypes}
                isSearchable={false}
                isLoading={isLoading}
                onOpen={this.openSelectionList}
                onChange={(items) => onChange('contentTypes', items)}
                modern
              />
            </div>
            <div className='add-black-list__item'>
              <SelectionList
                label={getTranslate('payment.addToList.project')}
                items={projectList}
                onOpen={this.openSelectionList}
                onChange={(items) => onChange('projectList', items)}
                isValidBeforeApply={checkSelectedRcsProjects}
                modern
              />
            </div>
            <div className='add-black-list__item'>
              <Input
                type='textarea'
                resize={false}
                id='blacklist-reason'
                label={getTranslate('payment.addToList.reason')}
                value={reasonValue}
                placeholder={getTranslate(
                  'risks.quickFilters.value.placeholder'
                )}
                onChange={(e) => onChange('reasonValue', e.target.value)}
                modern
              />
            </div>
          </div>
          <div className='add-black-list__footer'>
            <Button
              type='submit'
              status='primary'
              text={getTranslate('common.apply.button')}
              customClass='ui-button_full-width'
            />
          </div>
        </Form>
      </Panel>
    );
  };

  openSelectionList = () => {
    if (this.scrollbarRef) {
      // waiting selection list animation
      setTimeout(() => {
        this.scrollbarRef.closest('.add-black-list__form').scrollTo({
          behavior: 'smooth',
          top: 300,
        });
      }, 250);
    }
  };
}
export default addTranslation(AddBlackList);
