import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';

export const setAdditionalFeatures = (payload) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_FEATURES,
        payload,
      })
    );
  };
};

export const addAdditionalFeature = (payload) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.ADD_FEATURE,
        payload,
      })
    );
  };
};

export const updateStoredAdditionalFeature = (payload) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPDATE_FEATURE,
        payload,
      })
    );
  };
};

export const uploadAdditionalFeatureList = (payload) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPLOAD_FEATURES_LIST,
        payload,
      })
    );
  };
};

export const confirmDeleteAdditionalFeature = (additionalFeatureId) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.DELETE_FEATURE,
        payload: {
          additionalFeatureId,
        },
      })
    );
  };
};

export const resetFeatures = () => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_FEATURES,
      })
    );
  };
};

export const updateFeatures = (items) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPDATE_FEATURES_LIST,
        payload: items,
      })
    );
  };
};
