export default interface HelpArticle {
  menuIcon: string;
  language: string;
  slug: string;
  title: string;
  content: string;
  contentDraft: string;
  isChanged: boolean;
  isEditMode: boolean;
  articleId: string;
  updatedAt: string;
  viewCount: number;
  status: string;
  sortMenuIndex: number;
  editBy: string;
  editAt: string;
  approvedBy: string;
  publishedBy: string;
  publishedAt: string;
  createdBy: string;
  createdAt: string;
  // Client fields;
  subTitles?: ArticleSubTitlesType[];
  isFetched?: boolean;
}

export interface ArticleSubTitlesType {
  id: string;
  text: string;
  top: number;
  bottom: number;
}

export enum ArticleStatus {
  draft = 'draft',
  published = 'published',
}

export interface ArticleSearch {
  anchor: string;
  slug: string;
  articleId: string;
  content: string;
  title: string;
  breadcrumbs: ArticleBreadcrumb[];
}

export interface ArticleBreadcrumb {
  anchor?: string;
  language: string;
  menuIcon: string;
  slug: string;
  title: string;
}
