import BaseMessageHandler from '../BaseMessageHandler';
import Token from 'helpers/Token';
import path from 'helpers/path';
import { createAction } from 'helpers/redux';
import ACTIONS from 'constants/actionTypes';

export default class Auth_LoginAsSupport extends BaseMessageHandler {
  success = () => {
    const { message, dispatch, history } = this;

    dispatch(
      createAction({
        type: ACTIONS.LOGIN_USER,
        payload: {
          user: message.payload.user,
        },
      })
    );
    Token.setTokens({ authToken: message.payload.authToken });
    history.push(path('/'));
  };
}
