import React from 'react';
import { v4 as uuid } from 'uuid';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import classNames from 'classnames';

import api from 'api/chat';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';

import UnreadCount from 'components/ui/unreadCount';
import { Tab, TabsContainer as Tabs } from 'components/ui/tabs';
import SupportChat from '../supportChat';
import Icon from 'components/ui/icon';
import Panel from 'components/ui/panel';
import Tips from './tips';
import Grid from 'components/grid';

import LocalStorage from 'helpers/LocalStorage';
import { SupportMessages } from 'types/Api';

import './helpDesk.scss';

interface Props extends IntlProps, WithPermissions {
  wl: string;
  isLoading: boolean;
  isOpen: boolean;
  userLandingState: string;
  messages: SupportMessages;
  downLoadTemplates: (currPage: string) => void;
  toggleHelpDesk: () => void;
  uploadTemplate: () => void;
  setOpenTab: (tab: TabsType) => void;
  tips: { [key: string]: boolean } | null;
  docLinks?: { [key: string]: string };
  currentTip: string;
  openTab: number;
  tabs: { tips: boolean; chat: boolean };
}

interface State {
  isCollapsed: boolean;
  tabsKey: string;
}

export enum TabsType {
  tips,
  chat,
}

class HelpDesk extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: LocalStorage.get('helpdesk_expanded_by_user') !== true,
      tabsKey: uuid(),
    };
  }

  componentDidMount() {
    this.markTipAsRead();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { currentTip } = this.props;
    if (currentTip !== prevProps.currentTip) {
      this.setState({
        tabsKey: uuid(),
      });
    }

    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ isCollapsed: false });
    }

    this.markTipAsRead();
  }

  render() {
    const { toggleHelpDesk, getTranslate, openTab, tabs, setOpenTab } =
      this.props;
    const { isCollapsed, tabsKey } = this.state;

    return (
      <Panel
        isOpened={this.props.isOpen}
        renderCloseControl={false}
        customClass={classNames('helpdesk__panel', {
          helpdesk__panel_collapsed: isCollapsed,
        })}
        excludeFromCloseTrigger={['.helpdesk-toggle']}>
        {isCollapsed && (
          <Grid
            onClick={this.onToggleCollapse}
            align='center'
            justify='center'
            className='helpdesk__panel-inner'
            direction='column'>
            <Icon
              size={24}
              className='helpdesk-toggle__icon'
              name='im-Helpdesk'
            />
            <UnreadCount
              count={this.unreadCount}
              className='helpdesk__unread-chat'
            />
            <Icon name='im-Close' size={8} onClick={this.closeHelpDesk} />
          </Grid>
        )}
        {!isCollapsed && (
          <div
            className={classNames('helpdesk', {
              'helpdesk__chat-window': openTab === TabsType.chat,
            })}>
            <div className='helpdesk__header'>
              <div className='helpdesk__title'>
                <div>
                  <span className='helpdesk__wl'>{this.props.wl}</span>
                  {` `}
                  <span>{getTranslate('HelpDesk.label')}</span>
                </div>
              </div>
              <div className='helpdesk__icon-box helpdesk__icon-box_controls'>
                <div className='icon-button'>
                  <Icon
                    name='im-Collapse'
                    size={10}
                    onClick={this.onToggleCollapse}
                  />
                </div>
                <div className='icon-button'>
                  <Icon
                    name='im-Close'
                    size={10}
                    onClick={() => {
                      toggleHelpDesk();
                      LocalStorage.set('helpdesk_closed', true); //remember that popup was closed manually by user
                    }}
                  />
                </div>
              </div>
            </div>
            <Tabs
              key={tabsKey}
              defaultActiveTabIndex={openTab}
              onClick={setOpenTab}>
              {['tips', 'chat'].map((tabName) => (
                <Tab
                  key={tabName}
                  className={classNames('helpdesk__tab', {
                    helpdesk__tab_hidden: !tabs[tabName],
                  })}
                  title={this.tabs[tabName].header}>
                  {this.tabs[tabName].content}
                </Tab>
              ))}
            </Tabs>
          </div>
        )}
      </Panel>
    );
  }

  get unreadCount() {
    const { messages, currentTip, tips } = this.props;
    const count = messages.unreadCount;
    if (currentTip !== 'no_tip' && !tips?.[currentTip]) {
      return count + 1;
    }

    return count;
  }

  closeHelpDesk = (e) => {
    e.stopPropagation();
    this.props.toggleHelpDesk();
    setTimeout(() => {
      this.setState({ isCollapsed: false });
    }, 500);
  };

  onToggleCollapse = () => {
    const { isCollapsed } = this.state;
    LocalStorage.set('helpdesk_expanded_by_user', isCollapsed);
    this.setState({ isCollapsed: !isCollapsed });
  };

  markTipAsRead = () => {
    const { isCollapsed } = this.state;
    const { currentTip, tips, openTab } = this.props;

    if (
      openTab === TabsType.tips &&
      !isCollapsed &&
      this.props.isOpen &&
      !tips?.[currentTip] &&
      currentTip !== 'no_tip'
    ) {
      api.markTipAsRead(currentTip);
    }
  };

  get tabs() {
    const {
      downLoadTemplates,
      uploadTemplate,
      getTranslate,
      currentTip,
      messages,
      isLoading,
      docLinks,
    } = this.props;
    return {
      tips: {
        header: (
          <div className='helpdesk__title-container'>
            <span>{getTranslate('Tips.label')}</span>
          </div>
        ),
        content: currentTip ? (
          <Tips
            docLinks={docLinks}
            contentId={currentTip}
            downLoadTemplates={downLoadTemplates}
            uploadTemplate={uploadTemplate}
          />
        ) : null,
      },
      chat: {
        header: (
          <div className='helpdesk__title-container'>
            <span>{getTranslate('chatBot.label')}</span>
            <UnreadCount
              count={messages.unreadCount}
              className='helpdesk__unread-chat'
            />
          </div>
        ),
        content: messages.list ? <SupportChat isLoading={isLoading} /> : null,
      },
    };
  }
}

export default addTranslation(addPermissions(HelpDesk));
