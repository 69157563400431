import React from 'react';
import classNames from 'classnames';
import './unreadCount.scss';

interface Props {
  count: number;
  className: string;
}

export default class UnreadCount extends React.PureComponent<Props> {
  render() {
    const { count, className } = this.props;

    if (!count) {
      return null;
    }

    return (
      <div className={classNames('unread-count', className)}>
        <span>{count}</span>
      </div>
    );
  }
}
