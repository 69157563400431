import React, { PureComponent } from 'react';
import Icon from 'components/ui/icon';
import FormConstructor from '../formConstructor';
import { AnyObject } from 'types/Common';

interface Props {
  fields: any[] | null;
  validationErrors: AnyObject;
  onChangeField: (field: string, value: any, type: string) => void;
}

class AdditionalFields extends PureComponent<Props> {
  render() {
    const { fields, validationErrors, onChangeField } = this.props;

    if (!fields) return null;

    if (validationErrors.gateError) {
      return this.renderError(validationErrors.gateError);
    }

    return (
      <FormConstructor
        fields={fields}
        validationErrors={validationErrors}
        onChangeField={(field, value) =>
          onChangeField(field, value, 'additionalFields')
        }
      />
    );
  }

  renderError(message) {
    return (
      <div className='payout-additional-fields__error'>
        <Icon
          name='im-Error'
          size={18}
          className='payout-additional-fields__error-icon'
        />
        {message}
      </div>
    );
  }
}

export default AdditionalFields;
