import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Animation from 'components/ui/animation';
import Timer from 'components/ui/timer';
import Button from 'components/ui/button';
import InfoButton from 'components/ui/infoButton';
import PageTemplate from 'components/pageTemplate';
import DateUtils from 'helpers/Date';
import ClarificationInfo from '../clarificationInfo';
import './topPanel.scss';

const STATUS_AWAITING_CLARIFICATION_UPLOAD_PARSE = 6;

interface OwnProps {
  id: string;
  payoutData: any;
  expiredTime: string;
  isClarification: boolean;
  isEditing: boolean;
  isLoading: boolean;
  isOpenUploading: boolean;
  hasTableChanges: boolean;
  isSavingField: boolean;
  isTyping: boolean;
  file: File | null;
  itemsToReject: string[];
  onToggleEditMode: () => void;
  onToggleUploading: () => void;
  onProceed: () => void;
  onReject: () => void;
  onComplete: () => void;
  onDownloadFile: () => void;
  isProceedEnabled: boolean;
}

interface ConnectedProps {
  user: any;
  isMenuExpanded?: boolean;
}

type Props = OwnProps & ConnectedProps & IntlProps;

class TopPanelContainer extends PureComponent<Props> {
  render() {
    const {
      id,
      isEditing,
      isOpenUploading,
      isLoading,
      itemsToReject,
      isProceedEnabled,
      isTyping,
      isSavingField,
      onToggleEditMode,
      onToggleUploading,
      onProceed,
      onReject,
      onComplete,
      onDownloadFile,
      getTranslate,
    } = this.props;

    return (
      <div className='top-panel-batch'>
        <div className='top-panel-batch__inner'>
          <div className='top-panel-batch__row'>
            <div className='top-panel-batch__id'>
              <span className='top-panel-batch__id-text'>Batch ID</span> {id}
            </div>
            {this.isCanEdit() && (
              <div className='top-panel-batch__timer'>
                <span className='top-panel-batch__timer-text'>
                  {getTranslate('payouts.batch.timer.text')}
                </span>
                {!isLoading && (
                  <Timer
                    now={this.getNow}
                    expiredTime={this.getExpiredTime()}
                    onComplete={onComplete}
                  />
                )}
              </div>
            )}
          </div>
          <PageTemplate.Container>
            <div className='top-panel-batch__row'>
              <div className='top-panel-batch__block'>
                {this.isCanEdit() && (
                  <div
                    data-tip={
                      this.isAwaitingUpload()
                        ? getTranslate('payouts.batch.disabledManage.tooltip')
                        : ''
                    }>
                    <Button
                      status={isEditing ? 'outline' : 'primary'}
                      onClick={onToggleEditMode}
                      text={getTranslate(
                        isEditing
                          ? 'common.cancel.button'
                          : 'payouts.batch.manage.button'
                      )}
                      disabled={this.isAwaitingUpload()}
                      customClass='top-panel-batch__manage top-panel-batch__button-action'
                    />
                  </div>
                )}
                {isEditing && (
                  <Animation>
                    <Button
                      status='light-inverse'
                      customClass='top-panel-batch__item'
                      icon='download'
                      iconSize={14}
                      text='CSV'
                      onClick={() => onDownloadFile()}
                    />
                    <Button
                      status='outline'
                      customClass={classNames(
                        'top-panel-batch__item top-panel-batch__upload',
                        {
                          'ui-button_status-light-active': isOpenUploading,
                        }
                      )}
                      icon='upload'
                      iconSize={14}
                      text='CSV'
                      onClick={onToggleUploading}
                    />
                  </Animation>
                )}
                {this.isCanEdit() && (
                  <InfoButton
                    title='payouts.batch.info.label'
                    content={<ClarificationInfo />}
                    customClass='top-panel-batch__item'
                    isMenuExpanded={this.props.isMenuExpanded}
                    relativeMenu
                  />
                )}
              </div>
              {isEditing && (
                <div className='top-panel-batch__block top-panel-batch__block_right'>
                  <Button
                    status='outline'
                    text={getTranslate('common.reject.button')}
                    customClass='top-panel-batch__item top-panel-batch__button-action'
                    disabled={!itemsToReject.length}
                    onClick={onReject}
                  />
                  <Button
                    status='success'
                    text={getTranslate('common.proceed.button')}
                    customClass='top-panel-batch__item top-panel-batch__button-action'
                    onClick={onProceed}
                    loading={isSavingField}
                    disabled={!isProceedEnabled || isSavingField || isTyping}
                  />
                </div>
              )}
            </div>
          </PageTemplate.Container>
        </div>
      </div>
    );
  }

  isCanEdit = () => {
    const { isClarification, expiredTime } = this.props;
    if (!isClarification || !expiredTime) return false;

    return +this.getExpiredTime() > +this.getNow();
  };

  getNow = () => {
    const { user } = this.props;
    return DateUtils.createDate(
      DateUtils.getFormat(
        DateUtils.getDateWithTimezone(user.timezone),
        'datetime'
      ),
      'datetime'
    ).toDate();
  };

  getExpiredTime = () => {
    const { expiredTime } = this.props;
    return DateUtils.createDate(expiredTime, 'datetime').toDate();
  };

  isAwaitingUpload = (): boolean => {
    const { payoutData } = this.props;
    return (
      payoutData.paymentOperationBatchStatus ===
      STATUS_AWAITING_CLARIFICATION_UPLOAD_PARSE
    );
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
  isMenuExpanded: state.configuration?.isMenuExpanded || false,
});

export default connect(mapStateToProps)(addTranslation(TopPanelContainer));
