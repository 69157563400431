import BaseMessageHandler from '../BaseMessageHandler';
import showNotification from 'components/ui/notification/showNotification';
import getMessageError from 'helpers/getMessageError';

export default class PaymentOperationReference_Create extends BaseMessageHandler {
  success = () => {
    const { payload } = this.message;
    payload.s3Url && window.location.assign(payload.s3Url);
  };

  error = () => {
    const { payload } = this.message;
    showNotification({ status: 'error', content: getMessageError(payload) });
  };
}
