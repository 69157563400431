import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Loader from 'components/ui/loader';
import SettingsHistory from './SettingsHistory';
import DateHelpers from 'helpers/Date';
import getConfigurationByName from 'selectors/getConfigurationByName';
import tableNames from 'constants/tableNames';
import ItemConfiguration from 'types/ItemConfiguration';
import {
  RecurringSettingsHistory,
  RecurringSort,
} from '../RecurringSettingsTypes';
import { asc, desc } from '../../../../../types/sortings';

interface OwnProps {
  list: RecurringSettingsHistory;
  isLoading: boolean;
}

interface ConnectedProps {
  configuration: ItemConfiguration[];
}

type Props = OwnProps & ConnectedProps;

interface State {
  sortedList: RecurringSettingsHistory | null;
  historySort: RecurringSort;
}

class SettingsHistoryContainer extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      sortedList: null,
      historySort: {
        field: 'updatedAt',
        order: desc,
      },
    };
  }

  componentDidMount() {
    this.changeSort(this.state.historySort);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (!prevProps.list.length && this.props.list.length) {
      this.changeSort(this.state.historySort);
    }
  }

  render() {
    const { configuration, isLoading } = this.props;
    const { sortedList, historySort } = this.state;

    if (isLoading) {
      return <Loader type='bung' />;
    }

    return (
      <SettingsHistory
        list={sortedList}
        sort={historySort}
        configuration={configuration}
        onSort={(sort) => this.changeSort(sort)}
      />
    );
  }

  changeSort = (sort: RecurringSort) => {
    const { list } = this.props;
    const sortedList = [...list].sort((f1, f2) => {
      const date1: number = +DateHelpers.createDate(
        f1[sort.field],
        'datetime'
      ).toDate();
      const date2: number = +DateHelpers.createDate(
        f2[sort.field],
        'datetime'
      ).toDate();
      if (sort.order === asc) {
        return date1 - date2;
      }
      return date2 - date1;
    });

    this.setState({
      sortedList,
      historySort: sort,
    });
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  configuration: getConfigurationByName(
    state,
    tableNames.subscriptionPaymentsSettingsHistory
  ),
});

export default connect(mapStateToProps)(SettingsHistoryContainer);
