import React, { PureComponent, ReactElement } from 'react';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import Tooltip from 'react-tooltip';
import { debounce } from 'lodash';
import CustomScrollbar from 'components/ui/customScrollbar';

import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from 'constants/ui';

import { Width } from 'types/ItemConfiguration';
import './smartList.scss';

interface Props {
  list: any[];
  rowHeight?: number | (({ index: number }) => number);
  rowRenderer: (data, index: number) => ReactElement;
  totalCount: number;
  fixedColumnWidth?: number;
  contentId?: string;
  getRef?: any;
  headerRender?: ReactElement;
  hideHorizontal?: boolean;
  initialScroll?: any;
  loadNextPage?: (offset: number) => Promise<any>;
  noContent?: ReactElement;
  onScroll?: (event) => void;
  onScrollStop?: (values) => void;
  onUpdate?: (values) => void;
  onReady: () => void;
  thresholdRows?: number;
  getCustomContentScrollWidth: () => number;
  tooltip?: boolean;
  setWidth?: (width?: Width, cb?: () => void) => void;
}

interface State {
  isLoading: boolean;
  scrollLeft: number;
  scrollTop: number;
}

class SmartList extends PureComponent<Props, State> {
  List;
  scrollbarRef;
  headerHolderRef;
  private resizeTimeout;

  static defaultProps = {
    thresholdRows: 2,
    hideHorizontal: false,
    noContent: <div />,
    rowHeight: TABLE_ROW_HEIGHT,
    onReady: () => null,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      scrollLeft: 0,
      scrollTop: 0,
    };
  }

  componentDidMount(): void {
    const { scrollbarRef } = this;
    const { initialScroll, onReady } = this.props;

    if (initialScroll) {
      const { scrollTop, scrollLeft } = initialScroll;

      if (scrollbarRef) {
        setTimeout(() => {
          scrollbarRef.scrollTo(scrollLeft, scrollTop);
          if (
            scrollbarRef.scrollerElement &&
            scrollbarRef.scrollerElement.scrollTop !== scrollTop
          ) {
            setTimeout(() => {
              scrollbarRef.scrollTo(scrollLeft, scrollTop);
              onReady();
            }, 200);
          } else {
            onReady();
          }
        });
      } else {
        onReady();
      }
    } else {
      onReady();
    }
    this.props.tooltip && this.rebuildTooltip();
    this.attachEvents();
  }

  componentDidUpdate(prevProps): void {
    const { list, tooltip } = this.props;
    if (list.length !== prevProps.list.length && this.scrollbarRef) {
      this.handleScroll(this.scrollbarRef); //correct header horizontal position
    }
    tooltip && this.rebuildTooltip();
  }

  componentWillUnmount() {
    this.handleHeaderScrollFunction(false);
    this.detachEvents();
  }

  render() {
    const {
      list,
      totalCount,
      rowHeight,
      getRef,
      thresholdRows,
      noContent,
      contentId,
      fixedColumnWidth,
      hideHorizontal,
      getCustomContentScrollWidth,
    } = this.props;

    const listStyle = {
      overflowX: false,
      overflowY: false,
      top: TABLE_HEADER_HEIGHT,
    };

    const { list: itemList } = this.props;

    const { scrollLeft } = this.state;
    const hasData: boolean = list.length > 0;
    return (
      <div className='smart-list'>
        <div
          ref={(ref) => {
            this.headerHolderRef = ref;
            this.handleHeaderScrollFunction(true);
          }}
          className='header-holder'
          style={{ left: 0 - scrollLeft }}>
          {this.renderHeader()}
        </div>
        {hasData ? (
          <AutoSizer>
            {({ width, height }) => {
              const scrollWidth = fixedColumnWidth
                ? getCustomContentScrollWidth()
                : width;

              return (
                <CustomScrollbar
                  customWidth={width}
                  customHeight={height}
                  hideHorizontal={hideHorizontal}
                  fixedColumnWidth={fixedColumnWidth}
                  getRef={(el) => {
                    this.scrollbarRef = el;
                    getRef && getRef(el);
                  }}
                  onScroll={this.handleScroll}
                  onUpdate={this.handleUpdate}
                  onScrollStop={this.handleStop}>
                  <div id={contentId} className='smart-list__content'>
                    <InfiniteLoader
                      isRowLoaded={({ index }) => !!list[index]}
                      loadMoreRows={this.loadNextPage}
                      threshold={thresholdRows}
                      rowCount={totalCount}>
                      {({ onRowsRendered, registerChild }) => {
                        return (
                          <List
                            height={height - TABLE_HEADER_HEIGHT}
                            onRowsRendered={onRowsRendered}
                            ref={(refList) => {
                              this.List = refList;
                              registerChild(refList);
                            }}
                            rowCount={itemList.length}
                            rowHeight={rowHeight}
                            style={listStyle}
                            rowRenderer={this.rowRenderer}
                            width={scrollWidth}
                          />
                        );
                      }}
                    </InfiniteLoader>
                  </div>
                </CustomScrollbar>
              );
            }}
          </AutoSizer>
        ) : (
          noContent
        )}
      </div>
    );
  }

  renderHeader = () => {
    const { list, headerRender } = this.props;
    if (list.length) {
      return headerRender;
    }
    return (
      <div className='header-holder__empty-content'>
        <CustomScrollbar customHeight={TABLE_HEADER_HEIGHT} hideVertical>
          {headerRender}
        </CustomScrollbar>
      </div>
    );
  };

  rowRenderer = ({ index, key, style }) => {
    const { rowRenderer, list } = this.props;
    const row = list[index];

    return (
      <div key={key} style={style} className='smart-list__row'>
        {rowRenderer(row, index)}
      </div>
    );
  };

  loadNextPage = ({ startIndex }) => {
    const { loadNextPage } = this.props;

    if (typeof loadNextPage === 'function') {
      if (this.state.isLoading) return false;
      this.setState({
        isLoading: true,
      });

      loadNextPage(startIndex).then(() => {
        this.setState({
          isLoading: false,
        });
      });
    }
  };

  handleScroll = (values) => {
    const { onScroll } = this.props;
    const { scrollTop, scrollLeft } = values;
    const { Grid: grid } = this.List;

    this.setState({ scrollLeft, scrollTop });
    grid.handleScrollEvent({ scrollTop, scrollLeft });
    onScroll && onScroll(values);
  };

  handleUpdate = (values) => {
    const { onUpdate } = this.props;

    onUpdate && onUpdate(values);
  };

  handleStop = (values) => {
    const { scrollbarRef } = this;
    const { onScrollStop } = this.props;

    if (scrollbarRef) {
      onScrollStop && onScrollStop(values);
    }
  };

  handleHeaderScrollFunction = (isToAdd) => {
    if (this.headerHolderRef) {
      if (isToAdd) {
        this.headerHolderRef.addEventListener('wheel', this.handleWheelScroll, {
          passive: true,
        });
      } else {
        this.headerHolderRef.removeEventListener(
          'wheel',
          this.handleWheelScroll
        );
      }
    }
  };

  handleWheelScroll = (e) => {
    let { deltaY } = e;
    const { scrollTop, scrollLeft } = this.state;

    deltaY =
      Math.abs(deltaY) > TABLE_ROW_HEIGHT
        ? deltaY
        : Math.sign(deltaY) * TABLE_ROW_HEIGHT;

    this.scrollbarRef?.scrollTo(scrollLeft, scrollTop + deltaY);
  };

  onResize = () => {
    const { setWidth } = this.props;
    this.rebuildTooltip();

    setTimeout(() => {
      if (!this.scrollbarRef) {
        return;
      }
      const { scrollWidth, clientWidth } = this.scrollbarRef;
      const contentScrollable = scrollWidth > clientWidth;

      if (setWidth && !contentScrollable) {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
          setWidth(undefined);
        }, 500);
      }
    }, 0);
  };

  private rebuildTooltip = debounce(() => Tooltip.rebuild(), 200, {
    leading: false,
    trailing: true,
  });

  attachEvents = () => {
    window.addEventListener('resize', this.onResize);
  };

  detachEvents = () => {
    window.removeEventListener('resize', this.onResize);
  };
}

export default SmartList;
