export const httpStatuses = {
  validateError: 400,
  unauthorized: 401,
  accessDenied: 403,
  accessDenied2: 453,
  notFound: 404,
  unprocessableEntity: 422,
  locked: 423,
  serverError: 500,
};

export enum AccessDeniedActions {
  logout,
  notification,
}
