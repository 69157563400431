import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';

import { resetPassword, resetUserErrors } from 'actions/user';
import showNotification from 'components/ui/notification/showNotification';
import PasswordResetForm from 'pages/auth/passwordResetPage/PasswordResetForm';
import PageAuthWrapper from '../components/PageAuthWrapper';
import path from 'helpers/path';

import { AnyObject } from 'types/Common';
import { StoreProps } from 'store';

interface ConnectedProps {
  user: AnyObject;
  settings: AnyObject;
}

type Props = ConnectedProps & StoreProps & IntlProps & WithRouterProps;

interface State {
  email: string;
}

class PasswordResetPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: '',
    };

    props.dispatch(resetUserErrors());
  }

  render() {
    const { email } = this.state;
    const { user, settings } = this.props;

    return (
      <PageAuthWrapper title='auth.reset.header' text='auth.reset.text'>
        <PasswordResetForm
          validation={user && user.validationErrors}
          email={email}
          settings={settings}
          onChange={this.changeFields}
          onReset={this.resetPassword}
          goBack={this.goBack}
        />
      </PageAuthWrapper>
    );
  }

  changeFields = (key: keyof State, value: string) => {
    this.setState({
      [key]: value,
    } as Pick<State, keyof State>);
  };

  resetPassword = () => {
    const { dispatch, history, getTranslate }: any = this.props;
    const { email } = this.state;

    dispatch(
      resetPassword({ email: email.trim() }, () => {
        showNotification({
          status: 'success',
          content: (
            <span>{getTranslate('auth.passwordChangeResetNotification')}</span>
          ),
        });
        history.push(path('/login'));
      })
    );
  };

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
  settings: state.settings,
});

export default withRouter(
  connect(mapStateToProps)(addTranslation(PasswordResetPage))
);
