import { MafContainerType } from './MafContainer';

export default function (maf: MafContainerType) {
  return maf.props.children({
    boarding_status: maf.state.boarding_status,
    mafId: maf.state.mafId,
    legalEntityId: maf.state.legalEntityId,
    currentSteps: maf.currentSteps(),
    isMafFetching: maf.state.isMafFetching,
    data: maf.state.data,
    selectedEntity: maf.getEntityById(maf.state.selectedEntityId),
    currentStep: maf.state.currentStep,
    isUpdating: maf.state.isUpdating,
    isCommitting: maf.state.isCommitting,
    isCommitted: maf.state.isCommitted,
    fetchError: maf.state.fetchError,
    fieldState: maf.fieldState,
    onCreateFormByTemplate: maf.createFormByTemplate,
    getTabByName: maf.getTabByName,
    onChangeEntity: maf.changeEntity,
    onChangeStep: maf.changeStep,
    onDeleteForm: maf.deleteForm,
    onChangeField: maf.changeField,
    onDeleteAllTabs: maf.deleteAllTabs,
    onUpdate: () => maf.update(),
    onCommit: () => maf.commit(),
    onUpdateAndCommit: () => maf.updateAndCommit(),
    onDeleteTab: maf.deleteTab,
    validationErrors: maf.state.validationErrors,
    isValidated: maf.state.isValidated,
    isUpdated: maf.state.isUpdated,
    isEdited: maf.state.isEdited,
    canUpdate: maf.canUpdate(),
    canCommit: maf.canCommit(),
    modalTab: maf.state.modalTab,
    onOpenModalTab: maf.openModalTab,
    onCloseModalTab: maf.handleCloseModalTab,
    confirm: maf.confirm,
    onBlur: maf.onBlur,
    onBackAndSaveStep: maf.onBackAndSaveStep,
    onBackAndCancelStep: maf.onBackAndCancelStep,
    openedModalTabs: maf.modalTabManager.tabs,
    isFormLocked: maf.isFormLocked(),
    forseMarkStepAsFailed: maf.forseMarkStepAsFailed,
  });
}
