import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import { deleteUser, disableUser, enableUser, getUsers } from 'api/admin';
import getConfigurationByName from 'selectors/getConfigurationByName';
import Admin from './AdminPage';
import tableNames from 'constants/tableNames';
import path from 'helpers/path';
import { AnyObject } from 'types/Common';
import { RootState, StoreProps } from 'store';

interface ConnectedProps {
  configuration: AnyObject;
  quickFilters: AnyObject;
  filtersValues: AnyObject;
}

type Props = ConnectedProps & StoreProps & IntlProps & WithRouterProps;

interface State {
  isLoading: boolean;
}

class AdminContainer extends Component<Props, State> {
  state = { isLoading: false };

  render() {
    const { isLoading } = this.state;
    const { configuration } = this.props;
    return (
      <Admin
        canRenderTable
        tableName={tableNames.users}
        isLoading={isLoading}
        apiRequest={getUsers}
        onToggleActivate={(data) => this.toggleActivate(data)}
        configuration={configuration}
        onEditUser={(id) => this.editUser(id)}
        onDeleteUser={(id) => this.deleteUser(id)}
      />
    );
  }

  async toggleActivate(data) {
    try {
      data.status === 'Active'
        ? await disableUser(data.id)
        : await enableUser(data.id);
    } catch (e) {
      console.error(e);
    }
  }

  async deleteUser(id: number) {
    this.setState({ isLoading: true });
    try {
      await deleteUser(id);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async editUser(id: number) {
    const { history } = this.props;
    history.push(path(`/admin/edit/${id}`));
  }
}

const mapStateToProps = (state: RootState): ConnectedProps => ({
  configuration: getConfigurationByName(state, 'users'),
  quickFilters: state.quickFilters.users,
  filtersValues: state.filtersValues,
});

export default connect(mapStateToProps)(addTranslation(AdminContainer));
