export const summaryCurrencies = [
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
  {
    value: 'KZT',
    label: 'KZT',
  },
];
