import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 * Код подтверждения действия
 * @param id
 * @param params
 */
export const sendVerificationCode = (id, params) => {
  return new RpcService().request({
    messages: [
      {
        id,
        type: RpcTypes.Confirm_Psd2,
        payload: { ...params },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Переотправка кода на моб телефон
 * @param verificationId
 */
export const resendVerificationCode = (verificationId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Regenerate_Psd2,
        payload: { verificationId },
      },
    ],
    showValidateNotification: false,
  });
};

export default {
  sendVerificationCode,
  resendVerificationCode,
};
