import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './switch.scss';

interface Props {
  id: string;
  checked: boolean;
  onChange: (event: any) => void;
  text?: string;
  tooltip?: string;
  disabled?: boolean;
  customClass?: string;
  textPosition?: 'left' | 'right' | 'top';
}

class Switch extends PureComponent<Props> {
  static defaultProps = {
    disabled: false,
    customClass: '',
    textPosition: 'right',
  };

  render() {
    const {
      text,
      checked,
      disabled,
      customClass,
      onChange,
      textPosition,
      tooltip,
      id,
    } = this.props;

    return (
      <label
        className={classNames('ui-switch', customClass, {
          'ui-switch_disabled': disabled,
          'ui-switch_checked': checked,
          'ui-switch_text-top': textPosition === 'top',
        })}
        data-tip={tooltip}>
        {text && textPosition !== 'right' && (
          <label htmlFor={id} className='ui-switch__text ui-switch__text_left'>
            {text}
          </label>
        )}
        <div className='ui-switch__control'>
          <input
            id={id}
            type='checkbox'
            className='ui-switch__input'
            onChange={onChange}
            checked={checked}
            disabled={disabled}
          />

          <div className='ui-switch__knobs' />

          <div className='ui-switch__layer' />
        </div>
        {text && textPosition === 'right' && (
          <div className='ui-switch__text'>{text}</div>
        )}
      </label>
    );
  }
}

export default Switch;
