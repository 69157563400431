import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { openModal } from 'actions/modal';
import LocalStorage from 'helpers/LocalStorage';
import DateHelper from 'helpers/Date';

import Animation from 'components/ui/animation';
import Button from 'components/ui/button';
import { StoreProps } from 'store';
import './policyWidget.scss';

interface ConnectedProps {
  settings: {
    visibleLegalLink: boolean;
  };
}

type Props = ConnectedProps & StoreProps & IntlProps;

interface State {
  cookieAccepted: Boolean;
}

class PolicyWidget extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cookieAccepted: false,
    };
  }

  componentDidMount() {
    const { settings } = this.props;
    if (settings.visibleLegalLink) {
      this.checkCookie();
    }
  }

  render() {
    const { settings, getTranslate } = this.props;
    const { cookieAccepted } = this.state;

    if (cookieAccepted || !settings.visibleLegalLink) return null;

    return (
      <Animation name='auto-height'>
        <div className='policy-widget'>
          <div className='policy-widget__item'>
            <p>
              {getTranslate('cookies.popup.text', {
                cookiePolicyLink: (
                  <a
                    target='_blank'
                    href='/cookie-policy'
                    className='policy-widget__link'>
                    {getTranslate('cookies.popup.cookiePolicy.link')}
                  </a>
                ),
                node: true,
              })}
            </p>
          </div>
          <div className='policy-widget__item policy-widget__item_btns'>
            <Button
              status='outline'
              size='normal'
              customClass='policy-widget__btn policy-widget__btn_inline'
              onClick={this.showSettings}
              text={getTranslate('cookies.popup.settings.button')}
            />
            <Button
              size='normal'
              status='primary'
              customClass='policy-widget__btn'
              text={getTranslate('cookies.popup.acceptAll.button')}
              onClick={() =>
                this.setCookie({ essential: true, performance: true })
              }
            />
          </div>
        </div>
      </Animation>
    );
  }

  showSettings = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(
      openModal({
        modalId: 'CookieSettings',
        callback: this.setCookie,
      })
    );
  };

  checkCookie = () => {
    const cookie = LocalStorage.get('cookiesAccepted');
    if (cookie) {
      const expireDate = LocalStorage.get('cookiesAccepted');
      const diffDates = DateHelper.getDiff(
        'days',
        DateHelper.createDate(new Date()),
        expireDate
      );

      if (diffDates <= 0) {
        this.setState({ cookieAccepted: false });
        LocalStorage.remove('cookiesAccepted');
        LocalStorage.remove('performanceCookiesAccepted');
        return;
      }
      this.setState({ cookieAccepted: true });
    }
  };

  setCookie = ({ essential, performance }) => {
    const expireDate = DateHelper.addYears(2).toDate();
    if (essential) {
      LocalStorage.set('cookiesAccepted', expireDate);
      if (performance) {
        LocalStorage.set('performanceCookiesAccepted', expireDate);
      }
      setTimeout(() => {
        this.setState({ cookieAccepted: true });
      }, 500);
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  settings: state.settings,
});

export default connect(mapStateToProps)(addTranslation(PolicyWidget));
