import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { openModal } from 'actions/modal';
import { confirmLeavePage } from 'actions/app';
import { Tab, TabsContainer as Tabs } from 'components/ui/tabs';
import Single from './single';
import Multiple from './multiple';
import { StoreProps } from 'store';
import Messages from 'constants/rpcTypes';
import permissionReasons from 'constants/permissionReasons';
import './addToList.scss';

interface ConnectedProps {
  isConfirmLeaveForm: boolean;
}

interface OwnProps extends IntlProps {
  onCloseForm: () => void;
  onChangeAddType: (index: number) => void;
  defaultOpenedWbForm?: string;
}

interface State {
  currenTab: number;
}

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  WithRouterProps &
  WithPermissions;

class AddToList extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currenTab: Number(props.defaultOpenedWbForm === 'multiple'),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.defaultOpenedWbForm &&
      prevProps.defaultOpenedWbForm !== this.props.defaultOpenedWbForm
    ) {
      this.setState({
        currenTab: Number(this.props.defaultOpenedWbForm === 'multiple'),
      });
    }
  }

  render() {
    const { onCloseForm, getTranslate, isConfirmLeaveForm } = this.props;
    const isSingleAvailable =
      this.props.isEnabled(Messages.Rcs_CreateElement) ||
      this.props.isDisabledByReason(
        Messages.Rcs_CreateElement,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );
    const isBulkAvailable =
      this.props.isEnabled(Messages.Rcs_BulkCreateElement) ||
      this.props.isDisabledByReason(
        Messages.Rcs_BulkCreateElement,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );
    return (
      <div className='add-to-wb-list'>
        <Tabs
          defaultActiveTabIndex={this.state.currenTab}
          onBeforeChange={this.changeTab}
          needUpdateDefaultTab
          customClass='add-to-wb-list__tabs ui-tabs_add-to-list'
          theme='white'>
          {isSingleAvailable && (
            <Tab title={getTranslate('risks.addToList.single.header')}>
              <Single onCloseForm={this.onCloseForm} />
            </Tab>
          )}
          {isBulkAvailable && (
            <Tab title={getTranslate('risks.addToList.mass.header')}>
              <Multiple onCloseForm={onCloseForm} />
            </Tab>
          )}
        </Tabs>
        <Prompt when={isConfirmLeaveForm} message={this.handleNavigation} />
      </div>
    );
  }

  changeTab = (tabIndex) => {
    const { onChangeAddType } = this.props;
    this.handleCloseForm(() => {
      this.setActiveTab(tabIndex);
      onChangeAddType(tabIndex);
    });
  };

  onCloseForm = () => {
    const { onCloseForm } = this.props;
    this.handleCloseForm(() => {
      onCloseForm();
    });
  };

  handleNavigation = (location) => {
    const { history } = this.props;
    if (location.pathname !== this.props.location.pathname) {
      this.handleCloseForm(() => {
        history.push(location.pathname);
      });
      return false;
    }
    return true;
  };

  handleCloseForm = (handler) => {
    const { isConfirmLeaveForm } = this.props;

    if (isConfirmLeaveForm) {
      return this.showConfirmModal(() => {
        setTimeout(() => handler());
      });
    }
    handler();
  };

  showConfirmModal = (callback: () => void) => {
    const { dispatch } = this.props;
    dispatch(
      openModal({
        modalId: 'Confirm',
        content: {
          title: 'risk.modal.cancelAdding.header',
          text: 'risks.modal.cancelAdding.text',
        },
        callback: (onAccept) => {
          if (onAccept) {
            dispatch(
              confirmLeavePage({
                isConfirm: false,
              })
            );
            return callback();
          }
        },
      })
    );
  };

  setActiveTab = (currenTab) => {
    this.setState({ currenTab });
  };
}

const mapStateToProps = (state) => ({
  isConfirmLeaveForm: state.app.isConfirm,
});

export default withRouter(
  connect(mapStateToProps)(addPermissions(addTranslation(AddToList)))
);
