import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import DropZone from 'components/ui/dropzone';
import Button from 'components/ui/button';
import SlideDown from 'components/ui/slideDown';
import { AnyObject } from 'types/Common';

import './batchLoad.scss';

interface Props extends IntlProps {
  file: any;
  isFileUploading: boolean;
  closeLoading: () => void;
  onDeleteFile: () => void;
  onDrop: (files) => void;
  validationErrors: AnyObject;
  isOpen: boolean;
}

const BatchLoad: React.VFC<Props> = ({
  file,
  closeLoading,
  isFileUploading,
  isOpen,
  onDrop,
  onDeleteFile,
  validationErrors,
  getTranslate,
}) => (
  <SlideDown isOpen={isOpen}>
    <div className='batch-load'>
      <div className='batch-load__header'>
        <div className='batch-load__item-right'>
          <Button
            status='outline'
            size='small'
            text={getTranslate('common.close.button')}
            onClick={closeLoading}
            customClass='batch-load__btn'
          />
        </div>
      </div>
      <div className='batch-load__content'>
        <DropZone
          file={file}
          acceptFiles='.csv'
          isFileUploading={isFileUploading}
          onDrop={(files) => onDrop(files[0])}
          onDeleteFile={onDeleteFile}
          errorMessage={validationErrors.file}
        />
      </div>
    </div>
  </SlideDown>
);

export default addTranslation(BatchLoad);
