import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { create } from 'api/lcManage';
import checkAccess from 'decorators/checkAccess';
import { addIsMounted, IsMounted } from 'decorators/addIsMounted';
import { WithRouterProps } from 'decorators/withRouter';
import showNotification from 'components/ui/notification/showNotification';
import LcUpload from './LcUpload';
import path from 'helpers/path';
import Utils from 'helpers/Utils';
import { RowItem } from 'pages/lcmanage/LcManageTypes';
import Messages from 'constants/rpcTypes';

interface OwnProps {
  onCreateSuccess: (row: RowItem) => void;
}

type Props = OwnProps & IsMounted & WithRouterProps;

interface State {
  data: any;
  ticket: string;
  uploadedRows: number;
  isUploading: boolean;
}

@checkAccess([Messages.Localize_CreateLexeme])
class LcUploadContainer extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      ticket: '',
      uploadedRows: 0,
      isUploading: false,
    };
  }

  render() {
    const { data, ticket, isUploading } = this.state;

    return (
      <LcUpload
        data={data}
        ticket={ticket}
        isUploading={isUploading}
        onInputForm={this.parse}
        onStartUpload={this.upload}
        onChangeData={this.changeData}
        onChangeTicket={this.changeTicket}
        onDeleteRow={this.deleteRow}
        onReset={this.reset}
        onRoute={this.goToManage}
      />
    );
  }

  parse = (form) => {
    toast.dismiss();

    if (!form.innerHTML) return;

    const table = form.querySelector('.confluenceTable');
    if (!table) {
      return showNotification({
        status: 'error',
        content: 'Table not found!',
      });
    }

    let keys: any[] = [];
    const ths = table.querySelectorAll('.confluenceTh');
    if (ths.length) {
      ths.forEach((th, i) => {
        keys[i] = th.innerText.trim().toLowerCase();
      });
    } else {
      keys = ['token', 'en', 'ru'];
    }

    const data: any = [];

    const trs = table.querySelectorAll('tr');
    trs.forEach((tr) => {
      const tds = tr.querySelectorAll('.confluenceTd');
      if (!tds.length) {
        return false;
      }

      const row: any = {};
      tds.forEach((td, index) => {
        row[keys[index]] = td.innerText.trim();
      });

      row.uploadStatus = 0;
      data.push(row);
    });

    this.setState({ data });
  };

  upload = () => {
    const { data, ticket, uploadedRows } = this.state;
    const currentRow = data[uploadedRows];

    if (!currentRow) return;

    this.setState({
      isUploading: true,
    });

    const { token, en, ru } = currentRow;

    create({
      token,
      ticket_number: ticket,
      texts: [
        { lang: 'en', text: en },
        { lang: 'ru', text: ru },
      ],
    })
      .then(() => {
        const newValue = uploadedRows + 1;
        this.setState({
          uploadedRows: newValue,
        });
        this.changeData(uploadedRows, 'uploadStatus', 1);
        if (data.length > newValue) {
          return this.upload();
        }

        this.setState({
          isUploading: false,
        });
        showNotification({
          status: 'success',
          content: 'All rows have been uploaded',
        });
      })
      .catch(() => {
        this.setState({
          isUploading: false,
        });
      });
  };

  reset = () => {
    this.setState({
      data: null,
      uploadedRows: 0,
      ticket: '',
    });
    toast.dismiss();
  };

  changeData = (row, key, value) => {
    const { data } = this.state;
    if (data[row] && Utils.hasProp(data[row], key)) {
      const newData = [...data];
      newData[row][key] = value;
      this.setState({ data: newData });
    }
  };

  deleteRow = (row) => {
    const { data } = this.state;
    const newData = [...data];
    newData.splice(row, 1);
    this.setState({ data: newData });
  };

  changeTicket = (ticket) => {
    this.setState({ ticket });
  };

  goToManage = () => {
    const { history } = this.props;
    history.push(path('/lcmanage'));
  };
}

export default withRouter(addIsMounted(LcUploadContainer));
