import modes from 'components/ui/buttonsGroup/modes';
import filtersKeys from 'constants/filters';
import { FilterTypes } from 'constants/FilterTypes';

const payouts = [
  {
    filterId: filtersKeys.paymentStatus,
    type: FilterTypes.buttonsList,
    mode: modes.single,
    view: 'separate',
    metric: 'manualPayments.fastFilter.status',
    list: [
      { id: 'success', text: 'statuses.success' },
      { id: 'processing', text: 'statuses.processing' },
      { id: 'decline', text: 'statuses.decline' },
      { id: 'awaiting clarification', text: 'statuses.clarification' },
    ],
  },
  {
    filterId: filtersKeys.date,
    type: FilterTypes.dateButtons,
    metricName: 'manualPayments',
  },
];

const massPayouts = [
  {
    filterId: filtersKeys.status,
    type: FilterTypes.buttonsList,
    mode: modes.single,
    view: 'separate',
    metric: 'manualPayments.fastFilter.batchStatus',
    list: [
      { id: 'done', text: 'statuses.done' },
      { id: 'processing', text: 'statuses.processing' },
      { id: 'awaiting clarification', text: 'statuses.clarification' },
    ],
  },
  {
    filterId: filtersKeys.date,
    type: FilterTypes.dateButtons,
    metricName: 'manualPayments',
  },
];

export default {
  payouts,
  massPayouts,
};
