import React, { PureComponent } from 'react';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import InvoicesList from './list';
import InvoiceCreate from './create';

interface Props {
  isCreate: boolean;
}

class Invoices extends PureComponent<Props> {
  render() {
    return (
      <PageTemplate.Main topPadding>
        <PageTemplate.Container
          customClass={this.props.isCreate && 'page-invoices'}>
          {this.renderContent()}
        </PageTemplate.Container>
      </PageTemplate.Main>
    );
  }

  renderContent() {
    const { isCreate } = this.props;

    if (isCreate) {
      return <InvoiceCreate />;
    }

    return <InvoicesList />;
  }
}

export default Invoices;
