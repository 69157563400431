import React, { PureComponent, ReactNode } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import CustomSelect from 'components/ui/customSelect';
import SelectItem from 'types/SelectItem';
import './select.scss';

interface Props extends IntlProps {
  id: string;
  selectedValue: { value: string; label: string | ReactNode };
  options: SelectItem[];
  onChange: (filterId: string, value: any) => void;
  withLabel: boolean;
  isDisabled?: boolean;
  label?: string;
  className?: string;
  placeholder?: string;
  isRequired?: boolean;
  tooltip?: string;
}

class Select extends PureComponent<Props> {
  static defaultProps = {
    className: 'filters-select',
  };

  render() {
    const {
      id,
      selectedValue,
      options,
      withLabel,
      onChange,
      label,
      tooltip,
      getTranslate,
      isDisabled,
      className,
      placeholder,
    } = this.props;

    return (
      <div className={className}>
        <CustomSelect
          label={
            withLabel ? getTranslate(label || `filters.fields.${id}.label`) : ''
          }
          placeholder={placeholder || ''}
          isDisabled={isDisabled}
          options={options}
          value={selectedValue}
          onChange={(value) => onChange(id, value)}
          customClass='ui-select_large'
          modern
          containerSelector='.filters__content'
          tooltip={getTranslate(tooltip)}
        />
      </div>
    );
  }
}

export default addTranslation(Select);
