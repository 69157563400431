import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

export const getOnlineBalance = (params) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Balance_RealTime,
        payload: { ...params },
      },
    ],
    isCancelable: true,
  });
};

export const getStatementBalance = (params) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Balance_Statement,
        payload: { ...params },
      },
    ],
  });
};

export const getFinancialReport = (payload: {
  period: string[];
  exactMatch: boolean;
}): Promise<{ contextId: string }> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.FinancialReport_ListRequest,
        payload,
      },
    ],
  });
};

export const downloadFinancialReport = (
  fileKeys: string[],
  userChannel?: string
): Promise<{ status: string }> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.FinancialReport_DownloadRequest,
        payload: { fileKeys, userChannel: userChannel || 'ws' },
      },
    ],
  });
};

export const getCurrencyRates = (params) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.CurrencyRates_List,
        payload: { ...params },
      },
    ],
  });
};
