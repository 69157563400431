import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';
import request from '../helpers/request';
import { saveFileFromBase64 } from 'helpers/saveFile';

export const getDictionaryMaf = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Dictionary_Maf,
      },
    ],
  });
};

export const getCommercialOffer = () => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Maf_DownloadDocument,
        },
      ],
    })
    .then((response) => {
      // file fileData is base64 encoded, get blob from it
      saveFileFromBase64(response.fileName, response.fileData);
    });
};

export const getMaf = (crmId?: string, legalEntityId?: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Maf_Get,
        payload: {
          mafId: crmId,
          legalEntityId,
        },
      },
    ],
  });
};

export const getMafDigest = (legalEntityId: number) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Maf_GetDigest,
        payload: {
          legalEntityId,
        },
      },
    ],
  });
};

export const updateMaf = (mafId, mafData, legalEntityId?: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Maf_Update,
        payload: {
          mafId,
          mafData,
          legalEntityId,
        },
      },
    ],
  });
};

export const setMafWasOpen = (mafId, legalEntityId) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Maf_WasOpen,
        payload: {
          mafId,
          legalEntityId,
        },
      },
    ],
  });
};

export const uploadUserFile = (params: {
  file: null | File;
  mafId: string;
  documentType: string;
  attachmentType: string;
  legalEntityId?: string;
}) => {
  const { file, mafId, attachmentType, documentType, legalEntityId } = params;
  return request({
    method: 'POST',
    url: 'maf/upload-document',
    data: {
      file,
      mafId,
      version: 1,
      attachmentType,
      documentType,
      legalEntityId,
    },
    headers: { 'Content-Type': 'multipart/form-data' },
    errorHandle: false,
  }).then(({ data }: any) => data.payload);
};

export const commitMaf = (mafId, mafData, legalEntityId?: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Maf_Commit,
        payload: {
          mafId,
          mafData,
          legalEntityId,
        },
      },
    ],
  });
};

export const getDictionary = (dictionaryName, filter) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Dictionary_RequestCrmEnum,
        payload: {
          dictionaryName,
          filter,
        },
      },
    ],
  });
};
