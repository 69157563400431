import React, { FC } from 'react';
import classNames from 'classnames';
import './liveLogo.scss';

interface Props {
  customClass?: string;
}

const LiveLogo: FC<Props> = ({ customClass }) => {
  return (
    <div className={classNames('demo-logo', customClass)}>
      {/*<div className='demo-logo__button' />*/}
      <div className='demo-logo__label'>Demo</div>
    </div>
  );
};

export default LiveLogo;
