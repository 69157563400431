export const statusThemes = {
  blue: 'blue',
  red: 'red',
  green: 'green',
  yellow: 'yellow',
  gray: 'gray',
  purple: 'purple',
  violet: 'violet',
  teal: 'teal',
  black: 'black',
  white: 'white',
  mint: 'mint',
  orange: 'orange',
};

export default {
  success: 'success',
  cancel: 'cancel',
  awaiting: 'awaiting',
  refund: 'refund',
  processing: 'processing',
  decline: 'decline',
  error: 'error',
  fraud: 'fraud',

  active: 'active',
  inactive: 'inactive',
  blocked: 'blocked',

  black: 'black',
  white: 'white',

  notSet: 'not set',
  expired: 'expired',
  cancelled: 'cancelled',
  failed: 'failed',

  clarification: 'clarification',
  draft: 'draft',
  published: 'published',

  chargeback: 'chargeback',
};
