import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 * Получить данные о payment
 * @param transactionId
 */
export const getPaymentById = (transactionId: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Payments_Payment,
        payload: { transactionId },
      },
    ],
  });
};

export const getPaymentByProject = (payload: {
  projectId: string;
  paymentId: string;
}) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Payments_Payment,
        payload,
      },
    ],
  });
};

/**
 * Данные о возможностях refund, capture и т.д
 * @param transactionId
 */
export const getPaymentAvailableActions = ({ transactionId }) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentOperation_AvailableActions,
        payload: {
          transactionId,
        },
      },
    ],
  });
};

/**
 * Инициировать рефанд
 * @param params
 */
export const paymentRefund = (params) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentOperation_Refund,
        payload: {
          operation: params,
        },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Инициировать capture
 * @param params
 */
export const paymentCapture = (params) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentOperation_Capture,
        payload: {
          operation: params,
        },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Инициировать cancel
 * @param params
 */
export const paymentCancel = (params) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentOperation_Cancel,
        payload: {
          operation: params,
        },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 *
 * @param projectId
 * @param transactionId
 */
export const getFilledPayout = ({ projectId, transactionId }) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentOperation_PreFilledPayout,
        payload: {
          projectId,
          transactionId,
        },
      },
    ],
  });
};

/**
 * Переслать payment callback
 * @param transactionId
 * @param operationId
 */
export const resendPaymentCallback = (
  transactionId: string,
  operationId: string
) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Payments_ResendCallback,
        payload: { transactionId, operationId },
      },
    ],
  });
};

export default {
  getPaymentById,
  getPaymentAvailableActions,
  paymentRefund,
  resendPaymentCallback,
};
