import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';
import request from 'helpers/request';

export const getRemittanceList = (payload): Promise<any> => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Remittance_ListRecipientAccounts,
          payload,
        },
      ],
      isCancelable: true,
    })
    .then((data) => ({
      items: data.rows || [],
      totalRows: Infinity,
    }));
};

export const getRecipientAccount = (
  remittanceRecipientAccountId
): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Remittance_GetRecipientAccount,
        payload: {
          remittanceRecipientAccountId,
        },
      },
    ],
  });
};

export const createRemittance = (payload): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Remittance_CreateRecipientAccount,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};
export const updateRemittance = (payload): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Remittance_UpdateRecipientAccount,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

export const deleteRemittance = (
  remittanceRecipientAccountId
): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Remittance_DeleteRecipientAccount,
        payload: {
          remittanceRecipientAccountId,
        },
      },
    ],
  });
};

export const createRemittancePayment = (payload): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Remittance_CreatePayment,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

export const getRemittancePaymentsList = (payload): Promise<any> => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Remittance_ListPayments,
          payload,
        },
      ],
      isCancelable: true,
    })
    .then((data) => ({
      items: data.rows || [],
      totalRows: Infinity,
    }));
};

export const getRemittancePayment = (transactionId: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Remittance_GetPayment,
        payload: { transactionId },
      },
    ],
  });
};

export const uploadFile = (file: File) => {
  return request({
    method: 'POST',
    url: 'app/remittance/payment-batch-upload',
    data: { file },
    headers: { 'Content-Type': 'multipart/form-data' },
    errorHandle: false,
  }).then(({ data }: any) => data.payload.batch);
};

export const getBatchPreview = (remittancePaymentBatchId: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Remittance_PaymentBatchPreview,
        payload: { remittancePaymentBatchId },
      },
    ],
  });
};

export const confirmBatch = (remittancePaymentBatchId: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Remittance_PaymentBatchConfirm,
        payload: { remittancePaymentBatchId },
      },
    ],
    showValidateNotification: false,
  });
};

export const removeItemFromBatch = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Remittance_PaymentBatchRemoveItem,
        payload,
      },
    ],
  });
};

export const getRemittancePaymentBatchList = (payload) => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Remittance_PaymentBatchList,
          payload,
        },
      ],
      isCancelable: true,
    })
    .then((data) => ({
      items: data.rows || [],
      totalRows: Infinity,
    }));
};

export const getRemittanceBatchDetail = (remittancePaymentBatchId) => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Remittance_PaymentBatchView,
          payload: {
            remittancePaymentBatchId,
          },
        },
      ],
      isCancelable: true,
    })
    .then((data) => ({
      items: data.rows || [],
      totalRows: Infinity,
    }));
};
