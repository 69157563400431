import React from 'react';
import SubmittedForm from 'components/formFields/components/submittedForm';
import './mafReviewInformer.scss';

export const MafReviewInformer = ({
  onButtonClick,
}: {
  onButtonClick: () => void;
}) => (
  <div className='maf-review-informer__conteiner'>
    <div className='maf-review-informer__content card'>
      <SubmittedForm
        id='dafWaiting'
        status='success'
        title='businessDocs.waitingForDocsVerification.label'
        text='businessDocs.waitingForDocsVerification.text'
        primaryButtonText='businessDocs.backToList.button'
        onPrimaryButtonClick={onButtonClick}
      />
    </div>
  </div>
);
