import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import { AnyObject } from 'types/Common';

interface Props {
  data: AnyObject;
  columns: string[];
  showAllChildren: boolean;
  level: number;
}

interface State {
  showChildren: boolean;
}

class TreeTableRow extends PureComponent<Props, State> {
  static defaultProps = {
    level: 0,
    showAllChildren: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showChildren: props.showAllChildren,
    };
  }

  componentDidUpdate(prevProps) {
    const { showAllChildren } = this.props;
    if (this.hasChild() && showAllChildren !== prevProps.showAllChildren) {
      this.setState({ showChildren: showAllChildren });
    }
  }

  render() {
    const { data, columns, level, showAllChildren } = this.props;
    const { showChildren } = this.state;
    const hasChildren = this.hasChild();

    return (
      <div
        className={classNames(
          'ui-tree-table__row',
          `ui-tree-table__row_level-${level}`,
          {
            'ui-tree-table__row_show-children': showChildren,
            'ui-tree-table__row_has-children': hasChildren,
          }
        )}>
        <div onClick={this.toggleRow} className='ui-tree-table__columns'>
          {columns.map((column, index) => (
            <div
              className={`ui-tree-table__column ui-tree-table__column_${column}`}
              key={index}>
              <span className='ui-tree-table__column-inner'>
                {index === 0 && hasChildren && (
                  <div className='ui-tree-table__arrow'>
                    <Icon
                      size={10}
                      name='im-Arrow-down'
                      className='ui-tree-table__arrow-icon'
                    />
                  </div>
                )}
                {data[column]}
              </span>
            </div>
          ))}
        </div>
        {hasChildren && showChildren && (
          <div className='ui-tree-table__children'>
            {data.rows.map((row, index) => (
              <TreeTableRow
                key={index}
                data={row}
                columns={columns}
                level={level + 1}
                showAllChildren={showAllChildren}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  toggleRow = (event) => {
    const { showChildren } = this.state;
    if (this.hasChild()) {
      event.stopPropagation();
      this.setState({
        showChildren: !showChildren,
      });
    }
  };

  hasChild = () => {
    return this.props.data.rows;
  };
}

export default TreeTableRow;
