import React, { isValidElement, PureComponent } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import Animation from 'components/ui/animation';
import RoundButton from 'components/ui/roundButton';
import themes from '../themes';
import './tabs.scss';

interface TabProps {
  onClick: (tabIndex: number) => void;
  tabIndex: number;
  isActive: boolean;
  isDisabled?: boolean;
  theme?: string;
  mode?: string;
  id?: string;
}

interface Props {
  theme?: keyof typeof themes;
  mode?: 'vertical';
  animation?: string;
  customClass?: any;
  onClick?: (tabIndex: number) => void;
  isShowTabNames?: boolean;
  hideEmpty?: boolean;
  canRenderContent: boolean;
  activeTabIndex: number;
  handleClickTab: (tabIndex: number, tabId?: string) => void;
  needButtons?: boolean;
  infoText?: string;
  separate?: boolean;
}

class Tabs extends PureComponent<Props> {
  static defaultProps = {
    canRenderContent: true,
    animation: 'fadeIn',
    customClass: '',
    isShowTabNames: true,
  };

  render() {
    const {
      animation,
      theme,
      isShowTabNames,
      customClass,
      mode,
      canRenderContent,
      infoText,
      separate,
    } = this.props;
    const classes = classNames('ui-tabs', {
      [`ui-tabs_${theme}`]: theme,
      [`ui-tabs_${mode}`]: mode,
      [customClass]: !!customClass,
      'ui-tabs_no-titles': !isShowTabNames,
    });

    return (
      <div className={classes}>
        {isShowTabNames && (
          <div
            className={classNames('ui-tabs__header', {
              'card card_l': separate,
            })}>
            <div className='ui-tabs__col'>{this.renderHeaderWithProps()}</div>
            {infoText && (
              <div className='ui-tabs__col ui-tabs__col_text'>{infoText}</div>
            )}
          </div>
        )}
        <div className='ui-tabs__content'>
          {canRenderContent && (
            <Animation name={animation}>
              {this.renderActiveTabContent()}
            </Animation>
          )}
        </div>
      </div>
    );
  }

  renderActiveTabContent() {
    const { children, activeTabIndex } = this.props;
    if (!children) return null;

    const child: any = Array.isArray(children) ? children : [children];
    if (!child[activeTabIndex]) return null;
    return child[activeTabIndex].props.children;
  }

  renderHeaderWithProps() {
    const {
      children,
      theme,
      mode,
      handleClickTab,
      activeTabIndex,
      needButtons,
    } = this.props;
    if (needButtons) {
      SwiperCore.use([Navigation]);
      return (
        <>
          <Swiper
            spaceBetween={0}
            slidesPerView='auto'
            navigation={{
              prevEl: '.ui-tabs__arrow_prev',
              nextEl: '.ui-tabs__arrow_next',
            }}
            allowTouchMove={false}
            centeredSlides={false}>
            {React.Children.map(children, (child, index) => {
              if (!child) return null;
              if (isValidElement<TabProps>(child)) {
                const { isDisabled } = child.props;
                return (
                  <SwiperSlide>
                    {React.cloneElement(child, {
                      onClick: isDisabled ? () => null : handleClickTab,
                      tabIndex: index,
                      isActive: index === activeTabIndex,
                      isDisabled: child.props.isDisabled,
                      id: child.props.id,
                      theme,
                      mode,
                    })}
                  </SwiperSlide>
                );
              }
            })}
          </Swiper>
          <div className='utils-align ui-tabs__arrow_prev'>
            <RoundButton
              icon='im-Arrow-left-Option-2'
              status='primary'
              iconSize={12}
              size={26}
            />
          </div>
          <div className='utils-align ui-tabs__arrow_next'>
            <RoundButton
              icon='im-Arrow-right-Option-2'
              status='primary'
              iconSize={12}
              size={26}
            />
          </div>
        </>
      );
    }
    return React.Children.map(children, (child, index) => {
      if (!child) return null;
      if (isValidElement<TabProps>(child)) {
        const { isDisabled } = child.props;
        return React.cloneElement(child, {
          onClick: isDisabled ? () => null : handleClickTab,
          tabIndex: index,
          isActive: index === activeTabIndex,
          isDisabled: child.props.isDisabled,
          id: child.props.id,
          theme,
          mode,
        });
      }
    });
  }
}

export default Tabs;
