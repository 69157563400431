import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import {
  deleteRemittance,
  getRemittanceList,
  getRemittancePaymentBatchList,
  getRemittancePaymentsList,
} from 'api/remittance';
import { deleteDataItem } from 'actions/getData';
import { WithRouterProps } from 'decorators/withRouter';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addListeners } from 'decorators/addListeners';
import Remittance from './Remittance';
import getConfigurationByName from 'selectors/getConfigurationByName';
import { getPath, isCustomId } from 'pages/remittance/helpers';
import path from 'helpers/path';
import tableNames from 'constants/tableNames';
import Messages from 'constants/rpcTypes';
import urlsMap from 'constants/urlsMap';
import { createRoutes, tableRoutes } from './routesMapper';
import { StoreProps } from 'store';
import ItemConfiguration from 'types/ItemConfiguration';
import './remittance.scss';

interface ConnectedProps {
  paymentConfiguration: ItemConfiguration[];
  accountConfiguration: ItemConfiguration[];
  massRemittancePaymentsConfiguration: ItemConfiguration[];
}

type Props = ConnectedProps &
  StoreProps &
  IntlProps &
  WithRouterProps<{ id: string }>;

export type tableNameType =
  | tableNames.massRemittancePayments
  | tableNames.remittancePayments
  | tableNames.remittanceRecipientAccounts;

interface State {
  tableName: tableNameType;
}

@addListeners([Messages.Remittance_DeleteRecipientAccount])
class RemittanceContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tableName: this.setTableFromRoute() || tableNames.remittancePayments,
    };
  }

  componentDidMount() {
    this.checkRoute();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (prevProps.match.url !== this.props.match.url) {
      if (
        !this.props.match.params.id &&
        prevProps.match.params.id &&
        !isCustomId(prevProps.match.params.id)
      ) {
        this.props.history.replace(path(`/${urlsMap.remittance}`));
        this.setState({
          tableName: tableNames.remittancePayments,
        });
      } else {
        this.checkRoute();
      }
    }
  }

  render() {
    const { match, location } = this.props;
    const { tableName } = this.state;

    const settings = this.getSettings();
    if (!settings?.configuration) return null;

    const { type } = queryString.parse(location.search);
    const isBatchDetail = Boolean(type === 'batch');

    return (
      <Remittance
        tableName={tableName}
        pathname={location.pathname}
        id={match.params.id}
        isNewTab={match.path.includes(`${urlsMap.remittance}/create`)}
        configuration={settings.configuration}
        apiRequest={settings.request}
        onClickItem={this.goToCard}
        onDelete={this.onDelete}
        changeCurrentTable={this.changeCurrentTable}
        isRecipientAccountCard={Boolean(
          match.params.id && isCustomId(match.params.id) && !location.search
        )}
        goToBuilder={this.goToBuilder}
        isBatchDetail={isBatchDetail}
      />
    );
  }

  checkRoute = () => {
    const { match } = this.props;
    if (!Object.values(tableRoutes).includes(match.params.id)) {
      if (Object.values(createRoutes).includes(match.params.id)) {
        return;
      }
      if (!match.params.id) {
        this.props.history.replace(`${this.props.match.url}`);
      }
    } else {
      this.setState({
        tableName: this.setTableFromRoute() || tableNames.remittancePayments,
      });
    }
  };

  setTableFromRoute = (): tableNameType | undefined => {
    return (Object.keys(tableRoutes) as Array<tableNameType>).filter(
      (tableName) => tableRoutes[tableName] === this.props.match.params.id
    )[0];
  };

  getSettings = () => {
    switch (this.state.tableName) {
      case tableNames.remittancePayments:
        return {
          request: getRemittancePaymentsList,
          configuration: this.props.paymentConfiguration,
        };
      case tableNames.massRemittancePayments:
        return {
          request: getRemittancePaymentBatchList,
          configuration: this.props.massRemittancePaymentsConfiguration,
        };
      case tableNames.remittanceRecipientAccounts:
        return {
          request: getRemittanceList,
          configuration: this.props.accountConfiguration,
        };
    }
  };

  goToCard = (data) => {
    const { history } = this.props;
    const { tableName } = this.state;
    const pathToGo = getPath(tableName, data);
    history.push(path(pathToGo));
  };

  onDelete = async (id) => {
    await deleteRemittance(id);
  };

  changeCurrentTable = (tableName) => {
    const { history } = this.props;
    this.setState({ tableName });

    if (tableName !== tableNames.remittancePayments) {
      history.push(path(`/${urlsMap.remittance}/${tableRoutes[tableName]}`));
    } else {
      history.push(path(`/${urlsMap.remittance}`));
    }
  };

  goToBuilder = () => {
    const { history } = this.props;

    history.push(path(getPath(this.state.tableName, {}, true)));
  };

  onEvent = ({ data }) => {
    const { dispatch } = this.props;

    dispatch(
      deleteDataItem(
        tableNames.remittanceRecipientAccounts,
        'remittanceRecipientAccountId',
        data.payload.remittanceRecipientAccountId
      )
    );
  };
}

const mapStateToProps = (state): ConnectedProps => {
  return {
    paymentConfiguration: getConfigurationByName(
      state,
      tableNames.remittancePayments
    ),
    massRemittancePaymentsConfiguration: getConfigurationByName(
      state,
      tableNames.massRemittancePayments
    ),
    accountConfiguration: getConfigurationByName(
      state,
      tableNames.remittanceRecipientAccounts
    ),
  };
};

export default withRouter(
  connect(mapStateToProps)(addTranslation(RemittanceContainer))
);
