import { AdvancedAnalyticsEntityType } from '../../AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';
import { templateOperationConversion } from './operations';
import { templateInoutTurnover, templateInoutSalesAgentsTurnover } from './inout';
import { templateTopTenIssuer } from './topten';
import { AnyObject } from '../../../../../../../types/Common';

const getTemplateData = ({
  getTranslate,
  selectedType,
  dictionary: { projects },
}): AnyObject | void => {
  switch (selectedType) {
    case AdvancedAnalyticsEntityType.operations:
      const operationTemplate = templateOperationConversion(
        getTranslate,
        projects
      );
      return { ...operationTemplate.filters, ...operationTemplate.chartParams };
    case AdvancedAnalyticsEntityType.inout:
      const pivotTurnover = templateInoutTurnover(getTranslate, projects);
      return { ...pivotTurnover.filters, ...pivotTurnover.chartParams };
    case AdvancedAnalyticsEntityType.inout_sales_agents:
      const pivotSalesAgentsTurnover = templateInoutSalesAgentsTurnover(getTranslate, projects);
      return { ...pivotSalesAgentsTurnover.filters, ...pivotSalesAgentsTurnover.chartParams };
    case AdvancedAnalyticsEntityType.topten:
      const topTenIssuer = templateTopTenIssuer(getTranslate, projects);
      return { ...topTenIssuer.filters, ...topTenIssuer.chartParams };
    default:
      break;
  }
};

export { getTemplateData };
