export default {
  team: 'im-My-team',
  user: 'im-Avatar',
  subscriptions: 'im-Subscription',
  report_template: 'im-Reports',
  remittance: 'im-Remmitance',
  rcs: 'im-Risk',
  project: 'im-Projects',
  payment: 'im-Payments',
  manual_payment: 'im-Payout',
  maf: 'im-Many-Files',
  invoice: 'im-Invoice',
  finance: 'im-Finance',
  dashboard_admin: 'im-Configurator-2',
  analytics: 'im-Analytics-2',
  reconciliation: 'im-Reconcilations',
  qa: 'im-Touch',
};
