import BaseMessageHandler from '../BaseMessageHandler';
import { updateDataItemProperties } from 'actions/getData';
import tableNames from 'constants/tableNames';

export default class ReportTemplate_Activate extends BaseMessageHandler {
  success = () => {
    const { dispatch, message } = this;
    dispatch(
      updateDataItemProperties(
        tableNames.reports,
        'reportTemplateId',
        ['taskStatus'],
        message.payload
      )
    );
  };
}
