import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import Mapper from 'helpers/Mapper';
import './row.scss';
import './row-statuses.scss';

const TableRow: React.FC<{
  id?: string;
  customClass?: string;
  onClick?: (event: MouseEvent) => void;
  onMouseDown?: (event: MouseEvent) => void;
  onMouseUp?: (event: MouseEvent) => void;
  onDoubleClick?: (event: MouseEvent) => void;
  isActive?: boolean;
  needAnimate?: boolean;
  isBordered?: boolean;
  status?: string;
}> = ({
  customClass,
  isActive,
  needAnimate,
  status,
  isBordered = true,
  ...rest
}) => {
  const animationClass =
    status === 'error'
      ? 'ui-table__row_animate-error'
      : 'ui-table__row_animate-success';

  const statusMod = Mapper.getStatus(status);
  return (
    <div
      className={classNames('ui-table__row', customClass, {
        [`ui-table__row_status ui-table__row_status-${statusMod}`]:
          statusMod && isBordered,
        [animationClass]: needAnimate,
      })}
      {...rest}
    />
  );
};

export default TableRow;
