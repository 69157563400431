import React, { Component } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import MetricService from 'helpers/metricService/MetricService';
import './payout.scss';
import classNames from 'classnames';
import statuses from 'components/ui/button/statuses';

interface Props extends IntlProps {
  onLoadData: () => void;
  isLoading: boolean;
  customClass: string;
  buttonTheme?: keyof typeof statuses;
}

class Payout extends Component<Props> {
  render() {
    const { isLoading, onLoadData, customClass, buttonTheme, getTranslate } =
      this.props;

    return (
      <div className={classNames('payment-payout', customClass)}>
        <Button
          size='normal'
          status={buttonTheme}
          onClick={() => {
            this.sendMetric();
            onLoadData();
          }}
          text={getTranslate('payment.single.payout.Button')}
          loading={isLoading}
          customClass='payment-payout__button'
        />
      </div>
    );
  }

  sendMetric = () => {
    MetricService.send({
      action: 'click',
      actionKey: 'payments.paymentCard.payout',
    });
  };
}

export default addTranslation(Payout);
