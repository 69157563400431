import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { editContent, openModal } from 'actions/modal';
import { getRecurring } from 'actions/recurring';
import { addEntityToSaved, updateEntity } from 'actions/savedEntities';
import { WithRouterProps } from 'decorators/withRouter';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';
import getPaymentById from 'selectors/getPaymentById';
import RecurringCard from './RecurringCard';
import UiStateFactory from 'factories/UiStateFactory';
import savedEntities from 'constants/savedEntities';
import SavedRecurring from 'types/savedEntity/SavedRecurring';
import { AnyObject } from 'types/Common';
import SavedEntity from 'types/savedEntity';
import { getRecurringSettings } from 'api/recurring';
import {
  RecurringSettingsForm,
  RecurringSettingsHistory,
} from './settings/RecurringSettingsTypes';
import { StoreProps } from 'store';
import { addListeners } from 'decorators/addListeners';
import Messages from 'constants/rpcTypes';

interface OwnProps {
  id: string;
}

interface ConnectedProps {
  user: AnyObject;
  recurring: SavedRecurring;
  storedSavedEntities: { isFetch: boolean; items: SavedEntity[] };
}

type Props = OwnProps & ConnectedProps & StoreProps & WithRouterProps;

interface State {
  historyList: RecurringSettingsHistory;
  form: RecurringSettingsForm;
  isSettingsLoading: boolean;
  isShowHistory: boolean;
  isLoading: boolean;
}

@wrapAppWithCssClass('layout-app_tablet-width')
@addListeners([Messages.SubscriptionPayment_Update])
class RecurringCardContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      form: null,
      historyList: [],
      isSettingsLoading: false,
      isShowHistory: false,
      isLoading: !props.recurring,
    };
  }

  async componentDidMount() {
    await this.init();
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    const { user, storedSavedEntities } = this.props;
    if (user && prevProps.user.timezone !== user.timezone) {
      this.fetch();
    }

    if (!prevProps.storedSavedEntities && storedSavedEntities) {
      await this.init();
    }
  }

  render() {
    const { recurring } = this.props;
    const { historyList, isSettingsLoading, isShowHistory, isLoading } =
      this.state;

    return (
      <RecurringCard
        recurring={recurring}
        syncPaymentUI={this.syncPaymentUIWithStore}
        historyList={historyList}
        isSettingsLoading={isSettingsLoading}
        isLoading={isLoading}
        isShowHistory={isShowHistory}
        openSettingsForm={() => this.onOpenSettings()}
        toggleHistory={this.toggleHistory}
      />
    );
  }

  async init() {
    const { id, recurring, location, storedSavedEntities, dispatch } =
      this.props;
    const urlParams = queryString.parse(location.search);

    if (!storedSavedEntities) return;

    const actionParams: any = {};
    if (urlParams.name) {
      actionParams.name = urlParams.name;
    }

    if (!recurring) {
      const entityKey = savedEntities.recurring;
      dispatch(addEntityToSaved({ entityKey, id, urlParams: actionParams }));
    }

    if (!recurring || (recurring && !recurring.data)) {
      const ui = UiStateFactory.getInitialRecurringCardUI();
      this.syncPaymentUIWithStore(id, ui);
      this.fetch();
    }
  }

  fetch = () => {
    const { id, dispatch } = this.props;
    this.setState({ isLoading: true });

    dispatch(
      getRecurring(id, () => {
        this.setState({ isLoading: false });
      })
    );
  };

  async fetchSettings(isHistory = false) {
    try {
      this.setState({
        isSettingsLoading: true,
      });

      this.setSettingsLoader(true);

      const { settings, history } = await getRecurringSettings(
        this.props.recurring.id
      );

      if (!settings.recurringInterval) {
        settings.recurringInterval = '1';
      }

      this.setState(
        {
          form: settings,
          historyList: history?.list || [],
        },
        () => {
          if (!isHistory && settings) {
            this.openModal();
          }
        }
      );
    } finally {
      this.setState({
        isSettingsLoading: false,
      });
    }
  }

  onOpenSettings = () => {
    if (!this.state.isShowHistory) {
      this.fetchSettings();
    } else {
      this.openModal();
    }
  };

  setSettingsLoader = (isSettingsLoading) => {
    this.setState({ isSettingsLoading });
  };

  toggleHistory = () => {
    this.setState(
      (state) => ({ isShowHistory: !state.isShowHistory }),
      () => {
        if (this.state.isShowHistory && !this.state.historyList.length) {
          this.fetchSettings(true);
        } else if (!this.state.isShowHistory) {
          this.setState({
            historyList: [],
          });
        }
      }
    );
  };

  syncPaymentUIWithStore = (id, ui) => {
    const { dispatch } = this.props;
    const entityKey = savedEntities.recurring;
    dispatch(updateEntity({ entityKey, id, fields: { ui } }));
  };

  openModal = () => {
    const { recurring } = this.props;
    this.props.dispatch(
      openModal({
        modalId: 'RecurringSettingsForm',
        tooltipId: 'recurring-settings-form',
        content: {
          form: this.state.form,
          transactionId: recurring.id,
        },
      })
    );
  };

  onEvent = ({ data }) => {
    if (data.payload.validationErrors) return;
    this.setState({ form: data.payload.settings });
    this.props.dispatch(editContent({ form: data.payload.settings }));
  };
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => ({
  user: state.user,
  recurring: getPaymentById(state, savedEntities.recurring, ownProps.id),
  storedSavedEntities: state.savedEntities[savedEntities.recurring],
});

export default withRouter(connect(mapStateToProps)(RecurringCardContainer));
