import React, { PureComponent } from 'react';
import './reportTemplate.scss';
import RoundButton from 'components/ui/roundButton';

interface Props {
  title: string;
  onClick: (id) => void;
  onDelete?: () => void;
}

class reportTemplate extends PureComponent<Props> {
  render() {
    const { title, onClick, onDelete } = this.props;

    return (
      <div onClick={onClick} className='report-template' tabIndex={0}>
        <span className='report-template__title'>{title}</span>
        {onDelete && (
          <RoundButton
            status='danger'
            size={26}
            icon='im-Basket'
            iconSize={12}
            onClick={(e) => {
              e.stopPropagation();
              return onDelete();
            }}
            customClass='report-template__button'
          />
        )}
      </div>
    );
  }
}

export default reportTemplate;
