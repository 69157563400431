import React, { VFC } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import { WithRouterProps } from 'decorators/withRouter';

import { DafStatusInformerLayout } from './DafStatusInformerLayout';
import I18nHelpers from 'i18n/I18nHelpers';
import { getCommercialOffer } from 'api/maf';

import waitingImage from 'images/getStarted/waiting.svg';

interface ConnectedProps {
  email: string;
}

export const DocumentsWereSubmitted: VFC<
  ConnectedProps &
    IntlProps &
    WithRouterProps & { needDownloadButton?: boolean }
> = ({ email, getTranslate, needDownloadButton, history }) => (
  <DafStatusInformerLayout
    title={getTranslate('getStarted.DAFsubmitted.label')}
    mainText={I18nHelpers.getJsxFromHtml(
      getTranslate('getStarted.DAFsubmitted.text', {
        email: `<a href="mailto:${email}">${email}</a>`,
      })
    )}
    buttons={
      needDownloadButton ? (
        <Button
          id='comOfferDownload'
          status='outline'
          onClick={() => getCommercialOffer()}
          text={getTranslate('getStarted.downloadOffer.button')}
        />
      ) : (
        <Button
          id='goToDaf'
          status='primary'
          onClick={() => history.push('/maf')}
          text={getTranslate('getStarted.fillOut.button')}
        />
      )
    }
    image={<img src={waitingImage} alt='illustration' />}
  />
);

const mapStateToProps = (state): ConnectedProps => ({
  email: state.user?.boarding?.email || state.settings.salesEmail,
});

export default withRouter(
  connect(mapStateToProps)(addTranslation(DocumentsWereSubmitted))
);
