import Utils from 'helpers/Utils';
import checkFilters from 'helpers/checkFilters';
import { FilterTypes } from 'constants/FilterTypes';
import { AnyObject } from 'types/Common';

const typesWithValues = [
  FilterTypes.multiSelect,
  FilterTypes.checkboxList,
  FilterTypes.select,
  FilterTypes.radioList,
  FilterTypes.buttonsList,
];

class FiltersHelper {
  /**
   *
   * @param filters
   * @param dictionaryValues
   * @param availableValues
   */
  static prepareFiltersForState(
    filters,
    dictionaryValues = {},
    availableValues = {}
  ) {
    const filtersWithValues = {};

    for (const prop in filters) {
      if (Utils.hasProp(filters, prop)) {
        const currentFilter = { ...filters[prop] };
        const key = Array.isArray(filters) ? currentFilter.filterId : prop;
        const { type, dictionaryId, value } = currentFilter;

        const filterValues = { ...dictionaryValues[dictionaryId] };

        if (!filterValues || !filterValues.list) {
          filtersWithValues[key] = currentFilter;
          continue;
        }

        const ids = availableValues[key];
        if (Array.isArray(availableValues[key])) {
          filterValues.list = filterValues.list?.filter(({ id }) => {
            return ids.find((iId) => iId === id);
          });
        }

        if (
          type === FilterTypes.multiSelect ||
          type === FilterTypes.checkboxList
        ) {
          const values: any = [];
          filterValues.list?.forEach((item) => {
            values.push({
              id: item.id ?? item.projectId,
              text: item.text,
              isSelected: value?.includes(item.id ?? item.projectId),
              isFavorite: item.isFavorite,
            });
          });

          currentFilter.value = values;
          currentFilter.availableValues = [...values];
          currentFilter.isLoading = filterValues.isLoading;
          currentFilter.isFetched = filterValues.isFetched;
        } else if (type === FilterTypes.select) {
          const options: any[] = [];
          let selectedValue: any = null;
          filterValues.list?.forEach(({ id, text }) => {
            const currentObj = {
              value: id,
              label: text,
            };
            options.push(currentObj);
            if (value === id) {
              selectedValue = currentObj;
            }
          });
          currentFilter.options = options;
          currentFilter.value = selectedValue;
          currentFilter.availableValues = [...options];
          currentFilter.isLoading = filterValues.isLoading;
          currentFilter.isFetched = filterValues.isFetched;
        } else if (type === FilterTypes.radioList) {
          const items: any[] = [];
          filterValues.list?.forEach(({ id, text }) => {
            items.push({
              id,
              text,
            });
          });
          currentFilter.items = items;
          currentFilter.value = value || '';
          currentFilter.isLoading = filterValues.isLoading;
          currentFilter.isFetched = filterValues.isFetched;
          if (!currentFilter.initialValue) {
            currentFilter.initialValue = value;
          }
        } else if (type === FilterTypes.dateTime) {
          currentFilter.activePreset = '';
        }

        filtersWithValues[key] = currentFilter;
      }
    }

    return filtersWithValues;
  }

  static getFiltersValues = async (
    filters: AnyObject,
    dictionaryValues,
    additionalSectionName?
  ) => {
    const filtersNames: string[] = [];

    const getFilterNames = (item) => {
      if (
        (item.isDynamic || typesWithValues.includes(item.type)) &&
        !dictionaryValues[item.dictionaryId] &&
        !filtersNames.includes(item.type)
      ) {
        filtersNames.push(item.dictionaryId);
      }
    };

    if (Array.isArray(filters)) {
      filters.forEach((item) => {
        if (item.filterId) {
          getFilterNames(item);
          /* if (item.dictionaryId && !filtersNames.includes(item.type)) {
            filtersNames.push(item.dictionaryId);
          }*/
        } else {
          Object.values(item).forEach(getFilterNames);
        }
      });
    } else {
      Object.values(filters).forEach(getFilterNames);
    }
    if (filtersNames.length) {
      await checkFilters(filtersNames, additionalSectionName);
    }
  };

  /**
   *
   * @param filters
   */
  static prepareFiltersForStore(filters) {
    const newFilters: any[] = [];

    for (const key in filters) {
      if (Utils.hasProp(filters, key)) {
        const currentFilter = { ...filters[key] };
        const { type, value } = currentFilter;

        if (
          type === FilterTypes.multiSelect ||
          type === FilterTypes.checkboxList
        ) {
          const selectedValues: any = [];
          value.forEach((val) => {
            if (val.isSelected) {
              selectedValues.push(val.id);
            }
          });
          currentFilter.value = selectedValues;
        } else if (type === FilterTypes.select) {
          currentFilter.value = value ? value.value : '';
        }

        if (typeof currentFilter.value === 'string') {
          currentFilter.value = currentFilter.value.trim();
        }

        delete currentFilter.availableValues;

        newFilters.push(currentFilter);
      }
    }
    return newFilters;
  }

  /**
   *
   * @param filters
   */
  static resetFiltersValuesByTab(filters) {
    const newFilters = {};

    for (const key in filters) {
      if (Utils.hasProp(filters, key)) {
        const currentFilter = { ...filters[key] };
        const { type, value, initialValue } = currentFilter;
        let emptyValue: string | null | any[] = '';
        if (typeof value === 'string') {
          emptyValue = initialValue || '';
        } else if (
          type === FilterTypes.multiSelect ||
          type === FilterTypes.checkboxList
        ) {
          emptyValue = value.map((val) => {
            return {
              ...val,
              isSelected: false,
            };
          });
        } else if (type === FilterTypes.dateTime) {
          emptyValue = [];
          currentFilter.activePreset = '';
        } else if (type === FilterTypes.select) {
          emptyValue = null;
        } else {
          emptyValue = currentFilter.value;
        }
        currentFilter.value = emptyValue;
        newFilters[key] = currentFilter;
      }
    }

    return newFilters;
  }

  /**
   *
   * @param filters
   */
  static hasFilledValue(filters: any[]): boolean {
    return (
      filters.filter((filter) => {
        const { type, value } = filter;
        if (typeof value === 'string') {
          return value.length > 0;
        } else if (
          type === FilterTypes.multiSelect ||
          type === FilterTypes.checkboxList
        ) {
          return value.filter(({ isSelected }) => isSelected).length > 0;
        } else if (type === FilterTypes.dateTime) {
          return filter.options.filter((option) => option.length).length > 0;
        }
        return false;
      }).length > 0
    );
  }

  /**
   *
   * @param filter
   * @param dictionaryValues
   */
  static getSelectedFromDictionary(filter, dictionaryValues) {
    if (
      filter.type === FilterTypes.multiSelect ||
      filter.type === FilterTypes.checkboxList
    ) {
      const result: any[] = [];
      filter.value.forEach((val) => {
        if (val.isSelected) {
          const dictionaryValue = dictionaryValues[
            filter.dictionaryId
          ]?.list?.find(({ id }) => id === val.id);
          if (dictionaryValue) {
            result.push(dictionaryValue);
          }
        }
      });
      return result;
    } else if (filter.type === FilterTypes.select) {
      const result: any[] = [];
      if (filter.value) {
        const dictionaryValue = dictionaryValues[
          filter.dictionaryId
        ]?.list?.find(({ id }) => id === filter.value.value);
        if (dictionaryValue) {
          result.push(dictionaryValue);
        }
      }
      return result;
    }
    return [];
  }

  /**
   *
   * @param filterId
   * @param filters
   * @param dictionaryValues
   */
  static getAvailableValuesForDependenciesFilters(
    filterId: string,
    filters,
    dictionaryValues
  ) {
    const filter = filters[filterId];
    const { dependencies } = filter;

    const selectedItems = FiltersHelper.getSelectedFromDictionary(
      filter,
      dictionaryValues
    );

    for (const key in dependencies) {
      if (Utils.hasProp(dependencies, key)) {
        const keyForFilterInDictionary = dependencies[key];
        const dependentFilter = filters[key];
        if (!dependentFilter) continue;

        const availableIds = {};
        if (selectedItems.length > 0) {
          selectedItems.forEach((item) => {
            const ids = item[keyForFilterInDictionary];
            if (Array.isArray(ids)) {
              ids.forEach((id) => {
                availableIds[id] = true;
              });
            }
          });
        } else {
          dependentFilter.availableValues?.forEach(({ id, value }) => {
            availableIds[id || value] = true;
          });
        }

        const newValues: any[] = [];
        dependentFilter.availableValues?.forEach((availableValue) => {
          const id = availableValue.id || availableValue.value;
          if (availableIds[id]) {
            let currentValue;
            if (
              dependentFilter.type === FilterTypes.multiSelect ||
              dependentFilter.type === FilterTypes.checkboxList
            ) {
              currentValue = dependentFilter.value.find(
                (val) => availableValue.id === val.id
              );
            } else if (dependentFilter.type === FilterTypes.select) {
              currentValue = dependentFilter.options.find(
                ({ value }) => availableValue.value === value
              );
            }
            newValues.push(currentValue || availableValue);
          }
        });

        if (newValues.length) {
          if (
            dependentFilter.type === FilterTypes.multiSelect ||
            dependentFilter.type === FilterTypes.checkboxList
          ) {
            dependentFilter.value = newValues;
          } else if (dependentFilter.type === FilterTypes.select) {
            dependentFilter.options = newValues;
          }
        }
      }
    }

    return filters;
  }
}

export default FiltersHelper;
