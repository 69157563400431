import Utils from 'helpers/Utils';

/**
 *
 * @param prevState
 * @param payloadFilters
 */
export const createFilters = (prevState, payloadFilters) => {
  let selectedCount = 0;
  const newList = { ...prevState.list };
  for (const key in prevState.list) {
    if (
      Utils.hasProp(prevState.list, key) &&
      Utils.hasProp(payloadFilters, key)
    ) {
      const filterValue = payloadFilters[key];
      if (filterValue.length) {
        selectedCount++;
      }
      newList[key] =
        typeof filterValue === 'string' ? filterValue.trim() : filterValue;
    }
  }
  return {
    ...prevState,
    list: newList,
    selectedCount,
  };
};

/**
 *
 * @param initialState
 * @param prevState
 * @param payloadFilterId
 */
export const resetOneFilter = (initialState, prevState, payloadFilterId) => {
  const newList = { ...prevState.list };
  if (newList[payloadFilterId]) {
    newList[payloadFilterId] = initialState.list[payloadFilterId];
  }
  return {
    ...prevState,
    list: newList,
    selectedCount: prevState.selectedCount - 1,
  };
};
