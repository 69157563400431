import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Button from 'components/ui/button';
import InputDateGroup from 'components/ui/inputDateGroup';
import ButtonsGroup from 'components/ui/buttonsGroup';
import modes from 'components/ui/buttonsGroup/modes';
import DateHelpers from 'helpers/Date';
import filtersKeys from 'constants/filters';
import './dateRange.scss';

interface Props extends IntlProps {
  id: string;
  options: any[];
  params: any[];
  periodPreset: string[];
  activePreset: string;
  timezone: string;
  onChange: (
    filterId: string,
    value: any,
    options?: { valueKey?: string }
  ) => void;
  withLabel: boolean;
  label?: string;
  hideResetButton?: boolean;
  customClass?: string;
  containerSelector?: string;
}

interface State {
  dateType: any;
}

class DateRange extends PureComponent<Props, State> {
  static defaultProps = {
    params: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      dateType: DateRange.getInitialDateType(props.options),
    };
  }

  render() {
    const {
      options,
      periodPreset,
      withLabel,
      activePreset,
      hideResetButton = false,
      customClass,
      label,
      containerSelector,
      getTranslate,
    } = this.props;
    const { dateType } = this.state;
    const selectedDates = this.getSelectedOption(options).value;

    return (
      <div className={classNames('filters-date-range', customClass)}>
        {(withLabel || options.length > 1 || hideResetButton === false) && (
          <div className='filters-date-range__header'>
            {withLabel && (
              <div className='filters-date-range__label'>
                {getTranslate(label || `filters.types.${dateType}`)}
              </div>
            )}
            {!withLabel && options.length > 1 && (
              <>
                <ButtonsGroup
                  className='ui-buttons-group_single-choice'
                  activeButtons={[dateType]}
                  mode={modes.singleRequired}
                  theme='dark'
                  onClick={(items) => this.changeDateType(items[0])}>
                  {options.map((option) => {
                    return (
                      <Button
                        key={option.id}
                        id={option.id}
                        text={getTranslate(`filters.types.${option.id}`)}
                      />
                    );
                  })}
                </ButtonsGroup>
              </>
            )}
            {hideResetButton === false && (
              <div className='filters-date-range__reset'>
                <Button
                  customClass='ui-button_panel-button'
                  text={getTranslate('filters.components.dateRange.reset')}
                  onClick={() => this.reset()}
                />
              </div>
            )}
          </div>
        )}

        {periodPreset.length > 0 && (
          <div className='filters-date-range__preset'>
            {periodPreset.map((period: string) => {
              let classes = 'filters-date-range__preset-button';
              if (activePreset === period) {
                classes += ' ui-button_status-outline-active';
              }

              return (
                <Button
                  key={period}
                  id={period}
                  text={getTranslate(`filters.fields.dateTime.${period}`)}
                  status='outline'
                  size='small'
                  onClick={() => this.changePeriodPreset(period)}
                  customClass={classes}
                />
              );
            })}
          </div>
        )}
        <InputDateGroup
          selectedDates={selectedDates}
          params={this.getParamsById(dateType)}
          onChange={(date) => this.changeDate(dateType, date)}
          containerSelector={containerSelector}
          modern
        />
      </div>
    );
  }

  changeDate = (dateType, date) => {
    const { onChange } = this.props;
    onChange(dateType, date);
    onChange(dateType, '', { valueKey: 'activePreset' });
  };

  changePeriodPreset = (period) => {
    const { onChange, timezone } = this.props;
    const { dateType } = this.state;
    const params = this.getParamsById(dateType);
    const dates: string[] = DateHelpers.getPeriod(period, timezone);

    if (dates.length !== 2) return;

    if (params.minDate) {
      const minDate = DateHelpers.createDate(params.minDate, 'date');
      const minPeriodDate = DateHelpers.createDate(dates[0], 'date');
      if (+minDate > +minPeriodDate)
        dates[0] = DateHelpers.getFormat(minDate, 'datetime');
    }

    if (params.maxDate) {
      const maxDate = DateHelpers.createDate(params.maxDate, 'date');
      const maxPeriodDate = DateHelpers.createDate(dates[0], 'date');
      if (+maxDate > +maxPeriodDate)
        dates[1] = DateHelpers.getFormat(maxDate, 'datetime');
    }

    onChange(dateType, dates);
    onChange(dateType, period, { valueKey: 'activePreset' });
  };

  getSelectedOption = (options) => {
    const { dateType } = this.state;
    return options.find(({ id }) => id === dateType);
  };

  createOption({ id }) {
    const { getTranslate } = this.props;
    return {
      value: id,
      label: getTranslate(`filters.types.${id}`),
    };
  }

  getParamsById(id) {
    const { params } = this.props;
    return params.find((item) => item.id === id) || {};
  }

  reset = () => {
    const { options, onChange } = this.props;
    const { dateType } = this.state;
    const initialOption = options[0].id || filtersKeys.date;
    onChange(dateType, []);
    this.setState({ dateType: initialOption });
  };

  changeDateType = (dateType) => {
    const { onChange, options } = this.props;
    const prevDateType = this.state.dateType;
    if (prevDateType === dateType) {
      return;
    }
    this.setState({ dateType });
    onChange(dateType, this.getSelectedOption(options).value || []);
    onChange(prevDateType, []);
  };

  static getInitialDateType(options) {
    const initialOption =
      options.find((option) => option.value.length) || options[0];
    return initialOption.id;
  }
}

export default addTranslation(DateRange);
