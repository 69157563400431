import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { invert } from 'lodash';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { WithRouterProps } from 'decorators/withRouter';
import showNotification from 'components/ui/notification/showNotification';
import Finance from './Finance';
import views from './viewsList';
import path from 'helpers/path';
import MetricService from 'helpers/metricService/MetricService';
import Messages from 'constants/rpcTypes';
import { Dictionary } from 'types/FilterValue';
import { StoreProps } from 'store';
import SwitcherTabsItem from 'components/ui/switcherTabs/types';

interface ConnectedProps {
  balanceTypes: Dictionary;
}

type Props = ConnectedProps &
  StoreProps &
  IntlProps &
  WithPermissions &
  WithRouterProps<{ id: string }>;

interface State {
  currentView: string;
}

const viewsSections: Record<string, string> = invert(views);

class FinanceContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentView: this.getCurrentView(props.match.params.id),
    };

    if (
      props.isEnabled(Messages.Balance_Statement) &&
      props.isEnabled(Messages.Balance_RealTime) &&
      props.balanceTypes?.list &&
      !props.balanceTypes.list.length
    ) {
      showNotification({
        status: 'info',
        content: props.getTranslate('statement.noResults.hint'),
      });
    }
  }

  render() {
    const { currentView } = this.state;

    return (
      <Finance
        currentView={currentView}
        onChangeView={this.changeView}
        tabs={this.getTabs()}
      />
    );
  }

  changeView = (view) => {
    const { history } = this.props;

    if (view === 'statement') {
      MetricService.send({
        action: 'click',
        actionKey: 'finance.statement',
      });
    }

    history.push(path(`/finance/${views[view]}`));

    this.setState({ currentView: view });
  };

  getCurrentView = (currentPathId) => {
    const { isEnabled } = this.props;
    let view;
    switch (currentPathId) {
      case views.balance: {
        if (isEnabled(Messages.Balance_Get)) {
          view = viewsSections[views.balance];
          break;
        }
        return this.getCurrentView(views.online);
      }
      case views.online: {
        if (isEnabled(Messages.Balance_RealTime)) {
          view = viewsSections[views.online];
          break;
        }
        return this.getCurrentView(views.statement);
      }
      case views.statement: {
        if (isEnabled(Messages.Balance_Statement)) {
          view = viewsSections[views.statement];
          break;
        }
        return this.getCurrentView(views.finReports);
      }
      case views.statStatement: {
        if (isEnabled(Messages.FinancialData_StatementView)) {
          view = viewsSections[views.statStatement];
          break;
        }
        return this.getCurrentView(views.statement);
      }
      case views.finReports: {
        if (isEnabled(Messages.FinancialReport_ListRequest)) {
          view = viewsSections[views.finReports];
          break;
        }
        return this.getCurrentView(views.currencyRates);
      }
      case views.currencyRates: {
        if (isEnabled(Messages.CurrencyRates_List)) {
          view = viewsSections[views.currencyRates];
          break;
        }
        return this.getCurrentView(views.balance);
      }
      default: {
        if (currentPathId === views.balance) {
          return this.getCurrentView(views.online);
        }

        return this.getCurrentView(views.balance);
      }
    }

    if (view !== this.props.match.params.id) {
      this.props.history.replace(path(`/finance/${views[view]}`));
    }
    return view;
  };

  getTabs = (): SwitcherTabsItem[] => {
    const { getTranslate, isEnabled } = this.props;

    const tabs: SwitcherTabsItem[] = [];

    if (isEnabled(Messages.Balance_Get)) {
      tabs.push({
        id: viewsSections[views.balance],
        text: getTranslate('savedEntities.oldBalance'),
        path: 'balance',
      });
    }

    if (
      isEnabled(Messages.Balance_RealTime) &&
      isEnabled(Messages.Balance_Statement)
    ) {
      tabs.push(
        {
          id: viewsSections[views.online],
          text: getTranslate('onlinebalance.quickFilters.viewbalance.realTime'),
          path: 'online',
        },
        {
          id: viewsSections[views.statement],
          text: getTranslate(
            'onlinebalance.quickFilters.viewbalance.statement'
          ),
          path: 'statement',
        }
      );
    } else if (
      !isEnabled(Messages.Balance_RealTime) &&
      isEnabled(Messages.FinancialData_StatementView)
    ) {
      tabs.push({
        id: viewsSections[views.statStatement],
        text: getTranslate('savedEntities.statFinData'),
        path: 'stat-statement',
      });
    }

    if (isEnabled(Messages.FinancialReport_ListRequest)) {
      tabs.push({
        id: viewsSections[views.finReports],
        text: getTranslate('savedEntities.financialReports'),
        path: views.finReports,
      });
    }

    if (isEnabled(Messages.CurrencyRates_List)) {
      tabs.push({
        id: viewsSections[views.currencyRates],
        text: getTranslate('currencyRates.title'),
        path: views.currencyRates,
      });
    }

    return tabs;
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  balanceTypes: state.filtersValues?.balanceType,
});

export default withRouter(
  connect(mapStateToProps)(addTranslation(addPermissions(FinanceContainer)))
);
