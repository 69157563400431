import React, { PureComponent, Fragment } from 'react';
import { Column } from 'components/ui/table';
import Icon from 'components/ui/icon';
import Input from 'components/ui/input';
import './editableColumn.scss';

interface Props {
  configId: string;
  rowIndex: number;
  data: any;
  isUploading: boolean;
  uploadStatus: number;
  onChange: (row: number, key: string, value: string) => void;
  onDeleteRow: (row: number) => void;
  columnWidths?: { [key: string]: number };
}

interface State {
  isEditing: boolean;
}

class EditableColumn extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
    };
  }

  render() {
    const {
      data,
      configId,
      rowIndex,
      uploadStatus,
      isUploading,
      onDeleteRow,
      columnWidths,
    } = this.props;

    const { isEditing } = this.state;
    let content = data;

    if (configId === 'status') {
      if (uploadStatus === 0 && isUploading) {
        content = (
          <Icon
            name='im-Ellipse'
            size={14}
            className='rotating lc-upload-table__icon_process'
          />
        );
      } else if (uploadStatus === 1) {
        content = (
          <Icon
            name='im-Check-mark'
            className='lc-upload-table__icon_success'
          />
        );
      }
    } else if (configId === 'delete') {
      content = (
        <Icon
          onClick={() => onDeleteRow(rowIndex)}
          name='im-Basket'
          className='lc-upload-table__icon_delete'
        />
      );
    } else {
      content = (
        <Fragment>
          {isEditing ? (
            <Input
              id=''
              value={content}
              placeholder=''
              onChange={this.change}
              onBlur={this.blur}
              onKeyDown={this.keyDown}
            />
          ) : (
            <Fragment>
              <span className='editable-column__content'>{content}</span>
              <Icon
                size={16}
                name='im-Edit1'
                className='editable-column__edit-icon'
                onClick={() => this.setState({ isEditing: true })}
              />
            </Fragment>
          )}
        </Fragment>
      );
    }
    return (
      <Column
        columnWidths={columnWidths}
        key={configId}
        id={configId}
        content={content || '–'}
        customClass={`editable-column ui-table__column_${configId}`}
      />
    );
  }

  change = ({ target }) => {
    const { configId, rowIndex, onChange } = this.props;
    onChange(rowIndex, configId, target.value);
  };

  keyDown = (event) => {
    if (event.keyCode === 13) {
      this.setState({ isEditing: false });
    }
  };

  blur = () => {
    this.setState({ isEditing: false });
  };
}

export default EditableColumn;
