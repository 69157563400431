import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { resetConcreteQuickFilters } from 'actions/quickFilters';
import { resetFilter, resetFilters } from 'actions/filters';
import { resetSearchFilter, resetSearchFilters } from 'actions/searchFilters';
import QuickFiltersContainer from 'components/quickFiltersContainer';
import QuickFilters from 'components/quickFiltersContainer/QuickFilters';
import SelectedFiltersContainer from 'components/selectedFilters';
import getFiltersValues from 'selectors/getFiltersValues';
import getSelectionListItems from 'creators/getSelectionListItems';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import checkFilters from 'helpers/checkFilters';
import { filtersKeysType } from 'types/Filters';
import { QuickFiltersTableName } from 'types/QuickFilters';
import SearchFiltersType from 'types/SearchFilters';
import { AnyObject } from 'types/Common';
import filtersValuesPerSection from 'constants/filtersValuesPerSection';
import { FilterTypes } from 'constants/FilterTypes';
import { default as FilterConsts } from 'constants/filters';
import tableNames from 'constants/tableNames';
import breakpoints from 'constants/breakpoints';
import { StoreProps } from 'store';
import './topPanel.scss';
import RefreshButton from './components/RefreshButton';

interface OwnProps {
  name: QuickFiltersTableName | '';
  filtersConfig: AnyObject[];
  button?: ReactElement | false;
  customClass?: string;
  isFilterButton?: boolean;
  isConfigurationButton?: boolean;
  isFeatureConfigurationButton?: boolean;
  showRefreshButton?: boolean;
  refreshButtonAction?: () => void;
  hasSelectedFiltersCustom?: boolean;
  filtersValuesCustom?: AnyObject;
  onReset?: () => void;
  onResetCallback?: () => void;
  onChange?: (filterName: filtersKeysType, value: string | number) => void;
  disableFilters?: AnyObject;
  onBeforeChange?: {
    [key: string]: (key: filtersKeysType, value: any) => boolean;
  };
}

interface ConnectedProps {
  searchFilters: {
    list: SearchFiltersType;
    selectedCount: number;
  };
  filtersValues: AnyObject;
  hasSelectedFilters: boolean;
  quickFilters: AnyObject;
  hideFilters: AnyObject;
  fromSummaryTotal: number;
}

interface State {
  scrollEnd: 'left' | 'right' | undefined;
}

type Props = OwnProps & ConnectedProps & StoreProps;

const isFilterButtonDefault = true;

class TopPanelContainer extends Component<Props, State> {
  private topPanel;
  observer;
  constructor(props) {
    super(props);
    this.state = {
      scrollEnd: 'left',
    };
    this.observer = (() => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (
        userAgent.includes('safari') &&
        !userAgent.includes('chrome') &&
        window.innerWidth <= breakpoints.quickFilters
      ) {
        return new MutationObserver(this.watchOpenedPanels);
      }
    })();
  }
  componentDidMount() {
    if (filtersValuesPerSection[this.props.name]) {
      checkFilters(this.props.name);
    }

    if (this.observer) {
      this.observer.observe(this.topPanel, { childList: true, subtree: true });
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    const { name } = this.props;
    if (prevProps.name !== name) {
      this.setScrollToStart();

      if (filtersValuesPerSection[name]) {
        await checkFilters(name);
        this.setOptions();
      }
    }
  }

  render() {
    const {
      name,
      hasSelectedFilters,
      hasSelectedFiltersCustom,
      button,
      customClass,
      isFilterButton = isFilterButtonDefault,
      isConfigurationButton,
      isFeatureConfigurationButton,
      disableFilters,
      onBeforeChange,
      onChange,
      showRefreshButton,
      refreshButtonAction,
    } = this.props;
    const { scrollEnd } = this.state;
    return (
      <div
        className={classNames('top-panel', customClass, {
          'top-panel_selected': hasSelectedFilters,
          'top-panel_hide-left': scrollEnd === 'left',
          'top-panel_hide-right':
            scrollEnd === 'right' || (hasSelectedFilters && this.isSameWidth()),
        })}
        ref={(el) => {
          this.topPanel = el;
        }}>
        <div className='top-panel__inner' onScroll={this.handleScroll}>
          {hasSelectedFilters && (
            <SelectedFiltersContainer
              name={name}
              onReset={this.resetFilter}
              isSearchFilters={this.isSearchFilters()}
              total={this.props.fromSummaryTotal}
            />
          )}

          {!hasSelectedFilters && button && (
            <div className='top-panel__button-wrap'>{button}</div>
          )}
          <div className='top-panel__filters' onScroll={this.handleScroll}>
            {showRefreshButton && refreshButtonAction && (
              <RefreshButton refreshAction={refreshButtonAction} />
            )}
            <QuickFiltersContainer
              // @ts-ignore TODO: t.melashchenko, выручай!
              tableName={name}
              onChange={onChange}
              onBeforeChange={onBeforeChange}>
              {(props) => (
                <QuickFilters
                  onResetFilters={this.resetFilters}
                  onBeforeChange={onBeforeChange}
                  filters={props.filters}
                  onChangeFilter={props.onChangeFilter}
                  updateFiltersValues={props.updateFiltersValues}
                  filtersConfig={this.setOptions()}
                  name={props.name}
                  hasSelectedFilters={props.hasSelectedFilters}
                  hasSelectedQuickFilters={
                    hasSelectedFiltersCustom ?? props.hasSelectedQuickFilters
                  }
                  isFilterButton={isFilterButton}
                  isConfigurationButton={isConfigurationButton}
                  isFeatureConfigurationButton={isFeatureConfigurationButton}
                  disableFilters={disableFilters}
                />
              )}
            </QuickFiltersContainer>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.observer?.disconnect();
  }

  setOptions = () => {
    const { filtersConfig } = this.props;
    if (!filtersConfig) return null;
    return this.handleFilter(filtersConfig);
  };

  handleFilter = (flatConfig) => {
    const { filtersValues, quickFilters, filtersValuesCustom } = this.props;

    return flatConfig.map((filter) => {
      if (Array.isArray(filter)) return this.handleFilter(filter);

      if (filter.type === FilterTypes.multiSelect) {
        if (filter.dictionary) {
          if (
            filtersValuesCustom &&
            !isEmpty(filtersValuesCustom[filter.dictionary])
          ) {
            return {
              ...filter,
              ...filtersValuesCustom[filter.dictionary],
              list: filtersValuesCustom[filter.dictionary].list.map((item) => ({
                ...item,
                isSelected: quickFilters[filter.filterId].find(
                  (selectedId) => selectedId === item.id
                ),
              })),
            };
          }
          return {
            ...filter,
            isLoading: filtersValues?.[filter.dictionary]?.isLoading,
            list: getSelectionListItems({
              originalList: filtersValues?.[filter.dictionary]?.list || [],
              selectedList: quickFilters[filter.filterId] || [],
            }),
          };
        }
        return {
          ...filter,
          isLoading: filtersValues?.[filter.dictionary]?.isLoading,
          list: getSelectionListItems({
            originalList: filter.list || [],
            selectedList: quickFilters[filter.filterId] || [],
          }),
        };
      } else if (filter.type === FilterTypes.select) {
        return {
          ...filter,
          isLoading: filtersValues?.[filter.dictionary]?.isLoading,
          list: getCustomSelectItems({
            list: filtersValues?.[filter.dictionary]?.list,
          }),
        };
      } else if (filter.type === FilterTypes.buttonsList && filter.dictionary) {
        let list =
          filtersValues?.[filter.dictionary]?.list || filter.list || [];

        if (filter.filterId === FilterConsts.paymentCurrency) {
          const hideCurrencies =
            this.props.hideFilters?.paymentHotCurrency || [];
          list = list.filter((item) => hideCurrencies.indexOf(item.id) < 0);
        }
        return {
          ...filter,
          isLoading: filtersValues?.[filter.dictionary]?.isLoading,
          list: list,
        };
      }
      return filter;
    });
  };

  resetFilter = (filterId) => {
    const { name, dispatch } = this.props;
    this.isSearchFilters()
      ? dispatch(resetSearchFilter(filterId))
      : dispatch(resetFilter(name, filterId));
  };

  resetFilters = () => {
    const {
      name,
      dispatch,
      isFilterButton = isFilterButtonDefault,
      onReset,
      onResetCallback,
    } = this.props;

    if (onReset) {
      return onReset();
    }

    dispatch(resetConcreteQuickFilters({ name }));

    if (isFilterButton) {
      dispatch(resetFilters(name));
    }

    if (name === tableNames.payments) {
      dispatch(resetSearchFilters());
    }

    onResetCallback && onResetCallback();
  };

  isSearchFilters = () => {
    const { searchFilters } = this.props;
    return searchFilters.selectedCount > 0;
  };

  isSameWidth = () => {
    if (!this.topPanel) return false;
    return (
      this.topPanel.clientWidth ===
      this.topPanel.querySelector('.top-panel__inner').scrollWidth
    );
  };

  handleScroll = (e) => {
    if (
      !e.target.classList.contains('top-panel__filters') &&
      !e.target.classList.contains('top-panel__inner')
    )
      return;
    if (e.target.scrollLeft === 0) {
      this.setState({
        scrollEnd: 'left',
      });
    } else if (
      e.target.scrollWidth - e.target.scrollLeft ===
      e.target.clientWidth
    ) {
      this.setState({
        scrollEnd: 'right',
      });
    } else {
      this.setState({
        scrollEnd: undefined,
      });
    }
  };

  setScrollToStart = () => {
    if (!this.topPanel) return false;
    const { hasSelectedFilters } = this.props;
    if (hasSelectedFilters) {
      this.topPanel.querySelector('.top-panel__inner').scrollTo(0, 0);
    } else {
      this.topPanel.querySelector('.top-panel__filters').scrollTo(0, 0);
    }
  };

  watchOpenedPanels = () => {
    const panel =
      this.topPanel.querySelector('.ui-panel') ||
      this.topPanel.querySelector('.ui-select__menu');
    // const quickFilters = this.topPanel.querySelector('.quick-filters');
    if (panel) {
      /* const leftScrollPosition = this.topPanel.querySelector(
         '.top-panel__filters'
       ).scrollLeft;
       quickFilters.style.left = `-${leftScrollPosition}px`;
       quickFilters.style.position = 'relative';
       panel.style.left = `${leftScrollPosition}px`;*/
      this.topPanel.classList.add('top-panel_visible');
    } else {
      /*quickFilters.style.left = '0px';
      quickFilters.style.position = 'static';*/
      this.topPanel.classList.remove('top-panel_visible');
    }
  };
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => {
  const { searchFilters } = state;
  return {
    searchFilters,
    filtersValues: state.filtersValues,
    hasSelectedFilters:
      (ownProps.name === tableNames.payments &&
        searchFilters.selectedCount > 0) ||
      Object.keys(getFiltersValues(state, ownProps.name, true)).length > 0,
    quickFilters: state.quickFilters[ownProps.name],
    hideFilters: state.settings?.hideConfig?.filter || {},
    fromSummaryTotal: state.paymentSummaryData.data.total,
  };
};

export default connect(mapStateToProps)(TopPanelContainer);
