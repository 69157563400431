import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import './browserItem.scss';

interface Props {
  browserSrc: string;
  browserTitle: string;
  customClass?: string;
}

class BrowserItem extends PureComponent<Props> {
  render() {
    const { browserTitle, browserSrc, customClass } = this.props;
    let srcBrowser;
    try {
      srcBrowser = require(`images/browsers/${browserSrc}.svg`);
    } catch {}

    return (
      <span className={classNames('browser-item', customClass)}>
        <span className='browser-item__icon'>
          {srcBrowser ? (
            <img src={srcBrowser} alt={browserTitle} />
          ) : (
            <Icon name='im-Question' size={8} />
          )}
        </span>
        <span className='browser-item__text'>{browserTitle}</span>
      </span>
    );
  }
}

export default BrowserItem;
