import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Button from 'components/ui/button';
import Checkbox from 'components/ui/checkbox';
import Animation from 'components/ui/animation';
import Loader from 'components/ui/loader';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import CustomScrollbar from 'components/ui/customScrollbar';

import General from './components/general';
import Schedule from './components/schedule';
import Patterns from './components/patterns';
import Filters from './components/filters';
import Columns from './components/columns';
import reportTypes from './reportTypes';
import { AnyObject } from 'types/Common';

import './templatesBuilder.scss';

interface Props extends IntlProps {
  isLoading: boolean;
  fields: AnyObject;
  onChangeField: (key: string, value: any) => void;
  onBack: () => void;
  onSave: () => void;
  onLoadTemplate: (id: string) => void;
  onManualDateChange: (isManualInput: boolean) => void;
}

const TemplatesBuilder: React.VFC<Props> = ({
  onBack,
  onSave,
  onLoadTemplate,
  onChangeField,
  fields,
  isLoading,
  getTranslate,
  onManualDateChange,
}) => (
  <div className='templates-builder'>
    <Animation>
      <CustomScrollbar>
        <PageTemplate.Container>
          <div className='templates-builder__content'>
            <div className='grid-row templates-builder__row'>
              <div className='grid-column-4 grid-row-item'>
                <General />
              </div>
              <div className='grid-column-8 grid-row-item'>
                <Schedule
                  onManualChange={(isManualInput) => {
                    onManualDateChange(isManualInput);
                  }}
                />
              </div>
            </div>
            <div className='grid-row templates-builder__row'>
              <div className='grid-column-12'>
                <Patterns onLoadTemplate={onLoadTemplate} />
              </div>
            </div>
            <div className='grid-row templates-builder__row'>
              <div className='grid-column-7 grid-row-item'>
                <Filters />
              </div>
              <div className='grid-column-7 grid-row-item'>
                <Columns />
              </div>
            </div>
          </div>
        </PageTemplate.Container>
      </CustomScrollbar>
      <div className='grid-row templates-builder__footer'>
        <div className='templates-builder__footer-item'>
          <Checkbox
            id='template-builder-save'
            text={getTranslate('reports.new.saveAsTemplate.checkbox')}
            checked={
              fields.saveAsTemplate &&
              fields.reportType !== reportTypes.interchange
            }
            disabled={fields.reportType === reportTypes.interchange}
            onChange={({ target }) =>
              onChangeField('saveAsTemplate', target.checked)
            }
          />
        </div>
        <div className='templates-builder__footer-item'>
          <Button
            type='button'
            status='outline'
            text={getTranslate('common.cancel.button')}
            onClick={onBack}
            customClass='templates-builder__footer-btn'
          />
          <Button
            type='button'
            status='primary'
            text={getTranslate('common.save.button')}
            onClick={onSave}
            customClass='templates-builder__footer-btn'
          />
        </div>
      </div>
    </Animation>
    {isLoading && <Loader />}
  </div>
);

export default addTranslation(TemplatesBuilder);
