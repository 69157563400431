import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';

export const initFilters = (filters) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.INIT_FILTERS,
        payload: {
          filters,
        },
      })
    );
  };
};

export const applyFilters = (name: string, filters) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.APPLY_FILTERS,
        payload: {
          name,
          filters,
        },
      })
    );
  };
};

export const resetFilter = (name: string, filterId) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_ONE_FILTER,
        payload: {
          name,
          filterId,
        },
      })
    );
  };
};

export const resetFilters = (name: string, resetValues?: any) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_FILTERS,
        payload: {
          name,
          resetValues,
        },
      })
    );
  };
};
