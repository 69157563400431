import React from 'react';
import { Chart } from 'highcharts';

import { StoreProps } from 'store';

import ChartView from './ChartView';
import { ChartViewData } from 'types/Analytics';
import { LayoutTypes } from '../../../editLayout/EditLayout';

interface Props extends StoreProps {
  data: ChartViewData;
  withExport?: boolean;
  setRef?: (ref: Chart) => void;
  className?: string;
  layoutType?: LayoutTypes;
  shouldResize: boolean;
}

class ChartViewContainer extends React.Component<Props> {
  render() {
    const { data, setRef, className, shouldResize, dispatch } = this.props;

    return (
      <ChartView
        key={'uuid' in data ? data.uuid : ''}
        setRef={setRef}
        data={data}
        className={className}
        shouldResize={shouldResize}
        dispatch={dispatch}
      />
    );
  }
}

export default ChartViewContainer;
