import React, { isValidElement, PropsWithChildren, PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import classNames from 'classnames';
import modes from './modes';
import './buttonsGroup.scss';
import Icon from '../icon';

interface Props extends IntlProps {
  id?: string;
  mode?: keyof typeof modes;
  activeButtons: string[];
  onClick?: (state: string[]) => void;
  // event is like SelectionList.onChange
  onChange?: (event: {
    type: string;
    state?: { id?: string; isSelected: boolean };
  }) => void;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  label?: string;
  error?: string;
  theme?: 'dark';
  separate?: boolean;
}

class ButtonsGroup extends PureComponent<PropsWithChildren<Props>> {
  static defaultProps = {
    mode: modes.single,
    className: '',
    disabled: false,
  };

  render() {
    const {
      children,
      className,
      label,
      disabled,
      theme,
      mode,
      error,
      isLoading,
      getTranslate,
      activeButtons,
    } = this.props;
    const modeClass =
      this.props.separate ?? mode === modes.multi
        ? 'ui-buttons-group_separate'
        : 'ui-buttons-group_single-choice';
    return (
      <div
        className={classNames('ui-buttons-group', className, modeClass, {
          'ui-buttons-group_disabled': disabled,
          [`ui-buttons-group_${theme}`]: !!theme,
        })}
        id={this.props.id}>
        {label && (
          <div className='ui-buttons-group__label'>{getTranslate(label)}</div>
        )}
        <div className='ui-buttons-group__inner'>
          {isLoading && (
            <Icon
              size={18}
              name='im-Ellipse'
              className='ui-buttons-group__loader rotating'
            />
          )}
          {React.Children.map(children, (child) => {
            if (!child) return null;
            if (isValidElement(child)) {
              const { id, type, customClass = '' } = child.props;
              return React.cloneElement(child, {
                key: id,
                type,
                status: 'light',
                disabled,
                onClick: () => this.handleClick(id),
                active: activeButtons.includes(id),
                customClass: classNames(customClass, {
                  'ui-button_status-light-active': activeButtons.includes(id),
                }),
              });
            }
          })}
        </div>
        {error && <div className='ui-buttons-group__error'>{error}</div>}
      </div>
    );
  }

  handleClick = (id) => {
    const { mode, onClick, onChange, activeButtons } = this.props;
    let newStateButtons: string[] = [];
    const events: Array<{
      type: string;
      state?: { id?: string; isSelected: boolean };
    }> = [];
    switch (mode) {
      case modes.single:
        if (activeButtons[0] !== id) {
          newStateButtons.push(id);
          if (activeButtons[0] != null) {
            events.push({
              type: 'changeItem',
              state: { id: activeButtons[0], isSelected: false },
            });
          }
          events.push({ type: 'changeItem', state: { id, isSelected: true } });
        }
        break;
      case modes.singleRequired:
        if (activeButtons[0] === id && id) {
          return false;
        }
        newStateButtons.push(id);
        if (activeButtons[0] != null) {
          events.push({
            type: 'changeItem',
            state: { id: activeButtons[0], isSelected: false },
          });
        }
        events.push({ type: 'changeItem', state: { id, isSelected: true } });
        break;
      case modes.multi:
        if (activeButtons.includes(id)) {
          newStateButtons = activeButtons.filter((buttonId) => id !== buttonId);
          events.push({ type: 'changeItem', state: { id, isSelected: false } });
        } else {
          newStateButtons = [...activeButtons];
          newStateButtons.push(id);
          events.push({ type: 'changeItem', state: { id, isSelected: true } });
        }
        break;
      case modes.multiRequired:
        if (activeButtons.includes(id)) {
          if (activeButtons.length === 1) {
            return false;
          }
          newStateButtons = activeButtons.filter((buttonId) => id !== buttonId);
          events.push({ type: 'changeItem', state: { id, isSelected: false } });
        } else {
          newStateButtons = [...activeButtons];
          newStateButtons.push(id);
          events.push({ type: 'changeItem', state: { id, isSelected: true } });
        }
        break;
      default:
        console.error(
          `${mode} not supported! Use ${Object.keys(modes).toString()}`
        );
    }

    if (onClick) {
      onClick(newStateButtons);
    }
    if (onChange) {
      for (const event of events) {
        onChange(event);
      }
    }
  };
}

export default addTranslation(ButtonsGroup);
