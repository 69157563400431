import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import classNames from 'classnames';

import { getStatementBalance } from 'api/finance';
import { changeQuickFilter } from 'actions/quickFilters';
import { StoreProps } from 'store';

import StatementFinance from './StatementFinance';
import TopPanel from './components/topPanel';
import CreateStatementForm from './components/createStatementForm';

import DateHelpers from 'helpers/Date';
import showNotification from 'components/ui/notification/showNotification';
import getMessageError from 'helpers/getMessageError';
import { Balance } from './StatementFinanceTypes';
import { QuickBalanceStatementFiltersType } from 'types/QuickFilters';
import { DictionaryMultiSelect } from 'types/FilterValue';
import filtersKeys from 'constants/filters';
import tableNames from 'constants/tableNames';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';

interface ConnectedProps {
  filters: QuickBalanceStatementFiltersType;
  balanceType: DictionaryMultiSelect;
  balance: DictionaryMultiSelect;
  balanceCompany: DictionaryMultiSelect;
  balanceContract: DictionaryMultiSelect;
  balanceCurrency: DictionaryMultiSelect;
}

type Props = ConnectedProps & StoreProps;

interface State {
  list: Balance;
  dataLoading: boolean;
  filterLoading: boolean;
  showCreateForm: boolean;
}

@wrapAppWithCssClass('layout-app_desktop-width')
class StatementFinanceContainer extends PureComponent<Props, State> {
  __isMounted = false;

  constructor(props: Props) {
    super(props);

    const initialBalance = StatementFinanceContainer.getInitialBalance();
    const {
      balanceType,
      balance,
      balanceCompany,
      balanceContract,
      balanceCurrency,
    } = props;

    this.state = {
      list: initialBalance,
      dataLoading: false,
      filterLoading:
        !balanceType?.isFetched ||
        !balance?.isFetched ||
        !balanceCompany?.isFetched ||
        !balanceContract?.isFetched ||
        !balanceCurrency?.isFetched,
      showCreateForm: true,
    };
  }

  async componentDidMount() {
    this.__isMounted = true;
  }

  async componentDidUpdate() {
    const {
      filters,
      balanceType,
      balance,
      balanceCompany,
      balanceContract,
      balanceCurrency,
      dispatch,
    } = this.props;

    // Show loading while fetching dictionaries
    if (
      balanceType?.isFetched &&
      balance?.isFetched &&
      balanceCompany?.isFetched &&
      balanceContract?.isFetched &&
      balanceCurrency?.isFetched
    ) {
      this.setState({
        filterLoading: false,
      });
    }

    // Select first balanceType after loading
    if (
      balanceType?.isFetched &&
      !filters[filtersKeys.balanceStatementBalanceType]
    ) {
      dispatch(
        changeQuickFilter(
          tableNames.balanceStatement,
          filtersKeys.balanceStatementBalanceType,
          balanceType.list[0].id
        )
      );
    }
  }

  render() {
    const {
      filters,
      balanceType,
      balance,
      balanceCompany,
      balanceContract,
      balanceCurrency,
    } = this.props;
    const currentBalanceType = filters[filtersKeys.balanceStatementBalanceType];
    const { list, dataLoading, filterLoading, showCreateForm } = this.state;

    return (
      <div className='statement-finance'>
        <div className='statement-finance__top-panel'>
          <TopPanel
            balanceType={balanceType}
            showCreateForm={showCreateForm}
            isLoading={filterLoading || dataLoading}
            onToggle={this.toggleCreateForm}
          />
        </div>
        <div className='statement-finance__inner'>
          <div
            className={classNames('statement-finance__aside', {
              'statement-finance__aside_open': showCreateForm,
            })}>
            <CreateStatementForm
              balanceType={balanceType}
              balance={balance}
              balanceCompany={balanceCompany}
              balanceContract={balanceContract}
              balanceCurrency={balanceCurrency}
              filters={filters}
              isLoading={filterLoading || dataLoading}
              onClose={this.toggleCreateForm}
              onSubmit={() => this.fetchData()}
            />
          </div>
          <div className='statement-finance__content'>
            <StatementFinance
              isLoading={dataLoading}
              isFetched={list.isFetched}
              data={list.data}
              currentBalanceType={currentBalanceType}
              hasFilters={this.hasFilters()}
            />
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.__isMounted = false;
  }

  getFilterValues() {
    const { filters } = this.props;
    const filterKeys = [
      filtersKeys.balanceStatementBalanceType,
      filtersKeys.balanceStatementBalanceId,
      filtersKeys.balanceStatementCompanyId,
      filtersKeys.balanceStatementContractId,
      filtersKeys.balanceStatementCurrencyId,
      filtersKeys.balanceStatementPeriod,
    ];
    const outsideKeys = [filtersKeys.balanceStatementGroupPeriodBy];
    const result = {};

    const addValueToFilter = (data: { filterKey?: string; key: string }) => {
      const { filterKey, key } = data;
      const value = Object.prototype.hasOwnProperty.call(filters[key], 'values')
        ? filters[key].values
        : filters[key];

      if (
        (Array.isArray(value) === true && value.length > 0) ||
        (Array.isArray(value) === false && value)
      ) {
        if (filterKey !== undefined) {
          if (result[filterKey] === undefined) {
            result[filterKey] = {
              [key]: value,
            };
          } else {
            result[filterKey][key] = value;
          }
        } else {
          result[key] = value;
        }
      }
    };

    filterKeys.forEach((key) => {
      addValueToFilter({ filterKey: 'filter', key });
    });

    outsideKeys.forEach((key) => {
      addValueToFilter({ key });
    });

    return result;
  }

  async fetchData() {
    if (!this.__isMounted) {
      return;
    }

    const filter = this.getFilterValues();

    this.setState({ dataLoading: true });

    try {
      const response = await getStatementBalance(filter);

      this.setState({
        list: {
          isFetched: true,
          data: response,
          updateAt: DateHelpers.getDate().toDate(),
        },
      });
    } catch (error: any) {
      showNotification({
        status: 'error',
        content: getMessageError(error),
      });

      console.error(error);
    } finally {
      this.__isMounted && this.setState({ dataLoading: false });
    }
  }

  getPickedFilters = () => {
    return omit(this.props.filters, ['groupPeriodBy']);
  };

  hasFilters = () => {
    return Object.keys(this.getPickedFilters()).length > 0;
  };

  toggleCreateForm = () => {
    this.setState((prevState) => ({
      showCreateForm: !prevState.showCreateForm,
    }));
  };

  static getInitialBalance(): Balance {
    return {
      isFetched: false,
      data: {
        pendingDates: {},
        totals: {},
        balances: [],
        columns: {
          operational: [],
          nonOperational: [],
        },
        reportParams: {
          reportDate: '',
          merchant: '',
          project: [],
          reportPeriod: [],
          balance: [],
          contract: [],
          contractWith: [],
        },
      },
      updateAt: null,
    };
  }
}

const mapStateToProps = (state): ConnectedProps => {
  const { filtersValues, quickFilters } = state;

  return {
    filters: quickFilters.balanceStatement,
    balanceType: filtersValues?.balanceType,
    balance: filtersValues?.balance,
    balanceCompany: filtersValues?.balanceCompany,
    balanceContract: filtersValues?.balanceContract,
    balanceCurrency: filtersValues?.balanceCurrency,
  };
};

export default connect(mapStateToProps)(StatementFinanceContainer);
