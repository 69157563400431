import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pickBy } from 'lodash';

import { loadDictionary } from 'api/dictionaries';
import { createMoto } from 'api/payouts';
import { openModal } from 'actions/modal';
import showNotification from 'components/ui/notification/showNotification';
import FormFields from 'components/formFields/FormFields';
import SubmittedForm from 'components/formFields/components/submittedForm';
import { addListeners } from 'decorators/addListeners';
import path from 'helpers/path';
import Utils from 'helpers/Utils';
import { getMethods } from 'creators/paymentMethods';
import { AnyObject } from 'types/Common';
import { Dictionary } from 'types/FilterValue';
import Messages from 'constants/rpcTypes';
import urlsMap from 'constants/urlsMap';
import fieldsConfig from './fieldsConfig';
import formStateFactory from 'components/formFields/formStateFactory';
import { getRequiredFields } from 'components/formFields/helpers';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import { StoreProps } from 'store';

interface OwnProps {
  filtersValues?: Record<string, Dictionary>;
}

interface ConnectedProps {
  isPaymentIdGeneratorEnabled: boolean;
}

type Props = OwnProps & StoreProps & ConnectedProps;

interface State {
  isCreatingPayment: boolean;
  fields: AnyObject;
  fieldsState: {
    paymentMethodCode: boolean;
  };
  hiddenFields: {
    cardToken: boolean;
  };
  paymentPageMethod: Dictionary;
  motoType: Dictionary;
  validationErrors: AnyObject;
  status: string;
}

@addListeners([Messages.MotoPayment_Create])
class MotoContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isCreatingPayment: false,
      status: '',
      validationErrors: {},
      fields: this.getInitialFields(),
      fieldsState: {
        paymentMethodCode: false,
      },
      hiddenFields: {
        cardToken: true,
      },
      paymentPageMethod: {
        isFetched: false,
        isLoading: false,
        list: [],
        hasMoreRows: false,
      },
      motoType: {
        isFetched: false,
        isLoading: false,
        list: [],
        hasMoreRows: false,
      },
    };
  }

  componentDidMount() {
    this.getMoto();
  }

  render() {
    const { validationErrors, status } = this.state;

    if (status) {
      return (
        <SubmittedForm
          id='moto'
          title={
            status === 'success'
              ? 'payouts.request.moto.header'
              : 'payouts.request.moto.rejectedRequest.header'
          }
          text={this.getText(status)}
          onSecondaryButtonLink={path(`/${urlsMap.payouts}`)}
          status={status}
          onPrimaryButtonClick={this.newPayment}
          secondaryButtonText='payouts.request.backBtn'
          primaryButtonText='payouts.request.newRequestBtn'
        />
      );
    }

    return (
      <FormFields
        {...this.props}
        id='moto'
        text='payouts.request.moto.infoText'
        generalTitle='remittance.addPayout.generalInfo.label'
        additionalTitle='remittance.addPayout.additionalInfo.label'
        onChange={this.change}
        onSubmit={() => this.motoCreate()}
        onReset={this.newPayment}
        isLoading={this.state.isCreatingPayment}
        canCreate={this.isFilledFields()}
        fieldsValues={this.state.fields}
        fieldsConfig={fieldsConfig}
        dictionaries={{
          ...this.props.filtersValues,
          paymentPageMethod: this.state.paymentPageMethod,
          motoType: this.state.motoType,
        }}
        fieldsState={this.state.fieldsState}
        hiddenFields={this.state.hiddenFields}
        validationErrors={validationErrors}
        backUrl={path(`/${urlsMap.payouts}`)}
        backText='payouts.request.backBtn'
        createButtonText='payouts.request.payNow.button'
      />
    );
  }

  getInitialFields = () => {
    return {
      ...formStateFactory(
        fieldsConfig.general,
        this.props.isPaymentIdGeneratorEnabled
      ),
      ...formStateFactory(fieldsConfig.additional),
    };
  };

  change = (name, value) => {
    this.setState(
      (state) => ({
        fields: {
          ...state.fields,
          [name]: value,
        },
        validationErrors: {
          ...state.validationErrors,
          [name]: '',
        },
      }),
      async () => {
        if (name === 'projectId' && value) {
          this.getPaymentMethods(value);
        } else if (name === 'paymentMethodCode') {
          const isCardToken = value === 'card-token';

          this.setState((state) => {
            const newFields = !isCardToken
              ? {
                  ...state.fields,
                  cardToken: '',
                }
              : { ...state.fields };
            return {
              fields: newFields,
              hiddenFields: {
                cardToken: !isCardToken,
              },
            };
          });
        }
      }
    );
  };

  getDictionary = (payload) => {
    return loadDictionary(payload, false);
  };

  getMoto = async () => {
    this.setState((state) => ({
      motoType: {
        ...state.motoType,
        isLoading: true,
      },
    }));
    try {
      const data = await this.getDictionary({
        name: 'motoType',
      });

      const dictionary = data.elements.filter((item) => !!item.id);
      this.setMoto(dictionary);
    } catch (e) {
      this.setState((state) => ({
        motoType: {
          ...state.motoType,
          isLoading: false,
          isFetched: false,
        },
      }));
    }
  };

  setMoto = (dictionary) => {
    this.setState({
      motoType: {
        isLoading: false,
        isFetched: true,
        hasMoreRows: false,
        list: getCustomSelectItems({ list: dictionary }),
      },
    });
  };

  getPaymentMethods = async (value) => {
    this.setState((state) => ({
      paymentPageMethod: {
        ...state.paymentPageMethod,
        isLoading: true,
      },
    }));
    try {
      const data = await this.getDictionary({
        name: 'paymentPageMethod',
        params: { projectId: value },
      });

      this.setState((state) => ({
        paymentPageMethod: {
          list: getMethods(data.elements),
          isFetched: true,
          hasMoreRows: false,
          isLoading: false,
        },
        fieldsState: {
          paymentMethodCode: true,
        },
        fields: {
          ...state.fields,
          paymentMethodCode: '',
        },
      }));
    } catch (e) {
      this.setState((state) => ({
        paymentPageMethod: {
          ...state.paymentPageMethod,
          isLoading: false,
          isFetched: false,
          hasMoreRows: false,
        },
      }));
    }
  };

  isFilledFields = () => {
    const { fields } = this.state;
    const requiredFields = getRequiredFields(fieldsConfig);
    let isCardToken = false;

    return requiredFields.every((field) => {
      if (
        field.id === 'paymentMethodCode' &&
        fields[field.id] === 'card-token'
      ) {
        isCardToken = true;
      }
      if (field.id === 'cardToken') {
        return isCardToken ? !!fields[field.id] : !fields[field.id];
      }
      if (field.id === 'motoType' && fields[field.id] === 0) {
        return true;
      }
      return !!fields[field.id];
    });
  };

  motoCreate = async () => {
    const { fields } = this.state;

    try {
      this.setState({ isCreatingPayment: true });
      await createMoto(
        pickBy(
          {
            ...fields,
            amount: Utils.getNumberWithoutSpace(fields.amount),
          },
          (prop) => prop !== undefined
        )
      );
    } catch (e) {
      if (
        e.payload?.validationErrors ||
        (Array.isArray(e.payload) && !e.payload.length)
      )
        return;
      showNotification({ status: 'error', content: e.payload });
    } finally {
      this.setState({ isCreatingPayment: false });
    }
  };

  statusUpdate = (status) => {
    this.setState({
      status,
    });
  };

  newPayment = () => {
    this.setState({
      fields: this.getInitialFields(),
      status: '',
    });
  };

  getText = (status) => {
    const { validationErrors } = this.state;
    switch (status) {
      case 'success':
        return 'payouts.request.moto.success.text';
      case 'fail':
      case 'decline':
      case 'error':
        return 'payouts.request.single.timeout.text';
      case 'source error':
        return 'source error';
      case 'terminalError':
        return validationErrors.common;
      default:
        return 'unknown error';
    }
  };

  onEvent = ({ data }) => {
    if (data.payload.validationErrors) {
      if (data.payload.validationErrors.common) {
        this.setState({ status: 'terminalError' });
      }
      this.setState({
        validationErrors: data.payload.validationErrors,
      });
    } else {
      const { dispatch } = this.props;

      dispatch(
        openModal({
          modalId: 'PaymentFrame',
          content: {
            src: data.payload.paymentPageUrl,
            motoPaymentId: data.payload.motoPaymentId,
          },
          callback: ({ operationStatus }) => {
            this.statusUpdate(operationStatus);
          },
        })
      );
    }
    this.setState({
      isCreatingPayment: false,
    });
  };
}

const mapStateToProps = (state) => ({
  isPaymentIdGeneratorEnabled: state.user.isPaymentIdGeneratorEnabled,
});

export default connect(mapStateToProps)(MotoContainer);
