import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';
import Repository from 'helpers/Repository';

export const setArticles = (articles) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_HELP_ARTICLES,
        payload: {
          articles,
        },
      })
    );
  };
};
export const addArticle = (data) => {
  const interfaceLang =
    Repository.get('store').getState().user.interfaceLang || 'en';
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.ADD_HELP_ARTICLE,
        payload: {
          article: data.payload,
          interfaceLang,
        },
      })
    );
  };
};

export const updateArticle = (article) => {
  const interfaceLang =
    Repository.get('store').getState().user.interfaceLang || 'en';
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPDATE_HELP_ARTICLE,
        payload: {
          article,
          interfaceLang,
        },
      })
    );
  };
};

export const deleteArticle = (articleId) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.DELETE_HELP_ARTICLE,
        payload: {
          articleId,
        },
      })
    );
  };
};

export const updateArticles = (articles) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.UPDATE_HELP_ARTICLES,
        payload: {
          articles,
        },
      })
    );
  };
};

export const resetHelp = () => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_HELP,
      })
    );
  };
};
