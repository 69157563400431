import React, { PureComponent, ReactNode } from 'react';
import Tooltip from 'react-tooltip';
import { pickBy } from 'lodash';
import classNames from 'classnames';
import icons from 'images/icons';
import './icon.scss';

interface Props {
  name: string;
  className?: string;
  size?: number;
  onClick?: any;
  id?: string;
  tooltip?: ReactNode;
  isHtmlTooltip?: boolean;
  tooltipClassName?: string;
  tooltipOffset?: string; // '{"left": 10, top: 10 }'
  tooltipId?: string;
  tooltipPlace?: string;
  disabled?: boolean;
  color?: 'blue' | 'green';
}

class Icon extends PureComponent<Props> {
  static defaultProps = {
    size: 16,
  };

  componentDidMount(): void {
    this.props.tooltip && Tooltip.rebuild();
  }

  render() {
    const {
      className,
      name,
      size,
      tooltip,
      tooltipOffset,
      tooltipClassName,
      isHtmlTooltip = false,
      tooltipPlace,
      tooltipId,
      color,
      ...otherProps
    } = this.props;
    if (!name) return null;
    const type = name.startsWith('im-') ? 'icomoon' : 'md';

    const tooltipOptions = pickBy({
      'data-html': isHtmlTooltip,
      'data-tip': tooltip || '',
      'data-class': tooltipClassName || '',
      'data-offset': tooltipOffset,
      'data-for': tooltipId,
      'data-place': tooltipPlace,
    });

    switch (type) {
      case 'icomoon':
        return (
          <i
            className={classNames(
              'ui-icon ',
              'icomoon',
              `icomoon-${name}`,
              className,
              `fs-${size}`,
              {
                [`ui-icon_${color}`]: !!color,
              }
            )}
            {...tooltipOptions}
            {...otherProps}
          />
        );
      default: {
        const Component = icons[name];
        if (Component) {
          return (
            <Component
              className={classNames('ui-icon ', `ui-icon_${name}`, className)}
              {...otherProps}
              {...tooltipOptions}
            />
          );
        }
        return (
          <i
            className={classNames(
              'ui-icon ',
              'material-icons',
              className,
              `fs-${size}`
            )}
            data-html={isHtmlTooltip}
            data-class={tooltipClassName || ''}
            data-tip={tooltip || ''}
            data-offset={tooltipOffset}
            {...otherProps}>
            {name}
          </i>
        );
      }
    }
  }
}

export default Icon;
