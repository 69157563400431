import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

export const getList = (): Promise<any> => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Localize_LexemesList,
        },
      ],
    })
    .then((data: any) => data.lexemes);
};

export const create = (data): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Localize_CreateLexeme,
        payload: data,
      },
    ],
  });
};

export const edit = (data): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Localize_UpdateLexeme,
        payload: data,
      },
    ],
  });
};

export const remove = (id): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Localize_DeleteLexeme,
        payload: { id },
      },
    ],
  });
};

export const sync = (): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Localize_Sync,
      },
    ],
  });
};

export const ticketList = (): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Localize_TicketList,
      },
    ],
  });
};

export default {
  getList,
  create,
  edit,
  remove,
  sync,
  ticketList,
};
