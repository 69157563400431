import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';
import { IQuickActionItem } from 'pages/getStarted/overview/components/quickActions/QuickActionItem';

interface State {
  isFetched: false;
  items: IQuickActionItem[];
}

const initialState: State = { isFetched: false, items: [] };

const quickActions = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.SET_QUICK_ACTIONS:
      return {
        isFetched: true,
        items: payload,
      };
    case ACTIONS.RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

export default quickActions;
