export interface Project {
  id: string;
  projectId: string;
  name: string;
  url: string;
}

export interface ProjectGeneral {
  projectId: string;
  name: string;
  url: string;
  status: 0 | 1;
}

export const HistoryActionsTypes = {
  success: '130',
  decilned: '131',
  token: '132',

  successReturn: '140',
  decilneReturn: '141',
};

export interface RedirectError {
  url: string;
}

export interface ValidationErrors {
  redirectSuccess: RedirectError;
  redirectFail: RedirectError;
  redirectTokenize: RedirectError;
  merchantBackSuccess: RedirectError;
  merchantBackFail: RedirectError;
  [key: string]: any;
}
export interface Redirect {
  url: string;
  mode?: number;
  enabled: number;
  key: string;
}

export interface RedirectSettings {
  redirectSuccess: Redirect;
  redirectFail: Redirect;
  redirectTokenize: Redirect;
  merchantBackSuccess: Redirect;
  merchantBackFail: Redirect;
}
export type UrlType =
  | 'redirectSuccess'
  | 'redirectFail'
  | 'redirectTokenize'
  | 'merchantBackSuccess'
  | 'merchantBackFail';
