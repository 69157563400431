import React, { Component } from 'react';
import sunEditor from 'suneditor';
import {
  align,
  fontSize,
  fontColor,
  hiliteColor,
  formatBlock,
  list,
  table,
  link,
  image,
  video,
} from 'suneditor/src/plugins';
import layouts from './plugins/layouts';
import accordion from './plugins/accordion/index';

import 'suneditor/dist/css/suneditor.min.css';
import './editor.scss';

interface Props {
  content: string;
  onInit?: (editor) => void;
  onChange?: (content: string) => void;
}

class Editor extends Component<Props> {
  private nodeEditor;
  private instanceEditor;

  componentDidMount() {
    this.init();
  }

  render() {
    const { content } = this.props;

    return (
      <div className='editor'>
        <textarea
          ref={(el) => {
            this.nodeEditor = el;
          }}
          defaultValue={content}
        />
      </div>
    );
  }

  init() {
    const { onInit, onChange } = this.props;

    this.instanceEditor = sunEditor.create(this.nodeEditor, {
      plugins: {
        align,
        fontSize,
        fontColor,
        hiliteColor,
        list,
        table,
        link,
        image,
        video,
        formatBlock,
        layouts,
        accordion,
      },
      buttonList: [
        [
          'fontSize',
          'formatBlock',
          'bold',
          'underline',
          'italic',
          'fontColor',
          'hiliteColor',
          'removeFormat',
          'align',
          'list',
          'table',
          'link',
          'image',
          'video',
          'layouts',
          'accordion',
        ],
      ],
      formats: ['p', 'blockquote', 'h2', 'h3'],
      fontSize: [10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 48],
      mode: 'balloon',
      width: '100%',
      height: '100%',
      attributesWhitelist: {
        all: 'style|id|class|data-.+',
      },
    });

    this.instanceEditor.disabled();

    if (onInit) {
      setTimeout(() => onInit(this.instanceEditor));
    }

    if (onChange) {
      this.instanceEditor.onChange = (content) => onChange(content);
    }
  }
}

export default Editor;
