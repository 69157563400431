import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import TokenType from './TokenType';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import Messages from 'constants/rpcTypes';
import './profileTokens.scss';
import { CopyString } from 'components/ui/copyString/CopyString';

interface Props extends IntlProps, WithPermissions {
  token: TokenType | null;
  isLoading: boolean;
  isFetch: boolean;
  onCreate: () => Promise<any>;
  onRemove: () => Promise<any>;
  customClass: string;
}

class ProfileTokens extends PureComponent<Props> {
  render() {
    const { isLoading, isFetch, token, onRemove, getTranslate, customClass } =
      this.props;

    return (
      <div className={classNames('profile-tokens card card_l', customClass)}>
        <div className='card__header card__header_space-between'>
          <div className='card__title card__title-l'>
            {getTranslate('profile.apitokens.header')}
          </div>
          {isFetch && (
            <div className='profile-tokens__buttons'>
              {token && (
                <Button
                  text={getTranslate('profile.apitokens.delete.button')}
                  status='danger-outline'
                  size='normal'
                  customClass='profile-tokens__button'
                  onClick={onRemove}
                />
              )}
              {this.renderNewTokenButton()}
            </div>
          )}
        </div>
        <div className='card__container card__content profile-tokens__item profile-tokens__item_row'>
          {this.renderContent()}
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }

  renderNewTokenButton() {
    const { isEnabled, onCreate, getTranslate } = this.props;

    if (!isEnabled(Messages.UserApiToken_Generate)) return null;

    return (
      <Button
        text={getTranslate('profile.apitokens.newToken.button')}
        size='normal'
        status='primary'
        type='submit'
        customClass='profile-tokens__button'
        onClick={onCreate}
      />
    );
  }

  renderContent() {
    const { token, isFetch, getTranslate } = this.props;

    if (!isFetch) {
      return null;
    }

    if (!token) {
      return (
        <div className='profile-tokens__empty'>
          {getTranslate('profile.apitokens.noTokens.text')}
        </div>
      );
    }

    return (
      <>
        <div className='card card_bordered profile-tokens__column'>
          <div className='card__header'>
            <div className='card__title card__title-l'>
              {getTranslate('profile.apitokens.token.label')}
            </div>
          </div>
          <div className='card__container card__content'>
            <div className='profile-tokens__item profile-tokens__item_inner utils-flex'>
              <div className='profile-tokens__column'>
                <div className='profile-tokens__text'>
                  {getTranslate('profile.apitokens.created.label')}
                </div>
                <div className='profile-tokens__value'>{token.createdAt}</div>
              </div>
              <div className='profile-tokens__column'>
                <div className='profile-tokens__text'>
                  {getTranslate('profile.apitokens.lastUsed.label')}
                </div>
                <div className='profile-tokens__value'>
                  {token.lastUsedAt ||
                    getTranslate('profile.apitokens.neverUsed.label')}
                </div>
              </div>
            </div>
            {token.tokenId && (
              <CopyString
                text={token.tokenId}
                customClass='profile-tokens__item'
              />
            )}
          </div>
        </div>
        <div className='card card_bordered profile-tokens__column'>
          <div className='card__header'>
            <div className='card__title card__title-l'>
              {getTranslate('profile.apitokens.secretKey.label')}
            </div>
          </div>
          <div className='card__container card__content'>
            <div className='profile-tokens__item profile-tokens__text'>
              {getTranslate('profile.apitokens.keyInfo.text')}
            </div>
            {token.secretKey ? (
              <CopyString
                text={token.secretKey}
                customClass='profile-tokens__item'
              />
            ) : (
              <div className='profile-tokens__item'>
                <span className='profile-tokens__hidden utils-hidden'>
                  {getTranslate('profile.apitokens.hidden.label')}
                </span>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default addTranslation(addPermissions(ProfileTokens));
