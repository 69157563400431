import React from 'react';
import Highcharts, { Chart as ChartRef } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Exporting from 'highcharts/modules/exporting';
import throttle from 'lodash/fp/throttle';

import { StoreProps } from 'store';
import Utils from 'helpers/Utils';

import { reflowAllCharts } from 'actions/analytics';
import { ChartViewData } from 'types/Analytics';

Exporting(Highcharts);

interface Props extends StoreProps {
  data: ChartViewData;
  setRef?: (ref: ChartRef) => void;
  className?: string;
  shouldResize: boolean;
}

interface State {
  width: number;
  uuid: string;
}

const THROTTLE_TIMEOUT = 500;
const GRAPH_CARD_PADDING = 48;

class ChartView extends React.Component<Props, State> {
  private chartRef: ChartRef | undefined;
  private chartContainerRef: HTMLDivElement | null | undefined;

  constructor(props) {
    super(props);

    this.setRef = this.setRef.bind(this);
    this.state = {
      width: 0,
      uuid: 'uuid' in this.props.data ? this.props.data.uuid : '',
    };
  }

  componentDidMount() {
    const { shouldResize } = this.props;

    if (shouldResize === true) {
      window.addEventListener('resize', this.fitWidth);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { uuid: oldUuid } = this.state;
    const { shouldResize } = this.props;

    if (
      shouldResize === true &&
      'uuid' in this.props.data &&
      this.props.data.uuid !== oldUuid
    ) {
      this.fitWidth();
    }
  }

  /**
   * Page after canceling print doesn't resize chart
   * https://github.com/highcharts/highcharts/issues/1093
   *
   * mount -> current uuid to state -> print -> reflowAllCharts ->
   * -> new uuid -> compare with uuid in state -> fitWidth()
   */
  get chartEvents() {
    const { dispatch } = this.props;

    return {
      afterPrint: () => {
        dispatch(reflowAllCharts());
      },
    };
  }

  render() {
    const { data, className } = this.props;
    const { width } = this.state;
    const chart = 'chart' in data ? data.chart : undefined;
    const chartData = typeof chart === 'object' ? chart : {};

    return (
      <div
        className={className}
        ref={(ref) => {
          this.chartContainerRef = ref;
        }}>
        <HighchartsReact
          ref={this.setRef}
          highcharts={Highcharts}
          options={{
            ...data,
            chart: {
              ...chartData,
              width: width === 0 ? undefined : width,
              events: this.chartEvents,
            },
          }}
        />
      </div>
    );
  }

  setRef(ref) {
    const { setRef } = this.props;

    if (!ref) {
      return;
    }

    this.chartRef = ref.chart;
    setRef && setRef(ref);
  }

  /**
   * Highcharts по умолчанию не умеет ресайзить график
   * под ширину контейнера. Поэтому при наличии резиновых
   * контейнерах это необходимо делать руками. Метод обходит
   * условие полноэкранного режима, где ресайзить ничего не
   * нужно. Ресайзинг происходит по ширине карточки графика
   * минус отступы с двух сторон.
   *
   * При инитном рендере ширину передавать не нужно, она
   * выставляется по ширине flex-контейнера
   */
  fitWidth = throttle(THROTTLE_TIMEOUT, () => {
    if (this.chartContainerRef) {
      if (!this.chartRef) {
        return;
      }
      let isFullScreen: boolean | undefined = false;
      if (
        this.chartRef &&
        Utils.hasProp(this.chartRef.fullscreen, 'unbindFullscreenEvent')
      ) {
        isFullScreen = this.chartRef.fullscreen.isOpen;
      }
      if (isFullScreen) {
        return;
      }

      const chartContainerParentEl = this.chartContainerRef.parentElement;
      const graphCardEl =
        chartContainerParentEl !== null
          ? chartContainerParentEl.parentElement
          : null;

      if (graphCardEl !== null) {
        this.setState({
          width: graphCardEl.getBoundingClientRect().width - GRAPH_CARD_PADDING,
        });
      }
    }
  });
}

export default ChartView;
