import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getTimezone } from 'actions/dictionaries';
import api from 'api/user';
import TimezoneItem from 'types/TimezoneItem';
import Timezone from './Timezone';
import { StoreProps } from 'store';

interface OwnProps {
  customClass?: string;
}

interface ConnectedProps {
  user: any;
  timezoneList: TimezoneItem[];
}

type Props = OwnProps & ConnectedProps & StoreProps;

interface State {
  isOpenedSelect: boolean;
}

class TimezoneContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpenedSelect: false,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getTimezone());
  }

  render() {
    const { timezoneList, user, customClass } = this.props;
    const { isOpenedSelect } = this.state;

    return (
      <Timezone
        timezoneList={timezoneList}
        currentTimezone={{
          name: user.timezone,
          offset: user.timezoneOffset,
        }}
        isOpenedSelect={isOpenedSelect}
        onToggleSelectPanel={this.toggleTimezoneSelect}
        onUpdateTimezone={(timezone) => this.updateTimezone(timezone)}
        customClass={customClass}
      />
    );
  }

  async updateTimezone(timezone: TimezoneItem) {
    const { user } = this.props;
    if (user.timezone !== timezone) {
      this.toggleTimezoneSelect(false);
      await api.updateUserTimezone(timezone.name);
    }
  }

  toggleTimezoneSelect = (isOpenedSelect?: boolean): void => {
    this.setState((state) => {
      return {
        isOpenedSelect: isOpenedSelect || !state.isOpenedSelect,
      };
    });
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
  timezoneList: state.dictionaries.timezones.list,
});

export default connect(mapStateToProps)(TimezoneContainer);
