import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';
import { DocLinks, MafStatusQueue } from 'types/Api';

const getDemoProject = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.DemoStage_Project,
      },
    ],
  });
};

const getBillingTariffs = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Onboarding_BillingTariffs,
      },
    ],
  });
};

const getDocLinks = (): Promise<DocLinks> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.DemoStage_LandingData,
      },
    ],
  });
};

const confirmInvite = (code: string): Promise<void> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Onboarding_DemoStageInvitation,
        payload: { code },
      },
    ],
  });
};

const contactSales = (params: {
  type: string;
  message?: string;
}): Promise<MafStatusQueue> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Onboarding_ContactSales,
        payload: params,
      },
    ],
    showValidateNotification: false,
  });
};

const acceptPricing = (): Promise<MafStatusQueue> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Onboarding_AcceptPricing,
      },
    ],
  });
};

const commercialOfferWasDownloaded = (): Promise<void> =>
  new RpcService().request({
    messages: [{ type: RpcTypes.Onboarding_CommercialOfferDownloaded }],
  });

const acceptNonSddCommercialOffer = (): Promise<void> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Maf_ApproveCommercialOffer,
      },
    ],
  });
};

export default {
  getDemoProject,
  confirmInvite,
  getDocLinks,
  contactSales,
  acceptPricing,
  getBillingTariffs,
  commercialOfferWasDownloaded,
  acceptNonSddCommercialOffer,
};
