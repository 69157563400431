export default {
  remittanceRecipientAccountId: '',
  projectId: '',
  transferCurrency: '',
  beneficiaryName: '',
  beneficiaryAccount: '',
  beneficiaryBankName: '',
  beneficiaryBankCode: '',
  beneficiaryBankCountry: '',
  beneficiaryBankAddress: '',
  partnershipDescription: '',
  monthlyMinAmount: '',
  transferMaxAmount: '',
  companyLegalCountry: '',
  companyLegalCity: '',
  companyLegalAddress: '',
  companyPostCode: '',
  paymentNumber: '',
  title: '',
};
