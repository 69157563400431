import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { throttle } from 'lodash';

import privateRoutes from 'config/privateRoutes';
import ErrorBoundary from 'components/errorBoundary';

import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { WithRouterProps } from 'decorators/withRouter';
import CustomScrollbar from 'components/ui/customScrollbar';
import HelpDesk from 'components/helpDesk/HelpDeskContainer';
import SearchFiltersContainer from './components/searchFilters';
import MainMenu from './components/mainMenu';
import Header from './components/header';
import PrivacyLinks from 'pages/app/components/policyLinks/PolicyLinks';

import UIHelpers from 'helpers/ui';
import MetricService from 'helpers/metricService/MetricService';
import LocalStorage from 'helpers/LocalStorage';
import landingState from 'constants/landingState';
import Messages from 'constants/rpcTypes';
import { AnyObject } from 'types/Common';
import ThemeConfig from 'types/ThemeConfig';
import breakpoints from 'constants/breakpoints';
import './app.scss';

interface OwnProps {
  settings: {
    themeConfig: ThemeConfig;
    wl: string;
  };
  user: AnyObject;
  isMenuExpanded: boolean;
  isConfirmLogout: boolean;
  saveRoute: (route: string) => void;
  updateConfig: (isOpen: boolean) => void;
}

type Props = OwnProps & WithPermissions & WithRouterProps;

interface State {
  openedBlocks: {
    search: boolean;
    mainMenu: boolean;
  };
  helpdeskOpened: boolean;
  isHelpdeskAvailable: boolean;
  isMobile: boolean;
}

class App extends Component<Props, State> {
  constructor(props) {
    super(props);

    const haveBeenClosedByUser = LocalStorage.get('helpdesk_closed') === true;
    const isHelpdeskAvailable =
      props.isEnabled(Messages.SupportChat_Messages) ||
      props.isEnabled(Messages.HelpTips_List);
    const isMobile: boolean = window.innerWidth <= breakpoints.header;

    this.state = {
      openedBlocks: {
        search: false,
        mainMenu: !isMobile && (this.isDemoStage() || props.isMenuExpanded),
      },
      isHelpdeskAvailable,
      helpdeskOpened: isHelpdeskAvailable && !haveBeenClosedByUser,
      isMobile,
    };

    this.toggleBlock = throttle(this.toggleBlock, 100);
    this.handleResize = throttle(this.handleResize, 200);
  }

  componentDidMount() {
    if (this.isDemoStage() && !this.state.isMobile) {
      this.triggerOpening('mainMenu', true);
    }
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState) {
    const { location } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      this.props.saveRoute(this.props.location.pathname);

      const pathArray = location.pathname.split('/').slice(1);
      pathArray.forEach((item, i) => {
        if (!isNaN(+item)) {
          pathArray[i] = 'detail';
        }
      });

      MetricService.send({
        action: 'routeChange',
        actionKey: pathArray.join('.'),
      });
    }
  }

  render() {
    const { user, isEnabled, settings, isConfirmLogout } = this.props;
    const { openedBlocks, isHelpdeskAvailable, helpdeskOpened, isMobile } =
      this.state;
    return (
      <CustomScrollbar hideVertical={true}>
        <div
          className={classNames('layout-app__inner', {
            'layout-app__inner_expanded': openedBlocks.mainMenu,
          })}>
          <Header
            user={user}
            settings={settings}
            openedBlocks={openedBlocks}
            isHelpdeskAvailable={isHelpdeskAvailable}
            helpdeskOpened={helpdeskOpened}
            isConfirmLogout={isConfirmLogout}
            isDemoStage={this.isDemoStage()}
            toggleBlock={this.toggleBlock}
            toggleHelpDesk={this.toggleHelpDesk}
            isMobile={isMobile}
          />
          {/** Content Block **/}
          <div className='layout-app__wrapper'>
            {/** Menu Block **/}
            <div className='layout-app__menu'>
              <MainMenu
                isOpen={openedBlocks.mainMenu}
                onChooseItem={this.onMenuItemClick}
              />
            </div>
            <div className='layout-app__main-area'>
              {/** Search Block **/}
              {openedBlocks.search && (
                <SearchFiltersContainer
                  onClose={() => this.toggleBlock('search')}
                  isOpened={this.state.openedBlocks.search}
                />
              )}

              <div className='layout-app__content'>
                <Switch>
                  {privateRoutes.map((route, index) => {
                    const PrivateComponent = route.component;
                    const params = route.params;
                    const key = route.key || `${route.path}${index}`;

                    const enabled =
                      !params?.permissions ||
                      params.permissions?.some(isEnabled);

                    return (
                      <Route
                        key={key}
                        exact={route.exact}
                        path={route.path}
                        render={(props) => {
                          if (!enabled) {
                            return <Redirect to='/' />;
                          }
                          return (
                            <ErrorBoundary>
                              <PrivateComponent
                                {...props}
                                {...params}
                                path={props.history.location.pathname}
                              />
                            </ErrorBoundary>
                          );
                        }}
                      />
                    );
                  })}
                </Switch>
              </div>

              <div className='layout-app__footer'>
                <PrivacyLinks />
              </div>
            </div>
          </div>
          <HelpDesk
            isHelpdeskAvailable={isHelpdeskAvailable}
            toggleHelpDesk={this.toggleHelpDesk}
            isOpen={this.state.helpdeskOpened}
            wl={this.props.settings.wl}
          />
        </div>
      </CustomScrollbar>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  toggleHelpDesk = () => {
    this.setState((prevState) => {
      const helpdeskOpened = !prevState.helpdeskOpened;

      LocalStorage.set('helpdesk_closed', !helpdeskOpened);

      return {
        helpdeskOpened,
      };
    });
  };

  toggleBlock = (key: string, isChooseItem = false): void => {
    const { openedBlocks } = this.state;
    const isOpened = !openedBlocks[key];
    if (isChooseItem && isOpened) {
      return;
    }
    this.triggerOpening(key, isOpened);
    setTimeout(
      () => {
        if (this.isDemoStage()) {
          UIHelpers.initResizeEvent(); // if user gets on a page with a table while main menu is open, table might not recalculate it's height
        }
      },
      isOpened ? 0 : 100
    );
    if (key === 'mainMenu') {
      this.props.updateConfig(isOpened);
      MetricService.send({
        action: 'click',
        actionKey: 'mainMenu.expandCollapse.button',
      });
    }
  };

  triggerOpening = (blockType: string, open: boolean) => {
    this.setState((state) => ({
      openedBlocks: { ...state.openedBlocks, [blockType]: open },
    }));
  };

  onMenuItemClick = () => {
    if (this.state.isMobile) {
      this.toggleBlock('mainMenu', false);
    }
  };

  isDemoStage = () => {
    const { user } = this.props;
    return user.landingState === landingState.demoStage;
  };

  handleResize = () => {
    const isMobile: boolean = window.innerWidth <= breakpoints.header;
    if (this.state.isMobile !== isMobile) {
      if (isMobile) {
        this.triggerOpening('mainMenu', false);
      } else if (this.isDemoStage()) {
        this.triggerOpening('mainMenu', true);
      } else {
        this.triggerOpening('mainMenu', this.props.isMenuExpanded);
      }
      this.setState({ isMobile: isMobile });
    }
    document.documentElement.style.setProperty(
      '--app-height',
      `${window.innerWidth}px`
    );
  };
}

export default withRouter(addPermissions(App));
