import filtersKeys from 'constants/filters';
import { FilterTypes } from 'constants/FilterTypes';
import modes from 'components/ui/buttonsGroup/modes';

export default [
  {
    filterId: filtersKeys.reportName,
    type: FilterTypes.text,
    title: 'reports.list.header.name',
    metric: 'reports.fastFilter.reportName.enter',
  },
  {
    filterId: filtersKeys.reportId,
    type: FilterTypes.numbers,
    title: 'reports.list.header.reportTemplateTaskHistoryId',
    metric: 'reports.fastFilter.reportId.enter',
    cleaveOptions: { numericOnly: true, blocks: [11] },
  },
  {
    filterId: filtersKeys.reportType,
    type: FilterTypes.multiSelect,
    dictionary: 'reportType',
    title: 'reports.list.reportType.header',
  },
  {
    filterId: filtersKeys.reportPeriod,
    type: FilterTypes.multiSelect,
    title: 'reports.list.period.header',
    metric: 'reports.fastFilter.period',
    list: [
      { id: 'quarterly', text: 'reports.list.period.values.quarterly' },
      { id: 'monthly', text: 'reports.list.period.values.monthly' },
      { id: 'weekly', text: 'reports.list.period.values.weekly' },
      { id: 'daily', text: 'reports.list.period.values.daily' },
    ],
  },
  {
    filterId: filtersKeys.reportStatus,
    type: FilterTypes.multiSelect,
    metric: 'reports.fastFilter.status',
    title: 'reports.list.status.header',
    list: [
      { id: 'processing', text: 'reports.list.status.values.processing' },
      { id: 'success', text: 'reports.list.status.values.success' },
      { id: 'error', text: 'reports.list.status.values.error' },
    ],
  },
  {
    filterId: filtersKeys.reportRegularity,
    type: FilterTypes.buttonsList,
    valueType: 'string',
    mode: modes.single,
    metric: 'reports.fastFilter.regularity',
    list: [
      { id: 'single', text: 'reports.list.regularity.values.oneTime' },
      { id: 'regular', text: 'reports.list.regularity.values.regular' },
    ],
  },
  {
    filterId: filtersKeys.reportCreatedAt,
    type: FilterTypes.dateButtons,
    metricName: 'reports',
  },
];
