import { IFormDescriptorItem } from 'types/Analytics';

const DeclineCodesChartParams: Array<IFormDescriptorItem> = [
  {
    id: 'chartName',
    label: 'analytics.editForm.params.chartTitle.label',
    placeholder: 'analytics.editForm.params.chartTitle.placeholder',
    type: 'input',
  },
  {
    id: 'chartData',
    type: 'singleSelect',
    label: 'analytics.editForm.params.chartData.label',
    options: [
      {
        value: 'count',
        label: 'analytics.editForm.params.chartData.number.item',
      },
    ],
  },
  {
    id: 'dimension',
    type: 'singleSelect',
    label: 'analytics.editForm.params.determineBy.label',
    options: [
      {
        value: 'mappingResultMessage',
        label: 'analytics.editForm.params.groupBy.declineReasons.item',
      },
    ],
  },
  {
    id: 'chartType',
    type: 'singleSelect',
    label: 'analytics.editForm.params.chartType.label',
    options: [
      { value: 'bar', label: 'analytics.editForm.params.chartType.bar.item' },
      {
        value: 'horizontal_bar',
        label: 'analytics.editForm.params.chartType.horizontalBar.item',
      },
    ],
  },
];

const DeclineCodesFilters: Array<IFormDescriptorItem> = [
  {
    id: 'period',
    type: 'date',
    label: 'analytics.editForm.params.period.label',
  },
  {
    id: 'projectId',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.project.label',
    dictionaryKey: 'projects',
  },
  {
    id: 'paymentMethod',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.paymentMethodType.label',
    dictionaryKey: 'paymentMethod',
  },
  {
    id: 'issuerCountry',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.countryByBIN.label',
    dictionaryKey: 'country',
  },
  {
    id: 'type',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.inOutType.label',
    options: [
      {
        id: 'payin',
        text: 'Pay-In',
      },
      {
        id: 'payout',
        text: 'Pay-Out',
      },
      {
        id: 'refund',
        text: 'Refund',
      },
    ],
  },
];

export { DeclineCodesChartParams, DeclineCodesFilters };
