import React from 'react';
import ApsIcon from 'components/ui/apsIcon';
import './paymentMethods.scss';

export function getMethods(methodsList, idKey = 'id') {
  return methodsList.map((method) => getMethodItem(method, idKey));
}

export function getMethodItem(method, idKey = 'id') {
  return {
    value: method[idKey],
    label: getMethodLabel(method),
  };
}

export function getMethodLabel(method) {
  return (
    <div className='payment-method-option'>
      {method.iconUrl ? (
        <ApsIcon
          iconUrl={method.iconUrl}
          paymentType={method.urlAlias}
          customClass='payment-method-option__icon'
        />
      ) : (
        <span className='payment-method-option__icon' />
      )}

      <span className='payment-method-option__title'>
        {method.title || method.text}
      </span>
    </div>
  );
}
