import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import Tooltip from 'react-tooltip';
import Icon from 'components/ui/icon';
import Animation from 'components/ui/animation';
import Link from 'components/ui/link';
import CustomScrollbar from 'components/ui/customScrollbar';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import Env from 'helpers/Env';
import MetricService from 'helpers/metricService/MetricService';
import I18nHelpers from 'i18n/I18nHelpers';
import landingState from 'constants/landingState';
import Messages from 'constants/rpcTypes';
import './mainMenu.scss';

const publicPath = Env.getPublicPath();

interface OwnProps {
  menuItems: {
    key: string;
    icon: string;
    customUrl?: string;
    metricKey?: string;
    customKey?: string;
  }[];
  isOpen: boolean;
  onChooseItem?: (type: string) => void;
  userLandingState: string;
}

type Props = OwnProps & IntlProps & WithRouterProps & WithPermissions;

class MainMenu extends PureComponent<Props> {
  componentDidMount() {
    Tooltip.rebuild();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.isOpen && !this.props.isOpen) {
      Tooltip.rebuild();
    }
  }

  render() {
    const { menuItems, isOpen, getTranslate, onChooseItem } = this.props;

    return (
      <div
        className={classNames('main-menu', {
          'main-menu_closed': !isOpen,
        })}>
        <Animation>
          <CustomScrollbar hideHorizontal={true}>
            <div className='main-menu__items'>
              {menuItems.map((item) => {
                const { key, icon, customUrl, metricKey, customKey } = item;
                const url = customUrl || key;
                const isCurrent = this.isCurrent(item);
                const title = customKey
                  ? customKey
                  : getTranslate(`mainMenu.${key}`);
                return (
                  <div
                    key={key}
                    data-html={true}
                    data-for='html'
                    data-tip={
                      !isOpen ? I18nHelpers.getHtmlString(title) : undefined
                    }
                    data-class='tooltip-info_brand tooltip-info_main-menu'
                    className={classNames(
                      `main-menu__item main-menu__item_${key}`,
                      {
                        'main-menu__item_current': isCurrent,
                      }
                    )}>
                    <Link
                      onClick={() => {
                        if (onChooseItem) {
                          onChooseItem('mainMenu');
                        }
                        MetricService.send({
                          action: 'click',
                          actionKey: `mainMenu.${metricKey || key}`,
                        });
                      }}
                      className={classNames('main-menu__item-inner')}
                      url={`/${url}`}>
                      <Icon
                        size={18}
                        className='main-menu__item-icon'
                        name={icon}
                      />
                      <div className='main-menu__item-text'>
                        {I18nHelpers.getJsx(title)}
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </CustomScrollbar>
        </Animation>
      </div>
    );
  }

  isCurrent = (item) => {
    const { userLandingState, location } = this.props;
    const url = item.customUrl || item.key;
    const currentPath = location.pathname.slice(1).replace(publicPath, '');

    if (currentPath) {
      return currentPath.indexOf(url) === 0;
    }

    switch (userLandingState) {
      case landingState.maf: {
        return url === 'maf';
      }
      case landingState.demoStage: {
        return url === 'get-started';
      }
      case landingState.default: {
        if (this.props.isEnabled(Messages.GetStarted_View)) {
          return false;
        }
        return url === 'payments';
      }
      default: {
        return url === 'profile';
      }
    }
  };
}

export default withRouter(addTranslation(addPermissions(MainMenu)));
