import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import './labelPeriod.scss';

interface Props {
  text: string;
  type: string;
}

class LabelPeriod extends PureComponent<Props & IntlProps> {
  render() {
    const { text, type, getTranslate } = this.props;

    return (
      <div className={`ui-label-period ui-label-period_${type}`}>
        {getTranslate(text)}
      </div>
    );
  }
}

export default addTranslation(LabelPeriod);
