import { combineReducers } from 'redux';
import app from './app';
import user from './user';
import modal from './modal';
import quickFilters from './quickFilters';
import quickActions from './quickActions';
import filters from './filters';
import searchFilters from './searchFilters';
import configuration from './configuration';
import dictionaries from './dictionaries';
import savedEntities from './savedEntities';
import ui from './ui';
import localization from './localization';
import filtersValues from './filtersValues';
import templateBuilder from './templateBuilder';
import data from './data';
import permissions from './permissions';
import paymentActions from './paymentActions';
import settings from './settings';
import projects from './projects';
import userNotifications from './userNotifications';
import help from './help';
import demoStage from './gemoStage';
import maf from './maf';
import { analytics } from './analytics';
import additionalFeatures from './additionalFeatures';
import paymentSummaryData from './paymentSummary';

const appReducer = combineReducers({
  app,
  user,
  modal,
  quickFilters,
  quickActions,
  filters,
  searchFilters,
  configuration,
  dictionaries,
  savedEntities,
  ui,
  localization,
  filtersValues,
  data,
  templateBuilder,
  permissions,
  paymentActions,
  settings,
  projects,
  userNotifications,
  help,
  demoStage,
  analytics,
  maf,
  additionalFeatures,
  paymentSummaryData,
});

export default (state, action) => {
  return appReducer(state, action);
};
