import React, { PureComponent, ReactNode } from 'react';
import classNames from 'classnames';
import './noContent.scss';

interface Props {
  text?: string | ReactNode;
  className?: string;
}

class NoContent extends PureComponent<Props> {
  static defaultProps = {
    text: 'No data',
  };

  render() {
    const { text, className } = this.props;

    return <div className={classNames('ui-no-content', className)}>{text}</div>;
  }
}

export default NoContent;
