import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateField, update } from 'actions/templateBuilder';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import BuilderGeneral from './BuilderGeneral';
import reportTypes from '../../reportTypes';
import getIntervals from '../../getIntervalsHelper';
import { AnyObject } from 'types/Common';
import { StoreProps } from 'store';

interface ConnectedProps {
  fields: AnyObject;
  validationErrors: AnyObject;
  availableReportTypes: AnyObject[];
  availableIntervalBy: AnyObject;
}

type Props = ConnectedProps & StoreProps & WithPermissions;

class BuilderGeneralContainer extends Component<Props> {
  render() {
    const { fields, availableReportTypes, validationErrors } = this.props;

    return (
      <BuilderGeneral
        fields={fields}
        validationErrors={validationErrors}
        availableReportTypes={availableReportTypes}
        onChangeField={this.change}
      />
    );
  }

  change = (key: string, value: any) => {
    const { fields, availableIntervalBy, dispatch } = this.props;
    if (key === 'reportType') {
      dispatch(
        update('fields', {
          ...fields,
          [key]: value,
          reportScheduleDateType:
            value === reportTypes.interchange
              ? 'single'
              : fields.reportScheduleDateType,
          intervalBy: getIntervals(availableIntervalBy, value),
        })
      );
    } else {
      dispatch(updateField(key, value));
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  availableReportTypes: state.filtersValues.reportType?.list,
  availableIntervalBy: state.filtersValues.reportTemplateIntervalBy?.list,
  fields: state.templateBuilder.fields,
  validationErrors: state.templateBuilder.validationErrors,
});

export default connect(mapStateToProps)(
  addPermissions(BuilderGeneralContainer)
);
