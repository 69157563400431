import React from 'react';
import { MafFormProps } from '../../MafProps';
import { MafTab } from '../../MafTypes';
import './breadcrumbs.scss';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

interface Props extends Pick<MafFormProps, 'getTabByName' | 'onCloseModalTab'> {
  form: MafTab;
  currentFormName: string;
}

export class BreadCrumbs extends React.Component<Props & IntlProps> {
  isNewTab;
  constructor(props: Props & IntlProps) {
    super(props);
    const { form } = props;
    const parentTab = props.getTabByName(form.__parentFormId);
    this.isNewTab = parentTab?.__temporal;
  }

  render() {
    const { currentFormName, onCloseModalTab, getTranslate } = this.props;

    return (
      <div className='maf-breadcrumbs'>
        <div className='maf-breadcrumbs__item' onClick={onCloseModalTab}>
          {currentFormName}
        </div>
        <span>{`>`}</span>
        <div className='maf-breadcrumbs__item'>
          {`${
            this.isNewTab
              ? getTranslate('onboarding.new.button')
              : getTranslate('onboarding.edit.button')
          } ${currentFormName.toLowerCase()}`}
        </div>
      </div>
    );
  }
}

export default addTranslation(BreadCrumbs);
