import BaseMessageHandler from '../BaseMessageHandler';
import { deleteArticle } from 'actions/help';

export default class Article_Delete extends BaseMessageHandler {
  default = () => {
    const {
      dispatch,
      message: { payload },
    } = this;

    if (payload.validationErrors) return;
    dispatch(deleteArticle(payload.articleId));
  };
}
