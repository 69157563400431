import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { replace } from 'lodash';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';

import Loader from 'components/ui/loader';
import DynamicTable from 'components/ui/table/DynamicTable';
import { Column, Header, Row } from 'components/ui/table';
import Checkbox from 'components/ui/checkbox';

import Utils from 'helpers/Utils';
import { minBatchDetailTableColumnsWidth } from './constants';
import tableNames from 'constants/tableNames';
import fieldsMapper from '../fieldsMapper';
import './batchDetail.scss';

interface Props extends IntlProps {
  isLoading: boolean;
  isFetched: boolean;
  itemsList: any;
  columns: any;
  isEditing: boolean;
  isOpenUploading: boolean;
  focusedField: {
    name: string;
    id: string;
  };
  itemsToReject: any[];
  goToPayment: (data) => void;
  selectItemsToReject: (data) => void;
  onTableInputChange: (id, data, value) => void;
  onTableInputBlur: (data) => void;
  onTableInputFocus: (name, value, id) => void;
}

const deleteColumn = { id: 'delete' };

@wrapAppWithCssClass('layout-app_desktop-width')
class BatchDetail extends PureComponent<Props> {
  render() {
    const { itemsList, isLoading, isFetched, goToPayment, columns, isEditing } =
      this.props;
    const customTableConfiguration = isEditing
      ? [deleteColumn].concat(columns)
      : columns;

    return (
      <div className='batch-detail'>
        <div className='batch-detail__content'>
          {isFetched && (
            <DynamicTable
              minColumnsWidth={minBatchDetailTableColumnsWidth}
              // @ts-ignore TODO: t.melashchenko, выручай!
              customConfiguration={customTableConfiguration}
              resizableColumns={!!itemsList.length}
              tableName={tableNames.batch}
              items={itemsList}
              clickToItem={goToPayment}
              rowRender={this.rowRender}
              headerRender={this.renderTableHeader()}
            />
          )}
          {isLoading && <Loader />}
        </div>
      </div>
    );
  }

  renderTableHeader() {
    const {
      isEditing,
      selectItemsToReject,
      itemsToReject,
      itemsList,
      columns,
      getTranslate,
    } = this.props;
    return (
      <Header>
        {isEditing && (
          <Column
            header={true}
            id='reject'
            content={
              <>
                <Checkbox
                  id='delete'
                  checked={itemsToReject.length === itemsList.length}
                  onChange={(e) => selectItemsToReject(e.target.checked)}
                  customClass='batch-detail__header-checkbox'
                />
                {getTranslate('common.reject.button')}
              </>
            }
            modifier='batch-reject'
            customClass={classNames({
              'ui-table__column_batch-reject-active': isEditing,
            })}
          />
        )}

        {columns.map((column) => {
          return (
            <Column
              key={column.id}
              id={column.id}
              modifier={replace(column.id, '.', '-')}
              content={getTranslate(column.localId || column.title)}
            />
          );
        })}
      </Header>
    );
  }

  rowRender = ({ handleDoubleClick, ...data }) => {
    const {
      columns,
      isEditing,
      itemsToReject,
      selectItemsToReject,
      onTableInputChange,
      onTableInputBlur,
      onTableInputFocus,
      isOpenUploading,
      goToPayment,
    } = this.props;

    return (
      <Row
        customClass='batch-detail__row ui-table__row_clickable'
        onClick={() => handleDoubleClick(goToPayment)}
        status={data.paymentStatus}>
        {isEditing && (
          <Column
            id='reject'
            content={
              <Checkbox
                id={`checkbox-${data.paymentId}`}
                checked={itemsToReject.includes(data.payoutQueueId)}
                onChange={() => selectItemsToReject(data.payoutQueueId)}
              />
            }
            modifier='batch-reject'
            customClass={classNames({
              'ui-table__column_batch-reject-active': isEditing,
            })}
          />
        )}
        {columns.map((column) => {
          let content = data[column.id] || data.operationAttributes[column.id];

          if (fieldsMapper[column.id] && data[fieldsMapper[column.id]]) {
            content = data[fieldsMapper[column.id]];
          }

          /***
           * 1. такое поле есть
           * 2. поле не заполнено - помечаем красным
           */
          const checkEditable = () => {
            if (!data.clarificationValues) return false;
            const { clarificationValues } = data;
            if (Utils.hasProp(clarificationValues, column.id)) {
              return clarificationValues[column.id] ? 'edited' : 'error';
            }
            return false;
          };

          const isEditable = checkEditable();

          const edit = () => {
            if (!isEditable) return null;
            return {
              canEdit: isEditing && !isOpenUploading,
              isForbiddenEdit: isOpenUploading || !isEditing,
              onChange: (value) =>
                onTableInputChange(data.paymentId, column.id, value),
              onTableInputFocus: (e) =>
                onTableInputFocus(data.paymentId, column.id, e.target.value),
              onTableInputBlur: (value) =>
                onTableInputBlur({
                  value,
                  field: column.id,
                  payoutQueueId: data.payoutQueueId,
                }),
            };
          };

          return (
            <Column
              key={column.id}
              columnWidths={data.columnWidths}
              id={column.id}
              content={content}
              modifier={replace(column.id, '.', '-')}
              params={{
                valueId: column.id,
                valueType: column.valueType,
                isEditable,
                edit: edit(),
              }}
              customClass={isEditable ? `ui-table__column_${isEditable}` : ''}
            />
          );
        })}
      </Row>
    );
  };
}

export default addTranslation(BatchDetail);
