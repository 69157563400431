import {
  filter,
  head,
  includes,
  indexOf,
  isEmpty,
  map,
  orderBy,
  parseInt,
  replace,
} from 'lodash';
import filtersKeys from '../../../../../constants/filters';
import { PaymentMethodFilters } from '../../../../../types/QuickFilters';
import { SortingType } from '../../../../../types/sortings';
import { Filter, PaymentMethod } from './types';

export const isEmptyFilters = (filters: PaymentMethodFilters) => {
  return !(
    !isEmpty(filters[filtersKeys.method]) ||
    !isEmpty(filters[filtersKeys.currency]) ||
    !isEmpty(filters[filtersKeys.direction]) ||
    !isEmpty(filters[filtersKeys.min_amount]) ||
    !isEmpty(filters[filtersKeys.max_amount])
  );
};

export const updateSelectFilter = (filter: Filter[], values: string[]) => {
  return map(filter, (el) => {
    return { ...el, isSelected: indexOf(values, el.id) !== -1 };
  });
};

export const isContainsValue = (value: any) => {
  return !isEmpty(value) && head(value);
};

export const filterList = (
  list: PaymentMethod[],
  filters: string[],
  key: string
) => {
  return filter(list, (el) => includes(filters, el[key]));
};

export const prepareLimit = (limit: string) => {
  return parseInt(replace(limit, /\s/g, ''));
};

export const sortedList = (
  items: PaymentMethod[],
  field: string,
  order: SortingType
) => orderBy(items, [field], [order]);
