import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty, isEqual, isNull, omitBy } from 'lodash';

import { createReportTemplate, getReportTemplate } from 'api/reports';
import { reset, update, updateField } from 'actions/templateBuilder';
import { addEntityToSaved } from 'actions/savedEntities';
import { openModal } from 'actions/modal';
import { StoreProps } from 'store';
import { WithRouterProps } from 'decorators/withRouter';
import { wrapAppWithCssClass } from 'decorators/wrapAppWithClass';

import Loader from 'components/ui/loader';
import TemplatesBuilder from './TemplatesBuilder';
import BuilderNormalize from './BuilderNormalize';
import savedEntities from 'constants/savedEntities';
import { AnyObject } from 'types/Common';
import path from 'helpers/path';
import MetricService from 'helpers/metricService/MetricService';
import DateHelpers from 'helpers/Date';
import Utils from 'helpers/Utils';
import FiltersHelper from 'components/filters/FiltersHelper';
import reportTypes from './reportTypes';
import { getLatestDate } from './getLatestDate';
import { scrollToError } from 'components/formFields/helpers';

interface OwnProps {
  reportTemplateName?: string | null;
  reportType?: string;
  isTemplate?: boolean;
}

interface ConnectedProps {
  reportTemplateId: string | null;
  user: { availableProjects: AnyObject[] };
  reportsList: AnyObject;
  fields: AnyObject;
  isLoading: boolean;
  validationErrors: {
    [key: string]: string | AnyObject;
  };
  filtersValues: AnyObject;
  filterSet: AnyObject;
  storedSavedEntities: AnyObject;
}

type RouterProps = WithRouterProps<{ id?: string }>;

type Props = OwnProps & ConnectedProps & StoreProps & RouterProps;

interface State {
  isManualInput: boolean;
  isInit: boolean;
}

@wrapAppWithCssClass('layout-app_desktop-m-width')
class TemplatesBuilderContainer extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isManualInput: false,
      isInit: false,
    };
  }

  async componentDidMount() {
    await FiltersHelper.getFiltersValues(
      this.completeFilterSet,
      this.props.filtersValues,
      'reportsBuilder'
    );

    await this.init();
  }

  async componentDidUpdate(prevProps) {
    const { fields } = this.props;
    if (
      (isNull(prevProps.fields) && fields) ||
      !isEqual(
        prevProps.storedSavedEntities?.items,
        this.props.storedSavedEntities?.items
      )
    ) {
      this.setSavedEntities();
    }
  }

  componentWillUnmount(): void {
    const { dispatch } = this.props;
    dispatch(reset());
  }

  render() {
    const { fields, isLoading } = this.props;
    const { isInit } = this.state;

    if (!isInit || !fields) {
      return <Loader />;
    }

    return (
      <TemplatesBuilder
        isLoading={isLoading}
        fields={fields}
        onChangeField={this.change}
        onManualDateChange={this.onManualDateChange}
        onLoadTemplate={(id) => this.loadTemplate(id)}
        onSave={() => {
          return this.checkReportDate() && this.save();
        }}
        onBack={this.goBack}
      />
    );
  }

  get completeFilterSet() {
    const { filterSet } = this.props;
    const result: any[] = [];

    for (const filterKey in filterSet) {
      if (
        Utils.hasProp(filterSet, filterKey) &&
        !isEmpty(filterSet[filterKey])
      ) {
        result.push(filterSet[filterKey]);
      }
    }
    return result;
  }

  async init() {
    const { reportTemplateId, dispatch, history } = this.props;
    if (reportTemplateId) {
      dispatch(update('isTemplateFetching', true));
      try {
        await this.loadTemplate(reportTemplateId);
      } catch ({ payload }) {
        if (payload?.validationErrors?.reportTemplateId) {
          history.push(path('/reports'));
        }
        return false;
      } finally {
        dispatch(update('isTemplateFetching', false));
      }
    } else {
      this.updateFieldsState();
    }

    this.setSavedEntities();

    this.setState({
      isInit: true,
    });
  }

  setSavedEntities = () => {
    const { reportTemplateId, dispatch, fields, storedSavedEntities } =
      this.props;

    if (
      !storedSavedEntities?.isFetch ||
      storedSavedEntities.items.some((entity) => entity.id === reportTemplateId)
    )
      return;

    let savedEntityParams;
    if (reportTemplateId && fields) {
      savedEntityParams = {
        entityKey: savedEntities.reports,
        id: `${reportTemplateId}`,
        urlParams: {
          name: fields.reportName,
        },
      };
    } else {
      if (
        storedSavedEntities?.isFetch &&
        storedSavedEntities.items.some((entity) => entity.id === 'create')
      )
        return;

      savedEntityParams = {
        entityKey: savedEntities.reports,
        id: 'create',
        hiddenParams: { localeKey: 'reports.new.tabHeader' },
      };
    }

    dispatch(addEntityToSaved(savedEntityParams));
  };

  async loadTemplate(reportTemplateId) {
    const data = await getReportTemplate(reportTemplateId);

    const { isTemplate } = this.props;
    const schedulePlan =
      data.periodType !== 'single' && isTemplate
        ? { processingDate: getLatestDate(data.schedulePlan.processingDate) }
        : data.schedulePlan;

    this.updateFieldsState({
      ...data,
      schedulePlan,
      template: {
        ...data.template,
        filter: omitBy(data.template.filter, isNull),
      },
    });
  }

  onManualDateChange = (isManualInput) => {
    this.setState({ isManualInput });
  };

  async save(regularStartAt?) {
    const { fields, filterSet, history, dispatch } = this.props;
    dispatch(update('isLoading', true));

    try {
      const newReport = await createReportTemplate(
        BuilderNormalize.getDataForBackend(
          regularStartAt ? { ...fields, regularStartAt } : fields,
          filterSet
        )
      );

      if (!newReport.reportTemplateId) {
        return false;
      }

      this.sendMetrics();

      history.push(path('/reports'));
    } catch (error) {
      const { payload } = error;
      if (payload && payload.validationErrors) {
        dispatch(update('validationErrors', payload.validationErrors));
        scrollToError(payload.validationErrors);
      } else {
        console.error(error);
      }
    } finally {
      dispatch(update('isLoading', false));
    }
  }

  sendMetrics = () => {
    const {
      match: { params },
      fields,
    } = this.props;

    const { isManualInput } = this.state;

    MetricService.send({
      action: 'click',
      actionKey: params.id
        ? 'reports.editReport.save'
        : 'reports.newReport.save',
    });

    if (fields.reportScheduleDateType === 'regular' && !params.id) {
      MetricService.send({
        action: 'click',
        actionKey: 'reports.newReport.regular.save',
      });
    }

    if (
      fields.intervalBy?.find(
        (option) => option.id === 'operation_created_at' && option.isChecked
      )
    ) {
      MetricService.send({
        action: 'click',
        actionKey: 'reports.newReport.creation.save',
      });
    }
    if (isManualInput) {
      MetricService.send({
        action: 'click',
        actionKey: 'reports.newReport.manualPeriod',
      });
    }
  };

  checkReportDate = () => {
    const {
      fields: { reportScheduleDateType, regularStartAt },
    } = this.props;

    if (reportScheduleDateType !== 'regular') return true;

    const latestDate = getLatestDate(regularStartAt);
    if (latestDate === regularStartAt) return true;

    const { dispatch } = this.props;
    dispatch(
      openModal({
        modalId: 'Confirm',
        content: {
          title: 'common.warning.header',
          text: 'reports.modal.timeUpdate.text',
        },
        callback: (answer) => {
          if (answer) {
            const startDate = DateHelpers.createDate(
              latestDate,
              'datetime'
            ).add(1, 'minutes');
            dispatch(updateField('regularStartAt', startDate));
            this.save(DateHelpers.getFormat(startDate, 'datetime'));
          }
        },
      })
    );
  };

  updateFieldsState = (data = {}) => {
    const { user, filtersValues, filterSet, dispatch } = this.props;
    dispatch(
      update(
        'fields',
        BuilderNormalize.getDataForState({
          data,
          user,
          filtersValues,
          filterSet,
        })
      )
    );
  };

  change = (key: string, value: any): void => {
    const { dispatch } = this.props;
    dispatch(updateField(key, value));
  };

  goBack = () => {
    const { history } = this.props;
    history.push(path('/reports'));
  };
}

const mapStateToProps = (
  state,
  ownProps: OwnProps & RouterProps
): ConnectedProps => ({
  reportTemplateId: ownProps.match.params.id || null,
  user: state.user,
  reportsList: state.data.reports,
  fields: state.templateBuilder.fields,
  isLoading: state.templateBuilder.isLoading,
  validationErrors: state.templateBuilder.validationErrors,
  filtersValues: state.filtersValues,
  filterSet: {
    [reportTypes.operations]: state.filters.reportTemplateOperations,
    [reportTypes.payments]: state.filters.reportTemplatePayments,
    [reportTypes.interchange]: {},
    [reportTypes.chargebacks]: state.filters.reportTemplateChargebacks,
    [reportTypes.fraud]: state.filters.reportTemplateFraud,
    [reportTypes.remittanceOperations]:
      state.filters.reportTemplateRemittanceOperations,
    [reportTypes.remittancePayments]:
      state.filters.reportTemplateRemittancePayments,
  },
  storedSavedEntities: state.savedEntities[savedEntities.reports],
});

export default withRouter(connect(mapStateToProps)(TemplatesBuilderContainer));
