import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './slideDown.scss';

interface Props {
  isOpen: boolean;
  duration?: number;
  customClass?: string;
  updateHeight?: boolean;
}

interface State {
  height: number | string;
  isTransition: boolean;
  isShowContent: boolean;
}

class SlideDown extends PureComponent<Props, State> {
  private slideDownContainer;
  static defaultProps = {
    duration: 200,
  };

  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      isTransition: false,
      isShowContent: props.isOpen,
    };
  }

  componentDidMount(): void {
    const { isOpen } = this.props;
    if (isOpen) {
      this.setState({ height: 'auto' });
      setTimeout(this.setChildHeight, 1000);
    }
  }

  componentDidUpdate(prevProps, prevState): void {
    const { isOpen } = this.props;
    if (prevProps.isOpen !== isOpen) {
      isOpen ? this.open() : this.close();
    }
    if (this.props.updateHeight) {
      this.setChildHeight();
    }
  }

  render() {
    const { children, customClass, duration } = this.props;
    const { height, isTransition, isShowContent } = this.state;

    return (
      <div
        ref={(el) => {
          this.slideDownContainer = el;
        }}
        className={classNames('slide-down', customClass, {
          'slide-down_in-process': isTransition,
        })}
        style={{
          height: height === 'auto' ? 'auto' : `${height}px`,
          transitionDuration: `${duration ?? 200}ms`,
        }}>
        {isShowContent && children}
      </div>
    );
  }

  setChildHeight = () => {
    let height = 0;
    this.slideDownContainer?.childNodes.forEach((node) => {
      height += node.offsetHeight;
    });

    this.setState({ height });
  };

  close = () => {
    const { duration } = this.props;
    this.setState({ isTransition: true, height: 0 });
    setTimeout(() => {
      this.setState({ isTransition: false, isShowContent: false });
    }, duration);
  };

  open = () => {
    const { duration } = this.props;
    this.setState(
      { isTransition: true, isShowContent: true },
      this.setChildHeight
    );
    setTimeout(() => {
      this.setState({ isTransition: false });
    }, duration);
  };
}
export default SlideDown;
