import en from './en.svg';
import ru from './ru.svg';
import de from './de.svg';
import ad from './ad.svg';
import ae from './ae.svg';

export default {
  en,
  ru,
  de,
  ad,
  ae,
};
