import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import urlsMap from 'constants/urlsMap';
import menuConfiguration from 'constants/menuConfiguration';
import Icon from 'components/ui/icon';
import { WithRouterProps } from 'decorators/withRouter';
import SwitcherTabsItem from './types';

interface OwnProps {
  sectionName: string;
  tabs: Array<SwitcherTabsItem>;
  activeTab: string;
  onClick: Function;
}

type Props = OwnProps & WithRouterProps;

class SwitcherTabs extends PureComponent<Props> {
  render() {
    const { tabs, activeTab, onClick, sectionName, location } = this.props;
    const path = (sectionName && urlsMap[sectionName]) || sectionName;
    let currentPath;
    if (sectionName === 'finance') {
      currentPath = `/${location.pathname.split('/')[2]}`;
    } else {
      currentPath = location.pathname;
    }
    const icon = menuConfiguration.find((item) => item.key === sectionName);
    return (
      <div className='saved-entities saved-entities_switcher'>
        <ul className='saved-entities__list'>
          {tabs.map((tab) => (
            <li
              key={tab.id}
              className={classNames(
                'saved-entities__item saved-entities__item_fixed',
                {
                  'saved-entities__item_active':
                    currentPath === `/${tab.path || path}` &&
                    tab.id === activeTab,
                }
              )}
              onClick={() => onClick(tab.id)}>
              <span className='saved-entities__item-outer'>
                <span className='saved-entities__item-inner utils-overflow-dots'>
                  <Icon
                    name={icon?.icon || ''}
                    size={13}
                    className='saved-entities__icon'
                  />
                  {tab.text}
                </span>
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default withRouter(SwitcherTabs);
