import React from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Switch from 'components/ui/switch';
import Input from 'components/ui/input';
import Loader from 'components/ui/loader';
import socialIcons from 'images/social';
import { AnyObject } from 'types/Common';
import './tgmBot.scss';

interface Props extends IntlProps {
  tgmId: string;
  tgmBotIsActive: boolean;
  tgmBotLink: string | null;
  isLoading: boolean;
  validationErrors: AnyObject;
  onToggle: () => void;
  onChangeTgId: (value: string) => void;
  customClass: string;
}

const TgmBot: React.VFC<Props> = ({
  tgmId,
  tgmBotIsActive,
  tgmBotLink,
  isLoading,
  validationErrors,
  onChangeTgId,
  onToggle,
  customClass,
  getTranslate,
}) => (
  <div className={classNames('profile-tgm-bot card card_l', customClass)}>
    <div className='card__header card__header_space-between'>
      <div className='card__title card__title-l'>
        {getTranslate('profile.bot.header')}
      </div>
      <Switch
        id='tgm-bot-switcher'
        textPosition='left'
        checked={tgmBotIsActive}
        disabled={!tgmId}
        text={getTranslate('profile.bot.activate.label')}
        onChange={onToggle}
      />
    </div>
    <div className='card__container card__content'>
      {tgmBotLink && !tgmBotIsActive && (
        <div className='profile-tgm-bot__text'>
          {getTranslate('profile.bot.registrationInfo.text1')}
          <a
            className='profile-tgm-bot__link utils-link'
            href={tgmBotLink}
            rel='noreferrer noopener'
            target='_blank'>
            {getTranslate('profile.bot.registrationInfo.link.text')}
          </a>
          {getTranslate('profile.bot.registrationInfo.text2')}
        </div>
      )}
      <Input
        id='tgm-bot-id-input'
        label='profile.bot.id.label'
        value={tgmId}
        error={validationErrors.tgmId}
        disabled={tgmBotIsActive}
        maxLength={32}
        customClass='profile-tgm-bot__input'
        cleaveOptions={{ numericOnly: true }}
        placeholder={getTranslate('profile.bot.id.placeholder')}
        onChange={({ target: { value } }) => onChangeTgId(value)}
        prefix={
          <img
            className='profile-tgm-bot__img'
            src={socialIcons['telegram']}
            alt='telegram'
          />
        }
        modern
      />
    </div>
    {isLoading && <Loader />}
  </div>
);

export default addTranslation(TgmBot);
