import Utils from 'helpers/Utils';
import DateHelpers from 'helpers/Date';

const initialValue = (valueType) => {
  switch (valueType) {
    case 'string':
      return '';
    case 'number':
      return undefined;
    case 'boolean':
      return false;
  }
};

export const generateId = () => {
  const date = DateHelpers.getFormat(DateHelpers.getDate(), 'date');
  const time = DateHelpers.getFormat(DateHelpers.getDate(), 'timeMS');
  return `${date}-${time}`.replace(/[:.]/g, '');
};

const formStateFactory = (fieldsConfig, canGenerate = false) => {
  return fieldsConfig.reduce((state, field) => {
    if (Array.isArray(field)) {
      return { ...state, ...formStateFactory(field) };
    }

    if (Utils.hasProp(field, 'isFeatureEnabled')) {
      if (field.isFeatureEnabled) {
        return {
          ...state,
          [field.id]: field.initialValue || initialValue(field.valueType),
        };
      }
      return state;
    }
    return {
      ...state,
      [field.id]:
        field.generateValue && canGenerate
          ? generateId()
          : field.initialValue || initialValue(field.valueType),
    };
  }, {});
};
export default formStateFactory;
