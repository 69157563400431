import { DISPUTE_STATUSES } from './ChargebackCardContainer';

export const getCurrentDispute = (disputes) => {
  return disputes.find((dispute) => {
    return (
      dispute.status === DISPUTE_STATUSES.draft ||
      dispute.status === DISPUTE_STATUSES.awaiting_form ||
      dispute.status === DISPUTE_STATUSES.awaiting_delete
    );
  });
};
