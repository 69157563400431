import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import api from 'api/chat';
import stageApi from 'api/getStarted';
import {
  editSupportMessages,
  setSupportMessages,
  updateUserTips,
} from 'actions/user';
import { addListeners } from 'decorators/addListeners';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import { StoreProps } from 'store';

import HelpDesk, { TabsType } from './HelpDesk';
import Env from 'helpers/Env';
import DateHelpers from 'helpers/Date';
import { MESSAGES_PER_REQUEST } from '../supportChat/constants';
import Utils from 'helpers/Utils';
import LocalStorage from 'helpers/LocalStorage';
import { getSupportMessage } from '../supportChat/helpers';

import { DROPZONE_CLASSNAME } from 'components/ui/dropzone/Dropzone';
import Messages from 'constants/rpcTypes';
import landingState from 'constants/landingState';
import tableNames from 'constants/tableNames';
import { SupportMessages } from 'types/Api';

import './helpDesk.scss';

type Tips = { [key: string]: boolean } | null;

interface OwnProps {
  wl: string;
  isOpen: boolean;
  toggleHelpDesk: () => void;
  isHelpdeskAvailable: boolean;
}

interface ConnectedProps {
  docLinks?: {
    links: {
      documentation: { [key: string]: string };
    };
  };
  userLandingState: string;
  userTips: Tips;
  currentTip: string;
  supportMessages: SupportMessages;
}

type Props = OwnProps &
  ConnectedProps &
  WithPermissions &
  IntlProps &
  StoreProps &
  WithRouterProps;

interface State {
  isLoading: boolean;
  canRender: boolean;
  openTab: number;
  tabs: { tips: boolean; chat: boolean };
}

const pageTipsMap = {
  finance: 'balances',
  chargebacks: 'chargebacks_registry',
  projects: 'projects',
  maf: 'business_docs',
  team: 'my_team',
  payments: 'payments_registry',
  manual: 'manual_payments_registry',
  invoices: 'payment_links_registry',
  reports: 'reports_registry',
  references: 'payment_confirmations',
};

@addListeners([Messages.SupportChat_Message, Messages.SupportChat_Messages])
class HelpDeskContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      canRender: false,
      openTab: this.defaultOpenTab,
      tabs: {
        chat: this.isTabEnabled(TabsType.chat),
        tips: this.isTabEnabled(TabsType.tips),
      },
    };
  }

  componentDidMount() {
    this.init();
    this.correctTipsVisibilityState();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { currentTip } = this.props;
    if (prevProps.currentTip === currentTip) {
      return;
    }

    this.correctTipsVisibilityState();
  }

  render() {
    const {
      wl,
      toggleHelpDesk,
      userLandingState,
      userTips,
      supportMessages,
      currentTip,
      docLinks,
    } = this.props;
    const { isLoading, canRender, openTab, tabs } = this.state;

    if (!this.props.isHelpdeskAvailable || !canRender) {
      return null;
    }

    return (
      <HelpDesk
        openTab={openTab}
        setOpenTab={this.setOpenTab}
        tabs={tabs}
        docLinks={docLinks?.links.documentation}
        tips={userTips}
        currentTip={currentTip}
        toggleHelpDesk={toggleHelpDesk}
        wl={wl}
        isOpen={this.props.isOpen}
        messages={supportMessages}
        isLoading={isLoading}
        userLandingState={userLandingState}
        downLoadTemplates={this.downLoadTemplates}
        uploadTemplate={this.uploadTemplate}
      />
    );
  }

  setOpenTab = (tab) => {
    this.setState({ openTab: tab });
    LocalStorage.set('helpDeskTab', tab);
  };

  get defaultOpenTab() {
    const prevOpenTab = LocalStorage.get('helpDeskTab');
    if (this.isTabEnabled(prevOpenTab)) {
      return prevOpenTab;
    }
    return this.isTabEnabled(TabsType.tips) ? TabsType.tips : TabsType.chat;
  }

  isTabEnabled = (tabIndex: TabsType) => {
    switch (tabIndex) {
      case TabsType.chat:
        return this.props.isEnabled(Messages.SupportChat_Messages);
      case TabsType.tips:
        return this.props.isEnabled(Messages.HelpTips_List);
    }
  };

  correctTipsVisibilityState = () => {
    const { currentTip, isOpen, toggleHelpDesk } = this.props;
    const { tabs } = this.state;

    if (tabs.chat && currentTip === 'no_tip') {
      this.setState((prevState) => ({
        openTab: TabsType.chat,
        tabs: { ...prevState.tabs, tips: false },
      }));
      return;
    }

    this.setState((prevState) => ({
      tabs: {
        ...prevState.tabs,
        tips: !!currentTip && currentTip !== 'no_tip',
      },
      openTab: this.defaultOpenTab,
    }));

    if (
      (isOpen && currentTip === 'no_tip') ||
      (!isOpen &&
        currentTip &&
        currentTip !== 'no_tip' &&
        LocalStorage.get('helpdesk_closed') !== true)
    ) {
      toggleHelpDesk();
    }
  };

  setCurrentTipContent = () => {
    const currentTip = this.tipContentId;
    this.props.dispatch(updateUserTips({ currentTip }));
  };

  uploadTemplate = () => {
    const uploadButton = document.querySelector(
      `.${DROPZONE_CLASSNAME} > .${DROPZONE_CLASSNAME}-text .file-picker-button`
    ) as any;

    if (uploadButton) {
      uploadButton.click();
    }
  };

  downLoadTemplates = async (page) => {
    const type = this.props.history.location.state?.currentTab?.[page];

    const links = {
      payouts: type
        ? {
            url: Env.getApiUrl() + `files/mass_${type}_cards.csv`,
            name: `mass_${type}_cards.csv`,
          }
        : null,
      risks: {
        url: Env.getApiUrl() + `files/rcs.csv`,
        name: 'rcs.csv',
      },
    };

    const link = links[page];
    if (link) {
      await fetch(link.url)
        .then((res) => res.text())
        .then((data) => {
          const blob = new Blob([data], { type: 'text/csv' });

          const a = document.createElement('a') as any;
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = link.name;
          a.click();
          window.URL.revokeObjectURL(url);
        });
      return;
    }
    console.warn('No payout type specified');
  };

  get homePageTip() {
    const { userLandingState } = this.props;
    switch (userLandingState) {
      case landingState.maf:
        return pageTipsMap.maf;
      case landingState.default:
        return pageTipsMap.payments;
      default:
        return 'no_tip';
    }
  }

  get tipContentId() {
    const {
      history: { location },
    } = this.props;
    const { state, pathname } = location;

    if (!this.props.isEnabled(Messages.HelpTips_List)) {
      return undefined;
    }

    const contentId = pageTipsMap[location.pathname.replace('/', '')];

    if (contentId) {
      return contentId;
    }

    switch (pathname) {
      case '/':
        return this.homePageTip;
      case '/manual/request':
        if (state?.currentTab) {
          switch (state.currentTab?.payouts) {
            case 'mass':
            case 'payout':
            case 'refund':
            case 'capture':
            case 'cancel':
              return 'manual_payments_bulk';
            case 'moto':
              return 'manual_payments_moto';
            default:
              return 'manual_payments_request';
          }
        }
        return 'manual_payments_request';
      case '/reports/create':
        return 'reports_form';
      case '/invoices/create':
        return 'payment_link';
      case '/risks':
        if (state?.currentTab?.riskTable === tableNames.rcs) {
          switch (state?.currentTab?.riskManage) {
            case 'single':
              return 'risks_bw_single';
            case 'multiple':
              return 'risks_bw_bulk';
            default:
              return 'risks_bw_registry';
          }
        }
        return 'risks_fraud_registry';
      default:
        return 'no_tip';
    }
  }

  async init() {
    const { history, isEnabled, supportMessages } = this.props;
    try {
      this.setState({ isLoading: true });

      const promises: any[] = [];

      if (isEnabled(Messages.HelpTips_List)) {
        promises.push(stageApi.getDocLinks());
        promises.push(api.getTips());
      }

      if (
        isEnabled(Messages.SupportChat_Messages) &&
        !supportMessages?.isFetched
      ) {
        promises.push(api.getMessages());
      }
      await Promise.all(promises);
      this.setCurrentTipContent();
      this.setState({ canRender: true });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isLoading: false, canRender: true });
    }
    history.listen(() => {
      this.setCurrentTipContent();
    });
  }

  async onEvent({ data, name }) {
    const { supportMessages, dispatch, getTranslate } = this.props;
    if (data.payload.validationErrors) {
      return;
    }
    switch (name) {
      case 'SupportChat_Messages': {
        const prevList = supportMessages?.list || [];

        let currDate = DateHelpers.getDate();

        const list = data.payload.list;

        const newList = list
          .filter((m) => m.text)
          .concat(prevList)
          .map((msg, i) => {
            if (msg.createdAtTs) {
              msg = DateHelpers.formatMessageDate(msg, currDate, i === 0);
              currDate = DateHelpers.createDateFromTs(msg.createdAtTs);
            }
            return msg;
          });

        if (!list.length && !prevList.length) {
          newList.push(
            getSupportMessage({
              id: 'info-welcome',
              text: getTranslate('chatBot.welcome.text'),
            })
          );
        }

        dispatch(
          setSupportMessages({
            ...data.payload,
            list: newList,
            hasMoreMessages:
              list.length > 0 && list.length < MESSAGES_PER_REQUEST,
            isFetched: true,
          })
        );
        break;
      }
      case 'SupportChat_Message': {
        const { list = [], unreadCount: prevUnreadCount } =
          supportMessages || {};

        let msg = data.payload?.message;

        const lastMessage = list[list.length - 1];
        const currDate = lastMessage
          ? DateHelpers.createDateFromTs(lastMessage.createdAtTs)
          : DateHelpers.getDate();

        if (msg?.createdAtTs) {
          msg = DateHelpers.formatMessageDate(msg, currDate, list.length === 0);
        }

        const unreadCount = Utils.hasProp(data.payload, 'unreadCount')
          ? data.payload.unreadCount
          : prevUnreadCount + Number(!!msg?.from);

        dispatch(
          editSupportMessages({
            unreadCount,
            list: list.concat(msg),
            limits: data.payload?.limits,
          })
        );
        break;
      }
      default:
        break;
    }
  }
}

const mapStateToProps = (state): ConnectedProps => {
  const lang = state.user.interfaceLang || 'en';
  return {
    docLinks: state.demoStage.docLinks?.[lang],
    userLandingState: state.user.landingState,
    userTips: state.user.tips?.list || {},
    currentTip: state.user.tips?.currentTip,
    supportMessages: state.user.supportMessages || {},
  };
};

export default withRouter(
  connect(mapStateToProps)(addTranslation(addPermissions(HelpDeskContainer)))
);
