import React, { PureComponent, ReactElement } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import Panel from 'components/ui/panel';

import './infoButton.scss';
import CustomScrollbar from '../customScrollbar';

interface Props extends IntlProps {
  content: ReactElement | string;
  customClass?: string;
  title?: string;
  isMenuExpanded?: boolean;
  relativeMenu?: boolean;
}

interface State {
  isOpen: boolean;
}

class InfoButton extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    const {
      content,
      customClass,
      title,
      getTranslate,
      isMenuExpanded,
      relativeMenu,
    } = this.props;
    const { isOpen } = this.state;
    return (
      <div
        className={classNames('ui-info-button', customClass, {
          'ui-info-button_active': isOpen,
        })}>
        <Button
          icon='information'
          iconSize={30}
          status='light-inverse'
          onClick={this.togglePanel}
          customClass='ui-info-button__button'
        />
        <Panel
          id='info-panel'
          isOpened={isOpen}
          title={getTranslate(title)}
          customClass='ui-panel_left-side ui-panel_info-button'
          excludeFromCloseTrigger={['.ui-info-button']}
          onClose={this.closePanel}
          modern
          isMenuExpanded={isMenuExpanded}
          relativeMenu={relativeMenu}>
          <CustomScrollbar>{content}</CustomScrollbar>
        </Panel>
      </div>
    );
  }

  togglePanel = (): void => {
    this.setState((state) => {
      return {
        isOpen: !state.isOpen,
      };
    });
  };

  closePanel = () => {
    this.setState({
      isOpen: false,
    });
  };
}
export default addTranslation(InfoButton);
