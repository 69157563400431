import React, { Component } from 'react';
import checkFilters from 'helpers/checkFilters';
import { WithRouterProps } from 'decorators/withRouter';

import Recurring from './Recurring';

type Props = WithRouterProps<{ id?: string }>;

class RecurringContainer extends Component<Props> {
  componentDidMount() {
    checkFilters('recurring');
  }

  render() {
    const {
      match: { params },
    } = this.props;

    return <Recurring id={params.id} />;
  }
}

export default RecurringContainer;
