import React, { Component } from 'react';
import api from 'api/tokens';
import ProfileTokens from './ProfileTokens';
import TokenType from './TokenType';
import { addListeners, IListeners } from 'decorators/addListeners';
import checkIsMafUser from 'decorators/checkIsMafUser';
import Messages from 'constants/rpcTypes';
import MetricService from 'helpers/metricService/MetricService';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';

interface Props extends WithPermissions {
  customClass: string;
}

interface State {
  token: TokenType | null;
  isLoading: boolean;
  isFetch: boolean;
  canShow: boolean;
}

@checkIsMafUser
@addListeners([
  Messages.UserApiToken_Generate,
  Messages.UserApiToken_Remove,
  Messages.Confirm_Accept,
])
class ProfileTokensContainer
  extends Component<Props, State>
  implements IListeners
{
  constructor(props) {
    super(props);

    const canShow = props.isEnabled(Messages.UserApiToken_List);
    this.state = {
      token: null,
      isLoading: canShow,
      isFetch: false,
      canShow,
    };
  }

  async componentDidMount() {
    const { canShow } = this.state;
    if (!canShow) return;
    await this.getToken();
  }

  render() {
    const { token, isLoading, isFetch, canShow } = this.state;
    if (!canShow) return null;

    return (
      <ProfileTokens
        token={token}
        isLoading={isLoading}
        isFetch={isFetch}
        onRemove={() => this.removeToken()}
        onCreate={() => this.generateToken()}
        customClass={this.props.customClass}
      />
    );
  }

  async getToken() {
    try {
      const token = await api.getToken();

      this.setToken(token[0]);
      this.setState({ isFetch: true });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async generateToken() {
    this.setState({ isLoading: true });
    try {
      MetricService.send({
        actionKey: 'userProfile.newToken',
        action: 'click',
      });
      await api.generateToken();
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async removeToken() {
    this.setState({ isLoading: true });
    try {
      await api.removeToken();
    } finally {
      this.setState({ isLoading: false });
    }
  }

  setToken = (token) => {
    if (token) {
      this.setState({
        token: ProfileTokensContainer.prepareTokenForState(token),
      });
    } else {
      this.setState({
        token: null,
      });
    }
  };

  onEvent({ data, name }) {
    const { payload } = data;
    if (name === Messages.UserApiToken_Generate) {
      this.setToken(payload);
    } else if (name === Messages.UserApiToken_Remove) {
      this.setToken(null);
    }
  }

  static prepareTokenForState(token): TokenType {
    return {
      tokenId: token.value,
      secretKey: token.salt,
      createdAt: token.createdAt,
      lastUsedAt: token.lastUsedAt,
    };
  }
}

export default addPermissions(ProfileTokensContainer);
