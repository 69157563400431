import React from 'react';
import NoContent from 'components/ui/noContent';
import getLocalizedText from 'helpers/getLocalizedText';
import './errorBoundary.scss';

interface Props {
  error: any;
}

class ErrorBoundary extends React.Component<Props> {
  render() {
    return (
      <div className='error-boundary'>
        <NoContent text={getLocalizedText('common.sectionError.text')} />
      </div>
    );
  }
}

export default ErrorBoundary;
