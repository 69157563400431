import React, { PureComponent } from 'react';
import { getRemittancePayment } from 'api/remittance';
import PageTemplate from 'components/pageTemplate';
import SavedEntitiesContainer from 'components/savedEntities/SavedEntitiesContainer';
import TopPanelContainer from 'components/topPanel';
import DataListContainer from 'components/dataListContainer/DataListContainer';
import { Column, Row } from 'components/ui/table';
import RemittanceAccountBuilder from './remittanceAccountBuilder';
import RemittancePaymentBuilder from './remittancePaymentBuilder';
import PaymentCardContainer from 'components/paymentCard';
import Button from 'components/ui/button';
import savedEntities from 'constants/savedEntities';
import tableNames from 'constants/tableNames';
import { minRemittanceAccountsColumnWidth } from './minRemittanceAccountsColumnWidth';
import quickFiltersConfig from './quickFiltersConfig';
import Messages from 'constants/rpcTypes';
import { tableRoutes, createRoutes } from './routesMapper';
import permissionReasons from 'constants/permissionReasons';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import SwitcherTabs from 'components/ui/switcherTabs';
import BatchDetail from './batchDetail';
import ItemConfiguration from 'types/ItemConfiguration';
import urlsMap from 'constants/urlsMap';
import { isCustomId } from 'pages/remittance/helpers';

interface Props extends IntlProps, WithPermissions {
  id?: string;
  isNewTab: boolean;
  tableName:
    | tableNames.massRemittancePayments
    | tableNames.remittancePayments
    | tableNames.remittanceRecipientAccounts;
  pathname: string;
  isRecipientAccountCard: boolean;
  configuration: ItemConfiguration[];
  onClickItem: (data: any) => void;
  onDelete: (id: any) => void;
  goToBuilder: () => void;
  changeCurrentTable: (tableName: string) => void;
  apiRequest: any;
  isBatchDetail: boolean;
}

class Remittance extends PureComponent<Props> {
  render() {
    const { getTranslate } = this.props;
    return (
      <PageTemplate.Main>
        <PageTemplate.Entities>
          <SwitcherTabs
            activeTab={this.props.tableName}
            sectionName='remittance'
            tabs={[
              {
                id: tableNames.remittancePayments,
                text: getTranslate(
                  'remittance.quickFilters.registryType.payouts'
                ),
              },
              {
                id: tableNames.massRemittancePayments,
                path: `${urlsMap.remittance}/${
                  tableRoutes[tableNames.massRemittancePayments]
                }`,
                text: getTranslate(
                  'remittance.quickFilters.registryType.mass.payouts'
                ),
              },
              {
                id: tableNames.remittanceRecipientAccounts,
                path: `${urlsMap.remittance}/${
                  tableRoutes[tableNames.remittanceRecipientAccounts]
                }`,
                text: getTranslate(
                  'remittance.quickFilters.registryType.recipientAcc'
                ),
              },
            ]}
            onClick={this.props.changeCurrentTable}
          />
          <SavedEntitiesContainer
            entityKey={savedEntities.remittance}
            isNew={this.props.isNewTab}
            isFirstTab={false}
          />
        </PageTemplate.Entities>
        {this.renderContent()}
      </PageTemplate.Main>
    );
  }

  renderContent = () => {
    const {
      id,
      tableName,
      pathname,
      apiRequest,
      isRecipientAccountCard,
      isBatchDetail,
    } = this.props;
    const isDetailPage = isCustomId(id);
    if (pathname.includes('create-remittance')) {
      return (
        <PageTemplate.Container>
          <RemittancePaymentBuilder id={id} />
        </PageTemplate.Container>
      );
    } else if (
      isRecipientAccountCard ||
      pathname.includes(createRoutes[tableNames.remittanceRecipientAccounts])
    ) {
      return <RemittanceAccountBuilder id={id} />;
    }
    if (isDetailPage && isBatchDetail) {
      return <BatchDetail id={id} />;
    } else if (isDetailPage) {
      return (
        <PaymentCardContainer
          id={id}
          savedEntity={savedEntities.remittance}
          apiRequest={getRemittancePayment}
          config={{
            isCustomerInfo: false,
            isAddToList: false,
            isManagePayment: false,
            isRemittanceRecipientAccount: true,
          }}
        />
      );
    }

    return (
      <PageTemplate.Container customClass='remittance-page'>
        <TopPanelContainer
          // @ts-ignore TODO: t.melashchenko, выручай!
          name={tableName}
          filtersConfig={quickFiltersConfig[tableName]}
          button={this.renderCreateButton()}
          isConfigurationButton={
            tableName === tableNames.massRemittancePayments
          }
        />
        <DataListContainer
          minColumnsWidth={minRemittanceAccountsColumnWidth}
          tableName={tableName}
          quickFilter={tableName}
          apiRequest={apiRequest}
          rowRender={this.rowRender}
        />
      </PageTemplate.Container>
    );
  };

  rowRender = ({ handleDoubleClick, ...data }) => {
    const { configuration, onClickItem, onDelete } = this.props;

    return (
      <Row
        key={data.id}
        onClick={() => handleDoubleClick(onClickItem)}
        customClass='ui-table__row_clickable'>
        {configuration.map((column) => {
          const { id } = column;
          let content = data[column.id];
          if (id === 'edit') {
            content = (
              <Button
                status='transparent'
                disabled={data.status === 'created'}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(data.remittanceRecipientAccountId);
                }}
                icon='im-Basket'
              />
            );
          }
          return (
            <Column
              key={id}
              id={id}
              columnWidths={data.columnWidths}
              forbidResizeFor={data.forbidResizeFor}
              content={
                content || <span className='ui-table__column-empty'>—</span>
              }
              data={data}
              params={{
                ...column,
                valueId: id,
                valueType: column.valueType,
              }}
              customClass={`ui-table__column_${id}`}
            />
          );
        })}
      </Row>
    );
  };

  renderCreateButton = () => {
    const {
      tableName,
      getTranslate,
      goToBuilder,
      isEnabled,
      isDisabledByReason,
    } = this.props;
    const availableCreate =
      isEnabled(Messages.Remittance_CreatePayment) ||
      isDisabledByReason(
        Messages.Remittance_CreatePayment,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );

    return tableName !== tableNames.remittanceRecipientAccounts ? (
      availableCreate && (
        <Button
          status='primary'
          text={getTranslate('remittance.payouts.addNewRemittance.button')}
          onClick={goToBuilder}
        />
      )
    ) : (
      <Button
        status='primary'
        text={getTranslate('remittance.recipientAcc.addNewRecipientAcc.button')}
        onClick={goToBuilder}
      />
    );
  };
}

export default addTranslation(addPermissions(Remittance));
