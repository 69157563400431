import Repository from 'helpers/Repository';
import { ChartTypesUnion } from 'types/Analytics';

const isOperationDmsEnabled = (): boolean => {
  const { user } = Repository.get('store').getState();

  return user.isOperationDmsFilterEnabled;
};

const isOperationsLastStepCascadeFilterEnabled = (): boolean => {
  const { user } = Repository.get('store').getState();

  return user.isAnalyticsOperationsFilterByLastStepCascadeEnabled === true;
};

const isGraph = (chartType: ChartTypesUnion): boolean => {
  return (
    chartType === 'pie chart' ||
    chartType === 'bar' ||
    chartType === 'horizontal_bar' ||
    chartType === 'line'
  );
};

export {
  isOperationDmsEnabled,
  isGraph,
  isOperationsLastStepCascadeFilterEnabled,
};
