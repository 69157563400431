import { ThunkDispatch } from 'redux-thunk';

import ActionReducer from 'types/ActionReducer';
import configureStore from './configureStore';

export const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;

export interface StoreProps {
  dispatch: ThunkDispatch<RootState, void, ActionReducer>;
}
