import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import MainMenu from './MainMenu';
import menuItems from 'constants/menuConfiguration';
import { RootState, StoreProps } from 'store';

interface OwnProps {
  onChooseItem?: (type: string) => void;
  isOpen: boolean;
}

interface ConnectedProps {
  landingState: string;
}

type Props = OwnProps & ConnectedProps & StoreProps & WithPermissions;

class MainMenuContainer extends PureComponent<Props> {
  render() {
    const { onChooseItem, landingState, isOpen } = this.props;
    return (
      <MainMenu
        menuItems={this.getMenuItems()}
        onChooseItem={onChooseItem}
        isOpen={isOpen}
        userLandingState={landingState}
      />
    );
  }

  getMenuItems = () => {
    const { isEnabled } = this.props;
    return menuItems.filter((item) => {
      const { permissions, adminTool } = item;
      if (!permissions) return true;
      return (
        permissions &&
        permissions.some((permission) => isEnabled(permission)) &&
        !adminTool
      );
    });
  };
}

const mapStateToProps = (state: RootState): ConnectedProps => ({
  landingState: state.user.landingState,
});

export default connect(mapStateToProps)(addPermissions(MainMenuContainer));
