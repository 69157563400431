export const currency = ['EUR', 'GBP', 'PLN', 'RUB', 'USD', 'UAH', 'IDR'];

export const totalDataSectionRows = [
  {
    id: 'total_safeguarded',
    title: 'total_safeguarded',
  },
  {
    id: 'safeguarded_eur',
    title: 'safeguarded',
  },
  {
    id: 'total_need_to_safeguard',
    title: 'Total Need To Safeguard',
  },
  {
    id: 'need_to_safeguard_eur',
    title: 'Need To Safeguard (EUR)',
  },
  {
    id: 'assets',
    title: 'Total Assets',
  },
  {
    id: 'assets_eur',
    title: 'Total Assets (EUR)',
  },
];
