import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { invert } from 'lodash';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { WithRouterProps } from 'decorators/withRouter';

import FormFields from 'components/formFields';
import SingleRequest from './singleRequest';
import MassRequestContainer from './massRequest';
import Moto from './moto';
import HelpDeskHelpers from 'components/helpDesk/HelpDeskHelpers';
import motoFieldsConfig from './moto/fieldsConfig';
import permissionReasons from 'constants/permissionReasons';
import { PayoutTab, payoutTabs } from './constants';
import tableNames from 'constants/tableNames';
import { formIdRoutes } from '../routesMapper';
import path from 'helpers/path';
import urlsMap from 'constants/urlsMap';

interface OwnProps {
  onBack: (tableName: string) => void;
}

type Props = OwnProps &
  IntlProps &
  WithPermissions &
  WithRouterProps<{ formId: string }>;

interface State {
  currentForm: string;
}

class CreateRequest extends PureComponent<Props, State> {
  defaultActiveTab;
  constructor(props: Props) {
    super(props);

    const { state } = this.props.history.location;
    if (state?.currentPayoutTab) {
      const currentPayoutTab = state.currentPayoutTab;
      const currentTabIndex = this.tabs.findIndex(
        (tab) => tab.type === currentPayoutTab.type
      );
      this.defaultActiveTab = currentTabIndex >= 0 ? currentTabIndex : 0;
    }

    this.state = {
      currentForm: '',
    };
  }

  componentDidMount() {
    this.setFormTab();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.formId !== this.props.match.params.formId) {
      this.setFormTab();
    }
    if (prevState.currentForm !== this.state.currentForm) {
      this.setFormRoute();
    }
  }

  render() {
    if (!this.state.currentForm) return null;

    return (
      <FormFields
        id={this.state.currentForm}
        title='manual.addPayout.header'
        tabs={this.tabs}
        // @ts-ignore TODO: t.melashchenko, выручай!
        fieldsConfig={this.state.currentForm === 'moto' && motoFieldsConfig}
        onChangeTab={this.onChangeTab}
        formFields={this.getFields()}
      />
    );
  }

  handleTabsChange = (tabIndex = 0) => {
    HelpDeskHelpers.setCurrentTab.call(this, {
      payouts: this.tabs[tabIndex].type,
    });
  };

  onChangeTab = (_, tabId) => {
    this.setState({ currentForm: tabId });
    this.handleTabsChange();
  };

  setFormTab = () => {
    if (!this.state) return;
    const currentRoute = this.props.location.pathname.split('/')[3];
    const formRoutes = invert(formIdRoutes);
    const availableTab = this.getAvailableTab(formRoutes[currentRoute]);

    if (availableTab !== this.state.currentForm) {
      this.onChangeTab(null, availableTab);
    }
  };

  setFormRoute = () => {
    if (
      !this.state.currentForm ||
      this.props.match.params.formId === formIdRoutes[this.state.currentForm]
    )
      return;

    if (
      this.props.match.params.formId !== formIdRoutes[this.state.currentForm]
    ) {
      this.props.history.push(
        path(
          `/${urlsMap.payouts}/request/${formIdRoutes[this.state.currentForm]}`
        )
      );
    }
  };

  get tabs(): PayoutTab[] {
    const { isEnabled, isDisabledByReason } = this.props;

    return payoutTabs
      .filter((tab) => {
        return (
          isEnabled(tab.permission) ||
          isDisabledByReason(
            tab.permission,
            permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
          )
        );
      })
      .map((tab) => {
        return {
          ...tab,
          isCurrent: this.props.location.pathname.includes(tab.type),
        };
      });
  }

  getAvailableTab = (tabToOpen) => {
    const availableTab = this.tabs.find((tab) => tab.id === tabToOpen);
    return availableTab?.id || this.tabs[0].id;
  };

  getFields = () => {
    switch (this.state.currentForm) {
      case 'single':
        return <SingleRequest />;
      case 'mass':
      case 'payout':
      case 'refund':
      case 'capture':
      case 'cancel':
        return (
          <MassRequestContainer
            type={this.state.currentForm}
            onBack={() => this.props.onBack(tableNames.massPayouts)}
          />
        );
      case 'moto':
        return <Moto />;
    }
  };
}

export default withRouter(addTranslation(addPermissions(CreateRequest)));
