import React from 'react';
import { withRouter } from 'react-router-dom';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { WithRouterProps } from 'decorators/withRouter';
import Button from 'components/ui/button';
import path from 'helpers/path';
import Messages from 'constants/rpcTypes';

interface OwnProps {
  isAdmin?: boolean;
  customClass?: string;
}

type Props = OwnProps & IntlProps & WithPermissions & WithRouterProps;

const CreateUserButton: React.FC<Props> = ({
  history,
  getTranslate,
  isAdmin,
  isEnabled,
  customClass = '',
}) => {
  const canCreate = isEnabled(Messages.Team_CreateUser);
  return (
    <Button
      status='primary'
      onClick={() =>
        history.push(path(`/${isAdmin ? 'admin' : 'team'}/create`))
      }
      text={getTranslate('myteam.newuser.button')}
      disabled={!isAdmin && !canCreate}
      customClass={customClass}
      tooltip={
        !isAdmin && !canCreate
          ? getTranslate('myteam.viewOnly.hint')
          : undefined
      }
    />
  );
};

export default withRouter(addTranslation(addPermissions(CreateUserButton)));
