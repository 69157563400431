import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import classNames from 'classnames';

import Radio from 'components/ui/radio';
import ButtonsGroup from 'components/ui/buttonsGroup';
import Button from 'components/ui/button';
import RadioGroup from 'components/ui/radioGroup';
import TextBlock from 'components/ui/textBlock';
import InputDateRange from 'components/ui/inputDateRange';
import DateHelpers from 'helpers/Date';
import { getLatestDate } from '../../getLatestDate';
import { AnyObject } from 'types/Common';
import reportTypes from '../../reportTypes';
import { periodTypes } from 'constants/dateFormats';
import { Dictionary } from 'types/FilterValue';
import InputDate from 'components/ui/inputDate';
import './builderSchedule.scss';

interface Props extends IntlProps {
  fields: AnyObject;
  reportTemplateLimits: Dictionary;
  validationErrors: AnyObject;
  onChangeField: (key: string, value: any, isManual?: boolean) => void;
  onFocusDateField: (e) => void;
}

interface State {
  showDatePicker: boolean;
}

class BuilderSchedule extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showDatePicker: false,
    };

    this.attachEvents();
  }

  componentWillUnmount(): void {
    this.detachEvents();
  }

  render() {
    const {
      fields,
      onChangeField,
      onFocusDateField,
      reportTemplateLimits,
      validationErrors,
      getTranslate,
    } = this.props;
    const { showDatePicker } = this.state;
    const {
      reportScheduleDateType,
      singlePeriod,
      singlePeriodRange,
      regularPeriod,
      reportType,
    } = fields;
    const isChargebacks = reportType === reportTypes.chargebacks;
    const applyFormat = isChargebacks ? 'date' : 'datetime';
    const minDate =
      reportTemplateLimits.list[fields.reportType].periodType.single.minDate;

    return (
      <div className='templates-builder-schedule card'>
        <div className='card__header'>
          <div className='card__title'>
            {getTranslate('reports.new.dateAndTime.label')}
          </div>
        </div>
        <div className='grid-row card__content'>
          <div className='grid-column-6 templates-builder-schedule__column'>
            <div className='card__container'>
              <Radio
                id='single'
                text={getTranslate('reports.new.dateAndTime.oneTime.radio')}
                isChecked={reportScheduleDateType === 'single'}
                onChange={() =>
                  onChangeField('reportScheduleDateType', 'single')
                }
                customClass='templates-builder__item'
              />
              <div
                className={classNames('templates-builder-schedule__block', {
                  'templates-builder-schedule__block_disabled':
                    reportScheduleDateType !== 'single' && !showDatePicker,
                })}>
                <ButtonsGroup
                  activeButtons={[singlePeriod]}
                  mode='single'
                  onClick={(values) => onChangeField('singlePeriod', values[0])}
                  className='ui-buttons-group_separate templates-builder__item'
                  theme='dark'>
                  <Button
                    status='outline'
                    id='today'
                    text={getTranslate('reports.new.dateAndTime.today.button')}
                  />
                  <Button
                    status='outline'
                    id='yesterday'
                    text={getTranslate(
                      'reports.new.dateAndTime.yesterday.button'
                    )}
                  />
                  <Button
                    status='outline'
                    id={periodTypes.LastWeek}
                    text={getTranslate(
                      'reports.new.dateAndTime.lastWeek.button'
                    )}
                  />
                  <Button
                    status='outline'
                    id={periodTypes.LastMonth}
                    text={getTranslate(
                      'reports.new.dateAndTime.lastMonth.button'
                    )}
                  />
                  <Button
                    status='outline'
                    id={periodTypes.LastQuarter}
                    text={getTranslate(
                      'reports.new.dateAndTime.lastQuarter.button'
                    )}
                  />
                </ButtonsGroup>
                <div className='templates-builder__item'>
                  <span className='templates-builder__label templates-builder__label_col'>
                    {getTranslate(
                      'common.enterDate.period.incorrectFormat.text'
                    )}
                  </span>
                  <div
                    className='templates-builder-schedule__date-time'
                    id='dateTo'>
                    <InputDateRange
                      value={{
                        dateFrom: singlePeriodRange.dateFrom,
                        dateTo: singlePeriodRange.dateTo,
                      }}
                      error={getTranslate(
                        validationErrors.schedulePlan?.dateFrom ||
                          validationErrors.schedulePlan?.dateTo
                      )}
                      withTime={!isChargebacks}
                      minDate={
                        minDate
                          ? DateHelpers.getFormat(
                              DateHelpers.createDate(minDate, applyFormat),
                              applyFormat
                            )
                          : undefined
                      }
                      onChange={(date) => {
                        return onChangeField('singlePeriodRange', date, true);
                      }}
                      monthsAmount={3}
                      panelPosition={{ top: 32, left: 0 }}
                    />
                  </div>
                </div>
                {this.renderSorting('single')}
                {isChargebacks && (
                  <TextBlock
                    text={getTranslate(
                      'reports.new.dateAndTime.chargebacks.text'
                    )}
                    theme='grey'
                  />
                )}
              </div>
            </div>
          </div>
          <div className='grid-column-6 templates-builder-schedule__column'>
            <div className='card__container'>
              <Radio
                id='regular'
                text={getTranslate('reports.new.dateAndTime.regular.radio')}
                isChecked={reportScheduleDateType === 'regular'}
                onChange={() =>
                  onChangeField('reportScheduleDateType', 'regular')
                }
                isDisabled={fields.reportType === reportTypes.interchange}
                customClass='templates-builder__item'
              />
              <div
                className={classNames('templates-builder-schedule__block', {
                  'templates-builder-schedule__block_disabled':
                    reportScheduleDateType !== 'regular',
                })}>
                <ButtonsGroup
                  id='schedulePlan'
                  activeButtons={[regularPeriod]}
                  mode='single'
                  onClick={(values) =>
                    onChangeField('regularPeriod', values[0])
                  }
                  className='ui-buttons-group_separate templates-builder__item'
                  theme='dark'>
                  <Button
                    status='outline'
                    id='daily'
                    text={getTranslate('reports.new.dateAndTime.daily.button')}
                  />
                  <Button
                    status='outline'
                    id='weekly'
                    text={getTranslate('reports.new.dateAndTime.weekly.button')}
                  />
                  <Button
                    status='outline'
                    id='monthly'
                    text={getTranslate(
                      'reports.new.dateAndTime.monthly.button'
                    )}
                  />
                  <Button
                    status='outline'
                    id='quarterly'
                    text={getTranslate(
                      'reports.new.dateAndTime.quarterly.button'
                    )}
                  />
                </ButtonsGroup>
                {typeof validationErrors?.schedulePlan === 'string' && (
                  <div className='templates-builder-schedule__error'>
                    {getTranslate('reports.new.dateAndTime.needSchedule.msg')}
                  </div>
                )}
                <div className='templates-builder__item'>
                  <InputDate
                    id='processingDate'
                    title={getTranslate(
                      'reports.new.dateAndTime.startDate.label'
                    )}
                    tooltip=''
                    selectedDate={this.renderRegularStartAtText()}
                    minDate={DateHelpers.getFormat(
                      DateHelpers.getDate(),
                      'date'
                    )}
                    maxDate={DateHelpers.getFormat(
                      DateHelpers.addYears(1),
                      applyFormat
                    )}
                    onChange={(date) =>
                      onChangeField('regularStartAt', getLatestDate(date))
                    }
                    customClass='templates-builder-schedule__date'
                    error={validationErrors.schedulePlan?.processingDate}
                    withTime={!isChargebacks}
                    applyFormat={applyFormat}
                    onFocus={onFocusDateField}
                    withCloseButton={false}
                    scrollIntoView={false}
                    closeAfterSelect
                    isHideYearPicker
                  />
                  <div>{this.getHint()}</div>
                </div>
                {this.renderSorting('regular')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSinglePeriodText = () => {
    const { singlePeriodRange } = this.props.fields;
    return singlePeriodRange.length ? singlePeriodRange.join('–') : '';
  };

  renderRegularStartAtText = () => {
    const { regularStartAt, reportType } = this.props.fields;
    const dateFormat =
      reportType === reportTypes.chargebacks ? 'date' : 'datetime';
    if (regularStartAt) {
      const date = DateHelpers.createDate(regularStartAt, 'datetime');
      let dateForRender = DateHelpers.getFormat(date, dateFormat);
      dateForRender += ` ${DateHelpers.getFormat(date, 'dayOfWeek')}`;
      return dateForRender;
    }
    return '';
  };

  renderSorting = (regularity) => {
    const {
      fields: { intervalBy, reportScheduleDateType },
      onChangeField,
      validationErrors,
      getTranslate,
    } = this.props;

    const isCurrentRegularity = reportScheduleDateType === regularity;
    const selected = isCurrentRegularity
      ? intervalBy.find((item) => item.isChecked)?.id
      : '';
    const error = isCurrentRegularity
      ? validationErrors?.template?.orderBy
      : '';
    return (
      <div className='templates-builder__item templates-builder__item_sorting'>
        <RadioGroup
          checkedId={selected}
          onChange={(checkedId) => {
            const order = intervalBy.map((item) => {
              return {
                ...item,
                isChecked: item.id === checkedId,
              };
            });
            onChangeField('intervalBy', order);
          }}
          error={error}
          customClass='ui-radio-group_row'>
          {intervalBy.map((item) => (
            <Radio id={item.id} text={getTranslate(item.text)} key={item.id} />
          ))}
        </RadioGroup>
      </div>
    );
  };
  getHint = () => {
    const {
      fields: { regularPeriod },
      getTranslate,
    } = this.props;

    if (!regularPeriod || regularPeriod === 'single') {
      return getTranslate(`reports.new.dateAndTime.hint.values.default`);
    }
    return getTranslate(`reports.new.dateAndTime.hint.values.${regularPeriod}`);
  };

  attachEvents = () => {
    document.addEventListener('mousedown', this.closeListener);
  };

  detachEvents = () => {
    document.removeEventListener('mousedown', this.closeListener);
  };

  closeListener = ({ target }) => {
    if (
      !target.closest('.ui-date-picker') &&
      !target.closest('.ui-date-picker-range')
    ) {
      this.close();
    }
  };

  close = () => {
    this.setState({
      showDatePicker: false,
    });
  };

  open = (picker: string) => {
    this.setState((state) => {
      return {
        ...state,
        [picker]: true,
      };
    });
  };
}

export default addTranslation(BuilderSchedule);
