import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { closeModal, openModal } from 'actions/modal';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addListeners } from 'decorators/addListeners';
import { deleteFinancialFile, downloadFinancialFile } from 'api/financialData';
import { deleteDataItem } from 'actions/getData';
import FinancialData from 'pages/financialData/FinancialData';
import { loadFromLink } from 'helpers/saveFile';
import { StoreProps } from 'store';
import tableNames from 'constants/tableNames';
import Messages from 'constants/rpcTypes';
import { AnyObject } from 'types/Common';
import ItemConfiguration from 'types/ItemConfiguration';
import showNotification from 'components/ui/notification/showNotification';
import './financialData.scss';

interface ConnectedProps {
  configuration: ItemConfiguration[];
  data: AnyObject;
}

interface State {
  isDeleting: boolean;
  checkedList: Record<number, boolean>;
  loadingFiles: string[];
}

type Props = ConnectedProps & StoreProps & IntlProps;

@addListeners([
  Messages.FinancialData_FileDownloadReady,
  Messages.FinancialData_FileUpdated,
])
class FinancialDataContainer extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      checkedList: {},
      loadingFiles: [],
      isDeleting: false,
    };
  }

  render() {
    return (
      <FinancialData
        configuration={this.props.configuration}
        dataListLength={this.props.data.items.length}
        selectedAmount={this.getSelectedAmount()}
        checkedList={this.state.checkedList}
        loadingFiles={this.state.loadingFiles}
        isDeleting={this.state.isDeleting}
        openModal={this.openModal}
        onDelete={this.confirmModal}
        handleCheck={this.handleCheck}
        selectAll={this.selectAll}
        downloadFile={this.downloadFile}
      />
    );
  }

  openModal = () => {
    this.props.dispatch(
      openModal({
        modalId: 'UploadFinancialData',
      })
    );
  };

  getSelectedAmount = () => {
    return Object.values(this.state.checkedList).filter((item) => item).length;
  };

  handleCheck = (id) => {
    this.setState((state) => ({
      checkedList: {
        ...state.checkedList,
        [id]: !state.checkedList[id],
      },
    }));
  };

  selectAll = () => {
    const selectedAmount = this.getSelectedAmount();
    if (selectedAmount && selectedAmount === this.props.data.items.length) {
      this.setState({ checkedList: {} });
    } else {
      const checkedList = this.props.data.items.reduce((result, row) => {
        return { ...result, [row.financialFileId]: true };
      }, {});
      this.setState({ checkedList });
    }
  };

  downloadFile = async (id) => {
    this.setState((state) => ({
      loadingFiles: [id, ...state.loadingFiles],
    }));

    try {
      await downloadFinancialFile([id]);
    } catch (e) {
      console.error('Download file error: ', e);
    }
  };

  onDelete = async () => {
    this.setState({ isDeleting: true });

    try {
      const selectedFileIds = Object.keys(this.state.checkedList).filter(
        (id) => this.state.checkedList[id]
      );
      const deletedRows = await deleteFinancialFile(selectedFileIds);
      deletedRows.forEach((row) => {
        this.props.dispatch(
          deleteDataItem(
            tableNames.financialFile,
            'financialFileId',
            row.financialFileId
          )
        );
      });
      showNotification({
        status: 'success',
        content: this.props.getTranslate(
          'admin.uploadFinancialData.deleteCsv.success.modal.text'
        ),
      });
      this.setState({
        checkedList: {},
      });
    } catch (e) {
      console.error('Deleting error: ', e);
    } finally {
      this.setState({ isDeleting: false });
    }
  };

  confirmModal = () => {
    this.props.dispatch(
      openModal({
        modalId: 'Confirm',
        content: {
          title: 'admin.uploadFinancialData.deleteCsv.modal.header',
          text: this.props.getTranslate(
            'admin.uploadFinancialData.deleteCsv.modal.text',
            { batchCount: this.getSelectedAmount() }
          ),
        },
        callback: (isAgree) => isAgree && this.onDelete(),
      })
    );
  };

  onEvent = ({ name, data }) => {
    if (name === Messages.FinancialData_FileUpdated) {
      if (!data.payload.errors) {
        this.props.dispatch(closeModal());
      }
    } else {
      loadFromLink(data.payload.url);

      this.setState((state) => ({
        loadingFiles: state.loadingFiles.filter(
          (loadingId) => !data.payload.financialFileIds.includes(loadingId)
        ),
      }));
    }
  };
}

const mapStateToProps = (state) => ({
  configuration: state.configuration.financialFile,
  data: state.data.financialFile,
});

export default connect(mapStateToProps)(addTranslation(FinancialDataContainer));
