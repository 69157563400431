export interface ActionTypes {
  click: 'click';
  scrollVertical: 'scrollVertical';
  scrollHorizontal: 'scrollHorizontal';
  viewItem: 'view_item';
  refund: 'refund';
  notification: 'notification';
}

export interface ActionCategories {
  unknownCategory: 'unknownCategory';
  userProfile: 'userProfile';
  myTeam: 'myTeam';
  reports: 'reports';
  balanceStatement: 'balanceStatement';
  finance: 'finance';
  payments: 'payments';
  massPayouts: 'massPayouts';
  payouts: 'payouts';
  manualPayments: 'manualPayments';
  projects: 'projects';
  rcsFraud: 'rcsFraud';
  risks: 'risks';
  invoices: 'invoices';
  chargebacks: 'chargebacks';
  subscriptionPayments: 'subscriptionPayments';
  subscriptions: 'subscriptions';
}

export const EntitiyNamesMap = {
  remittance: 'remittances',
  recurring: 'subscriptionPayments',
};

// prettier-ignore
export const ActionKeys = {
  'search.button.save': 'search.button.save',
  'search.button.reset': 'search.button.reset',

  'userProfile.edit.save': 'userProfile.edit.save',
  'userProfile.favCcy.save': 'userProfile.favCcy.save',
  'userProfile.newToken': 'userProfile.newToken',
  'userProfile.changePassword.confirm': 'userProfile.changePassword.confirm',

  'myTeam.newUser.create': 'myTeam.newUser.create',
  'myTeam.editUser.save.yes': 'myTeam.editUser.save.yes',
  'myTeam.users.registry.delete.yes': 'myTeam.users.registry.delete.yes',
  'myTeam.users.registry.deactivate.yes': 'myTeam.users.registry.deactivate.yes',
  'myTeam.newUser.backToRegistry': 'myTeam.newUser.backToRegistry',
  'teamUsers.registry.verticalScroll': 'teamUsers.registry.verticalScroll',
  'teamUsers.registry.horizontalScroll': 'teamUsers.registry.horizontalScroll',
  'teamActivities.registry.verticalScroll': 'teamActivities.registry.verticalScroll',
  'teamActivities.registry.horizontalScroll': 'teamActivities.registry.horizontalScroll',

  'reports.registry.download': 'reports.registry.download',
  'reports.newReport.save': 'reports.newReport.save',
  'reports.newReport.regular.save': 'reports.newReport.regular.save',
  'reports.newReport.creation.save': 'reports.newReport.creation.save',
  'reports.newReport.deleteTemplate': 'reports.newReport.deleteTemplate',
  'reports.newReport.manualPeriod': 'reports.newReport.manualPeriod',
  'reports.editReport.save': 'reports.editReport.save',
  'reports.registry.delete.yes': 'reports.registry.delete.yes',
  'reports.registry.activation': 'reports.registry.activation',
  'reports.registry.verticalScroll': 'reports.registry.verticalScroll',
  'reports.registry.horizontalScroll': 'reports.registry.horizontalScroll',

  'balanceStatement.filter.search': 'balanceStatement.filter.search',
  'finance.statement.verticalScroll': 'finance.statement.verticalScroll',
  'finance.statement.horizontalScroll': 'finance.statement.horizontalScroll',
  'finance.statement.showTotals': 'finance.statement.showTotals',
  'finance.statement': 'finance.statement',
  'finance.realtime.filter.contracts.apply': 'finance.realtime.filter.contracts.apply',
  'balanceRealTime.registry.verticalScroll': 'balanceRealTime.registry.verticalScroll',
  'balanceRealTime.registry.horizontalScroll': 'balanceRealTime.registry.horizontalScroll',
  'finance.old.balanceDetails': 'finance.old.balanceDetails',
  'finance.old.filter.contracts.apply': 'finance.old.filter.contracts.apply',


  'payments.registry.verticalScroll': 'payments.registry.verticalScroll',
  'payments.registry.horizontalScroll': 'payments.registry.horizontalScroll',
  'payments.registry.card': 'payments.registry.card',
  'payments.paymentCard.lastCallback': 'payments.paymentCard.lastCallback',
  'payments.paymentCard.resendCallback': 'payments.paymentCard.resendCallback',
  'payments.paymentCard.refund.refund': 'payments.paymentCard.refund.refund',
  'payments.paymentCard.payout': 'payments.paymentCard.payout',
  'payments.paymentCard.customerInfo': 'payments.paymentCard.customerInfo',
  'payments.paymentCard.blackList.apply': 'payments.paymentCard.blackList.apply',
  'payments.paymentCard.whiteList.apply': 'payments.paymentCard.whiteList.apply',
  'payments.registry.filterDeactivation': 'payments.registry.filterDeactivation',

  'massPayouts.registry.card': 'massPayouts.registry.card',
  'massPayouts.registry.verticalScroll': 'massPayouts.registry.verticalScroll',
  'massPayouts.registry.horizontalScroll': 'massPayouts.registry.horizontalScroll',
  'payouts.registry.verticalScroll': 'payouts.registry.verticalScroll',
  'payouts.registry.horizontalScroll': 'payouts.registry.horizontalScroll',
  'payouts.registry.card': 'payouts.registry.card',
  'manualPayments.registry.massRequests': 'manualPayments.registry.massRequests',
  'manualPayments.request.singlePayout.send': 'manualPayments.request.singlePayout.send',
  'manualPayments.request.massPayouts.send': 'manualPayments.request.massPayouts.send',
  'manualPayments.request.massRefunds.send': 'manualPayments.request.massRefunds.send',
  'manualPayments.request.singlePayout.backToRegistry': 'manualPayments.request.singlePayout.backToRegistry',
  'manualPayments.request.massRefunds.backToRegistry':'manualPayments.request.massRefunds.backToRegistry',
  'manualPayments.request.massPayouts.backToRegistry': 'manualPayments.request.massPayouts.backToRegistry',
  'manualPayments.registry.filterDeactivation': 'manualPayments.registry.filterDeactivation',

  'projects.paymentMethods.save': 'projects.paymentMethods.save',
  'projects.redirectURLs.save': 'projects.redirectURLs.save',

  'rcsFraud.registry.verticalScroll': 'rcsFraud.registry.verticalScroll',
  'rcsFraud.registry.horizontalScroll': 'rcsFraud.registry.horizontalScroll',
  'rcsFraud.registry.card': 'rcsFraud.registry.card',
  'risks.blackWhiteLists.registry.filter.listType.blacklist': 'risks.blackWhiteLists.registry.filter.listType.blacklist',
  'risks.blackWhiteLists.registry.filter.listType.whitelist': 'risks.blackWhiteLists.registry.filter.listType.whitelist',
  'risks.blackWhiteLists.manage.apply': 'risks.blackWhiteLists.manage.apply',
  'rcsBlackWhite.registry.verticalScroll': 'rcsBlackWhite.registry.verticalScroll',
  'rcsBlackWhite.registry.horizontalScroll': 'rcsBlackWhite.registry.horizontalScroll',

  'invoices.newInvoice.create': 'invoices.newInvoice.create',
  'invoices.registry.verticalScroll': 'invoices.registry.verticalScroll',
  'invoices.registry.horizontalScroll': 'invoices.registry.horizontalScroll',

  'chargebacks.registry.verticalScroll': 'chargebacks.registry.verticalScroll',
  'chargebacks.registry.horizontalScroll': 'chargebacks.registry.horizontalScroll',
  'chargebacks.registry.chargebackCard': 'chargebacks.registry.chargebackCard',
  'chargebacks.registry.accept.yes': 'chargebacks.registry.accept.yes',
  'chargebacks.chargebackCard.accept.yes': 'chargebacks.chargebackCard.accept.yes',
  'chargebacks.chargebackCard.recommendedDocs': 'chargebacks.chargebackCard.recommendedDocs',

  'subscriptionPayments.registry.verticalScroll': 'subscriptionPayments.registry.verticalScroll',
  'subscriptionPayments.registry.horizontalScroll': 'subscriptionPayments.registry.horizontalScroll',
  'subscriptions.registry.subscriptionCard': 'subscriptions.registry.subscriptionCard',
  'subscriptions.subscriptionCard.subscriptionsSettings.save': 'subscriptions.subscriptionCard.subscriptionsSettings.save',
  'subscriptions.subscriptionCard.cancel.yes': 'subscriptions.subscriptionCard.cancel.yes',

  'mainMenu.analytics': 'mainMenu.analytics',
  'mainMenu.payments': 'mainMenu.payments',
  'mainMenu.manualPayments': 'mainMenu.manualPayments',
  'mainMenu.subscriptions': 'mainMenu.subscriptions',
  'mainMenu.reports': 'mainMenu.reports',
  'mainMenu.finance': 'mainMenu.finance',
  'mainMenu.balance': 'mainMenu.balance',
  'mainMenu.risks': 'mainMenu.risks',
  'mainMenu.chargebacks': 'mainMenu.chargebacks',
  'mainMenu.projects': 'mainMenu.projects',
  'mainMenu.businessDocs': 'mainMenu.businessDocs',
  'mainMenu.myTeam': 'mainMenu.myTeam',
  'mainMenu.help': 'mainMenu.help',
  'mainMenu.icons': 'mainMenu.icons',
  'mainMenu.references': 'mainMenu.references',
  'mainMenu.invoices': 'mainMenu.invoices',

  'notification.open':'notification.open',

  'maf.button.save': 'maf.button.save',
  'maf.button.sendToValidation': 'maf.button.sendToValidation',
  'mainMenu.getStarted': 'mainMenu.getStarted',

  'myTeam.fastFilter.userName.enter':'myTeam.fastFilter.userName.enter',
  'myTeam.fastFilter.status.active':'myTeam.fastFilter.status.active',
  'myTeam.fastFilter.status.blocked':'myTeam.fastFilter.status.blocked',
  'myTeam.fastFilter.status.inactive':'myTeam.fastFilter.status.inactive',
  'myTeam.fastFilter.role.operation': 'myTeam.fastFilter.role.operation',
  'myTeam.fastFilter.role.finance': 'myTeam.fastFilter.role.finance',
  'myTeam.fastFilter.role.support': 'myTeam.fastFilter.role.support',
  'myTeam.fastFilter.role.risk': 'myTeam.fastFilter.role.risk',
  'finance.old.filter.agreementNumber.apply': 'finance.old.filter.agreementNumber.apply',
  'finance.old.filter.agreementBrandLe': 'finance.old.filter.agreementBrandLe',
  'finance.statement.filter.agreementNumber.search': 'finance.statement.filter.agreementNumber.search',
  'finance.statement.filter.agreementBrandLe': 'finance.statement.filter.agreementBrandLe',
  'reports.fastFilter.type.operations': 'reports.fastFilter.type.operations',
};

export interface OptionsTypes {
  action: keyof ActionTypes | string;
  actionKey: keyof typeof ActionKeys | string;
  params?: any;
}
