import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

export const getPermissions = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.MessagesPermissions,
      },
    ],
  });
};

export default {
  getPermissions,
};
