import React from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import MafHelpers from 'components/maf/MafHelpers';
import { MafField, MafTab } from 'components/maf/MafTypes';
import { AnyObject } from 'types/Common';
import './overview.scss';

interface Props extends IntlProps {
  currentSteps: MafTab[];
  validationErrors: AnyObject;
  onChangeStep: (index: number) => void;
  isVisible: (form: MafTab, field: MafField) => boolean;
  isFormLocked: boolean;
}

class Overview extends React.Component<Props> {
  render() {
    const { onChangeStep, validationErrors, getTranslate } = this.props;
    return (
      <div className='maf-form-container'>
        <div className='maf-form__title'>
          {getTranslate('overview.maf.tab')}
        </div>
        {this.props.currentSteps?.map((step, index) => {
          const isValid = MafHelpers.validateSection(step, validationErrors);

          if (!this.doesStepEditable(step)) {
            return null;
          }

          const isPending = step.tabs?.some((tab) => {
            return (
              !MafHelpers.validateSection(tab, validationErrors) &&
              MafHelpers.isTabAwaitingClarification(tab)
            );
          });

          return (
            <div className='maf-form-overview' key={step.name}>
              <div className='maf-form-container__main'>
                <div
                  className={classNames('maf-form maf-form__card-view', {
                    [`maf-form__card-view_pending`]: isPending,
                    'maf-form__card-view_error': !isValid && !isPending,
                  })}>
                  {!isValid && (
                    <div className='maf-form__card-status-text'>
                      <span
                        className={`maf-form__card-status-text${
                          isPending ? '_pending' : '_error'
                        }`}>
                        {isPending
                          ? 'AWAITING CLARIFICATION'
                          : getTranslate('fill.missinginfo.alert.text')}
                      </span>
                    </div>
                  )}
                  <div className={classNames('maf-form__card-view-header')}>
                    <span className='maf-form__card-view-header_inner'>
                      {getTranslate(
                        step.__tabGroup ? step.tabs?.[0]?.caption : step.caption
                      )}
                    </span>
                  </div>
                  <div className='maf-form__card-view-content'>
                    {this.getCardPreview(step)}
                  </div>

                  <Button
                    size='normal'
                    status='light'
                    customClass='maf-form-overview__to-section-button'
                    text={this.getButtonText(isValid)}
                    onClick={() => onChangeStep(index)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  getButtonText(isValid: boolean) {
    const { isFormLocked, getTranslate } = this.props;

    if (isFormLocked) {
      return getTranslate('businessdocuments.doc.action.view');
    }

    if (isValid) {
      return getTranslate('onboarding.edit.button');
    }

    return getTranslate('fill.missingdata.alert.text');
  }

  getCardPreview = (
    card: MafTab,
    previewData: Array<
      { separator: true } | { name: string; value?: string }
    > = []
  ) => {
    const { getTranslate } = this.props;

    if (
      card.tab_template &&
      card.tabs?.length > 0 &&
      card.tab_template.item_type === 'single_field'
    ) {
      const values = card.tabs
        .map((tab) => MafHelpers.formatCardPreviewValue(tab.tabs[0].fields[0]))
        .sort()
        .join(', ');

      previewData.push({
        name: card.tab_template.tabs[0]?.fields?.[0]?.caption,
        value: values,
      });
    } else if (
      card.tab_template &&
      card.tabs?.length > 0 &&
      card.behaviour_type === 'filebin'
    ) {
      const values = card.tabs
        .map(
          (tab) =>
            tab.tabs[0].fields.find((field) => field.type === 'file')
              ?.lookup_display_value
        )
        .join(', ');

      previewData.push({
        name: 'Documents',
        value: values,
      });
    } else if (card.tabs) {
      card.tabs.forEach((tab) => {
        if (card.tab_template && card.tabs?.length > 0) {
          previewData.push({ separator: true });
        }

        this.getCardPreview(tab, previewData);
      });

      if (card.tab_template && card.tabs?.length > 0) {
        previewData.push({ separator: true });
      }
    }

    if (card.fields && card.fields.length) {
      card.fields.forEach((field) => {
        const value = MafHelpers.formatCardPreviewValue(field);
        if (
          value &&
          field?.type !== 'details' &&
          field?.type !== 'checkbox' &&
          this.props.isVisible(card, field)
        ) {
          previewData.push({ name: field.caption, value });
        }

        if (
          field?.type === 'checkbox' &&
          field?.value === 'True' &&
          this.props.isVisible(card, field)
        ) {
          previewData.push({ name: field.caption, value: 'Yes' });
        }
      });
    }

    return (
      <table className='maf-form-overview__fields-table'>
        <tbody>
          {previewData.map((item, index) =>
            'separator' in item ? (
              <tr key={index} className='maf-form-overview__row-separator'>
                <td colSpan={2}></td>
              </tr>
            ) : (
              <tr key={index} className='maf-form-overview__field'>
                <td className='maf-form-overview__field-name'>
                  <div className='maf-form-overview__table-cell-content'>
                    {getTranslate(item.name)}
                  </div>
                </td>
                <td className='maf-form-overview__field-value'>
                  <div className='maf-form-overview__table-cell-content'>
                    {item.value}
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    );
  };

  doesStepEditable = (step: MafTab) => {
    // details fields are not editable, just info field
    const editableFields = step.fields?.filter(
      (field) => field?.type !== 'details'
    );
    const visibleAndEditableFields = editableFields?.filter((field) =>
      this.props.isVisible(step, field)
    );

    return (
      step.tabs ||
      step.tab_template || // listed tabs are editable when empty
      visibleAndEditableFields.length > 0
    );
  };
}

export default addTranslation(Overview);
