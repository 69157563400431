import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import AccordionItem from 'components/ui/accordion';
import TreeTable from 'components/ui/treeTable';
import {
  BalanceDetails,
  Columns,
} from 'pages/finance/statement/StatementFinanceTypes';
import './balanceitem.scss';

interface Props extends IntlProps {
  data: BalanceDetails;
  columns: Columns;
}

class BalanceItem extends PureComponent<Props> {
  render() {
    const { getTranslate } = this.props;
    const { isEmptyBalance, companyTitle, contractTitle, merchantTitle } =
      this.props.data;

    return (
      <div className='statement-balance-item'>
        <AccordionItem
          isOpened={!isEmptyBalance}
          isDisabled={isEmptyBalance}
          title={this.getBalanceTitle()}>
          <div className='statement-balance-item__info'>
            <span className='statement-balance-item__info-item'>
              <span className='statement-balance-item__info-key'>
                {getTranslate('statement.balanceInfo.merchant')}
              </span>{' '}
              {merchantTitle}
            </span>
            <span className='statement-balance-item__info-item'>
              <span className='statement-balance-item__info-key'>
                {getTranslate('statement.balanceInfo.contract')}
              </span>{' '}
              {contractTitle}
            </span>
            <span className='statement-balance-item__info-item'>
              <span className='statement-balance-item__info-key'>
                {getTranslate('statement.balanceInfo.contractWith')}
              </span>{' '}
              {companyTitle}
            </span>
          </div>
          {this.renderTable('nonOperational')}
          {this.renderTable('operational')}
          {this.renderTable('transactionSplitting')}
        </AccordionItem>
      </div>
    );
  }

  getBalanceTitle() {
    const { title, closingBalance, currency } = this.props.data;
    return (
      <div className='statement-balance-item__header'>
        <div>{title}</div>
        <div>
          {closingBalance} {currency}
        </div>
      </div>
    );
  }

  renderTable(tableName) {
    const { columns, getTranslate } = this.props;
    const { data } = this.props;
    const table = data[tableName];

    if (!table) return null;

    if (tableName === 'transactionSplitting' && +table.total === 0) {
      return null;
    }

    return (
      <div className='statement-balance-item__table'>
        <AccordionItem
          key={tableName}
          isOpened={true}
          customClass='statement-balance-item__table-header'
          title={this.getTableTitle(tableName)}>
          <TreeTable
            columns={Object.keys(columns[tableName])}
            localize={columns[tableName]}
            rows={table.rows}
            rowTotal={{
              title: getTranslate('statement.Operational.totals'),
              ...table.rowsTotal,
            }}
            showAllRows={true}
          />
        </AccordionItem>
      </div>
    );
  }

  getTableTitle(tableName) {
    const { data, getTranslate } = this.props;
    const table = data[tableName];

    return (
      <div className='statement-balance-item__table-header-inner'>
        <div className='statement-balance-item__table-header-title'>
          {getTranslate(`statement.balanceInfo.${tableName}PositionsTotal`)}
        </div>
        <div className='statement-balance-item__table-header-value'>
          {table.total} {data.currency}
        </div>
      </div>
    );
  }
}

export default addTranslation(BalanceItem);
