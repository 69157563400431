import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';

import { getReferenceList } from 'api/references';
import getConfigurationByName from 'selectors/getConfigurationByName';
import TopPanelContainer from 'components/topPanel';
import DataListContainer from 'components/dataListContainer';
import { Column, Row } from 'components/ui/table';
import Icon from 'components/ui/icon';
import { AnyObject } from 'types/Common';
import tableNames from 'constants/tableNames';
import quickFiltersConfig from '../quickFiltersConfig';
import { StoreProps } from 'store';
import './referenceList.scss';

interface ConnectedProps {
  configuration: AnyObject;
}

type Props = ConnectedProps & StoreProps & IntlProps & WithRouterProps;

class ReferencesListContainer extends PureComponent<Props> {
  render() {
    return (
      <Fragment>
        <TopPanelContainer
          name={tableNames.references}
          filtersConfig={quickFiltersConfig}
          isConfigurationButton={false}
          isFilterButton={false}
        />
        <DataListContainer
          customClass='table-references'
          tableName={tableNames.references}
          apiRequest={getReferenceList}
          rowRender={this.renderRow}
        />
      </Fragment>
    );
  }

  renderRow = (data) => {
    const { configuration } = this.props;

    return (
      <Row>
        {configuration.map((config) => {
          const { id } = config;
          let content = data[id];
          const columnParams = {
            ...config,
            valueId: config.id,
            valueType: config.valueType,
          };

          if (id === 'download' && data.s3Url) {
            content = (
              <a download href={data.s3Url}>
                <Icon
                  name='im-Download1'
                  size={16}
                  className='table-references__download-icon'
                />
              </a>
            );
          }

          return (
            <Column
              columnWidths={data.columnWidths}
              key={id}
              id={id}
              content={content || '—'}
              customClass={`ui-table__column_${id}`}
              params={columnParams}
            />
          );
        })}
      </Row>
    );
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  configuration: getConfigurationByName(state, tableNames.references),
});

export default withRouter(
  connect(mapStateToProps)(addTranslation(ReferencesListContainer))
);
