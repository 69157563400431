import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEqual, mapValues, isEmpty } from 'lodash';

import { applySearchFilters } from 'actions/searchFilters';
import { resetFilters } from 'actions/filters';
import { resetQuickFilters } from 'actions/quickFilters';
import { resetData } from 'actions/getData';
import { WithRouterProps } from 'decorators/withRouter';
import SearchFiltersType from 'types/SearchFilters';
import { AnyObject } from 'types/Common';
import SearchFilters from './SearchFilters';
import FiltersStateFactory from 'factories/FiltersStateFactory';
import MetricService from 'helpers/metricService/MetricService';
import path from 'helpers/path';
import { StoreProps } from 'store';
import breakpoints from 'constants/breakpoints';

interface OwnProps {
  onClose: () => void;
  isOpened: boolean;
}

interface ConnectedProps {
  searchFilters: {
    list: SearchFiltersType;
    selectedCount: number;
  };
}

type Props = OwnProps & ConnectedProps & StoreProps & WithRouterProps;

interface State {
  filtersValues: SearchFiltersType;
  validationErrors: AnyObject;
  isMobile: boolean;
}

class SearchFiltersContainer extends Component<Props, State> {
  private initialFiltersValues;
  private _isMounted;

  constructor(props: Props) {
    super(props);
    this.initialFiltersValues = { ...props.searchFilters.list };

    this.state = {
      filtersValues: JSON.parse(JSON.stringify(this.initialFiltersValues)),
      validationErrors: {},
      isMobile: window.innerWidth <= breakpoints.searchFilters,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount(): void {
    this._isMounted = false;
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { searchFilters } = this.props;
    if (!isEqual(prevProps.searchFilters.list, searchFilters.list)) {
      this.setState({
        filtersValues: { ...searchFilters.list },
      });

      // Reset all filters. Set new initial
      if (Object.values(searchFilters.list).filter((val) => val).length === 0) {
        this.initialFiltersValues = JSON.parse(
          JSON.stringify(searchFilters.list)
        );
      }
    }
  }

  render() {
    const { isMobile, filtersValues, validationErrors } = this.state;
    return (
      <SearchFilters
        filters={filtersValues}
        validationErrors={validationErrors}
        canApply={this.hasChangesInFilters()}
        onChange={this.changeFilter}
        onApply={this.applyFilters}
        onReset={this.resetAllFilters}
        onClose={this.props.onClose}
        isOpened={this.props.isOpened}
        isMobile={isMobile}
      />
    );
  }

  changeFilter = (filterId: string, filterValue) => {
    const { filtersValues } = this.state;
    this.setState({
      filtersValues: {
        ...filtersValues,
        [filterId]: filterValue,
      },
    });
  };

  applyFilters = () => {
    const { history, dispatch } = this.props;
    const { filtersValues } = this.state;
    if (this.hasChangesInFilters() && this.isFieldsCorrect()) {
      if (!this.isPaymentsPage()) {
        dispatch(resetData('payments'));
        history.push(path('/payments'));
      }
      dispatch(resetFilters('payments'));
      dispatch(resetQuickFilters());
      dispatch(
        applySearchFilters(mapValues(filtersValues, (val) => val.trim()))
      );
      this.initialFiltersValues = JSON.parse(JSON.stringify(filtersValues));
      MetricService.send({
        action: 'click',
        actionKey: 'search.button.save',
      });
    }
  };

  isFieldsCorrect = () => {
    const { filtersValues } = this.state;
    const emailRegExp =
      // eslint-disable-next-line unicorn/no-unsafe-regex
      /^[a-zA-Z0-9!#$%&' * +\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&' * +\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
    const validationErrors = Object.keys(filtersValues).reduce(
      (result, field) => {
        if (!filtersValues[field].length) return result;
        let condition;
        switch (field) {
          case 'arn':
            condition =
              filtersValues[field].length === 23 &&
              !isNaN(+filtersValues[field]) &&
              typeof +filtersValues[field] === 'number';
            break;
          case 'rrn':
            condition =
              filtersValues[field].length === 12 &&
              !isNaN(+filtersValues[field]) &&
              typeof +filtersValues[field] === 'number';
            break;
          case 'customerEmail':
            condition =
              filtersValues[field].length <= 255 &&
              filtersValues[field].match(emailRegExp);
            break;
          case 'operationId':
          case 'projectId':
            condition =
              !isNaN(+filtersValues[field]) &&
              typeof +filtersValues[field] === 'number';
            break;
          case 'paymentId':
            condition =
              filtersValues[field].length <= 255 &&
              !filtersValues[field].match(/[\s]/);
            break;
          case 'paymentAccount':
            condition =
              filtersValues[field].length <= 100 &&
              !filtersValues[field].match(/[\s]/);
            break;
          case 'customerId':
            condition = filtersValues[field].length <= 255;
            break;
          default:
            return '';
        }
        return condition ? result : { ...result, [field]: ' ' };
      },
      {}
    );

    this.setState({ validationErrors });
    return isEmpty(validationErrors);
  };

  hasChangesInFilters = (): boolean => {
    const { filtersValues } = this.state;
    if (!this.initialFiltersValues || !filtersValues) {
      return false;
    }

    return !isEqual(
      this.getValuesForCompare(this.initialFiltersValues),
      this.getValuesForCompare(filtersValues)
    );
  };

  getValuesForCompare = (filters) => {
    return Object.values(filters).map((value: any) => value.trim());
  };

  isPaymentsPage = (): boolean => {
    const { history } = this.props;
    const { pathname } = history.location;
    return pathname === path('/payments') || pathname === path('/');
  };

  resetAllFilters = () => {
    this.setState({
      filtersValues: FiltersStateFactory.getInitialSearchFilters(),
    });

    MetricService.send({
      action: 'click',
      actionKey: 'search.button.reset',
    });
  };

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= breakpoints.searchFilters });
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  searchFilters: state.searchFilters,
});

export default withRouter(connect(mapStateToProps)(SearchFiltersContainer));
