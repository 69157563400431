import React, { Component } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import DynamicTable from 'components/ui/table/DynamicTable';
import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import RoundButton from 'components/ui/roundButton';
import financialReportsEmpty from '../images/financialReportsEmpty.svg';
import tableNames from 'constants/tableNames';
import InputDateRange from 'components/ui/inputDateRange';
import { Column, Row, Header } from 'components/ui/table';
import Checkbox from 'components/ui/checkbox';
import Icon from 'components/ui/icon';
import FinancialTabEmptyState from '../financialTabEmptyState';
import ItemConfiguration from 'types/ItemConfiguration';
import { DataItem } from './FinancialReportsContainer';
import './financialReports.scss';

interface Props extends IntlProps {
  date: { dateFrom: string; dateTo: string };
  exactMatch: boolean;
  items: DataItem[];
  isAllSelected: boolean;
  isEmailSending: boolean;
  loadingFiles: string[];
  state: 'loading' | 'empty' | 'data' | 'notFound';
  onChange: (
    key: string,
    value: { dateFrom: string; dateTo: string } | boolean
  ) => void;
  onDownload: (fileKey?: string[]) => void;
  onSendToEmail: () => void;
  configuration: ItemConfiguration[];
  onSelectAllData: (isSelected: boolean) => void;
  onSelectDataItem: (fileKey: string) => void;
}

class FinancialReports extends Component<Props> {
  render() {
    const { state, loadingFiles, getTranslate } = this.props;
    const isDisabledButtons = this.props.items.every(
      (item) => !item.isSelected
    );
    return (
      <div className='financial-tab financial-reports card card_l'>
        <div className='financial-tab__header card__header card__header_bordered'>
          <div className='financial-tab__title'>
            {getTranslate('finance.financialReports.header')}
          </div>
          <div className='financial-tab__text card__subtext'>
            {getTranslate('finance.financialReports.infoText')}
          </div>
          <div className='financial-reports__calendar'>
            <InputDateRange
              id='financial-reports-date'
              value={this.props.date}
              label={getTranslate(
                'finance.financialReports.createReport.reportPeriod.label'
              )}
              placeholder={getTranslate(
                'finance.financialReports.createReport.reportPeriod.placeholder'
              )}
              panelTitle='finance.financialReports.filter.date'
              withTime={false}
              onChange={(date) => {
                this.props.onChange('date', date);
              }}
              customClass='financial-reports__field-date'
              monthsAmount={3}
              applyFormat='date'
              disableButton
              modern
            />
          </div>
          <Checkbox
            checked={this.props.exactMatch}
            id='exactMatch'
            text={getTranslate(
              'finance.financialReports.createReport.exactMatch'
            )}
            onChange={() => {
              this.props.onChange('exactMatch', !this.props.exactMatch);
            }}
          />
        </div>
        <div
          className={classNames('financial-tab__content', {
            'financial-tab__content_empty':
              state === 'empty' || state === 'notFound',
          })}>
          <div className='financial-tab__content-inner card__container'>
            {this.renderContent()}
          </div>
        </div>
        <div className='financial-reports__footer'>
          <Button
            text={getTranslate('finance.financialReports.sendToEmail.button')}
            status='outline'
            onClick={() => this.props.onSendToEmail()}
            disabled={isDisabledButtons}
            loading={this.props.isEmailSending}
          />
          <Button
            text={getTranslate('finance.financialReports.download.button')}
            status='primary'
            onClick={() => this.props.onDownload()}
            disabled={isDisabledButtons}
            loading={!!loadingFiles.length}
          />
        </div>
      </div>
    );
  }

  renderContent = () => {
    const { state } = this.props;

    switch (state) {
      case 'loading':
        return <Loader />;
      case 'notFound':
        return (
          <FinancialTabEmptyState text='finance.financialReports.notFoundReports.text' />
        );
      case 'empty':
        return (
          <FinancialTabEmptyState
            title='finance.financialReports.noReports.header'
            text='finance.financialReports.noReports.text'
            picturePath={financialReportsEmpty}
          />
        );
      case 'data':
        return (
          <DynamicTable
            items={this.props.items}
            clickToItem={() => null}
            totalItems={100}
            tableName={tableNames.financialReport}
            rowRender={this.rowRenderer}
            headerRender={this.headerRenderer()}
          />
        );
    }
  };

  headerRenderer = () => {
    return (
      <Header>
        {this.props.configuration.map((config, i) => {
          if (i === 0) {
            return (
              <Column
                key={config.id}
                id={config.id}
                modifier={config.id}
                content={
                  <>
                    <Checkbox
                      id='select-all-balance'
                      checked={this.props.items.every(
                        (item) => item.isSelected
                      )}
                      onChange={(e) =>
                        this.props.onSelectAllData(e.target.checked)
                      }
                      customClass='financial-reports__checkbox'
                    />
                    {this.props.getTranslate(config.localId)}
                  </>
                }
              />
            );
          } else if (config.id === 'download') {
            return (
              <Column
                key={config.id}
                id={config.id}
                modifier={config.id}
                content={
                  <Icon
                    name='im-Tick'
                    size={10}
                    className='financial-reports__table-header-icon'
                  />
                }
              />
            );
          }
          return (
            <Column
              key={config.id}
              id={config.id}
              modifier={config.id}
              content={this.props.getTranslate(config.localId)}
            />
          );
        })}
      </Header>
    );
  };

  rowRenderer = (data) => {
    return (
      <Row key={data.balanceId}>
        {this.props.configuration.map((config, i) => {
          let content = data[config.id];
          if (i === 0) {
            content = (
              <>
                <Checkbox
                  id={`${data.balanceId}-${data.fileFormat}`}
                  checked={data.isSelected}
                  onChange={() => this.props.onSelectDataItem(data.fileKey)}
                  customClass='financial-reports__checkbox'
                />
                {content}
              </>
            );
          } else if (config.id === 'download') {
            content = (
              <RoundButton
                icon='im-Download1'
                iconSize={16}
                onClick={() => this.props.onDownload(data.fileKey)}
                loading={this.props.loadingFiles.includes(data.fileKey)}
                status='success'
              />
            );
          }
          return (
            <Column
              key={config.id}
              columnWidths={data.columnWidths}
              id={config.id}
              content={content || '—'}
              customClass={`ui-table__column_${config.id}`}
              params={{ valueId: config.id, valueType: config.valueType }}
            />
          );
        })}
      </Row>
    );
  };
}

export default addTranslation(FinancialReports);
