const statuses = [
  {
    id: 'NEEDS RESPONSE',
    text: 'Needs Response',
  },
  {
    id: 'IN PROGRESS',
    text: 'In progress',
  },
  {
    id: 'COMPLETED',
    text: 'Completed',
    sublist: [
      {
        id: 'ACCEPTED BY MERCHANT',
        text: ' Accepted by merchant',
      },
      {
        id: 'ACCEPTED BY MERCHANT (EXPIRED)',
        text: 'Accepted by merchant (expired)',
      },
      {
        id: 'CANCELLED BY ISSUER',
        text: 'Cancelled by issuer',
      },
      {
        id: 'WON',
        text: 'Won',
      },
      {
        id: 'PARTIALLY WON',
        text: 'Partially won',
      },
      {
        id: 'LOST',
        text: 'Lost',
      },
    ],
  },
];

const statusMapper = {
  needsResponse: 'NEEDS RESPONSE',
  inProgress: 'IN PROGRESS',
  acceptedByMerchant: 'ACCEPTED BY MERCHANT',
  returned: 'RETURNED',
  won: 'WON',
  partiallyWon: 'PARTIALLY WON',
  lost: 'LOST',
};

export { statuses, statusMapper };
