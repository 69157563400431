import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deleteRisk, getFraud, getRisks } from 'api/risks';
import { changeQuickFilter, initQuickFilters } from 'actions/quickFilters';
import getConfigurationByName from 'selectors/getConfigurationByName';
import { addListeners } from 'decorators/addListeners';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import RisksPage from './RisksPage';
import HelpDeskHelpers from 'components/helpDesk/HelpDeskHelpers';
import getSelectionListItems from 'creators/getSelectionListItems';
import path from 'helpers/path';
import { getPathUrl } from 'helpers/paymentsPayoutsRequest';
import Messages from 'constants/rpcTypes';
import tableNames from 'constants/tableNames';
import filtersKeys from 'constants/filters';
import { AnyObject } from 'types/Common';
import { Dictionary } from 'types/FilterValue';
import getQuickFiltersValues from 'selectors/getQuickFiltersValues';
import LocalStorage from 'helpers/LocalStorage';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import { checkSelectedRcsProjects } from 'pages/risks/helpers';
import { RootState, StoreProps } from 'store';
import { QuickFiltersTableName } from 'types/QuickFilters';
import { tableRoutes } from './routesMapper';
import { PROJECTS_LIMIT } from './constants';

interface OwnProps {
  configuration: AnyObject;
}

interface ConnectedProps {
  state: RootState;
  selectedRcsQuickFilters: AnyObject;
}

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  IntlProps &
  WithRouterProps<{ id?: string }>;

interface State {
  tableName: QuickFiltersTableName;
  isLoading: boolean;
  merchantProjects: Dictionary | null;
}

@addListeners([Messages.Rcs_DeleteElement, Messages.Confirm_Reject])
class RisksContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const tableName = window['__riskTableName'] || tableNames.rcs;
    this.state = {
      tableName:
        props.match.params.id === tableRoutes[tableNames.fraud]
          ? tableNames.fraud
          : tableName,
      isLoading: false,
      merchantProjects: null,
    };

    if (
      this.state.tableName === tableNames.fraud &&
      props.match.params.id !== tableRoutes[tableNames.fraud]
    ) {
      props.history.replace(path(`risks/${tableRoutes[tableNames.fraud]}`));
    }

    HelpDeskHelpers.setCurrentTab.call(this, {
      riskTable: tableName,
    });
  }

  componentDidMount() {
    if (
      this.state.tableName === tableNames.rcs &&
      this.props.state.quickFilters[tableNames.rcs].merchantId
    ) {
      this.getMerchantProjects(
        this.props.state.quickFilters[tableNames.rcs].merchantId
      );
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const {
      state: { quickFilters, filtersValues },
    } = this.props;
    const { tableName } = this.state;

    if (prevProps.match.params.id !== this.props.match.params.id) {
      if (!this.props.match.params.id) {
        this.setState({ tableName: tableNames.rcs });
      } else if (this.props.match.params.id === tableRoutes[tableNames.fraud]) {
        this.setState({ tableName: tableNames.fraud });
      }
    }

    if (prevState.tableName !== this.state.tableName) {
      window['__riskTableName'] = tableName;
      HelpDeskHelpers.setCurrentTab.call(this, {
        riskTable: tableName,
      });
    }

    if (
      !prevProps.state.filtersValues?.userMerchant?.isFetched &&
      filtersValues?.userMerchant?.isFetched
    ) {
      this.setInitFilters(tableName, true);
    }

    if (
      tableName === tableNames.rcs &&
      (prevState.tableName !== tableName ||
        prevProps.state.quickFilters[tableName]?.isReady !==
          this.props.state.quickFilters[tableName]?.isReady)
    ) {
      if (this.props.state.quickFilters[tableNames.rcs]?.isReady) {
        this.getMerchantProjects(
          this.props.state.quickFilters[tableNames.rcs].merchantId
        );
      } else {
        this.setInitFilters(tableName, true);
      }
    }

    const prevBlackWhiteFilters =
      prevProps.state.quickFilters?.[tableNames.rcs];
    if (
      tableName === tableNames.rcs &&
      prevBlackWhiteFilters &&
      prevBlackWhiteFilters.isReady &&
      prevBlackWhiteFilters[filtersKeys.riskMerchantId] !==
        quickFilters[tableNames.rcs][filtersKeys.riskMerchantId]
    ) {
      this.getMerchantProjects(
        quickFilters[tableNames.rcs][filtersKeys.riskMerchantId]
      );
    }
  }

  render() {
    const { tableName, merchantProjects, isLoading } = this.state;
    const { state, match, location, history } = this.props;
    const isBlackWhite = tableName === tableNames.rcs;
    return (
      <RisksPage
        id={
          match.params.id !== tableRoutes[tableNames.fraud]
            ? match.params.id
            : ''
        }
        tableName={tableName}
        isLoading={isLoading}
        canRenderTable={state.quickFilters[tableName].isReady}
        apiRequest={isBlackWhite ? getRisks : getFraud}
        configuration={getConfigurationByName(state, tableName)}
        onDeleteItem={(e, id) => this.onDeleteItem(e, id)}
        onChangeCurrentTable={this.changeRouteForTable}
        onReset={isBlackWhite ? this.onReset : undefined}
        onChange={this.onChangeQuickFilters}
        onBeforeChange={{
          [filtersKeys.riskProjectIds]: this.changeRcsProjects,
        }}
        onRowClick={this.onRowClick}
        filtersValuesCustom={
          isBlackWhite ? { enabledProjectExternal: merchantProjects } : {}
        }
        hasSelectedFiltersCustom={
          isBlackWhite ? this.checkSelectedFilters() : undefined
        }
        location={location}
        history={history}
        match={match}
      />
    );
  }

  changeRouteForTable = (tableName) => {
    const { history, match } = this.props;
    //table name is setting to state in componentDidUpdate
    if (tableName === tableNames.fraud) {
      history.push(path(`/risks/${tableRoutes[tableNames.fraud]}`));
    } else if (match.params.id) {
      history.push(path('/risks'));
    }
  };

  setInitFilters = (tableName, isInitial = false) => {
    const {
      state: { filtersValues },
      dispatch,
    } = this.props;
    if (
      tableName !== tableNames.rcs ||
      !filtersValues?.userMerchant?.isFetched ||
      !filtersValues?.enabledProjectExternal?.isFetched
    )
      return;

    const merchantId = filtersValues.userMerchant?.list?.[0].id;
    const projects = this.getMerchantProjects(merchantId, isInitial);

    dispatch(
      initQuickFilters({
        name: tableNames.rcs,
        content: {
          isReady: true,
          [filtersKeys.riskMerchantId]: merchantId,
          [filtersKeys.riskProjectIds]: projects,
          [filtersKeys.riskListType]: [],
          [filtersKeys.riskValue]: [],
          [filtersKeys.risksCreatedAt]: { values: [], type: '' },
          [filtersKeys.riskContentType]: isInitial
            ? 'pan'
            : LocalStorage.get('riskCategory'),
        },
      })
    );
  };

  getMerchantProjects = (merchantId, isInitial = false) => {
    const {
      state: { filtersValues },
      dispatch,
    } = this.props;
    const { tableName } = this.state;

    if (!merchantId || tableName !== tableNames.rcs) return;

    const merchant = filtersValues.userMerchant?.list?.find(
      (item) => item.id === merchantId
    );

    const projects = (merchant.projectId || []).map((id) => {
      return filtersValues.enabledProjectExternal?.list?.find(
        (project) => +project.id === +id
      );
    });

    this.setState({
      merchantProjects: {
        isFetched: true,
        isLoading: false,
        hasMoreRows: false,
        list: getSelectionListItems({
          originalList: projects,
          selectedList: [],
        }),
      },
    });

    const projectIds =
      projects
        .filter((item, i) => i < PROJECTS_LIMIT && item.id)
        .map((item) => item.id) || [];

    if (isInitial) {
      return projectIds;
    }
    dispatch(
      changeQuickFilter(tableNames.rcs, filtersKeys.riskProjectIds, projectIds)
    );
  };

  changeRcsProjects = (key, value) => {
    if (this.state.tableName === tableNames.fraud) return false;
    return checkSelectedRcsProjects(value);
  };

  async onDeleteItem(e, data) {
    e.stopPropagation();
    if (isNotAvailableForSupport(Messages.Rcs_DeleteElement)) return;

    this.setState({ isLoading: true });
    try {
      await deleteRisk(data);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onRowClick = (data) => {
    const { history } = this.props;
    const { tableName } = this.state;
    if (tableName === tableNames.rcs || !data.transactionId) return;
    history.push(path(getPathUrl(tableName, data)));
  };

  checkSelectedFilters = () => {
    const { selectedRcsQuickFilters, state } = this.props;
    const { tableName, merchantProjects } = this.state;

    const selected = Object.keys(selectedRcsQuickFilters).filter((key) => {
      if (
        tableName === tableNames.rcs &&
        Object.keys(selectedRcsQuickFilters).length === 3
      ) {
        const merchantList = state.filtersValues.userMerchant?.list || [];

        if (key === filtersKeys.riskProjectIds) {
          if (
            merchantProjects &&
            merchantProjects.list.length > PROJECTS_LIMIT
          ) {
            return selectedRcsQuickFilters[key].length !== PROJECTS_LIMIT;
          }
          return (
            selectedRcsQuickFilters[key].length !==
            merchantProjects?.list.length
          );
        } else if (key === filtersKeys.riskMerchantId) {
          return selectedRcsQuickFilters[key] !== merchantList[0]?.id;
        } else if (key === filtersKeys.riskContentType) {
          return selectedRcsQuickFilters[key] !== 'pan';
        }
      }
      return true;
    });
    return Boolean(selected.length);
  };

  onChangeQuickFilters = (filterName, value) => {
    if (filterName === filtersKeys.riskContentType) {
      this.setValueToLocalStorage(value);
    }
  };

  setValueToLocalStorage = (value = 'pan') => {
    LocalStorage.set('riskCategory', value);
  };

  onReset = () => {
    this.setInitFilters(this.state.tableName, true);
    this.setValueToLocalStorage();
  };

  onEvent = () => {
    this.setState({ isLoading: false });
  };
}

const mapStateToProps = (state: RootState): ConnectedProps => ({
  state,
  selectedRcsQuickFilters: getQuickFiltersValues(state, tableNames.rcs),
});

export default addTranslation(connect(mapStateToProps)(RisksContainer));
