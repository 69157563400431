import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';

export const openModal = ({
  // eslint-disable-next-line no-empty-function
  onClose = () => {},
  modalId,
  customClassName = '',
  // eslint-disable-next-line no-empty-function
  callback = (data: any) => {},
  content = {},
  theme = '',
  tooltipId = '',
  onClickAwayClose = true,
  needCloseButton = true,
  invertButtons = false, // show "no" as primary button
}) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.OPEN_MODAL,
        payload: {
          onClose,
          modalId,
          callback,
          content,
          theme,
          customClassName,
          onClickAwayClose,
          needCloseButton,
          tooltipId,
          invertButtons,
        },
      })
    );
  };
};

export const editContent = (content) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.EDIT_CONTENT,
        payload: { content },
      })
    );
  };
};

export const editOptions = (options: {
  isOpened?: boolean;
  onClickAwayClose?: boolean;
  customClassName?: string;
  needCloseButton?: boolean;
  callback?: (answer: boolean) => void;
  confirm?: {
    title: string;
    text: string;
  };
}) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.EDIT_OPTIONS,
        payload: options,
      })
    );
  };
};

export const resetModal = () => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_MODAL,
      })
    );
  };
};

export const closeModal = () => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.CLOSE_MODAL,
      })
    );
    setTimeout(resetModal, 1000);
  };
};
