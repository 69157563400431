import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import userApi from 'api/user';
import { closeModal } from 'actions/modal';
import LocalStorage from 'helpers/LocalStorage';
import { httpStatuses } from 'constants/httpCodes';
import config from 'config';

export interface PingState {
  authToken: string;
}

export const withAuthPing = (WrapperComponent) => {
  class PingDecorator extends React.Component<any, PingState> {
    pingIntervalId;

    state = {
      authToken: this.props.content.authToken,
    };

    componentDidMount(): void {
      this.startPing();
    }

    render() {
      const props = { ...this.props, authToken: this.state.authToken };
      return <WrapperComponent {...props} />;
    }

    componentWillUnmount() {
      this.stopPing();
    }

    stopPing = () => {
      clearInterval(this.pingIntervalId);
    };

    startPing = () => {
      const { app, dispatch, user: userStore } = this.props;
      const { validationErrors, ...user } = userStore || {};

      if ((!user || isEmpty(user)) && isEmpty(validationErrors)) {
        this.pingIntervalId = setInterval(() => {
          const { authToken } = this.state;
          if (LocalStorage.get('instanceId') === app.instanceId) {
            userApi
              .ping({
                authToken,
              })
              .then((response) => {
                this.setState({ authToken: response.authToken });
              })
              .catch((error) => {
                if (error?.error?.status === httpStatuses.unauthorized) {
                  this.stopPing();
                  dispatch(closeModal());
                }
              });
          }
        }, config.PING_INTERVAL);
      }
    };
  }

  const mapStateToProps = (state) => {
    return {
      content: state.modal.content,
      app: state.app,
      user: state.user,
    };
  };
  return connect(mapStateToProps)(PingDecorator) as typeof WrapperComponent;
};
