import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Tooltip from 'react-tooltip';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import Button from 'components/ui/button';
import { TabsContainer as Tabs, Tab } from 'components/ui/tabs';
import Form from 'components/ui/form';
import Loader from 'components/ui/loader';
import Input from 'components/ui/input';
import CustomSelect from 'components/ui/customSelect';
import InputAmount from 'components/ui/inputAmount';
import Icon from 'components/ui/icon';
import ButtonsGroup from 'components/ui/buttonsGroup';
import Link from 'components/ui/link';
import { AnyObject } from 'types/Common';
import { DictionaryCustomSelect } from 'types/FilterValue';
import Messages from 'constants/rpcTypes';
import permissionReasons from 'constants/permissionReasons';
import urlsMap from 'constants/urlsMap';
import fieldConfig from './fieldConfig';

interface Props extends IntlProps, WithPermissions {
  fields: any;
  id?: string;
  dictionaries: {
    projectId: DictionaryCustomSelect;
    transferCurrency: DictionaryCustomSelect;
    companyLegalCountry: DictionaryCustomSelect;
    beneficiaryBankCountry: DictionaryCustomSelect;
    allCountries: DictionaryCustomSelect;
  };
  validationErrors: AnyObject;
  onChangeField: (name: string, value: any) => void;
  setCurrentStep: (stepIndex: number) => void;
  currentStep: number;
  canSave: boolean;
  canApprove: boolean;
  isSubmitting: boolean;
  save: () => void;
  approve: () => void;
}

interface State {
  areFieldsDisabled: boolean;
}

const steps = [
  {
    title: 'remittance.addRecipientAcc.stepper.generalInfo',
    icon: 'im-Many-Files',
  },
  { title: 'remittance.addRecipientAcc.stepper.recipient', icon: 'im-Avatar' },
  {
    title: 'remittance.addRecipientAcc.stepper.companyAddress',
    icon: 'im-Location',
  },
  {
    title: 'remittance.addRecipientAcc.stepper.legalInfo',
    icon: 'im-Settings',
  },
];

class RemittanceAccountBuilder extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      areFieldsDisabled: false,
    };
  }

  componentDidMount() {
    this.setStatus();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { fields } = this.props;
    if (prevProps.fields.status !== fields.status) {
      this.setStatus();
    }
    Tooltip.rebuild();
  }

  render() {
    return (
      <PageTemplate.Container>
        <div className='remittance-builder'>
          <div className='remittance-builder__header'>
            <Icon name='im-Remmitance' size={20} />
            {this.title}
          </div>
          {this.content}
        </div>
      </PageTemplate.Container>
    );
  }

  setStatus = () => {
    this.setState({
      areFieldsDisabled:
        this.props.fields.status && this.props.fields.status !== 'draft',
    });
  };

  get content() {
    const { currentStep, getTranslate, setCurrentStep, isSubmitting } =
      this.props;
    const { areFieldsDisabled } = this.state;
    return (
      <div className='remittance-builder__content'>
        {isSubmitting && <Loader />}
        <Tabs
          mode='vertical'
          customClass='remittance-builder__tabs'
          defaultActiveTabIndex={currentStep}
          needUpdateDefaultTab
          onClick={setCurrentStep}>
          {steps.map((step, index) => {
            return (
              <Tab
                key={`remittance-step-${step.title}`}
                className={classNames('remittance-builder__tab', {
                  'remittance-builder__tab_selected': index === currentStep,
                })}
                title={
                  <div className='remittance-builder__tab-title'>
                    <div>
                      <Icon name={step.icon} size={22} />
                      {getTranslate(step.title)}
                    </div>
                    {!areFieldsDisabled && (
                      <div className='remittance-builder__step-status'>
                        {this.getStepStatusIcon(index)}
                      </div>
                    )}
                  </div>
                }>
                {this.renderStep(index)}
                {this.controlButtons}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    );
  }

  get controlButtons() {
    const {
      setCurrentStep,
      currentStep,
      getTranslate,
      approve,
      canApprove,
      save,
      canSave,
      isEnabled,
      isDisabledByReason,
    } = this.props;
    const { areFieldsDisabled } = this.state;

    const availableSave =
      isEnabled(Messages.Remittance_CreateRecipientAccount) ||
      isDisabledByReason(
        Messages.Remittance_CreateRecipientAccount,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );
    const availableApprove =
      isEnabled(Messages.Remittance_ApproveRecipientAccount) ||
      isDisabledByReason(
        Messages.Remittance_CreateRecipientAccount,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );

    return (
      <div className='remittance-builder__footer'>
        <Link
          url={`/${urlsMap.remittance}`}
          className='ui-button ui-submitted-form__button ui-button_status-outline ui-button_size-normal'>
          {getTranslate('remittance.addRecipientAcc.back.button')}
        </Link>

        <div className='remittance-builder__buttons'>
          {availableSave && !areFieldsDisabled && (
            <Button
              key='save as draft'
              disabled={!canSave}
              status='outline'
              text={getTranslate('remittance.addRecipientAcc.saveDraft.button')}
              onClick={save}
            />
          )}

          {currentStep < 3 && (
            <Button
              key='continue'
              status='primary'
              text={getTranslate('remittance.addRecipientAcc.continue.button')}
              onClick={() => setCurrentStep(currentStep + 1)}
            />
          )}

          {currentStep === 3 && availableApprove && !areFieldsDisabled && (
            <div
              data-tip={
                canApprove
                  ? ''
                  : getTranslate(
                      'remittance.addRecipientAcc.requiredFields.error'
                    )
              }>
              <Button
                disabled={!canApprove}
                text={getTranslate(
                  'remittance.addRecipientAcc.sendApprove.button'
                )}
                status='primary'
                onClick={approve}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  emptyValues = [null, undefined, '', '0.00', 0];

  isFilled = (field) => {
    const { fields } = this.props;
    return this.emptyValues.every((value) => value !== fields[field.id]);
  };

  getStepStatusIcon = (stepNumber) => {
    const { validationErrors } = this.props;
    const stepFields = fieldConfig[stepNumber];
    const requiredFields = stepFields.filter((field) => field.isRequired);
    if (stepFields.some((field) => validationErrors[field.id])) {
      return (
        <Icon
          size={12}
          name='im-Error'
          className=' remittance-builder__step-status_error'
        />
      );
    }
    if (requiredFields.every(this.isFilled)) {
      return (
        <Icon
          size={10}
          name='im-Tick'
          className=' remittance-builder__step-status_success'
        />
      );
    }
    if (requiredFields.some(this.isFilled)) {
      return (
        <Icon
          size={10}
          name='im-Edit1'
          className=' remittance-builder__step-status_valid'
        />
      );
    }
    return (
      <Icon
        size={6}
        name='im-Collapse'
        className='remittance-builder__step-status_not-filled'
      />
    );
  };

  getFieldLabel = (field) => {
    const { fields, getTranslate } = this.props;

    if (field.id === 'title') {
      return getTranslate(
        !!fields.status && fields.status !== 'draft'
          ? 'remittance.addRecipientAcc.field.view.title.label'
          : field.label
      );
    }
    return `${getTranslate(field.label)}${
      fields.transferCurrency && field.type === 'amount'
        ? ` (${fields.transferCurrency})`
        : ''
    }`;
  };

  renderStep = (step) => {
    const {
      fields,
      onChangeField,
      validationErrors,
      dictionaries,
      getTranslate,
    } = this.props;
    const { areFieldsDisabled } = this.state;

    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        customClass='remittance-builder__form'>
        <div className='remittance-builder__inner remittance-builder__inner-container'>
          {fieldConfig[step].map((field) => {
            const { id } = field;

            switch (field.type) {
              case 'text':
              case 'textarea':
              case 'amount': {
                const isAmount = field.type === 'amount';
                const InputComponent = isAmount ? InputAmount : Input;

                return (
                  <InputComponent
                    key={id}
                    id={id}
                    placeholder={field.placeholder}
                    label={this.getFieldLabel(field)}
                    value={fields[id]}
                    tooltip={field.tooltip ? getTranslate(field.tooltip) : ''}
                    type={!isAmount ? field.type : ''}
                    cleaveOptions={field.options}
                    onChange={(e) => {
                      switch (id) {
                        case 'beneficiaryAccount':
                          onChangeField(
                            id,
                            e.target.value.replace(/[^\w]/g, '')
                          );
                          break;
                        default:
                          onChangeField(id, e.target.value);
                      }
                    }}
                    disabled={areFieldsDisabled}
                    customClass='remittance-builder__item'
                    error={validationErrors?.[id]}
                    modern
                  />
                );
              }
              case 'select':
                return (
                  <CustomSelect
                    key={id}
                    value={this.getSelectValue(id)}
                    options={dictionaries?.[id]?.list || []}
                    placeholder={field.placeholder}
                    label={field.label}
                    isSearchable={true}
                    isDisabled={areFieldsDisabled}
                    onChange={(value) => onChangeField(id, value?.value)}
                    customClass='ui-select_large remittance-builder__item'
                    error={validationErrors?.[id]}
                    modern
                  />
                );
              case 'buttonGroup': {
                return (
                  <ButtonsGroup
                    key={id}
                    label={field.label}
                    disabled={areFieldsDisabled}
                    className='ui-buttons-group_single-choice'
                    activeButtons={[fields[id]]}
                    theme='dark'
                    onClick={(values) => {
                      onChangeField(id, values[0]);
                    }}
                    error={validationErrors[id]}
                    isLoading={dictionaries[id].isLoading}>
                    {dictionaries[id].list?.map(({ label, value }) => {
                      return (
                        <Button
                          disabled={areFieldsDisabled}
                          key={value}
                          status='light'
                          id={value}
                          text={label}
                        />
                      );
                    })}
                  </ButtonsGroup>
                );
              }
              default:
                return null;
            }
          })}
        </div>
      </Form>
    );
  };

  getSelectValue = (id) => {
    const { dictionaries, fields } = this.props;
    if (!fields[id]) return null;
    const value = dictionaries[id]?.list?.find(
      (item) => item.value === fields[id]
    );
    if (value !== undefined) {
      return value;
    }
    if (id === `beneficiaryBankCountry` || id === `companyLegalCountry`) {
      return this.getSelectValueFromAllCountries(id);
    }
    return value;
  };

  getSelectValueFromAllCountries = (id) => {
    const { dictionaries, fields } = this.props;
    return dictionaries[`allCountries`]?.list?.find(
      (item) => item.value === fields[id]
    );
  };

  get title() {
    const { fields, getTranslate } = this.props;
    if (this.props.id) {
      return `${
        fields.title || getTranslate('remittance.editDraftRecipientAcc.header')
      } #${fields.remittanceRecipientAccountId}`;
    }
    return getTranslate('remittance.addRecipientAcc.header');
  }
}

export default addTranslation(addPermissions(RemittanceAccountBuilder));
