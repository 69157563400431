import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { addTranslation, IntlProps } from 'decorators/addTranslation';

import {
  changeQuickFilter,
  resetConcreteQuickFiltersProps,
} from 'actions/quickFilters';
import { StoreProps } from 'store';

import Button from 'components/ui/button';
import RoundButton from 'components/ui/roundButton';
import Form from 'components/ui/form';
import SelectionList from 'components/ui/selectionList';
import CustomSelect from 'components/ui/customSelect';
import ButtonsGroup from 'components/ui/buttonsGroup';
import DateRange from 'components/filters/list/dateRange';
import Loader from 'components/ui/loader';
import CustomScrollbar from 'components/ui/customScrollbar';

import DateHelpers from 'helpers/Date';
import {
  getAvailableValuesByDependencies,
  getDependencyAvailableValuesFromSelected,
} from 'helpers/quickFilters';
import checkFilters from 'helpers/checkFilters';
import { QuickBalanceStatementFiltersType } from 'types/QuickFilters';
import { AnyObject } from 'types/Common';
import { DictionaryMultiSelect } from 'types/FilterValue';
import getSelectionListItems from 'creators/getSelectionListItems';
import tableNames from 'constants/tableNames';
import { periodTypes, groupPeriodBy } from 'constants/dateFormats';
import filtersKeys from 'constants/filters';
import { DEFAULT_BALANCE_TYPES } from '../../../constants';
import './createStatementForm.scss';

interface OwnProps {
  filters: QuickBalanceStatementFiltersType;
  balanceType: DictionaryMultiSelect;
  balance: DictionaryMultiSelect;
  balanceCompany: DictionaryMultiSelect;
  balanceContract: DictionaryMultiSelect;
  balanceCurrency: DictionaryMultiSelect;
  isLoading: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

interface ConnectedProps {
  timezone: string;
}

type Props = OwnProps & ConnectedProps & StoreProps & IntlProps;

class CreateStatementForm extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.onChangeButtonGroup = this.onChangeButtonGroup.bind(this);
    this.onChangePeriodPreset = this.onChangePeriodPreset.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.onChangeDateRange = this.onChangeDateRange.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
  }

  async componentDidMount() {
    await checkFilters('financeStatement');
  }

  render() {
    const { isLoading, getTranslate, onSubmit } = this.props;

    return (
      <Form
        onSubmit={onSubmit}
        customClass='card statement-finance__card statement-create-form'>
        {this.renderCloseButton()}
        <div className='card__header card__header_bordered statement-create-form__header'>
          <div className='card__title-l'>
            {getTranslate('statement.generalInfo.createStatementHeader')}
          </div>
        </div>
        <div className='filters statement-create-form__filters'>
          <div className='filters__content statement-create-form__content'>
            <CustomScrollbar>
              <div className='filters__fields statement-create-form__fields'>
                {this.renderBalanceType()}
                {this.renderBalanceSelect()}
                {this.renderAgreementNumber()}
                {this.renderAgreementWith()}
                {this.renderCurrency()}
                {this.renderDateRange()}
                {this.renderGroupPeriodBy()}
                {this.renderQuickDatePicker()}
              </div>
            </CustomScrollbar>
          </div>
        </div>
        <div className='statement-create-form__footer'>
          {this.renderApplyButton()}
        </div>
        {isLoading && <Loader />}
      </Form>
    );
  }

  getAvailableValuesByBalanceType = (data: AnyObject) => {
    const { key, list } = data;
    const { filters, balanceType } = this.props;
    const balanceTypeList = balanceType?.list || [];

    const currentBalanceType = balanceTypeList.find(
      (item) => item.id === filters[filtersKeys.balanceStatementBalanceType]
    );

    const ids = currentBalanceType ? currentBalanceType[key] : [];

    return list.filter((item) => ids.includes(item.id));
  };

  getAvailableValues({
    key,
    list,
    dependencies,
  }: {
    key: String;
    list: any[];
    dependencies?: any[];
  }) {
    const { filters } = this.props;

    list = this.getAvailableValuesByBalanceType({ key, list });

    return getAvailableValuesByDependencies({
      list,
      filters,
      dependencies,
    });
  }

  renderCloseButton() {
    const { onClose } = this.props;

    return (
      <RoundButton
        size={30}
        icon='im-Close'
        iconSize={10}
        customClass='statement-create-form__close-button'
        onClick={onClose}
      />
    );
  }

  renderBalanceType() {
    const { balanceType, filters, getTranslate } = this.props;
    const balanceTypeList = balanceType?.list || DEFAULT_BALANCE_TYPES;

    return (
      <div className='filters__item filters__item_buttons-group'>
        <div className='filters__buttons-group-title'>
          {getTranslate('statement.filters.balanceType')}
        </div>
        <ButtonsGroup
          activeButtons={[filters[filtersKeys.balanceStatementBalanceType]]}
          mode='singleRequired'
          theme='dark'
          className='ui-buttons-group_single-choice'
          onClick={(values) =>
            this.onChangeButtonGroup({
              key: filtersKeys.balanceStatementBalanceType,
              values,
            })
          }>
          {balanceTypeList?.map((item) => (
            <Button
              key={item.id}
              status='outline'
              id={item.id}
              text={item.text}
            />
          ))}
        </ButtonsGroup>
      </div>
    );
  }

  renderAgreementWith() {
    const { balanceCompany, filters, getTranslate } = this.props;
    const dependencies = [
      filtersKeys.balanceStatementBalanceId,
      filtersKeys.balanceStatementContractId,
    ];

    const originalList = this.getAvailableValues({
      key: filtersKeys.balanceStatementCompanyId,
      list: balanceCompany?.list || [],
      dependencies,
    });

    return (
      <div className='filters__item'>
        <SelectionList
          modern={true}
          label={getTranslate('statement.filters.contractWith')}
          isSingleMode={true}
          items={getSelectionListItems({
            originalList,
            selectedList: [filters[filtersKeys.balanceStatementCompanyId]],
          })}
          onChange={(itemsResult) =>
            this.onChangeSelect({
              key: filtersKeys.balanceStatementCompanyId,
              itemsResult,
              isSingleMode: true,
              originalList,
              dependencies,
            })
          }
        />
      </div>
    );
  }

  renderAgreementNumber() {
    const { balanceContract, filters, getTranslate } = this.props;
    const dependencies = [
      filtersKeys.balanceStatementBalanceId,
      filtersKeys.balanceStatementCompanyId,
    ];

    const originalList = this.getAvailableValues({
      key: filtersKeys.balanceStatementContractId,
      list: balanceContract?.list || [],
      dependencies,
    });

    return (
      <div className='filters__item'>
        <SelectionList
          modern
          label={getTranslate('statement.filters.contract')}
          items={getSelectionListItems({
            originalList,
            selectedList: filters[filtersKeys.balanceStatementContractId],
          })}
          onChange={(itemsResult) =>
            this.onChangeSelect({
              key: filtersKeys.balanceStatementContractId,
              itemsResult,
              originalList,
              dependencies,
            })
          }
        />
      </div>
    );
  }

  renderBalanceSelect() {
    const { balance, filters, getTranslate } = this.props;
    const dependencies = [
      filtersKeys.balanceStatementCompanyId,
      filtersKeys.balanceStatementContractId,
    ];

    const originalList = this.getAvailableValues({
      key: filtersKeys.balanceStatementBalanceId,
      list: balance?.list || [],
      dependencies,
    });

    return (
      <div className='filters__item'>
        <SelectionList
          label={getTranslate('statement.filters.balance')}
          modern
          items={getSelectionListItems({
            originalList,
            selectedList: filters[filtersKeys.balanceStatementBalanceId],
          })}
          onChange={(itemsResult) =>
            this.onChangeSelect({
              key: filtersKeys.balanceStatementBalanceId,
              itemsResult,
              originalList,
              dependencies,
            })
          }
        />
      </div>
    );
  }

  renderCurrency() {
    const { balanceCurrency, filters, getTranslate } = this.props;

    const originalList = this.getAvailableValues({
      key: filtersKeys.balanceStatementCurrencyId,
      list: balanceCurrency?.list || [],
    });

    return (
      <div className='filters__item'>
        <SelectionList
          label={getTranslate('statement.filters.currency')}
          items={getSelectionListItems({
            originalList,
            selectedList: filters[filtersKeys.balanceStatementCurrencyId],
          })}
          onChange={(itemsResult) =>
            this.onChangeSelect({
              key: filtersKeys.balanceStatementCurrencyId,
              itemsResult,
            })
          }
          tooltip={getTranslate('select.currency.favorite.tooltip')}
          modern
        />
      </div>
    );
  }

  renderDateRange() {
    const { filters, timezone } = this.props;
    return (
      <div className='filters__item'>
        <DateRange
          id={filtersKeys.balanceStatementPeriod}
          timezone={timezone}
          withLabel={false}
          hideResetButton={true}
          options={[
            {
              id: filtersKeys.balanceStatementPeriod,
              value: filters[filtersKeys.balanceStatementPeriod],
            },
          ]}
          periodPreset={[]}
          activePreset={''}
          params={[
            {
              id: filtersKeys.balanceStatementPeriod,
              minDate: '12.12.2022',
            },
          ]}
          customClass='statement-create-form__date-range'
          onChange={(filterId, value) => {
            this.onChangeDateRange({
              key: filtersKeys.balanceStatementPeriod,
              dependencyKey: filtersKeys.balanceStatementPeriodPreset,
              value,
            });
          }}
          containerSelector='.statement-create-form__content'
        />
      </div>
    );
  }

  renderGroupPeriodBy() {
    const { filters, getTranslate } = this.props;
    const filterValue = filters[filtersKeys.balanceStatementGroupPeriodBy];
    const options = [
      {
        value: groupPeriodBy.Day,
        label: getTranslate('statement.filters.groupBy.day'),
      },
      {
        value: groupPeriodBy.Week,
        label: getTranslate('statement.filters.groupBy.week'),
      },
      {
        value: groupPeriodBy.Month,
        label: getTranslate('statement.filters.groupBy.month'),
      },
      {
        value: groupPeriodBy.Quarter,
        label: getTranslate('statement.filters.groupBy.3month'),
      },
    ];
    const selectedValue = filterValue
      ? {
          label: options.find((item) => item.value === filterValue)?.label,
          value: filterValue,
        }
      : null;

    return (
      <div className='filters__item'>
        <CustomSelect
          label={getTranslate('statement.filters.groupBy')}
          value={selectedValue}
          options={options}
          modern
          onChange={({ value }) =>
            this.onChangeFilter({
              key: filtersKeys.balanceStatementGroupPeriodBy,
              value,
            })
          }
        />
      </div>
    );
  }

  renderQuickDatePicker() {
    const { filters, getTranslate } = this.props;
    const list = [
      periodTypes.TodayFullDay,
      periodTypes.Yesterday,
      periodTypes.LastWeek,
      periodTypes.LastMonth,
      periodTypes.LastQuarter,
    ];
    return (
      <div className='filters__item filters__item_buttons-group'>
        <div className='filters__buttons-group-title'>
          {getTranslate('quickFilters.chooseDate')}
        </div>
        <ButtonsGroup
          activeButtons={[filters[filtersKeys.balanceStatementPeriodPreset]]}
          mode='singleRequired'
          theme='dark'
          className='ui-buttons-group_single-choice ui-buttons-group_expanded'
          onClick={(values) =>
            this.onChangePeriodPreset({
              key: filtersKeys.balanceStatementPeriodPreset,
              dependencyKey: filtersKeys.balanceStatementPeriod,
              values,
            })
          }>
          {list.map((type) => (
            <Button
              key={type}
              status='outline'
              id={type}
              text={getTranslate(
                `filters.fields.dateTime.${
                  type === periodTypes.TodayFullDay ? periodTypes.Today : type
                }`
              )}
            />
          ))}
        </ButtonsGroup>
      </div>
    );
  }

  renderApplyButton() {
    const { filters, getTranslate } = this.props;

    return (
      <Button
        status='primary'
        text={getTranslate('statement.buttons.apply')}
        type='submit'
        disabled={
          !filters[filtersKeys.balanceStatementPeriod].length ||
          !filters[filtersKeys.balanceStatementBalanceType]
        }
        customClass='ui-button_full-width text-semi ui-button_size-normal'
      />
    );
  }

  onChangeButtonGroup({ key, values }): void {
    if (key === filtersKeys.balanceStatementBalanceType) {
      const { dispatch } = this.props;

      dispatch(
        resetConcreteQuickFiltersProps({
          name: tableNames.balanceStatement,
          keys: [
            filtersKeys.balanceStatementBalanceId,
            filtersKeys.balanceStatementContractId,
            filtersKeys.balanceStatementCurrencyId,
            filtersKeys.balanceStatementCompanyId,
          ],
        })
      );
    }

    this.onChangeFilter({ key, value: values[0] });
  }

  onChangeSelect({
    key,
    itemsResult,
    isSingleMode,
    originalList,
    dependencies,
  }: {
    key: string;
    itemsResult: Array<any>;
    isSingleMode?: boolean;
    originalList?: Array<any>;
    dependencies?: Array<any>;
  }): void {
    const { filters } = this.props;
    const value = itemsResult
      .filter((item) => item.isSelected)
      .map((item) => item.id);
    if (value.length > 0 && dependencies) {
      dependencies.forEach((dependencyKey) => {
        const dependencyAvailableValues =
          getDependencyAvailableValuesFromSelected({
            value,
            originalList,
            filters,
            dependencyKey,
          });

        if (dependencyAvailableValues.length > 0) {
          this.onChangeFilter({
            key: dependencyKey,
            value: dependencyAvailableValues,
          });
        }
      });
    }

    this.onChangeFilter({
      key,
      value: isSingleMode === true ? value[0] || '' : value,
    });
  }

  onChangeDateRange({
    key,
    dependencyKey,
    value,
  }: {
    key: string;
    dependencyKey?: string;
    value: Array<any>;
  }): void {
    const { timezone } = this.props;

    if (Array.isArray(value)) {
      if (dependencyKey) {
        const period = DateHelpers.checkRangeIsPeriod({
          range: value,
          timezone,
        });

        this.onChangePeriodPreset({ key: dependencyKey, values: [period] });
      }

      this.onChangeFilter({ key, value });
    }
  }

  onChangePeriodPreset({
    key,
    dependencyKey,
    values,
  }: {
    key: string;
    dependencyKey?: string;
    values: Array<any>;
  }): void {
    const { timezone, dispatch } = this.props;
    const value = values[0];

    if (value && dependencyKey) {
      const period = DateHelpers.getPeriod(value, timezone);

      this.onChangeDateRange({ key: dependencyKey, value: period });
    }

    dispatch(changeQuickFilter(tableNames.balanceStatement, key, value));
  }

  onChangeFilter({ key, value }): void {
    const { dispatch } = this.props;
    dispatch(changeQuickFilter(tableNames.balanceStatement, key, value));
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  timezone: state.user.timezone,
});

export default connect(mapStateToProps)(addTranslation(CreateStatementForm));
