import BaseMessageHandler from '../BaseMessageHandler';
import { addDataItem, updateDataItem } from 'actions/getData';
import Repository from 'helpers/Repository';

export default class PaymentOperationBatch_Updated extends BaseMessageHandler {
  success = () => {
    const {
      dispatch,
      message: { payload },
    } = this;

    const store = Repository.get('store');
    const {
      data: { massPayouts },
    } = store.getState();

    if (massPayouts.isFetched) {
      if (
        massPayouts.items.find(
          ({ paymentOperationBatchId }) =>
            paymentOperationBatchId === payload.paymentOperationBatchId
        )
      ) {
        dispatch(
          updateDataItem('massPayouts', 'paymentOperationBatchId', payload)
        );
      } else {
        dispatch(addDataItem('massPayouts', payload));
      }
    }
  };
}
