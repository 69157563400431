import React from 'react';
import { connect } from 'react-redux';

import { updateFiltersValues } from 'actions/filtersValues';
import { changeQuickFilter } from 'actions/quickFilters';
import getFiltersValues from 'selectors/getFiltersValues';
import getQuickFiltersValues from 'selectors/getQuickFiltersValues';
import { filtersKeysType } from 'types/Filters';
import { QuickFiltersTableName } from 'types/QuickFilters';
import tableNames from 'constants/tableNames';
import { RootState, StoreProps } from 'store';

import QuickFiltersProps from './QuickFiltersProps';

interface OwnProps<TableName extends QuickFiltersTableName> {
  tableName: TableName;
  onChange?: QuickFiltersProps<TableName>['onChangeFilter'];
  children: (props: QuickFiltersProps<TableName>) => React.ReactElement;
  onBeforeChange?: {
    [key: string]: (key: filtersKeysType, value: any) => boolean;
  };
}

interface ConnectedProps<TableName extends QuickFiltersTableName>
  extends Pick<
    QuickFiltersProps<TableName>,
    'hasSelectedQuickFilters' | 'hasSelectedFilters'
  > {
  quickFilters: QuickFiltersProps<TableName>['filters'] | '';
}

type Props<TableName extends QuickFiltersTableName> = OwnProps<TableName> &
  ConnectedProps<TableName> &
  StoreProps;

class QuickFiltersContainer<
  TableName extends QuickFiltersTableName
> extends React.Component<Props<TableName>> {
  render() {
    const {
      quickFilters,
      tableName,
      children,
      hasSelectedFilters,
      hasSelectedQuickFilters,
      onBeforeChange,
    } = this.props;

    // @ts-ignore TODO: t.melashchenko, выручай!
    return children({
      filters: quickFilters,
      onChangeFilter: this.changeFilter,
      updateFiltersValues: this.updateFiltersValues,
      name: tableName,
      hasSelectedQuickFilters,
      hasSelectedFilters,
      onBeforeChange,
    });
  }

  changeFilter = (key: filtersKeysType, value: any) => {
    const { dispatch, tableName, onChange } = this.props;
    dispatch(changeQuickFilter(tableName, key, value));

    onChange && onChange(key, value);
  };

  updateFiltersValues = (key: filtersKeysType, value: any) => {
    const { dispatch } = this.props;
    dispatch(updateFiltersValues({ [key]: value }));
  };
}

const mapStateToProps = <TableName extends QuickFiltersTableName>(
  state: RootState,
  ownProps: OwnProps<TableName>
): ConnectedProps<TableName> => ({
  quickFilters: state.quickFilters[ownProps.tableName],
  hasSelectedQuickFilters:
    Object.keys(getQuickFiltersValues(state, ownProps.tableName, true)).length >
    0,
  hasSelectedFilters:
    (ownProps.tableName === tableNames.payments &&
      state.searchFilters.selectedCount > 0) ||
    Object.keys(getFiltersValues(state, ownProps.tableName, true)).length > 0,
});

export default connect(mapStateToProps)(QuickFiltersContainer);
