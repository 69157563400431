import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'components/ui/table/helpers';
import { getReconciliation, setReconciliation } from 'api/reconciliation';
import { initAllConfiguration } from 'actions/configuration';
import { openModal } from 'actions/modal';
import showNotification from 'components/ui/notification/showNotification';
import Reconciliation from './Reconciliation';
import DateHelpers from 'helpers/Date';
import Utils from 'helpers/Utils';
import getConfigurationByName from 'selectors/getConfigurationByName';
import { configurationBottom, configurationTop } from './configuration';
import { initialState, targetWiresInitial } from './initialState';
import { AnyObject } from 'types/Common';
import { StoreProps } from 'store';
import './reconciliation.scss';

interface ConnectedProps {
  timezone: string;
  configuration: AnyObject;
  configurationRelevantFunds: AnyObject;
}

type Props = ConnectedProps & StoreProps;

interface State {
  dataSection: any;
  relevantFunds: any;
  dataSectionRate: AnyObject;
  relevantFundsRate: AnyObject;
  deposit: AnyObject;
  date: string;
  dateToSet: string;
  commentary: string;
  isLoading: boolean;
}

class ReconciliationContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      date: '',
      dateToSet: '',
      commentary: '',
      ...initialState,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const date = new Date();

    this.setState(
      {
        date: DateHelpers.getFormat(DateHelpers.getUtcDate(date), 'date'),
        dateToSet: DateHelpers.getFormat(
          DateHelpers.getUtcDate(date),
          'datetime'
        ),
      },
      () => {
        this.getConfiguration();
        this.init();
      }
    );
  }

  render() {
    const { configuration, configurationRelevantFunds } = this.props;
    const {
      dataSection,
      commentary,
      date,
      dateToSet,
      relevantFunds,
      dataSectionRate,
      relevantFundsRate,
      deposit,
      isLoading,
    } = this.state;

    return (
      <Reconciliation
        date={date}
        dateToSet={dateToSet}
        data={dataSection}
        commentary={commentary}
        dataSectionRate={dataSectionRate}
        relevantFundsRate={relevantFundsRate}
        relevantFunds={relevantFunds}
        deposit={deposit}
        isLoading={isLoading}
        configuration={configuration}
        configurationRelevantFunds={configurationRelevantFunds}
        changeData={this.changeData}
        onCommentaryEdit={this.onCommentaryEdit}
        changeDeposit={this.changeDeposit}
        saveData={this.saveData}
        changeDate={this.changeDate}
        changeRate={this.changeRate}
        openCSVModal={this.openCSVModal}
      />
    );
  }

  init = async () => {
    const { date: _date } = this.state;
    try {
      this.setState({ isLoading: true });

      const result: any = await getReconciliation(
        DateHelpers.getFormat(DateHelpers.createDate(_date, 'date'), 'date')
      );

      const { data, date } = result;
      this.setState({
        commentary: data.commentary || '',
        dataSection:
          this.normalizeCase(
            this.fixGBRTypo(this.addTargetWires(data.dataSection)),
            configurationTop
          ) || initialState.dataSection,
        relevantFunds:
          this.normalizeCase(
            this.fixGBRTypo(data.relevantFunds),
            configurationBottom
          ) || initialState.relevantFunds,
        dataSectionRate:
          this.fixGBRTypo(data.dataSectionRate) || initialState.dataSectionRate,
        relevantFundsRate:
          this.fixGBRTypo(data.relevantFundsRate) ||
          initialState.relevantFundsRate,
        deposit: this.fixGBRTypo(data.deposit) || initialState.deposit,
      });

      if (isEmpty(data)) {
        showNotification({
          status: 'error',
          content: `No data for ${date}`,
        });
      }
    } finally {
      this.setState({ isLoading: false });
    }
  };

  addTargetWires = (dataSection) => {
    if (
      dataSection &&
      !dataSection.some((row) =>
        row.bank_name.toLowerCase().includes('targetwires')
      )
    ) {
      dataSection.push(targetWiresInitial);
    }
    return dataSection;
  };

  fixGBRTypo = (data) => {
    if (!data) {
      return data;
    }
    if (Array.isArray(data)) {
      return data.map(this.fixGBRTypo);
    }
    if (Utils.hasProp(data, 'GBR')) {
      const gbrData = data.GBR;
      delete data.GBR;
      data.GBP = gbrData;
    }
    return data;
  };

  getConfiguration = () => {
    const { configuration, dispatch } = this.props;
    dispatch(
      initAllConfiguration({
        ...configuration,
        reconciliation: configurationTop,
        reconciliationRelevantFunds: configurationBottom,
      })
    );
  };

  onCommentaryEdit = (e) => {
    this.setState({ commentary: e.target.value });
  };

  changeData = (section, rowId, colName, value, isOver?) => {
    const data = this.state[section];
    const newData = data.map((row: AnyObject) => {
      if ((row.bank_name || row.id) !== rowId) return row;
      if (colName === 'bank_name') return { ...row, [colName]: value };

      const newValue = isOver
        ? (+Utils.getNumberWithoutSpace(value)).toFixed(2)
        : Utils.getNumberWithSpace(value);
      return {
        ...row,
        [colName]: newValue,
      };
    });
    this.setState({
      [section]: newData,
    } as Pick<State, keyof State>);
  };

  changeDate = (date, toSet?) => {
    if (!date) {
      return;
    }
    if (toSet) {
      this.setState({ dateToSet: date });
    } else {
      this.setState({ date }, () => {
        this.init();
      });
    }
  };

  changeRate = (rateName: string, currency, value) => {
    const rateSection = this.state[`${rateName}Rate`];
    const newState = {
      [`${rateName}Rate`]: {
        ...rateSection,
        [currency]: Utils.getNumberWithoutSpace(value),
      },
    };
    if (rateName === 'dataSection') {
      newState.relevantFundsRate = {
        ...this.state.relevantFundsRate,
        [currency]: Utils.getNumberWithoutSpace(value),
      };
    }
    this.setState({ ...newState } as Pick<State, keyof State>);
  };

  changeDeposit = (name, cur, value, callback, isOver) => {
    const newValue = Utils.getNumberWithoutSpace(value);
    this.setState((state) => {
      const section = state.deposit;
      return {
        deposit: {
          ...section,
          [name]: {
            ...section[name],
            [cur]: isOver ? (+newValue).toFixed(2) : newValue,
          },
        },
      } as Pick<State, keyof State>;
    }, callback);
  };

  saveData = async () => {
    const {
      date,
      dateToSet,
      dataSection,
      dataSectionRate,
      relevantFunds,
      relevantFundsRate,
      deposit,
      commentary,
    } = this.state;
    try {
      this.setState({ isLoading: true });
      await setReconciliation({
        date: DateHelpers.getFormat(
          DateHelpers.createDate(date, 'date'),
          'date'
        ),
        data: {
          commentary,
          date: dateToSet,
          dataSection,
          dataSectionRate,
          relevantFunds,
          relevantFundsRate,
          deposit,
        },
      });
      showNotification({ status: 'success', content: 'Changes saved' });
    } catch (e) {
      showNotification({
        status: 'error',
        content: e.payload?.error || 'Something went wrong',
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  openCSVModal = (tableName: string) => {
    const { dispatch } = this.props;
    dispatch(
      openModal({
        modalId: 'CSVUpload',
        callback: (data) => {
          if (data) {
            this.setState({
              [tableName]: this.normalizeCase(
                data,
                this.getConfigByTableName(tableName)
              ),
            } as Pick<State, keyof State>);
          }
        },
      })
    );
  };

  getConfigByTableName = (tableName) => {
    if (tableName === 'dataSection') {
      return configurationTop;
    }
    return configurationBottom;
  };

  normalizeCase = (data, config) => {
    if (!data) return;

    return data.map((dataItem) => {
      return Object.keys(dataItem).reduce((result, dataKey) => {
        const configItem = config.find((item) => {
          return item.id.toLowerCase() === dataKey.toLowerCase();
        });

        if (configItem) {
          result[configItem.id] = dataItem[dataKey];
        }
        return result;
      }, {});
    });
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  timezone: state.user.timezone,
  configuration: getConfigurationByName(state, 'reconciliation'),
  configurationRelevantFunds: getConfigurationByName(
    state,
    'reconciliationRelevantFunds'
  ),
});

export default connect(mapStateToProps)(ReconciliationContainer);
