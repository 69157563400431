import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import FormConstructor from '../formConstructor';
import { AnyObject } from 'types/Common';

interface Props extends IntlProps {
  fields: any[] | null;
  validationErrors: AnyObject;
  onChangeField: (field: string, value: any, type: string) => void;
}

const ClarificationFields: React.VFC<Props> = ({
  fields,
  validationErrors,
  onChangeField,
  getTranslate,
}) => {
  if (!fields) return null;

  return (
    <>
      <div className='ui-form-fields__col-title'>
        {getTranslate('payouts.request.single.clarificationParameters.label')}
      </div>
      <FormConstructor
        fields={fields}
        validationErrors={validationErrors}
        onChangeField={(field, value) =>
          onChangeField(field, value, 'clarificationFields')
        }
      />
    </>
  );
};

export default addTranslation(ClarificationFields);
