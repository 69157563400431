import BaseMessageHandler from '../BaseMessageHandler';
import { closeModal, openModal } from 'actions/modal';
import showNotification from 'components/ui/notification/showNotification';
import getLocalizedText from 'helpers/getLocalizedText';

export default class Confirm_Telegram extends BaseMessageHandler {
  request = () => {
    const { dispatch, message } = this;
    const { payload } = message;

    dispatch(
      openModal({
        modalId: 'ConfirmTelegram',
        content: {
          ...payload,
        },
      })
    );
  };

  success = () => {
    this.dispatch(closeModal());
    showNotification({
      status: 'success',
      content: getLocalizedText('common.successfulAuth.sysmsg'),
    });
  };
}
