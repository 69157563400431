import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { StoreProps } from 'store';

import Button from 'components/ui/button';

import { DictionaryMultiSelect } from 'types/FilterValue';
import {
  changeQuickFilter,
  resetConcreteQuickFilters,
} from 'actions/quickFilters';
import tableNames from 'constants/tableNames';
import filtersKeys from 'constants/filters';

interface OwnProps {
  showCreateForm: boolean;
  isLoading: boolean;
  balanceType: DictionaryMultiSelect;
  onToggle: () => void;
}

type Props = OwnProps & StoreProps & IntlProps;

class TopPanel extends PureComponent<Props> {
  render() {
    const { showCreateForm, isLoading } = this.props;
    const { getTranslate, onToggle } = this.props;

    return (
      <Fragment>
        <Button
          text={getTranslate('statement.buttons.createStatement')}
          status='primary'
          disabled={showCreateForm}
          onClick={onToggle}
        />
        <Button
          status='light'
          icon='im-Reset-filter'
          disabled={isLoading}
          onClick={this.onResetFilters}
        />
      </Fragment>
    );
  }

  onResetFilters = () => {
    const { balanceType, dispatch } = this.props;

    dispatch(resetConcreteQuickFilters({ name: tableNames.balanceStatement }));

    if (balanceType?.isFetched) {
      dispatch(
        changeQuickFilter(
          tableNames.balanceStatement,
          filtersKeys.balanceStatementBalanceType,
          balanceType.list[0].id
        )
      );
    }
  };
}

export default connect()(addTranslation(TopPanel));
