import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import api from 'api/user';

import ProfilePage from './ProfilePage';
import Loader from 'components/ui/loader';
import { AnyObject } from 'types/Common';
import checkFilters from 'helpers/checkFilters';
import { StoreProps } from 'store';
import { logoutUser } from 'actions/user';
import path from 'helpers/path';

interface ConnectedProps {
  timezones: {
    isFetch: boolean;
  };
  contactLanguages: AnyObject[];
}

type Props = ConnectedProps & StoreProps & RouteComponentProps;

class ProfileContainer extends Component<Props> {
  async componentDidMount() {
    await checkFilters('profile');
    await this.init();
  }

  render() {
    if (!this.canRender()) {
      return <Loader />;
    }
    return <ProfilePage logout={this.logout} />;
  }

  async init() {
    try {
      await api.getUser();
    } catch (e) {
      console.error(e);
    }
  }

  canRender() {
    const { timezones, contactLanguages } = this.props;
    return timezones.isFetch && contactLanguages;
  }

  logout = () => {
    this.props.dispatch(
      logoutUser(() => {
        this.props.history.push(path('/login'));
      })
    );
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  contactLanguages: state.filtersValues?.contactLanguage?.list,
  timezones: state.dictionaries.timezones,
});

export default withRouter(connect(mapStateToProps)(ProfileContainer));
