import React, { PureComponent, ReactNode } from 'react';
import * as Redux from 'redux';
import { connect } from 'react-redux';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Link from 'components/ui/link';
import PolicyContainer from '../PolicyContainer';
import { AnyObject } from 'types/Common';
import ThemeConfig from 'types/ThemeConfig';

interface OwnProps {
  history?: any;
  dispatch?: Redux.Dispatch<any>;
  children?: ReactNode;
}

interface ConnectedProps {
  settings: {
    themeConfig: ThemeConfig;
    lexemeMap: AnyObject;
  };
}

type Props = OwnProps & ConnectedProps & IntlProps;

class PrivacyPolicy extends PureComponent<Props> {
  render() {
    const { getTranslate } = this;
    const { common = {}, privacy = {} } = this.props.settings.lexemeMap;

    return (
      <PolicyContainer {...this.props}>
        <h1>{getTranslate('cookies.privacyPolicy.label')}</h1>

        <div className='policy__block'>
          <h2>{getTranslate('cookies.privacyPolicy.heading1')}</h2>
          <p>
            {getTranslate(privacy.text01, {
              link1: (
                <a
                  href={getTranslate(common.link1, {
                    inline: true,
                  })}
                  className='policy__link'
                  target='_blank'
                  rel='noopener noreferrer'>
                  {getTranslate(common.link1)}
                </a>
              ),
            })}
          </p>

          <p>
            {getTranslate('cookies.privacyPolicy.text01b', {
              text01a: (
                <span className='bold'>
                  {getTranslate('cookies.privacyPolicy.text01a')}
                </span>
              ),
            })}
          </p>
        </div>

        <div className='policy__block'>
          <h2>{getTranslate('cookies.privacyPolicy.heading2')}</h2>

          <h3>{getTranslate('cookies.privacyPolicy.heading3')}</h3>

          <p>{getTranslate('cookies.privacyPolicy.text02')}</p>

          <ul>
            <li>{getTranslate('cookies.privacyPolicy.text03')}</li>
            <li>{getTranslate('cookies.privacyPolicy.text04')}</li>
            <li>{getTranslate('cookies.privacyPolicy.text05')}</li>
          </ul>

          <p>{getTranslate('cookies.privacyPolicy.text06')}</p>

          <h3>{getTranslate('cookies.privacyPolicy.heading4')}</h3>

          <p>{getTranslate('cookies.privacyPolicy.text07')}</p>

          <ul>
            <li>{getTranslate('cookies.privacyPolicy.text08')}</li>
            <li>
              {getTranslate('cookies.privacyPolicy.text09', {
                text09a: (
                  <Link url='./cookie-policy' className='policy__link'>
                    {getTranslate('cookies.privacyPolicy.text09a')}
                  </Link>
                ),
              })}
            </li>
            <li>{getTranslate('cookies.privacyPolicy.text10')}</li>
          </ul>
        </div>

        <div className='policy__block'>
          <h2>{getTranslate('cookies.privacyPolicy.heading5')}</h2>

          <p>{getTranslate(privacy.text11)}</p>

          <p>{getTranslate('cookies.privacyPolicy.text12')}</p>

          <ul>
            <li>{getTranslate('cookies.privacyPolicy.text13')}</li>
            <li>{getTranslate('cookies.privacyPolicy.text14')}</li>
            <li>{getTranslate('cookies.privacyPolicy.text15')}</li>
            <li>{getTranslate('cookies.privacyPolicy.text16')}</li>
            <li>{getTranslate('cookies.privacyPolicy.text17')}</li>
            <li>{getTranslate('cookies.privacyPolicy.text18')}</li>
          </ul>
        </div>

        <div className='policy__block'>
          <h2>{getTranslate('cookies.privacyPolicy.heading6')}</h2>

          <p>{getTranslate('cookies.privacyPolicy.text19')}</p>
        </div>

        <div className='policy__block'>
          <h2>{getTranslate('cookies.privacyPolicy.heading7')}</h2>

          <p>{getTranslate('cookies.privacyPolicy.text20')}</p>
        </div>

        <div className='policy__block'>
          <h2>{getTranslate('cookies.privacyPolicy.heading8')}</h2>

          <p>{getTranslate('cookies.privacyPolicy.text21')}</p>

          <ul>
            <li>
              {getTranslate(privacy.text23, {
                text22: (
                  <span className='bold'>
                    {getTranslate('cookies.privacyPolicy.text22')}
                  </span>
                ),
              })}
            </li>
            <li>
              {getTranslate('cookies.privacyPolicy.text25', {
                text24: (
                  <span className='bold'>
                    {getTranslate('cookies.privacyPolicy.text24')}
                  </span>
                ),
              })}
            </li>
            <li>
              {getTranslate('cookies.privacyPolicy.text27', {
                text26: (
                  <span className='bold'>
                    {getTranslate('cookies.privacyPolicy.text26')}
                  </span>
                ),
              })}
            </li>
            <li>
              {getTranslate('cookies.privacyPolicy.text29', {
                text28: (
                  <span className='bold'>
                    {getTranslate('cookies.privacyPolicy.text28')}
                  </span>
                ),
              })}
            </li>
          </ul>

          <p>{getTranslate('cookies.privacyPolicy.text30')}</p>
        </div>

        <div className='policy__block'>
          <h2>{getTranslate('cookies.privacyPolicy.text31')}</h2>

          <p>{getTranslate('cookies.privacyPolicy.text32')}</p>

          <ul>
            <li>
              {getTranslate('cookies.privacyPolicy.text33', {
                text34: (
                  <span className='bold'>
                    {getTranslate('cookies.privacyPolicy.text34')}
                  </span>
                ),
                link5: (
                  <a
                    className='policy__link'
                    href={`mailto:${getTranslate(common.link5, {
                      inline: true,
                    })}`}
                    rel='noreferrer noopener'
                    target='_blank'>
                    {getTranslate(common.link5)}
                  </a>
                ),
              })}
            </li>
            <li>
              {getTranslate('cookies.privacyPolicy.text35', {
                text36: (
                  <span className='bold'>
                    {getTranslate('cookies.privacyPolicy.text36')}
                  </span>
                ),
                link5: (
                  <a
                    className='policy__link'
                    href={`mailto:${getTranslate(common.link5, {
                      inline: true,
                    })}`}
                    rel='noreferrer noopener'
                    target='_blank'>
                    {getTranslate(common.link5)}
                  </a>
                ),
              })}
            </li>
            <li>
              {getTranslate('cookies.privacyPolicy.text37', {
                text38: (
                  <span className='bold'>
                    {getTranslate('cookies.privacyPolicy.text38')}
                  </span>
                ),
              })}
            </li>
            <li>
              {getTranslate('cookies.privacyPolicy.text40', {
                text41: (
                  <span className='bold'>
                    {getTranslate('cookies.privacyPolicy.text41')}
                  </span>
                ),
              })}
            </li>
            <li>
              {getTranslate('cookies.privacyPolicy.text42', {
                text43: (
                  <span className='bold'>
                    {getTranslate('cookies.privacyPolicy.text43')}
                  </span>
                ),
                text44: (
                  <span className='bold'>
                    {getTranslate('cookies.privacyPolicy.text44')}
                  </span>
                ),
              })}
            </li>
            <li>
              {getTranslate('cookies.privacyPolicy.text45', {
                text46: (
                  <span className='bold'>
                    {getTranslate('cookies.privacyPolicy.text46')}
                  </span>
                ),
              })}
            </li>
          </ul>
        </div>

        <div className='policy__block'>
          <h2>{getTranslate('cookies.privacyPolicy.heading10')}</h2>

          {/*TODO Contact links ?*/}
          <p>
            {getTranslate('cookies.privacyPolicy.text47', {
              text09a: (
                <Link url='./cookie-policy' className='policy__link'>
                  {getTranslate('cookies.privacyPolicy.text09a')}
                </Link>
              ),
            })}
          </p>
        </div>

        <div className='policy__block'>
          <h2>{getTranslate('cookies.privacyPolicy.heading11')}</h2>

          <p>{getTranslate('cookies.privacyPolicy.text48')}</p>
          <p>{getTranslate('cookies.privacyPolicy.text48a')}</p>
        </div>

        <div className='policy__block'>
          <h2>{getTranslate('cookies.privacyPolicy.heading12')}</h2>

          <p>{getTranslate('cookies.privacyPolicy.text49')}</p>
          <p>{getTranslate('cookies.privacyPolicy.text49a')}</p>
        </div>

        <div className='policy__block'>
          <h2>{getTranslate('cookies.privacyPolicy.heading13')}</h2>

          <p>
            {getTranslate('cookies.privacyPolicy.text50', {
              link5: (
                <a
                  className='policy__link'
                  href={`mailto:${getTranslate(common.link5, {
                    inline: true,
                  })}`}
                  rel='noreferrer noopener'
                  target='_blank'>
                  {getTranslate(common.link5)}
                </a>
              ),
            })}
          </p>
        </div>

        <div className='policy__block'>
          {privacy.heading14 && <h2>{getTranslate(privacy.heading14)}</h2>}

          {privacy.text51 && (
            <p>
              {getTranslate(privacy.text51, {
                link5: (
                  <a
                    className='policy__link'
                    href={`mailto:${getTranslate(common.link5, {
                      inline: true,
                    })}`}
                    rel='noreferrer noopener'
                    target='_blank'>
                    {getTranslate(common.link5)}
                  </a>
                ),
              })}
            </p>
          )}

          <p>
            {privacy.text51a && (
              <>
                {getTranslate(privacy.text51a)}
                <br />
              </>
            )}

            {privacy.text51b && (
              <>
                {getTranslate(privacy.text51b)}
                <br />
              </>
            )}

            {privacy.text51c && (
              <>
                {getTranslate(privacy.text51c)}
                <br />
              </>
            )}

            {privacy.text51d && (
              <>
                {getTranslate(privacy.text51d)}
                <br />
              </>
            )}

            {privacy.text51e && (
              <>
                {getTranslate(privacy.text51e)}
                <br />
              </>
            )}
          </p>
        </div>
      </PolicyContainer>
    );
  }

  getTranslate = (id, values = {}) => {
    const { getTranslate } = this.props;
    const options: any = values;
    if (!id) {
      return '';
    }
    return getTranslate(id, { ...options, node: !options.inline });
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  settings: state.settings,
});

export default connect(mapStateToProps)(addTranslation(PrivacyPolicy));
