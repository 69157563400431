export enum FilterNames {
  merchant = 'merchant',
  project = 'project',
  legalEntity = 'legalEntity',
}

export type AdminFilterPayload = {
  name: string;
  params?: {
    projectId?: string | string[];
    merchantId?: string;
    q?: string;
  };
  offset?: number;
};
