import DateHelpers from 'helpers/Date';

export const getMinYear = (minDate?) => {
  const MIN_YEAR = DateHelpers.getDate().year() - 2;
  return minDate ? DateHelpers.createDate(minDate, 'date').year() : MIN_YEAR;
};

export const getMaxYear = (maxDate?) => {
  const MAX_YEAR = DateHelpers.getDate().year();
  if (maxDate === 'none') {
    return MAX_YEAR;
  }
  return maxDate ? DateHelpers.createDate(maxDate, 'date').year() : MAX_YEAR;
};

export const getMinDate = (minDate?) => {
  if (minDate) {
    return DateHelpers.createDate(minDate, 'date').toDate();
  }

  const currentDate = DateHelpers.getDate();
  const currentMonth = currentDate.month();
  return currentDate
    .year(getMinYear())
    .month(currentMonth)
    .startOf('month')
    .toDate();
};

export const getMaxDate = (maxDate?) => {
  if (maxDate && maxDate === 'none') {
    return;
  } else if (maxDate) {
    return DateHelpers.createDate(maxDate, 'date').toDate();
  }

  const currentDate = DateHelpers.getDate();
  const currentMonth = currentDate.month();
  return currentDate
    .year(getMaxYear())
    .month(currentMonth)
    .endOf('month')
    .toDate();
};
