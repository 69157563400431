import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import NoContentImage from 'images/common/graph-details-no-content.svg';

interface Props extends IntlProps {}

class NoContent extends React.PureComponent<Props> {
  render() {
    const { getTranslate } = this.props;
    return (
      <div className='no-content'>
        <img src={NoContentImage} alt='' className='no-content__image' />
        <div className='no-content__text'>
          {getTranslate('Select the data to display on a chart')}
        </div>
      </div>
    );
  }
}
export default addTranslation(NoContent);
