import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Button from 'components/ui/button';
import CustomSelect from 'components/ui/customSelect';
import InputDateRange from 'components/ui/inputDateRange';
import FinancialTabEmptyState from 'pages/finance/financialTabEmptyState';
import { Fields } from './StatStatementContainer';
import SelectionListItem from 'types/SelectionListItem';
import SelectItem from 'types/SelectItem';
import { periodTypes } from 'constants/dateFormats';
import ButtonsGroup from 'components/ui/buttonsGroup/ButtonsGroup';
import DateHelpers from 'helpers/Date';
import { dictionaries, periods } from 'pages/finance/statStatement/constants';
import Loader from 'components/ui/loader';
import StatStatementData from 'pages/finance/statStatement/statStatementData';
import { Data } from './statStatementData/types';
import './statStatement.scss';

interface OwnProps {
  state: 'loading' | 'empty' | 'data' | 'notFound';
  onChange: (key: keyof Fields, value: string | SelectionListItem[]) => void;
  onChangePeriodPreset: (value) => void;
  currencyList: SelectItem[];
  balanceIdList: SelectItem[];
  onFetchView: () => void;
  data: Data | null;
  loadingDictionaries: string[];
  canFetch: boolean;
}

type Props = OwnProps & IntlProps & Fields;

class StatStatement extends PureComponent<Props> {
  render() {
    const { getTranslate } = this.props;

    return (
      <div className='financial-tab stat-statements card card_l'>
        <div className='financial-tab__header card__header'>
          <div className='financial-tab__title'>
            {getTranslate('finance.statFinData.header')}
          </div>
          <div className='financial-tab__text card__subtext'>
            {getTranslate('finance.statFinData.infoText')}
          </div>
        </div>
        <div className='stat-statements__inner card__container'>
          <div className='stat-statements__form'>
            {this.renderDatePresets()}
            <InputDateRange
              id='statatement-data'
              label={getTranslate(
                'finance.statFinData.createReport.reportPeriod.label'
              )}
              placeholder={getTranslate(
                'finance.statFinData.createReport.reportPeriod.placeholder'
              )}
              onChange={(value) => {
                this.props.onChange('date', value);
              }}
              value={{
                dateFrom: this.props.date.dateFrom,
                dateTo: this.props.date.dateTo,
              }}
              withTime={false}
              applyFormat='date'
              monthsAmount={3}
              customClass='stat-statements__form-item'
              maxDate={DateHelpers.getFormat(DateHelpers.getDate(), 'date')}
              disableButton
              modern
            />
            <CustomSelect
              id='statement-select-currency'
              label={getTranslate(
                'finance.statFinData.createReport.balanceCurrency.label'
              )}
              options={this.props.currencyList}
              value={
                this.props.currencyList.find(
                  (currency) => currency.value === this.props.currency
                ) || null
              }
              onChange={(value) => {
                this.props.onChange('currency', value?.value);
              }}
              customClass='stat-statements__form-item'
              isLoading={this.props.loadingDictionaries.includes(
                dictionaries.currency
              )}
              modern
            />

            <CustomSelect
              id='statement-select-balance-id'
              label={getTranslate(
                'finance.statFinData.createReport.balance.label'
              )}
              options={this.props.balanceIdList}
              value={
                this.props.balanceIdList.find(
                  (balance) => balance.value === this.props.balanceId
                ) || null
              }
              onChange={(value) => {
                this.props.onChange('balanceId', value?.value);
              }}
              customClass='stat-statements__form-item'
              isLoading={this.props.loadingDictionaries.includes(
                dictionaries.balance
              )}
              modern
            />
            <Button
              status='primary'
              customClass='ui-button_full-width stat-statements__button'
              text={getTranslate(
                'finance.statFinData.createReport.newReport.button'
              )}
              disabled={!this.props.canFetch}
              onClick={this.props.onFetchView}
            />
          </div>
          <div
            className={classNames('stat-statements__content', {
              'stat-statements__content_empty': this.props.state === 'empty',
            })}>
            <div className='financial-tab__content-inner '>
              {this.props.state === 'loading' && <Loader />}
              {this.renderContent()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderContent = () => {
    if (this.props.state !== 'data') {
      return (
        <FinancialTabEmptyState
          text={
            this.props.state === 'notFound'
              ? 'finance.statFinData.notFoundReports.text'
              : 'finance.statFinData.noReports.text'
          }
          title='finance.statFinData.noReports.header'
        />
      );
    }
    return this.props.data ? (
      <StatStatementData
        data={this.props.data}
        text={this.props.getTranslate('onlinebalance.currentBalanceList.hint')}
      />
    ) : null;
  };

  renderDatePresets() {
    return (
      <div className='stat-statements__form-item'>
        <ButtonsGroup
          activeButtons={[this.props.periodPreset]}
          mode='singleRequired'
          theme='dark'
          className='ui-buttons-group_single-choice'
          onClick={(values) => {
            this.props.onChange('periodPreset', values[0]);
          }}>
          {periods.map((type) => (
            <Button
              key={type}
              status='outline'
              id={type}
              text={this.props.getTranslate(
                `filters.fields.dateTime.${
                  type === periodTypes.TodayFullDay ? periodTypes.Today : type
                }`
              )}
            />
          ))}
        </ButtonsGroup>
      </div>
    );
  }
}

export default addTranslation(StatStatement);
