export const minPayoutsTableColumnsWidth = {
  paymentId: 150,
  recurringScheduledPaymentId: 120,
  requestCountArray: 150,
  paymentAmount: 97,
  paymentCurrency: 77,
  paymentMethodType: 87,
  mapping_result_code: 76,
  last3dsCheck: 76,
};
