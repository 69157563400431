import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 *
 * @param params
 */
export const getChargebacks = (params = {}): Promise<any> => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Chargeback_List,
          payload: { ...params },
        },
      ],
      isCancelable: true,
    })
    .then((data: any) => ({
      items: data.rows,
      totalRows: Infinity,
    }));
};

/**
 *
 * @param chargebackId
 */
export const getChargebackInfo = (chargebackId: string): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Chargeback_Info,
        payload: { chargebackId },
      },
    ],
  });
};

/**
 *
 * @param chargebackId
 */
export const acceptChargebacks = (chargebackId: string[]): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Chargeback_Accept,
        payload: { chargebackId },
      },
    ],
  });
};

export const startDisputeChargeback = (
  chargebackId: string[]
): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Chargeback_DisputeStart,
        payload: { chargebackId },
      },
    ],
    showValidateNotification: false,
  });
};

export const getDisputeStatus = (disputeId: number): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Chargeback_DisputeStatus,
        payload: { disputeId },
      },
    ],
  });
};

export const cancelDispute = (disputeId: number): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Chargeback_DisputeDelete,
        payload: {
          disputeId,
        },
      },
    ],
  });
};

export const commitDispute = (payload: {
  disputeId: number;
  mafData: string;
}): Promise<any> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Chargeback_DisputeCommit,
        payload,
      },
    ],
  });
};
